const IconLogo = () => (
  <svg width="95" height="26" viewBox="0 0 95 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="106786A8-CBC9-4063-BD57-061526D4AA1D 1">
      <g id="black">
        <path id="Vector" d="M58.4273 3.55714C58.8118 3.51374 59.2045 3.51425 59.588 3.57144C60.1172 3.69296 60.6438 3.96866 60.9311 4.44145C61.2211 4.88055 61.2464 5.45852 61.0516 5.93947C60.8717 6.3173 60.5694 6.64509 60.1854 6.8243C59.6795 7.09439 59.0857 7.1143 58.526 7.06529C57.9038 6.98768 57.2816 6.66653 56.9591 6.11971C56.6314 5.5739 56.6785 4.8443 57.0547 4.33321C57.3782 3.9028 57.8996 3.64598 58.4273 3.55714Z" fill="black" />
        <path id="Vector_2" d="M57.2383 8.88752C58.3804 8.88497 59.522 8.87935 60.6641 8.88956C60.6703 13.3504 60.6703 17.8113 60.6641 22.2722C59.5225 22.265 58.3804 22.2707 57.2388 22.2686C57.2507 19.1858 57.2409 16.1024 57.244 13.0191C57.2403 11.6415 57.2512 10.2645 57.2383 8.88752Z" fill="black" />
      </g>
      <g id="black">
        <path id="Vector_3" d="M3.00442 4.56189C8.34701 4.56597 13.6901 4.56138 19.0327 4.56393C19.0296 5.61571 19.0327 6.66698 19.0312 7.71876C14.903 7.71723 10.7754 7.71774 6.6478 7.71825C6.64832 9.25967 6.64729 10.8006 6.64832 12.3415C10.1532 12.341 13.6581 12.3405 17.163 12.342C17.1635 13.3289 17.1614 14.3159 17.164 15.3033C13.6586 15.3054 10.1532 15.3043 6.6478 15.3033C6.64884 17.6213 6.64677 19.9388 6.64884 22.2568C5.43386 22.2609 4.21888 22.2573 3.00391 22.2583C3.00546 16.3597 3.00442 10.461 3.00442 4.56189Z" fill="black" />
        <path id="Vector_4" d="M67.028 4.74658C68.1608 4.74505 69.2931 4.74505 70.4259 4.74607C70.4274 6.12972 70.4274 7.51338 70.4259 8.89703C71.5158 8.8955 72.6052 8.89754 73.6951 8.89601C73.692 9.73692 73.693 10.5783 73.6941 11.4193C72.6047 11.4162 71.5153 11.4177 70.4259 11.4182C70.4284 15.031 70.4264 18.6439 70.4269 22.2567C69.2936 22.2618 68.1603 22.2613 67.0274 22.2567C67.03 18.6433 67.028 15.03 67.028 11.4167C65.9386 11.4187 64.8497 11.4167 63.7603 11.4177C63.7629 10.5773 63.7639 9.73641 63.7598 8.89601C64.8486 8.89754 65.9375 8.89499 67.0269 8.89754C67.03 7.51389 67.028 6.13024 67.028 4.74658Z" fill="black" />
        <path id="Vector_5" d="M41.123 8.8967C45.5354 8.89618 49.9472 8.88853 54.3596 8.89159C51.5818 12.4186 48.802 15.9436 46.0181 19.4655C48.6444 19.4615 51.2712 19.4737 53.8976 19.4671C53.8945 20.3984 53.8996 21.3302 53.8955 22.2614C49.1865 22.2584 44.478 22.262 39.7695 22.2599C42.523 18.7319 45.2904 15.214 48.0491 11.6895C45.7405 11.687 43.4326 11.6926 41.124 11.6865C41.1266 10.7567 41.1287 9.82696 41.123 8.8967Z" fill="black" />
      </g>
      <g id="black">
        <path id="Vector_6" d="M29.2043 8.64846C29.7035 8.60149 30.2027 8.66021 30.7025 8.66021C31.4895 8.74343 32.2844 8.85014 33.0316 9.11921C33.6104 9.29536 34.1546 9.56137 34.69 9.8381C35.0296 10.0551 35.3892 10.2481 35.6817 10.5289C35.8688 10.6336 35.9939 10.8148 36.1758 10.9282C36.5799 11.3555 36.9691 11.8048 37.2662 12.3139C37.768 13.1512 38.0642 14.1121 38.1293 15.0832C38.1846 15.4457 38.1732 15.8184 38.1169 16.1799C38.1189 16.475 38.0393 16.7604 37.9944 17.0499C37.9613 17.1163 37.9536 17.1893 37.9427 17.2608C37.7148 18.0558 37.3561 18.8206 36.8394 19.4736C35.9748 20.6301 34.706 21.4352 33.3572 21.9382C31.6745 22.5519 29.8342 22.6499 28.0668 22.4314C27.0002 22.2981 25.965 21.9673 25.01 21.4827C24.887 21.425 24.78 21.3342 24.6451 21.3045C24.4782 21.1575 24.2787 21.0467 24.0844 20.939C23.9247 20.7679 23.7056 20.6658 23.5413 20.4994C23.3821 20.3309 23.1749 20.2119 23.0446 20.0169C22.7222 19.7131 22.4643 19.3521 22.2198 18.9865C21.6731 18.1109 21.3434 17.1061 21.2715 16.0798C21.2834 15.6208 21.2333 15.1567 21.3191 14.7033C21.331 14.4603 21.4023 14.2254 21.4395 13.986C21.5625 13.6041 21.6777 13.2186 21.8509 12.8551C22.241 12.0162 22.8317 11.2769 23.5315 10.6693C23.8555 10.4247 24.1599 10.1475 24.5278 9.96727C25.0115 9.62672 25.5723 9.41943 26.1201 9.20346C27.1071 8.84759 28.1562 8.66429 29.2043 8.64846ZM28.8523 11.6598C27.735 11.7951 26.6301 12.2597 25.8601 13.0879C25.3046 13.7001 24.9366 14.495 24.9025 15.3211C24.8229 16.1672 25.0803 17.0295 25.5774 17.7198C25.764 17.9904 26.0162 18.2064 26.2508 18.4346C27.4069 19.372 28.9826 19.66 30.4404 19.5002C31.6983 19.3761 32.9738 18.8349 33.7526 17.8209C34.4885 16.886 34.7272 15.5887 34.3464 14.4629C34.1148 13.6873 33.5846 13.0256 32.9319 12.5492C32.1898 12.0259 31.2988 11.7226 30.3924 11.6465C30.0813 11.6542 29.7717 11.5807 29.4616 11.6256C29.2596 11.6517 29.0544 11.6343 28.8523 11.6598Z" fill="black" />
      </g>
      <g id="black">
        <path id="Vector_7" d="M83.6961 8.66226C84.3344 8.60559 84.9752 8.66328 85.6124 8.70719C87.1411 8.85475 88.6289 9.38319 89.8976 10.2379C90.2273 10.4881 90.5514 10.7464 90.8568 11.0252C91.1865 11.4005 91.5209 11.7773 91.7787 12.2067C92.3307 13.0956 92.6588 14.1198 92.7152 15.1613C92.7172 15.6821 92.7384 16.2075 92.6314 16.7206C92.4377 17.8424 91.9307 18.9084 91.1803 19.7713C90.6707 20.3641 90.0506 20.8563 89.3777 21.2586C88.5597 21.7028 87.6951 22.0821 86.7757 22.266C85.5214 22.5442 84.2202 22.5825 82.9442 22.4538C81.862 22.3369 80.7902 22.0612 79.8181 21.5706C78.8569 21.1213 77.9794 20.4784 77.3122 19.6569C76.5696 18.7731 76.0879 17.6805 75.9241 16.544C75.7877 15.4815 75.8657 14.3827 76.2368 13.3713C76.4972 12.6687 76.8776 12.0091 77.367 11.4377C77.5168 11.2948 77.6404 11.1293 77.7809 10.9782C78.2269 10.5953 78.6755 10.2078 79.1949 9.92286C80.0868 9.42097 81.0517 9.02426 82.0656 8.84505C82.6026 8.7368 83.1483 8.674 83.6961 8.66226ZM83.5256 11.6522C82.4341 11.7711 81.3644 12.2107 80.568 12.9664C80.3127 13.2349 80.0848 13.5316 79.9158 13.8609C79.4031 14.7871 79.3365 15.9415 79.7349 16.9203C80.0522 17.7346 80.6843 18.4101 81.4476 18.84C82.4997 19.445 83.7607 19.6197 84.9597 19.5048C85.8692 19.4226 86.7731 19.1295 87.5173 18.599C88.247 18.0782 88.8015 17.3057 89.0056 16.4347C89.2888 15.2966 88.9788 14.0268 88.1834 13.1538C87.556 12.4431 86.6646 11.9922 85.7462 11.7706C85.0201 11.597 84.2656 11.6032 83.5256 11.6522Z" fill="black" />
      </g>
      <g id="black">
        <path id="Vector_8" d="M28.8547 11.6599C29.0568 11.6344 29.262 11.6518 29.464 11.6257C29.7741 11.5808 30.0837 11.6543 30.3948 11.6467C31.3012 11.7227 32.1922 12.026 32.9343 12.5494C33.587 13.0257 34.1172 13.6874 34.3487 14.463C34.7296 15.5888 34.4909 16.8862 33.7549 17.821C32.9761 18.835 31.7007 19.3762 30.4428 19.5003C28.985 19.6601 27.4093 19.3721 26.2532 18.4347C26.0186 18.2065 25.7664 17.9905 25.5798 17.7199C25.0827 17.0296 24.8253 16.1673 24.9049 15.3213C24.939 14.4951 25.307 13.7002 25.8625 13.088C26.6325 12.2599 27.7374 11.7952 28.8547 11.6599Z" fill="white" />
      </g>
    </g>
  </svg>);

export default IconLogo;
