import ButtonGroupFooter from 'features/checkIn/components/ButtonGroupFooter';
import Header from 'features/checkIn/components/Header';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import {
  ECheckInType,
  IConfirmCheckInParams,
} from 'features/checkIn/services/types/api';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import SearchStaff from './components/SearchStaff';
import StaffList from './components/StaffList';
import StaffPageStyled from './styles';
import shopActions from 'services/shop/actions';

interface IEmployeeProps { }

const StaffPage: React.FC<IEmployeeProps> = () => {
  return (
    <StaffPageStyled>
      <Header>EMPLOYEE LIST</Header>
      <ListStaffContainer className="list-staff-container">
        <SearchStaff className="staff-search-box" />
        <StaffList />
      </ListStaffContainer>
      <Actions />
    </StaffPageStyled>
  );
};

const Actions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const checkInData = checkInSelectors.getCheckedInData();
  const walkInData = checkInSelectors.getWalkInData();
  const selectedServices = checkInSelectors.getSelectedServices();
  const selectedStaff = checkInSelectors.getSelectedStaff();
  const confirmedWalkIn = checkInSelectors.getConfirmedCheckIn();

  useEffect(() => {
    dispatch(shopActions.get.staffsAvailable.fetch());
  },[]);
  
  const clickBack = () => {
    dispatch(checkInActions.resetForm('services'));
    navigate(-1);
  };
  const clickDone = () => {
    const confirmCheckIn: IConfirmCheckInParams = {
      customerId: checkInData.data?.id ?? '',
      checkInType: ECheckInType.WALK_IN,
      staffId: selectedStaff?.staffId ?? '',
      group: walkInData.numOfMembers,
      serviceIds: selectedServices.map(o => o.id),
    };
    dispatch(checkInActions.confirmWalkIn.fetch(confirmCheckIn));
  };

  useEffect(() => {
    if (confirmedWalkIn) {
      navigate(`/store/${storage.shop_id.get()}/check-in/ticket-detail`);
    }
  }, [confirmedWalkIn]);

  return (
    <ButtonGroupFooter
      className="staff-list-custom-group-btn"
      clickBack={clickBack}
      clickDone={clickDone}
    />
  );
};

const ListStaffContainer = styled.div`
  margin: 2rem 6.4rem;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.list-staff-container .staff-search-box {
    margin-bottom: 1.9rem;
  }
`;

export default StaffPage;
