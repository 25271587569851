import { QRCode, Row } from 'antd';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment from 'moment';
import React from 'react';
import { ICouponItemData } from 'services/shop/types/coupon';
import styled from 'styled-components';

type ICouponInfoBoxProps = {
  data: ICouponItemData,
  printMode?: boolean;
};
const CouponTicket: React.FC<ICouponInfoBoxProps> = ({ data, printMode = false }) => {
  const AmountView = () => (
    <div className={`content ${data?.discountValue > 9 ? 'size2' : ''}`}>
      <div className='currency'>$</div>
      <div className="money">{data?.discountValue}</div>
      <div className="end">
        <div className="end-top">
          <span className='value'>00</span>
          <span style={{ position: 'relative' }}>
            <span className="label" style={{ opacity: 0 }}>OFF</span>
            <span className="label absolute">OFF</span>
          </span>
        </div>
        <div className="end-bottom">{data?.title}</div>
      </div>
    </div>
  );

  const PercentView = () => (
    <div className='content percent'>
      <div className="money">{data?.discountValue}</div>
      <div className="end">
        <div className="end-top">
          <span className='percent'>%</span>
          <span style={{ position: 'relative' }}>
            <span className="label" style={{ opacity: 0 }}>OFF</span>
            <span className="label absolute">OFF</span>
          </span>
        </div>
        <div className="end-bottom">{data?.title}</div>
      </div>
    </div>
  );


  return (
    <CouponInfoBoxStyled printMode={printMode}>
      <SellingBox printMode={printMode}>
        <div className="box">
          <BoxCoupon>
            {data.discountType === 'PERCENT' ? <PercentView /> : <AmountView />}
            <BoxContent>
              <Row align={'middle'} justify={'space-between'}>
                <div className='list-description'>
                  <ul>
                    {
                      data.description.split(' - ')?.map((text, i) => (
                        <li key={i} >{text}</li>
                      ))
                    }
                  </ul>
                </div>
                <QRCode bordered={false} size={85} value={data?.couponCode} />
              </Row>
              <Row className='expire-group' align={'middle'} justify={'space-between'}>
                <p className='expire_date'>Expires Until:  <b>{data.endTime ? moment(data.endTime).format(DATE_FORMAT) : '--'}</b></p>
                <div className='couponCode'>{data.couponCode}</div>
              </Row>
            </BoxContent>
          </BoxCoupon>
        </div>
      </SellingBox>
    </CouponInfoBoxStyled>
  );
};

export default CouponTicket;
type CouponInfoBoxStyledProps = {
  printMode?: boolean;
}
const CouponInfoBoxStyled = styled.div<CouponInfoBoxStyledProps>`
  border-radius: 5px;
  background: var(--fill-fill-5, #E5E6EB);
  .divider {
    background: #86909C;
    width: 2px;
  }
`;
const BoxCoupon = styled.div`
  width: 100%;
`;

const BoxContent = styled.div`
  .expire_date {
    color: black;
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin-left: 14px;
    b {
      color: black;
      font-family: Roboto;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .title {
    color: black;
    font-family: Roboto;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .list-description {
    flex:1;
    padding-right:10px;
  }
  ul {
    margin:0;
    padding-left: 15px;
    li, p {
      color: black;
      font-family: Roboto;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 15px;
      b {
        font-weight: 700;
      }
    } 
  }
  .expire-group {
    transform: translateY(-5px);
  }
  .couponCode {
    color: black;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 85px;
  }

`;

const SellingBox = styled.div <CouponInfoBoxStyledProps>`
border-radius: 5px;
  padding: 1rem;
  background: #fff;
.box {
  border-radius: 5px;
  display:flex;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='%23333' stroke-width='3' stroke-dasharray='6%2c 16' stroke-dashoffset='10' stroke-linecap='round'/%3e%3c/svg%3e");
  padding: 1rem;
  padding-top: 1.2rem;
  padding-bottom: 0.9rem;
    .content {
      display: flex;
      padding-left: 12px;
      
      .currency {
        color: black;
        font-family: Roboto;
        font-size: 35px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .money {
        color: black;
        font-family: Roboto;
        font-size: 100px;
        font-style: normal;
        font-weight: 700;
        line-height: 102px;
      }
      .end {
        display: flex;
        flex-direction: column;
        .end-top {
          display: flex;
          gap: 8px;
          .value {
            color: black;
            font-family: Roboto;
            font-size: 35px;
            font-style: normal;
            font-weight: 400;
          }
          .percent {
            color: black;
            font-family: Roboto;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .label {
            color: black;
            font-family: Roboto;
            font-size: 55px;
            font-style: normal;
            font-weight: 700;
            align-self: center;
            &.absolute {
              line-height: 0px;
              position: absolute;
              top: 24px;
              left: 0;
            }
          }
        }

        .end-bottom {
          color: black;
          font-family: Roboto;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          transform: translateY(-7px);
          white-space: nowrap;
        }
      }

      &.size2 {
        padding-left: 0;
        .end-bottom {
          font-size: 16px;
        }
      }
      &.percent {
        padding-left: 0;
        justify-content: center;
      }
    }
  }
`;