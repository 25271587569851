import { Col, Row } from 'antd';
import AvatarBox from 'components/AvatarBox';
import Text from 'components/Text';
import appointmentSelectors from 'features/appointment/services/selectors';
import React, { useMemo } from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';

export type IAddStaffFormProps = {
  staff?: IStaffItemData | undefined;
  onPickStaff: (option: IStaffItemData) => void;
};
const StaffForm: React.FC<IAddStaffFormProps> = ({ onPickStaff }) => {
  const staffList = appointmentSelectors.getStaffList();
  const options = useMemo(() => {
    return staffList.map(o => {
      const name = o.firstName + ' ' + o.lastName;

      return ({
        ...o,
        label: name,
        value: o.id,
        keyword: name.toLowerCase(),
      });
    });
  }, [staffList]);

  const handleClickItem = (data: IStaffItemData) => {
    onPickStaff(data);
  };

  return (
    <AddStaffFormStyled>
      <Text mb={.5} />
      <ListContainer>
        <Row gutter={[16, 16]} justify="start">
          {options.map((item) => {
            return (
              <Col
                key={item.id}
                onClick={() => handleClickItem(item)}
              >
                <AvatarBox
                  src={item.urlImage}
                  name={item.firstName + ' ' + item.lastName}
                />
              </Col>
            );
          })}
        </Row>
      </ListContainer>
    </AddStaffFormStyled>
  );
};

export default StaffForm;
const AddStaffFormStyled = styled.div``;
const ListContainer = styled.div`
  padding: 1.5rem 1rem;
  max-height: 35rem;
  height:60vh;
  overflow: auto;
`;
