import { Checkbox, Form, FormInstance, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import SwitchCustom from 'components/SwitchCustom';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IEditCateBodyType } from 'features/settingService/services/types/api';
import React, { useEffect, useState } from 'react';
import {
  ICategoryItemData,
  IServiceItemData,
} from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import AddServiceCatesButton from './ButtonModal/AddServiceCatesButton';
import DeleteButton from './ButtonModal/DeleteButton';
import MoreOutlinedDot from './ButtonModal/MoreOutlinedDot';

type IRightCateFormProps = {
  cateFormRef: React.MutableRefObject<FormInstance<ICategoryItemData> | null>;
};

const RightCateForm: React.FC<IRightCateFormProps> = ({ cateFormRef }) => {
  const dispatch = useAppDispatch();
  const cateSelected = settingServiceSelectors.getCateSelected();
  const [form] = Form.useForm<ICategoryItemData>();
  cateFormRef.current = form;
  const [isEdit, setIsEdit] = useState(false);
  const [visiblePW, setVisiblePW] = useState(false);
  const [actionDot, setActionDot] = useState('');

  const handleChangeSupplyFee = (value: number | string | null) => {
    const services = form.getFieldValue('services') || [];
    const updatedServices = services.map((service: IServiceItemData[]) => {
      return { ...service, supplyFee: value || 0 };
    });
    form.setFieldsValue({ services: updatedServices });
  };

  const onClick = (key: string) => {
    if (key === 'edit') {
      setIsEdit(true);
    }
    if (key === 'delete') {
      setVisiblePW(true);
    }
    if (key === 'delete_service') {
      setActionDot('delete_service');
      setVisiblePW(true);
    }
  };

  const handleClose = () => {
    setVisiblePW(false);
    setActionDot('');
  };

  const onVerifyAdminSuccess = (pinCode?: string) => {
    if (actionDot === 'delete_service') {
      const services = form.getFieldValue('services');
      const servicesSelected = services?.filter(
        (item: { isSelected: boolean }) => item?.isSelected
      ).map((item: {id: string}) => item?.id);
      dispatch(settingServiceActions.deleteMultiple.fetch(servicesSelected));
    } else {
      const paramsDelete = {
        id: cateSelected.id,
        pinCode,
      };
      dispatch(
        settingServiceActions.deleteCategoryForProduct.fetch(paramsDelete)
      );
      dispatch(settingServiceActions.resetServiceSetting());
    }
    setVisiblePW(false);
  };

  const onFinish = async (values: ICategoryItemData) => {
    const body: IEditCateBodyType = {
      categoryId: cateSelected.id,
      cateName: values.categoryName,
      cateType: 'SERVICE',
      supplyFee: values.supplyFee || 0,
      orderNumber: values.orderNumber || 0,
      services: values.services.map((item) => ({
        serviceId: item.id,
        serviceName: item.serviceName,
        priceSell: item.priceSell,
        duration: item.duration || 0,
        commission: item.commission || 0,
        supplyFee: item.supplyFee || 0,
        orderNumber: item.orderNumber || 0,
        categoryId: item.catId,
        addOn: false,
      })),
    };
    dispatch(settingServiceActions.editCategory.fetch(body));
  };

  const handleCancel = () => {
    form.setFieldsValue({ categoryName: cateSelected.categoryName });
    setIsEdit(false);
  };
  const handleEdit = () => {
    setIsEdit(false);
  };

  const handleSelectAll = (e: CheckboxChangeEvent) => {
    // The 'checked' property indicates whether the checkbox is now checked or not.
    const isChecked = e.target.checked;
    const values = form.getFieldsValue();
    const updatedServices = values.services.map((service: any) => ({
      ...service,
      isSelected: isChecked,
    }));
    form.setFieldsValue({ services: updatedServices });
  };

  useEffect(() => {
    form.setFieldsValue(cateSelected);
  }, [cateSelected]);

  return (
    <RightCateFormStyled>
      <Form form={form} onFinish={onFinish} initialValues={cateSelected}>
        <Box bb="line_3">
          <NameEditButtonBox>
            <Box display="flex" gap="1">
              <BiggerCheckbox onChange={handleSelectAll} />
              <FormItemNoMargin name="categoryName">
                <Input bordered={false} readOnly={!isEdit} maxLength={15} />
              </FormItemNoMargin>
            </Box>
            {isEdit && (
              <Box display="flex" gap="3" px="3" bl="line_3">
                <EditIconStyled onClick={handleCancel}>
                  <Icon type="close" />
                </EditIconStyled>
                <EditIconStyled onClick={handleEdit}>
                  <Icon type="checkMask" />
                </EditIconStyled>
              </Box>
            )}
            <div className="button-group">
              <MoreOutlinedDot onClick={onClick} />
              <AddServiceCatesButton />
            </div>
          </NameEditButtonBox>
          <Box py="3" px="4" display="flex" flexDirection="column" gap="2">
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Ordinal Number
              </Text>
              <Box width={'7rem'} display="flex" justifyContent="end">
                <FormItemNoMargin name="orderNumber">
                  <CurrencyInputPrecision size="small" />
                </FormItemNoMargin>
              </Box>
            </Box>
            <Box className="space-between">
              <Box className="center" gap="2">
                <FormItemNoMargin name="applyFee" valuePropName="checked">
                  <SwitchCustom />
                </FormItemNoMargin>
                <Text variant="CONTENT_1" color="text_3">
                  Apply Supply Fee
                </Text>
              </Box>
              <Box width={'7rem'} display="flex" justifyContent="end">
                <FormItemNoMargin name="supplyFee" style={{ width: '100%' }}>
                  <CurrencyInputPrecision
                    size="small"
                    onChange={handleChangeSupplyFee}
                  />
                </FormItemNoMargin>
              </Box>
            </Box>
          </Box>
        </Box>

        <Form.List name="services">
          {(fields) => {
            return (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <Box key={key} bb="line_3" py="3" px="4">
                    <Box className="space-between" gap="2">
                      <Box className="center">
                        <Box className="center" gap="2">
                          <Form.Item
                            style={{ margin: 0 }}
                            {...restField}
                            name={[name, 'isSelected']}
                            valuePropName="checked"
                          >
                            <BiggerCheckbox />
                          </Form.Item>
                          <Text variant="H7" color="text_3">
                            <div>{index + 1}.</div>
                          </Text>
                        </Box>

                        <Form.Item
                          style={{ margin: 0 }}
                          {...restField}
                          name={[name, 'serviceName']}
                          rules={[
                            {
                              required: true,
                              message: 'Missing service name',
                            },
                          ]}
                        >
                          <ServiceInputStyled bordered={false} />
                        </Form.Item>
                      </Box>
                      <Box>
                        <DeleteButton index={index} />
                      </Box>
                    </Box>
                    <Box pl="4" display="flex" flexDirection="column" gap="3">
                      <Box className="space-between">
                        <Text variant="H8" color="text_3">
                          Sell Price
                        </Text>
                        <Box width={'7rem'} display="flex" justifyContent="end">
                          <FormItemNoMargin
                            {...restField}
                            name={[name, 'priceSell']}
                            rules={[
                              { required: true, message: 'Missing price' },
                            ]}
                          >
                            <CurrencyInputPrecision size="small" />
                          </FormItemNoMargin>
                        </Box>
                      </Box>
                      <Box className="space-between">
                        <Text variant="H8" color="text_3">
                          Duration (minutes)
                        </Text>
                        <Box width={'7rem'} display="flex" justifyContent="end">
                          <FormItemNoMargin
                            {...restField}
                            name={[name, 'duration']}
                            rules={[
                              { required: true, message: 'Missing duration' },
                            ]}
                          >
                            <Input type="number" />
                          </FormItemNoMargin>
                        </Box>
                      </Box>
                      <Box className="space-between">
                        <Text variant="H8" color="text_3">
                          Supply Fee
                        </Text>
                        <Box width={'7rem'} display="flex" justifyContent="end">
                          <FormItemNoMargin
                            {...restField}
                            name={[name, 'supplyFee']}
                            rules={[
                              { required: true, message: 'Missing supplyFee' },
                            ]}
                          >
                            <CurrencyInputPrecision size="small" />
                          </FormItemNoMargin>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))}
              </>
            );
          }}
        </Form.List>
      </Form>
      {visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={handleClose}
        />
      )}
    </RightCateFormStyled>
  );
};

export default RightCateForm;
const RightCateFormStyled = styled.div``;
const NameEditButtonBox = styled.div`
  padding-left: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colorTheme.line_2};
  border-bottom: 1px solid ${colorTheme.line_2};
  .button-group {
    display: flex;
    height: 3rem;
    .button-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 0.5rem;
      border-left: 1px solid ${colorTheme.line_2};
    }
  }
`;

const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
`;

const EditIconStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const BiggerCheckbox = styled(Checkbox)`
  .ant-checkbox {
    width: 24px;
    height: 24px;

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;

      &::after {
        top: 50%;
        left: 32%;
      }
    }
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #6fabb6 !important;
    background-color: #6fabb6 !important;
  }

  &.ant-checkbox-wrapper .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #6fabb6 !important;
    background-color: #6fabb6 !important;
  }

  .ant-checkbox-inner {
    border-radius: 2px;
    border: 2px solid var(--line-line-3, ${colorTheme.line_3}) !important;
  }
`;

const ServiceInputStyled = styled(Input)`
  color: var(--text-text-3, #1d2129);
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
