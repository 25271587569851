import React, { useMemo } from 'react';
import { ContentBoxStyled } from './styles';
import cashierSelectors from 'features/cashier/services/selectors';
import Text from 'components/Text';
import { Row } from 'antd';
import Icon from 'assets/Icons';
import { useAppDispatch } from 'store/hooks';
import cashierActions from 'features/cashier/services/actions';
import { formatCurrency } from 'utils/formatCurrency';
import shopSelectors from 'services/shop/selectors';

const CustomerInfo = () => {
  const dispatch = useAppDispatch();
  const customerInfo = cashierSelectors.getSelectedCustomer();
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();

  const handleRemoveCustomer = () => dispatch(cashierActions.removeSelectedCustomer());
  const dollar = useMemo(() => {
    if (!loyaltySetting) return 0;

    return +(customerInfo?.point || '') / loyaltySetting?.oneDollarToPoint;
  }, [customerInfo, loyaltySetting]);
  if (!customerInfo) return null;
  return (
    <ContentBoxStyled style={{ backgroundColor: '#FDCDC5', padding: '4px', borderRadius: '4px' }}>
      <Text variant="CONTENT_2" color="text_3" style={{ fontWeight: 'bolder' }}>
        {customerInfo.customerName || 'UNKNOWN CUSTOMER'}
      </Text>
      <Row align={'middle'}>
        {loyaltySetting?.active && customerInfo.customerId && <Text variant="CONTENT_2" mr={1} style={{ fontWeight: 'bolder' }}>
          {customerInfo.point} point = {formatCurrency(dollar)}
        </Text>}
        <button onClick={handleRemoveCustomer}><Icon type="changeCustomer" fill='#F5767F' /></button>
      </Row>
    </ContentBoxStyled>
  );
};

export default CustomerInfo;
