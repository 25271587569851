import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import Modal from 'components/Modal';
import NumberKeyBoard from 'components/NumberKeyBoard';
import Text from 'components/Text';
import checkInActions from 'features/checkIn/services/actions';
import { IWalkInData } from 'features/checkIn/services/types/demoData';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import iconClock from '../../../images/icon-clock.png';
export enum TYPE_WALK_IN {
  Individual = 'Individual',
  Group = 'Group',
}
const ButtonWalkIn = () => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [type, setType] = useState<TYPE_WALK_IN>(TYPE_WALK_IN.Individual);
  const navigate = useNavigate();
  const { shop_id = '' } = useParams();

  const onChange = (e: RadioChangeEvent) => setType(e.target.value);
  const handleSubmitTypeWalkIn = () => {
    const walkInData: IWalkInData = {
      type,
      numOfMembers: 0,
    };
    if (type === TYPE_WALK_IN.Individual) {
      walkInData.numOfMembers = 0;
    } else {
      walkInData.numOfMembers = quantity;
    }

    dispatch(checkInActions.setWalkInData(walkInData));
    navigate(`/store/${shop_id}/check-in/services`);
    setVisible(false);
  };
  const isDisableOk = type === TYPE_WALK_IN.Group && quantity <=0;
  return (
    <>
      <button className="icon" onClick={() => setVisible(true)}>
        <img src={iconClock} />
      </button>
      <Modal
        modalTitle="Walk-in"
        okTitle={'Next'}
        visible={visible}
        onClose={() => setVisible(false)}
        onSubmit={() => {
          handleSubmitTypeWalkIn();
        }}
        disableOk={isDisableOk}
      >
        <Radio.Group onChange={onChange} value={type} style={{ width: '100%' }}>
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col span={12}>
              <Radio value={TYPE_WALK_IN.Individual}>Individual</Radio>
            </Col>
            <Col span={12}>
              <Radio value={TYPE_WALK_IN.Group}>Group</Radio>
            </Col>
          </Row>
        </Radio.Group>
        {type === TYPE_WALK_IN.Group && (
          <>
            <Text mt={1.5} mb={0.5} variant='CONTENT_1' color='text_3'>Quantity</Text>
            <NumberKeyBoard type='NUMBER' onChange={e => setQuantity(+e)} value={quantity?.toString()} />
          </>
        )}
      </Modal>
    </>
  );
};

export default ButtonWalkIn;