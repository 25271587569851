const Crown = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="19" viewBox="0 0 25 19" fill="none">
            <g id="#000000ff">
                <path id="Vector" d="M11.4559 0.654169C11.6209 0.309163 11.9668 0.0565783 12.3476 0.0106098C12.7105 -0.0401975 13.0923 0.0938371 13.3425 0.361906C13.6444 0.674008 13.7446 1.16321 13.5873 1.56822C13.4548 1.93209 13.1286 2.20355 12.7565 2.29645C14.3485 4.24891 15.9404 6.20136 17.5334 8.15284C19.1563 7.34186 20.7758 6.52459 22.3973 5.71167C22.2778 5.52248 22.183 5.31005 22.1801 5.08311C22.1583 4.68053 22.3664 4.276 22.7065 4.05971C23.0409 3.83713 23.4928 3.81003 23.8519 3.99051C24.2109 4.16326 24.464 4.53343 24.4949 4.93069C24.5322 5.31296 24.3614 5.70587 24.0594 5.94248C23.7648 6.18104 23.3506 6.2541 22.9906 6.13555C21.8104 9.12012 20.6437 12.1105 19.4679 15.097C14.8232 15.0975 10.1779 15.097 5.53317 15.0975C4.35831 12.11 3.1941 9.11867 2.01343 6.1341C1.65343 6.25459 1.23826 6.18297 0.943094 5.94297C0.611153 5.68796 0.443247 5.24521 0.517281 4.83392C0.597121 4.32826 1.04858 3.91164 1.56004 3.87584C2.01682 3.82938 2.4828 4.08536 2.69038 4.49423C2.89651 4.87456 2.85296 5.35989 2.60377 5.71071C4.22428 6.5512 5.84866 7.38396 7.4682 8.22591C9.06307 6.2512 10.6521 4.27165 12.2494 2.29887C11.9576 2.22194 11.6886 2.04871 11.526 1.79128C11.3049 1.45934 11.2802 1.01127 11.4559 0.654169Z" fill="#FFDC7C" />
                <path id="Vector_2" d="M5.53319 15.8721C10.1784 15.8706 14.8237 15.8725 19.4689 15.8711C19.4675 16.6453 19.4699 17.4195 19.4679 18.1937C14.8227 18.1952 10.1775 18.1932 5.53223 18.1947C5.53368 17.4205 5.53126 16.6463 5.53319 15.8721Z" fill="#FFDC7C" />
            </g>
        </svg>
    );
};

export default Crown;
