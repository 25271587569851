import { MenuProps } from 'antd/lib';
import turnActions from 'features/turn/services/actions';
import turnSelectors from 'features/turn/services/selectors';
import { ITurnRow, ITurns } from 'features/turn/services/types';
import { useCallback, useEffect, useState } from 'react';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import { HALF_TURN, MAX_TUR_DEFAULT, SCALE_TABLE_KEY_LOCALE_STORAGE, TURN_BOX_HEIGHT, TURN_BOX_WITDH, TurnStatus, TurnType } from '../../constanst';

export type IDataTable = IItemDataTable[];
export interface HashTable<T> {
    [key: string]: T;
}

export interface IItemDataTable extends ITurnRow {
    data: ITurns[][]
}

const TurnTableHooks = ({ search }: { search: string }) => {
    const dispatch = useAppDispatch();
    const [dataTable, setDataTable] = useState<IDataTable>();
    const [header, setHeader] = useState([]);
    const [sort, setSort] = useState<string>();
    const [scaleView, setScaleView] = useState<number>(100);
    const staffAvailableData = turnSelectors.getTurnStaffsAvailable();
    const turnData = turnSelectors.getTurn();
    const showModalTurnDetail = turnSelectors.getShowModalTurnDetail();
    const selectWaitingListResItem = turnSelectors.selectWaitingListResItem();
    const loadingTable = turnSelectors.getTurnLoading();
    useEffect(() => {
        const scaleJSON = localStorage.getItem(SCALE_TABLE_KEY_LOCALE_STORAGE);
        if (scaleJSON) {
            setScaleView(JSON.parse(scaleJSON));
        }
    }, []);
    useEffect(() => {
        dispatch(turnActions.getTurn.fetch({ sort: sort }));
        dispatch(turnActions.getTurnStaffsAvailable.fetch({ sort: sort }));
    }, [sort]);
    useEffect(() => {
        let maxTurn = MAX_TUR_DEFAULT;
        let countTotalMaxTurn = 0;
        if (staffAvailableData) {
            const hashData: HashTable<IItemDataTable> = {};
            turnData?.map((m: ITurnRow) => {
                const _mapTurn: ITurns[][] = [];
                let countTotalTurn = 0;
                for (let i = 0; i < m.turns.length; i++) {
                    const item = m.turns[i];
                    countTotalTurn += item.turn;
                    _mapTurn.push([item]);
                }
                if (countTotalTurn >= countTotalMaxTurn) {
                    countTotalMaxTurn = countTotalTurn;
                }
                if (maxTurn <= _mapTurn.length) {
                    maxTurn = _mapTurn.length;
                }
                hashData[m.staffId] = ({ ...m, data: _mapTurn });
            });
            const mapDataTable: IDataTable = staffAvailableData.map((mData: IStaffAvailableItem) => {
                if (hashData[mData.staffId]) {
                    return hashData[mData.staffId];
                }
                return {
                    staffId: mData.staffId,
                    firstName: mData.staffName,
                    lastName: '',
                    urlImage: mData.staffAvatar,
                    turns: [],
                    data: []
                };
            });
            if (search?.trim()?.length > 0) {
                const filterData = mapDataTable.filter((f: IItemDataTable) =>
                    f?.firstName?.toLowerCase()?.includes(search?.toLowerCase())
                    || f?.lastName?.toLowerCase()?.includes(search.toLowerCase()));
                setDataTable(filterData);
            } else {
                setDataTable(mapDataTable);
            }
        }
        const _scale = calcScaleFixMinLine(staffAvailableData?.length >= 20 ? 20
            : staffAvailableData?.length >= 10 && staffAvailableData?.length < 20 ? 15
                : 5);
        setScaleView(_scale);
        setHeader(Array.from({ length: calcMaxBlock(_scale, countTotalMaxTurn) }));
    }, [turnData, staffAvailableData, search]);

    const ColArray = useCallback((arr: any[]) => {
        return Array.from({ length: header.length * 2 - arr.filter(f => f[0].turn == 1).length }, (_, index) => arr[index] || null);
    }, [header]);

    const calcMaxBlock = (scale: number, totalMaxTurn: number) => {
        const turnTableContainer = document.getElementById('turn-table-container');
        const witdhTurnTableContainer = turnTableContainer?.offsetWidth;
        let turnBlock = totalMaxTurn > 10 ? totalMaxTurn : 10;
        if (witdhTurnTableContainer) {
            //Tính số lượng tối thiểu block có thể chèn vào 
            const witdhAllTurnBlock = witdhTurnTableContainer - (TURN_BOX_WITDH + 48) * (scale / 100);
            const minTurnBlock = Math.round(witdhAllTurnBlock / ((TURN_BOX_WITDH) * (scale / 100)));
            if (minTurnBlock > turnBlock) {
                turnBlock = minTurnBlock;
            }
        }
        return turnBlock + 2;
    };
    const calcScaleFixMinLine = (minLineTurn: number) => {
        const turnTableContainer = document.getElementById('turn-table-container');
        const heightTurnTableContainer = turnTableContainer?.offsetHeight;
        let _scale = 100;
        if (heightTurnTableContainer) {
            //Tính số lượng dòng turn tối đa vừa offsetHeight của table
            const maxLineTurnScale100 = Math.round((heightTurnTableContainer - 56) / TURN_BOX_HEIGHT);
            if (minLineTurn < maxLineTurnScale100) {
                return _scale; //return 100 nếu số lượng minLineTurn <= số lượng dòng mặc định
            }
            _scale = (maxLineTurnScale100 / minLineTurn * 100);
        }
        return _scale;
    };
    const getBlockStatus = (type: string) => {
        switch (type) {
            case TurnStatus.IN_PROGRESS:
                return 'tb-turn-block--in-progress';
            case TurnStatus.FINISH_TURN:
                return 'tb-turn-block--finish';
            case TurnStatus.VOIDED:
                return 'tb-turn-block--voided';
            case TurnStatus.DONE:
                return 'tb-turn-block--done';
            default:
                return;
        }
    };
    const getBlockType = (type: string) => {
        switch (type) {
            case TurnType.APPOINTMENT:
                return 'tb-turn-status--appointment';
            case TurnType.BONUS:
                return 'tb-turn-status--bonus';
            case TurnType.REQUEST:
                return 'tb-turn-status--request';
            case TurnType.WALK_IN:
            default:
                return 'tb-turn-status--walkin';
        }
    };


    const onClodeModalDetail = () => {
        dispatch(turnActions.setShowModalTurnDetail(false));
    };
    const onClickMenuItem = (key: string) => {
        if (sort == key) {
            setSort(undefined);
        } else {
            setSort(key);
        }
    };
    const filterItem: MenuProps['items'] = [
        {
            key: 'QUANTITY',
            label: (<a onClick={(e) => {
                e.preventDefault();
                onClickMenuItem('QUANTITY');
            }}>Quantity</a>),
        },
        {
            key: 'CLOCK_IN',
            label: (<a onClick={(e) => {
                e.preventDefault();
                onClickMenuItem('CLOCK_IN');
            }}>Clock In</a>),
        },
        {
            key: 'PROMPT_AVAILABILITY',
            label: (<a onClick={(e) => {
                e.preventDefault();
                onClickMenuItem('PROMPT_AVAILABILITY');
            }}>Prompt Availability</a>),
        },
    ];
    const checkIsHalfTurn = (value: number) => {
        return value == HALF_TURN;
    };
    const onPressBlock = (event: any, turn: ITurns[], staffId: string) => {
        event.preventDefault();
        if (selectWaitingListResItem && staffId) {
            dispatch(turnActions.syncWaitngToTicket.fetch({
                staffId: staffId,
                checkInId: selectWaitingListResItem.checkInId
            }));
            return;
        }
        if (turn?.[0]) {
            onViewDetail(turn[0]);
        }
    };
    const onViewDetail = (data: ITurns) => {
        dispatch(turnActions.getTurnDetail.fetch({ id: data.turnId }));
    };
    return {
        dispatch,
        header,
        dataTable,
        showModalTurnDetail,
        selectWaitingListResItem,
        staffAvailableData,
        loadingTable,
        scaleView,
        ColArray,
        items: filterItem,
        sort,
        getBlockStatus,
        getBlockType,
        onViewDetail,
        onClodeModalDetail,
        checkIsHalfTurn,
        onPressBlock
    };
};

export default TurnTableHooks;