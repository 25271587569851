import { Divider } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
type ITipBillCouponProps = {
  item: any;
};
const TipBillCoupon: React.FC<ITipBillCouponProps> = ({ item }) => {
  return (
    <TipBillCouponStyled className='tip_item_coupon'>
      <div className="tip-bill-item d-flex central" key={item.staffId}>
        <hr className="tip-border"></hr>
        <div className="tip-content">
          <div className="d-flex">
            <Icon type="logoSmall" />
            <Text variant="H1">LOGO</Text>
          </div>
          <Text className="bg-tip-id" variant="H1" color="text_1">
            #12120
          </Text>
          <Text variant="H7">{item.name}</Text>
          <Text variant="CONTENT_1" color="text_2">
            12-11-2023 2:35PM
          </Text>
          <Divider className="tip-divider"></Divider>
          <div className="tip-money-field">
            <div className="d-flex head-tail-align">
              <Text variant="H8">Price</Text>
              <Text variant="H9">${item.price}</Text>
            </div>
            <div className="d-flex head-tail-align">
              <Text variant="H8">Tip</Text>
              <Text variant="H9">${item.tip}</Text>
            </div>
          </div>
        </div>
        {/* <CouponSvg></CouponSvg> */}
      </div>
    </TipBillCouponStyled>
  );
};

export default TipBillCoupon;
const TipBillCouponStyled = styled.div`
  .tip-bill-item {
    height: 19rem;
    width: 18.75rem;
    position: relative;
    border-radius: 1.25rem;
    background: #fff;
  }

  .tip-divider {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .tip-content {
    width: 85%;
  }

  .tip-border {
    width: 90%;
    z-index: 1;
    position: absolute;
    top: -1rem;
    border-top: 1rem dotted #bbb;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
  }

  .bg-tip-id {
    margin-bottom: 1rem;
    width: 7.9rem;
    border-radius: 5px;
    background: var(--fill-fill-2, #adadad);
  }
  .d-flex {
    display: flex;
  }

  .flex-column {
    flex-direction: column;
  }

  .head-tail-align {
    justify-content: space-between;
  }

  .horizontal-center {
    justify-content: center;
  }

  .vertical-center {
    align-items: center;
  }

  .central {
    align-items: center;
    justify-content: center;
  }

  .tip-money-field {
    display: flex;
    flex-direction: column;
    row-gap: 0.8125rem;
  }
`;
