import { Card, Col, Row } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { ISalaryStaffDetail } from 'features/report/services/types/reportStaff';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

interface IDashboardStaffPayrollReport {
    data?: ISalaryStaffDetail
}

const DashboardStaffPayrollReport: React.FC<IDashboardStaffPayrollReport> = ({ data }) => {
    return (
        <Row gutter={[16, 16]} style={{ paddingBottom: 20 }}>
            <Col flex={'auto'} >
                <RowCardMoneyBox gutter={[16, 16]} align={'middle'}>
                    <CardMoneyBox label='TOTAL SALES' value={data?.totalSales} />
                    <CardMoneyBox label='SUPPLY FEES' value={data?.supplyFee} />
                    <CardMoneyBox label='COMMISSIONS' value={data?.totalCommission} />
                    <CardMoneyBox label='DISCOUNTS' value={data?.totalDiscount} />
                    <CardMoneyBox label='TIPS' value={data?.totalTip} />
                </RowCardMoneyBox>
            </Col>
            <Col flex={'auto'}>
                <StyledCard>
                    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <StyledCardFooter style={{ padding: '20px 10px' }}>
                            <TextBox style={{ justifyContent: 'center' }}>
                                <Text variant='CONTENT_2' textAlign='center' style={{ fontWeight: 'bolder' }}>TOTAL PAID OUT</Text>
                            </TextBox>
                        </StyledCardFooter>
                        <StyledCardBody style={{ padding: '10px 10px' }}>
                            <IconBox>
                                <Icon type="moneyExchange" />
                            </IconBox>
                            <ColFlex style={{ marginLeft: '1rem', justifyContent: 'center', alignItems: 'start' }}>
                                <div>
                                    {/* <Text mb={0.25} variant='CONTENT_2'>Total Salary</Text> */}
                                    <Text variant='CONTENT_2' style={{ fontWeight: 'bolder', fontSize: 30 }}>{formatCurrency((data?.totalSalary ?? 0))}</Text>
                                </div>
                            </ColFlex>
                        </StyledCardBody>
                    </div>
                </StyledCard>
            </Col>
            <Col flex={'auto'}>
                <StyledCard>
                    <StyledCardBody>
                        <IconBox>
                            <Icon type="moneyExchange" />
                        </IconBox>
                        <ColFlex>
                            <TextBox>
                                <Text variant='CONTENT_2'>Cash Percent: </Text>
                                <Text variant='CONTENT_2'>{(data?.cashPercent ?? 0)}%</Text>
                            </TextBox>
                            <TextBox>
                                <Text variant='CONTENT_2'>Tip in Cash: </Text>
                                <Text variant='CONTENT_2'>{formatCurrency(data?.tipToCashValue ?? 0)}</Text>
                            </TextBox>
                        </ColFlex>
                        <ColFlex>
                            <TextBox>
                                <Text variant='CONTENT_2'>Check Percent: </Text>
                                <Text variant='CONTENT_2'>{(data?.checkPercent ?? 0)}%</Text>
                            </TextBox>
                            <TextBox>
                                <Text variant='CONTENT_2'>Tip in Check: </Text>
                                <Text variant='CONTENT_2'>{formatCurrency(data?.tipToCheckValue ?? 0)}</Text>
                            </TextBox>
                        </ColFlex>
                    </StyledCardBody>
                    <StyledCardFooter>
                        <TextBox>
                            <Text variant='CONTENT_2' style={{ fontWeight: 'bolder' }}>Total Cash: </Text>
                            <Text variant='CONTENT_2' style={{ fontWeight: 'bolder', fontSize: 20 }}>{formatCurrency(data?.cashValue ?? 0)}</Text>
                        </TextBox>
                        <TextBox>
                            <Text variant='CONTENT_2' style={{ fontWeight: 'bolder' }}>Total Check: </Text>
                            <Text variant='CONTENT_2' style={{ fontWeight: 'bolder', fontSize: 20 }}>{formatCurrency(data?.checkValue ?? 0)}</Text>
                        </TextBox>
                    </StyledCardFooter>
                </StyledCard>

            </Col>
        </Row>
    );
};

export default DashboardStaffPayrollReport;
const StyledCard = styled(Card)`
    border-radius: 3.358px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0.672px solid #86909C;
    background: #FFF;
    display:flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    .ant-card-body {
        flex: 1;
        padding: 0;
    }
`;

const StyledCardBody = styled(Row)`
    flex-wrap: nowrap;
    padding: 20px;
`;

const StyledCardFooter = styled(Row)`
    background: #E5E6EB;
    padding: 10px 20px;
`;

const ColFlex = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`;
const IconBox = styled.div`
    border-radius: 10px;
    background: #fff;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 67px;
    height: 67px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
`;

const TextBox = styled.div`
    display: flex;
    padding-left: 10px;
    align-items: center;
    justify-content: space-between;
    flex:1;
`;

const RowCardMoneyBox = styled(Row)`
    height: 100%;
`;

type CardMoneyBoxProps = {
    label: string;
    value?: number;

};
const CardMoneyBox = ({ label, value }: CardMoneyBoxProps) => {
    return (
        <Col style={{
            flex: 1,
            alignSelf: 'stretch'
        }}>
            <StyledCard style={{ alignItems: 'unset' }}>
                <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <StyledCardFooter style={{ padding: '20px 10px' }}>
                        <TextBox style={{ justifyContent: 'center' }}>
                            <Text variant='CONTENT_2' textAlign='center' style={{ fontWeight: 'bolder' }}>{label}</Text>
                        </TextBox>
                    </StyledCardFooter>
                    <StyledCardBody
                        style={{ padding: '0rem 0.5rem', height: '100%', alignItems: 'center', justifyContent: 'space-between' }}
                    >
                        <IconBox style={{
                            width: 40,
                            height: 40,
                        }}>
                            <Icon type="moneyExchange" />
                        </IconBox>
                        <TextBox>
                            <Text variant='CONTENT_2' style={{ fontSize: '1.25rem', textAlign: 'right' }}>{formatCurrency(value ?? 0)}</Text>
                        </TextBox>
                    </StyledCardBody>
                </div>
            </StyledCard>
        </Col>
    );
};