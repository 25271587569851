import { Form, Input, InputRef } from 'antd';
import Box from 'components/Box';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type IAddServiceButtonProps = {
  name: number;
  restField: { fieldKey?: number };
  index: number;
  nameField: string;
  clearErrorByIndex: (index: string) => void;
};
const AddServiceButton: React.FC<IAddServiceButtonProps> = ({
  name,
  restField,
  index,
  nameField,
  clearErrorByIndex,
}) => {
  const form = Form.useFormInstance();
  const inputRef = useRef<InputRef>(null);
  const [open, setOpen] = useState(false);

  const currentService: IServiceItemData = form.getFieldValue([
    nameField,
    index,
  ]);
  const handleShowModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    const currentListValues = form.getFieldValue(nameField) || [];
    const updatedItem = {
      ...currentListValues[index],
      orderNumber: currentService.orderNumber,
      serviceName: currentService.serviceName,
      duration: currentService.duration,
      priceSell: currentService.priceSell,
      supplyFee: currentService.supplyFee,
    };
    currentListValues[index] = updatedItem;
    form.setFieldsValue({ [nameField]: currentListValues });
    setOpen(false);
  };
  const handleSubmitModal = async () => {
    try {
      await form.validateFields([
        [nameField, index, 'orderNumber'],
        [nameField, index, 'serviceName'],
        [nameField, index, 'duration'],
        [nameField, index, 'priceSell'],
        [nameField, index, 'supplyFee'],
      ]);
      clearErrorByIndex(String(index));
      setOpen(false);
    } catch (error) {
    }
  };

  const focusInput = debounce(() => {
    inputRef.current?.focus();
  }, 100);

  useEffect(() => {
    if (open && inputRef.current) {
      focusInput();
    }
  }, [open, inputRef]);

  return (
    <AddServiceButtonStyled>
      <ServiceStyled onClick={handleShowModal}>
        <Box className="center" gap="1" flexDirection="column">
          <Text variant="H9" color="text_3">
            {currentService?.serviceName || 'Name'}
          </Text>
          <Box className="center" gap="1" flexWrap="wrap">
            <Text variant="CONTENT_1" color="text_3">
              Duration:{' '}
            </Text>
            <Text variant="H9" color="text_3">
              {currentService?.duration || 0}m
            </Text>
          </Box>

          <Box className="center" gap="1" flexWrap="wrap">
            <Text variant="CONTENT_1" color="text_3">
              Supply Fee:{' '}
            </Text>
            <Text variant="H9" color="text_3">
              {formatCurrency(currentService?.supplyFee || 0)}
            </Text>
          </Box>
        </Box>
        <Box className="center" gap="1" flexWrap="wrap">
          <Text variant="CONTENT_1" color="text_3">
            Sell price:{' '}
          </Text>
          <Text>{formatCurrency(currentService?.priceSell)}</Text>
        </Box>
      </ServiceStyled>
      <Modal
        modalTitle="NEW SERVICE"
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        okTitle="Save"
        cancelTitle="Close"
      >
        <Box display="flex" flexDirection="column" gap="2">
          <Text variant="CONTENT_1" color="text_3">
            Ordinal Number
          </Text>
          <Form.Item
            {...restField}
            name={[name, 'orderNumber']}
            rules={[
              { required: true, message: 'Missing Ordinal Number' },
              {
                validator: async (_, value) => {
                  if (value > 0) return;
                  throw new Error('Number must be greater than 0');
                },
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Box>

        <Box display="flex" flexDirection="column" gap="2">
          <Text variant="CONTENT_1" color="text_3">
            Service Name
          </Text>
          <Form.Item
            {...restField}
            name={[name, 'serviceName']}
            rules={[{ required: true, message: 'Missing service name' }]}
          >
            <Input placeholder="Waxing" ref={inputRef} />
          </Form.Item>
        </Box>
        <Box display="flex" flexDirection="column" gap="2">
          <Text variant="CONTENT_1" color="text_3">
            Duration (Minutes)
          </Text>
          <Form.Item
            {...restField}
            name={[name, 'duration']}
            rules={[
              { required: true, message: 'Missing duration' },
              {
                validator: async (_, value) => {
                  if (value > 0) return;
                  throw new Error('Number must be greater than 0');
                },
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
        </Box>
        <Box display="flex" flexDirection="column" gap="2">
          <Text variant="CONTENT_1" color="text_3">
            Sell Price
          </Text>
          <Form.Item
            {...restField}
            name={[name, 'priceSell']}
            rules={[
              { required: true, message: 'Missing priceSell' },
              {
                validator: async (_, value) => {
                  if (value > 0) return;
                  throw new Error('Number must be greater than 0');
                },
              },
            ]}
          >
            <CurrencyInputPrecision />
          </Form.Item>
        </Box>
        <Box display="flex" flexDirection="column" gap="2">
          <Text variant="CONTENT_1" color="text_3">
            Supply Fee
          </Text>
          <Form.Item
            {...restField}
            name={[name, 'supplyFee']}
          >
            <CurrencyInputPrecision />
          </Form.Item>
        </Box>
      </Modal>
    </AddServiceButtonStyled>
  );
};

export default AddServiceButton;
const AddServiceButtonStyled = styled.div`
  border-radius: 5px;
  background: var(--fill-fill-3, #e5e5e5);
  padding: 4px;
`;
const ServiceStyled = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 9rem;
  text-align: center;
  border-radius: 5px;
  border: 1px dashed var(--line-line-3, #86909c);
  background: var(--fill-fill-3, #e5e5e5);
`;
