import React, { useEffect } from 'react';
import SignPageStyled from './styles';
import Header from 'features/checkIn/components/Header';
import SignUpForm from './components/SignUpForm';
import checkInSelectors from 'features/checkIn/services/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import { ICheckedInRes } from 'features/checkIn/services/types/demoData';
import { useAppDispatch } from 'store/hooks';
import checkInActions from 'features/checkIn/services/actions';

interface ISignUpPage { }
const SignUpPage: React.FC<ISignUpPage> = () => {
  const signUpRes = checkInSelectors.getSignUpData();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { shop_id = '' } = useParams();
  useEffect(() => {
    if (signUpRes.status === 200 && signUpRes.data) {
      const checkInData: ICheckedInRes = {
        status: signUpRes.status,
        message: signUpRes.message,
        pageable: signUpRes.pageable,
        data: {
          id: signUpRes.data.id,
          phone: signUpRes.data.phone,
          name: signUpRes.data.name,
          point: signUpRes.data.point,
        },
      };
      dispatch(checkInActions.checkIn.success(checkInData));
      navigate(`/store/${shop_id}/check-in/customer-info`);
    }
  }, [signUpRes]);
  return (
    <SignPageStyled>
      <Header>SIGN UP</Header>
      <SignUpForm></SignUpForm>
    </SignPageStyled>
  );
};

export default SignUpPage;
