import React, { useEffect } from 'react';
import Box from 'components/Box';
import colorTheme from 'constants/color';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import StaffCarousel from './Carousel';
import settingStaffActions from 'features/settingStaff/services/actions';

type IStaffListProps = {};
const StaffList: React.FC<IStaffListProps> = ({}) => {
  const staffs = settingStaffSelectors.staffsTableData.data();
  const staffsDisplay = settingStaffSelectors.staffsTableData.dataFilter();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(settingStaffActions.searchStaff(staffs));
  }, [staffs]);

  return (
    <StaffListStyled>
      <CarouselContainerStyled>
        <Box width={'100%'}>
          <StaffCarousel data={staffsDisplay} />
        </Box>
      </CarouselContainerStyled>
    </StaffListStyled>
  );
};

export default StaffList;
const StaffListStyled = styled.div`
  background-color: ${colorTheme.fill_3};
`;
const CarouselContainerStyled = styled.div`
  padding-left: 0 4rem;
  display: flex;
  position: relative;
`;
