import Button from 'components/Button';
import checkInActions from 'features/checkIn/services/actions';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import logo from 'assets/big-logo.svg';
type IHeaderProps = {};
const Header: React.FC<IHeaderProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const clickBack = () => {
    dispatch(checkInActions.setInitCheckIn());
    navigate(-1);
  };
  return (
    <HeaderStyled className="ant-layout-header-cus">
      <img src={logo} className="logo" />
      <Button onClick={clickBack} ntype="DEFAULT" icon="back">
        Back
      </Button>
    </HeaderStyled>
  );
};

export default Header;
const HeaderStyled = styled.div`
  margin: 0 2.5rem;
  position: fixed;
  top:0;
  left:0;
  right:0;
  height: 4.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    width:auto;
  }
`;
