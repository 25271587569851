import { Layout } from 'antd';
import Sidebar from 'components/layout/Sidebar';
import appointmentActions from 'features/appointment/services/actions';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import AppointmentCalendar from './components/Calendar';
import AppointmentsPageStyled from './styles';
type IAppointmentsPageProps = any;
const AppointmentsPage: React.FC<IAppointmentsPageProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(appointmentActions.initData());
  }, []);


  return (
    <AppointmentsPageStyled>
      <Sidebar />
      <Layout>
        <AppointmentCalendar />
      </Layout>
    </AppointmentsPageStyled>
  );
};

export default AppointmentsPage;

