import Box from 'components/Box';
import Text from 'components/Text';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { find, get } from 'lodash';
import { memo, useMemo } from 'react';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IBillDetailData | null
};
const DescribePayment = ({ data }: Props) => {
  const describePayment = useMemo(() => {
    const creditPayment = find(data?.payments, o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
    return [
      creditPayment ? { name: 'Credit Fee', price: get(creditPayment, 'feeCreditCard', 0) } : null,
      { name: 'Sale Tax:', price: data?.saleTax },
      { name: 'Use Tax:', price: data?.useTax },
      { name: 'Disc. By Item:', price: data?.discountItem },
      { name: 'Disc. By Ticket:', price: data?.discountTicket },
    ].filter(o => !!o);
  }, [data]);

  return (
    <div>
      <Box display="flex" flexDirection="column" px="4" mb='1'>
        <Text mt={0.5} mb={0.5} className="divider" />
        <Box className="space-between"  >
          <Text variant="BODY_1" color="text_3">
            Subtotal:
          </Text>
          <Text variant="BODY_1" color="text_3" style={{ fontWeight: '600' }}>
            {formatCurrency(data?.subTotal)}
          </Text>
        </Box>
        <Text mt={0.5} mb={0.5} className="divider" />
        {describePayment.map((item) => {
          return (
            <Box className="space-between" key={item?.name}>
              <Text variant="BODY_1" color="text_3" mb={0.5}>
                {item?.name}
              </Text>
              <Text variant="BODY_1" color="text_3" mb={0.5}>
                {formatCurrency(item?.price)}
              </Text>
            </Box>
          );
        })}
      </Box>
      <Box >
        <Box p="2" px='4' className="space-between" bgColor="fill_3">
          <Text variant="H6" color="text_3">
            Total
          </Text>
          <Text variant="H5" color="text_3">
            {formatCurrency(data?.total)}
          </Text>
        </Box>
      </Box>
    </div>

  );
};

export default memo(DescribePayment);
