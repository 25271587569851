import { Checkbox, Row, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import Text from 'components/Text';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import uiSelector from 'services/UI/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import { GIFT_CARDS } from '../pages';
import giftCardsActions from '../services/actions';
import { PATH_LOADING } from '../services/constants';
import giftCardsSelectors from '../services/selectors';
import { IGiftCard } from '../services/types/reducer';
import { IconStore, IconWeb } from './Icons';
type ITableGiftCardsProps = {
    resetTime?: number | undefined;
    searchText?: string | undefined;
    pageSize?: number | undefined;
    resetTimeChecked: string[];
    setResetTimeChecked: (value: any) => void;
};

const TableGiftCards: FC<ITableGiftCardsProps> = ({ resetTime, searchText, pageSize, resetTimeChecked, setResetTimeChecked }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [data, setData] = useState<IGiftCard[]>([]);
    const currentPage = giftCardsSelectors.getCurrentPage();
    const listGiftCard = giftCardsSelectors.getListGiftCard();
    const totalElements = giftCardsSelectors.getTotalElements();
    const dataAppointment = giftCardsSelectors.getParams()?.[GIFT_CARDS];
    const loadingGiftCards = uiSelector.getLoading(PATH_LOADING.getListGiftCard);

    useEffect(() => {
        setData([...listGiftCard]);
    }, [listGiftCard]);

    const columns: ColumnsType<IGiftCard> = [
        {
            title: 'Card number ',
            dataIndex: 'giftCode',
            align: 'center',
            onCell: () => {
                return {
                    onClick: event => {
                        event.stopPropagation(); // this will avoid onRow being called
                    }
                };
            },
            render(giftCode, record) {
                const isChecked = resetTimeChecked.findIndex(f => f == record.id) != -1;
                return (
                    <Checkbox checked={isChecked} onChange={() => {
                        if (isChecked) {
                            setResetTimeChecked(resetTimeChecked.filter(f => f != record.id));
                        } else {
                            setResetTimeChecked(resetTimeChecked.concat(record.id));
                        }
                    }} >
                        <EllipsisText ellipsis={{ tooltip: giftCode }}>
                            {giftCode || '--'}
                        </EllipsisText>
                    </Checkbox>
                );
            },
        },
        {
            title: 'Customer',
            dataIndex: 'customerName',
            align: 'center',
            render(customerName) {
                return (
                    <EllipsisText ellipsis={{ tooltip: customerName }}>
                        {customerName || '--'}
                    </EllipsisText>
                );
            },
        },
        {
            title: 'Phone',
            dataIndex: 'customerPhone',
            align: 'center',
            render(customerPhone) {
                return (
                    <Text variant="BODY_1" color="text_3">
                        {customerPhone ? maskPhone(customerPhone) : '--'}
                    </Text>
                );
            },
        },
        {
            title: 'Staff',
            dataIndex: 'staffName',
            align: 'center',
            render(staffName) {
                return (
                    <EllipsisText ellipsis={{ tooltip: staffName }}>
                        {staffName || '--'}
                    </EllipsisText>
                );
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            align: 'center',
            render(amount) {
                return (
                    <Text variant="BODY_1" color="text_3">
                        {formatCurrency(amount)}
                    </Text>
                );
            },
        },
        {
            title: 'Redeemed',
            dataIndex: 'usedAmount',
            align: 'center',
            render(usedAmount) {
                return (
                    <Text variant="BODY_1" color="text_3">
                        {formatCurrency(usedAmount)}
                    </Text>
                );
            },
        },
        {
            title: 'Issued Date',
            dataIndex: 'issuedDate',
            align: 'center',
            render(createdDate) {
                return (
                    <Text variant="BODY_1" color="text_3">
                        {createdDate ? moment(createdDate).format('MM-DD-YYYY') : ''}
                    </Text>
                );
            },
        },
        {
            title: 'Last Issued',
            dataIndex: 'updatedDate',
            align: 'center',
            render(updatedDate) {
                return (
                    <Text variant="BODY_1" color="text_3">
                        {updatedDate ? moment(updatedDate).format('MM-DD-YYYY') : ''}
                    </Text>
                );
            },
        },
        {
            title: 'Expiration Date',
            dataIndex: 'expireDate',
            align: 'center',
            render(expireDate) {
                return (
                    <Text variant="BODY_1" color="text_3">
                        {expireDate ? moment(expireDate).format('MM-DD-YYYY') : ''}
                    </Text>
                );
            },
        },
        {
            title: 'Status',
            dataIndex: 'buyOnline',
            align: 'center',
            render(buyOnline) {
                return (
                    <Row align={'middle'} style={{ gap: '5px' }}>
                        {buyOnline ? <IconWeb /> : <IconStore />}
                        {buyOnline ? 'Online' : 'In Store'}
                    </Row>
                );
            },
        },
        {
            title: 'Reset Time',
            dataIndex: 'numberReused',
            align: 'center',
            render(numberReused) {
                return (
                    <Text variant="BODY_1" color="text_3">
                        {numberReused}
                    </Text>
                );
            },
        },
    ];

    const handleChangePage = (page: number) => {
        const payload = {
            page: page,
            size: pageSize ?? 10,
            resetNumber: resetTime ?? 0,
            keySearch: searchText,
            startDate: dataAppointment?.startTime,
            endDate: dataAppointment?.endTime
        };
        dispatch(giftCardsActions.getListGiftCard.fetch(payload));
    };

    const onRow = (data: IGiftCard) => {
        return {
            onClick: (event: any) => {
                event.stopPropagation();
                navigate(`/store/${storage.shop_id.get()}/gift-cards/${data.id}`);
            },
        };

    };

    return (
        <TableGiftCardsStyled>
            <Table
                columns={columns}
                dataSource={data}
                rowKey={'id'}
                pagination={{
                    total: totalElements ?? 0,
                    pageSize: pageSize ?? 10,
                    current: currentPage + 1,
                    showSizeChanger: false,
                    onChange: handleChangePage
                }}
                onRow={onRow}
                loading={loadingGiftCards}
            />
        </TableGiftCardsStyled>
    );
};

export default TableGiftCards;
const TableGiftCardsStyled = styled.div`
  padding: 0 1.5rem;
  margin-top: 1rem;
`;
const EllipsisText = styled(Typography.Text)`
  width: 6rem;
  color: var(--text-text-3, #1d2129);
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

