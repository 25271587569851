import { Row } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IVIPs } from 'features/settingService/services/types/reducer';
import { FC, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import VIPCard, { IVIPsUpdate } from './Component/VIPCard';
interface IVIPSetting {

}
type IDelete =  Record<string, boolean>
const VIPSetting: FC<IVIPSetting> = () => {
    const dispatch = useAppDispatch();
    // const [switchStatus, setSwitchStatus] = useState<boolean>(false);
    const [isValidAddNewBtn, setIsValidAddNewBtn] = useState<boolean>(false);
    const [isValidDeleteBtn, setIsValidDeleteBtn] = useState<boolean>(false);
    const [dataAddNew, setDataAddNew] = useState<IVIPs>();
    const [editDatas, setEditDatas] = useState<IVIPs[]>([]);
    const [deleteData, setDeleteData] = useState<IDelete>({});
    const listVIP = settingServiceSelectors.getVIPList();
    const reloadVIPs = settingServiceSelectors.reloadVIPs();
    const scrollRef = useRef();
    const addNewVIPCardRef = useRef();
    useEffect(() => {
        dispatch(settingServiceActions.getVIP.fetch({page:1, size:20}));
    }, []);

    useEffect(()=>{
        {/* @ts-ignore */ }
        scrollRef.current?.scrollBy({ behavior: 'smooth', left: -Number.MIN_SAFE_INTEGER });
    },[listVIP]);
    
    useEffect(() => {
        if (reloadVIPs) {
            dispatch(settingServiceActions.reloadVIPs(false));
            dispatch(settingServiceActions.getVIP.fetch({page:1, size:20}));
            reset();
        }
    }, [reloadVIPs]);

    const reset = () => {
        setEditDatas([]);
        setDeleteData({});
        setDataAddNew(undefined);
        setIsValidAddNewBtn(false);
        setIsValidDeleteBtn(false);
            //@ts-ignore
            addNewVIPCardRef?.current?.resetData();
    };
    const handleAddNewVIPCard = () => {
        if(dataAddNew)
        dispatch(settingServiceActions.createVIP.fetch(dataAddNew));
    };
    const handleDelete = () => {
        const lstId = Object.keys(deleteData);
        dispatch(settingServiceActions.deleteVIP.fetch({ids:lstId}));
    };

    const handleReturnDataAddNewVIPCard = (data: IVIPsUpdate) => {
        const mapData:IVIPs = {
            yearlyPrice: data?.yearlyPrice,
            discountType: data?.discountType,
            discountValue: data?.discountValue,
            image: data?.image,
            expiresDate: data?.expiresDate,
            orderNumber: 1,
        };
        if (data.fileList.length === 0) {
            setDataAddNew(undefined);
            return setIsValidAddNewBtn(false);
        }else{
            setDataAddNew(mapData);
            return setIsValidAddNewBtn(true);
        }
    };
    const handleReturnDataEditVIPCard =  (dataEdit: IVIPsUpdate) => {
        const prevData:IVIPs = listVIP.find(f => f.id == dataEdit.id);
        if(prevData.urlImage == dataEdit?.fileList?.[0]?.url){
            dataEdit.image = undefined;
        }
        if(prevData.yearlyPrice == dataEdit.yearlyPrice 
            && prevData.discountValue == dataEdit.discountValue
            && prevData.discountType == dataEdit.discountType
            && prevData.expiresDate == dataEdit.expiresDate
            && prevData.urlImage == dataEdit?.fileList?.[0]?.url
            || !dataEdit?.fileList?.[0]
            ) {
                //No changed
               return  setEditDatas(editDatas.filter(f => f.id != dataEdit.id));
            }

            setEditDatas([...editDatas, dataEdit]);
    };
    const handleEditVIPCard = () => {
        dispatch(settingServiceActions.updateVIP.fetch(editDatas));
    };
    const handleCancelEditVIPCard = () => {
        setEditDatas([]);
    };
    const handleSelectDelete = (id:string, checked: boolean) => {
        const _delData = deleteData;
        if(checked) {
            _delData[id] = checked;
        }else{
            delete _delData[id];
        }
        const _isValid = Object.keys(_delData)?.length > 0 || false;
        setIsValidDeleteBtn(_isValid);
        setDeleteData(_delData);
    };
    return (
        <VIPSettingStyled>
            <Header>
                <Text variant="H5">VIP Card</Text>
                {/* <Row>
                    <SwitchTextStyled active={!switchStatus}>Bill Monthly</SwitchTextStyled>
                    <Switch checked={switchStatus} onChange={onSwitchChange} />
                    <SwitchTextStyled active={switchStatus}>Bill Anually</SwitchTextStyled>
                </Row> */}
                <ButtonGroup>
                    <Button ntype="PRIMARY"  disabled={!isValidAddNewBtn}  onClick={handleAddNewVIPCard}>Add New VIP Card</Button>
                    <Button ntype="SECONDARY" disabled={!isValidDeleteBtn} onClick={handleDelete} icon='trash'>Delete</Button>
                </ButtonGroup>
            </Header>
            <Content>
                {/* @ts-ignore */}
                <ScrollStyled ref={scrollRef}>
                    {listVIP.map(m=> (<VIPCard key={m.id} data={m} returnData={handleReturnDataEditVIPCard} returnChecked={handleSelectDelete}/>))}
                </ScrollStyled>
                <AreaAddMoreStyled>
                    {/* @ts-ignore */}
                    <VIPCard ref={addNewVIPCardRef} returnData={handleReturnDataAddNewVIPCard} />
                </AreaAddMoreStyled>
            </Content>
            <ButtonGroup>
                <Button ntype="SECONDARY"  disabled={editDatas.length == 0} onClick={handleCancelEditVIPCard}>Close</Button>
                <Button ntype="PRIMARY" disabled={editDatas.length == 0} onClick={handleEditVIPCard}>Save</Button>
            </ButtonGroup>
        </VIPSettingStyled >
    );
};
export default VIPSetting;

const VIPSettingStyled = styled.div`
    padding: 20px;
    background-color: '#E5E5E5';
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ButtonGroup = styled(Row)`
    justify-content: center;
    button {
      width: 10rem;
      margin: 0px 6px;
    }
`;
const Content = styled.div`
    margin: 10px 0px;
    display: flex;
    flex-direction: row;

`;
const ScrollStyled = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    > div {
        margin: 10px;
    }
`;
const AreaAddMoreStyled = styled.div`
        margin: 10px;
        position: relative;
`;

