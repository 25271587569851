import { Col, Form, Input, InputNumber, Row } from 'antd';
import { Checkbox } from 'antd/lib';
import Button from 'components/Button';
import Modal from 'components/Modal';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { ICategory, IProduct } from 'features/settingService/services/types/reducer';
import debounce from 'lodash/debounce';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ProductsCarousel from './Carousel';

interface IModalAddCategory {
    isShown: boolean;
    onClose: () => void,
    orderNumber: number;
}
interface IFormCategory extends ICategory {
    subCategories: number
}
const ModalAddCategory: FC<IModalAddCategory> = ({ isShown, onClose, orderNumber }) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<IFormCategory>();
    const [subCategories, setSubCategories] = useState<number>(0);
    const [lstProducts, setLstProducts] = useState<IProduct[]>([]);
    const applyFee = Form.useWatch('applyFee', form);
    const supplyFee = Form.useWatch('supplyFee', form);
    const reloadCategorySuccess = settingServiceSelectors.getReloadCategorySuccess();
    const handleClose = () => {
        onClose();
    };
    const onFinish = async () => {
        try {
            const values = await form.validateFields();
            const body = {
                categoryId: values.id ?? '',
                cateName: values.categoryName,
                cateType: 'PRODUCT',
                supplyFee: values.supplyFee || 0,
                orderNumber: values.orderNumber || 0,
                categoryName: values.categoryName,
                applyFee: values.supplyFee || 0,
                showSellPrice: values.applyFee,
                products: lstProducts
            };
            dispatch(settingServiceActions.createCategoryForProduct.fetch(body));
        } catch (errorInfo) { }
    };
    useEffect(() => {
        if (reloadCategorySuccess) {
            dispatch(settingServiceActions.reloadCategory(false));
            dispatch(settingServiceActions.getListCategoriesForProduct.fetch({}));
            form.resetFields();
            onClose();
        }
    }, [reloadCategorySuccess]);
    useEffect(()=>{
        form.setFieldValue('orderNumber', orderNumber);
    },[orderNumber]);

    const disabledBtnDone = !(subCategories > 0) || lstProducts.some((s: IProduct) => !s.productName || !s.image && !s.urlImage);

    return (
        <Modal
            modalTitle="ADD NEW CATEGORY"
            visible={isShown}
            onClose={onClose}
            destroyOnClose={true}
            cancelTitle="Close"
            footer={<></>}
            width={900}
            noneBodyStyle
        >
            <Content>
                <Form
                    form={form}
                    layout={'vertical'}
                >
                    <Row gutter={[12, 12]}>
                        <Col span="12">
                            <Form.Item
                                label="Ordinal Number"
                                name="orderNumber"
                                rules={[{ required: true, message: 'Missing ordinal number' }]}
                            >
                                <InputNumberStyled
                                    size='small'
                                    min={0}
                                    controls={false} />
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <Form.Item
                                label="Category Name"
                                name="categoryName"
                                rules={[{ required: true, message: 'Missing category name' }]}
                            >
                                <Input placeholder='waxing' style={{ height: 48 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]}>

                        <Col span="12">
                            <Form.Item
                                label="Sub-Catagories"
                                name="subCategories"
                                rules={[{ required: true, message: 'Missing sub-catagories' }]}
                            >
                                <InputNumberStyled
                                    size='small'
                                    value={subCategories}
                                    min={0}
                                    max={20}
                                    onChange={debounce((value: any) => { setSubCategories(value); }, 300)} />
                            </Form.Item>
                        </Col>
                        <Col span="12">
                            <ApplyCommissionPriceBox>
                                <FormItemNoMargin name="applyFee" valuePropName="checked">
                                    <Checkbox>Apply Commission Price</Checkbox>
                                </FormItemNoMargin>
                                {applyFee
                                    && <FormItemNoMargin
                                        name="supplyFee"
                                    ><InputCommissionPrice
                                            size='small'
                                            formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            // eslint-disable-next-line
                                            parser={(value: any) => value!.replace(/\$\s?|(,*)/g, '')}
                                            controls={false} />
                                    </FormItemNoMargin>
                                }
                            </ApplyCommissionPriceBox>
                        </Col>
                    </Row>
                    <Row>
                        <ProductsCarousel subCategories={subCategories}
                            returnListProducts={setLstProducts}
                            commissionFee={supplyFee}
                        />
                    </Row>
                </Form>
                <ButtonGroup>
                    <Button ntype="SECONDARY" onClick={handleClose}>Close</Button>
                    <Button ntype="PRIMARY" disabled={disabledBtnDone} onClick={onFinish}>Save</Button>
                </ButtonGroup>
            </Content>
        </Modal>
    );
};

export default ModalAddCategory;

const Content = styled.div`
    padding: 20px;
`;
const ApplyCommissionPriceBox = styled(Row)`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    height: 100%;
    width: 100%;
`;
const InputNumberStyled = styled(InputNumber)`
    display: flex;
    height: 48px;
    width: 100%;
    align-items: center;
    div {
        width: 100%;
    }
`;
const InputCommissionPrice = styled(InputNumber)`
    display: flex;
    height: 48px;
    flex:1;
    align-items: center;
    width: 100%;
    div {
        width: 100%;
    }
`;
const ButtonGroup = styled(Row)`
    justify-content: center;
    margin-top: 20px;
    button {
      width: 10rem;
      margin: 0px 6px;
    }
`;
const FormItemNoMargin = styled(Form.Item)`
  margin: 0 !important;
`;