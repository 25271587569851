import { Card, Checkbox, InputNumber } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { IProduct } from 'features/settingService/services/types/reducer';
import { FC } from 'react';
import styled from 'styled-components';

interface IProductDetail {
    data: IProduct,
    onCheck: (data: IProduct, checked: boolean) => void,
    checked: boolean;
    onEdit: (data: IProduct) => void,
}
const Product: FC<IProductDetail> = ({ data, onCheck, checked, onEdit }) => {
    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
        onCheck(data, e.target.checked);
    };
    return (
        <CardStyled>
            <CheckboxStyled onChange={onChangeCheckbox} checked={checked} />
            <Content>
                <img
                    alt={data.productName}
                    src={data.urlImage}
                />
                <ProductDetail>
                    <RowBetween>
                        <Text variant='CONTENT_2'>{data.productName}</Text>
                        <div onClick={() => onEdit(data)}>
                            <Icon type="editPen" width="22" height="22" />
                        </div>
                    </RowBetween>
                    <RowBetween>
                        <Text variant='CONTENT_1' color='text_2'>Sell price</Text>
                        <InputNumberStyled
                            disabled
                            size='small'
                            value={data.priceSell}
                            formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value: any) => value?.replace(/\$\s?|(,*)/g, '') || ''}
                            onChange={() => undefined}
                            controls={false}
                        />
                    </RowBetween>
                    <RowBetween>
                        <Text variant='CONTENT_1' color='text_2'>Commission Price</Text>
                        <InputNumberStyled
                            size='small'
                            disabled
                            value={data.commission}
                            min={0}
                            max={100}
                            formatter={(value: any) => `${value}%`}
                            onChange={() => undefined}
                            controls={false}
                        />
                    </RowBetween>
                    <RowBetween>
                        <Text variant='CONTENT_1' color='text_2'>Unit</Text>
                        <InputNumberStyled
                            size='small'
                            disabled
                            value={data.unit}
                            min={0}
                            onChange={() => undefined}
                            controls={false}
                        />
                    </RowBetween>
                </ProductDetail>
            </Content>
        </CardStyled>
    );
};
export default Product;

const CheckboxStyled = styled(Checkbox)`
    position: absolute;
    top: 20px;
    left: 15px;
`;
const CardStyled = styled(Card)`
    /* border: unset; */
`;
const Content = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 15px;
    img {
        width: 9rem;
        height: 9rem;
        object-fit: cover;
    }
`;
const ProductDetail = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
    background-color: borwn;
`;
const RowBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px 5px;
    .ant-input-number-input-wrap > input {
        text-align: center !important;
    }
`;
const InputNumberStyled = styled(InputNumber)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    text-align: center;
`;