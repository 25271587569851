import { SVGProps } from 'react';

const Edit = ({ fill }: SVGProps<any>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2334_46717)">
        <path
          d="M21.1608 0H21.3534C22.0266 0.031875 22.6917 0.304219 23.1703 0.782813C23.6752 1.26656 23.9695 1.94953 24 2.64609V2.83781C23.9766 3.35672 23.8163 3.87094 23.5186 4.29844C23.3255 4.58484 23.0663 4.81594 22.8258 5.06109C19.5066 8.38125 16.1855 11.7005 12.8663 15.0211C12.7191 15.1856 12.5034 15.2545 12.292 15.2887C11.1572 15.5123 10.0238 15.7444 8.88845 15.9652C8.40798 16.0622 7.93548 15.5878 8.03345 15.1073C8.26313 13.9322 8.50266 12.758 8.73563 11.5833C8.76516 11.4122 8.8486 11.2533 8.9747 11.1337C12.3741 7.73578 15.773 4.33687 19.1719 0.938906C19.6749 0.376406 20.4042 0.0248437 21.1608 0ZM20.3742 1.85766C20.0756 2.15203 19.7822 2.45062 19.4841 2.745C20.0733 3.33609 20.6597 3.93 21.2564 4.51312C21.562 4.19766 21.8817 3.89531 22.1845 3.57609C22.545 3.18328 22.6013 2.55328 22.3191 2.10188C22.1058 1.74141 21.7013 1.50516 21.2822 1.5C20.9475 1.48922 20.6119 1.62234 20.3742 1.85766ZM10.1752 12.0614C10.0158 12.7945 9.87938 13.5333 9.72938 14.2687C10.4124 14.1436 11.0902 13.9917 11.7727 13.8619C11.8491 13.8436 11.94 13.8431 11.993 13.7752C14.7239 11.0409 17.4581 8.31094 20.1895 5.57719C19.6017 4.98656 19.0125 4.39734 18.4224 3.80859C15.6741 6.56016 12.9178 9.30469 10.1752 12.0614Z"
          fill={fill || '#1D2129'}
        />
        <path
          d="M0.720469 4.89563C1.23563 4.3261 1.99734 3.99141 2.76516 3.99703C5.57859 3.99563 8.3925 3.99703 11.2059 3.99657C11.4248 3.98907 11.648 4.0711 11.7952 4.2361C11.9991 4.44657 12.0577 4.77985 11.9372 5.04657C11.8209 5.31282 11.5425 5.50219 11.25 5.4961C8.42109 5.4975 5.59219 5.49469 2.76328 5.4975C2.24859 5.48625 1.75266 5.82891 1.57875 6.31266C1.48734 6.54047 1.49906 6.78985 1.5 7.03032C1.50047 11.7656 1.49906 16.5009 1.50047 21.2367C1.47937 21.8948 2.05734 22.4958 2.71688 22.4948C7.49953 22.4986 12.2822 22.4948 17.0648 22.4972C17.2856 22.5023 17.513 22.4939 17.7192 22.4048C18.1856 22.2216 18.5095 21.7369 18.4992 21.2353C18.5006 18.5011 18.4997 15.7669 18.4997 13.0327C18.5016 12.8217 18.4777 12.5948 18.5841 12.4027C18.7247 12.1223 19.0528 11.9541 19.3627 12.0061C19.7184 12.0534 20.0058 12.3895 19.9992 12.7486C20.0011 15.4983 19.9992 18.2484 20.0002 20.9981C20.0508 21.7964 19.7728 22.6172 19.1995 23.183C18.7289 23.6653 18.0759 23.947 17.407 23.9995H2.61234C1.97438 23.9541 1.34813 23.7009 0.880781 23.2598C0.36 22.7836 0.046875 22.1006 0 21.3984V6.58875C0.0459375 5.96578 0.294375 5.35547 0.720469 4.89563Z"
          fill={fill || '#1D2129'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2334_46717">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Edit;
