import ActionBtn from 'components/ActionBtn';
import Button, { ButtonProps } from 'components/Button';
import Modal from 'components/Modal';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import CurrencyKeyBoard, { CurrencyKeyBoardRef, ICurrencyKeyBoard } from './NumberKeyBoard/Currency';
interface INumberKeyBoardButtonProps extends Omit<ButtonProps, 'onSubmit'> {
  btnTitle: string
  modalTitle?: string;
  defaultValue?: number;
  onSubmit?: (value: number) => Promise<boolean>;
  verticalButton?: boolean;
}
const NumberKeyBoardButton: React.FC<INumberKeyBoardButtonProps> = (
  { verticalButton, btnTitle, modalTitle = 'TIP', onSubmit = () => undefined, ...props },
) => {
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<number | null>(null);

  // useEffect(() => {
  //   if (props.defaultValue && !text) {
  //     setText(String(props.defaultValue));
  //   }
  // }, [props.defaultValue]);

  const handleShowModal = () => {
    setOpen(true);
    setTimeout(() => {
      currencyRef.current?.setValue('');
    }, 100);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmitModal = async () => {
    const isClose = await onSubmit(value || 0);
    if (isClose) handleCloseModal();
  };
  const setAmountValue: ICurrencyKeyBoard['onChange'] = (value: ICurrencyKeyBoard['value']) => {
    setValue(value || null);
  };

  return (
    <NumberKeyBoardButtonStyled>
      {!verticalButton ?
        <Button {...props} onClick={handleShowModal}>
          {btnTitle}
        </Button> :
        <ActionBtn {...props} onClick={handleShowModal} label={btnTitle} small />
      }

      <Modal
        modalTitle={modalTitle}
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        destroyOnClose={true}
      >
        <CurrencyKeyBoard ref={currencyRef} value={value} onChange={setAmountValue} />
      </Modal>
    </NumberKeyBoardButtonStyled>
  );
};

export default NumberKeyBoardButton;
const NumberKeyBoardButtonStyled = styled.div``;
