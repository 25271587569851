import { Col, Row } from 'antd';
import Text from 'components/Text';
import GiftCard from './GiftCard';
import Product from './Product';
import Services from './Services';
import VIP from './VIP';

const ExtendFeatures = () => {
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Services />
        </Col>
        <Col span={6}>
          <GiftCard />
        </Col>
        <Col span={6}>
          <VIP />
        </Col>
        <Col span={6}>
          <Product />
        </Col>
      </Row>
      <Text mb={1.125}  />
    </>
  );
};

export default ExtendFeatures;