import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import CheckUI from '../../CashierSide/components/CheckOthers/CheckPayment/CheckUI';

export interface IFormCheckSubmitValues {
  name: string;
  address: string;
  phone: string;
  dlInformation: string;
  no: string;
  currentDate: string;
  companyName: string;
  amount: number;
  bankName: string;
  bankFor: string;
  signImg: string;
  bankNum: string;
}

export type IFormCheckPayment = string;

type Props = {
  onRedeem: (signature: string) => void;
};

export type CheckRef = {
  open: (values: any) => void;
  close: () => void;
};
const Check = forwardRef<CheckRef, Props>(({ onRedeem }, ref) => {
  const [form] = useForm();
  const [visible, setVisible] = useState(false);
  const handleClose = () => {
    form.resetFields();
    setVisible(false);
  };

  const handleOpen = (values: any) => {
    form.setFieldsValue(values);
    setVisible(true);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: () => setVisible(false),
  }));

  const handleSubmitFormCheck = async (values: IFormCheckSubmitValues) => {
    onRedeem(values.signImg);
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      footer={<></>}
      noneBodyStyle
      noneFooterStyle
      hiddenHeader
      className='modal-check'
      width={'auto'}
    >
      <BoxContainer>
        <Form form={form} onFinish={handleSubmitFormCheck} >
          <CheckUI onSubmit={() => form.submit()} />
        </Form>
      </BoxContainer>
    </Modal>
  );
});
Check.displayName = 'Check';
export default Check;

const BoxContainer = styled.div`
  background-color: #E3F1E0;
  max-width: 85.5rem;
  width: 90vw;
  padding: 1rem;
`;
