import Box from 'components/Box';
import Spin from 'components/Spin';
import Text from 'components/Text';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';
import CreditCard from './ModalButton/CreditCard';
import EditCashButton from './ModalButton/EditCashButton';
import Button from 'components/Button';
import VoidButton from './ModalButton/VoidButton';
import RefundButton from './ModalButton/RefundButton';
type IRightTicketDetailsProps = {
  doPrint: () => void;
};
const RightTicketDetails: React.FC<IRightTicketDetailsProps> = ({ doPrint }) => {
  const details = ticketListSelectors.ticketDetailsData.data();
  const totalPayment = useMemo(() => {
    const creditPayment = details?.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
    return [
      {
        name: 'Grand Sub-Total:',
        price: formatCurrency(details?.subTotal || 0),
        color: 'info_4',
      },
      creditPayment ? {
        name: 'Card Fees:',
        price: formatCurrency(creditPayment?.feeCreditCard || 0),
        color: 'error_4',
      } : null,
      {
        name: 'Tip:',
        price: formatCurrency(details?.tip || 0),
        color: 'error_4',
      },
      {
        name: 'Sale Tax:',
        price: formatCurrency(details?.saleTax || 0),
      },
      {
        name: 'Used Tax:',
        price: formatCurrency(details?.useTax || 0),
      },
      {
        name: 'Discount Item:',
        price: formatCurrency(details?.discountItem || 0),
      },
      {
        name: 'Discount Ticket:',
        price: formatCurrency(details?.discountTicket || 0),
      },
    ].filter(o => !!o);
  }, [details]);
  const moneyCard = useMemo(() => {
    return [
      {
        type: PAYMENT_TYPE.LOYALTY_POINT,
        name: 'Points:',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.LOYALTY_POINT
        )?.amount,
      },
      {
        type: PAYMENT_TYPE.GIFT_CARD,
        name: 'Gift Card:',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.GIFT_CARD
        )?.amount,
      },
      {
        type: PAYMENT_TYPE.CHECK,
        name: 'Check /Other(S):',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.CHECK
        )?.amount,
      },
      {
        type: PAYMENT_TYPE.CASH,
        name: 'Cash:',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.CASH
        )?.amount,
      },
      {
        type: PAYMENT_TYPE.CREDIT_CARD,
        name: details?.billStatus === 'REFUND' ? 'Refunded:' : 'Voided:',
        price: details?.payments.find(
          (o) => o.paymentType === PAYMENT_TYPE.CREDIT_CARD
        )?.amount,
      },
    ].filter(o => !!o.price);
  }, [details]);

  const isCreditCard = !!details?.payments.find(
    (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
  );

  const isVoided = details?.billStatus === 'VOID';

  const loading = ticketListSelectors.ticketDetailsData.loading();
  if (loading) {
    return (
      <RightTicketDetailsStyled>
        <Spin></Spin>
      </RightTicketDetailsStyled>
    );
  }

  return (
    <RightTicketDetailsStyled>
      <Text variant="H5" color="text_3" textTransform='uppercase'>
        Payment Detail
      </Text>
      <Text variant="H8" color="text_2">
        {momentTimezone(details?.startTime).format('DD/MM/YYYY hh:mm A')}
      </Text>
      <Box mt='3'></Box>

      <SubTotalBox>
        {totalPayment?.map((item) => {
          return (
            <Box className="space-between" key={item?.name}>
              <Text variant="H8" color="text_3">
                {item?.name}
              </Text>
              <Box className="center" gap="2">
                <Text variant="H9" color={item?.color as any}>
                  {item?.price}
                </Text>
                {/* {isShowTip && <AddTipButton disabled={isDisableTip} />} */}
              </Box>
            </Box>
          );
        })}
      </SubTotalBox>
      <Divider />
      <TotalPaymentBox>
        <SubTotalBox>
          {moneyCard?.map((item: any) => {
            return (
              <Box className="space-between" key={item.type}>
                {item.type === PAYMENT_TYPE.CREDIT_CARD && <CreditCard />}
                {!(item.type === PAYMENT_TYPE.CREDIT_CARD) && (
                  <Text variant="CONTENT_2" color="text_3" style={{
                    fontSize: 18,
                    fontWeight: '700',
                  }}>
                    {item.name}
                  </Text>
                )}
                <Box className="center" gap="2">
                  <Text variant="CONTENT_2" color="text_3" style={{
                    fontSize: 18,
                    fontWeight: '700',
                  }}>
                    {formatCurrency(item.price)}
                  </Text>
                  {item.type === PAYMENT_TYPE.CASH && details?.billStatus !== 'VOID' && (
                    <EditCashButton maxAmount={item.price || 0} />
                  )}
                </Box>
              </Box>
            );
          })}
        </SubTotalBox>
      </TotalPaymentBox>
      {
        isCreditCard && details.signature ? <>
          <SignatureBox>
            <div className='img' style={{ backgroundImage: `url(${details.signature?.includes('http') ? details.signature : 'data:image/png;base64,' + details.signature})` }}></div>
          </SignatureBox>
        </> : null
      }
      <Box py='2'>
        <Text variant="H2" color="text_3" textAlign='center' style={{ fontWeight: 'bolder' }}>
          {details?.billStatus === 'VOID' ? 'VOIDED' : 'TOTAL'}: {formatCurrency(details?.total)}
        </Text>
      </Box>
      <Divider />
      {!!details?.reason && <Box bgColor='info_bg_orange' py='2'>
        <Text variant="CONTENT_2" color="error_4" textAlign='center'>
          Reason: {details?.reason}
        </Text>
      </Box>}
      <ButtonGroup >
        <Button ntype="SECONDARY" icon="print" onClick={doPrint}>
          Print
        </Button>
        {!details?.void || isVoided ?
          <Button
            disabled
            ntype="YELLOW"
            icon="voidList">
            Voided
          </Button>
          : <VoidButton />}


        {isCreditCard && <RefundButton disabled={!details?.refund || isVoided} maxAmount={details.total} />}
      </ButtonGroup>
    </RightTicketDetailsStyled>
  );
};

export default RightTicketDetails;
const RightTicketDetailsStyled = styled.div`
  padding: 1.5rem;
  width: 40%;
  overflow: auto;
`;
const TotalPaymentBox = styled.div`
  border-radius: 5px;
  // background: rgb(254 237 191 / 50%);
  padding-bottom: 0.5rem;
`;
const SubTotalBox = styled.div`
  margin-top: 1rem 0 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Divider = () => <Box bt="line_3" my='2'></Box>;

const SignatureBox = styled.div`
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  .img {
    width: 100%;
    height: 215px;
    margin: auto;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  .blank {
    width: 100%;
    height: 5.5rem;
  }
  align-items: end;
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;