const Cash = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.834336 7.26147C0.907461 7.24147 0.981211 7.23147 1.05746 7.23272C11.0181 7.23272 20.9787 7.23272 30.9393 7.23272C31.2537 7.19584 31.5362 7.48084 31.5 7.79459C31.5 13.4202 31.5 19.0458 31.5 24.6715C31.5362 24.984 31.2537 25.2677 30.9406 25.2327C21.085 25.2333 11.2287 25.2321 1.37309 25.2333C1.12809 25.2246 0.833086 25.2833 0.645586 25.084C0.463086 24.9127 0.503086 24.6458 0.498711 24.4215C0.501211 18.879 0.499336 13.3365 0.499961 7.79334C0.480586 7.56709 0.613711 7.33272 0.834336 7.26147ZM1.49996 8.23272C1.49996 13.5658 1.49996 18.8996 1.49996 24.2327C11.1668 24.2327 20.8331 24.2327 30.5 24.2327C30.5 18.8996 30.5 13.5658 30.5 8.23272C20.8331 8.23272 11.1668 8.23272 1.49996 8.23272Z"
        fill="#1D2129"
      />
      <path
        d="M4.8305 8.76352C4.90612 8.74165 4.98362 8.73165 5.063 8.7329C12.3542 8.7329 19.6461 8.7329 26.938 8.7329C27.1974 8.70665 27.4674 8.89602 27.4899 9.16352C27.5099 9.44915 27.5367 9.74102 27.6567 10.0054C27.9467 10.7129 28.6705 11.2135 29.4361 11.2298C29.7205 11.2054 30.0049 11.4316 29.9967 11.7266C30.0049 14.6866 29.9961 17.6466 30.0011 20.6066C30.0105 20.791 29.9742 20.9966 29.8186 21.1166C29.6074 21.2904 29.3142 21.2085 29.068 21.2816C28.1961 21.4598 27.5136 22.281 27.5024 23.1716C27.5311 23.4841 27.2561 23.7679 26.9411 23.7329C19.648 23.7329 12.3555 23.7329 5.063 23.7329C4.743 23.771 4.4655 23.4816 4.49675 23.1654C4.48175 22.2779 3.80175 21.4591 2.93237 21.2816C2.673 21.2054 2.353 21.2979 2.14675 21.0854C1.96362 20.9148 2.00237 20.6473 1.99862 20.4223C2.0005 17.6316 2.0005 14.8404 1.99862 12.0491C2.003 11.8216 1.96175 11.5498 2.148 11.3785C2.35487 11.1679 2.67362 11.2591 2.93175 11.1835C3.80237 11.0054 4.483 10.186 4.49675 9.29665C4.4855 9.07102 4.608 8.83602 4.8305 8.76352ZM5.45862 9.73415C5.2605 10.9635 4.23112 11.9941 3.00112 12.1916C2.99862 14.886 2.99925 17.5798 3.00112 20.2735C4.23175 20.4704 5.2605 21.5016 5.45862 22.7316C12.4861 22.7335 19.513 22.7335 26.5405 22.7316C26.7374 21.501 27.7686 20.4716 28.9986 20.2741C29.0011 17.5798 29.0005 14.886 28.9986 12.1923C27.7699 11.9923 26.738 10.9654 26.5411 9.73415C19.5136 9.73165 12.4861 9.73227 5.45862 9.73415Z"
        fill="#1D2129"
      />
      <path
        d="M15.2819 10.2786C16.5462 10.1218 17.8594 10.3849 18.965 11.0193C20.2756 11.7624 21.2869 13.0155 21.7294 14.4568C22.1531 15.8099 22.0712 17.3118 21.505 18.6111C20.9725 19.8555 20.0012 20.9036 18.8044 21.5343C17.6469 22.1499 16.2831 22.3686 14.9912 22.1443C13.5581 21.9049 12.225 21.1205 11.3206 19.983C10.4987 18.9643 10.0275 17.6699 10.0037 16.3611C9.96936 14.9824 10.435 13.5993 11.2906 12.518C12.2569 11.2849 13.725 10.4593 15.2819 10.2786ZM15.3431 11.2774C14.0856 11.4405 12.9037 12.1043 12.1112 13.0936C11.33 14.053 10.9294 15.3118 11.0119 16.5455C11.0844 17.7774 11.6369 18.9736 12.5281 19.8268C13.325 20.6018 14.3894 21.0961 15.4956 21.2049C16.5512 21.3161 17.6394 21.0755 18.55 20.5305C19.5231 19.9549 20.2894 19.0399 20.6825 17.9799C21.0487 17.008 21.0969 15.9211 20.8219 14.9205C20.5094 13.7555 19.7562 12.7143 18.745 12.0568C17.7531 11.3993 16.5219 11.1186 15.3431 11.2774Z"
        fill="#1D2129"
      />
      <path
        d="M15.4837 13.2514C15.51 13.007 15.6731 12.762 15.935 12.7377C16.2344 12.6877 16.4869 12.947 16.5131 13.2327C16.845 13.2364 17.1769 13.2258 17.5087 13.2358C17.7719 13.2302 18.0037 13.4683 17.9987 13.7302C18.0062 13.9958 17.77 14.2364 17.5037 14.2295C16.8962 14.2433 16.2887 14.2195 15.6819 14.2395C15.3081 14.2608 14.9944 14.6064 15.0019 14.9795C14.9906 15.3558 15.3081 15.702 15.6831 15.7258C16.0344 15.7495 16.3956 15.697 16.7381 15.8027C17.5131 16.0139 18.0681 16.812 17.9937 17.6127C17.9481 18.4077 17.2981 19.097 16.515 19.2164C16.4894 19.4583 16.3256 19.6995 16.0681 19.727C15.7675 19.7783 15.5125 19.5195 15.4862 19.2327C15.1556 19.2283 14.8244 19.2395 14.4937 19.2295C14.2281 19.2358 13.9919 18.9945 14.0012 18.7289C13.9956 18.4658 14.23 18.2283 14.4944 18.2358C15.0812 18.2245 15.6687 18.2408 16.2556 18.2295C16.6294 18.2364 16.9694 17.9214 16.9944 17.5495C17.0375 17.1752 16.7512 16.8014 16.3787 16.747C16.0475 16.707 15.7081 16.767 15.38 16.6914C14.845 16.5777 14.3762 16.1977 14.1544 15.6977C13.9112 15.1639 13.9619 14.5083 14.2894 14.0208C14.5562 13.6102 15.0012 13.3295 15.4837 13.2514Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default Cash;
