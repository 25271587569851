import React, { useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import styled from 'styled-components';
import SubmitButton from './ButtonModal/SubmitButton';
import { useAppDispatch } from 'store/hooks';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import {
  IUpdateQuickPay,
  IUpdateQuickPayBody,
} from 'features/settingService/services/types/api';
import storage from 'utils/sessionStorage';
import FromToInput from './QuickpayTab/FromToInput';

type IQuickPaySettingProps = {};

const QuickPaySetting: React.FC<IQuickPaySettingProps> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const quickPayList = settingServiceSelectors.getQuickPayList();

  const initialForm = {
    quickPay:
      quickPayList.length > 0
        ? quickPayList
        : [
            {
              fromValue: 0,
              toValue: 0,
              supplyFee: 0,
            },
          ],
  };

  const onFinish = (values: { data: IUpdateQuickPay[] }) => {
    const body: IUpdateQuickPayBody = {
      data: values.data.map((item) => ({
        ...item,
        shopId: item?.shopId || storage.shop_id.get(),
        id: item?.id || '',
      })),
    };
    dispatch(settingServiceActions.updateQuickPayList.fetch(body));
  };

  useEffect(() => {
    if (quickPayList) {
    }
  }, [quickPayList]);

  useEffect(() => {
    dispatch(settingServiceActions.getQuickPayList.fetch());
  }, []);

  return (
    <Form
      form={form}
      onFinish={onFinish}
      initialValues={initialForm}
      layout="vertical"
    >
      <QuickPaySettingStyled>
        <FormBox>
          <Box>
            <Form.List name="data">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <Box
                        key={key}
                        display="flex"
                        alignItems="center"
                        gap="1"
                        justifyContent="space-between"
                      >
                        <Row gutter={[16, 16]}>
                          <Col
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 11 }}
                            lg={{ span: 11 }}
                            xl={{ span: 11 }}
                            xxl={{ span: 11 }}
                          >
                            <Box>
                              <Form.Item label="Range Sale">
                                <FromToInput formListName="data" name={name} />
                              </Form.Item>
                            </Box>
                          </Col>
                          <Col
                            xs={{ span: 20 }}
                            sm={{ span: 20 }}
                            md={{ span: 11 }}
                            lg={{ span: 11 }}
                            xl={{ span: 11 }}
                            xxl={{ span: 11 }}
                          >
                            <Box>
                              <FormItemNoMargin
                                label="Supply Fee"
                                {...restField}
                                name={[name, 'supplyFee']}
                                rules={[
                                  {
                                    required: true,
                                    message: 'Please input the supply fee!',
                                  },
                                  {
                                    type: 'number',
                                    min: 0.01,
                                    message:
                                      'Supply fee must be greater than 0!',
                                  },
                                ]}
                              >
                                <CurrencyInputPrecision size="large" />
                              </FormItemNoMargin>
                            </Box>
                          </Col>
                          <Col>
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              onClick={() => remove(name)}
                              height="100%"
                            >
                              <Icon type="circleClose" />
                            </Box>
                          </Col>
                        </Row>
                      </Box>
                    );
                  })}
                  <Button
                    ntype="LIGHT_BLUE"
                    icon="addPlus"
                    onClick={() => add()}
                  >
                    Add Level
                  </Button>
                </>
              )}
            </Form.List>
          </Box>
        </FormBox>
        <Box className="center" mt="3" gap="3">
          <Box width="10rem">
            <Button width="100%" ntype="SECONDARY" htmlType="reset">
              Reset
            </Button>
          </Box>
          <Box width="10rem">
            <SubmitButton />
          </Box>
        </Box>
      </QuickPaySettingStyled>
    </Form>
  );
};

export default QuickPaySetting;
const QuickPaySettingStyled = styled.div`
  background: var(--fill-fill-3, #e5e5e5);
  padding: 1.5rem;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
      color: var(--text-text-3, #1d2129);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &:focus,
      &:hover,
      &:focus-visible {
        border: none;
        outline: none;
      }
    }
  }
`;
const FormBox = styled.div`
  border-radius: 5px;
  background: var(--fill-fill-1, #f6f7fc);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
`;
