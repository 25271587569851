import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/Button';
import Modal from 'components/Modal';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import { useAppDispatch } from 'store/hooks';
import ticketListActions from 'features/ticketList/services/actions';
import CurrencyKeyBoard, {
  CurrencyKeyBoardRef,
} from 'components/NumberKeyBoard/Currency';

type IEditCashButtonProps = {
  maxAmount: number;
};
const EditCashButton: React.FC<IEditCashButtonProps> = ({ maxAmount }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const cashRef = useRef<CurrencyKeyBoardRef>(null);
  const [visiblePW, setVisiblePW] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [cashValue, setCashValue] = useState<number | null | undefined>();

  const handleShowRefundModal = () => {
    setOpenRefund(true);
  };
  const handleCloseCashModal = () => {
    cashRef.current?.hideError();
    setCashValue(0);
    setOpenRefund(false);
  };

  const handleShowModal = () => {
    setVisiblePW(true);
  };

  const handleClose = () => setVisiblePW(false);

  const onVerifyAdminSuccess = () => {
    setVisiblePW(false);
    setTimeout(() => cashRef.current?.setValue(maxAmount?.toFixed(2)), 100);
    handleShowRefundModal();
  };

  const handleSubmitCash = async () => {
    const payload = {
      billId: id || '',
      amount: Number(cashValue),
    };
    dispatch(ticketListActions.editCashByBillId.fetch(payload));
    handleCloseCashModal();
  };

  useEffect(() => {
    if (cashValue && openRefund && cashValue > maxAmount) {
      cashRef.current?.showError(`Max cash must be $${maxAmount?.toFixed(2)}`);
    } else {
      cashRef.current?.hideError();
    }
  }, [cashValue, openRefund]);

  return (
    <EditCashButtonStyled>
      <Button ntype="SECONDARY" icon="edit" onClick={handleShowModal}>
        Edit
      </Button>
      {visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={handleClose}
        />
      )}
      {openRefund && (
        <Modal
          modalTitle={'EDIT CASH PAYMENT'}
          visible={openRefund}
          onClose={handleCloseCashModal}
          onSubmit={handleSubmitCash}
          destroyOnClose={true}
          disableOk={!!(cashValue && cashValue > maxAmount)}
        >
          <CurrencyKeyBoard
            ref={cashRef}
            value={cashValue}
            onChange={setCashValue}
          />
        </Modal>
      )}
    </EditCashButtonStyled>
  );
};

export default EditCashButton;
const EditCashButtonStyled = styled.div``;
