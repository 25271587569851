import React, { useMemo } from 'react';
import { Empty as EmptyAnt, EmptyProps } from 'antd';
import { ISpacing, generateCssSpacing } from 'styles/spacing';
import { styled } from 'styled-components';
interface Props extends ISpacing {
    description?: string;
    style?: EmptyProps['style'];
    centerFloating?: boolean;
}
const Empty = ({ description, ...props }: Props) => {
    const styles: React.CSSProperties = useMemo(
        () => {
            return ({
                ...generateCssSpacing(props),
                ...props.style ?? {},
            });
        },
        [props]
    );

    return (
        <EmptyAntStyled  {...props} style={styles} description={description} />
    );
};
export default Empty;

const EmptyAntStyled = styled(EmptyAnt) <Props>`
    ${({ centerFloating }) => centerFloating ? `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    
    `: ''}
`;
