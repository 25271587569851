import React, { useEffect } from 'react';
import colorTheme from 'constants/color';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import LeftSpecializeStaff from './SpecializeStaff/LeftSpecializeStaff';
import RightSpecializeStaff from './SpecializeStaff/RightSpecializeStaff';
type IStaffSpecializeProps = {};
const StaffSpecialize: React.FC<IStaffSpecializeProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(shopActions.get.categories.fetch());
    dispatch(shopActions.get.addOnServices.fetch());
  }, []);

  return (
    <StaffSpecializeStyled>
      <LeftSpecializeStaff />
      <RightSpecializeStaff />
    </StaffSpecializeStyled>
  );
};

export default StaffSpecialize;
const StaffSpecializeStyled = styled.div`
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: #fff;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: space-between;
`;
