import { Input, Popover } from 'antd';
import Icon from 'assets/Icons';
import checkInActions from 'features/checkIn/services/actions';
import React, { CSSProperties, useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import SearchResult from './SearchResult';
const overlayInnerStyle: CSSProperties = { borderRadius: 0, boxShadow: 'none', padding: 0, background: 'transparent' };
interface ISearchStaff {
  className?: string;
}

const SearchStaff: React.FC<ISearchStaff> = ({ className }) => {
  const dispatch = useAppDispatch();
  const staffListStore = shopSelectors.data.staffsAvailable();
  const [isShowResultSearch, setIsShowResultSearch] = useState(false);
  const [textSearch, setTextSearch] = useState('');

  const staffList = useMemo(() => {
    if (!textSearch) return staffListStore;
    return staffListStore.filter(o => o.staffName?.toLowerCase()?.includes(textSearch.toLowerCase()));
  }, [staffListStore, textSearch]);

  const chooseStaff = (staff: IStaffAvailableItem) => {
    setIsShowResultSearch(false);
    dispatch(checkInActions.chooseStaff(staff));
    setTextSearch('');
  };

  return (
    <SearchStaffStyled className={`${className ?? ''}`}>
      <Popover
        trigger={'contextMenu'}
        arrow={false}
        open={isShowResultSearch}
        onOpenChange={setIsShowResultSearch}
        overlayInnerStyle={overlayInnerStyle}
        placement='bottom'
        content={
          <SearchResult
            listStaff={staffList}
            chooseStaff={chooseStaff}
          />
        }
      >
        <Input
          onChange={(event) => {
            setTextSearch(event.target.value);
          }}
          onClick={() => setIsShowResultSearch(true)}
          value={textSearch}
          style={{ maxWidth: '628px', width: '40vw' }}
          placeholder="Search"
          suffix={<Icon type="search"></Icon>}
        ></Input>
      </Popover>
    </SearchStaffStyled>
  );
};

const SearchStaffStyled = styled.div`
    position: relative;
`;

export default SearchStaff;
