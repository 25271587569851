import { DndContext, DragEndEvent, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import appointmentActions from 'features/appointment/services/actions';
import { IBodyApiUpdateAppointmentWithDrop } from 'features/appointment/services/types/api';
import { ICalendarDndData, ICalendarItemData, ICalendarSchema, ICalendarViewRowItem, ICalendarViewRowSpanInfoItem } from 'features/appointment/services/types/calendar';
import { set } from 'lodash';
import moment from 'moment';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import { momentTimezone } from 'utils/time';
import CalendarStyled from '../styles';
import { DraggableOverlay } from './DnD/DraggableOverlay';
import RealtimeLine from './RealtimeLine';
import CalendarViewRow from './Row';
const DATE_VALUE_FORMAT = 'MM-DD-YYYY HH:mm:ss';
const dateRex = /(^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)/;

type IProps = {
  rows: ICalendarViewRowItem[];
  rowsSpanInfo: ICalendarViewRowSpanInfoItem[];
  distanceTimeline: ICalendarSchema['distanceTimeline'];
};
const CalendarViewBody = ({ rows = [], rowsSpanInfo, distanceTimeline }: IProps) => {
  const dispatch = useAppDispatch();
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 500,
      tolerance: 8,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor,);

  const scheduleBooking = shopSelectors.data.scheduleBooking();

  const onDragEnd = (event: DragEndEvent) => {

    const appointmentItem = (event.active?.data?.current?.data) as ICalendarItemData;
    if (!appointmentItem) return;
    const [timeStr, droppable] = String(event.over?.id || '').split('/');
    const overData = event.over?.data.current as ICalendarDndData;

    if (overData?.blockDrop) return;

    let timeStart = momentTimezone(appointmentItem.startTime);
    const timeEnd = momentTimezone(appointmentItem.endTime);
    const distance = timeEnd.diff(timeStart?.clone(), 'minutes');

    if (dateRex.test(droppable)) {
      timeStart = moment(droppable, 'DD-MM-YYYY');
    }

    const time = moment(timeStr, 'HH:mm');
    timeStart.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: 0,
    });
    const payload: IBodyApiUpdateAppointmentWithDrop = {
      appointmentId: appointmentItem.id,
      startTime: timeStart.format(DATE_VALUE_FORMAT),
      distance,
    };

    if (!dateRex.test(droppable)) {
      set(payload, 'staffId', droppable);
    }

    dispatch(appointmentActions.updateAppointmentWithDrop(payload));
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}
    >
      <CalendarStyled.TBody>
        {rows.map((row, index) => (
          <CalendarViewRow
            key={row.id}
            data={row}
            index={index}
            rowsSpanInfo={rowsSpanInfo}
            distance={distanceTimeline}
            scheduleBooking={scheduleBooking}
          />
        ))}
        <RealtimeLine />
      </CalendarStyled.TBody>
      <DraggableOverlay />
    </DndContext>
  );
};

export default CalendarViewBody;
