
export const NAME_REDUCER = 'payment';

export const PREFIX_ACTIONS = 'payment_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
};

export enum PAYMENT_TYPE {
  CASH = 'CASH',
  CREDIT_CARD = 'CREDIT_CARD',
  CHECK = 'CHECK',
  GIFT_CARD = 'GIFT_CARD',
  LOYALTY_POINT = 'LOYALTY_POINT',
}
export enum PAYMENT_TYPE_LABEL {
  CASH = 'Cash',
  CREDIT_CARD = 'Credit Card',
  CHECK = 'Check',
  GIFT_CARD = 'Gift Card',
  LOYALTY_POINT = 'Loyalty Point',
}

export enum CREDIT_CARD_METHODS {
  MANUAL = 'MANUAL',
  X_TERMINAL = 'X_TERMINAL',
  SWIPE = 'SWIPE'
}

export enum OTHER_PAYMENT {
  CHECK = 'CHECK',
  VENMO = 'VENMO',
  CASH_APPS = 'CASH_APPS',
  ZELLE = 'ZELLE',
  PAYPAL = 'PAYPAL',
}