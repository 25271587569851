import { Layout, Row } from 'antd';
import Button from 'components/Button';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import userActions from 'features/user/services/actions';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import turnActions from '../services/actions';
import StaffCarousel from './components/Carousel';
import SearchBox from './components/SearchBox';
import TurnTable from './components/TurnTable';
import patentPending from './patent-pending.png';
type IPageProps = any;

const TurnPage: React.FC<IPageProps> = () => {
    const dispatch = useAppDispatch();
    const [visiblePW, setVisiblePW] = useState(false);
    const [searchText, setSearchText] = useState<string>('');

    useEffect(() => {
        dispatch(turnActions.getTurnStaffsAvailable.fetch({}));
        dispatch(shopActions.get.staffs.fetch());
        dispatch(turnActions.getTurn.fetch({}));
        dispatch(userActions.getWaitingList.fetch());
    }, []);

    const handleDebounceSearchStaff = useCallback(
        debounce((value: string) => {
            setSearchText(value);
        }, 300),
        []
    );

    const handleClearTurn = () => setVisiblePW(true);
    const handleClose = () => setVisiblePW(false);

    const onVerifyAdminSuccess = () => {
        handleClose();
        dispatch(turnActions.clearAllTurn());
    };

    return (
        <PageStyled id='page_turn'>
            <Sidebar />
            <Container>
                <HeaderBox>
                    <StatusSearchBox>
                        <Row align={'middle'} justify={'space-between'}>
                            <StatusBar>
                                <StatusItem>
                                    <div className="square tb-turn-status--walkin"></div>
                                    <span>Walk-in</span>
                                </StatusItem>

                                <StatusItem>
                                    <div className="square tb-turn-status--appointment"></div>
                                    <span>Appointment (Appt)</span>
                                </StatusItem>
                                <StatusItem>
                                    <div className="square tb-turn-status--bonus"></div>
                                    <span>Bonus</span>
                                </StatusItem>
                                <StatusItem>
                                    <div className="square tb-turn-status--request"></div>
                                    <span>Request (Reqs)</span>
                                </StatusItem>
                                <StatusItem>
                                    <div className="circle tb-turn-block--in-progress"></div>
                                    <span>In Progress</span>
                                </StatusItem>
                                <StatusItem>
                                    <div className="circle tb-turn-block--finish"></div>
                                    <span>Finish</span>
                                </StatusItem>
                                <StatusItem>
                                    <div className="circle tb-turn-block--done"></div>
                                    <span>Done</span>
                                </StatusItem>
                                <StatusItem>
                                    <div className="circle tb-turn-block--voided"></div>
                                    <span>Voided</span>
                                </StatusItem>
                            </StatusBar>
                            <Row align={'middle'} style={{ gap: '1rem' }}>
                                <SearchBox onChange={(event) => {
                                    handleDebounceSearchStaff(event.target.value);
                                }} />
                                <Button onClick={handleClearTurn} icon='clearTurn' ntype='YELLOW'>Clear Turn</Button>
                            </Row>
                        </Row>
                    </StatusSearchBox>
                </HeaderBox>
                <Content>
                    <StaffCarousel />
                    <TurnTable search={searchText} />
                </Content>
            </Container>
            {visiblePW && (
                <RequestAdminLocalPW
                    onlyAdmin
                    visible
                    onCorrect={onVerifyAdminSuccess}
                    onClose={handleClose}
                />
            )}
            <div className='patent-pending zoom-in-zoom-out' />
        </PageStyled>
    );
};

export default TurnPage;

const PageStyled = styled(Layout)`
.zoom-in-zoom-out {
    margin: 24px;
    width: 50px;
    height: 50px;
    background: green;
    animation: zoom-in-zoom-out 2s ease-out infinite;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
    .patent-pending {
        background: url(${patentPending});
        width: 5rem;
        height: 5rem;
        position: fixed;
        bottom: 10px;
        right: 10px;
        background-size: contain;
        background-repeat: no-repeat;
    }
`;
const Container = styled(Layout)`
    background-color: ${colorTheme.fill_3};
    
    .tb-turn-status--walkin {
    background: var(--fill-fill-2, #adadad);
    }
    .tb-turn-status--appointment {
    background: var(--fill-fill-4, #1d2129);
    }
    .tb-turn-status--request {
    background: var(--info-infor-2, #ffdc7c);
    }
    .tb-turn-status--bonus {
    background: var(--orange, #ff7d00);
    }
    .tb-turn-block--in-progress {
    cursor: pointer;
    background: var(--primary-button, #ffe5e7);
    border: 1px solid var(--line-line-3, #f5767f);
    }
    .tb-turn-block--finish {
    cursor: pointer;
    background: var(--blue-headline, #F8D9BB);
    border: 1px solid var(--info-infor-3, #FF7D00);
    }
    .tb-turn-block--voided {
    cursor: pointer;
    background: #FEEDBF;
    border: 1px solid #FDCC43;
    }
    .tb-turn-block--done {
    cursor: pointer;
    background: var(--blue-headline, #c6e9ef);
    border: 1px solid var(--info-infor-3, #6fabb6);
    }

    .tb-turn-block--late {
    background: var(--blue-headline, #86909C);
    border: 1px solid var(--info-infor-3, #adadad);
    }
`;
const HeaderBox = styled.div`
    background: #EFEFEF;
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);
    flex-wrap: wrap;
    padding: 1rem 1.5rem 0rem;
    gap: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Content = styled(Layout.Content)`
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  background: #EFEFEF;
`;

const StatusSearchBox = styled.div`
    display: flex;
    flex:1;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
    >div:last-child{
        width: 100%;
        align-items: center;
        display: flex;
    }
    .square {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    }
    .circle {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-right: 10px;
    }
    
`;

const StatusBar = styled.div`
    min-width: 780px;
    display: flex;
`;

const StatusItem = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
`;