import { Col, InputRef, Row } from 'antd';
import AvatarBox from 'components/AvatarBox';
import Text from 'components/Text';
import cashierSelectors from 'features/cashier/services/selectors';
import { IStaff } from 'features/cashier/services/types/api';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import StaffSearchInput from './StaffSearchInput';

export type IAddStaffFormProps = {
  staff?: IStaff | undefined;
  onPickStaff: (option: IStaff) => void;
  inputRef: React.RefObject<InputRef>;
};
const AddStaffForm: React.FC<IAddStaffFormProps> = ({ staff, inputRef, onPickStaff }) => {
  const staffList = cashierSelectors.getAvailableStaff();
  const [searchText] = useState('');
  const initOptions = useMemo(() => {
    return staffList.map(o => ({
      ...o,
      label: o.staffName,
      value: o.staffId,
      keyword: o.staffName.toLowerCase(),
    }));
  }, [staffList]);

  const options = useMemo(() => {
    if (!searchText.trim()) return initOptions;

    return initOptions.filter((item) =>
      item.keyword.includes(searchText.toLowerCase())
    );
  }, [initOptions, searchText]);

  const handleClickItem = (data: IStaff) => {
    onPickStaff(data);
  };

  return (
    <AddStaffFormStyled>
      <StaffSearchInput inputRef={inputRef} onPickStaff={onPickStaff} />
      <Text mb={.5} />
      <ListContainer>
        <Row gutter={[16, 16]} justify="start">
          {options.map((item) => {
            return (
              <Col
                key={item.staffId}
                onClick={() => handleClickItem(item)}
              >
                <AvatarBox
                  src={item.staffAvatar}
                  name={item.staffName}
                  selected={item.staffId === staff?.staffId}
                />
              </Col>
            );
          })}
        </Row>
      </ListContainer>
    </AddStaffFormStyled>
  );
};

export default AddStaffForm;
const AddStaffFormStyled = styled.div`
  min-height: 40rem;
`;
const ListContainer = styled.div`
  padding: 1.5rem 1rem;
`;
