import Button, { ButtonProps } from 'components/Button';
import { styled } from 'styled-components';
interface Props extends ButtonProps { label: string, small?: boolean }
function ActionBtn(props: Props) {
    return (
        <ActionBtnStyled
            {...props}
            block
            vertical
        >
            <p>{props.label}</p>
        </ActionBtnStyled>
    );
}

export default ActionBtn;
const ActionBtnStyled = styled(Button) <Props>`
    width: 6.2rem !important;
    p {
        white-space: pre;
      color: var(--text-text-3, #1D2129);
      text-align: center;
      font-family: Open Sans;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
`;