import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { find, set } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import StaffSelector from '../ClockReport/components/StaffSelector';
import AppointmentDatePicker from '../components/DatePicker';
import SwitchCalendarType from '../components/SwitchCalendarType';
import { ReportType } from '../constants';
import DashboardRow from './component/DashboardRow';
import TableEmployeeSalary from './component/TableEmployeeSalary';

interface IEmployeeSalary { }
const EmployeeSalary: React.FC<IEmployeeSalary> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const summaryDashboardSalaryStaff = reportSelectors.getSummaryDashboardSalaryStaff();
    const tableSummaryStaff = reportSelectors.getSummarySalaryStaff();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.EMPLOYEE_SALARY];
    const viewType = reportSelectors.getViewType()?.[ReportType.EMPLOYEE_SALARY];
    const salaryType = reportSelectors.getFilterTypeSalaryStaff();
    const staffData = shopSelectors.data.staffs()?.filter(f => !f.anyBody);
    const staffSelected = useMemo(() => {
        if (!dataAppointment?.staffId) return undefined;
        return find(staffData, (o: IStaffItemData) => o.id === dataAppointment.staffId);
    }, [dataAppointment, staffData]);

    useEffect(() => {
        if (staffData.length == 0) {
            dispatch(shopActions.get.staffs.fetch());
        }
        dispatch(reportActions.setParams({
            [ReportType.EMPLOYEE_SALARY]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT),
            }
        }));
    }, []);

    useEffect(
        () => {
            if (dataAppointment?.startTime && dataAppointment?.endTime) {
                const params = {
                    startDate: dataAppointment?.startTime,
                    endDate: dataAppointment?.endTime,
                    salaryType: salaryType
                };

                if (dataAppointment.staffId) {
                    set(params, ['staffId'], dataAppointment.staffId);
                }

                dispatch(reportActions.getSummaryDashboardSalaryStaff.fetch(params));
                dispatch(reportActions.getSummarySalaryStaff.fetch(params));
            }
        }, [dataAppointment, salaryType]
    );

    const onChangeStaffSelected = (value: IStaffItemData | null) => {
        const params = {
            ...dataAppointment || {
                startTime: moment().format(DATE_FORMAT),
                endTime: moment().format(DATE_FORMAT),
            },
            staffId: value?.id || undefined,
        };
        if (!params.staffId) {
            delete params.staffId;
        }

        dispatch(reportActions.setParams({
            [ReportType.EMPLOYEE_SALARY]: params
        }));
    };

    const handlePrint = () => {
        if (viewType && viewType != CALENDAR_VIEW_TYPE.DAY_VIEW) {
            return navigate(`/store/${storage.shop_id.get()}/report/print/employee-salary-date-range`);
        }
        navigate(`/store/${storage.shop_id.get()}/report/print/employee-salary`);
    };

    return (
        <StyledEmployeeSalary>
            {/* header */}
            <HeaderBox>
                <Box pt='3' pb='4'>
                    <Text variant="H5">PAYROLL REPORTS</Text>
                </Box>
                <StaffSelector size='middle' data={staffData} onChange={onChangeStaffSelected} selected={staffSelected} />
                <AppointmentDatePicker size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.EMPLOYEE_SALARY} />
                <SwitchCalendarType actions={reportActions} selectors={reportSelectors} type={ReportType.EMPLOYEE_SALARY} />
                <Button disabled={tableSummaryStaff.length == 0} icon="print" onClick={handlePrint}>
                    Preview
                </Button>
            </HeaderBox>
            <DashboardRow data={summaryDashboardSalaryStaff} />
            {/* table */}
            <TableEmployeeSalary data={tableSummaryStaff} />

        </StyledEmployeeSalary>
    );
};

export default EmployeeSalary;

const StyledEmployeeSalary = styled.div`
    min-height: 100vh;
    background-color: ${colorTheme.fill_3};
    padding: 1.5rem;
    direction: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const HeaderBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
`;

