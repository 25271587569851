import { Col, Row } from 'antd';
import styled from 'styled-components';

export const Header = styled(Row)`
  border-top:1px solid black;
  padding: 2px 0px;
  border-bottom: 1px solid black;
`;

export const ColHeader = styled(Col)`
    color:  black;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .text {
      font-size: 0.7rem;
    }
`;