import { Avatar, Row, Table, TableColumnsType } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { IIconAndPriceProps } from 'features/report/components/IconAndPrice';
import IconAndPriceContainer from 'features/report/components/IconAndPriceContainer';
import ProcessReport, { IListTotalProcessReport } from 'features/report/components/ProcessReport';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import AppointmentDatePicker from '../components/DatePicker';
import SwitchCalendarType from '../components/SwitchCalendarType';
import { ReportType } from '../constants';
import ModalViewInfo from './components/ModalViewInfo';

type ISalonReportProps = {};

const columns: TableColumnsType<DataType> = [
  {
    title: <div>Staffs</div>,
    dataIndex: 'name',
    width: 150,
    key: 'name',
    render: (_, { name, urlImage }) => (
      <Row align={'middle'} justify={'start'} style={{ gap: '10px' }} >
        <Avatar size={'small'} src={urlImage} style={{ backgroundColor: '#f56a00' }}>{name?.[0] || 'A'}</Avatar>
        <Text variant="BODY_1" color="text_3" textAlign="left">
          {name}
        </Text>
      </Row>
    ),
  },
  {
    title: 'Services',
    dataIndex: 'service',
    key: 'service',
    render: (_, { service }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(service)}
      </Text>
    ),
  },
  {
    title: 'Products',
    dataIndex: 'product',
    key: 'product',
    render: (_, { product }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(product)}
      </Text>
    ),
  },
  {
    title: 'Supply Fees',
    dataIndex: 'supply_fee',
    key: 'supply_fee',
    render: (_, { supply_fee }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(supply_fee)}
      </Text>
    ),
  },
  {
    title: 'COMMISSIONS',
    dataIndex: 'commissionValue',
    key: 'commissionValue',
    render: (commissionValue) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(commissionValue)}
      </Text>
    ),
  },
  {
    title: 'Discounts',
    dataIndex: 'discount',
    key: 'discount',
    render: (_, { discount }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(discount)}
      </Text>
    ),
  },
  {
    title: 'Tips',
    dataIndex: 'tip',
    key: 'tip',
    render: (_, { tip }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(tip)}
      </Text>
    ),
  },
  {
    title: 'PAYROLL',
    key: 'payroll',
    render: (_, { discount = 0, commissionValue = 0, tip = 0 }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency((+commissionValue) + (+tip) - (+discount))}
      </Text>
    ),
  },
  {
    title: 'Actions',
    key: 'actions',
    render: () => (
      <Icon type={'next'}></Icon>
    ),
    align: 'center'
  },
];
interface DataType {
  key: string;
  name: string;
  service: string | number;
  product: string | number;
  supply_fee: string | number;
  discount: string | number;
  tip: string | number;
  urlImage: string;
  commissionValue?: number;
}

const defaultProgressList: IIconAndPriceProps[] = [
  {
    title: 'SERVICES',
    price: 0,
    progress: 0,
  },
  {
    title: 'PRODUCTS',
    price: 0,
    progress: 0,
  },
  {
    title: 'VIP',
    price: 0,
    progress: 0,
  },
  {
    title: 'GIFT CARD',
    price: 0,
    progress: 0,
  },
];

const defaultListTotalProgressReport: IListTotalProcessReport = {
  totalSaleService: 0,
  totalSaleProduct: 0,
  totalSaleVIP: 0,
  totalSaleGiftCard: 0,
  totalCash: 0,
  totalCreditCard: 0,
  totalCheckOther: 0,
  totalGiftCard: 0,
  totalRewardPoint: 0,
};

const SalonReport: React.FC<ISalonReportProps> = () => {
  const [dataProgressList, setDataProgressList] = useState<IIconAndPriceProps[]>(defaultProgressList);
  const [listTotalProgressReport, setListTotalProgressReport] = useState<IListTotalProcessReport>(defaultListTotalProgressReport);
  const [dataTable, setDataTable] = useState<DataType[]>([]);
  const [isShowInfoModal, setIsShowInfoModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const summarySalon = reportSelectors.getSummarySalon();
  const summaryTicket = reportSelectors.getSummaryTicket();
  const tableSummarySalon = reportSelectors.getSummarySalarySalon();
  const dataAppointment = reportSelectors.getParams()?.[ReportType.SALON];
  const staffData = shopSelectors.data.staffs()?.filter(f => !f.anyBody);
  useEffect(() => {
    if (staffData.length == 0) {
      dispatch(shopActions.get.staffs.fetch());
    }
    dispatch(reportActions.setParams({
      [ReportType.SALON]: {
        startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
        endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
      }
    }));
  }, []);


  useEffect(
    () => {
      if (dataAppointment?.startTime && dataAppointment?.endTime) {
        dispatch(reportActions.getSummarySalon.fetch({
          startDate: dataAppointment?.startTime,
          endDate: dataAppointment?.endTime
        }));
        dispatch(reportActions.getSummaryTicket.fetch({
          startDate: dataAppointment?.startTime,
          endDate: dataAppointment?.endTime
        }));
        dispatch(reportActions.getSummarySalarySalon.fetch({
          startDate: dataAppointment?.startTime,
          endDate: dataAppointment?.endTime
        }));
      }
    }, [dataAppointment]
  );

  useEffect(
    () => {
      setDataProgressList([
        {
          title: 'SERVICES',
          price: summarySalon?.totalSaleService ?? 0,
          progress: summarySalon?.saleServicePercent,
        },
        {
          title: 'PRODUCTS',
          price: summarySalon?.totalSaleProduct ?? 0,
          progress: summarySalon?.saleProductPercent,
        },
        {
          title: 'VIP',
          price: summarySalon?.totalSaleVIP ?? 0,
          progress: summarySalon?.saleVIPPercent,
        },
        {
          title: 'GIFT CARDS',
          price: summarySalon?.totalGiftCard ?? 0,
          progress: summarySalon?.saleGiftCardPercent,
        },
      ]);
      setListTotalProgressReport(summarySalon);
    }, [summarySalon]
  );

  useEffect(
    () => {
      setDataTable(tableSummarySalon.map(d => {
        return {
          key: d.staffId,
          name: d.staffName,
          service: d.totalSaleService,
          product: d.totalSaleProduct,
          supply_fee: d.supplyFee,
          discount: d.discount,
          tip: d.tip,
          urlImage: d.avatar,
          commissionValue: d.commissionValue,
        };
      }));
    }, [tableSummarySalon]
  );

  const onRow = (data: DataType) => {
    return {
      onClick: () => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalaryStaffDetailOneDay.fetch({
          startDate: moment().format(DATE_FORMAT),
          endDate: moment().format(DATE_FORMAT),
          staffId: data.key
        }));
        setIsShowInfoModal(true);
      }, // click row
    };

  };

  const onClose = () => setIsShowInfoModal(false);

  const handlePrint = () => {
    navigate(`/store/${storage.shop_id.get()}/report/print`);
  };
  return (
    <SalonReportStyled>
      {/* header */}
      <HeaderBox>
        <Box>
          <Text variant="H5">INCOME REPORTS</Text>
        </Box>
        <HeaderBox>
          <AppointmentDatePicker size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.SALON} />
          <SwitchCalendarType actions={reportActions} selectors={reportSelectors} type={ReportType.SALON} />
          <Button icon="print" onClick={handlePrint}>
            Preview
          </Button>
        </HeaderBox>
      </HeaderBox>
      {/* ticket icon + price */}
      <IconAndPriceContainer
        dataSale={summarySalon}
        dataTicket={summaryTicket}
      />
      <ProcessReport list={dataProgressList} listTotal={listTotalProgressReport} />
      {/* table */}
      <TableBox>
        <Table
          loading={false}
          dataSource={dataTable}
          columns={columns}
          pagination={{ pageSize: 50 }}
          scroll={{ y: 500 }}
          onRow={onRow}
        />
      </TableBox>
      <ModalViewInfo key={Math.random()} isShow={isShowInfoModal} onClose={onClose} />
    </SalonReportStyled>
  );
};

export default SalonReport;
const SalonReportStyled = styled.div`
  min-height: 100vh;
  background-color: ${colorTheme.fill_3};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

`;
const TableBox = styled.div`
  .ant-table-thead > tr > th {
    background: var(--fill-fill-2, #adadad);
    color: white; /* White text */
  }
`;
