import Text from 'components/Text';
import paymentSelectors from 'features/payment/services/selectors';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
type IPrintHeaderProps = {};
const PrintHeader: React.FC<IPrintHeaderProps> = () => {
  const data = paymentSelectors.getData();
  if (!data) return <PrintHeaderStyled />;
  return (
    <PrintHeaderStyled>
      <Text variant="H5" color="text_3">
        #{data.ticketNumber}
      </Text>
      <Text variant="H6" color="text_3">
        {data.customerName}
      </Text>
      <Text variant="CONTENT_1" color="text_3">
        {moment(data.startTime).format('MM-DD-YYYY LT')}
      </Text>
    </PrintHeaderStyled>
  );
};

export default PrintHeader;
const PrintHeaderStyled = styled.div`
  padding: 1rem;
  height: 5rem;
  position: absolute;
  top: 0;
  left: 0;
`;
