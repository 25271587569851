import { CALENDAR_TIME_SET } from 'features/appointment/services/constants';
import moment from 'moment';
import React, { memo, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import CalendarContext from '../CalendarContext';
import CalendarViewBody from './Body';
import HeadContent from './HeadContent';
import CalendarViewHeader from './Header';
import LoadingCalendar from './Loading';
import useCalendarFilter from './hooks/useCalendarFilter';
import useCalendarMapping from './hooks/useCalendarMapping';
import CalendarStyled, { PIXEL_PER_MINUTE } from './styles';

const getTop = () => {
  const { close, open } = CALENDAR_TIME_SET;
  const now = moment();
  const currentSpaceMinutes = now.diff(open, 'minutes');
  let space = 0;
  if (now.isBetween(open, close))
    space = PIXEL_PER_MINUTE * currentSpaceMinutes;
  return space;
};

type ICalendarTableViewProps = any;
const CalendarTableView: React.FC<ICalendarTableViewProps> = () => {
  const calendarRef = useRef<HTMLTableElement>(null);
  const schema = useCalendarFilter();
  const body = useCalendarMapping(schema);

  const scrollTo = (top: number) => {
    setTimeout(() => calendarRef.current?.scrollTo({ top: top, behavior: 'smooth' }), 0);
  };

  useEffect(() => {
    scrollTo(getTop());
  }, []);

  return (
    <CalendarContext.Provider value={{ scrollTo }}>
      <HeadContent />
      <CalendarTableViewStyled id='calendar'>
        <CalendarTableOverflowStyled ref={calendarRef}>
          <div className="calendar">
            <CalendarStyled.Table typeView={schema.typeView} >
              <CalendarViewHeader headers={schema.headers} renderHeader={schema.renderHeader} />
              <CalendarViewBody rows={body.rows} rowsSpanInfo={body.rowsSpanInfo} distanceTimeline={schema.distanceTimeline} />
            </CalendarStyled.Table>
          </div>
        </CalendarTableOverflowStyled>
        <LoadingCalendar />
      </CalendarTableViewStyled>
    </CalendarContext.Provider>
  );
};

export default memo(CalendarTableView);

type CalendarTableViewStyledProps = {}
const CalendarTableViewStyled = styled.div<CalendarTableViewStyledProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
`;

const CalendarTableOverflowStyled = styled.div`
position: relative;
  max-height: 100%;
  height:100%;
  z-index: 1;
  max-width: 100%;
  overflow: scroll;
  .calendar {
    position: absolute;
    inset: 0;
  }
`;