import Icon, { typeIcon } from 'assets/Icons';
import Text from 'components/Text';
import React from 'react';
import styled, { css } from 'styled-components';
export const CARD_CSS_TYPE = {
  DEFAUL: 'DEFAUL',
  ACTIVE: 'ACTIVE',
} as const;

const CARD_CSS = {
  [CARD_CSS_TYPE.DEFAUL]: `
  border: 1px solid #86909c;
  background: #e5e6eb;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:hover {
    border: 1px solid #86909C;
    background: #ADADAD;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  `,
  [CARD_CSS_TYPE.ACTIVE]: `
  border: 1px solid #F5767F;
  background: #FFE5E7;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:hover {
    border: 1px solid #F5767F;
    background: #FF8890;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  `,
};

type CardBoxProps = {
  disabled?: boolean;
  type: keyof typeof CARD_CSS_TYPE;
  children?: React.ReactNode;
  onClick?: () => void;
  icon?: typeIcon;
};
type CardBoxStyledProps = {
  disabled?: boolean;
};

const CardBox: React.FC<CardBoxProps> = ({
  disabled,
  type,
  children,
  onClick,
  icon,
}) => {
  return (
    <CardBoxStyled onClick={onClick} className={`prevent-select ${type}`} disabled={disabled}>
      {icon ? <Icon type={icon} /> : undefined}
      <Text variant='H7' color='text_3'>{children}</Text>
    </CardBoxStyled>
  );
};

export default CardBox;

const cardBoxStyledCSS = css<CardBoxStyledProps>`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  width: 99%;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
`;

const CardBoxStyled = styled.div.withConfig({
  shouldForwardProp: (prop) => !['disabled', 'type'].includes(prop),
})<CardBoxStyledProps>`
  ${cardBoxStyledCSS}
  ${Object.entries(CARD_CSS).map(
    ([key, css]) => `
      &.${key} {
        ${css}
      }
    `
  )}
`;
