
import { AxiosResponse } from 'axios';
import { ITicketDetails } from 'features/cashier/services/types/api';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import uiActions from 'services/UI/actions';
import { setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import toast from 'utils/toast';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import {
  IConfirmCheckInParams,
  ISignUpForm
} from './types/api';
import { ICheckInConfirmResData } from './types/checkIn';
import { IAppointmentCustomerDetail, ICustomerSignInResData } from './types/customer';
import {
  ICheckedInRes,
  ISignUpRes
} from './types/demoData';

const checkIn: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: true }));
  yield delay(200);
  try {
    const resData: AxiosResponse<ICheckedInRes> = yield call(
      apis.checkIn,
      payload
    );
    if (resData && resData.status == 200) {
      yield put(actions.checkIn.success(resData.data));
    }
  } catch (error) {
    yield put(actions.checkIn.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({ path: PATH_LOADING.getData, result: false })
    );
  }
};

const signUp: ISagaFunc<ISignUpForm> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: ICustomerSignInResData }> = yield call(
      apis.customerSignUp,
      payload
    );
    if (resData && resData.status == 200) {
      const success: ISignUpRes = {
        status: resData.status,
        message: resData.statusText,
        pageable: null,
        data: resData.data.data
      };
      yield put(actions.signUp.success(success));
    } else {
      toast.error('Customer phone number already exists');
    }
  } catch (error) {
    const failSignUpRes: ISignUpRes = {
      status: 500,
      message: 'Fail',
      pageable: null,
      data: null,
    };
    yield put(actions.signUp.fail(failSignUpRes));
  } finally {
    yield setLoadingPage(false);
  }
};

const confirmWalkIn: ISagaFunc<IConfirmCheckInParams> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(200);
  try {
    const resData: IResponseDataBody<ICheckInConfirmResData> = yield call(
      apis.confirmWalkIn,
      payload
    );
    if (resData && resData.data) {
      const data = resData.data.data;
      if (data.id) {
        const detailRes: IResponseDataBody<ITicketDetails> = yield call(apis.getCheckInDetail, data.id);
        if (detailRes.data.data) {
          const detailTicket = detailRes.data.data;
          const appointmentData: IAppointmentCustomerDetail = {
            customerId: detailTicket.customerInfo?.customerId || '',
            customerName: detailTicket.customerInfo?.customerName || '',
            point: +(detailTicket.customerInfo?.point ?? ''),
            // @ts-ignore
            items: detailTicket.items.map(o => ({
              bookingTime: null,
              services: o.services,
              staffId: o.staffId,
              staffName: o.staffName,
              tip: 0,
            })),
            ticketNumber: detailTicket.ticketInfo.ticketNumber,
            qrCodeImage: '',
            startTime: detailTicket.ticketInfo.createdDate,
          };
          yield put(actions.getAppointmentDetail.success(appointmentData));
        }
      }
      yield put(actions.confirmWalkIn.success(resData.data.data));

    }
  } catch (error) {
    yield put(actions.confirmWalkIn.fail(false));
  } finally {
    yield setLoadingPage(false);
  }
};

const getAppointment: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: true }));
  yield delay(200);
  try {
    const resData: IResponseDataBody<IAppointmentCustomerDetail> = yield call(
      apis.getAppointmentDetail,
      payload
    );
    if (resData && resData.data) {
      yield put(actions.getAppointmentDetail.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getAppointmentDetail.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({ path: PATH_LOADING.getData, result: false })
    );
  }
};

export default function* checkInServiceSagas() {
  yield takeLatest(actions.checkIn.fetch, checkIn);
  yield takeLatest(actions.signUp.fetch, signUp);
  yield takeLatest(actions.getAppointmentDetail.fetch, getAppointment);
  yield takeLatest(actions.confirmWalkIn.fetch, confirmWalkIn);
}
