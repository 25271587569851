/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR FEATURE NAME HERE
 * ex:
 *  NAME_REDUCER = 'NAME_REDUCER'
 *   => 
 *  NAME_REDUCER = 'booking'
 */
export const NAME_REDUCER = 'giftCards';

/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR PREFIX ACTION NAME HERE
 * ex:
 *  PREFIX_ACTIONS = 'PREFIX_ACTIONS';
 *   => 
 *  PREFIX_ACTIONS = 'booking_feature_';
 */
export const PREFIX_ACTIONS = 'giftCards_feature_';


export const PATH_LOADING = {
  getListGiftCard: `loading.${NAME_REDUCER}.getListGiftCard`,
  getGiftCardDetail: `loading.${NAME_REDUCER}.getGiftCardDetail`,
  resetGiftCardDetail: `loading.${NAME_REDUCER}.resetGiftCardDetail`,
};
