import { Col, Form, Input, InputRef, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IEditCateBodyType } from 'features/settingService/services/types/api';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import {
  ICategoryItemData,
  IServiceItemData,
} from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import AddServiceButton from './AddServiceButton';
interface FormValidationFailure {
  errorFields: {
    name: string[];
    errors: string[];
  }[];
  outOfDate: boolean;
}
type IAddServiceCatesButtonProps = {};
const AddServiceCatesButton: React.FC<IAddServiceCatesButtonProps> = () => {
  const dispatch = useAppDispatch();
  const cateSelected = settingServiceSelectors.getCateSelected();
  const form = Form.useFormInstance<ICategoryItemData>();
  const [open, setOpen] = useState(false);
  const supplyFee = Form.useWatch('supplyFee', form);
  const sub_catagories = Form.useWatch('sub_catagories', form);
  const data = form.getFieldsValue();
  const inputRef = useRef<InputRef>(null);
  const [errorIndex, setErrorIndex] = useState<string[]>([]);

  const clearErrorByIndex = (index: string) => {
    const newErrors = errorIndex.filter(
      (item) => String(item) !== String(index)
    );
    setErrorIndex(newErrors);
  };

  const handleShowModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields(['new_services', 'sub_catagories']);
  };

  const handleSubmitModal = async () => {
    try {
      const values = await form.validateFields();
      const body: IEditCateBodyType = {
        categoryId: cateSelected.id,
        cateName: values.categoryName,
        cateType: 'SERVICE',
        supplyFee: values.supplyFee || 0,
        orderNumber: values.orderNumber || 0,
        services:
          values.new_services?.map((item) => ({
            serviceId: item.id || '',
            serviceName: item.serviceName || '',
            priceSell: Number(item.priceSell) || 0,
            duration: Number(item.duration) || 0,
            commission: Number(item.commission) || 0,
            supplyFee: Number(item.supplyFee) || 0,
            orderNumber: Number(item.orderNumber) || 0,
            categoryId: cateSelected.id,
            addOn: false,
          })) || [],
      };
      dispatch(settingServiceActions.addMoreServiceByCate.fetch(body));
      form.resetFields(['new_services', 'sub_catagories']);
      setOpen(false);
    } catch (errorInfo) {
      const formError: FormValidationFailure =
        errorInfo as FormValidationFailure;
      const errorsFilter = formError?.errorFields?.filter(
        (item) => item.name[0] === 'new_services'
      );
      const errorsIndex = errorsFilter.map((item) => item.name[1]);
      const finalErrorIndex = errorsIndex.filter(
        (num, index, self) => self.indexOf(num) === index
      );
      setErrorIndex(finalErrorIndex);
    }
  };

  useEffect(() => {
    if (sub_catagories < 100 && sub_catagories > 0) {
      const newService: IServiceItemData[] = Array.from({
        length: sub_catagories,
      }).map((_item, index) => ({
        orderNumber: index + 1,
        id: '',
        catId: '',
        serviceName: '',
        duration: 0,
        priceSell: 0,
        priceCost: 0,
        commission: 0,
        supplyFee: supplyFee || 0,
      }));

      form.setFieldsValue({ new_services: newService });
    } else {
      form.setFieldsValue({ new_services: [] });
    }
  }, [sub_catagories]);
  const focusInput = debounce(() => {
    inputRef.current?.focus();
  }, 100);

  useEffect(() => {
    if (open && inputRef.current) {
      focusInput();
    }
  }, [open, inputRef]);

  return (
    <AddServiceCatesButtonStyled>
      <div className="button-item" onClick={handleShowModal}>
        <Icon type="addPlus" />
      </div>
      <Modal
        width={'62rem'}
        modalTitle="ADD MORE SERVICES"
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        destroyOnClose={true}
        okTitle="Save"
        cancelTitle="Close"
      >
        <Form.Item name="cateType" hidden>
          <Input />
        </Form.Item>
        <Box p="4">
          <Row gutter={[24, 24]}>
            <Col span={8}>
              <Text color="text_3" variant="CONTENT_1">
                Catagory Name
              </Text>
              <Text color="text_3" variant="H9">
                {data.categoryName}
              </Text>
            </Col>

            <Col span={8}>
              <Text color="text_3" variant="CONTENT_1">
                Supply Fee
              </Text>
              <Text color="text_3" variant="H9">
                {data.supplyFee || 0}
              </Text>
            </Col>
            <Col span={8}>
              <Text color="text_3" variant="CONTENT_1">
                Sub-Catagories Available
              </Text>
              <Text color="text_3" variant="H9">
                {data.services?.length || 0}
              </Text>
            </Col>

            <Col span={24}>
              <Box className="center">
                <Box
                  display="flex"
                  flexDirection="column"
                  gap="2"
                  mb="3"
                  width={'50%'}
                >
                  <Text color="text_3" variant="CONTENT_1">
                    Add More Sub-Catagories
                  </Text>
                  <FormItemNoMargin name="sub_catagories">
                    <Input type={'number'} ref={inputRef} />
                  </FormItemNoMargin>
                </Box>
              </Box>
            </Col>
          </Row>
          <Form.List name="new_services">
            {(fields) => {
              return (
                <Row gutter={[12, 12]}>
                  {fields?.map(({ key, name, ...restField }, index) => {
                    const isHaveError = errorIndex.some(
                      (item) => Number(item) === index
                    );
                    return (
                      <Col span={6} key={key}>
                        <AddServiceButton
                          name={name}
                          nameField="new_services"
                          restField={restField}
                          index={index}
                          clearErrorByIndex={clearErrorByIndex}
                        />
                        {isHaveError && (
                          <Text variant="CAPTION_1" color="error_4">
                            Check Validate
                          </Text>
                        )}
                      </Col>
                    );
                  })}
                </Row>
              );
            }}
          </Form.List>
        </Box>
      </Modal>
    </AddServiceCatesButtonStyled>
  );
};

export default AddServiceCatesButton;
const AddServiceCatesButtonStyled = styled.div`
  .button-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 0.5rem;
    border-left: 1px solid ${colorTheme.line_2};
    height: 100%;
  }
`;
const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
`;
