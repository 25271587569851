import { ISalaryInfo } from './types/staffSalary';

export const NAME_REDUCER = 'setting_staff';

export const PREFIX_ACTIONS = 'setting_staff_feature_';

export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
  getStaffList: `loading.${NAME_REDUCER}.getStaffList`,
  getStaffDetails: `loading.${NAME_REDUCER}.getStaffDetails`,
};
export enum SalaryPayrollTipOption {
  NONE = 'NONE',
  ADD_TO_CHECK = 'ADD_TO_CHECK',
  ADD_TO_CASH = 'ADD_TO_CASH',
  ADD_TO_CHECK_SUBTRACT_FROM_CASH = 'ADD_TO_CHECK_SUBTRACT_FROM_CASH',
  BY_DEFINED_PERCENTAGE = 'BY_DEFINED_PERCENTAGE',
}

export enum STAFF_POSITION {
  ADMIN = 'ADMIN',
  OTHER = 'OTHER',
  TECHNICAL = 'TECHNICAL',
  CASHIER = 'CASHIER',
}
export enum STAFF_POSITION_INFO {
  ADMIN = 'ADMIN',
  TECHNICAL = 'TECHNICAL',
  CASHIER = 'CASHIER',
  MANAGER = 'MANAGER',
}

export const enum ESalaryMode {
  COMMISSION = 'COMMISSION',
  HOURS = 'HOURS',
  COMMISSION_ACCELERATE = 'COMMISSION_ACCELERATE',
}
export const enum CALENDAR_PICKER_TYPE {
  DATE = 'date',
  WEEK = 'week',
  MONTH = 'month',
}

export const initSalaryInfo: ISalaryInfo = {
  salaryMode: ESalaryMode.COMMISSION,
  commission_accelerate: [{ from: '', to: '', commission: '' }],
  commission: '',
  cash: '',
  check: '',
  specialFee: '',
  selectedTipMethod: SalaryPayrollTipOption.NONE,
  tipFee: '',
  payrollCash: '',
  payrollCheck: '',
};

const capitalizeFirstLetter = (str: string): string => 
    str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();


export const positionsArray = Object.entries(STAFF_POSITION_INFO).map(
  ([key, value]) => ({
    label: capitalizeFirstLetter(value),
    value: key,
  })
);
