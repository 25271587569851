
export const NAME_REDUCER = 'shop';

export const PREFIX_ACTIONS = 'shop_feature_';

export const PATH_LOADING = {
  getDetail: `loading.${NAME_REDUCER}.getDetail`,
  getCategories: `loading.${NAME_REDUCER}.getCategories`,
  getProductCategories: `loading.${NAME_REDUCER}.getProductCategories`,
  getProductsVIP: `loading.${NAME_REDUCER}.getProductsVIP`,
  getAddOnList: `loading.${NAME_REDUCER}.getAddOnList`,
  getStaffList: `loading.${NAME_REDUCER}.getStaffList`,
  getStaffAvailableList: `loading.${NAME_REDUCER}.getStaffAvailableList`,
  getCustomerList: `loading.${NAME_REDUCER}.getCustomerList`,
  getRecentCustomerList: `loading.${NAME_REDUCER}.getRecentCustomerList`,
  getScheduleBooking: `loading.${NAME_REDUCER}.getScheduleBooking`,
};

export enum SHOP_DEVICE_TYPE {
  CHECK_IN = 'CHECK_IN',
  POS = 'POS',
}
export enum CUSTOMER_TARGET_TYPE {
  VIP = 'VIP',
  LOYAL = 'LOYAL',
  NORMAL = 'NORMAL',
}