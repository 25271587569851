const CheckMask = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29.5539 2.50651C29.9073 2.3246 30.3311 2.41699 30.5739 2.73222C29.2825 4.03032 27.9016 5.23699 26.6758 6.6008C23.9673 9.40937 21.5663 12.5132 19.5035 15.8237C18.8806 17.0141 18.0854 18.1027 17.4549 19.2884C16.7235 20.5151 15.9673 21.7265 15.2158 22.9408C15.1016 23.2094 14.7758 23.1227 14.5482 23.1103C14.2654 22.7494 14.073 22.3303 13.8463 21.9351C13.3854 20.9684 12.8797 20.0189 12.5282 19.0056C12.213 18.057 11.7416 17.1713 11.2854 16.2856C10.9473 15.6322 10.492 15.0132 9.85965 14.6189C9.46251 14.3617 8.97203 14.4294 8.52441 14.4322C8.6387 13.6113 9.31394 13.0008 10.0482 12.6979C10.5558 12.4589 11.1358 12.4884 11.6797 12.5417C12.7254 12.7903 13.5063 13.6122 14.1216 14.4465C14.4635 14.9332 14.7987 15.4275 15.0711 15.957C15.1187 15.957 15.2139 15.9589 15.2606 15.9589C16.0425 14.7598 16.9492 13.6475 17.7977 12.4951C18.6625 11.3913 19.573 10.3237 20.5206 9.29032C21.3616 8.42079 22.1473 7.49318 23.0663 6.70175C24.9425 4.91413 27.1768 3.52365 29.5539 2.50651Z"
        fill="#FF8890"
      />
      <path
        d="M5.12171 7.2307C7.73219 4.49261 11.5398 2.95642 15.317 3.09451C18.0293 3.20975 20.7122 4.14404 22.8846 5.77737C22.9817 5.93356 22.7598 6.00594 22.6789 6.10594C22.2827 6.40118 21.9874 6.81356 21.597 7.1088C21.3884 7.02785 21.215 6.87928 21.0236 6.76689C18.0808 4.88213 14.2684 4.44689 10.9798 5.62975C8.33409 6.54499 6.07124 8.4707 4.68743 10.8964C3.41981 13.1383 2.89981 15.8107 3.31219 18.3574C3.68362 20.904 4.96171 23.2936 6.81981 25.0669C8.96076 27.0859 11.8846 28.3117 14.8417 28.245C17.5474 28.3012 20.2303 27.2669 22.297 25.5383C24.335 23.8336 25.7693 21.4155 26.2579 18.8012C26.4989 17.7336 26.435 16.6307 26.4131 15.545C26.2541 13.6059 25.5408 11.7421 24.4979 10.1098C24.8465 9.58118 25.2998 9.13261 25.7217 8.66308C26.9703 10.3498 27.8274 12.3288 28.1503 14.405C28.7369 17.8098 27.9293 21.4202 25.9627 24.2602C24.3722 26.5783 22.0446 28.3869 19.397 29.3383C16.3579 30.4497 12.9208 30.3955 9.91028 29.2136C8.26457 28.544 6.71409 27.5974 5.45695 26.3336C3.6579 24.6345 2.37028 22.4078 1.74552 20.0174C1.30552 18.4259 1.27409 16.7564 1.39314 15.1202C1.71695 12.1745 3.04267 9.34499 5.12171 7.2307Z"
        fill="#FF8890"
      />
    </svg>
  );
};

export default CheckMask;
