import React, { CSSProperties } from 'react';
import { Col, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import IconAndPrice, { IIconAndPriceProps } from './IconAndPrice';
export interface IListTotalProcessReport {
  totalSaleService: number
  totalSaleProduct: number
  totalSaleVIP: number
  totalSaleGiftCard: number
  totalCash: number
  totalCreditCard: number
  totalCheckOther: number
  totalGiftCard: number
  totalRewardPoint: number
}

const labelStyle: CSSProperties = {
  fontSize: '1.5rem',
};

type IProcessReportProps = {
  list: IIconAndPriceProps[];
  listTotal: IListTotalProcessReport;
};
const ProcessReport: React.FC<IProcessReportProps> = ({ list, listTotal }) => {
  return (
    <ContainerStyled>
      <ProcessReportStyled>
        <Row gutter={[24, 24]}>
          {list.map((item, index) => (
            <Col
              key={item.title + index}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 8 }}
              xl={{ span: 6 }}
              xxl={{ span: 6 }}
            >
              <div className="item-ticket-price">
                <IconAndPrice
                  progress={item.progress}
                  price={item.price}
                  title={item.title}
                  labelStyle={labelStyle}
                />
              </div>
            </Col>
          ))}
        </Row>
      </ProcessReportStyled>

      <TotalBox>
        <Box justifyContent='center' alignItems='center'>
          <Text variant="H7" color="text_3">
            REDEEMED LOYALTY POINTS:
          </Text>
          <TextPrice variant="H9" color="text_3" isIncrease={listTotal?.totalRewardPoint >= 0}>
            {formatCurrency(listTotal?.totalRewardPoint, true)}
          </TextPrice>
        </Box>

        <Box justifyContent='center' alignItems='center'>
          <Text variant="H7" color="text_3">
            Redeemed Gift Cards:
          </Text>
          <TextPrice variant="H9" color="text_3" isIncrease={listTotal?.totalGiftCard >= 0}>
            {formatCurrency(listTotal?.totalGiftCard, true)}
          </TextPrice>
        </Box>
        <Box justifyContent='center' alignItems='center'>
          <Text variant="H7" color="text_3">
            Check / Other(s):
          </Text>
          <TextPrice variant="H9" color="text_3" isIncrease={listTotal?.totalCheckOther >= 0}>
            {formatCurrency(listTotal?.totalCheckOther, true)}
          </TextPrice>
        </Box>

        <Box justifyContent='center' alignItems='center'>
          <Text variant="H7" color="text_3">
            Credit Cards:
          </Text>
          <TextPrice variant="H9" color="text_3" isIncrease={listTotal?.totalCreditCard >= 0}>
            {formatCurrency(listTotal?.totalCreditCard, true)}
          </TextPrice>
        </Box>

        <Box justifyContent='center' alignItems='center' style={{ minWidth: '100px' }}>
          <Text variant="H7" color="text_3">
            Cash:
          </Text>
          <TextPrice variant="H9" color="text_3" isIncrease={listTotal?.totalCash >= 0}>
            {formatCurrency(listTotal?.totalCash, true)}
          </TextPrice>
        </Box>
      </TotalBox>
    </ContainerStyled>
  );
};

export default ProcessReport;
const ContainerStyled = styled.div`
  overflow: auto;
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: ${colorTheme.fill_5};
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
const ProcessReportStyled = styled.div`
  // display: flex;
  gap: 1rem;
  padding: 1rem;
  .item-ticket-price {
    border-right: 1px solid #c9cdd4;
  }
  .item-ticket-price:last-child {
    border-right: none;
  }
`;
const TotalBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid ${colorTheme.line_3};
  gap: 1.5rem;
  flex-wrap: wrap;
`;

const TextPrice = styled(Text) <{ isIncrease?: boolean; }>`
  color: ${props => props?.isIncrease ? '#6FABB6' : '#F53F3F'};
`;
