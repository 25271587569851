import { Avatar, Col, Input, Row } from 'antd';
import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import InputPeriod, { PeriodValues } from 'features/appointment/components/InputPeriod';
import appointmentActions from 'features/appointment/services/actions';
import appointmentApis from 'features/appointment/services/apis';
import { IBodyApiMakeAppointment } from 'features/appointment/services/types/api';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import { get } from 'lodash';
import { Moment } from 'moment';
import React, { memo, useCallback, useMemo, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { IServiceItemData } from 'services/shop/types/categories';
import { ICustomerItemData } from 'services/shop/types/customer';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { maskPhone } from 'widgets/Customer/CustomerSearchInput';
import { CustomerIcon, ListIcon, MakeNoteIcon, PeriodTimeIcon, StaffIcon } from '../Icons';
import { ButtonActions } from './helpers';
import { APPOINTMENT_SOURCE_TYPE } from 'features/appointment/services/constants';
const okTitle = (
  <Row align={'middle'} justify={'center'} className='prevent-select'>
    <Icon type='addPlus' />
    <Text variant='CONTENT_2' color='text_3' ml={0.5} >Add New Appointment</Text>
  </Row>
);

type INewAppointmentProps = {
  visible?: boolean;
  onClose: () => void;
  timeStart: Moment;
  staff: IStaffItemData;
  customer: ICustomerItemData;
  services: IServiceItemData[];
  onDone: () => void;
};
const NewAppointmentConfirm: React.FC<INewAppointmentProps> = ({ services, timeStart, staff, customer, onClose, visible, onDone }) => {
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const [note, setNote] = useState('');
  const [repeat, setRepeat] = useState(PeriodValues.NO_REPEAT);

  const handleClose = () => {
    onClose();
  };
  const timeEnd = useMemo(() => {
    const time = timeStart?.clone();
    if (services.length === 0) {
      time?.add(15, 'minute');
    } else {
      services.forEach(o => {
        time?.add(o.duration, 'minute');
      });
    }
    return time;
  }, [timeStart, services]);

  const AppointmentTime = useCallback(() => {
    return (
      <div className='row-item row-time'>
        <Icon type='time' />
        <div className="row-grow" style={{ alignItems: 'center' }}>
          <Text className='text-lg' variant='H8' color='text_3' mr={2}>{timeStart.format('MM-DD-YYYY')}</Text>
          <Text className='text-lg' variant='H8' color='text_3'>{timeStart.format(FORMAT_TIME)}</Text>
          <Text className='text-lg' variant='H8' color='text_3' mx={1}>-</Text>
          <Text className='text-lg' variant='H8' color='text_3'>{timeEnd?.format(FORMAT_TIME)}</Text>
        </div>
      </div>
    );
  }, [timeStart, services, staff]);

  const StaffInfo = useCallback(() => {
    const staffName = staff?.firstName + ' ' + staff?.lastName;
    return (
      <div className="row-item">
        <StaffIcon />
        <div className="row-grow">
          <div className="row-staff">
            <Avatar size={'default'}>{get(staffName, [0], '')}</Avatar>
            <Text variant='H9' color='text_3' className='text-lg'>{staffName}</Text>
          </div>
        </div>
      </div>
    );
  }, [staff]);


  const onSubmit = async () => {
    if (!customer || !timeStart) return;
    setLoadingPage(true);
    const payload: IBodyApiMakeAppointment = {
      bookingType: APPOINTMENT_SOURCE_TYPE.POS,
      customerId: customer?.id || '',
      serviceId: services.map(o => o.id),
      staffId: staff ? staff.id : '',
      startTime: timeStart?.format('MM/DD/YYYY HH:mm:ss'),
      repeat,
      note,
    };
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.makeNewAppointment(payload);
      if (res?.data?.data) {
        dispatch(appointmentActions.setParams());
        dispatch(appointmentActions.setTableParams());
        onDone();
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return (
    <>
      <Modal
        modalTitle="NEW APPOINTMENT"
        visible={visible}
        onClose={handleClose}
        cancelTitle='Back'
        noneBodyStyle
        noneFooterStyle
        containerPadding={1.5}
        headerBgColor={colorTheme.info_bg_3}
        okTitle={okTitle}
        onSubmit={onSubmit}
        width={'50vw'}
        footer={<></>}
        hiddenFooter
      >
        <>
          <NewAppointmentStyled>
            <AppointmentTime />

            <StaffInfo />

            <div className="row-item">
              <CustomerIcon />
              <div className="row-grow">
                <div className="w-100">
                  <Row align={'middle'}>
                    <Avatar size={'default'} src={customer?.imageAvatarUrl}>{get(customer?.name, [0], '')}</Avatar>
                    <Text variant='H9' color='text_3' ml={0.5} className='text-lg'>{customer?.name} - {maskPhone(customer?.phone || '')}</Text>
                  </Row>
                </div>
              </div>
            </div>
            <Text mb={1} />

            <div className="row-item">
              <PeriodTimeIcon />
              <div className="row-grow">
                <div className="w-100">
                  <InputPeriod value={repeat} onChange={setRepeat} />
                </div>
              </div>
            </div>
            <Text mb={1} />
            <div className="row-item">
              <MakeNoteIcon />
              <div className="row-grow">
                <div className="w-100">
                  <Input.TextArea
                    rows={3}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    placeholder='Note ...'
                  />
                </div>
              </div>
            </div>
            <Text mb={1} />

            <div className="row-item">
              <ListIcon />
            </div>
            <Text mb={0.5} />
            <div className="row-item">
              <div style={{ opacity: 0 }}><ListIcon /></div>
              <Row className='w-100' gutter={[50, 16]}>
                {services.map((o, i) => (
                  <Col span={12} key={i}>
                    <Row align={'middle'} justify={'space-between'} wrap={false}>
                      <Text variant='CONTENT_1' color='text_3' className='text-overflow text-lg' mr={1}>{o.serviceName}</Text>
                      <Text variant='CONTENT_1' color='text_3' className='text-lg' >({o.duration})m</Text>
                    </Row>
                  </Col>
                ))}
              </Row>
            </div>
          </NewAppointmentStyled>
          <ButtonActions confirm onBack={handleClose} onOk={onSubmit} />
        </>
      </Modal >
    </>
  );
};

export default memo(NewAppointmentConfirm);
type NewAppointmentStyledProps = {}
const NewAppointmentStyled = styled.div<NewAppointmentStyledProps>`
  display: flex;
  flex-direction: column;
  min-height: 40vh;

  .row-item {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
  .row-grow {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .row-time {
    margin-bottom: 0.5rem;
  }
  .row-staff {
    gap: 11px;
    display: flex;
    align-items: center;
    padding: 1rem 0;
  }
  .w-100 {
    width: 100%;
  }
  .ant-select {
    width: 100%;
  }
  .text-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-lg {
    font-size: 1.5rem;
  }
`;
