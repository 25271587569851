import { Form, Modal, Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadProps } from 'antd/lib';
import Icon from 'assets/Icons';
import { uniqueId } from 'lodash';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import venmo_Logo from './Venmo_Logo.png';
import qrCodeImg from './qrCodeImg.png';
import venmo from './venmo.png';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';

type IFormItemUploadImgProps = {
  isQr?: boolean;
  label?: string;
  item_name?: string;
  name: number;
  restField: {
    fieldKey?: number | undefined;
    // Add other properties of restField here if there are any
  };
};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface File extends UploadFile {}

const FormItemUploadImg: React.FC<IFormItemUploadImgProps> = ({
  isQr,
  label,
  item_name,
  name,
  restField,
}) => {
  const form = Form.useFormInstance();
  const srcForm = form.getFieldValue(['others', name, item_name || '']); // url String
  const [fileList, setFileList] = useState<File[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState<string>();
  const handleCancel = () => setPreviewOpen(false);

  const onChange: UploadProps['onChange'] = async ({
    fileList: newFileList,
  }) => {
    if (newFileList?.length > 0) {
      const base64Url = await getBase64(
        newFileList?.[0]?.originFileObj as RcFile
      );
      const convertBase64 = removeBase64Prefix(base64Url);
      form.setFieldsValue({
        others: {
          [name]: { [item_name || '']: convertBase64 },
        },
      });
    } else {
      form.setFieldsValue({
        others: {
          [name]: { [item_name || '']: null },
        },
      });
    }
    setFileList(newFileList);
  };

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1)
    );
  };

  useEffect(() => {
    if (fileList.length > 0) {
      // form.setFieldsValue({
      //   others: {
      //     [field]: { [name || '']: file },
      //   },
      // });
    }
  }, [fileList]);

  useEffect(() => {
    if (srcForm) {
      setFileList([
        {
          uid: uniqueId(),
          name: srcForm || 'image_preview',
          status: 'done',
          url: srcForm,
        },
      ]);
    }
  }, []);

  return (
    <WrapperBox>
      <Form.Item
        {...restField}
        style={{ margin: 0 }}
        label={label}
        name={[name, item_name || '']}
        valuePropName="fileList"
      >
        <Upload
          name="upload"
          fileList={fileList}
          listType="picture"
          maxCount={1}
          onChange={onChange}
          onPreview={handlePreview}
          beforeUpload={() => false}
        >
          {fileList && fileList.length > 0 ? null : (
            <div>
              <ImgBoxStyled>
                {isQr ? (
                  <>
                    <Icon type="qrCode" />
                    <Icon type="camera" />
                    <img src={qrCodeImg} alt="" />
                  </>
                ) : (
                  <>
                    <Icon type="logoImageUpload" />
                    <img src={venmo} alt="" />
                    <img src={venmo_Logo} alt="" />
                  </>
                )}
              </ImgBoxStyled>
            </div>
          )}
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Form.Item>
    </WrapperBox>
  );
};

export default FormItemUploadImg;

const ImgBoxStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    position: absolute;
  }
`;
const WrapperBox = styled.div`
  .ant-upload-list-item-name {
    display: none;
  }
`;
