import { InputNumber } from 'antd';
import { FormItem } from 'components/Form';
import { maskPercent, unmaskedPercent } from 'components/PercentInputMasked';
import styled from 'styled-components';

const PercentInput = ({
  value, onChange, label,
}: { label: string, value: any, onChange: (val: any) => void }) => {
  return (
    <InputNumberStyled label={label}>
      <InputNumber
        defaultValue={100}
        min={0}
        // max={100}
        value={value}
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        formatter={(value) => maskPercent(value)}
        parser={(value: any) => unmaskedPercent(value)}
        onChange={onChange}
      />
    </InputNumberStyled>
  );
};

export default PercentInput;

const InputNumberStyled = styled(FormItem)`
display: flex;
flex-direction: column;
align-items: center;
.ant-input-number {
  width: 100%;background: var(--fill-fill-0, #FFF);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-color: #86909C;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input-wrap {
  height: 100%;
  input {
    height: 100%;
    color: var(--text-text-3, #1D2129);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &:focus, &:hover, &:focus-visible {
      border:none;
      outline: none;
    }
  }
}
.label {
  color: var(--text-text-3, #1D2129);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 1.5rem;
}
`;