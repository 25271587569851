import React, { useCallback } from 'react';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { IServiceItem } from 'features/settingStaff/services/types/api';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type IRightSpecializeStaffProps = {};
const RightSpecializeStaff: React.FC<IRightSpecializeStaffProps> = () => {
  const dispatch = useAppDispatch();
  const servicesSelected = settingStaffSelectors.servicesSelected();

  const handleClickService = useCallback((serviceItem: IServiceItem) => {
    dispatch(settingStaffActions.clickService(serviceItem));
  }, []);

  return (
    <RightSpecializeStaffStyled>
      <Box px="4">
        <Text variant="H6" color="text_3">
          Selected Services
        </Text>
      </Box>
      <Box
        mt="2"
        display="flex"
        flexDirection="column"
        gap="3"
        height={'28rem'}
        overflow="auto"
        px="4"
      >
        {servicesSelected.map((item) => (
          <Box key={item.serviceId} className="space-between">
            <Text variant="H9" color="text_3">
              {item.serviceName}
            </Text>
            <IconCloseButton onClick={() => handleClickService(item)}>
              <Icon type="circleClose" />
            </IconCloseButton>
          </Box>
        ))}
      </Box>
    </RightSpecializeStaffStyled>
  );
};

export default RightSpecializeStaff;
const RightSpecializeStaffStyled = styled.div`
  width: 21rem;
  border-radius: 0px 5px 5px 0px;
  background: #e5e5e5;
  padding: 1.5rem 0;
`;
const IconCloseButton = styled.div`
  cursor: pointer;
`;
