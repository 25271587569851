import Text from 'components/Text';
import { IShopItem } from 'features/auth/services/types/owner';
import React from 'react';
import styled from 'styled-components';
type IShopItemProps = {
  data: IShopItem;
  onClick:()=>void;
};
const ShopItem: React.FC<IShopItemProps> = ({ data , onClick}) => {
  return (
    <ShopItemStyled onClick={onClick}>
      <Text variant='H9' color='text_3' mb={1.5}>{data.shopName}</Text>
      <Text variant='BODY_1' color='text_2' mb={1}>{data.email}</Text>
      <Text variant='BODY_1' color='text_2'>{data.address}</Text>
    </ShopItemStyled>
  );
};

export default ShopItem;
type ShopItemStyledProps = {}
const ShopItemStyled = styled.button<ShopItemStyledProps>`
  width:100%;
  max-height: 12.75rem;
  height: 25vh;
  min-height:10vh;
  border-radius: 16px;
  background: #ffffff;
  border: 1px solid #86909C;
  &:hover {
    border: 1px solid #86909C !important;
    background-color: #E5E6EB !important;
  }
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
