import React from 'react';
type Props = {
  value?: number;
};
export const Number = ({ value = 0 }: Props) => {
  const result = String(value).padStart(2, '0');
  return (
    <div className='digital'>
      <p>88</p>
      <p>{result}</p>
    </div>
  );
};
