import { Form } from 'antd';
import Button from 'components/Button';
import React from 'react';
import styled from 'styled-components';
type ISubmitButtonProps = {};
const SubmitButton: React.FC<ISubmitButtonProps> = () => {
  const form = Form.useFormInstance();
  const [submittable, setSubmittable] = React.useState(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  return (
    <SubmitButtonStyled>
      <Button ntype="PRIMARY" htmlType="submit" block disabled={!submittable}>
        Submit
      </Button>
    </SubmitButtonStyled>
  );
};

export default SubmitButton;
const SubmitButtonStyled = styled.div``;
