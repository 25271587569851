const DeleteIconCashier = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        height: 24,
      }}
    >
      <path
        d="M15.4594 0.0883777C16.4715 -0.158767 17.6185 0.12216 18.3265 0.809364C18.7515 1.18808 18.967 1.69837 19.107 2.20689C20.2339 1.95085 21.3527 1.66815 22.4746 1.39789C23.5068 1.12318 24.6699 1.18808 25.6186 1.65392C26.6347 2.11888 27.3708 2.99722 27.6337 3.97602C27.7475 4.41696 28.0355 4.90414 27.7424 5.33887C27.5179 5.72825 26.9942 5.80204 26.5632 5.90606C19.6145 7.58451 12.6669 9.2674 5.72022 10.9494C5.1885 11.1094 4.55003 10.8338 4.40602 10.3475C4.22676 9.7768 3.99414 9.20517 4.00118 8.60509C3.97198 7.5934 4.48457 6.58615 5.34661 5.92117C5.87329 5.49533 6.54298 5.23308 7.23181 5.08639C8.31943 4.8268 9.40503 4.56365 10.4906 4.29873C10.3023 3.73243 10.1905 3.12434 10.4121 2.55093C10.6779 1.73838 11.4352 1.07162 12.3577 0.83959C13.3909 0.585334 14.4252 0.338189 15.4594 0.0883777ZM12.8693 2.61583C12.3164 2.78741 12.2681 3.38571 12.4816 3.79821C14.0324 3.44528 15.5742 3.05945 17.118 2.68162C17.0335 2.46826 17.0002 2.22467 16.8149 2.05487C16.6055 1.84684 16.26 1.76861 15.9619 1.85928C14.9307 2.10998 13.8935 2.34379 12.8693 2.61583ZM7.62557 6.88308C7.12204 6.98353 6.66081 7.24668 6.37883 7.63162C6.06564 8.01656 6.05255 8.50463 6.09787 8.95802C12.5954 7.40493 19.0838 5.81893 25.5773 4.25161C25.2883 3.39905 24.1855 2.85231 23.2137 3.1119C18.0204 4.37607 12.825 5.63757 7.62557 6.88308Z"
        fill="#F5767F"
      />
      <path
        d="M6.39713 11.7302C6.51898 11.7071 6.64184 11.6956 6.76672 11.6965C13.4505 11.6965 20.1353 11.6973 26.8191 11.6956C27.1887 11.6893 27.5916 11.796 27.8101 12.077C28.0055 12.3028 28.0185 12.5997 27.9793 12.87C27.2935 17.7586 26.6147 22.6482 25.9309 27.5368C25.802 28.3147 25.799 29.1308 25.3922 29.8491C24.7356 31.1035 23.2441 31.9561 21.6812 31.9917C18.5925 32.005 15.5029 31.9925 12.4133 31.9979C11.7093 32.0121 10.9923 31.9321 10.3448 31.6778C9.01245 31.1764 8.02755 30.0323 7.83923 28.7646C7.11617 23.6172 6.40519 18.4698 5.68112 13.3233C5.64285 12.9535 5.50186 12.5535 5.70428 12.2023C5.83016 11.9569 6.10307 11.7854 6.39713 11.7302ZM7.79895 13.5314C8.48173 18.5249 9.18868 23.5158 9.88355 28.5085C10.0114 29.4322 10.9802 30.179 12.0346 30.1701C15.2068 30.1754 18.38 30.1745 21.5523 30.1701C22.6419 30.1852 23.6389 29.3913 23.7255 28.4303C24.4133 23.4634 25.1233 18.4983 25.797 13.5296C19.797 13.5323 13.798 13.5287 7.79895 13.5314Z"
        fill="#F5767F"
      />
      <path
        d="M11.3486 15.2121C11.6537 15.1356 12.0052 15.2414 12.1835 15.4752C12.3698 15.6993 12.3406 15.9917 12.3688 16.254C12.685 19.8083 13.0072 23.3616 13.3224 26.9159C13.3456 27.1817 13.3909 27.4484 13.3667 27.716C13.3013 28.1161 12.7686 28.3952 12.3416 28.2361C12.0072 28.1454 11.8249 27.8342 11.8179 27.5409C11.4815 23.6852 11.1301 19.8296 10.7847 15.974C10.7313 15.6415 10.975 15.2939 11.3486 15.2121Z"
        fill="#F5767F"
      />
      <path
        d="M16.5859 15.2175C17.0652 15.0788 17.609 15.4415 17.5758 15.8896C17.5778 19.7754 17.5758 23.6604 17.5768 27.5463C17.6171 27.9472 17.2042 28.3197 16.747 28.2797C16.3281 28.261 15.9987 27.9116 16.0199 27.5471C16.0169 23.6906 16.0209 19.8332 16.0179 15.9767C15.9907 15.6495 16.2112 15.3019 16.5859 15.2175Z"
        fill="#F5767F"
      />
      <path
        d="M21.9124 15.2001C22.3726 15.1157 22.851 15.4677 22.8228 15.8855C22.4955 19.6496 22.1471 23.4128 21.8157 27.1777C21.7926 27.4213 21.8027 27.6774 21.6899 27.9058C21.4915 28.2846 20.8621 28.4108 20.5106 28.1228C20.281 27.9654 20.1924 27.6996 20.2317 27.4516C20.562 23.8039 20.8832 20.1555 21.2155 16.5078C21.2437 16.2296 21.2286 15.9451 21.3092 15.6731C21.3857 15.4321 21.6325 15.2392 21.9124 15.2001Z"
        fill="#F5767F"
      />
    </svg>
  );
};

export default DeleteIconCashier;
