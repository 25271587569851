import { Row } from 'antd';
import Text from 'components/Text';
import { APPOINTMENT_LAYOUT } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import styled from 'styled-components';
import CalendarFilter from '../Calendar/Filter';
import LayoutButton from './LayoutButton';
import TableFilter from '../Calendar/TableView/Filter';

const AppointmentFilter = () => {
  const layout = appointmentSelectors.getAppointmentLayout();
  return (
    <CalendarFilterStyled align={'middle'}>
      <Text variant='H5' color='text_3'>Appointments</Text>
      <div className="space" />
      <LayoutButton />
      {layout === APPOINTMENT_LAYOUT.CALENDAR ? <CalendarFilter /> : <TableFilter />}
    </CalendarFilterStyled>
  );
};

export default AppointmentFilter;

type CalendarFilterStyledProps = {}
const CalendarFilterStyled = styled(Row) <CalendarFilterStyledProps>`
  .space {
    flex: 1;
  }
  gap: 12px;
`;
