const SendSms = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 1.88281L23.2361 12.0008L3 22.1189V13.9862L5.77954 12.0008L3 10.0155V1.88281ZM7.82046 13.0008L5 15.0155V18.8828L16.7639 13.0008H7.82046ZM16.7639 11.0008H7.82047L5 8.98623V5.11888L16.7639 11.0008Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default SendSms;
