import { PATH_LOADING as SHOP_PATH_LOADING } from './../../../services/shop/constants';
import {
  IPaymentMethodParams,
  ISpecialDiscount,
  ITaxParams,
} from 'features/settingService/services/types/paymentSetting';
import { IShopDiscountParams, IReward } from './types/paymentSetting';
import {
  ICategoryItemData,
  IServiceItemData,
} from './../../../services/shop/types/categories';
import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import {
  ICategories,
  ICategory,
  IDayOffItem,
  IGiftCard,
  ILateForWork,
  INotificationSettingGroup,
  IPageable,
  IPaymentSettingGroup,
  IProduct,
  ITurnSetting,
} from './types/reducer';
import { DataItem } from './types/generalSetting';
import uiSelector from 'services/UI/selectors';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['settingService']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['settingService']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) =>
  useAppSelector((state) => get(getCurrentState(state), key, defaultValue));

const getCateSelected = () => selector('cateSelected') as ICategoryItemData;
const getAddOnServiceSelected = () =>
  selector('addOnServiceSelected') as IServiceItemData;
const getListCategoriesForProduct = () => selector('categories') as ICategories;
const getReloadCategorySuccess = () => selector('reloadCategory') as boolean;
const reloadProduct = () => selector('reloadProduct') as boolean;
const getProductsByCategoryId = () => selector('products') as IProduct[];
const getProductsPageable = () => selector('productsPageable') as IPageable;
const getCateForProductSelected = () =>
  selector('cateForProductSelected') as ICategory;
const getVIPList = () => selector('listVIP') as any[];
const reloadVIPs = () => selector('reloadVIPs') as boolean;
const getTurnSetting = () => selector('turnSetting') as ITurnSetting;
const reloadTurnSetting = () => selector('reloadTurnSetting') as boolean;
const getLateForWork = () => selector('lateForWork') as ILateForWork;
const reloadLateForWork = () => selector('reloadLateForWork') as boolean;

const getQuickPayList = () => selector('quickPayList') as any[];

// start selector data for Payment Setting
const getPaymentSettingData = () =>
  selector('paymentSetting') as IPaymentSettingGroup;

const dataLoading = {
  cate: () => uiSelector.getLoading(SHOP_PATH_LOADING.getCategories) as boolean,
  addOn: () => uiSelector.getLoading(SHOP_PATH_LOADING.getAddOnList) as boolean,
};

const paymentSetting = {
  tax: () => getPaymentSettingData().tax as ITaxParams,
  discount: () => getPaymentSettingData().discount as IShopDiscountParams,
  specialDiscount: () =>
    getPaymentSettingData().specialDiscount as ISpecialDiscount[],
  loyaltyReward: () => getPaymentSettingData().loyaltyReward as IReward[],
  otherPaymentMethod: () =>
    getPaymentSettingData().otherPaymentMethod as IPaymentMethodParams[],
  policyTerm: () => getPaymentSettingData().policyTerm,
  giftCard: () => getPaymentSettingData().giftCard as IGiftCard,
};
// end selector data for Payment Setting

// start selector data for Notification Setting
// end selector data for Notification Setting
const getNotificationSettingData = () =>
  selector('notificationSetting') as INotificationSettingGroup;

const notificationSetting = {
  notification: () => getNotificationSettingData().notification as DataItem[],
  scheduler: () => getNotificationSettingData().scheduler as any,
};

const getDayOffList = () => selector('dayOffList') as IDayOffItem[];

const settingServiceSelectors = {
  getCateSelected,
  getAddOnServiceSelected,
  getListCategoriesForProduct,
  getReloadCategorySuccess,
  getProductsByCategoryId,
  getProductsPageable,
  reloadProduct,
  getCateForProductSelected,
  getVIPList,
  reloadVIPs,
  getTurnSetting,
  reloadTurnSetting,
  getLateForWork,
  reloadLateForWork,
  getQuickPayList,
  paymentSetting,
  getPaymentSettingData,
  notificationSetting,
  getNotificationSettingData,
  dataLoading,
  getDayOffList,
};
export default settingServiceSelectors;
