import { Col, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Spin from 'components/Spin';
import SwitchCustom from 'components/SwitchCustom';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { ICategoryItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import AddCateButton from './ButtonModal/AddCateButton';

type ICateListProps = {};
const CateList: React.FC<ICateListProps> = () => {
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const cateLoading = settingServiceSelectors.dataLoading.cate();
  const categories = shopSelectors.data.categories();
  const cateSelected = settingServiceSelectors.getCateSelected();
  const rowPerPage = 15;

  const listDisplay = categories.slice(
    (page - 1) * rowPerPage,
    page * rowPerPage
  );
  const totalPage = Math.ceil(categories.length / rowPerPage);

  const handleClickCate = (cate: ICategoryItemData) => {
    dispatch(settingServiceActions.clickCate(cate));
  };
  const handlePrev = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <CateListStyled>
      <Box className="space-between" mb="2">
        <Text variant="H5" color="text_3">
          Catagories
        </Text>
        <Box className="center" gap="3">
          <SwitchCustom defaultChecked />
          <Text variant="CONTENT_1" color="text_3">
            Hide Sell Price
          </Text>
          <Box display="flex" gap="2">
            <IconButtonBox onClick={handlePrev} disabled={page === 1}>
              <Icon type="back" />
            </IconButtonBox>
            <IconButtonBox onClick={handleNext} disabled={totalPage === page}>
              <Icon type="next" />
            </IconButtonBox>
          </Box>
        </Box>
      </Box>
      {cateLoading ? (
        <Box className="center">
          <Spin />
        </Box>
      ) : (
        <Row gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 12 }}
            lg={{ span: 8 }}
            xl={{ span: 6 }}
            xxl={{ span: 6 }}
          >
            <AddCateButton />
          </Col>
          {listDisplay?.map((item) => {
            const isSelected = item.id === cateSelected?.id;
            return (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 8 }}
                xl={{ span: 6 }}
                xxl={{ span: 6 }}
                key={item.id}
              >
                <CateItem
                  isSelected={isSelected}
                  onClick={() => handleClickCate(item)}
                >
                  <Box display="flex">
                    <Box>
                      <Text variant="H7" color="text_3">
                        <EllipsisText>{item.categoryName}</EllipsisText>
                      </Text>
                      <Text variant="CONTENT_1" color="text_2">
                        {item.services.length} Services
                      </Text>
                    </Box>
                  </Box>
                  {isSelected && (
                    <SelectedSignBoxStyled>
                      <Icon type="twoLineOpen" />
                    </SelectedSignBoxStyled>
                  )}
                </CateItem>
              </Col>
            );
          })}
        </Row>
      )}
    </CateListStyled>
  );
};

export default CateList;
const CateListStyled = styled.div``;
const EllipsisText = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%; // Make sure to set a width or max-width
  max-width: 10rem; // You can adjust the max-width as needed
`;

const IconButtonBox = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  border-radius: 50%;
  background-color: ${colorTheme.fill_2};
  border: 1px solid ${colorTheme.line_1};
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
  pointer-events: none;
  `}
`;

const CateItem = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<{ isSelected?: boolean }>`
  min-height: 4rem;
  position: relative;
  padding: 1rem;
  @media only screen and (max-width: 1400px) {
    padding: 1.25rem;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-1, #f6f7fc);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  ${(props) =>
    props.isSelected &&
    `
    border: 1px solid var(--primary-primary-hover, #f5767f);
    background: var(--primary-button, #ffe5e7);
  `}
`;

const SelectedSignBoxStyled = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 2.25rem;
  border-radius: 0px 4px 4px 0px;
  background: var(--primary-primary-active, #ff8890);
  display: flex;
  align-items: center;
  justify-content: center;
`;
