const Filter = (props:any) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M7.1539 11.2891H2.78784V9.28906H7.1539V11.2891ZM29.2123 11.2891H17.5302V9.28906H29.2123V11.2891Z" fill={props?.fill ? props?.fill:'#7B7B7B' } />
            <path d="M24.8461 20.7109L29.2122 20.7109L29.2122 22.7109L24.8461 22.7109L24.8461 20.7109ZM2.78768 20.7109L14.4698 20.7109L14.4698 22.7109L2.78768 22.7109L2.78768 20.7109Z" fill={props?.fill ? props?.fill:'#7B7B7B' } />
            <path d="M13.0605 13.224C14.6832 13.224 15.9986 11.9085 15.9986 10.2858C15.9986 8.66311 14.6832 7.34766 13.0605 7.34766C11.4378 7.34766 10.1223 8.66311 10.1223 10.2858C10.1223 11.9085 11.4378 13.224 13.0605 13.224ZM13.0605 15.224C15.7877 15.224 17.9986 13.0131 17.9986 10.2858C17.9986 7.55854 15.7877 5.34766 13.0605 5.34766C10.3332 5.34766 8.12231 7.55854 8.12231 10.2858C8.12231 13.0131 10.3332 15.224 13.0605 15.224Z" fill={props?.fill ? props?.fill:'#7B7B7B' } />
            <path d="M18.9395 18.7721C17.3168 18.7721 16.0014 20.0876 16.0014 21.7103C16.0014 23.333 17.3168 24.6484 18.9395 24.6484C20.5622 24.6484 21.8777 23.333 21.8777 21.7103C21.8777 20.0876 20.5622 18.7721 18.9395 18.7721ZM18.9395 16.7721C16.2123 16.7721 14.0014 18.983 14.0014 21.7103C14.0014 24.4376 16.2123 26.6484 18.9395 26.6484C21.6668 26.6484 23.8777 24.4376 23.8777 21.7103C23.8777 18.983 21.6668 16.7721 18.9395 16.7721Z" fill={props?.fill ? props?.fill:'#7B7B7B' } />
        </svg>
    );
};

export default Filter;

