import Modal from 'components/Modal';
import ChooseCustomerForm, { ChooseCustomerFormProps } from './ChooseCustomerForm';
import { InputRef } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { ICustomerItemData } from 'services/shop/types/customer';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
type Props = {
  visible?: boolean;
  onClose?: () => void;
  customer?: ICustomerItemData;
  onPickCustomer?: ChooseCustomerFormProps['onPickCustomer'];
  onChooseUnknown?: ChooseCustomerFormProps['onChooseUnknown'];
};
const ModalChooseCustomer = ({ visible, onClose, onPickCustomer = () => undefined, customer, onChooseUnknown }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const [recent, setRecent] = useState<ICustomerItemData[]>([]);

  const getListRecent = async () => {
    try {
      const res: IResponseDataBody<ICustomerItemData[]> = await shopApis.getCustomersRecent();
      if (res.data.data) setRecent(res.data.data);
    } catch (error) { }
  };

  const addRecent = async (customer_id: string) => {
    try {
      await shopApis.addCustomersRecent(customer_id);
    } catch (error) { }
  };

  useEffect(() => {
    if (visible) {
      setTimeout(() => inputRef.current?.focus(), 10);
      getListRecent();
    }
  }, [visible]);

  const handlePickCustomer = (cus: ICustomerItemData) => {
    addRecent(cus.id);
    onPickCustomer(cus);
  };

  return (
    <Modal
      visible={visible}
      cancelTitle="Close"
      onClose={onClose}
      modalTitle="SELECT CUSTOMER"
      hiddenOkButton={true}
      noneBodyStyle
      noneFooterStyle
      containerPadding={1.5}
      footerPadding={1.5}
      showClose
      footer={<></>}
      width={'65rem'}
      hiddenFooter
    >
      <ChooseCustomerForm
        inputRef={inputRef}
        customer={customer}
        recentList={recent}
        onPickCustomer={handlePickCustomer}
        onChooseUnknown={onChooseUnknown}
      />
    </Modal>
  );
};

export default ModalChooseCustomer;