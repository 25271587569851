import { Divider, Row } from 'antd';
import Text from 'components/Text';
import { IAppointmentStaffItem } from 'features/checkIn/services/types/customer';
import moment from 'moment';
import React from 'react';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

interface ITargetServicesProps {
  isAppointment?: boolean;
  staffs: IAppointmentStaffItem[];
}
const TargetServices: React.FC<ITargetServicesProps> = ({ staffs = [], isAppointment }) => {
  if (isAppointment) {
    return (
      <TargetServicesStyled>{
        staffs.map((item) => {
          return (
            <div className="staff-does-services" key={item.staffId}>
              <Row align={'middle'} justify={'space-between'} className='staff-appointment container' >
                <Text variant='H9' color='text_3'>{item.staffId ? item.staffName || 'No name' : 'Next Available'}</Text>
                {item.bookingTime && <Text variant='H7' color='text_3'>{moment(item.bookingTime).format('LT')}</Text>}
              </Row>
              <Divider className="custom-divider"></Divider>
              <div className="target-services appointment container">
                {item.services.map((service) => {
                  return (
                    <div key={service.itemId} className="target-service-item">
                      <Text variant='CONTENT_1' color='text_3'>{service.itemName || 'No Name'}</Text>
                      <Text variant='CONTENT_2' color='text_3'>{formatCurrency(service.price)}</Text>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })
      }</TargetServicesStyled>
    );
  }

  return <TargetServicesStyled>{
    staffs.map((item) => {
      return (
        <div className="staff-does-services container" key={item.staffId}>
          <Text variant='H9' color='text_3'>{item.staffId ? item.staffName || 'No name' : 'Next Available'}</Text>
          <Divider className="custom-divider"></Divider>
          <div className="target-services">
            {item.services.map((service) => {
              return (
                <div key={service.itemId} className="target-service-item">
                  <Text variant='CONTENT_1' color='text_3'>{service.itemName || 'No Name'}</Text>
                  <Text variant='CONTENT_2' color='text_3'>{formatCurrency(service.price)}</Text>
                </div>
              );
            })}
          </div>
        </div>
      );
    })
  }</TargetServicesStyled>;
};
export default TargetServices;

const TargetServicesStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    .staff-does-services .target-services {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;
      row-gap: 0.5rem;
    }
    
    .target-service-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .staff-does-services .custom-divider {
      margin: 0;
    }

    .staff-appointment {
      background: var(--fill-fill-5, #E5E6EB);
      box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 0.5rem;
    }
    .target-services {
      &.appointment {
        padding: 1rem;
      }
    }
`;
