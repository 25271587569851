import { Col, Input, Radio, Row } from 'antd';
import Form, { FormItem, useForm } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useState } from 'react';
import styled from 'styled-components';
type Props = {
  visible?: boolean;
  onAccept: (reason: string) => void;
  onClose: () => void;
};
const ModalInputReason = ({ visible, onAccept, onClose }: Props) => {
  const [form] = useForm();
  const [reason, setReason] = useState<string>('');
  const handleClose = () => {
    onClose();
    setReason('');
    form.resetFields();
  };

  const handleSetReason = (val: string) => {
    form.setFieldValue('reason', val);
    setReason(val);
    form.validateFields();
  };

  const handleVoid = () => {
    onAccept(reason);
    handleClose();
  };

  return (
    <Modal
      visible={visible}
      modalTitle='Select'
      showClose
      hiddenCloseButton
      okTitle={'Accept'}
      noneBodyStyle
      noneFooterStyle
      containerPadding={1.5}
      disableOk={!reason.trim()}
      onClose={handleClose}
      onSubmit={() => form.submit()}
    >
      <Wrapper form={form} validateTrigger={['onSubmit']} onFinish={handleVoid}>
        <Text textAlign='center' variant='CONTENT_2' style={{ fontSize: '1.2rem' }}>Please select or enter the reason you want to update this appointment</Text>
        <Reasons value={reason} onChangeText={handleSetReason} />
        <ReasonInputWrap name={'reason'} rules={[{ required: true }]}>
          <Input.TextArea
            rows={5}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder='Type your reason here'
          />
        </ReasonInputWrap>
      </Wrapper>
    </Modal>
  );
};

export default ModalInputReason;

const Wrapper = styled(Form)`
  height: 40vh;  
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
type ReasonProps = {
  value: string;
  onChangeText: (val: string) => void;
};

const REASONS = {
  BUSY: 'Busy',
  SPAM: 'Spam',
  CHANGE_TIME_APT: 'Change time appointment',
};

const Reasons = ({ value, onChangeText }: ReasonProps) => (
  <Row>
    <Col span={24} onClick={() => onChangeText(REASONS.BUSY)}><Reason checked={value === REASONS.BUSY} message={REASONS.BUSY} /></Col>
    <Col span={24} onClick={() => onChangeText(REASONS.SPAM)}><Reason checked={value === REASONS.SPAM} message={REASONS.SPAM} /></Col>
    <Col span={24} onClick={() => onChangeText(REASONS.CHANGE_TIME_APT)}><Reason checked={value === REASONS.CHANGE_TIME_APT} message={REASONS.CHANGE_TIME_APT} /></Col>
  </Row>
);
const Reason = ({ message, checked }: { checked?: boolean, message?: string }) => (
  <ReasonStyled type='button'>
    <Radio checked={checked} />
    <Text variant='CONTENT_2' className='reason-text'>{message}</Text>
  </ReasonStyled>
);
const ReasonStyled = styled.button`
display: flex;
align-items: center;
width: 100%;
margin-bottom: 0.5rem;
padding: 4px 0;
border-radius: 5px;
  .reason-text {
    font-size: 1rem;
  }
  &:hover {
    background-color: #FFE5E7 !important;
  }
`;

const ReasonInputWrap = styled(FormItem)``;