import { Input } from 'antd';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { styled } from 'styled-components';
import { isMobileBrowser } from 'utils/detachBehavior';
import InputKeyboardView, { InputViewProps } from '../InputKeyboardView';
import NumberPad from '../NumberPad';

export interface IAmountKeyboard {
  value?: string | null;
  onChange?: (value: IAmountKeyboard['value']) => void;
  onChangeNoneDebounce?: (value: IAmountKeyboard['value']) => void;
  disableInput?: boolean;
  inputViewProps?: InputViewProps;
  inputClassName?: string;
}

export type AmountKeyboardRef = {
  showError: (text: string) => void,
  hideError: () => void,
  clear: () => void,
  setValue: (val: string) => void,
};
const inputPlaceholder = '$0.00';
const AmountKeyboard = forwardRef<AmountKeyboardRef, IAmountKeyboard>(({
  disableInput,
  value,
  onChange,
  onChangeNoneDebounce = () => undefined,
  inputViewProps = {},
  inputClassName = '',
}, ref) => {
  const [text, setText] = useState<string>();
  const [error, setError] = useState<string | undefined>();

  useEffect(() => {
    if (text) return;
    setText(value?.toString());
  }, [value]);

  useImperativeHandle(ref, () => ({
    showError: (text: string) => setError(text),
    hideError: () => setError(''),
    clear: () => setText(undefined),
    setValue: (_text: string) => setText(_text),
  }));

  const _debounce = useCallback(debounce((inputValue: IAmountKeyboard['value']) => typeof onChange === 'function' && onChange(inputValue), 200), [onChange]);

  useEffect(() => {
    _debounce(text ?? '');
  }, [text]);

  const onNumberPadChange = (_val: string) => {
    const val = _val === '0' ? '' : _val;
    setText(val);
    onChangeNoneDebounce(val);
  };

  const InputView = () => {
    if (disableInput) return null;
    if (isMobileBrowser()) return (
      <InputKeyboardView
        className={`${inputClassName ?? ''}`}
        value={text}
        {...inputViewProps}
        type={'CURRENCY'}
        placeholder={inputPlaceholder}
      />
    );

    return (
      <InputWrapStyled>
        <Input
          autoFocus
          value={text}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={e => onNumberPadChange(e.target.value?.toString())}
        />
      </InputWrapStyled>
    );
  };

  return (
    <AmountKeyboardStyled>
      <Text variant='CONTENT_1' mb={0.5}>Amount</Text>
      <InputView />
      {error && <Text textAlign='center' mt={0.5} style={{ color: '#f53f3f' }}>{error}</Text>}
      <Text mb={1} />
      <NumberPad
        value={text}
        onChange={onNumberPadChange}
        type={'NUMBER'}
      />
    </AmountKeyboardStyled >
  );
});
AmountKeyboard.displayName = 'AmountKeyboard';
export default memo(AmountKeyboard);

const AmountKeyboardStyled = styled.div`
  display: flex;
  flex-direction: column;
`;
const InputWrapStyled = styled.div`
  width: 100%;
  .ant-input-number-group-wrapper {
    width: 100%;
  }
  input {
    color: var(--text-text-3, #1D2129);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height: 3.5rem;
  }
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
`;