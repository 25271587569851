import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import styled from 'styled-components';
import classNames from 'classnames';

export function Droppable(props: {
  id: string;
  className?: string;
  children:
  | string
  | number
  | boolean
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | Iterable<React.ReactNode>
  | React.ReactPortal
  | null
  | undefined;
}) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    color: isOver ? 'green' : undefined,
  };

  return (
    <DroppableStyled
      ref={setNodeRef} style={style}
      className={classNames(
        isOver && 'over',
        props.className
      )}
      aria-label="Droppable region"
    >
      {props.children}
    </DroppableStyled>
  );
}
const DroppableStyled = styled.div`
  &.over {
    .ticket-wrapper {
      box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
    }
  }
  &.dropped {
    > svg {
      opacity: 0.2;
      transform: translate3d(-50%, 100%, 0) scale(0.8);
    }
  }
`;
