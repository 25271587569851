import React from 'react';

function cardVIP() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="52" height="42" viewBox="0 0 52 42" fill="none">
            <path d="M23.4188 3.54884C23.7677 2.81928 24.4993 2.28517 25.3045 2.18796C26.072 2.08053 26.8793 2.36396 27.4083 2.93082C28.0468 3.59079 28.2586 4.62526 27.926 5.48169C27.6457 6.25114 26.956 6.82516 26.1692 7.02162C29.5355 11.1503 32.9019 15.279 36.2703 19.4056C39.7022 17.6907 43.1269 15.9625 46.5557 14.2435C46.3029 13.8434 46.1024 13.3942 46.0962 12.9143C46.0502 12.063 46.4902 11.2076 47.2095 10.7502C47.9165 10.2795 48.8722 10.2222 49.6314 10.6039C50.3907 10.9692 50.9258 11.7519 50.9913 12.592C51.0701 13.4003 50.7089 14.2312 50.0704 14.7315C49.4473 15.236 48.5714 15.3905 47.8101 15.1398C45.3145 21.451 42.8475 27.7744 40.3611 34.0897C30.5393 34.0907 20.7165 34.0897 10.8946 34.0907C8.41027 27.7734 5.94842 21.4479 3.45178 15.1367C2.69051 15.3915 1.81259 15.2401 1.18843 14.7326C0.48651 14.1933 0.131455 13.2571 0.288006 12.3874C0.456837 11.3181 1.41149 10.4371 2.49303 10.3614C3.45894 10.2632 4.4443 10.8044 4.88326 11.6691C5.31915 12.4733 5.22706 13.4996 4.7001 14.2414C8.12684 16.0187 11.5618 17.7797 14.9865 19.5601C18.359 15.3843 21.7192 11.1984 25.0968 7.02674C24.4798 6.86405 23.9109 6.49774 23.5671 5.95339C23.0995 5.25146 23.0473 4.30397 23.4188 3.54884Z" fill="#FFDC7C" />
            <path d="M10.8946 35.7276C20.7175 35.7246 30.5403 35.7287 40.3632 35.7256C40.3601 37.3627 40.3652 38.9999 40.3611 40.637C30.5383 40.6401 20.7154 40.636 10.8926 40.6391C10.8956 39.0019 10.8905 37.3648 10.8946 35.7276Z" fill="#FFDC7C" />
        </svg>
    );
}

export default cardVIP;
