import {
  ICouponsParams,
  IReferralsParams,
} from 'features/customer/services/types/api';
import { get, set } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { ICustomer, IGetCustomersParam, IResSummaryReport } from './types/api';
import { AppointmentCustomer, IAppointmentParams } from './types/appointment';
import { ITransactionsParams } from './types/reducer';
import { Bill } from 'features/ticketList/services/types/api';
import { IReferral } from './types/referral';
import { ICoupon } from './types/coupon';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['customer']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['customer']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) =>
  useAppSelector((state) => get(getCurrentState(state), key, defaultValue));

export const getCustomerFilterParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.params ?? {};
};

const getIsWindowDisplay = () => selector('isWindow') as boolean;
const getCustomers = () => selector('customers') as ICustomer[];
const getCustomersActive = () => selector('customersActive') as ICustomer[];

const customersActiveData = {
  loading: () =>
    uiSelector.getLoading(PATH_LOADING.getCustomersActive) as boolean,
  data: () => selector('customersActive') as ICustomer[] | [],
};

const paramStatus = () => {
  const paramStatus = selector('params') as IGetCustomersParam;
  return paramStatus.types?.split(',');
};

const customersTableData = {
  loading: () => uiSelector.getLoading(PATH_LOADING.getCustomers) as boolean,
  params: () => selector('params') as IGetCustomersParam,
  data: () => selector('customers') as ICustomer[] | [],
  totalElement: () => selector('totalElement') as number | null,
};

export const getAppointmentParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.paramsAppointment ?? {};
};
export const getTransactionsParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.paramsTransactions ?? {};
};
export const getReferralsParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.paramsReferral ?? {};
};
export const getCouponsParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.paramsCoupon ?? {};
};

const appointmentTableData = {
  loading: () => uiSelector.getLoading(PATH_LOADING.getAppointments) as boolean,
  params: () => selector('paramsAppointment') as IAppointmentParams,
  data: () => selector('appointmentList') as AppointmentCustomer[] | [],
  totalElement: () => selector('appointmentTotalElement') as number | null,
};
const transactionTableData = {
  loading: () => uiSelector.getLoading(PATH_LOADING.getTransactions) as boolean,
  params: () => selector('paramsTransactions') as ITransactionsParams,
  data: () => selector('transactionList') as Bill[] | [],
  totalElement: () => selector('totalTransactionsElement') as number | null,
};
const referralTableData = {
  loading: () => uiSelector.getLoading(PATH_LOADING.getReferrals) as boolean,
  params: () => selector('paramsReferral') as IReferralsParams,
  data: () => selector('referralList') as IReferral[] | [],
  totalElement: () => selector('totalReferralElement') as number | null,
};
const couponTableData = {
  loading: () => uiSelector.getLoading(PATH_LOADING.getCoupons) as boolean,
  params: () => selector('paramsCoupon') as ICouponsParams,
  data: () => selector('couponList') as ICoupon[] | [],
  totalElement: () => selector('totalCouponElement') as number | null,
};
const summaryReportData = {
  loading: () =>
    uiSelector.getLoading(PATH_LOADING.getSummaryReport) as boolean,
  data: () => selector('summaryReport') as IResSummaryReport,
};

const getCustomerDetails = () => {
  const result = selector('customerDetails') as ICustomer;
  return result;
};

const customerSelectors = {
  getIsWindowDisplay,
  getCustomers,
  customersTableData,
  paramStatus,
  getCustomerDetails,
  appointmentTableData,
  transactionTableData,
  summaryReportData,
  getCustomersActive,
  customersActiveData,
  referralTableData,
  couponTableData,
};
export default customerSelectors;

export const getSpendingAmountByKey = (key: string) => {
  const data = { sendingAmountFrom: '', sendingAmountTo: '' };
  switch (key) {
    case '1':
      set(data, 'sendingAmountFrom', 1001);
      set(data, 'sendingAmountTo', '');
      break;
    case '2':
      set(data, 'sendingAmountFrom', 501);
      set(data, 'sendingAmountTo', 1000);
      break;
    case '3':
      set(data, 'sendingAmountFrom', 301);
      set(data, 'sendingAmountTo', 500);
      break;
    case '4':
      set(data, 'sendingAmountFrom', 100);
      set(data, 'sendingAmountTo', 300);
      break;
    case '5':
      set(data, 'sendingAmountFrom', '');
      set(data, 'sendingAmountTo', 100);
      break;
    default:
      set(data, 'sendingAmountFrom', '');
      set(data, 'sendingAmountTo', '');
      break;
  }
  return data;
};
export const getPointByKey = (key: string) => {
  const data = { pointFrom: '', pointTo: '' };
  switch (key) {
    case '1':
      set(data, 'pointFrom', 1001);
      set(data, 'pointTo', '');
      break;
    case '2':
      set(data, 'pointFrom', 501);
      set(data, 'pointTo', 1000);
      break;
    case '3':
      set(data, 'pointFrom', 301);
      set(data, 'pointTo', 500);
      break;
    case '4':
      set(data, 'pointFrom', 100);
      set(data, 'pointTo', 300);
      break;
    case '5':
      set(data, 'pointFrom', '');
      set(data, 'pointTo', 100);
      break;
    default:
      set(data, 'pointFrom', '');
      set(data, 'pointTo', '');
      break;
  }
  return data;
};
