import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { Progress, Row } from 'antd';
import Icon, { typeIcon } from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { formatCurrency } from 'utils/formatCurrency';
export type IIconAndPriceProps = {
  icon?: typeIcon;
  title: string;
  price: string | number;
  increase?: boolean;
  decrease?: boolean;
  percent?: number;
  progress?: number;
  isPercentageInTittle?: boolean;
  isHidePercentage?: boolean;
  isHideCurrency?: boolean;
  background?: string;
  labelStyle?: CSSProperties,
};

const IconAndPrice: React.FC<IIconAndPriceProps> = ({
  icon,
  title,
  price,
  increase,
  decrease,
  percent,
  progress,
  isPercentageInTittle,
  isHidePercentage,
  isHideCurrency,
  background,
  labelStyle,
}) => {

  return (
    <IconAndPriceStyled isHidePercentage={isHidePercentage || !icon}>
      {icon && (
        <IconBox background={background}>
          <Icon type={icon}></Icon>
        </IconBox>
      )}
      {typeof progress === 'number' && (
        <Progress
          type="circle"
          percent={progress}
          size="small"
          strokeColor={colorTheme.primary_active}
          trailColor={'white'}
        />
      )}
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <StyledRow>
          <StyledText $isPercentageInTittle={isPercentageInTittle}>{title}</StyledText>
          {
            !isHidePercentage &&
            isPercentageInTittle &&
            percent && <RenderIncreaseDecreaseBox increase={increase} decrease={decrease} percent={percent} />
          }
        </StyledRow>

        <PriceBox>
          <Text variant="H2" color="text_3" style={labelStyle}>
            {isHideCurrency ? price : formatCurrency(price)}
          </Text>
          {
            !isHidePercentage &&
            !isPercentageInTittle &&
            percent && <RenderIncreaseDecreaseBox increase={increase} decrease={decrease} percent={percent} />
          }
        </PriceBox>
      </Box>
    </IconAndPriceStyled>
  );
};
export default IconAndPrice;

interface IRenderIncreaseBox {
  increase?: boolean;
  decrease?: boolean;
  percent?: number;
}

const RenderIncreaseDecreaseBox: React.FC<IRenderIncreaseBox> = ({ increase, decrease, percent }) => {
  return (
    <IncreaseDecreaseBox increase={increase} decrease={decrease}>
      {increase && <Icon type="upArrow" />}
      {decrease && <Icon type="downArrow" />}
      <Text
        variant="BODY_1"
        color={`${increase ? 'info_3' : 'primary_active'}`}
      >
        {percent}%
      </Text>
    </IncreaseDecreaseBox>);
};

const IconAndPriceStyled = styled.div<{ isHidePercentage?: boolean }>`
  display: flex;
  gap: 1rem;
  background: transparent;
  height: ${props => props.isHidePercentage ? '100%' : undefined};
`;
type IncreaseDecreaseProps = {
  increase?: boolean;
  decrease?: boolean;
};
const IncreaseDecreaseBox = styled.div<IncreaseDecreaseProps>`
  padding: 2px 8px;
  border-radius: 50px;
  display: flex;
  gap: 4px;
  justify-content: center;
  height: 20px;
  flex-shrink: 0;
  align-items: center;
  background: ${(props) =>
    props.increase ? '#c6e9ef' : props.decrease ? '#FFE5E7' : 'transparent'};;
    
`;
const IconBox = styled.div<{ background?: string }>`
  border-radius: 10px;
  background: ${(props) => props?.background ? props.background : '#fff'};
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 67px;
  height: 67px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
`;
const PriceBox = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
`;

const StyledRow = styled(Row)`
  align-items: center;
`;

const StyledText = styled(Text) <{ $isPercentageInTittle?: boolean; }>`
  margin-right: ${props => props.$isPercentageInTittle ? '10px' : 0};
`;
