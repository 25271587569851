import { Row } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import styled from 'styled-components';

export const ButtonActions = ({ confirm, onBack, onOk, first = false, disabledOk }: { first?: boolean, confirm?: boolean, onOk: () => void, onBack: () => void, disabledOk?: boolean }) => {
  if (confirm) {
    return (
      <ButtonActionsStyled align={'middle'}>
        <Button onClick={onBack}>
          <Text style={{ fontSize: '1.5rem' }}>
            Back
          </Text>
        </Button>
        <Button ntype='PRIMARY' onClick={onOk} disabled={disabledOk} icon='addPlus'>

          <Text style={{ fontSize: '1.5rem' }}>
            Add New Appointment
          </Text>
        </Button>
      </ButtonActionsStyled>
    );
  }
  return (
    <ButtonActionsStyled align={'middle'}>
      <Button onClick={onBack}>{first ? 'Close' : 'Back'}</Button>
      <Button ntype='PRIMARY' onClick={onOk} disabled={disabledOk}>Next</Button>
    </ButtonActionsStyled>
  );
};

const ButtonActionsStyled = styled(Row)`
  margin-top: 1rem;
  width: 100%;
  gap: 1rem;
  flex-wrap: nowrap;
  button {
    width:50%;
    flex-grow:1;
  }
`;

export enum APPOINTMENT_TABS {
  STAFF = 'STAFF',
  TIME = 'TIME',
  SERVICES = 'SERVICES',
  CUSTOMER = 'CUSTOMER',
}
