import { Col, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import { IPrintSummarySalon } from 'features/report/services/types/reportStaff';
import { sumBy } from 'lodash';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import employeeCalcMethods, { minusValue } from './calc';
import { Header } from './styles';
type Props = {
  dataSummarySalon: IPrintSummarySalon;
};
const EmployeeTableV2 = ({ dataSummarySalon }: Props) => {
  return (
    // px="1" <-- Chỉnh khoảng cách viền bill ở đây (1 unit = 4px)
    <Box px="1">
        <div style={{ borderBottom: '1px dotted #000' }} />
      {/* <Box highlight>
        <Box className="space-between">
          <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight'>
            Employee
          </Text>
        </Box>
      </Box> */}
      <Text mb={0.5} />
      {
        dataSummarySalon?.summaryStaff?.map((staff, i) => (
          <Box key={staff.staffId} display="flex" flexDirection="column">
            <Header style={{ border: 'unset', padding: '0' }}>
              <ColHeader  style={{ padding: 'unset' }} >
                <Box display='flex' highlight style={{ padding: '2px 0', paddingRight: '2px', fontSize: '1rem' }} alignItems='center' justifyContent='space-between'>
                  <p className='title-highlight'>{staff.staffName}: </p>
                  <Text ml={0.5} />
                  <p className='title-highlight'>{formatCurrency(employeeCalcMethods.payroll(staff))}</p>
                  {/* <p className='title-highlight'>{formatCurrency(9999)}</p> */}
                </Box>
              </ColHeader>
            </Header>
            <Header>
              <ColHeader span={6}>SALES</ColHeader>
              <ColHeader span={5} style={{ textAlign: 'start' }}>SUPPLY</ColHeader>
              <ColHeader span={5} style={{ textAlign: 'start' }}>COMM</ColHeader>
              <ColHeader span={4} style={{ textAlign: 'start' }}>DICS</ColHeader>
              <ColHeader span={4} style={{ textAlign: 'start' }}>TIP</ColHeader>
            </Header>
            <Row>
              <ColHeader span={6}>
                <Text variant='CAPTION_2' printMode className='text'>{formatCurrency(staff.totalSaleService)}</Text>
              </ColHeader>
              <ColHeader span={5} style={{ textAlign: 'start' }}><Text variant='CAPTION_2' printMode className='text' >{minusValue(staff.supplyFee)}</Text></ColHeader>
              <ColHeader span={5} style={{ textAlign: 'start' }}><Text variant='CAPTION_2' printMode className='text'>{formatCurrency(staff?.commissionValue)}</Text></ColHeader>
              <ColHeader span={4} style={{ textAlign: 'start' }}><Text variant='CAPTION_2' printMode className='text'>{minusValue(staff?.discount)}</Text></ColHeader>
              <ColHeader span={4} style={{ textAlign: 'start' }}><Text variant='CAPTION_2' printMode className='text'>{formatCurrency(staff?.tip)}</Text></ColHeader>
            </Row>

            {dataSummarySalon?.summaryStaff.length - 1 !== i && <Text my={0.5}>
              <div style={{ borderBottom: '1px dotted #000' }} />
            </Text>}
          </Box>
        ))
      }
      <Text my={0.5}>
        <div style={{ borderBottom: '1px dotted #000' }} />
      </Text>
      <Box display="flex" flexDirection="column">
        <Header style={{ border: 'unset', padding: '0' }}>
          <ColHeader span={24} style={{ padding: 'unset' }} >
            <Box display='flex' highlight style={{ padding: '2px 0', fontSize: '1rem' }} alignItems='center' justifyContent='center'>
              <p className='title-highlight'>Total: </p>
              <Text ml={0.5} />
              <p className='title-highlight'>{formatCurrency(sumBy(dataSummarySalon?.summaryStaff, employeeCalcMethods.payroll))}</p>
            </Box>
          </ColHeader>
        </Header>
        <Header>
          <ColHeader span={6}>SALES</ColHeader>
          <ColHeader span={5} style={{ textAlign: 'start' }}>SUPPLY</ColHeader>
          <ColHeader span={5} style={{ textAlign: 'start' }}>COMM</ColHeader>
          <ColHeader span={4} style={{ textAlign: 'start' }}>DICS</ColHeader>
          <ColHeader span={4} style={{ textAlign: 'start' }}>TIP</ColHeader>
        </Header>
        <Row>
          <ColHeader span={6}>
            <Text variant='CAPTION_2' printMode className='text'>{formatCurrency(sumBy(dataSummarySalon?.summaryStaff, o => o.totalSaleService))}</Text>
          </ColHeader>
          <ColHeader span={5} style={{ textAlign: 'start' }}><Text variant='CAPTION_2' printMode className='text' >{minusValue(sumBy(dataSummarySalon?.summaryStaff, o => o.supplyFee))}</Text></ColHeader>
          <ColHeader span={5} style={{ textAlign: 'start' }}><Text variant='CAPTION_2' printMode className='text'>{formatCurrency(sumBy(dataSummarySalon?.summaryStaff, o => o.commissionValue))}</Text></ColHeader>
          <ColHeader span={4} style={{ textAlign: 'start' }}><Text variant='CAPTION_2' printMode className='text'>{minusValue(sumBy(dataSummarySalon?.summaryStaff, o => o.discount))}</Text></ColHeader>
          <ColHeader span={4} style={{ textAlign: 'start' }}><Text variant='CAPTION_2' printMode className='text'>{formatCurrency(sumBy(dataSummarySalon?.summaryStaff, o => o.tip))}</Text></ColHeader>
        </Row>
      </Box>
    </Box>
  );
};

export default EmployeeTableV2;

const ColHeader = styled(Col)`
    color:  black;
    font-size: 0.7125rem;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    .text {
      font-size: 0.7125rem !important;
    }
`;