import { Col } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../components/PrintHeader';
import { ReportType } from '../constants';
import { renderTime } from '../utils';
import IconLogo from 'assets/Icons/logo';

interface IServiceReportPrintPage { }
const ServiceReportPrintPage: React.FC<IServiceReportPrintPage> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dataPrintSummaryServiceCategory = reportSelectors.getPrintSummaryServiceCategory();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.SERVICES];

    useEffect(() => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSummaryServiceCategory.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime
        }));
    }, [dataAppointment]);
    const onBack = () => {
        navigate(-1);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });
    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <Box className="center" overflow="auto">
                        <TicketBox>
                            <LogoBox>
                                <Icon type="logoSmall" />
                                <IconLogo/>
                            </LogoBox>
                            <Box className="center">
                                <Text variant="CONTENT_2" color="text_3" printMode>
                                    {renderTime(dataAppointment)}
                                </Text>
                            </Box>
                            <Box>
                                <Category bb="print_line">
                                    <Box className="space-between" bb="print_line">
                                        <Text variant="CAPTION_2" color="text_3" printMode>
                                            CATAGORIES
                                        </Text>
                                        <Text variant="CAPTION_2" color="text_3" printMode>
                                            {formatCurrency(dataPrintSummaryServiceCategory.summaryCategory?.reduce((total, cur) => total + cur.totalSale, 0) ?? 0)}
                                        </Text>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        {dataPrintSummaryServiceCategory.summaryCategory?.map((item, index) => (
                                            <Box key={index} className="space-between">
                                                <Text variant="CAPTION_1" color="text_3" printMode>
                                                    {item.categoryName}
                                                </Text>
                                                <Text variant="CAPTION_1" color="text_3" printMode>
                                                    {formatCurrency(item.totalSale)}
                                                </Text>
                                            </Box>
                                        ))}
                                    </Box>
                                </Category>
                                <Box>
                                    <Box px="4">
                                        <Box display="flex" flexDirection="column" >
                                            <Box className="space-between" bb="print_line" >
                                                <Text variant="CAPTION_2" color="text_3" printMode>
                                                    Services
                                                </Text>
                                                <Text variant="CAPTION_2" color="text_3" printMode>
                                                    {formatCurrency(dataPrintSummaryServiceCategory.summaryService?.reduce((total, cur) => total + cur.totalSale, 0) ?? 0)}
                                                </Text>
                                            </Box>
                                            {dataPrintSummaryServiceCategory.summaryService?.map((item, index) => (
                                                <Box key={index} className="space-between" bb="print_line"
                                                    bStyle="dotted">
                                                    <Col span={16}>
                                                        <Text variant="CAPTION_2" printMode>
                                                            {item.serviceName}
                                                        </Text>
                                                        <Text variant="CAPTION_1" color="text_2" printMode>
                                                            Service: {item.serviceTimes} times
                                                        </Text>
                                                        <Text variant="CAPTION_1" color="text_2" printMode>
                                                            Coupon Code Used: {item.couponUsed}
                                                        </Text>
                                                    </Col>
                                                    <Col span={8} style={{ marginBottom: 'auto' }}>
                                                        <Text variant="CAPTION_2" textAlign='right' printMode>
                                                            {formatCurrency(item.totalSale)}
                                                        </Text>
                                                    </Col>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </TicketBox>
                    </Box>
                </div>
            </ContainerStyled>
        </div>
    );
};

export default ServiceReportPrintPage;

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 19.5rem;
  height: fit-content;
  padding: 1.5rem 0 2rem;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;
const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Category = styled(Box)`
  padding: 2px 1.5rem;
`;

