import Sidebar from 'components/layout/Sidebar';
import cashierActions from 'features/cashier/services/actions';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import MainBody from '../../components/MainBody';
import RightEditTicket from '../../components/RightEditTicket';
import TickerFooter from '../../components/TickerFooter';
import EditTicketPageStyled from './styles';
type IEditTicketPageProps = any;

const EditTicketPage: React.FC<IEditTicketPageProps> = () => {
  const { id = '' } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(cashierActions.initData());
    dispatch(cashierActions.getDetail.fetch(id));
  }, [id]);

  return (
    <EditTicketPageStyled>
      <Sidebar />
      <RightEditTicket>
        <MainBody />
        <TickerFooter />
      </RightEditTicket>
    </EditTicketPageStyled>
  );
};

export default EditTicketPage;
