import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiBodyOwnerLogin } from './types/api';
const ownerLogin = createAsyncAction<IApiBodyOwnerLogin>(PREFIX_ACTIONS + 'ownerLogin');
const setAutoSelectShop = createAction<boolean>(PREFIX_ACTIONS + 'setAutoSelectShop');
const ownerLogout = createAction(PREFIX_ACTIONS + 'ownerLogout');
const authActions = {
  ownerLogin,
  ownerLogout,
  setAutoSelectShop,
};

export default authActions;

