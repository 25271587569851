import React from 'react';
import { styled } from 'styled-components';
import QR from '../../../images/QR.svg';
import Text from 'components/Text';
import BoundingBox from 'components/BoundingBox';

interface ICustomerInfoProps {
  customerName: string;
  point: string;
}
const CustomerInfo: React.FC<ICustomerInfoProps> = ({
  customerName = 'No name',
  point = '0',
}) => {
  return (
    <CustomerInfoStyled>
      <BoundingBox type="SQUARE">
        <img src={QR}></img>
      </BoundingBox>
      <Text variant="H7">{customerName}</Text>
      <Text variant="CONTENT_1">{`${point} Point`}</Text>
    </CustomerInfoStyled>
  );
};
export default CustomerInfo;

const CustomerInfoStyled = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 0.5rem;
`;
