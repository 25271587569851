import Icon, { typeIcon } from 'assets/Icons';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import React from 'react';
import styled from 'styled-components';
type IComponentProps = {
  icon: typeIcon;
  content: string;
  nbgcolor: string
  textColor: string
  border_color: string
  onClick?: () => void;
  active?: boolean;
};
type ComponentStyledProps = {
  nbgcolor: string;
  border_color: string;
  active?: boolean;
};
const CardTicketItem: React.FC<IComponentProps> = ({
  icon,
  content,
  textColor,
  onClick,
  ...props
}) => {
  return (
    <ComponentStyled {...props} onClick={onClick}>
      <Icon type={icon} />
      <Text variant="H8" className='text-content' style={{ fontSize: '1.125rem' }} color={textColor as keyof typeof colorTheme}>
        {content}
      </Text>
    </ComponentStyled>
  );
};

export default CardTicketItem;
const ComponentStyled = styled.button.withConfig({
  shouldForwardProp: (propName) => !['nbgcolor', ['border_color']].includes(propName)
}) <ComponentStyledProps>`
  height: 6.75rem;
  width: 100%;
  border-radius: 10px;

  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1.5px solid ${({ border_color }) => border_color};
  background: ${({ nbgcolor }) => nbgcolor};
  position: relative;
  ${({ active, border_color }) => active && `
    background:${border_color};
    &::after {
      border-radius: 10px;
      position: absolute;
      inset: -7px;
      bottom: -9px;
      z-index: 99;
      content: '';
      border: 1.5px solid ${border_color};
    }
  `}
  .text-content {
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
