export const cleanParams = <T extends object>(data: T): T => {
  const cleanedData = { ...data };
  for (const key in cleanedData) {
    const keyType = key as keyof T;
    if (!cleanedData[keyType] || cleanedData[key] === undefined) {
      delete cleanedData[key as keyof T];
    }
  }
  return cleanedData;
};
