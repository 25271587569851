import CarouserBox from 'components/Carosel/CarouserBox';
import CategoryItem from 'components/Category';
import Spin from 'components/Spin';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { ICategory } from 'features/cashier/services/types/api';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';

const Categories = () => {
  const dispatch = useAppDispatch();
  const loading = shopSelectors.loading.categories();
  const categories = shopSelectors.data.categories();
  const activeCategory = checkInSelectors.getActiveCategory();
  const selectedServices = checkInSelectors.getSelectedServices();

  const handleClick = (item: ICategory) => {
    if (activeCategory?.id === item.id) return;
    dispatch(checkInActions.setActiveCategory(item));
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <CarouserBox
      content="CATEGORIES"
      list={categories}
      itemContent={(item: ICategory) => {
        return (
          <CategoryItem
            onClick={() => handleClick(item)}
            selected={!!item.services.some(s => !!selectedServices.find(o => o.id === s.id))}
            isActive={item.id === activeCategory?.id}
          >
            {item.categoryName}
          </CategoryItem>
        );
      }}
      rowPerPage={4}
      colSpan={12}
    ></CarouserBox>
  );
};

export default Categories;