import { Layout } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { Content } from 'antd/es/layout/layout';
import React, { FC } from 'react';
import styled from 'styled-components';
import ListCategoriesProduct from './Components/ListCategories';
import ListProducts from './Components/ListProducts';

interface IProductsSetting {

}

const ProductsSetting: FC<IProductsSetting> = () => {
   
    return (
        <ProductsSettingStyled>
            <Layout hasSider>
                <Sider width={360} style={siderStyle}>
                    <ListCategoriesProduct/>  
                </Sider>
                <Content style={contentStyle}>
                    <ListProducts/>
                </Content>
            </Layout>
        </ProductsSettingStyled >
    );
};
export default ProductsSetting;


const ProductsSettingStyled = styled.div`
    background-color: #E5E5E5;
    min-height: calc(100vh - 3rem);
`;


const contentStyle: React.CSSProperties = {
    backgroundColor: '#FFF',
};

const siderStyle: React.CSSProperties = {
    background: 'transparent',
    height: '100%',
};