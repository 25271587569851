import { CSSProperties } from 'react';
import { styled } from 'styled-components';
export const COL_CALENDAR_TIME_WIDTH = 72;
export const COL_CALENDAR_HEIGHT = 52;
export const PIXEL_PER_MINUTE = (COL_CALENDAR_HEIGHT) / 15;
export const fixedStyle: CSSProperties = { maxWidth: COL_CALENDAR_TIME_WIDTH, minWidth: COL_CALENDAR_TIME_WIDTH, width: COL_CALENDAR_TIME_WIDTH, padding: 0 };
type TableStyledProps = {}
const Table = styled.table<TableStyledProps>`
    border-collapse: collapse;
    min-width: 100%;
    height: 100%;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
`;

type HeadStyledProps = {}
const THead = styled.thead<HeadStyledProps>``;

type BodyStyledProps = {}
const TBody = styled.tbody<BodyStyledProps>`
`;

type RowStyledProps = {
}
const TRow = styled.tr<RowStyledProps>`
   
`;

type THStyledProps = {
    fixed?: boolean;
    fixedHigh?: boolean;
}
const TH = styled.th<THStyledProps>`
    border: 2px dashed var(--grey-grey-300, #EBEBEB);
    height: ${COL_CALENDAR_HEIGHT}px;
    width: auto;
    min-width: auto;
    max-width: auto;
    ${({ fixed }) => fixed ? `
        position: sticky;
        top: 0;
        z-index: 11;
        background: var(--blue-headline, #C6E9EF);
        border:none;
    `: ''}
`;
type TDStyledProps = {
    fixed?: boolean;
    time?: boolean;
}
const TD = styled.td<TDStyledProps>`
    border: 2px dashed var(--grey-grey-300, #EBEBEB);
    background: var(--fill-fill-0, #FFF);
    width: 20rem;
    height: 10rem;
    .col-box {
        height:100%;
        width:100%;
        position: relative;
        .date-label {
            padding: 8px;
            position: absolute;
            inset:0;
        }
    }
`;

const CalendarMonthStyled = {
    Table,
    THead,
    TBody,
    TRow,
    TH,
    TD,
};

export default CalendarMonthStyled;