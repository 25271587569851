import React from 'react';
import { Navigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import checkInSelectors from '../services/selectors';
type ShieldComponent = ((props: any) => JSX.Element) | React.LazyExoticComponent<(props: any) => JSX.Element>;
const CheckInShield = (Component: ShieldComponent) => {
  const isSigned = checkInSelectors.getCheckedInData().data?.id;
  if (!isSigned) return <Navigate to={`/store/${storage.shop_id.get()}/check-in/sign-in`} replace />;
  return <Component />;
};

export default CheckInShield;
