const Add = (props:any) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4.5C12.9142 4.5 13.25 4.83579 13.25 5.25V18.75C13.25 19.1642 12.9142 19.5 12.5 19.5C12.0858 19.5 11.75 19.1642 11.75 18.75V5.25C11.75 4.83579 12.0858 4.5 12.5 4.5Z"
        fill={props?.fill ? props.fill : '#1D2129'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 12C5 11.5858 5.33579 11.25 5.75 11.25H19.25C19.6642 11.25 20 11.5858 20 12C20 12.4142 19.6642 12.75 19.25 12.75H5.75C5.33579 12.75 5 12.4142 5 12Z"
        fill={props?.fill ? props.fill :'#1D2129'}
      />
    </svg>
  );
};

export default Add;
