const CircleClose = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="15.3333" r="13.3333" fill="#FF8890" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 10.2929C11.6834 9.90237 12.3166 9.90237 12.7071 10.2929L20.7071 18.2929C21.0976 18.6834 21.0976 19.3166 20.7071 19.7071C20.3166 20.0976 19.6834 20.0976 19.2929 19.7071L11.2929 11.7071C10.9024 11.3166 10.9024 10.6834 11.2929 10.2929Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7071 10.2929C21.0976 10.6834 21.0976 11.3166 20.7071 11.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L19.2929 10.2929C19.6834 9.90237 20.3166 9.90237 20.7071 10.2929Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default CircleClose;
