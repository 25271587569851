import { Avatar, DatePicker, Row, Select, Space, TimePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { IApiCreateUpdateClockInOut } from 'features/report/services/types/api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { styled } from 'styled-components';
import ModalPassword from './ModalPassword';
import { SizeType } from 'antd/es/config-provider/SizeContext';

dayjs.extend(customParseFormat);

const { Option } = Select;
type IModalAddTimeInOut = {
  onSubmit: (payload: IApiCreateUpdateClockInOut) => void;
  isShown: boolean;
  onClose: () => void;
  size?: SizeType
};
const DATE_FORMAT = 'MM/DD/YYYY HH:mm A';
const PAYLOAD_DATE_FORMAT = 'MM-DD-YYYY HH:mm:ss';
const DATE_WORK_FORMAT = 'MM/DD/YYYY';
const CLOCK_IN_OUT_FORMAT = 'HH:mm A';
const ModalAddTimeInOut: React.FC<IModalAddTimeInOut> = ({ onSubmit, isShown, onClose, size = 'middle' }) => {
  const [staffId, setStaffSelected] = useState<string>();
  const [showModalPassword, setShowModalPassword] = useState<boolean>(false);
  const [showModalAddTimeOut, setShowModalAddTimeOut] = useState<boolean>(false);
  const [pinCode, setPinCode] = useState<string>();
  const [dateWork, setDateWork] = useState<string>();
  const [clockIn, setClockIn] = useState<string>('00:00 AM');
  const [clockOut, setClockOut] = useState<string>('00:00 AM');
  const staffData = shopSelectors.data.staffs()?.filter(f => !f.anyBody);
  const handleCloseModal = () => {
    setShowModalAddTimeOut(false);
    onClose();
  };

  useEffect(() => {
    setShowModalPassword(isShown);
  }, [isShown]);

  const onChangeSelect = (value: any) => {
    setStaffSelected(value);
  };
  const onChangeDateWork = (value: any, dateString: string) => {
    setDateWork(dateString);
  };

  const onChangeClockIn = (value: any, dateString: string) => {
    if (moment(dateString, CLOCK_IN_OUT_FORMAT).isAfter(moment(clockOut, CLOCK_IN_OUT_FORMAT))) {
      setClockIn(clockOut);
      setClockOut(dateString);
      return;
    }
    setClockIn(dateString);
  };

  const onChangeClockOut = (value: any, dateString: string) => {
    if (moment(dateString, CLOCK_IN_OUT_FORMAT).isBefore(moment(clockIn, CLOCK_IN_OUT_FORMAT))) {
      setClockOut(clockIn);
      setClockIn(dateString);
      return;
    }
    setClockOut(dateString);
  };

  const onCloseModalPassword = () => {
    setShowModalPassword(false);
    onClose();
  };

  const onSubmitPinCode = (value: string) => {
    setShowModalPassword(false);
    setPinCode(value);
    setShowModalAddTimeOut(true);
  };
  const handleSubmitAddTimeOut = () => {
    if (!staffId || !clockIn || !dateWork || !clockOut) return;
    const payload: IApiCreateUpdateClockInOut = {
      staffId: staffId || '',
      timeClockIn: moment(`${dateWork} ${clockIn}`, DATE_FORMAT).format(PAYLOAD_DATE_FORMAT),
      timeClockOut: moment(`${dateWork} ${clockOut}`, DATE_FORMAT).format(PAYLOAD_DATE_FORMAT),
      pinCode: pinCode
    };
    onSubmit(payload);
    handleCloseModal();
  };
  const selectAndDatePickerStyle = {
    borderRadius: 2,
    border: '1px solid  #86909C)',
    background: '#FFF',
    width: '100%',
    marginLeft: 4
  };
  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().endOf('day');
  };

  const isNotValid = !staffId || !clockIn || !dateWork || !clockOut;

  return (
    <>
      <Modal
        modalTitle="ADD TIME IN/OUT"
        visible={showModalAddTimeOut}
        onClose={handleCloseModal}
        destroyOnClose={true}
        footer={<></>}
      >
        <InlineRow>
          <PreIconBox>
            <Icon type="time" />
          </PreIconBox>
          <DatePicker
            onChange={onChangeDateWork}
            style={selectAndDatePickerStyle}
            className='staff-date-picker'
            size={size}
            placeholder='Date Work'
            format={DATE_WORK_FORMAT}
            disabledDate={disabledDate}
          />
        </InlineRow>
        <InlineRow>
          <PreIconBox />
          <TimeRangePicker>
            <div>
              <ClockInLabel variant='BODY_1' color='text_2'>Clock In</ClockInLabel>
              <TimePicker
                use12Hours
                format={CLOCK_IN_OUT_FORMAT}
                suffixIcon={<></>}
                placeholder='Clock-in'
                defaultValue={dayjs('00:00 AM', CLOCK_IN_OUT_FORMAT)}
                value={clockIn ? dayjs(clockIn, CLOCK_IN_OUT_FORMAT) : undefined}
                bordered={false}
                size={size}
                onChange={onChangeClockIn}
              />
            </div>
            <span>
              <Text>-</Text>
            </span>
            <div>
              <ClockOutLabel variant='BODY_1' color='text_2'>Clock Out</ClockOutLabel>
              <TimePicker
                use12Hours
                format={CLOCK_IN_OUT_FORMAT}
                suffixIcon={<></>}
                placeholder='Clock-out'
                defaultValue={dayjs('00:00 AM', CLOCK_IN_OUT_FORMAT)}
                value={clockOut ? dayjs(clockOut, CLOCK_IN_OUT_FORMAT) : undefined}
                bordered={false}
                size={size}
                onChange={onChangeClockOut}
              />
            </div>
          </TimeRangePicker>
        </InlineRow>
        <InlineRow>
          <PreIconBox>
            <Icon type="employee" />
          </PreIconBox>
          <Select
            className='staff-selector'
            style={selectAndDatePickerStyle}
            placeholder="Staff Name"
            onChange={onChangeSelect}
            optionLabelProp="label"
            size={size}
            suffixIcon={<Icon type="search" />}
          >
            {staffData?.map(item => {
              return (
                <Option key={item.id} value={item.id} label={item.firstName + ' ' + item.lastName}>
                  <Space>
                    <ImageContainer>
                      <Avatar
                        src={item.urlImage}
                      >{item?.firstName?.charAt(0)}</Avatar>
                    </ImageContainer>
                    {item.firstName + ' ' + item.lastName}
                  </Space>
                </Option>
              );
            })}
          </Select>
        </InlineRow>
        <ButtonGroup>
                    <Button ntype="SECONDARY" onClick={handleCloseModal}>Close</Button>
                    <Button ntype="PRIMARY" disabled={isNotValid} onClick={handleSubmitAddTimeOut}>Done</Button>
        </ButtonGroup>
      </Modal>
      <ModalPassword isShown={showModalPassword} onClose={onCloseModalPassword} onSubmit={onSubmitPinCode} />
    </>
  );
};

export default ModalAddTimeInOut;

const ImageContainer = styled.div`
  min-width: 20px;
  margin-right: 0.5rem;
`;

const InlineRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-top: 15px;
    .staff-selector {
      border-radius: 2px;
    }
    .staff-date-picker {
      border-radius: 2px;
    }
`;

const PreIconBox = styled.div`
  width: 40px;
  align-items: center;
  display: flex;
`;

const TimeRangePicker = styled(Box)`
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    border-radius: 2px;
    border: 1px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-0, #FFF);
    div {
      display: flex;
      flex: 1;
      align-items: center;
    }
    span{
      margin-right: 15px;
    }
`;

const ClockInLabel = styled(Text)`
    margin-left: 10px;
    max-width: 55px;
`;

const ClockOutLabel = styled(Text)`
  max-width: 65px;
`;

const ButtonGroup = styled(Row)`
    justify-content: space-around;
    margin-top: 20px;
    button {
      width: 48%;
    }
`;