import { UserOutlined } from '@ant-design/icons';
import { Avatar, Checkbox, Col } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
type IAvatarCheckBoxProps = {
  id: string;
  urlImage: string;
  firstName: string;
  isChecked: boolean;
  handleChangeStaffItem: (id: string) => void;
};
const AvatarCheckBox: React.FC<IAvatarCheckBoxProps> = ({
  id,
  urlImage,
  firstName,
  isChecked,
  handleChangeStaffItem,
}) => {
  return (
    <AvatarCheckBoxStyled span={6}>
      <Box key={id}>
        <Checkbox
          checked={isChecked}
          onChange={() => handleChangeStaffItem(id)}
        >
          <Box className="center" gap="2">
            {urlImage ? (
              <Avatar size="small" src={urlImage} />
            ) : (
              <Avatar size="small" icon={<UserOutlined />} />
            )}
            <Text>{firstName}</Text>
          </Box>
        </Checkbox>
      </Box>
    </AvatarCheckBoxStyled>
  );
};

export default AvatarCheckBox;
const AvatarCheckBoxStyled = styled(Col)``;
