const Change = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.69816 6.69489C7.81664 5.57642 9.19776 4.88621 10.6424 4.62156L10.282 2.6543C8.45093 2.98975 6.69852 3.86611 5.28395 5.28068C1.57397 8.99066 1.57397 15.0057 5.28395 18.7157L3.57684 20.4228H8.40527V15.5944L6.69816 17.3015C3.76923 14.3726 3.76923 9.62383 6.69816 6.69489ZM20.4243 3.57535H15.5959V8.40377L17.303 6.69667C20.2319 9.6256 20.2319 14.3743 17.303 17.3033C16.1845 18.4217 14.8034 19.1119 13.3587 19.3766L13.7191 21.3439C15.5502 21.0084 17.3026 20.132 18.7172 18.7175C22.4272 15.0075 22.4272 8.99243 18.7172 5.28245L20.4243 3.57535Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default Change;
