const AddAppointment = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/linear/add">
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
        <g id="vuesax/linear/add_2">
          <g id="add">
            <path id="Vector" d="M7.5 12H16.5" stroke="#FF8890" strokeLinecap="round" strokeLinejoin="round" />
            <path id="Vector_2" d="M12 16.5V7.5" stroke="#FF8890" strokeLinecap="round" strokeLinejoin="round" />
          </g>
        </g>
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#86909C" />
      </g>
    </svg>

  );
};

export default AddAppointment;

export const minusAppointment = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="vuesax/linear/add">
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" />
        <g id="vuesax/linear/add_2">
          <g id="add">
            <path id="Vector" d="M7.5 12H16.5" stroke="#FF8890" strokeLinecap="round" strokeLinejoin="round" />
            {/* <path id="Vector_2" d="M12 16.5V7.5" stroke="#FF8890" strokeLinecap="round" strokeLinejoin="round" /> */}
          </g>
        </g>
        <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#86909C" />
      </g>
    </svg>

  );
};
