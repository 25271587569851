export const CustomerInfoActive = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3698_2821)">
        <path
          d="M11.3994 0.87447C12.6558 -0.166728 14.5703 -0.284564 15.962 0.555442C16.4868 0.872196 16.918 1.32611 17.2578 1.83307C17.6549 2.42907 17.9311 3.09857 18.1274 3.78513C18.3676 4.61793 18.4991 5.47613 18.6529 6.3275C19.0659 8.69028 19.8135 10.9947 20.8687 13.1487C21.1369 13.7019 21.4113 14.2664 21.498 14.8818C21.6322 15.8032 21.4071 16.7747 20.8732 17.5389C20.367 18.2725 19.5482 18.7457 19.1311 19.5467C18.7889 20.2124 18.8272 20.9827 18.7995 21.7094C18.77 22.5297 18.609 23.3864 18.1069 24.057C17.639 24.6864 16.8104 25.0929 16.0249 24.9058C16.5754 24.9937 17.0582 24.5136 17.1544 24.0006C17.2321 23.5478 17.0612 23.0954 16.8304 22.7127C16.4652 22.1057 15.9188 21.6196 15.6263 20.9668C15.4092 20.486 15.3941 19.9332 15.5233 19.4266C15.7044 18.7075 16.1003 18.0675 16.5144 17.4613C16.8672 16.9611 17.2237 16.4587 17.4931 15.9071C17.7731 15.3361 17.972 14.6904 17.8663 14.0497C17.7864 13.5083 17.4594 13.0521 17.1279 12.6338C16.7732 12.1962 16.4527 11.7301 16.1878 11.2327C15.4069 9.77619 15.0296 8.14694 14.7632 6.52793C14.6829 6.07137 14.6355 5.60836 14.5472 5.15331C14.1164 4.66833 13.6117 4.23374 13.0264 3.94502C12.6043 3.73625 12.1284 3.6851 11.665 3.651C11.3808 3.62296 11.0921 3.70329 10.8409 3.83135C10.6196 4.09317 10.6295 4.45463 10.5196 4.76457C10.2767 5.47651 9.88874 6.13692 9.40376 6.71094C9.19499 6.94699 9.00062 7.19516 8.80208 7.43955C8.64673 7.63733 8.3834 7.69455 8.14811 7.73433C7.54226 7.81011 6.933 7.69568 6.34723 7.54716C6.30707 7.52973 6.24834 7.53352 6.22144 7.49866C6.36693 6.7026 6.47946 5.89935 6.66739 5.11125C6.86442 4.26897 7.14669 3.44071 7.57181 2.68444C7.87417 2.1502 8.22502 1.63188 8.68955 1.22457C9.0961 0.863861 9.60457 0.606214 10.1479 0.544454C10.5848 0.499745 11.0588 0.578555 11.3994 0.87447Z"
          fill="white"
        />
        <path
          d="M6.29622 8.49255C6.33183 8.36448 6.45005 8.25422 6.58872 8.26104C6.90548 8.28491 7.20556 8.44102 7.42342 8.66987C7.66364 8.9207 7.81937 9.24427 7.90196 9.57959C7.5674 9.31171 7.25482 9.01769 6.92025 8.74944C6.81947 9.35529 6.99073 10.0274 7.42153 10.4753C7.6284 10.3749 7.8402 10.2843 8.04215 10.1737C8.17931 10.5829 8.28502 11.0027 8.44113 11.4059C8.89959 12.6032 9.64866 13.6906 10.6126 14.5374C11.0744 14.9322 11.5833 15.2978 12.1691 15.4812C12.5605 15.6127 12.9806 15.5047 13.3467 15.3463C13.8972 15.1035 14.3799 14.7303 14.8107 14.315C15.334 13.8016 15.7803 13.2101 16.1349 12.5687C16.1501 12.5425 16.1687 12.519 16.191 12.4986C16.3285 12.6933 16.4718 12.8847 16.6245 13.0681C15.9989 14.1274 15.1544 15.0826 14.0927 15.7192C13.5308 16.0435 12.8749 16.3053 12.2157 16.1867C11.6 16.0606 11.0483 15.7305 10.5512 15.3577C9.58311 14.6136 8.79653 13.6436 8.22554 12.5664C7.98759 12.1091 7.78072 11.6332 7.63636 11.138C7.37455 11.227 7.09795 11.1122 6.91305 10.9235C6.52696 10.5386 6.32577 10.0032 6.24165 9.47274C6.19922 9.14765 6.19505 8.80816 6.29622 8.49255Z"
          fill="white"
        />
        <path
          d="M5.46791 9.75156C5.50011 9.67806 5.51982 9.59584 5.57741 9.53711C5.66266 10.1441 5.88469 10.7446 6.2776 11.2209C6.50456 11.4983 6.80881 11.7184 7.15777 11.8124C7.54008 12.8388 8.11069 13.7928 8.8196 14.6268C8.89159 14.7143 8.92834 14.8234 8.9757 14.9253C9.27768 15.5899 9.65392 16.2204 10.0965 16.8009C10.4545 17.2794 10.8118 17.8015 10.8777 18.4115C10.9315 18.8961 10.7088 19.3493 10.486 19.7634C10.1783 20.3545 9.80662 20.9244 9.63763 21.5761C9.51525 22.041 9.54291 22.5582 9.78312 22.9818C10.0241 23.4194 10.45 23.7551 10.9384 23.8691C10.0264 24.5557 8.68168 24.6087 7.7227 23.9851C7.06191 23.563 6.57996 22.8651 6.43219 22.0936C6.29541 21.3624 6.45303 20.6239 6.49016 19.8911C6.54056 19.0613 6.35717 18.2175 5.96009 17.4863C5.66948 16.9297 5.22959 16.475 4.8397 15.9893C4.5059 15.5763 4.20468 15.1144 4.09783 14.5866C3.94514 13.8572 4.10162 13.1044 4.34373 12.4129C4.67034 11.5062 5.09432 10.6389 5.46791 9.75156Z"
          fill="white"
        />
        <path
          d="M14.3477 16.3301C14.5792 16.1933 14.8088 16.0516 15.0198 15.8838C15.0293 16.3089 15.0217 16.7344 15.0244 17.1599C15.0278 17.3766 15.0096 17.5953 15.0494 17.8101C14.8406 17.898 14.6261 17.9711 14.4151 18.0526C14.3571 17.8616 14.3435 17.6608 14.3454 17.4626C14.3465 17.0853 14.3439 16.7075 14.3477 16.3301Z"
          fill="white"
        />
        <path
          d="M12.5401 19.1015C13.4062 19.1345 14.2898 19.0011 15.0794 18.6328C14.8203 19.2936 14.6782 20.0264 14.8468 20.7281C14.9847 21.3472 15.3579 21.8761 15.7497 22.3615C16.0483 22.7442 16.3878 23.131 16.4912 23.6194C16.4995 23.7157 16.5401 23.8411 16.4525 23.9138C16.2623 24.0851 16.0327 24.2048 15.8133 24.3336C15.4026 24.5572 14.9893 24.7804 14.5535 24.9512C14.9571 25.143 15.3617 25.3332 15.7679 25.5188C16.1907 25.659 16.6552 25.6457 17.0819 25.5283C17.7684 25.3392 18.3732 24.8849 18.7592 24.2882C19.2488 23.5452 19.4348 22.6442 19.4647 21.7663C19.4932 21.2411 19.4644 20.7069 19.5951 20.1931C20.0979 20.3553 20.5923 20.561 21.0209 20.8751C21.912 21.5067 22.5482 22.4865 22.7588 23.5584C22.8312 23.9085 22.852 24.2677 22.8433 24.6246C22.8456 24.9528 22.8517 25.2809 22.849 25.609C21.6843 25.2691 20.4196 25.2627 19.256 25.6105C19.2469 26.3179 19.2003 27.0253 19.104 27.7262C19.0192 28.3078 18.9108 28.8936 18.6763 29.4354C18.5796 29.6378 18.4758 29.8522 18.2981 29.9951C14.4592 30.0038 10.6195 29.9947 6.78016 29.9996C6.72976 30.0019 6.6752 29.9954 6.64413 29.9507C6.35239 29.6279 6.21409 29.2017 6.09929 28.7898C5.83747 27.7535 5.76018 26.679 5.74199 25.6135C4.58258 25.2657 3.3167 25.2597 2.15577 25.6041C2.16297 24.8599 2.09135 24.101 2.27929 23.372C2.55891 22.2198 3.33412 21.1991 4.37153 20.6243C4.82166 20.364 5.32634 20.2272 5.81209 20.0506C5.74237 20.8258 5.62112 21.6204 5.8136 22.388C5.99244 23.1091 6.40657 23.7664 6.96885 24.2507C7.47618 24.6909 8.115 24.9785 8.78223 25.06C9.65633 25.1714 10.5733 24.9527 11.2932 24.4416C11.6982 24.1393 12.1157 23.8532 12.5117 23.5395C12.0513 23.4402 11.5932 23.3296 11.1333 23.228C10.7472 23.1526 10.4145 22.8563 10.2902 22.4839C10.1822 22.1493 10.2482 21.7871 10.3705 21.4662C10.6915 20.6383 11.267 19.9252 11.5015 19.061C11.8452 19.1144 12.1938 19.1072 12.5401 19.1015Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3698_2821">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

const CustomerInfo = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3698_2821)">
        <path
          d="M11.3994 0.87447C12.6558 -0.166728 14.5703 -0.284564 15.962 0.555442C16.4868 0.872196 16.918 1.32611 17.2578 1.83307C17.6549 2.42907 17.9311 3.09857 18.1274 3.78513C18.3676 4.61793 18.4991 5.47613 18.6529 6.3275C19.0659 8.69028 19.8135 10.9947 20.8687 13.1487C21.1369 13.7019 21.4113 14.2664 21.498 14.8818C21.6322 15.8032 21.4071 16.7747 20.8732 17.5389C20.367 18.2725 19.5482 18.7457 19.1311 19.5467C18.7889 20.2124 18.8272 20.9827 18.7995 21.7094C18.77 22.5297 18.609 23.3864 18.1069 24.057C17.639 24.6864 16.8104 25.0929 16.0249 24.9058C16.5754 24.9937 17.0582 24.5136 17.1544 24.0006C17.2321 23.5478 17.0612 23.0954 16.8304 22.7127C16.4652 22.1057 15.9188 21.6196 15.6263 20.9668C15.4092 20.486 15.3941 19.9332 15.5233 19.4266C15.7044 18.7075 16.1003 18.0675 16.5144 17.4613C16.8672 16.9611 17.2237 16.4587 17.4931 15.9071C17.7731 15.3361 17.972 14.6904 17.8663 14.0497C17.7864 13.5083 17.4594 13.0521 17.1279 12.6338C16.7732 12.1962 16.4527 11.7301 16.1878 11.2327C15.4069 9.77619 15.0296 8.14694 14.7632 6.52793C14.6829 6.07137 14.6355 5.60836 14.5472 5.15331C14.1164 4.66833 13.6117 4.23374 13.0264 3.94502C12.6043 3.73625 12.1284 3.6851 11.665 3.651C11.3808 3.62296 11.0921 3.70329 10.8409 3.83135C10.6196 4.09317 10.6295 4.45463 10.5196 4.76457C10.2767 5.47651 9.88874 6.13692 9.40376 6.71094C9.19499 6.94699 9.00062 7.19516 8.80208 7.43955C8.64673 7.63733 8.3834 7.69455 8.14811 7.73433C7.54226 7.81011 6.933 7.69568 6.34723 7.54716C6.30707 7.52973 6.24834 7.53352 6.22144 7.49866C6.36693 6.7026 6.47946 5.89935 6.66739 5.11125C6.86442 4.26897 7.14669 3.44071 7.57181 2.68444C7.87417 2.1502 8.22502 1.63188 8.68955 1.22457C9.0961 0.863861 9.60457 0.606214 10.1479 0.544454C10.5848 0.499745 11.0588 0.578555 11.3994 0.87447Z"
          fill="#1D2129"
        />
        <path
          d="M6.29622 8.49255C6.33183 8.36448 6.45005 8.25422 6.58872 8.26104C6.90548 8.28491 7.20556 8.44102 7.42342 8.66987C7.66364 8.9207 7.81937 9.24427 7.90196 9.57959C7.5674 9.31171 7.25482 9.01769 6.92025 8.74944C6.81947 9.35529 6.99073 10.0274 7.42153 10.4753C7.6284 10.3749 7.8402 10.2843 8.04215 10.1737C8.17931 10.5829 8.28502 11.0027 8.44113 11.4059C8.89959 12.6032 9.64866 13.6906 10.6126 14.5374C11.0744 14.9322 11.5833 15.2978 12.1691 15.4812C12.5605 15.6127 12.9806 15.5047 13.3467 15.3463C13.8972 15.1035 14.3799 14.7303 14.8107 14.315C15.334 13.8016 15.7803 13.2101 16.1349 12.5687C16.1501 12.5425 16.1687 12.519 16.191 12.4986C16.3285 12.6933 16.4718 12.8847 16.6245 13.0681C15.9989 14.1274 15.1544 15.0826 14.0927 15.7192C13.5308 16.0435 12.8749 16.3053 12.2157 16.1867C11.6 16.0606 11.0483 15.7305 10.5512 15.3577C9.58311 14.6136 8.79653 13.6436 8.22554 12.5664C7.98759 12.1091 7.78072 11.6332 7.63636 11.138C7.37455 11.227 7.09795 11.1122 6.91305 10.9235C6.52696 10.5386 6.32577 10.0032 6.24165 9.47274C6.19922 9.14765 6.19505 8.80816 6.29622 8.49255Z"
          fill="#1D2129"
        />
        <path
          d="M5.46791 9.75156C5.50011 9.67806 5.51982 9.59584 5.57741 9.53711C5.66266 10.1441 5.88469 10.7446 6.2776 11.2209C6.50456 11.4983 6.80881 11.7184 7.15777 11.8124C7.54008 12.8388 8.11069 13.7928 8.8196 14.6268C8.89159 14.7143 8.92834 14.8234 8.9757 14.9253C9.27768 15.5899 9.65392 16.2204 10.0965 16.8009C10.4545 17.2794 10.8118 17.8015 10.8777 18.4115C10.9315 18.8961 10.7088 19.3493 10.486 19.7634C10.1783 20.3545 9.80662 20.9244 9.63763 21.5761C9.51525 22.041 9.54291 22.5582 9.78312 22.9818C10.0241 23.4194 10.45 23.7551 10.9384 23.8691C10.0264 24.5557 8.68168 24.6087 7.7227 23.9851C7.06191 23.563 6.57996 22.8651 6.43219 22.0936C6.29541 21.3624 6.45303 20.6239 6.49016 19.8911C6.54056 19.0613 6.35717 18.2175 5.96009 17.4863C5.66948 16.9297 5.22959 16.475 4.8397 15.9893C4.5059 15.5763 4.20468 15.1144 4.09783 14.5866C3.94514 13.8572 4.10162 13.1044 4.34373 12.4129C4.67034 11.5062 5.09432 10.6389 5.46791 9.75156Z"
          fill="#1D2129"
        />
        <path
          d="M14.3477 16.3301C14.5792 16.1933 14.8088 16.0516 15.0198 15.8838C15.0293 16.3089 15.0217 16.7344 15.0244 17.1599C15.0278 17.3766 15.0096 17.5953 15.0494 17.8101C14.8406 17.898 14.6261 17.9711 14.4151 18.0526C14.3571 17.8616 14.3435 17.6608 14.3454 17.4626C14.3465 17.0853 14.3439 16.7075 14.3477 16.3301Z"
          fill="#1D2129"
        />
        <path
          d="M12.5401 19.1015C13.4062 19.1345 14.2898 19.0011 15.0794 18.6328C14.8203 19.2936 14.6782 20.0264 14.8468 20.7281C14.9847 21.3472 15.3579 21.8761 15.7497 22.3615C16.0483 22.7442 16.3878 23.131 16.4912 23.6194C16.4995 23.7157 16.5401 23.8411 16.4525 23.9138C16.2623 24.0851 16.0327 24.2048 15.8133 24.3336C15.4026 24.5572 14.9893 24.7804 14.5535 24.9512C14.9571 25.143 15.3617 25.3332 15.7679 25.5188C16.1907 25.659 16.6552 25.6457 17.0819 25.5283C17.7684 25.3392 18.3732 24.8849 18.7592 24.2882C19.2488 23.5452 19.4348 22.6442 19.4647 21.7663C19.4932 21.2411 19.4644 20.7069 19.5951 20.1931C20.0979 20.3553 20.5923 20.561 21.0209 20.8751C21.912 21.5067 22.5482 22.4865 22.7588 23.5584C22.8312 23.9085 22.852 24.2677 22.8433 24.6246C22.8456 24.9528 22.8517 25.2809 22.849 25.609C21.6843 25.2691 20.4196 25.2627 19.256 25.6105C19.2469 26.3179 19.2003 27.0253 19.104 27.7262C19.0192 28.3078 18.9108 28.8936 18.6763 29.4354C18.5796 29.6378 18.4758 29.8522 18.2981 29.9951C14.4592 30.0038 10.6195 29.9947 6.78016 29.9996C6.72976 30.0019 6.6752 29.9954 6.64413 29.9507C6.35239 29.6279 6.21409 29.2017 6.09929 28.7898C5.83747 27.7535 5.76018 26.679 5.74199 25.6135C4.58258 25.2657 3.3167 25.2597 2.15577 25.6041C2.16297 24.8599 2.09135 24.101 2.27929 23.372C2.55891 22.2198 3.33412 21.1991 4.37153 20.6243C4.82166 20.364 5.32634 20.2272 5.81209 20.0506C5.74237 20.8258 5.62112 21.6204 5.8136 22.388C5.99244 23.1091 6.40657 23.7664 6.96885 24.2507C7.47618 24.6909 8.115 24.9785 8.78223 25.06C9.65633 25.1714 10.5733 24.9527 11.2932 24.4416C11.6982 24.1393 12.1157 23.8532 12.5117 23.5395C12.0513 23.4402 11.5932 23.3296 11.1333 23.228C10.7472 23.1526 10.4145 22.8563 10.2902 22.4839C10.1822 22.1493 10.2482 21.7871 10.3705 21.4662C10.6915 20.6383 11.267 19.9252 11.5015 19.061C11.8452 19.1144 12.1938 19.1072 12.5401 19.1015Z"
          fill="#1D2129"
        />
      </g>
      <defs>
        <clipPath id="clip0_3698_2821">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomerInfo;
