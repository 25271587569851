const Windows = () => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8.5C8.44772 8.5 8 8.94772 8 9.5V16C8 16.5523 8.44772 17 9 17H15.5C16.0523 17 16.5 16.5523 16.5 16V9.5C16.5 8.94772 16.0523 8.5 15.5 8.5H9ZM10 15V10.5H14.5V15H10ZM8 20C8 19.4477 8.44772 19 9 19H15.5C16.0523 19 16.5 19.4477 16.5 20V26.5C16.5 27.0523 16.0523 27.5 15.5 27.5H9C8.44772 27.5 8 27.0523 8 26.5V20ZM10 21V25.5H14.5V21H10ZM19.5 19C18.9477 19 18.5 19.4477 18.5 20V26.5C18.5 27.0523 18.9477 27.5 19.5 27.5H26C26.5523 27.5 27 27.0523 27 26.5V20C27 19.4477 26.5523 19 26 19H19.5ZM20.5 25.5V21H25V25.5H20.5Z"
        fill="#ADADAD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9412 8.5C19.4214 8.5 19 8.92138 19 9.44118V15.5588C19 16.0786 19.4214 16.5 19.9412 16.5H26.0588C26.5786 16.5 27 16.0786 27 15.5588V9.44118C27 8.92138 26.5786 8.5 26.0588 8.5H19.9412ZM20.8824 14.6176V10.3824H25.1176V14.6176H20.8824Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default Windows;

export const WindowstActive = () => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="35" height="35" rx="5" fill="#FF8890" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 8.5C8.44772 8.5 8 8.94772 8 9.5V16C8 16.5523 8.44772 17 9 17H15.5C16.0523 17 16.5 16.5523 16.5 16V9.5C16.5 8.94772 16.0523 8.5 15.5 8.5H9ZM10 15V10.5H14.5V15H10ZM8 20C8 19.4477 8.44772 19 9 19H15.5C16.0523 19 16.5 19.4477 16.5 20V26.5C16.5 27.0523 16.0523 27.5 15.5 27.5H9C8.44772 27.5 8 27.0523 8 26.5V20ZM10 21V25.5H14.5V21H10ZM19.5 19C18.9477 19 18.5 19.4477 18.5 20V26.5C18.5 27.0523 18.9477 27.5 19.5 27.5H26C26.5523 27.5 27 27.0523 27 26.5V20C27 19.4477 26.5523 19 26 19H19.5ZM20.5 25.5V21H25V25.5H20.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9412 8.5C19.4214 8.5 19 8.92138 19 9.44118V15.5588C19 16.0786 19.4214 16.5 19.9412 16.5H26.0588C26.5786 16.5 27 16.0786 27 15.5588V9.44118C27 8.92138 26.5786 8.5 26.0588 8.5H19.9412ZM20.8824 14.6176V10.3824H25.1176V14.6176H20.8824Z"
        fill="white"
      />
    </svg>
  );
};
