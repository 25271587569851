import { Form, Input, InputNumber } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import AmountFormItem from './AmountFormItem';
type IDiscountSpecialProps = {};
const DiscountSpecial: React.FC<IDiscountSpecialProps> = () => {
  const dispatch = useAppDispatch();
  const specialDiscountStore =
    settingServiceSelectors.paymentSetting.specialDiscount();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const body = values.special_discount || [];
    dispatch(settingServiceActions.postSpecialDiscount.fetch(body));
  };

  const handleStaffChange = (
    val: number | null,
    name: number,
    targetName: string
  ) => {
    form.setFieldsValue({
      special_discount: {
        [name]: {
          [targetName]: 100 - (val || 0),
        },
      },
    });
  };

  useEffect(() => {
    if (specialDiscountStore) {
      form.setFieldsValue({ special_discount: specialDiscountStore });
    }
  }, [specialDiscountStore]);

  return (
    <DiscountSpecialStyled>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ special_discount: specialDiscountStore }}
        onFinish={onFinish}
      >
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Text variant="H9" color="text_3">
            Special Discount
          </Text>
          <Box>
            <Form.List name="special_discount">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...fieldKey }) => (
                    <Box key={key} display="flex" gap="2">
                      <ItemFormStyled>
                        <FormItemStyled
                          {...fieldKey}
                          name={[name, 'discountName']}
                          label="Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your discount Name!',
                            },
                          ]}
                        >
                          <Input size="large" placeholder="Discount name" />
                        </FormItemStyled>
                      </ItemFormStyled>
                      <ItemFormStyled>
                        <Form.Item label="Amount">
                          <AmountFormItem name={name} />
                        </Form.Item>
                      </ItemFormStyled>

                      <ItemFormStyled>
                        <FormItemStyled
                          name={[name, 'staffDiscountPercent']}
                          label="Staff"
                        >
                          <InputNumber
                            placeholder="0%"
                            style={{ width: '100%', height: '4rem' }}
                            min={0}
                            max={100}
                            formatter={(val) => `${val}%`}
                            parser={(val: any) => val?.replace('%', '') || ''}
                            onChange={(val) => {
                              handleStaffChange(
                                val,
                                name,
                                'ownerDiscountPercent'
                              );
                            }}
                          />
                        </FormItemStyled>
                      </ItemFormStyled>

                      <ItemFormStyled>
                        <FormItemStyled
                          name={[name, 'ownerDiscountPercent']}
                          label="Company"
                        >
                          <InputNumber
                            style={{ width: '100%', height: '4rem' }}
                            placeholder="0%"
                            min={0}
                            max={100}
                            formatter={(val) => `${val}%`}
                            parser={(val: any) => val?.replace('%', '') || ''}
                            onChange={(val) => {
                              handleStaffChange(
                                val,
                                name,
                                'staffDiscountPercent'
                              );
                            }}
                          />
                        </FormItemStyled>
                      </ItemFormStyled>
                      <Box className="center" onClick={() => remove(name)}>
                        <Icon type="circleClose" />
                      </Box>
                    </Box>
                  ))}
                  <Box mt="3">
                    <Button
                      ntype="LIGHT_BLUE"
                      icon="addPlus"
                      onClick={() =>
                        add({
                          discountType: 'MONEY',
                          discountValue: 0,
                          ownerDiscountPercent: 0,
                          staffDiscountPercent: 0,
                        })
                      }
                    >
                      Add New
                    </Button>
                  </Box>
                </>
              )}
            </Form.List>
          </Box>
          <Box display="flex" gap="3" mt="4" justifyContent="end">
            <Button width="10rem" ntype="SECONDARY" htmlType="reset">
              Reset
            </Button>
            <Form.Item>
              <Button width="10rem" ntype="PRIMARY" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Box>
        </Box>
      </Form>
    </DiscountSpecialStyled>
  );
};

export default DiscountSpecial;
const DiscountSpecialStyled = styled.div`
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  flex-grow: 1;
`;
const ItemFormStyled = styled.div`
  // flex-grow: 1;
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;
