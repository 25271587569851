import React, { useState } from 'react';
import Icon from 'assets/Icons';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import { Form } from 'antd';
import settingServiceActions from 'features/settingService/services/actions';
import { useAppDispatch } from 'store/hooks';
type IDeleteButtonProps = {
  index: number;
};
const DeleteButton: React.FC<IDeleteButtonProps> = ({ index }) => {
  const form = Form.useFormInstance();
  const dispatch = useAppDispatch();
  const [visiblePW, setVisiblePW] = useState(false);

  const handleClose = () => {
    setVisiblePW(false);
  };

  const handleShowModal = () => {
    setVisiblePW(true);
  };

  const onVerifyAdminSuccess = () => {
    const services = form.getFieldValue('services');
    const servicesSelected = [services[index]?.id];
    dispatch(settingServiceActions.deleteMultiple.fetch(servicesSelected));
    setVisiblePW(false);
  };

  return (
    <DeleteButtonStyled>
      <div className="button-item" onClick={handleShowModal}>
        <Icon type="deleteIcon" />
      </div>

      {visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={handleClose}
        />
      )}
    </DeleteButtonStyled>
  );
};

export default DeleteButton;
const DeleteButtonStyled = styled.div`
  .button-item {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 0.5rem;
    height: 100%;
  }
`;
