import Header from 'features/checkIn/components/Header';
import styled from 'styled-components';
import TicketDetailBox from './component/TicketDetailBox';
import ButtonCountDown from './component/ButtonCountDown';
import checkInSelectors from 'features/checkIn/services/selectors';

type service = {
  serviceId: string;
  serviceName: string;
  price: number;
};

export type StaffServices = {
  id: string;
  staffName: string;
  services: service[];
};

const CheckInTicketDetail = () => {
  const detail = checkInSelectors.getAppointmentDetail();
  return (
    <CheckInTicketDetailStyled>
      <ButtonCountDown></ButtonCountDown>
      <Header>WALK IN</Header>
      <TicketDetailBox
        data={detail}
      />
    </CheckInTicketDetailStyled>
  );
};
export default CheckInTicketDetail;

const CheckInTicketDetailStyled = styled.div`
`;
