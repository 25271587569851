import Sidebar from 'components/layout/Sidebar';
import React from 'react';
import styled from 'styled-components';
import FormEditCustomer from './components/FormEditCustomer';
type IEditCustomersProps = {};
const EditCustomers: React.FC<IEditCustomersProps> = () => {
  return (
    <EditCustomersStyled>
      <Sidebar />
      <BodyStyled>
        <FormEditCustomer />
      </BodyStyled>
    </EditCustomersStyled>
  );
};

export default EditCustomers;
const EditCustomersStyled = styled.div`
  display: flex;
`;

const BodyStyled = styled.div`
  background: var(--fill-fill-3, #e5e5e5);
  flex-grow: 1;
  display: flex;
  padding: 1.5rem;
`;
