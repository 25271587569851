import { Table, TableColumnsType } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import StaffSelector from 'features/report/components/StaffSelector';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { ISalaryStaffDetail, ITableSalaryStaffDetail } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import AppointmentDatePicker from '../../../components/DatePicker';
import SwitchCalendarType from '../../../components/SwitchCalendarType';
import { ReportType } from '../../../constants';
import DashboardStaffPayrollReport from '../components/Dashboard';
interface IEmployeeSalaryStaffDetail { }

interface DataType extends ITableSalaryStaffDetail {
    key?: string,
}
const columns: TableColumnsType<DataType> = [
    {
        title: <div>Time</div>,
        dataIndex: 'time',
        key: 'time',
        render: (_, record) => (
            <Text variant='CONTENT_1'>{moment(record.createdDate).format('MM-DD-YYYY HH:mm')}</Text>
        ),
    },
    {
        title: 'Tickets',
        dataIndex: 'tickets',
        key: 'tickets',
        width: 100,
        render: (_, record) => (
            <Text variant="CONTENT_2" color="text_3">
                #{record.ticketNumber}
            </Text>
        ),
    },
    {
        title: 'Services',
        dataIndex: 'services',
        key: 'services',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {record.itemName}
            </Text>
        ),
    },
    {
        title: 'Sell Price',
        dataIndex: 'sell_price',
        key: 'sell_price',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.sellPrice)}
            </Text>
        ),
    },
    {
        title: 'Commission',
        dataIndex: 'commission',
        key: 'commission',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.commission)}
            </Text>
        ),
    },
    {
        title: 'Discounts',
        dataIndex: 'discount',
        key: 'discount',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.discount)}
            </Text>
        ),
    },
    {
        title: 'Tips',
        dataIndex: 'tips',
        key: 'tips',
        render: (_, record) => (
            <div style={{ display: 'flex' }}>
                <Text variant="CONTENT_1" color="text_3">
                    {formatCurrency(record.tip)}
                </Text>
            </div>
        ),
    },
    {
        title: 'Cash',
        dataIndex: 'cash',
        key: 'cash',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.cashInPay)}
            </Text>
        ),
    },
    {
        title: 'Check',
        dataIndex: 'check',
        key: 'check',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.checkInPay)}
            </Text>
        ),
    },
];

const EmployeeSalaryStaffDetail: React.FC<IEmployeeSalaryStaffDetail> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { staff_id = '' } = useParams();
    const [searchText,] = useState<string>('');
    const [dataTable, setDataTable] = useState<ITableSalaryStaffDetail[]>([]);
    const [dataDashboard, setDataDashboard] = useState<ISalaryStaffDetail>();
    const [, setListStaff] = useState<IStaffItemData[]>([]);
    const salaryStaffDetail = reportSelectors.getSalaryStaffDetail();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.STAFF_SALARY_DETAIL];
    const viewType = reportSelectors.getViewType()?.[ReportType.STAFF_SALARY_DETAIL];
    const staffData = shopSelectors.data.staffs()?.filter(f => !f.anyBody);

    const staffSelected = useMemo(() => staffData.find(o => o.id === staff_id), [staff_id, staffData]);

    useEffect(() => {
        dispatch(reportActions.setParams({
            [ReportType.STAFF_SALARY_DETAIL]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
            }
        }));
        staff_id && dispatch(reportActions.getSalaryStaffDetail.fetch({
            staffId: staff_id,
            startDate: dataAppointment?.startTime || moment().format(DATE_FORMAT),
            endDate: dataAppointment?.endTime || moment().format(DATE_FORMAT)
        }));
    }, []);

    useEffect(() => {
        dataAppointment && staff_id && dispatch(reportActions.getSalaryStaffDetail.fetch({
            staffId: staff_id,
            startDate: dataAppointment?.startTime,
            endDate: dataAppointment?.endTime
        }));
    }, [dataAppointment, staff_id]);

    useEffect(() => {
        const _dashboardData = { ...salaryStaffDetail };
        delete _dashboardData.details;
        if (salaryStaffDetail?.details) {
            setDataTable(salaryStaffDetail.details);
        }
        setDataDashboard(_dashboardData);
    }, [salaryStaffDetail]);

    useEffect(() => {
        setListStaff(staffData);
    }, [staffData]);

    useEffect(() => {
        let filterData = staffData || [];
        if (searchText.trim().length == 0) {
        } else {
            const rexSearch = new RegExp(searchText, 'i');
            filterData = staffData.filter(f => {
                return (f.firstName.search(rexSearch) !== -1 ||
                    f.email.search(rexSearch) !== -1 ||
                    f.lastName.search(rexSearch) !== -1 ||
                    f.phone.search(searchText) !== -1 ||
                    f.staffSalaryType && f.staffSalaryType.search(searchText) !== -1
                );
            });
        }
        setListStaff(filterData);
    }, [searchText]);

    const choseStaffInStaffListCondition = (value: IStaffItemData | null) => {
        const _staffId = value?.id;
        navigate(`/store/${storage.shop_id.get()}/report/employee-salary/staff/${_staffId}`);
    };

    const handlePrint = () => {
        if (viewType && viewType != CALENDAR_VIEW_TYPE.DAY_VIEW) {
            return navigate(`/store/${storage.shop_id.get()}/report/print/staff-salary-date-range/${staff_id}`);
        }
        navigate(`/store/${storage.shop_id.get()}/report/print/staff-salary/${staff_id}`);

    };

    const handleBack = () => {
        navigate(`/store/${storage.shop_id.get()}/report/employee-salary`);
    };

    return (
        <StyledEmployeeSalaryStaffDetail>
            <div style={{ background: '#FFF' }}>
                <Sidebar />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, maxWidth: 'calc(100% - 104px)' }}>
                <HeaderBox>
                    <StaffSelector disabledAll size='middle' data={staffData} onChange={choseStaffInStaffListCondition} selected={staffSelected} />
                    <AppointmentDatePicker size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.STAFF_SALARY_DETAIL} />
                    <SwitchCalendarType actions={reportActions} selectors={reportSelectors} type={ReportType.STAFF_SALARY_DETAIL} />
                    <Button disabled={dataTable.length == 0} icon="print" onClick={handlePrint}>
                        Preview
                    </Button>
                    <Button icon="back" onClick={handleBack}>
                        Back
                    </Button>
                </HeaderBox>
                <Content>
                    <DashboardStaffPayrollReport data={dataDashboard} />
                    <TableBox>
                        <Table
                            loading={false}
                            dataSource={dataTable}
                            columns={columns}
                            pagination={{ pageSize: 50 }}
                            scroll={{ y: 500 }}
                        />
                    </TableBox>
                </Content>
            </div>
        </StyledEmployeeSalaryStaffDetail>
    );
};
export default EmployeeSalaryStaffDetail;

const StyledEmployeeSalaryStaffDetail = styled.div`
    display: flex;
    background-color: ${colorTheme.fill_3};
    height: 100vh;
    flex:1;
    width: 100vw;
    .search-staff {
        flex:1;
        input {
            height: 38px;
        }
        .ant-input-suffix {
            height: 38px;
        }
    }
`;

const HeaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 20px;
    padding-left: 20px;
    flex-wrap: wrap;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
`;

const TableBox = styled.div`
  .ant-table-thead > tr > th {
    background: var(--fill-fill-2, #adadad);
    color: white; /* White text */
  }
`;