import CarouserBox from 'components/Carosel/CarouserBox';
import CategoryItem from 'components/Category';
import Spin from 'components/Spin';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { ICategory } from 'features/cashier/services/types/api';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';

const Category = () => {
  const loading = cashierSelectors.loading.getCategories();
  const dispatch = useAppDispatch();
  const categories = cashierSelectors.getCategories();
  const categoryActive = cashierSelectors.getActiveCategory();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();

  const handleClickCategory = (item: ICategory) => {
    dispatch(cashierActions.selectCategory(item));
  };

  const renderItem = useCallback((item: ICategory) => {
    return (
      <CategoryItem
        onClick={() => handleClickCategory(item)}
        selected={item.services.some(o => !!serviceIdsSelected.find(s => s.id === o.id))}
        isActive={categoryActive?.id === item.id}
      >
        <Text variant='CONTENT_2' color='text_3' textTransform='uppercase'>{item.categoryName}</Text>
      </CategoryItem>
    );
  }, [categories, categoryActive, serviceIdsSelected]);

  return (
    <>
      <CarouserBox
        content="CATEGORIES"
        list={categories}
        rowPerPage={4}
        colSpan={24}
        md={24}
        lg={12}
        sm={24}
        itemContent={renderItem}
      />
      {loading && <Spin />}
    </>
  );
};

export default Category;
