import { Col, Form, Row, Switch } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import colorTheme from 'constants/color';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import {
  DataItem,
  GroupedFeature,
  GroupedUserType,
  IBodyItemNotificationPut,
} from 'features/settingService/services/types/generalSetting';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const types = ['sms', 'email', 'notification'];
const roles = ['Owner', 'Staffs', 'Customers'];

const convertString = (str: string) =>
  str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

const groupData = (arr: DataItem[]): GroupedFeature[] => {
  type TempResult = Record<string, Record<string, GroupedUserType[]>>;

  const tempResult: TempResult = {};

  arr.forEach((item) => {
    const { feature, type, id, userType, enable } = item;

    if (!tempResult[feature]) {
      tempResult[feature] = {};
    }

    if (!tempResult[feature][type]) {
      tempResult[feature][type] = [];
    }

    tempResult[feature][type].push({
      id,
      userType,
      enable,
    });
  });

  const userTypeOrder: Record<string, number> = {
    OWNER: 1,
    STAFF: 2,
    CUSTOMER: 3,
  };

  const sortUserTypes = (a: GroupedUserType, b: GroupedUserType) => {
    return userTypeOrder[a.userType] - userTypeOrder[b.userType];
  };

  // Convert nested object to desired array structure and sort userTypes
  return Object.entries(tempResult).map(([featureKey, typeObj]) => ({
    features: featureKey,
    items: Object.entries(typeObj).map(([typeKey, users]) => ({
      type: typeKey,
      items: users.sort(sortUserTypes),
    })),
  }));
};

type INotificationProps = {};
const Notification: React.FC<INotificationProps> = () => {
  const [isFieldsChange, setIsFieldsChange] = useState<IBodyItemNotificationPut[]>([]);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const notificationStore =
    settingServiceSelectors.notificationSetting.notification();
  const convertNotification = groupData(notificationStore);

  const onFinish = () => {
    dispatch(settingServiceActions.postNotification.fetch(isFieldsChange));
  };

  useEffect(() => {
    if (notificationStore.length > 0) {
      form.setFieldsValue({ notifications: convertNotification });
    }
  }, [notificationStore]);

  useEffect(() => {
    dispatch(settingServiceActions.getNotification.fetch());
  }, []);

  const handleChange = (id: string, enable: boolean) => {
    const isHave = isFieldsChange.some((item) => item.id === id);
    if (isHave) {
      setIsFieldsChange((preState) => preState.filter((item) => item.id !== id));
    } else {
      setIsFieldsChange((preState) => [...preState, { id, enable }]);
    }
  };

  return (
    <NotificationStyled>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ notifications: convertNotification }}
      >
        <Box bgColor="fill_1" px="3" borderRadius="5px" b="fill_3">
          <Row className="line_box">
            <Col flex={3}></Col>
            <Col span={7}>
              <Box className="center">SMS</Box>
            </Col>
            <Col span={7}>
              <Box className="center">Email</Box>
            </Col>
            <Col span={7}>
              <Box className="center">Push Notification</Box>
            </Col>
          </Row>
          <Row
            style={{ backgroundColor: colorTheme.fill_5, borderRadius: '5px' }}
            className="header_row_border"
          >
            <Col span={3} className="header_col_border">
              <Box display="flex" alignItems="center" height="100%">
                Features
              </Box>
            </Col>
            {types.map((type) => (
              <Col key={type} span={7} className="header_col_border">
                <Row>
                  {roles.map((role) => (
                    <Col key={role} span={8}>
                      <Box className="center">{role}</Box>
                    </Col>
                  ))}
                </Row>
              </Col>
            ))}
          </Row>
          <Form.List name="notifications">
            {(features) => (
              <>
                {features.map((featureField) => (
                  <Row key={featureField.key} className="body_row_border">
                    <Col span={3} className="body_col_border">
                      {convertString(
                        convertNotification[featureField.name]?.features
                      )}
                    </Col>

                    <Form.List name={[featureField.name, 'items']}>
                      {(types) => (
                        <>
                          {types.map((typeField) => (
                            <Col
                              span={7}
                              key={typeField.key}
                              className="body_col_border"
                            >
                              <Row>
                                <Form.List name={[typeField.name, 'items']}>
                                  {(userTypes) => (
                                    <>
                                      {userTypes.map((userTypeField) => (
                                        <Col span={8} key={userTypeField.key}>
                                          <Box className="center">
                                            <Form.Item
                                              {...userTypeField}
                                              name={[
                                                userTypeField.name,
                                                'enable',
                                              ]}
                                              valuePropName="checked"
                                              noStyle
                                            >
                                              <Switch
                                                onChange={(checked) => {
                                                  const id =
                                                    convertNotification[
                                                      featureField.name
                                                    ].items[typeField.name]
                                                      .items[userTypeField.name]
                                                      .id;
                                                  handleChange(id, checked);
                                                }}
                                              />
                                            </Form.Item>
                                          </Box>
                                        </Col>
                                      ))}
                                    </>
                                  )}
                                </Form.List>
                              </Row>
                            </Col>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </Row>
                ))}
              </>
            )}
          </Form.List>
        </Box>
        <Box display="flex" gap="3" mt="4" justifyContent="end">
          <Button width="10rem" ntype="SECONDARY" htmlType="reset">
            Close
          </Button>
          <Button width="10rem" ntype="PRIMARY" htmlType="submit">
            Save
          </Button>
        </Box>
      </Form>
    </NotificationStyled>
  );
};

export default Notification;
const NotificationStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-col {
    padding: 0.5rem;
  }
  .line_box {
    .ant-col:not(:last-child) {
      border-right: 1px solid ${colorTheme.line_1};
    }
  }
  .header_row_border {
    .header_col_border:not(:last-child) {
      border-right: 1px solid ${colorTheme.line_1};
    }
  }
  .body_row_border {
    .body_col_border {
      border-bottom: 1px solid ${colorTheme.line_1};
    }
    .body_col_border:not(:last-child) {
      border-right: 1px solid ${colorTheme.line_1};
    }
  }
`;
