import fetch from 'services/request';
import { cleanParams } from 'utils/updateParamsFilter';
import {
  IGetCustomersParam,
  IReferralsParams,
  ICouponsParams,
} from './types/api';
import { IAppointmentParams } from './types/appointment';
import { CustomerDetailsForm } from './types/customer';
import { ITransactionsParams } from './types/reducer';

const baseURL = 'api/v1';

export const getCustomers = (params: IGetCustomersParam) => {
  const _params = cleanParams(params);
  return fetch({
    method: 'get',
    url: `${baseURL}/customer`,
    params: _params,
  });
};

const getCustomerDetails = (id: string) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/customer/get-detail/${id}`,
  });
};
const getReferrals = (params: IReferralsParams) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/customer/referrals/${params.customerId}`,
    params,
  });
};
const getAppointments = async (params: IAppointmentParams) => {
  const _params: IAppointmentParams = {
    page: params.page,
    size: params.size,
  };
  return fetch({
    method: 'get',
    url: `${baseURL}/customer/appointment-list/${params.customerId}`,
    params: _params,
  });
};
const getTransactions = async (params: ITransactionsParams) => {
  const _params: ITransactionsParams = {
    page: params.page,
    size: params.size,
  };
  return fetch({
    method: 'get',
    url: `${baseURL}/customer/transaction-bill/${params.customerId}`,
    params: _params,
  });
};

const getCoupons = (params: ICouponsParams) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/customer/history-redeem-coupon/${params.customerId}`,
    params,
  });
};

const getSummaryReport = async (id: string) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/customer/summary-report/${id}`,
  });
};
const getCustomersActive = async (params: {
  lastPhoneNumber?: number | string;
}) => {
  const _params = cleanParams(params);
  return fetch({
    method: 'get',
    url: `${baseURL}/customer`,
    params: _params,
  });
};
const deleteCustomer = async (customerId: string) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/customer/delete/${customerId}`,
  });
};
const editCustomer = async (body: CustomerDetailsForm) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/customer/update`,
    body,
  });
};

const customerApis = {
  getCustomers,
  getCustomerDetails,
  getReferrals,
  getAppointments,
  getTransactions,
  getSummaryReport,
  getCustomersActive,
  deleteCustomer,
  editCustomer,
  getCoupons,
};

export default customerApis;
