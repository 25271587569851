import { Radio } from 'antd';
import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import cashierActions from 'features/cashier/services/actions';
import { IGiftCardResult } from 'features/cashier/services/types/giftCard';
import moment from 'moment';
import { useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import CardTicketItem from '../CardTicketItem';
import CheckGiftCard from './CheckGiftCard';
import IssueGiftCard, { IssueFormValues } from './IssueGiftCard';

export enum GIFT_CARD_RADIO_TYPE {
  ISSUED = 'ISSUED',
  BALANCE = 'BALANCE',
}

const GiftCard = () => {
  const [visible, setVisible] = useState(false);
  const [, startTransition] = useTransition();
  const dispatch = useAppDispatch();

  const [radioType, setRadioType] = useState<GIFT_CARD_RADIO_TYPE>(GIFT_CARD_RADIO_TYPE.ISSUED);

  const [form] = useForm();

  const reset = () => {
    setRadioType(GIFT_CARD_RADIO_TYPE.ISSUED);
    form.resetFields();
  };
  const handleClose = () => startTransition(() => {
    setVisible(false);
    reset();
  });

  const handleFinish = async (values: IssueFormValues) => {
    const payload: IGiftCardResult = {
      amount: values.amount || 0,
      code: values.giftNumber,
      expireDate: moment(values.expiration).format('MM-DD-YYYY'),
    };
    dispatch(cashierActions.addGiftCard(payload));
    handleClose();
  };

  const validation = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!await validation()) return;
    form.submit();
  };

  const handleOpen = () => {
    setVisible(true);
  };

  return (
    <>
      <CardTicketItem
        icon={'cardGiftCard'}
        content={'Gift Card'}
        nbgcolor={colorTheme.info_bg_3}
        textColor={colorTheme.text_3}
        border_color={colorTheme.line_3}
        onClick={handleOpen}
      />
      <Modal
        visible={visible}
        onClose={handleClose}
        okTitle={'Issue'}
        onSubmit={handleSubmit}
        modalTitle='GIFT CARDS'
        width={650}
        hiddenOkButton={radioType === GIFT_CARD_RADIO_TYPE.BALANCE}
      >
        <Text variant='CONTENT_1' color='text_3' mb={1}>Status</Text>
        <RadioGroupStyled onChange={(e: any) => {
          form.resetFields();
          setRadioType(e.target.value);
        }} value={radioType}>
          <Radio value={GIFT_CARD_RADIO_TYPE.ISSUED}>Issued Gift Cards</Radio>
          <Radio value={GIFT_CARD_RADIO_TYPE.BALANCE}>Gift Card Balance</Radio>
        </RadioGroupStyled>
        <Text mb={2} />
        <Form form={form} onFinish={handleFinish}>
          {visible && <>
            {radioType === GIFT_CARD_RADIO_TYPE.ISSUED && <IssueGiftCard />}
            {radioType === GIFT_CARD_RADIO_TYPE.BALANCE && <CheckGiftCard />}
          </>}
        </Form>
      </Modal>
    </>
  );
};

export default GiftCard;

const RadioGroupStyled = styled(Radio.Group)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .ant-radio-wrapper {
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: #6FABB6;
        background-color: #6FABB6;
      }
    }
    .ant-radio-inner {
      border-color: #E5E6EB;
      border-width: 5px; 
    }
  }
`;