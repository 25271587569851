import { DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core';
import ActionBtn from 'components/ActionBtn';
import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import cashierApis from 'features/cashier/services/apis';
import cashierSelectors from 'features/cashier/services/selectors';
import { IApiBodySplitTicket, IApiBodySplitTicketNewBill, IApiBodySplitTicketNewItem, IApiBodySplitTicketService, IService } from 'features/cashier/services/types/api';
import { ISplitTicketItem, IStaffTicketResItem } from 'features/user/services/types/ticket';
import { first, last, remove, sumBy } from 'lodash';
import React, { useCallback, useRef, useState, useTransition } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { v4 as uuid } from 'uuid';
import { Draggable } from '../DragAndDrop/Draggable';
import { Droppable } from '../DragAndDrop/Droppable';
import useRefreshScreen from 'hooks/useRefreshPage';
type DraggableData = {
  service: IService;
  staff: IStaffTicketResItem;
};

const geneNewTicket = () => {
  const result: ISplitTicketItem = {
    billId: uuid(),
    customerName: 'New Ticket',
    totalPrice: 0,
    customerPhone: '',
    staffs: [],
    ticketNumber: '',
    isDraft: true,
  };
  return result;
};
type ISplitButtonProps = {};
const SplitButton: React.FC<ISplitButtonProps> = () => {
  const setPageLoading = useSetLoadingPage();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const detail = cashierSelectors.getTicketDetails();
  const ticketDetailStore = cashierSelectors.getInfoBillTicketDetail();
  const [ticketDetail, setTicketDetail] = useState<ISplitTicketItem | null>(null);
  const [, startTransition] = useTransition();
  const refreshScreen = useRefreshScreen();

  const handleShowModal = () => startTransition(() => {
    setOpen(true);
    setTicketDetail({ ...ticketDetailStore });
    setNewTickets([geneNewTicket(), geneNewTicket()]);
  });

  const [newTickets, setNewTickets] = useState<ISplitTicketItem[]>([geneNewTicket(), geneNewTicket()]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (ticketDetail?.staffs?.length === 1 && first(ticketDetail.staffs)?.services?.length === 1) return;

    const { over, active } = event;
    const activeData = active.data.current || {};
    if (!activeData || !over) return;
    const { service, staff } = activeData as DraggableData;
    setNewTickets(newTickets.map(o => {
      if (o.billId === over?.id) {
        const staffs = [...o.staffs ?? []];
        const exist = staffs.find(s => s.staffId === staff.staffId);
        if (exist) {
          exist.services.push(service);
        } else {
          staffs.push({
            avatar: '',
            services: [service],
            staffId: staff.staffId,
            staffName: staff.staffName,
          });
        }
        return ({
          ...o,
          staffs,
        });
      }
      return o;
    }));

    const staffs = [...ticketDetail?.staffs || []];

    const exist = staffs.find(_staff => _staff.staffId === staff.staffId);
    if (exist) {
      const serFilters = exist.services.filter(ser => ser.uuid_local !== service.uuid_local);
      if (serFilters.length === 0) {
        remove(staffs, sta => sta.staffId === staff.staffId);
      } else {
        exist.services = [...serFilters];
      }
    }

    setTicketDetail({
      ...ticketDetail,
      staffs,
    } as ISplitTicketItem);
  };

  const handleAddNewTicket = () => {
    if (last(newTickets)?.staffs.length === 0) return;
    const _newTickets = [...newTickets];
    _newTickets.push(geneNewTicket());
    setNewTickets(_newTickets);
    setTimeout(() => {
      scrollRef.current?.scrollBy({ behavior: 'smooth', left: -Number.MIN_SAFE_INTEGER });
    }, 0);
  };

  const handleSubmitModal = async () => {
    setOpen(false);
    setPageLoading(true);
    const payload: IApiBodySplitTicket = {
      billCurrent: {
        billId: ticketDetail?.billId || '',
        items: ticketDetail?.staffs.map(staff => ({
          staffId: staff.staffId,
          service: staff?.services?.map(o => ({
            itemName: o.itemName,
            price: o.price,
            serviceId: o.itemId,
          }) as IApiBodySplitTicketService) || []
        }) as IApiBodySplitTicketNewBill) || [],
      },
      newBills: newTickets.filter(o => o.staffs.length).map(o => ({
        items: o.staffs.map(sta => ({
          staffId: sta.staffId,
          service: sta.services.map(ser => ({
            itemName: ser.itemName,
            price: ser.price,
            serviceId: ser.itemId,
          }) as IApiBodySplitTicketService)
        }) as IApiBodySplitTicketNewBill)
      }) as IApiBodySplitTicketNewItem)
    };
    try {
      const res: IResponseDataBody<boolean> = await cashierApis.splitTicket(payload);
      if (res.data.data) refreshScreen();
    } catch (error) {

    } finally {
      setPageLoading(false);
    }
  };

  return (
    <SplitButtonStyled>
      <ActionBtn
        ntype="SECONDARY"
        icon="split"
        label='Split Ticket'
        onClick={handleShowModal}
        disabled={!!detail?.checkInId || !detail?.items?.length}
      />
      <Modal
        width={847}
        modalTitle="Split Ticket"
        className='modal-split-ticket'
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        noneBodyStyle
        noneFooterStyle
        containerPadding={2}
        footerPadding={2}
        okTitle="Done"
      >
        <Box>
          <DndContext onDragEnd={handleDragEnd}>
            <TicketContainerStyled >
              <TicketDisplay item={ticketDetail} />
              <div className='flex-grow' ref={scrollRef}>
                {newTickets.map(o => (<TicketDisplay key={o.billId} item={o} />))}
              </div>
            </TicketContainerStyled>
            <DragOverlay />
          </DndContext>
          <Box>
            <Button ntype="LIGHT_BLUE" width="100%" onClick={handleAddNewTicket}>
              + Add New Ticket
            </Button>
          </Box>
        </Box>
      </Modal>
    </SplitButtonStyled>
  );
};

export default SplitButton;
const SplitButtonStyled = styled.div``;
const TicketContainerStyled = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  position: relative;
  .ticket-item {
    width: 33.33%;
    min-width: 33.33%;
    height: 21.5rem;
    position:relative;
    z-index:2;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .flex-grow {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    .ticket-item {
      width: 50%;
      min-width: 50%;
      position:relative;
      z-index:1;
    }  
  }
  
  .ticket-item:last-child {
    .ticket-wrapper {
      margin-right: 0;
    }
  }
  .ticket-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
  }
`;

const TicketStyled = styled.div<{ overflow: boolean }>`
  margin-right: 1rem;
  background-color: ${colorTheme.fill_3};
  min-height: calc(100% - 1rem);
  // overflow-y:${({ overflow }) => overflow ? 'auto' : 'unset'}
`;

const TicketDisplay = ({ item }: { item: ISplitTicketItem | null }) => {
  const TicketContent = useCallback(() => {
    if (!item) return null;
    const total = sumBy(item.staffs, o => sumBy(o.services, s => s.price) || 0) || 0;
    return (
      <TicketStyled className="ticket-wrapper" overflow={!!item.isDraft}>
        <Box className="space-between">
          {!item.isDraft ? <Text variant="H9" color="text_3">
            #{item.ticketNumber} - {item.customerName}
          </Text> : <Text variant="H9" color="text_3">
            New Ticket
          </Text>}
          <Text variant="H9" color="text_3">
            {formatCurrency(total)}
          </Text>
        </Box>
        {/* mapping here */}
        {item.staffs.map((staff) => (
          <Box key={staff.staffId}>
            <Box bb="line_3">
              <Text variant="CONTENT_2" color="text_3">
                {staff.staffName}
              </Text>
            </Box>
            {staff.services.map((service, index) => {
              const col: DraggableData = { service, staff };
              return (
                item.isDraft ? <Box key={service.uuid_local} className="service-item space-between" px="2" py="3">
                  <Text variant="BODY_1" color="text_3">
                    {service.itemName}
                  </Text>
                  <Box display="flex" gap="2" alignItems="center">
                    <Text variant="BODY_1" color="text_3">
                      {formatCurrency(service.price)}
                    </Text>
                  </Box>
                </Box> :
                  <Draggable key={service.uuid_local} id={`${staff.staffId}_${service.itemId}_${index}`} data={col}>
                    <Box className="service-item space-between" px="2" py="3">
                      <Text variant="BODY_1" color="text_3">
                        {service.itemName}
                      </Text>
                      <Box display="flex" gap="2" alignItems="center">
                        <Text variant="BODY_1" color="text_3">
                          {formatCurrency(service.price)}
                        </Text>
                      </Box>
                    </Box>
                  </Draggable>
              );
            })}
          </Box>
        ))}
      </TicketStyled>
    );
  }, [item]);

  if (!item?.isDraft) return <div className='ticket-item'>
    <TicketContent />
  </div>;

  return (
    <Droppable
      id={item.billId}
      className="ticket-item"
    >
      <TicketContent />
    </Droppable>
  );
};