import { Avatar, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import userActions from 'features/user/services/actions';
import userSelectors from 'features/user/services/selectors';
import { IHistoryClockInOutContentItem } from 'features/user/services/types/clock-in-out';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import close from './close-circle-fill.png';

const columns: ColumnsType<IHistoryClockInOutContentItem> = [
  {
    title: 'No',
    align: 'center',
    render(value, record, index) {
      return index + 1;
    },
  },
  {
    title: 'Staff',
    dataIndex: 'staffName',
    align: 'center',
    render(value, record) {
      return (
        <Row align={'middle'} gutter={8} justify={'center'}>
          <Col>
            <Avatar src={record.avatar}>
              {get(record.staffName, [0], '')}
            </Avatar>
          </Col>
          <Col>
            <Text variant="CONTENT_1" color="text_3">
              {record.staffName ?? ''}
            </Text>
          </Col>
        </Row>
      );
    },
  },
  {
    title: 'Time',
    dataIndex: 'time',
    align: 'center',
    render: (text) => {
      return <>{text ? moment(text).format('LT') : null}</>;
    },
  },
  {
    title: 'Type',
    dataIndex: 'type',
    align: 'center',
    render: (value) => <>{value === 'CLOCK_IN' ? 'Clock In' : 'Clock Out'}</>,
  },
];

const ButtonClockInOutHistory = () => {
  const dispatch = useAppDispatch();
  const isClockIn = userSelectors.staff.isClockIn();
  const [visible, setVisible] = useState(false);
  const totalElement = userSelectors.historyClockInOut.totalSize();
  const params = userSelectors.historyClockInOut.params();
  const data = userSelectors.historyClockInOut.data();
  const loading = userSelectors.historyClockInOut.loading();

  useEffect(() => {
    dispatch(
      userActions.setHistoryClockInOutParams({
        page: 1,
      })
    );
  }, []);
  
  return (
    <>
      <Button
        ntype="DEFAULT"
        disabled={!isClockIn}
        icon="report"
        onClick={() => setVisible(true)}
      >
        Clock In/Out History
      </Button>
      <Modal
        modalTitle="CLOCK IN/OUT HISTORY"
        noneFooterStyle
        noneBodyStyle
        containerPadding={1}
        visible={visible}
        hiddenOkButton
        footer={null}
        closeIcon={<IconClose />}
        closable
        showClose
        onClose={() => setVisible(false)}
        width={'50vw'}
      >
        <TableStyled>
          <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey={'time'}
            pagination={{
              total: totalElement ?? 0,
              pageSize: params.size,
              current: params.page,
              onChange: (pageChange) => {
                dispatch(
                  userActions.setHistoryClockInOutParams({
                    page: pageChange,
                  })
                );
              },
              showSizeChanger: false,
            }}
          />
        </TableStyled>
      </Modal>
    </>
  );
};

export default ButtonClockInOutHistory;
const TableStyled = styled.div`
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-content {
          max-height: 70vh;
          overflow: auto;
          border-radius: 5px;
          border: 1px solid #86909c;
          .ant-table-thead {
            .ant-table-cell {
              position: sticky;
              top: 0;
              z-index: 99;
              border-start-start-radius: 0;
              border-start-end-radius: 0;
              background: var(--fill-fill-2, #adadad);
              color: var(--text-text-1, #fff);
              text-align: center;
              font-size: 1rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              &::before {
                content: none;
              }
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              border-bottom: 1px solid #86909c;
              color: var(--text-text-3, #1d2129);
              text-align: right;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .ant-table-row:last-child {
              .ant-table-cell {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .ant-table-pagination {
      .ant-pagination-item,
      .ant-pagination-next,
      .ant-pagination-prev {
        border-radius: 3.333px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        a {
          color: var(--text-text-3, #1d2129);
          text-align: center;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          text-transform: capitalize;
        }
        &.ant-pagination-item-active {
          background: var(--primary-primary-active, #ff8890);
        }
      }
    }
  }
`;
const IconClose = () => (
  <Row>
    <img src={close} style={{ height: '100%', width: '100%' }} />
  </Row>
);
