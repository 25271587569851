import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Table from 'components/Table';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import StatusBox from './StatusBox';
type ICustomersBodyDisplayProps = {};
const CustomersBodyDisplay: React.FC<ICustomersBodyDisplayProps> = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const data = customerSelectors.customersTableData.data();
  const params = customerSelectors.customersTableData.params();
  const loading = customerSelectors.customersTableData.loading();
  const totalElement = customerSelectors.customersTableData.totalElement();

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      align: 'center',
      render(text) {
        return (
          <EllipsisText ellipsis={{ tooltip: text }}>
            {text || '--'}
          </EllipsisText>
        );
      },
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      align: 'center',
      render(phone) {
        return (
          <Text variant="BODY_1" color="text_3">
            {phone ? maskPhone(phone) : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      align: 'center',
      render(email) {
        return (
          <EllipsisText ellipsis={{ tooltip: email }}>
            {email || '--'}
          </EllipsisText>
        );
      },
    },
    {
      title: 'DOB',
      dataIndex: 'birthday',
      align: 'center',
      render(dob) {
        return (
          <Text variant="BODY_1" color="text_3">
            {dob ? momentTimezone(dob).format('MM-DD-YYYY') : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Point',
      dataIndex: 'point',
      align: 'center',
      render(point) {
        return (
          <Text variant="BODY_1" color="text_3">
            {point}
          </Text>
        );
      },
    },
    {
      title: 'Amount Spent',
      dataIndex: 'amountSpent',
      align: 'center',
      render(amountSpent) {
        return (
          <Text variant="BODY_1" color="text_3">
            {formatCurrency(amountSpent)}
          </Text>
        );
      },
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      align: 'center',
      render(createdDate) {
        return (
          <Text variant="BODY_1" color="text_3">
            {createdDate ? momentTimezone(createdDate).format('MM-DD-YYYY') : ''}
          </Text>
        );
      },
    },

    {
      title: 'Visited',
      dataIndex: 'numberVisit',
      align: 'center',
      render(value) {
        return (
          <Text variant="BODY_1" color="text_3">
            {value}
          </Text>
        );
      },
    },
    {
      title: 'Last Visit Date',
      dataIndex: 'lastVisitedDate',
      align: 'center',
      render(lastVisitedDate) {
        return (
          <Text variant="BODY_1" color="text_3">
            {lastVisitedDate
              ? momentTimezone(lastVisitedDate).format('MM-DD-YYYY')
              : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render(status: string) {
        return <StatusBox status={status} />;
      },
    },
    {
      title: '',
      dataIndex: 'id',
      align: 'center',
      render(id) {
        return (
          <ButtonNavigate
            onClick={() =>
              navigate(`/store/${storage.shop_id.get()}/customers/${id}`)
            }
          >
            <Text variant="BODY_1" color="text_1">
              View Detail
            </Text>
          </ButtonNavigate>
        );
      },
    },
  ];

  const handleChangePage = (page: number) => {
    dispatch(
      customerActions.setCustomerParam({
        page: page,
      })
    );
  };

  return (
    <CustomersBodyDisplayStyled>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={'id'}
        pagination={{
          total: totalElement ?? 0,
          pageSize: params.size,
          current: params.page,
          showSizeChanger: false,
        }}
        handleChangePage={handleChangePage}
      />
    </CustomersBodyDisplayStyled>
  );
};

export default CustomersBodyDisplay;
const CustomersBodyDisplayStyled = styled.div`
  padding: 0 1.5rem;
`;
const ButtonNavigate = styled.div`
  border-radius: 5px;
  background: var(--info-infor-3, #6fabb6);
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  cursor: pointer;
`;
const EllipsisText = styled(Typography.Text)`
  width: 6rem;
  color: var(--text-text-3, #1d2129);
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
