import { Col, Row } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { styled } from 'styled-components';
import { IKeyboardType, TYPE_KEYBOARD } from './helper';
type NumberPadProps = {
  type: IKeyboardType;
  value?: string;
  onChange: (val: string) => void;
  hint?: boolean;
  modeTip?: boolean;
};
const NumberPad = ({ type, value, onChange = () => undefined, hint = false, modeTip = false }: NumberPadProps) => {
  return (
    <NumberPadStyled modeTip={modeTip}>
      <Row gutter={[16, 16]} className='WRAPPER-KEYBOARD'>
        {buttonNames.filter(o => {
          if (o.acceptAll) return true;
          return o.accept?.includes(type as any);
        }).map((item) => (
          <Col key={item.key} span={8}>
            <ButtonNumberPad
              onClick={() => onChange(item.get(value?.toString() || ''))}
              className='NUMBER-PAD-BUTTON'
            >
              {typeof item.value === 'string' ? (
                <Text variant="H2" className='NUMBER-PAD'>{item.value}</Text>
              ) : (item.value)}
            </ButtonNumberPad>
          </Col>
        ))}
      </Row>
      {hint && <>
        <Text mt={0.5} />
        <Row gutter={[16, 16]}>
          <Col span={8}><Text className='hint'>CANCEL</Text></Col>
          <Col span={8} />
          <Col span={8}><Text className='hint'>CLEAR</Text></Col>
        </Row>
      </>
      }
    </NumberPadStyled>
  );
};

export default NumberPad;

export const ButtonNumberPad = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  padding: 12px 16px;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:disabled {
    border: transparent !important;
    opacity: 0.5;
  }

  fill: var(--fill-fill-0, #FFF);
  box-shadow: -1px 1px -1px 0px rgba(0, 0, 0, 0.10) inset;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin: auto;
  border: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  &:hover {
    cursor: pointer;
  }
  &:focus-visible{
    outline: 4px solid #fff0f0;
    outline-offset: 1px;
    transition: outline-offset 0s,outline 0s;
  }
`;

const NumberPadStyled = styled.div<{ modeTip?: boolean }> `
  width: 75%;
  margin: auto;
  .hint {
    color: var(--text-text-2, #767676);
    text-align: center;
    font-family: Open Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  ${({ modeTip }) => modeTip ? `
  width: 55%;
    .NUMBER-PAD-BUTTON {
      box-shadow: none;
      border: 1px solid #37413D;
      height: 8rem !important;
      width: 8rem !important;
      .NUMBER-PAD {
        font-weight: unset;
        font-size: 3.5rem !important;
      }
    }
  `: ''}
`;

const buttonNames = [
  {
    key: '1',
    value: '1',
    get: (text: string) => text + '1',
    acceptAll: true,
  },
  {
    key: '2',
    value: '2',
    get: (text: string) => text + '2',
    acceptAll: true,
  },
  {
    key: '3',
    value: '3',
    get: (text: string) => text + '3',
    acceptAll: true,
  },
  {
    key: '4',
    value: '4',
    get: (text: string) => text + '4',
    acceptAll: true,
  },
  {
    key: '5',
    value: '5',
    get: (text: string) => text + '5',
    acceptAll: true,
  },
  {
    key: '6',
    value: '6',
    get: (text: string) => text + '6',
    acceptAll: true,
  },
  {
    key: '7',
    value: '7',
    get: (text: string) => text + '7',
    acceptAll: true,
  },
  {
    key: '8',
    value: '8',
    get: (text: string) => text + '8',
    acceptAll: true,
  },
  {
    key: '9',
    value: '9',
    get: (text: string) => text + '9',
    acceptAll: true,
  },
  {
    key: 'removeAll',
    value: <Icon type="removeAll"> </Icon>,
    get: () => '',
    accept: [TYPE_KEYBOARD.PHONE, TYPE_KEYBOARD.PIN_PASSWORD, TYPE_KEYBOARD.NUMBER],
  },
  {
    key: 'dot',
    value: '.',
    get: (text: string) => {
      if (!text) return '0.';
      return text.includes('.') ? text : text + '.';
    },
    accept: [TYPE_KEYBOARD.CURRENCY],
  },
  {
    key: '0',
    value: '0',
    get: (text: string) => text + '0',
    acceptAll: true,
  },
  {
    key: 'backSpace',
    value: <Icon type="backSpace"> </Icon>,
    get: (text: string) => text.slice(0, text.length - 1),
    acceptAll: true,
  },
];