import { Form, Input } from 'antd';
import { FormInstance } from 'antd/lib';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useEffect, useState } from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
type IRightAddOnFormProps = {
  addOnFormRef: React.MutableRefObject<FormInstance<IServiceItemData> | null>;
};
const RightAddOnForm: React.FC<IRightAddOnFormProps> = ({ addOnFormRef }) => {
  const [visiblePW, setVisiblePW] = useState(false);
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<IServiceItemData>();
  addOnFormRef.current = form;

  const addOnServiceSelected =
    settingServiceSelectors.getAddOnServiceSelected();

  const onFinish = (values: any) => {
    const body = {
      serviceId: addOnServiceSelected.id || '',
      serviceName: values.serviceName || '',
      priceSell: values.priceSell || 0,
      duration: values.duration || 0,
      orderNumber: values.orderNumber || 0,
      supplyFee: addOnServiceSelected.supplyFee || 0,
      commission: addOnServiceSelected.commission || 0,
      addOn: true,
    };
    dispatch(settingServiceActions.createOrUpdateServiceAddOn.fetch(body));
  };

  const handleDeleteAddOn = () => {
    setVisiblePW(true);
  };
  const handleClose = () => setVisiblePW(false);

  const onVerifyAdminSuccess = (pinCode?: string) => {
    const paramsDelete = {
      id: addOnServiceSelected.id,
      pinCode,
    };
    dispatch(settingServiceActions.deleteAddOnService.fetch(paramsDelete));
    dispatch(settingServiceActions.resetServiceSetting());
    setVisiblePW(false);
  };

  useEffect(() => {
    form.setFieldsValue(addOnServiceSelected);
  }, [addOnServiceSelected]);

  return (
    <RightAddOnFormStyled>
      <Form
        form={form}
        initialValues={addOnServiceSelected}
        onFinish={onFinish}
      >
        <NameEditButtonBox>
          <FormItemNoMargin name="serviceName">
            <Input bordered={false} />
          </FormItemNoMargin>
          <div className="button-group">
            <div className="button-item">
              <Icon type="edit" />
            </div>
            <div className="button-item" onClick={handleDeleteAddOn}>
              <Icon type="deleteIcon" />
            </div>
          </div>
        </NameEditButtonBox>

        <Box py="3" px="4" display="flex" flexDirection="column" gap="2">
          <Box className="space-between">
            <Text variant="CONTENT_1" color="text_3">
              Ordinal Number
            </Text>
            <Box width={'5.5rem'} display="flex" justifyContent="end">
              <FormItemNoMargin name="orderNumber">
                <Input type="number" />
              </FormItemNoMargin>
            </Box>
          </Box>
          <Box className="space-between">
            <Text variant="CONTENT_1" color="text_3">
              Sell Price
            </Text>
            <Box width={'5.5rem'} display="flex" justifyContent="end">
              <FormItemNoMargin name="priceSell">
                <CurrencyInputPrecision />
              </FormItemNoMargin>
            </Box>
          </Box>

          <Box className="space-between">
            <Text variant="CONTENT_1" color="text_3">
              Duration (minutes)
            </Text>
            <Box width={'5.5rem'} display="flex" justifyContent="end">
              <FormItemNoMargin name="duration">
                <Input type="number" />
              </FormItemNoMargin>
            </Box>
          </Box>
        </Box>
      </Form>
      {visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={handleClose}
        />
      )}
    </RightAddOnFormStyled>
  );
};

export default RightAddOnForm;
const RightAddOnFormStyled = styled.div``;

const NameEditButtonBox = styled.div`
  padding-left: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${colorTheme.line_2};
  border-bottom: 1px solid ${colorTheme.line_2};
  .button-group {
    display: flex;
    height: 3rem;
    .button-item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 0 0.5rem;
      border-left: 1px solid ${colorTheme.line_2};
    }
  }
`;

const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
`;
