
const Ellipsis = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="31" height="32" viewBox="0 0 31 32" fill="none" {...props}>
            <circle cx="15" cy="8" r="2" fill={props?.fill ? props.fill : '#1D2129'} />
            <circle cx="15" cy="16" r="2" fill={props?.fill ? props.fill : '#1D2129'} />
            <circle cx="15" cy="24" r="2" fill={props?.fill ? props.fill : '#1D2129'} />
        </svg>
    );
};

export default Ellipsis;
