const AvatarUnset = () => {
  return (
    <svg
      width="144"
      height="145"
      viewBox="0 0 144 145"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="71.9101"
        cy="72.3271"
        r="71.31"
        fill="#E5E6EB"
        stroke="#86909C"
      />
      <g clipPath="url(#clip0_754_28420)">
        <path
          d="M68.8497 29H75.1571C81.1565 29.8888 86.7467 33.3384 90.0709 38.4336C91.9651 41.1968 93.0167 44.4462 93.5359 47.733C94.1805 52.8062 93.0365 58.1148 90.2535 62.418C86.7995 67.9048 80.6747 71.614 74.2133 72.1244C72.1563 72.0914 70.0817 72.2674 68.0445 71.9154C63.6841 71.1608 59.5745 69.0224 56.4791 65.8588C52.6115 61.9406 50.4005 56.5022 50.2949 51.011C50.0749 43.5816 53.9513 36.161 60.2653 32.2054C62.8525 30.529 65.8159 29.4884 68.8497 29Z"
          fill="#1D2129"
        />
        <path
          d="M40.1903 83.6637C44.5265 78.7665 50.0551 74.9209 56.1623 72.5757C59.3127 74.5821 62.8151 76.0693 66.4935 76.7491C67.9323 77.1077 69.4151 77.2749 70.9001 77.2617C72.4379 77.2463 73.9845 77.3189 75.5135 77.1253C79.5483 76.5049 83.5523 75.2729 86.9997 73.0399C87.3539 72.8683 87.7301 72.4987 88.1481 72.6923C96.8403 76.1023 104.232 82.5879 108.901 90.6553C111.041 94.1489 112.55 97.9945 113.716 101.908C114.698 105.307 115.182 108.816 115.567 112.325C112.535 112.972 109.528 113.716 106.476 114.268C104.639 114.466 102.855 114.994 101.014 115.152C99.1041 115.297 97.2429 115.801 95.3267 115.889C92.1697 116.136 89.0391 116.664 85.8667 116.719C84.4939 116.879 83.0903 116.672 81.7373 117H62.2651C60.9121 116.675 59.5063 116.877 58.1335 116.719C54.7455 116.653 51.4015 116.076 48.0267 115.832C46.3349 115.7 44.6761 115.313 42.9865 115.152C41.1429 114.998 39.3587 114.457 37.5195 114.268C34.4747 113.694 31.4541 113.001 28.4313 112.325C28.5655 110.576 28.9021 108.854 29.1067 107.113C29.2893 105.705 29.7491 104.352 29.9999 102.958C31.8545 95.8627 35.2645 89.1241 40.1903 83.6637ZM92.6933 86.6535C92.6669 90.0217 92.6955 93.3921 92.6801 96.7603C89.3097 96.7757 85.9415 96.7493 82.5711 96.7713C82.5579 98.6721 82.5579 100.575 82.5711 102.476C85.9371 102.52 89.3031 102.452 92.6669 102.509C92.7153 105.884 92.6537 109.258 92.6977 112.633C94.6645 112.69 96.6357 112.686 98.6047 112.635C98.6355 109.404 98.5959 106.17 98.6179 102.938C98.5739 102.586 99.0073 102.454 99.2845 102.487C102.433 102.476 105.579 102.509 108.727 102.476C108.742 100.573 108.742 98.6721 108.727 96.7713C105.359 96.7493 101.988 96.7735 98.6179 96.7625C98.6025 93.3921 98.6289 90.0217 98.6069 86.6513C96.6357 86.6381 94.6645 86.6381 92.6933 86.6535Z"
          fill="#1D2129"
        />
      </g>
      <defs>
        <clipPath id="clip0_754_28420">
          <rect
            width="88"
            height="88"
            fill="white"
            transform="translate(28 29)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AvatarUnset;
