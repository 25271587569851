import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  turn: [],
  turnLoading: false,
  turnDetail: null,
  showModalTurnDetail: false,
  turnStaffsAvailable: [],
  selectWaitingListResItem: undefined
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getTurn.success, (state, { payload }) => {
        state.turn = payload;
      })
      .addCase(actions.setTurnLoading, (state, { payload }) => {
        state.turnLoading = payload;
      })
      .addCase(actions.getTurnDetail.success, (state, { payload }) => {
        state.turnDetail = payload;
        state.showModalTurnDetail = true;
      })
      .addCase(actions.setShowModalTurnDetail, (state) => {
        state.turnDetail = null;
        state.showModalTurnDetail = false;
      })
      .addCase(actions.getTurnStaffsAvailable.success, (state, { payload }) => {
        state.turnStaffsAvailable = payload;
      })
      .addCase(actions.selectWaitingListResItem, (state, { payload }) => {
        state.selectWaitingListResItem = payload;
      })
      ;
  },
});

/** TODO:
 * please replace FEATURE_NAME => your feature name
 * ex:
 *      my feature is booking 
 *  name sagas like
 *      FEATURE_NAMEServiceReducer => bookingServiceReducers
 * 
 * // TODO:
 * Move to rootReducer (src/store/root/reducers.ts) and import your feature reducer
 */

const turnServiceReducer = Slice.reducer;
export default turnServiceReducer;
