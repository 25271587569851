const CashBtn = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3926_69736)">
        <path d="M0.617241 4.0144C0.653804 4.0044 0.690679 3.9994 0.728804 4.00003C5.70912 4.00003 10.6894 4.00003 15.6697 4.00003C15.8269 3.98159 15.9682 4.12409 15.9501 4.28096C15.9501 7.09378 15.9501 9.90659 15.9501 12.7194C15.9682 12.8757 15.8269 13.0175 15.6704 13C10.7426 13.0003 5.81443 12.9997 0.886616 13.0003C0.764116 12.996 0.616616 13.0253 0.522866 12.9257C0.431616 12.84 0.451616 12.7066 0.449429 12.5944C0.450679 9.82315 0.449741 7.0519 0.450054 4.28034C0.440366 4.16721 0.506929 4.05003 0.617241 4.0144ZM0.950054 4.50003C0.950054 7.16659 0.950054 9.83346 0.950054 12.5C5.78349 12.5 10.6166 12.5 15.4501 12.5C15.4501 9.83346 15.4501 7.16659 15.4501 4.50003C10.6166 4.50003 5.78349 4.50003 0.950054 4.50003Z" fill="#1D2129" />
        <path d="M2.61544 4.76531C2.65326 4.75437 2.69201 4.74937 2.73169 4.74999C6.37732 4.74999 10.0233 4.74999 13.6692 4.74999C13.7989 4.73687 13.9339 4.83156 13.9451 4.96531C13.9551 5.10812 13.9686 5.25406 14.0286 5.38624C14.1736 5.73999 14.5354 5.99031 14.9183 5.99843C15.0604 5.98624 15.2026 6.09937 15.1986 6.24687C15.2026 7.72687 15.1983 9.20687 15.2008 10.6869C15.2054 10.7791 15.1873 10.8819 15.1095 10.9419C15.0039 11.0287 14.8573 10.9878 14.7342 11.0244C14.2983 11.1134 13.957 11.5241 13.9514 11.9694C13.9658 12.1256 13.8283 12.2675 13.6708 12.25C10.0242 12.25 6.37794 12.25 2.73169 12.25C2.57169 12.2691 2.43294 12.1244 2.44857 11.9662C2.44107 11.5225 2.10107 11.1131 1.66638 11.0244C1.53669 10.9862 1.37669 11.0325 1.27357 10.9262C1.18201 10.8409 1.20138 10.7072 1.19951 10.5947C1.20044 9.19937 1.20044 7.80374 1.19951 6.40812C1.20169 6.29437 1.18107 6.15843 1.27419 6.07281C1.37763 5.96749 1.53701 6.01312 1.66607 5.97531C2.10138 5.88624 2.44169 5.47656 2.44857 5.03187C2.44294 4.91906 2.50419 4.80156 2.61544 4.76531ZM2.92951 5.25062C2.83044 5.86531 2.31576 6.38062 1.70076 6.47937C1.69951 7.82656 1.69982 9.17343 1.70076 10.5203C2.31607 10.6187 2.83044 11.1344 2.92951 11.7494C6.44326 11.7503 9.95669 11.7503 13.4704 11.7494C13.5689 11.1341 14.0845 10.6194 14.6995 10.5206C14.7008 9.17343 14.7004 7.82656 14.6995 6.47968C14.0851 6.37968 13.5692 5.86624 13.4708 5.25062C9.95701 5.24937 6.44326 5.24968 2.92951 5.25062Z" fill="#1D2129" />
        <path d="M7.84088 5.52287C8.47307 5.44443 9.12963 5.57599 9.68244 5.89318C10.3378 6.26474 10.8434 6.8913 11.0646 7.61193C11.2765 8.28849 11.2356 9.03943 10.9524 9.68912C10.6862 10.3113 10.2006 10.8354 9.60213 11.1507C9.02338 11.4585 8.3415 11.5679 7.69557 11.4557C6.979 11.336 6.31244 10.9438 5.86025 10.3751C5.44932 9.86568 5.21369 9.21849 5.20182 8.56412C5.18463 7.87474 5.41744 7.18318 5.84525 6.64255C6.32838 6.02599 7.06244 5.61318 7.84088 5.52287ZM7.8715 6.02224C7.24275 6.1038 6.65182 6.43568 6.25557 6.93037C5.86494 7.41005 5.66463 8.03943 5.70588 8.6563C5.74213 9.27224 6.01838 9.87037 6.464 10.2969C6.86244 10.6844 7.39463 10.9316 7.94775 10.986C8.47557 11.0416 9.01963 10.9213 9.47494 10.6488C9.9615 10.361 10.3446 9.90349 10.5412 9.37349C10.7243 8.88755 10.7484 8.34412 10.6109 7.8438C10.4546 7.2613 10.0781 6.74068 9.57244 6.41193C9.0765 6.08318 8.46088 5.94287 7.8715 6.02224Z" fill="#1D2129" />
        <path d="M7.94182 7.00937C7.95495 6.88719 8.03651 6.76469 8.16745 6.7525C8.31714 6.7275 8.44339 6.85719 8.45651 7C8.62245 7.00187 8.78839 6.99656 8.95433 7.00156C9.08589 6.99875 9.20183 7.11781 9.19933 7.24875C9.20308 7.38156 9.08495 7.50187 8.95182 7.49844C8.64807 7.50531 8.34432 7.49344 8.04089 7.50344C7.85401 7.51406 7.69714 7.68687 7.70089 7.87344C7.69526 8.06156 7.85401 8.23469 8.04151 8.24656C8.21714 8.25844 8.39776 8.23219 8.56901 8.285C8.95651 8.39062 9.23401 8.78969 9.19682 9.19C9.17401 9.5875 8.84901 9.93219 8.45745 9.99187C8.44464 10.1128 8.36276 10.2334 8.23401 10.2472C8.0837 10.2728 7.9562 10.1434 7.94307 10C7.77776 9.99781 7.61214 10.0034 7.44682 9.99844C7.31401 10.0016 7.19589 9.88094 7.20057 9.74812C7.19776 9.61656 7.31495 9.49781 7.44714 9.50156C7.74057 9.49594 8.03432 9.50406 8.32776 9.49844C8.51464 9.50187 8.68464 9.34437 8.69714 9.15844C8.7187 8.97125 8.57557 8.78437 8.38932 8.75719C8.2237 8.73719 8.05401 8.76719 7.88995 8.72937C7.62245 8.6725 7.38807 8.4825 7.27714 8.2325C7.15557 7.96562 7.18089 7.63781 7.34464 7.39406C7.47807 7.18875 7.70057 7.04844 7.94182 7.00937Z" fill="#1D2129" />
      </g>
      <defs>
        <clipPath id="clip0_3926_69736">
          <rect width="16" height="16" fill="white" transform="translate(0.199951 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CashBtn;
