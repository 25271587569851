import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { Bill } from 'features/ticketList/services/types/api';
import React, { memo, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

type ITipColumnProps = {
  record: Bill;
};

const TipColumn: React.FC<ITipColumnProps> = ({ record }) => {
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const dispatch = useAppDispatch();
  const adjustTipList = ticketListSelectors.adjustTip();
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState<number | null | undefined>();

  const currentTip = useMemo(() => {
    return adjustTipList.find((item) => item.billId === record.billId)?.tip || 0;
  }, [adjustTipList, record]);

  const handleOpen = () => {
    setVisible(true);
    setTimeout(() => currencyRef.current?.setValue(currentTip?.toFixed(2)), 100);
  };

  const handleSubmitModal = () => {
    const payload = { billId: record?.billId, tip: amount || 0 };
    dispatch(ticketListActions.handleChangeAdjustTip(payload));
    setVisible(false);
  };

  return (
    <>
      <TipColumnStyled>
        <Text variant="CONTENT_2" color="error_4" mb={0.25} textAlign='left' style={{ width: '4rem' }}>
          {formatCurrency(record.tip)}
        </Text>
        <div className={`box-input ${visible ? 'active' : ''} prevent-select`} onClick={handleOpen}>
          <p>{formatCurrency(currentTip)}</p>
        </div>
      </TipColumnStyled>
      {visible && <Modal
        modalTitle={'TIP'}
        visible={visible}
        onClose={() => setVisible(false)}
        onSubmit={handleSubmitModal}
        destroyOnClose={true}
      >
        <CurrencyKeyBoard ref={currencyRef} value={amount} onChange={setAmount} />
      </Modal>
      }
    </>
  );
};

const TipColumnStyled = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-input-number {
    height: 2.25rem;
    width: 5rem;
    text-align: center;
    .ant-input-number-input {
      text-align: center;
    }
    .ant-input-number-handler-wrap {
      display: none;
    }
  }
  .box-input {
    border-radius: 5px;
    border: 1px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-0, #FFF);
    width: 4.5rem;
    padding: 8px;
    p {
      color: var(--text-text-3, #1D2129);
      text-align: center;
      font-family: Open Sans;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &.active ,&:hover {
      cursor: pointer;
      border: 1px solid #F5767F;
    }
  }
`;

export default memo(TipColumn);
