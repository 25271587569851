const WalkIn = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
        <g>
            <path d="M8.00674 0.664595C9.31741 0.456595 10.7921 0.831262 11.6707 1.8726C12.5947 2.94326 12.9427 4.3686 13.1561 5.73259C13.2774 6.78326 13.4121 7.84193 13.3547 8.9006C13.2614 10.3486 12.2041 11.4926 11.9961 12.9113C11.8854 13.4713 11.9614 14.0433 11.9147 14.6073C11.8814 14.8939 11.5761 15.0193 11.3254 15.0553C9.77474 15.3659 8.22408 15.6766 6.67741 16.0006C6.36674 16.0793 5.92674 16.0739 5.78274 15.7286C5.06141 13.3766 4.54141 10.9539 4.33874 8.49926C4.30541 6.66593 4.48408 4.77926 5.20808 3.07793C5.69208 1.8966 6.69208 0.840595 8.00674 0.664595ZM7.31608 2.25126C6.62941 2.73393 6.27474 3.53526 6.03074 4.31126C5.62674 5.66993 5.54141 7.10059 5.58141 8.51126C5.76941 10.6099 6.18808 12.6819 6.77474 14.7046C8.07874 14.4473 9.38141 14.1846 10.6827 13.9166C10.6827 12.8579 10.9107 11.8019 11.3774 10.8499C11.7187 10.1046 12.1494 9.35526 12.1361 8.50859C12.0774 7.10859 12.0041 5.6886 11.5987 4.33793C11.3387 3.47393 10.8627 2.60326 10.0374 2.16993C9.19608 1.76326 8.10674 1.6926 7.31608 2.25126Z" fill="#1D2129" />
            <path d="M21.3441 7.47031C22.4188 7.26631 23.6428 7.35431 24.5134 8.08497C25.5534 8.92764 26.0348 10.2463 26.3268 11.5116C26.6121 12.8423 26.7148 14.213 26.6401 15.5716C26.3854 17.9303 25.9241 20.269 25.1921 22.5263C25.0508 22.845 24.6574 22.8276 24.3708 22.7636C22.7854 22.437 21.1988 22.1236 19.6134 21.7983C19.3401 21.7596 19.0814 21.5743 19.0628 21.281C19.0414 20.8076 19.1001 20.3316 19.0308 19.861C18.8668 18.2463 17.5734 16.965 17.6214 15.3036C17.6868 13.505 17.8014 11.6516 18.5081 9.97164C18.9841 8.75031 20.0134 7.69431 21.3441 7.47031ZM21.4894 8.71964C20.5574 8.95031 19.8854 9.75964 19.5601 10.629C18.9894 12.117 18.9268 13.7343 18.8628 15.309C18.8574 16.2956 19.4414 17.1263 19.7961 18.0076C20.1534 18.8516 20.3001 19.7676 20.3188 20.6796C21.6174 20.9276 22.9094 21.213 24.2108 21.4516C24.8188 19.4543 25.2134 17.3956 25.4121 15.317C25.4668 13.9023 25.3654 12.4703 24.9828 11.1036C24.7268 10.2956 24.3521 9.45964 23.6241 8.97297C23.0068 8.53831 22.1948 8.55297 21.4894 8.71964Z" fill="#1D2129" />
            <path d="M11.3495 16.5278C11.7282 16.4145 12.2868 16.3958 12.4068 16.8758C12.7362 18.1092 13.0415 19.3612 13.1415 20.6372C13.1748 21.3812 13.0628 22.2238 12.4842 22.7545C11.8402 23.3318 10.9308 23.4705 10.0962 23.4865C9.28416 23.4918 8.49082 23.1198 7.98149 22.4878C7.12282 21.4412 6.76549 20.0945 6.44549 18.8065C6.37882 18.4598 6.22016 18.0865 6.37216 17.7412C6.46816 17.5372 6.70416 17.4732 6.90549 17.4278C8.38682 17.1318 9.86949 16.8385 11.3495 16.5278ZM7.68416 18.5452C7.81082 19.3212 8.02949 20.0825 8.32416 20.8118C8.58416 21.4278 9.01082 22.0812 9.71616 22.2158C10.2922 22.2705 10.9082 22.2452 11.4308 21.9732C11.8548 21.7358 11.9762 21.2172 11.9442 20.7665C11.8948 19.7638 11.5895 18.7972 11.3802 17.8212C10.1388 18.0078 8.91482 18.3012 7.68416 18.5452Z" fill="#1D2129" />
            <path d="M18.9641 23.2418C19.2028 23.1511 19.4561 23.2631 19.6974 23.2938C21.2108 23.6191 22.7334 23.9004 24.2468 24.2258C24.6694 24.3044 24.7614 24.8111 24.6428 25.1631C24.3294 26.5058 23.9948 27.9004 23.1761 29.0391C22.6641 29.7751 21.8028 30.2484 20.9028 30.2511C20.1028 30.2418 19.2628 30.0818 18.6041 29.6044C18.0081 29.1191 17.7974 28.3098 17.8254 27.5684C17.8681 26.3698 18.2028 25.2111 18.4734 24.0498C18.5574 23.7471 18.5934 23.3284 18.9641 23.2418ZM19.0934 27.0511C19.0241 27.6124 19.0054 28.3271 19.5334 28.6818C20.3801 29.1724 21.7001 29.1951 22.2814 28.2858C22.8654 27.3951 23.1148 26.3311 23.3214 25.2978C22.0974 25.0404 20.8681 24.8178 19.6481 24.5444C19.4081 25.3658 19.2228 26.2058 19.0934 27.0511Z" fill="#1D2129" />
        </g>
        <defs>
            <clipPath id="clip0_2730_60407">
                <rect width="30" height="30" fill="white" transform="translate(0.5 0.5)" />
            </clipPath>
        </defs>
    </svg>);
};
export default WalkIn;
