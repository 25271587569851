import { Col, Row } from 'antd';
import Icon from 'assets/Icons';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalaryStaffDetailRangeDate, IPrintSalaryStaffDetailRangeDate_Detail } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../../../components/PrintHeader';
import { ReportType } from '../../../constants';
import { renderTime } from '../../../utils';
import { DATE_FORMAT } from 'features/appointment/utils/format';

interface IEmployeeSalaryDateRangeReportPrintPage { }

const RowItem = (item: IPrintSalaryStaffDetailRangeDate_Detail) => {
    return (
        <Row style={{ padding: '1px 0px' }}>
            <ColHeader span={10}>
                <Text variant='CAPTION_3' printMode>{moment(item?.createdDate).format(DATE_FORMAT)}</Text>
            </ColHeader>
            <ColHeader span={7} style={{ textAlign: 'end' }}><Text variant='CAPTION_3' printMode >{formatCurrency(item?.price)}</Text></ColHeader>
            <ColHeader span={7} style={{ textAlign: 'end' }}><Text variant='CAPTION_3' printMode >{formatCurrency(item?.tip)}</Text></ColHeader>
        </Row>
    );
};
const UserDetail = (data: IPrintSalaryStaffDetailRangeDate) => {
    return (
        <Box px='4'>
            <Box className="center" highlight>
                <Text printMode variant="CAPTION_2" className='title-highlight'>
                    {data?.staffName}
                </Text>
            </Box>
            <Box>
                <Header>
                    <ColHeader span={10}>DATE</ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}>PRICE</ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}>TIPS</ColHeader>
                </Header>
                {/* @ts-ignore */}
                {data?.details?.map(item => RowItem(item))}
                <Total>
                    <ColHeader span={10}><Text printMode variant='CAPTION_2' >TOTAL SALES</Text></ColHeader>
                    <ColHeader span={14} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.totalSaleService)}</Text></ColHeader>
                </Total>
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' >SUPPLY FEES</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.supplyFee)}</Text></ColHeader>
                </Discount>
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' >COMMISSION</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.totalCommission)}</Text></ColHeader>
                </Discount>
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' >TIP</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.totalTip)}</Text></ColHeader>
                </Discount>
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' >DISCOUNT</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.staffDiscount)}</Text></ColHeader>
                </Discount>
                <Box className="space-between">
                    <Text printMode variant="CAPTION_2" >
                        SALARY
                    </Text>
                    <Text printMode variant="CAPTION_2" >
                        {formatCurrency(data?.totalSalary)}
                    </Text>
                </Box>
            </Box>
        </Box>
    );
};
const StaffSalaryDateRangeReportPrintPage: React.FC<IEmployeeSalaryDateRangeReportPrintPage> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { staff_id = '' } = useParams();
    const dataPrintSalaryStaffDetail = reportSelectors.getPrintSalaryStaffDetailRangeDate();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.STAFF_SALARY_DETAIL];
    useEffect(() => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalaryStaffDetailRangeDate.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: staff_id
        }));
    }, [dataAppointment, staff_id]);

    const onBack = () => {
        navigate(-1);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });
    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <Box className="center" overflow="auto">
                        <TicketBox>
                            <Box px='4'>
                                <LogoBox>
                                    <Icon type="logoSmall" />
                                    <IconLogo />
                                </LogoBox>
                                <Box className="center" pt='1'>
                                    <Text printMode variant="CONTENT_2" color="text_2">
                                        {renderTime(dataAppointment)}
                                    </Text>
                                </Box>
                            </Box>
                            {UserDetail(dataPrintSalaryStaffDetail)}
                        </TicketBox>
                    </Box>
                </div>
            </ContainerStyled>
        </div>
    );
};

export default StaffSalaryDateRangeReportPrintPage;

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 20.5rem;
  height: fit-content;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Header = styled(Row)`
  border-top:1px solid black;
  border-bottom: 1px dashed black;
`;

const ColHeader = styled(Col)`
    color:  black;
    padding: 1px 0px;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Total = styled(Header)`
  border-bottom: 1px solid black;
`;

const Discount = styled(Header)`
  border-top: unset;
`;
