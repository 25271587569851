import { DndContext, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { ICategories, ICategory } from 'features/settingService/services/types/reducer';
import { FC, MouseEventHandler, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ModalAddCategory from './ModalAddCategory';

interface IListCategoriesProduct { }

const ListCategoriesProduct: FC<IListCategoriesProduct> = () => {
    const dispatch = useAppDispatch();
    const [categoriesList, setCategoriesList] = useState<ICategories | []>([]);
    // const [oldLstIndex, setOldLstIndex] = useState<string>('');
    // const [newLstIndex, setNewLstIndex] = useState<string>('');
    const [isSorting, setIsSorting] = useState<boolean>(false);
    const [isShowModalAddCategory, setIsShowModalAddCategory] = useState<boolean>(false);
    const categoriesSaga = settingServiceSelectors.getListCategoriesForProduct();
    const cateForProductSelected = settingServiceSelectors.getCateForProductSelected();
    const pageable = settingServiceSelectors.getProductsPageable();
    useEffect(() => {
        if (categoriesSaga?.length == 0) {
            dispatch(settingServiceActions.getListCategoriesForProduct.fetch({}));
        }
    }, []);
    useEffect(() => {
        setCategoriesList(categoriesSaga);
        // const indexLstString = categoriesSaga.map(m => m.id).join('_');
        // setOldLstIndex(indexLstString);
        // setNewLstIndex(indexLstString);
    }, [categoriesSaga]);
    const mouseSensor = useSensor(MouseSensor, {
        // Require the mouse to move by 10 pixels before activating
        activationConstraint: {
            distance: 10,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        // Press delay of 250ms, with tolerance of 5px of movement
        activationConstraint: {
            delay: 500,
            tolerance: 8,
        },
    });

    const sensors = useSensors(mouseSensor, touchSensor,);

    const handleDragStart = () => {
        setIsSorting(true);
    };

    const handleDragEnd = (event: any) => {
        const { active, over } = event;
        if (active.id !== over.id) {
            const oldIndex = categoriesList.findIndex(f => f.id == active.id);
            const currentData = { ...categoriesList[oldIndex] };
            const newIndex = categoriesList.findIndex(f => f.id == over.id);
            const newCategoriesList = [...categoriesList];
            newCategoriesList.splice(oldIndex, 1);
            newCategoriesList.splice(newIndex, 0, currentData);
            setCategoriesList(newCategoriesList);
            // setNewLstIndex(newCategoriesList.map(m => m.id).join('_'));
        }
        setIsSorting(false);
    };

    const handleSelected = (selected:ICategory) => {
        if (selected?.id == cateForProductSelected?.id) {
            dispatch(settingServiceActions.getProductsByCategoryId.success([]));
            dispatch(settingServiceActions.cateForProductSelected(undefined));
            return;
        }
        dispatch(settingServiceActions.cateForProductSelected(selected));
        dispatch(settingServiceActions.getProductsByCategoryId.fetch({
            body: {
                catId: selected.id ?? ''
            }, params: {
                page: 1,
                size: pageable.pageSize
            }
        }));
    };

    const handleAddCategory = () => {
        setIsShowModalAddCategory(true);
    };
    return (
        <ListCategoriesProductStyled>
            <HeaderSlider>
                <Text variant='H5'>Categories</Text>
                <AddBtn onClick={handleAddCategory}>
                    <Icon type="addPlus" width="32" height="32" fill="#6FABB6" />
                </AddBtn>
                <ModalAddCategory isShown={isShowModalAddCategory} onClose={() => setIsShowModalAddCategory(false)} orderNumber={(categoriesList?.length ?? 0) + 1} />
            </HeaderSlider>
            <CategoriesContent>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={handleDragEnd}
                    onDragStart={handleDragStart}
                >
                    {/* <SortableContext items={categoriesList} strategy={verticalListSortingStrategy}> */}
                        {categoriesList.map((item, index) => (
                            <SortableItem key={item?.id + index} id={item?.id} title={item.categoryName} services={item.products?.length ?? 0} active={item?.id == cateForProductSelected?.id}
                                onClick={() => isSorting ? undefined : handleSelected(item)} />
                        ))}
                    {/* </SortableContext> */}
                </DndContext>
            </CategoriesContent>
        </ListCategoriesProductStyled>
    );
};

export default ListCategoriesProduct;

interface ISortableItem {
    id: string;
    title: string;
    services: number;
    active?: boolean;
    onClick?: MouseEventHandler<HTMLDivElement>
}
const SortableItem: FC<ISortableItem> = ({ id, title, services, active, onClick }) => {
    const { setNodeRef, attributes, listeners, transform, transition } = useSortable({
        id: id,
    });
    const style = {
        transition,
        transform: CSS.Transform.toString(transform)
    };
    return (
        <SortableItemBox ref={setNodeRef} style={style} {...attributes} {...listeners} active={active} onClick={onClick}>
            <Text variant='H9'>{title}</Text>
            <Text variant='CAPTION_1'>{services} Items</Text>
        </SortableItemBox>
    );
};

const ListCategoriesProductStyled = styled.div``;
const HeaderSlider = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
`;
const CategoriesContent = styled.div`
    max-height: calc(100vh - 12.5rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    flex:1;
    overflow-y: auto;
`;
const AddBtn = styled.div`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const SortableItemBox = styled.div<{ active?: boolean }>`
    width: 304px;
    height: 56px;
    flex-shrink: 0;
    border-radius: 5px;
    padding: 0px 10px;
    border: ${props => props?.active ? '1px solid var(--primary-primary-hover, #F5767F)' : '1px solid var(--line-line-3, #86909C)'};
    background:  ${props => props?.active ? 'var(--primary-button, #FFE5E7)' : 'var(--fill-fill-1, #F6F7FC)'};

    /* box */
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;