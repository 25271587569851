import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import VoidPrintBody from 'features/ticketList/components/VoidTicketPrint/PrintBody';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import LeftTicketDetails from './components/LeftTicketDetails';
import RightTicketDetails from './components/RightTicketDetails';
import TicketDetailsPageStyled from './styles';

type ITicketDetailsPageProps = any;
const TicketDetailsPage: React.FC<ITicketDetailsPageProps> = () => {
  const { id } = useParams();
  const details = ticketListSelectors.ticketDetailsData.data();
  const dispatch = useAppDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const doPrint = useReactToPrint({ content: () => divRef.current, });
  const ticketWrapper = useTicketWrapper();

  const handlePrint = () => {
    if (!id) return;
    if (details?.billStatus !== 'VOID') return ticketWrapper.print(id);
    setTimeout(() => doPrint(), 10);
  };

  useEffect(() => {
    dispatch(ticketListActions.getTicketDetails.fetch(id || ''));
    dispatch(shopActions.get.staffsAvailable.fetch());
  }, []);

  return (
    <>
      <TicketDetailsPageStyled>
        <Sidebar />
        <BodyStyled>
          <LeftTicketDetails doPrint={handlePrint} />
          <RightTicketDetails doPrint={handlePrint} />
        </BodyStyled>
      </TicketDetailsPageStyled>
      <div style={{ display: 'none' }}><VoidPrintBody billRef={divRef} isPrinter /></div>
    </>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
  display: flex;
`;

export default TicketDetailsPage;
