import {
  Checkbox,
  Col,
  Collapse,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Switch,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CollapseProps } from 'antd/lib';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IGiftCard } from 'features/settingService/services/types/reducer';
import React, { useEffect, useState } from 'react';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import FromToInput from '../QuickpayTab/FromToInput';
import AvatarCheckBox from './components/AvatarCheckBox';
type IGiftCardProps = {};
const GiftCard: React.FC<IGiftCardProps> = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const giftCardStore = settingServiceSelectors.paymentSetting.giftCard();
  const staffList = shopSelectors.data.staffs();
  const [staffSelected, setStaffSelected] = useState<string[]>([]);
  const commissionStaffWatch = Form.useWatch('divideCommissions', form);

  const onFinish = (values: IGiftCard) => {
    const body: IGiftCard = {
      ...values,
      id: giftCardStore.id,
      staffIds: staffSelected,
      optionCommissions: values?.optionCommissions
        ? values?.optionCommissions?.map((item) => ({
            ...item,
            id: item.id || '',
          }))
        : [],
    };
    dispatch(settingServiceActions.postGiftCard.fetch(body));
  };

  const handleCheckAll = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    if (checked) {
      const allId = staffList.map((item) => item.id);
      setStaffSelected(allId);
    } else {
      setStaffSelected([]);
    }
  };

  const handleChangeStaffItem = (staffId: string) => {
    if (staffSelected.includes(staffId)) {
      // If the staff ID is already selected, remove it from the array
      const newSelected = staffSelected.filter((id) => id !== staffId);
      setStaffSelected(newSelected);
    } else {
      // If the staff ID is not selected, add it to the array
      setStaffSelected([...staffSelected, staffId]);
    }
  };

  const checkAll = staffList.length === staffSelected.length;
  const indeterminate =
    staffSelected.length > 0 && staffSelected.length < staffList.length;

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: (
        <Checkbox
          onChange={handleCheckAll}
          indeterminate={indeterminate}
          checked={checkAll}
        >
          Applies To All Staff
        </Checkbox>
      ),
      children: (
        <Row gutter={[16, 16]}>
          {staffList.map((staff) => {
            const isChecked = staffSelected.includes(staff.id);
            return (
              <AvatarCheckBox
                key={staff.id}
                firstName={staff.firstName}
                handleChangeStaffItem={handleChangeStaffItem}
                id={staff.id}
                isChecked={isChecked}
                urlImage={staff.urlImage}
              />
            );
          })}
        </Row>
      ),
    },
  ];

  useEffect(() => {
    if (giftCardStore) {
      form.setFieldsValue(giftCardStore);
      setStaffSelected(giftCardStore.staffIds);
    }
  }, [giftCardStore]);

  useEffect(() => {
    dispatch(settingServiceActions.getGiftCard.fetch());
    dispatch(shopActions.get.staffs.fetch());
  }, []);

  return (
    <GiftCardStyled>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Box display="flex" gap="3" alignItems="center">
            <Form.Item name="divideCommissions" valuePropName="checked" noStyle>
              <Switch />
            </Form.Item>
            <Text variant="CONTENT_2" color="text_3">
              Divide commissions to Staff
            </Text>
          </Box>
          <Box>
            {commissionStaffWatch && (
              <Box mt="2">
                <Collapse items={items} collapsible="icon" />
                <Box mt="2">
                  <Form.List name="optionCommissions">
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <div key={key}>
                            <Row gutter={[16, 16]}>
                              <Col span={11}>
                                <Box display="flex" flexDirection="column">
                                  <Form.Item label="Range Sale">
                                    <FromToInput
                                      formListName="optionCommissions"
                                      name={name}
                                    />
                                  </Form.Item>
                                </Box>
                              </Col>
                              <Col span={11}>
                                <FormItemNoMargin
                                  {...restField}
                                  name={[name, 'commission']}
                                  label="Commission Fee"
                                >
                                  <InputNumberStyled
                                    formatter={(value: any) => `${value}%`}
                                    parser={(value: any) =>
                                      value?.replace('%', '')
                                    }
                                  />
                                </FormItemNoMargin>
                              </Col>
                              <Col>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  onClick={() => remove(name)}
                                  height="100%"
                                >
                                  <Icon type="circleClose" />
                                </Box>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <Button
                          ntype="LIGHT_BLUE"
                          icon="addPlus"
                          onClick={() => add({ commission: 0 })}
                        >
                          Add New
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Box>
              </Box>
            )}
          </Box>
          <Divider />

          <Box>
            <Form.Item name="maxReset" label="Max Reset Time">
              <Input type="number" />
            </Form.Item>
          </Box>
        </Box>
        <Box display="flex" gap="3" mt="4" justifyContent="end">
          <Button width="10rem" ntype="SECONDARY" htmlType="reset">
            Reset
          </Button>
          <Button width="10rem" ntype="PRIMARY" htmlType="submit">
            Save
          </Button>
        </Box>
      </Form>
    </GiftCardStyled>
  );
};

export default GiftCard;
const GiftCardStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
`;

const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
`;
const InputNumberStyled = styled(InputNumber)`
  height: 4rem;
  width: 100%;
  .ant-input-number-input-wrap {
    height: 100%;
  }
  .ant-input-number-input {
    height: 100%;
  }
`;
