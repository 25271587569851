import Button from 'components/Button';
import React from 'react';
import styled from 'styled-components';
import BtnShowSendEmailModal from './Modals/BtnShowSendEmailModal';
import BtnShowSendSMSModal from './Modals/BtnShowSendSMSModal';

type IPrintFooterProps = {
  onPrint?: () => void;
  onBack?: () => void;
};
const PrintFooter: React.FC<IPrintFooterProps> = ({ onPrint = () => undefined, onBack = () => undefined }) => {
  return (
    <PrintFooterStyled>
      <Button ntype="DEFAULT" icon="print" onClick={onPrint}>
        Print
      </Button>
      <BtnShowSendEmailModal></BtnShowSendEmailModal>
      <BtnShowSendSMSModal></BtnShowSendSMSModal>
      <Button ntype="SECONDARY" onClick={onBack}>Close</Button>
    </PrintFooterStyled>
  );
};

export default PrintFooter;
const PrintFooterStyled = styled.div`
  height: 5rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
`;
