import Button from 'components/Button';
import Modal from 'components/Modal';
import CurrencyKeyBoardTip, { CurrencyKeyBoardTipRef, ICurrencyKeyBoardTip } from 'components/NumberKeyBoard/Currency/TIP';
import Text from 'components/Text';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import multiplePaymentSelectors from '../../services/selectors';
import { Row } from 'antd';

export interface IAddTipResult {
  billId: string;
  tip: number;
  items: null;
}

type Props = {
  onAddATip: (val: number) => void;
};
export type ModalAddTipRef = {
  open: () => void;
  close: () => void;
};
const AddATip = forwardRef<ModalAddTipRef, Props>(({ onAddATip }, ref) => {
  const currencyRef = useRef<CurrencyKeyBoardTipRef>(null);
  const detailTicket = multiplePaymentSelectors.getDetail();
  const [amount, setAmount] = useState<number | null>(0);

  const [visible, setVisible] = useState(false);

  const onClose = () => { setVisible(false); };

  const suggestions = [0.18, 0.2, 0.25, .3];

  useImperativeHandle(ref, () => ({
    open: () => {
      setVisible(true);
      setTimeout(() => currencyRef.current?.setValue((0).toFixed(2)), 500);
    },
    close: () => setVisible(false),
  }));

  const handleNoTip = () => {
    onAddATip(0);
    setVisible(false);
  };
  const handleSubmit = () => {
    onAddATip(+(amount || ''));
    setVisible(false);
  };


  const setAmountValue: ICurrencyKeyBoardTip['onChange'] = (value: ICurrencyKeyBoardTip['value']) => {
    setAmount(value || null);
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      onSubmit={onClose}
      width={'100vw'}
      footer={<></>}
      noneBodyStyle
      noneFooterStyle
      hiddenHeader
      rootClassName={'modal-add-tip'}
      className='modal-overflow-unset modal-max-height-unset'
    >
      <AddATipStyled>
        <div className='keyboard-div' style={{ width: '50%' }}>
          <CurrencyKeyBoardTip
            ref={currencyRef}
            value={amount || 0}
            onChange={setAmountValue}
            initialAmount={detailTicket?.total || 0}
            modeTip
          />
          <Row align={'middle'} justify={'center'}>
            <div className="button-submit" >
              <Button ntype='PRIMARY' onClick={handleSubmit} >OK</Button>
            </div>
          </Row>
        </div>
        <div className="suggestions" style={{ width: '50%' }}>
          <div className="head-tip-content">
            <Text className='text-your-bill'>Your Bill: {formatCurrency(detailTicket?.total || 0)}</Text>
            <Text className='text-tip'>TIP: {formatCurrency(amount)}</Text>
          </div>
          {suggestions.map((o, i) => {
            const value = ((detailTicket?.total || 0) * o)?.toFixed(2);
            return (
              <button className="suggestion-item" key={i} onClick={() => currencyRef.current?.setValue(value)}>
                <div className="box">
                  <Text className='text-tip-percent'>{o * 100}% </Text>
                  <Text className='text-tip-value'>({formatCurrency(value)})</Text>
                </div>
              </button>
            );
          })}
          <button className="suggestion-item no-tip-box" onClick={handleNoTip}>
            <div className="box">
              <div className="label">No Tip</div>
            </div>
          </button>
          <div className=""></div>
        </div>
      </AddATipStyled>
    </Modal>
  );
});
AddATip.displayName = 'AddATip';
export default AddATip;
const AddATipStyled = styled.div`
  display: flex;
  height: 100vh;
  .keyboard-div {
    padding: 1.5rem 2rem;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }
  .modal-title {
    color: var(--text-text-2, #767676);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .text-your-bill {
    color: var(--text-text-1, #FFF);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .text-tip {
    color: var(--yellow-headline, #FEEDBF);
    text-align: center;
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .head-tip-content {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .suggestions {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    opacity: 0.9;
    background: #37413D;
  }
  .suggestion-item {
    border-radius: 5px;
    background: transparent;
    border: 1px solid #fff;
    padding: 8px 9px;
    display: flex;
    height: 12vh;
    opacity: 1;
    min-width: 25vw;
    width: 50%;
    margin: 0 auto;
    &:hover {
      opacity: 0.8;
    }
    .box {
      border-radius: 5px;
      background: transparent;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-self: stretch;
      gap:4px;
      justify-content: center;
      align-items: center;
      .text-tip-percent {
        color: #fff;
        text-align: center;
        font-family: Open Sans;
        
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      .text-tip-value {
        color: #fff;
        font-family: Open Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  .no-tip-box {
    border-radius: 5px;
    background: transparent;
    border: 1px solid #fff;
    height: 12vh;
    display: flex;
    width: 50%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: 8px 9px;
    &:hover {
      opacity: 0.8;
    }
    .label {
      color: #fff;
      text-align: center;
      font-family: Open Sans;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .button-submit {
    width: 6.5rem;
    border-radius: 100%;
    overflow: hidden;
    button {
      width:100%;
      height: 6rem !important;
      .CONTENT_2 {
        font-size: 1.5rem;
      }
    }
  }
`;