import { Row } from 'antd';
import Modal from 'components/Modal';
import React from 'react';
import close from './close-circle-fill.png';
import Calculator from './Calculator/index.js';
import styled from 'styled-components';

type Props = {
  visible?: boolean;
  onClose?: () => void;
};
const CalculatorModal = ({ onClose = () => undefined, visible }: Props) => {
  return (
    <Modal
      modalTitle='Calculator'
      visible={visible}
      hiddenHeader
      noneBodyStyle
      noneFooterStyle
      footer={<></>}
      onClose={onClose}
      className='modal-overflow-unset'
      width={'auto'}
    >
      <ModalStyled>
        <button className='icon-close' onClick={onClose}><IconClose /></button>
        <Calculator />
      </ModalStyled>
    </Modal>
  );
};

export default CalculatorModal;

const ModalStyled = styled.div`
  position: relative;
  padding: 1rem;
  text-align:right;
  .icon-close {
    position: absolute;
    width: 42px;
    height: 42px;
    top: -20px;
    right: -20px;
  }
`;

const IconClose = () => (
  <Row>
    <img src={close} style={{ height: '100%', width: '100%' }} />
  </Row>
);
