import { Row } from 'antd';
import appointmentActions from 'features/appointment/services/actions';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import StaffSearchInput from 'widgets/Staff/StaffSearchInput';

const StaffPicker = () => {
  const dispatch = useAppDispatch();
  const handlePickStaff = (o: IStaffItemData) => {
    dispatch(appointmentActions.setTableParams({ staffId: o.id }));
  };
  const handleClear = () => {
    dispatch(appointmentActions.setTableParams({ staffId: undefined }));
  };

  return (
    <StaffPickerStyled>
      <StaffSearchInput onPickAll={handleClear} onPickStaff={handlePickStaff} />
    </StaffPickerStyled>
  );
};

export default StaffPicker;
const StaffPickerStyled = styled(Row)`
width: 18rem;
  .ant-select {
    .ant-select-selector {
      height: 2.5rem !important;
      .ant-select-selection-search {
        height: 100%;
        .ant-input-affix-wrapper {
          height: 100%;
          &::before {
            content: none;
          }
          input {
            height: 100%;
          }
          .ant-input-suffix {
            height: unset;
          }
        }
  
      }

    }
  }
`;
