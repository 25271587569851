const KingVip = () => {
  return (
    <svg
      width="68"
      height="55"
      viewBox="0 0 68 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.0416 3.85348C31.5091 2.87596 32.4893 2.16031 33.5683 2.03006C34.5966 1.88611 35.6783 2.26587 36.3871 3.0254C37.2426 3.90969 37.5264 5.29576 37.0808 6.44328C36.7051 7.47427 35.7811 8.24339 34.7268 8.50662C39.2374 14.0386 43.7479 19.5705 48.2612 25.0997C52.8595 22.8019 57.4482 20.4863 62.0424 18.1831C61.7038 17.647 61.4351 17.0452 61.4268 16.4022C61.3652 15.2615 61.9547 14.1153 62.9185 13.5025C63.8658 12.8719 65.1463 12.7951 66.1636 13.3065C67.1809 13.7959 67.8979 14.8447 67.9857 15.9703C68.0912 17.0534 67.6073 18.1666 66.7518 18.837C65.9168 19.5129 64.7433 19.72 63.7233 19.3841C60.3794 27.8403 57.0739 36.3131 53.7424 44.7748C40.5823 44.7762 27.4208 44.7748 14.2607 44.7762C10.9319 36.3117 7.63328 27.8362 4.28806 19.3799C3.26805 19.7213 2.09174 19.5184 1.25543 18.8384C0.314934 18.1159 -0.1608 16.8614 0.0489616 15.6961C0.275175 14.2634 1.55431 13.083 3.00345 12.9815C4.29766 12.8499 5.61793 13.5752 6.20608 14.7337C6.79012 15.8113 6.66673 17.1864 5.96067 18.1803C10.5521 20.5617 15.1545 22.9212 19.7432 25.3067C24.262 19.7117 28.7644 14.103 33.29 8.51348C32.4633 8.29549 31.701 7.80467 31.2404 7.07531C30.6138 6.13481 30.5439 4.86527 31.0416 3.85348Z"
        fill="#fff"
      />
      <path
        d="M14.2606 46.9695C27.4221 46.9654 40.5836 46.9709 53.7451 46.9668C53.741 49.1604 53.7478 51.354 53.7423 53.5476C40.5808 53.5517 27.4193 53.5462 14.2578 53.5503C14.2619 51.3567 14.2551 49.1631 14.2606 46.9695Z"
        fill="#fff"
      />
    </svg>
  );
};

export default KingVip;
