import styled from 'styled-components';
import _bg from '../assets/loginBackground.png';
type Props = {
  bg?: string;
};
const ImageBackground = styled.div<Props>`
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${({ bg }) => bg || _bg});
  background-size: cover;
  .form-box {
    position: relative;
    z-index:2;
    border-radius: 20px;
    border: 1px solid var(--primary-primary-hover, #F5767F);
    background: #FFF;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 1.5rem;
    max-width: 40rem;
    min-width: 20rem;
    width: 40vw;
  }
  .logo {
    position: absolute;
    z-index: 3;
    top: -7rem;
  }
`;

export default ImageBackground;
