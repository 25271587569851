import { useLocation, useNavigate } from 'react-router-dom';

const useRefreshScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (screen?: string) => {
    const pathName = location.pathname;
    navigate('/refresh', { state: { screen: screen || pathName }, replace: true });
  };
};

export default useRefreshScreen;
