import { Form, Input } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Modal from 'components/Modal';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import React, { useState } from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

type IAddNewAddOnButtonProps = {};
const AddNewAddOnButton: React.FC<IAddNewAddOnButtonProps> = () => {
  const [form] = Form.useForm<IServiceItemData>();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const handleShowModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmitModal = async () => {
    try {
      const values = await form.validateFields();
      const body = {
        serviceId: '',
        serviceName: values.serviceName,
        priceSell: values.priceSell || 0,
        duration: values.duration || 0,
        commission: values.commission || 0,
        supplyFee: values.supplyFee || 0,
        orderNumber: values.orderNumber || 0,
        addOn: true,
      };
      dispatch(settingServiceActions.createOrUpdateServiceAddOn.fetch(body));
      form.resetFields();
      setOpen(false);
    } catch (errorInfo) {}
  };

  const onFinish = () => {
    handleSubmitModal();
  };

  return (
    <AddNewAddOnButtonStyled>
      <div className="button-wrapper" onClick={handleShowModal}>
        <div className="icon-opacity">
          <Icon type="addPlus"></Icon>
        </div>
        <Text color="text_2" variant="CONTENT_2">
          Add New Add - On
        </Text>
      </div>
      <Modal
        modalTitle="Add-On"
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        destroyOnClose={true}
        okTitle="Save"
        cancelTitle="Close"
      >
        <Form
          initialValues={{ cateType: 'SERVICE' }}
          form={form}
          onFinish={onFinish}
          layout={'vertical'}
        >
          <Box p="4">
            <Form.Item
              label="Ordinal Number"
              name="orderNumber"
              rules={[{ required: true, message: 'Missing orderNumber' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Add - On Name"
              name="serviceName"
              rules={[{ required: true, message: 'Missing serviceName' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Sell Price" name="priceSell">
              <Input />
            </Form.Item>
            <Form.Item label="Duration (Minutes)" name="duration">
              <Input />
            </Form.Item>
          </Box>
        </Form>
      </Modal>
    </AddNewAddOnButtonStyled>
  );
};

export default AddNewAddOnButton;
const AddNewAddOnButtonStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: #e5e6eb;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  gap: 1rem;
  padding: 4px;
  .button-wrapper {
    padding: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 1px dashed var(--line-line-3, #86909c);
    .icon-opacity {
      opacity: 0.5;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
