import Box from 'components/Box';
import Text from 'components/Text';
import { IBillDetailData, IBillItemData } from 'features/payment/services/types/bill';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IBillDetailData | null
};
const DetailItems = ({ data }: Props) => {
  const Items = () => {
    return <>
      {data?.items.map(o => (
        <StaffItem key={o.staffId} data={o} />
      ))}
    </>;
  };
  return (
    <Box display="flex" flexDirection="column" gap="1" px="4" pt="4">
      <Items />
    </Box>
  );
};

export default DetailItems;
const StaffItem = ({ data }: { data: IBillItemData }) => {
  return (
    <StaffItemStyled>
      <Text mb={0.25} className='staff-name' style={{ fontWeight: 600 }} >{data.staffName}</Text>
      <Text mb={0.5} className="divider" />
      {data.services.map((o, i) => (
        <ServiceItemStyled key={i}>
          <div className="label">{o.itemName}</div>
          {!!o.discount && <div className="value discount">{ }</div>}
          <div className="value">{formatCurrency(o.price)}</div>
        </ServiceItemStyled>
      ))}
    </StaffItemStyled>
  );
};
const StaffItemStyled = styled.div`
.staff-name {
  color: var(--text-text-3, #1D2129);
  font-family: Open Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;

const ServiceItemStyled = styled.div`
  display:flex;
  margin-bottom: 0.25rem;
  .label {
    flex:1;
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .value {
    color: var(--text-text-3, #1D2129);
    text-align: right;
    font-family: Open Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.discount {
      text-decoration-line: strikethrough;
    }
  }
`;