import { Row } from 'antd';

const IconLogoAuthLogin = () => (
  <Row justify={'center'}>
    <svg width="200" height="100" viewBox="0 0 76 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M46.482 5.30174C46.7928 5.26665 47.1104 5.26706 47.4204 5.31329C47.8482 5.41154 48.274 5.63444 48.5063 6.01668C48.7407 6.37167 48.7611 6.83894 48.6036 7.22779C48.4582 7.53325 48.2138 7.79825 47.9034 7.94314C47.4943 8.1615 47.0143 8.1776 46.5618 8.13798C46.0587 8.07523 45.5557 7.81559 45.295 7.3735C45.0301 6.93223 45.0681 6.34237 45.3723 5.92917C45.6338 5.58119 46.0554 5.37356 46.482 5.30174Z" fill="#F38375" />
      <path d="M45.5209 9.61054C46.4442 9.60847 47.3672 9.60393 48.2906 9.61219C48.2956 13.2187 48.2956 16.8252 48.2906 20.4317C47.3676 20.4259 46.4442 20.4304 45.5213 20.4288C45.5309 17.9364 45.523 15.4436 45.5255 12.9508C45.5225 11.8371 45.5313 10.7238 45.5209 9.61054Z" fill="#F38375" />
      <path d="M1.67412 6.11475C5.99346 6.11805 10.3132 6.11433 14.6326 6.1164C14.6301 6.96673 14.6326 7.81665 14.6313 8.66699C11.2938 8.66575 7.95676 8.66616 4.6197 8.66658C4.62012 9.91277 4.61928 11.1586 4.62012 12.4043C7.45372 12.4039 10.2873 12.4035 13.1209 12.4048C13.1213 13.2027 13.1197 14.0006 13.1218 14.7989C10.2877 14.8005 7.45372 14.7997 4.6197 14.7989C4.62053 16.6729 4.61886 18.5466 4.62053 20.4206C3.63826 20.4239 2.65598 20.421 1.67371 20.4218C1.67496 15.6529 1.67412 10.8841 1.67412 6.11475Z" fill="black" />
      <path d="M53.4355 6.26417C54.3513 6.26293 55.2667 6.26293 56.1826 6.26376C56.1838 7.3824 56.1838 8.50105 56.1826 9.61969C57.0637 9.61846 57.9445 9.62011 58.8256 9.61887C58.8231 10.2987 58.824 10.979 58.8248 11.6588C57.9441 11.6564 57.0633 11.6576 56.1826 11.658C56.1847 14.5789 56.183 17.4997 56.1834 20.4206C55.2671 20.4247 54.3509 20.4243 53.435 20.4206C53.4371 17.4993 53.4355 14.5781 53.4355 11.6568C52.5547 11.6584 51.6744 11.6568 50.7936 11.6576C50.7957 10.9782 50.7966 10.2983 50.7932 9.61887C51.6735 9.62011 52.5539 9.61804 53.4346 9.62011C53.4371 8.50146 53.4355 7.38282 53.4355 6.26417Z" fill="black" />
      <path d="M32.4927 9.61946C36.06 9.61904 39.6268 9.61285 43.1941 9.61533C40.9484 12.4668 38.701 15.3167 36.4502 18.1641C38.5735 18.1608 40.6973 18.1707 42.8206 18.1653C42.8181 18.9182 42.8223 19.6716 42.8189 20.4245C39.0118 20.422 35.2051 20.4249 31.3984 20.4233C33.6245 17.5709 35.8619 14.7268 38.0922 11.8774C36.2258 11.8753 34.3599 11.8799 32.4935 11.8749C32.4956 11.1232 32.4973 10.3715 32.4927 9.61946Z" fill="black" />
      <path d="M22.8572 9.41707C23.2608 9.37909 23.6644 9.42656 24.0684 9.42656C24.7047 9.49385 25.3473 9.58012 25.9515 9.79766C26.4194 9.94007 26.8594 10.1551 27.2922 10.3789C27.5667 10.5543 27.8575 10.7103 28.094 10.9374C28.2453 11.022 28.3464 11.1685 28.4934 11.2601C28.8202 11.6057 29.1348 11.9689 29.375 12.3804C29.7807 13.0574 30.0201 13.8343 30.0728 14.6194C30.1175 14.9125 30.1083 15.2138 30.0627 15.506C30.0644 15.7446 30.0001 15.9754 29.9637 16.2094C29.937 16.2631 29.9307 16.3221 29.9219 16.3799C29.7377 17.0226 29.4477 17.641 29.0299 18.1689C28.3309 19.1039 27.3052 19.7548 26.2147 20.1614C24.8543 20.6576 23.3665 20.7368 21.9376 20.5602C21.0752 20.4524 20.2383 20.185 19.4662 19.7932C19.3668 19.7466 19.2803 19.6731 19.1712 19.6492C19.0363 19.5303 18.875 19.4407 18.7179 19.3536C18.5888 19.2153 18.4116 19.1328 18.2788 18.9982C18.1501 18.862 17.9825 18.7658 17.8773 18.6081C17.6165 18.3625 17.4081 18.0707 17.2104 17.7751C16.7684 17.0672 16.5018 16.2548 16.4437 15.4251C16.4534 15.054 16.4128 14.6788 16.4822 14.3123C16.4918 14.1158 16.5495 13.9259 16.5795 13.7323C16.679 13.4236 16.7721 13.1119 16.9121 12.818C17.2276 12.1398 17.7051 11.5421 18.2708 11.0509C18.5328 10.8531 18.7789 10.629 19.0764 10.4833C19.4674 10.208 19.9208 10.0404 20.3637 9.86577C21.1617 9.57805 22.0098 9.42987 22.8572 9.41707ZM22.5726 11.8517C21.6693 11.9611 20.776 12.3367 20.1535 13.0062C19.7043 13.5012 19.4069 14.1439 19.3793 14.8117C19.3149 15.4957 19.523 16.1929 19.925 16.751C20.0758 16.9698 20.2797 17.1444 20.4694 17.3289C21.404 18.0868 22.6779 18.3196 23.8566 18.1904C24.8735 18.0901 25.9047 17.6525 26.5343 16.8327C27.1293 16.0769 27.3223 15.028 27.0144 14.1179C26.8272 13.4908 26.3985 12.9559 25.8708 12.5707C25.2709 12.1476 24.5506 11.9024 23.8177 11.8409C23.5662 11.8471 23.3159 11.7877 23.0652 11.824C22.9019 11.8451 22.736 11.831 22.5726 11.8517Z" fill="#747A7A" />
      <path d="M66.9117 9.42825C67.4277 9.38243 67.9458 9.42908 68.461 9.46458C69.6969 9.58387 70.8997 10.0111 71.9255 10.7021C72.192 10.9044 72.454 11.1132 72.7009 11.3386C72.9675 11.642 73.2378 11.9466 73.4463 12.2938C73.8925 13.0125 74.1578 13.8405 74.2034 14.6826C74.2051 15.1036 74.2222 15.5284 74.1357 15.9432C73.979 16.8501 73.5692 17.712 72.9625 18.4096C72.5505 18.8889 72.0492 19.2868 71.5052 19.612C70.8438 19.9712 70.1448 20.2779 69.4015 20.4265C68.3874 20.6514 67.3354 20.6824 66.3038 20.5784C65.4289 20.4838 64.5624 20.2609 63.7765 19.8643C62.9993 19.501 62.2899 18.9813 61.7505 18.3171C61.1501 17.6026 60.7607 16.7193 60.6283 15.8004C60.518 14.9414 60.581 14.0531 60.881 13.2354C61.0916 12.6674 61.3991 12.1341 61.7948 11.6721C61.916 11.5566 62.0158 11.4228 62.1295 11.3006C62.49 10.9911 62.8527 10.6777 63.2726 10.4474C63.9937 10.0416 64.7738 9.72091 65.5935 9.57603C66.0276 9.48852 66.4689 9.43774 66.9117 9.42825ZM66.7739 11.8455C65.8914 11.9417 65.0266 12.2971 64.3827 12.908C64.1763 13.1251 63.9921 13.365 63.8554 13.6312C63.441 14.38 63.3871 15.3133 63.7092 16.1046C63.9657 16.763 64.4767 17.3091 65.0938 17.6567C65.9445 18.1458 66.964 18.287 67.9333 18.1941C68.6686 18.1277 69.3994 17.8907 70.001 17.4619C70.591 17.0408 71.0393 16.4163 71.2043 15.7121C71.4333 14.792 71.1826 13.7654 70.5396 13.0595C70.0324 12.4849 69.3116 12.1204 68.5692 11.9413C67.9822 11.8009 67.3722 11.8059 66.7739 11.8455Z" fill="#6BABB7" />
      <path d="M22.5727 11.852C22.736 11.8314 22.9019 11.8454 23.0653 11.8243C23.316 11.788 23.5662 11.8475 23.8177 11.8413C24.5506 11.9028 25.2709 12.148 25.8709 12.5711C26.3986 12.9562 26.8272 13.4912 27.0144 14.1182C27.3224 15.0284 27.1293 16.0773 26.5344 16.8331C25.9047 17.6529 24.8736 18.0904 23.8566 18.1907C22.678 18.3199 21.404 18.0871 20.4694 17.3292C20.2797 17.1447 20.0758 16.9701 19.925 16.7513C19.5231 16.1932 19.315 15.4961 19.3793 14.8121C19.4069 14.1442 19.7044 13.5015 20.1535 13.0066C20.7761 12.337 21.6694 11.9614 22.5727 11.852Z" fill="#FFDF7A" />
    </svg>

  </Row>
);

export default IconLogoAuthLogin;
