import { Col, QRCode, Row } from 'antd';
import Text from 'components/Text';
import IconLogo from 'features/payment/pages/PrintPage/components/logo';
import paymentActions from 'features/payment/services/actions';
import paymentSelectors from 'features/payment/services/selectors';
import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import { PHONE_MASKED } from 'utils/mask';
import storage from 'utils/sessionStorage';

const CheckInPrinter = () => {
  const setting = shopSelectors.data.allSetting();
  const data = paymentSelectors.getInfoPrintWaiting();
  const divRef = useRef<HTMLDivElement>(null);
  const shopInfo = shopSelectors.data.shopInfo();
  const dispatch = useAppDispatch();
  const doPrint = useReactToPrint({
    content: () => divRef.current, onAfterPrint() {
      dispatch(paymentActions.getInfoPrintWaiting.success(null));
    },
  });

  useEffect(() => {
    if (!setting?.enablePrintCheckIn) return;

    if (!data) return;

    setTimeout(() => doPrint(), 10);

  }, [data]);

  if (storage.device_type.get() !== 'POS') return null;

  if (!setting?.enablePrintCheckIn) return null;


  return (
    <CheckInPrinterStyled style={{ display: 'none' }}>
      <TicketBox className="ticket-detail-box" ref={divRef}>
        <Row justify={'space-between'} align={'middle'} className='container'>
          <Col>
            <IconLogo />
            <TicketCodeBox>
              #{data?.ticketNumber}
            </TicketCodeBox>
          </Col>
          <Col>
            <ShopText printMode variant="CONTENT_2" className='bold' style={{ fontSize: '1.5rem' }}>{shopInfo?.shopName || ''}</ShopText>
            <ShopText printMode variant="CONTENT_2" className={'bold'} style={{ fontSize: '0.75rem' }}>
              {shopInfo?.address || ''}{', ' + shopInfo?.city || ''}
            </ShopText>
            <ShopText printMode variant="CONTENT_2" style={{ fontSize: '0.75rem' }}>
              {PHONE_MASKED.resolve(shopInfo?.phone || '')}
            </ShopText>
          </Col>
        </Row>
        <Text mb={0.5} />
        {data?.qrCode && <Row align={'middle'} justify={'center'} className='container'>
          <QRCode size={200} value={data.qrCode} />
        </Row>}
        <Text mb={0.5} />
        <Row align={'middle'} className='container'>
          <Text style={{ fontSize: '18px', color: 'black' }} mr={0.5}>Customer: </Text>
          <ShopText printMode variant="CONTENT_2" className='bold' style={{ fontSize: '18px' }}>{data?.customerName} - {maskPhone(data?.phone || '')}</ShopText>
        </Row>
        <Text mb={0.5} />
        <div className='container'>
          {data?.items?.map((item) => (
            <div key={item.staffId}>
              <StaffHeaderBox>{item.staffName || 'Next Available'}</StaffHeaderBox>
              <ServiceBoxStyled>
                {item.services.map((service) => (
                  <div key={service.itemId}>
                    <SpaceBetweenBox>
                      <Text printMode variant="CONTENT_2">
                        {service.itemName}
                      </Text>
                      <PriceBoxStyled>
                        <Text printMode variant="CONTENT_2">
                          {formatCurrency(service.price)}
                        </Text>
                      </PriceBoxStyled>
                    </SpaceBetweenBox>
                  </div>
                ))}
              </ServiceBoxStyled>
            </div>
          ))}
        </div>
        <Text mt={1} />
        <Row align={'middle'} justify={'center'} className='container'>
          <TicketCodeBox>
            POS Stations #{storage.station_number.get()}
          </TicketCodeBox>
        </Row>
        <Text style={{ height: '1rem', width: '100%' }} />
      </TicketBox>
    </CheckInPrinterStyled>
  );
};

export default CheckInPrinter;

const CheckInPrinterStyled = styled.div`
  // position: fixed;
  // inset: 0;
  // index: 999999;
  // background: red;

  .container {
    padding:0 1rem;
  }
`;
const TicketBox = styled.div`
  background: #fff;
  width: 25.5rem;
  height: fit-content;
`;
const TicketCodeBox = styled.div`
  border-radius: 5px;
  background: black;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;

  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const ShopText = styled(Text)`
  color: black;
  font-style: normal;
  font-family: 'Roboto', serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: right;

  &.bold {
    font-size: 0.8rem;
    font-weight: 700;
  }
`;

const StaffHeaderBox = styled.div`
  border-bottom: 1px solid black;
  color: black;
  font-family: 'Roboto', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

const ServiceBoxStyled = styled.div`
  padding: 0.5rem 0;
`;

const SpaceBetweenBox = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const PriceBoxStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;