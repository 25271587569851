
const Discount = ({ fill }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4454 0.0995184C11.4769 -0.0350339 12.5246 -0.0320356 13.5561 0.101767C15.5592 0.36525 17.4945 1.14183 19.1161 2.3498C20.3547 3.26356 21.4132 4.42093 22.2185 5.7331C23.0467 7.08499 23.5983 8.6033 23.8424 10.1696C23.9169 10.6111 23.9221 11.0608 24 11.5012V12.7208C23.9712 12.7943 23.9405 12.8692 23.9401 12.9498C23.7315 15.8365 22.4125 18.6209 20.3289 20.6246C18.392 22.5132 15.7966 23.7054 13.1041 23.9483C11.2287 24.1233 9.31133 23.8534 7.56206 23.1507C5.17404 22.2171 3.123 20.4705 1.77585 18.2911C0.871638 16.8185 0.287551 15.1488 0.0879875 13.4314C0.0426833 13.1717 0.0531669 12.9064 0 12.6477V11.324C0.026209 11.1819 0.0471763 11.0387 0.0524181 10.8941C0.247488 8.71012 1.06147 6.58389 2.37604 4.82946C2.801 4.27401 3.25105 3.73206 3.77635 3.26768C4.27657 2.77332 4.83969 2.3483 5.42378 1.95889C6.9293 0.971298 8.66022 0.330019 10.4454 0.0995184ZM11.3642 1.58184C8.87251 1.72951 6.43994 2.80743 4.67457 4.57835C3.15033 6.0648 2.11133 8.0366 1.72905 10.131C1.60549 10.8363 1.53997 11.5515 1.55869 12.2677C1.59239 13.1934 1.73504 14.1192 2.00649 15.0067C2.71151 17.3777 4.30016 19.4694 6.38565 20.794C8.04243 21.8644 10.0126 22.4337 11.9813 22.4416C13.9683 22.4378 15.9579 21.8614 17.6263 20.7741C19.7609 19.4087 21.3682 17.2371 22.0373 14.7901C22.3653 13.6342 22.4746 12.4214 22.3881 11.2243C22.2159 8.74497 21.1031 6.34889 19.3382 4.60309C18.2816 3.55553 17.0041 2.72535 15.6052 2.21563C14.2555 1.7104 12.8012 1.49601 11.3642 1.58184Z"
        fill={fill || '#1D2129'}
      />
      <path
        d="M14.7224 4.79681C14.9583 4.76682 15.2099 4.7642 15.4252 4.88039C15.7895 5.02356 16.0422 5.4171 15.9973 5.80988C15.955 6.03251 15.8352 6.22928 15.7382 6.4313C13.7448 10.5215 11.7469 14.609 9.75391 18.6995C9.66629 18.8873 9.51877 19.0511 9.32857 19.1377C9.14436 19.2378 8.93094 19.2318 8.72801 19.2175C8.37494 19.1512 8.06005 18.8858 7.96233 18.535C7.87809 18.3382 7.9305 18.1205 8.02224 17.9368C10.08 13.7106 12.1326 9.4814 14.1915 5.25593C14.2911 5.03218 14.4865 4.86315 14.7224 4.79681Z"
        fill={fill || '#1D2129'}
      />
      <path
        d="M7.32035 5.52168C7.7865 5.4857 8.26463 5.47071 8.72291 5.58015C9.15723 5.67347 9.59305 5.84925 9.90681 6.17458C10.334 6.58273 10.5119 7.19215 10.5089 7.77084C10.513 8.66998 10.5119 9.56912 10.5092 10.4683C10.5186 11.0766 10.3239 11.7171 9.86787 12.138C9.48147 12.5109 8.94531 12.6694 8.42562 12.7407C7.72135 12.8164 6.97888 12.7725 6.33152 12.4622C5.85452 12.2351 5.49358 11.7947 5.34681 11.2887C5.18918 10.8322 5.22363 10.3438 5.22026 9.86896C5.22138 9.18158 5.21801 8.49458 5.22213 7.8072C5.22325 7.2019 5.40634 6.56174 5.85863 6.13822C6.25065 5.76118 6.79542 5.60226 7.32035 5.52168ZM7.61764 7.05123C7.50044 7.08046 7.3825 7.12057 7.28478 7.19403C7.0972 7.33458 7.00884 7.57857 7.0152 7.80795C7.01221 8.68197 7.01333 9.55638 7.01483 10.4304C7.00397 10.6549 7.07698 10.8925 7.24697 11.0458C7.43679 11.2081 7.69739 11.2527 7.94001 11.2392C8.22007 11.237 8.51698 11.1088 8.65252 10.8524C8.76709 10.6568 8.74949 10.4237 8.75099 10.2059C8.74912 9.40608 8.75399 8.60589 8.74837 7.8057C8.75211 7.53285 8.60909 7.24238 8.35074 7.13069C8.12572 7.01338 7.86213 7.02125 7.61764 7.05123Z"
        fill={fill || '#1D2129'}
      />
      <path
        d="M15.5834 11.2051C16.1787 11.1778 16.7976 11.1939 17.3529 11.4349C17.7378 11.5803 18.0759 11.8535 18.2908 12.2058C18.5435 12.6144 18.6383 13.1027 18.6323 13.578C18.633 14.416 18.6334 15.2537 18.6323 16.0914C18.636 16.6768 18.4844 17.2926 18.0808 17.7334C17.6412 18.2041 16.9875 18.4091 16.3599 18.4443C16.0129 18.4998 15.6617 18.4556 15.3157 18.4185C14.7986 18.3428 14.2726 18.1479 13.9075 17.7596C13.5488 17.4002 13.386 16.8871 13.3466 16.3905C13.335 15.4407 13.3433 14.491 13.3418 13.5412C13.3335 13.1511 13.3972 12.7534 13.5661 12.3996C13.7222 12.0522 13.9963 11.7625 14.3216 11.5683C14.7065 11.3472 15.1453 11.2497 15.5834 11.2051ZM15.7347 12.7459C15.5733 12.7759 15.4145 12.8452 15.3078 12.9734C15.1457 13.144 15.1161 13.3913 15.1131 13.6162C15.1131 14.3654 15.1135 15.115 15.1131 15.8646C15.112 16.1007 15.1049 16.3526 15.221 16.5666C15.3292 16.7682 15.5471 16.8844 15.768 16.9155C16.0342 16.9508 16.3285 16.9347 16.558 16.7806C16.7815 16.6303 16.8665 16.3474 16.8654 16.0902C16.8654 15.2398 16.8665 14.3898 16.865 13.5397C16.8553 13.3216 16.7995 13.0851 16.6276 12.9367C16.3944 12.7077 16.0372 12.7137 15.7347 12.7459Z"
        fill={fill || '#1D2129'}
      />
    </svg>
  );
};

export default Discount;
