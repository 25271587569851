import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Select } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Spin from 'components/Spin';
import Text from 'components/Text';
import {
  ICategory,
  IServiceCategory,
} from 'features/cashier/services/types/api';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ButtonGroup from '../ButtonGroup';
// import { debounce } from 'lodash';
type ILeftSpecializeStaffProps = {};
const LeftSpecializeStaff: React.FC<ILeftSpecializeStaffProps> = () => {
  const [form] = Form.useForm<any>();
  const dispatch = useAppDispatch();
  const [cateSelected, setCateSelected] = useState('');
  const shopCategories = shopSelectors.data.categories();
  const shopAddOnList = shopSelectors.data.addonServices();
  const loading = shopSelectors.loading.categories();
  const servicesSelected = settingStaffSelectors.servicesSelected();
  const staffSelected = settingStaffSelectors.selectedStaffData.data();
  const addOnCate: ICategory = {
    id: 'add-on',
    categoryName: 'Add-on',
    services: shopAddOnList,
  };
  const optionsCate = [addOnCate, ...shopCategories].map((item, index) => ({
    ...item,
    value: item.id,
    label: item?.categoryName || 'cate at ' + index,
  }));

  const handleChange = (value: string) => {
    setCateSelected(value);
  };

  // const _debounce = debounce(() => undefined, 500);

  const handleClickService = (serviceItem: IServiceCategory) => {
    const serviceConvert = {
      serviceId: serviceItem.id,
      serviceName: serviceItem.serviceName,
    };
    dispatch(settingStaffActions.clickService(serviceConvert));
  };

  const onFinish = () => {
    const body = {
      staffId: staffSelected.id,
      serviceIds: servicesSelected.map((item) => item.serviceId),
    };
    dispatch(settingStaffActions.staffUpdateMappingService.fetch(body));
  };

  useEffect(() => {
    if (loading === false) {
      setCateSelected(optionsCate?.[0].id);
    }
  }, [loading]);

  return (
    <LeftSpecializeStaffStyled>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Box>
          <Row gutter={24}>
            <Col span={12}>
              <Select
                defaultValue="add-on"
                style={{ width: '100%' }}
                onChange={handleChange}
                options={optionsCate}
              />
            </Col>
            <Col span={12}>
              {/* <Box>
                <Input
                  onChange={(event) => {
                    _debounce(event.target.value);
                  }}
                  placeholder="Search"
                  suffix={<Icon type="search"></Icon>}
                />
              </Box> */}
            </Col>
          </Row>
        </Box>
        <Box mt="3">
          <Box>
            <Text color="text_2">Services</Text>
          </Box>
          <Box mt="2">
            <Row gutter={[16, 16]}>
              {optionsCate
                .find((item) => item.id === cateSelected)
                ?.services.map((service) => {
                  const isSelected = servicesSelected.some(
                    (item) => item.serviceId === service.id
                  );
                  return (
                    <Col key={service.id} span={8}>
                      <ServiceItemStyled
                        selected={isSelected}
                        onClick={() => handleClickService(service)}
                      >
                        {isSelected && (
                          <IconBadge>
                            <Icon type="tick" />
                          </IconBadge>
                        )}
                        <Text variant="H8" color="text_3">
                          {service.serviceName}
                        </Text>
                      </ServiceItemStyled>
                    </Col>
                  );
                })}
            </Row>
          </Box>
        </Box>
        <Box mt="3">
          <ButtonGroup />
        </Box>
        {loading && <Spin />}
      </Form>
    </LeftSpecializeStaffStyled>
  );
};

export default LeftSpecializeStaff;
const LeftSpecializeStaffStyled = styled.div`
  padding: 1.5rem;
  width: 100%;
  .ant-input-suffix {
    opacity: 0.5;
  }
`;
const ServiceItemStyled = styled.div<{ selected?: boolean }>`
  position: relative;
  cursor: pointer;
  padding: 1rem;
  border-radius: 5px;
  // ${({ selected }) => !selected && 'border: 1px solid #86909c;'}
  background: ${(props) => (props.selected ? '#F6F7FC' : '#fff')};
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:hover {
    background: ${(props) => (props.selected ? '#f6f7fc' : '#f6f7fc')};
  }
`;
const IconBadge = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;
