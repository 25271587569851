import { get, sum } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
import { StaffWorkItem } from './types/api';
import { IBillDetailData } from './types/bill';

type MyState = RootState['payment'];
const getCurrentState = (state: RootState): MyState => state['payment'];

export const checkIsShareTip = (root: RootState) => {
  const state = getCurrentState(root);
  return (state.data?.items.length ?? 0) > 1;
};

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getData = () => selector('data') as (IBillDetailData | null);

const getDemoDataLoading = () => uiSelector.getLoading(PATH_LOADING.getData) as boolean;

const getIsSentPayInfo = () => selector('isSentPayInfo') as boolean;

const getStaffWorkList = () => selector('staffWorkList') as StaffWorkItem[];

const detailPayment = () => selector('payment') as MyState['payment'];

const getVisibleShareTip = () => selector('visibleShareTip') as MyState['visibleShareTip'];

const getSignatureValue = () => selector('signatureValue') as MyState['signatureValue'];

const getActivePaymentType = () => selector('activePaymentType') as MyState['activePaymentType'];

const getInfoPrintWaiting = () => selector('infoPrintWaiting') as MyState['infoPrintWaiting'];

const remaining = () => {
  const data = paymentSelectors.getData();
  const detailPayment = paymentSelectors.detailPayment();
  const subTotal = sum(Object.values(detailPayment).map(o => (o?.amount ?? 0)));
  return (data?.total ?? 0) - subTotal;
};

const paymentSelectors = {
  getData,
  getDemoDataLoading,
  getIsSentPayInfo,
  getStaffWorkList,
  detailPayment,
  getVisibleShareTip,
  getSignatureValue,
  getActivePaymentType,
  remaining,
  getInfoPrintWaiting,
};

export default paymentSelectors;
