import React from 'react';

function cardServiceIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
            <path d="M4.29758 0.141479C4.4393 0.138184 4.72273 0.138184 4.86444 0.138184C5.48403 0.991768 5.76087 2.06946 6.51559 2.83077C7.24394 3.60525 8.31503 3.86561 9.17521 4.44566C9.17521 4.58737 9.17521 4.8741 9.17521 5.01911C8.30185 5.58926 7.22416 5.85951 6.49581 6.64388C5.75428 7.3986 5.48403 8.46311 4.87763 9.3101C4.73262 9.3101 4.4426 9.31339 4.29758 9.31669C3.69447 8.46311 3.41434 7.40189 2.6761 6.64059C1.94775 5.85951 0.866767 5.59585 0 5.01252C0 4.8708 0 4.58407 0 4.44236C0.870063 3.86232 1.94446 3.59537 2.6761 2.81429C3.41434 2.05298 3.69447 0.991768 4.29758 0.141479ZM2.47507 4.72908C3.26603 5.36515 3.97461 6.0968 4.58761 6.90754C5.19731 6.0968 5.90588 5.36515 6.70015 4.72908C5.90918 4.08972 5.19731 3.36467 4.58761 2.55393C3.97461 3.36137 3.26274 4.08972 2.47507 4.72908Z" fill="#FF8890" />
            <path d="M17.1938 0.88019C19.7447 -0.438087 23.047 0.0101268 25.176 1.92492C26.2537 2.83124 26.9095 4.10667 27.4434 5.3854C28.6233 5.47768 30.0602 5.06902 31.0193 5.95885C31.7641 6.65424 31.5828 7.75501 31.6224 8.67121C31.5762 11.7626 31.6619 14.8572 31.5894 17.9453C31.6323 19.1383 30.4656 20.015 29.3286 19.8865C23.6073 19.9062 17.8826 19.8733 12.1613 19.8996C11.3506 19.8733 10.3849 19.9656 9.77853 19.3031C9.11609 18.6967 9.21826 17.7278 9.19189 16.917C9.22156 13.829 9.18201 10.7409 9.20508 7.65284C9.13257 6.72675 9.65 5.71827 10.6025 5.49086C11.5121 5.31619 12.448 5.39529 13.3708 5.3854C14.0761 3.50356 15.3318 1.75025 17.1938 0.88019ZM18.9043 1.62502C16.4787 2.23142 14.5836 4.51534 14.4914 7.02336C14.3398 9.25455 15.5822 11.4989 17.5531 12.5535C19.6425 13.7433 22.4439 13.4533 24.2532 11.868C26.0856 10.3454 26.8107 7.66273 25.9999 5.41836C25.0771 2.56758 21.7913 0.814276 18.9043 1.62502ZM10.8793 6.78278C10.3586 7.11894 10.586 7.82751 10.5102 8.34494C10.5563 11.5451 10.4574 14.7518 10.5563 17.9486C10.5234 18.6143 11.3605 18.5583 11.812 18.5748C17.7541 18.5418 23.6995 18.6242 29.6417 18.5352C30.3272 18.5715 30.2942 17.7607 30.2975 17.2862C30.2843 14.0729 30.3173 10.8595 30.281 7.64625C30.3931 6.23569 28.5574 6.83221 27.7005 6.68391C27.7565 9.10624 26.837 11.6143 24.8464 13.0874C22.2165 15.2099 18.1166 15.0846 15.6218 12.8073C13.8454 11.3111 13.0676 8.95793 13.1105 6.6872C12.3689 6.70038 11.6175 6.67072 10.8793 6.78278Z" fill="#FF8890" />
            <path d="M35.9889 0.187855L36.236 0C37.2313 0.767897 37.3335 2.22459 38.3387 3.00897C39.0901 3.76368 40.3161 3.85267 40.9588 4.72602C40.4776 5.45108 39.5416 5.5895 38.8594 6.06078C37.7751 6.67048 37.3335 7.8833 36.7699 8.91815C36.6216 9.29715 36.3415 9.42898 35.9295 9.31034C35.2935 8.40072 35.01 7.24723 34.1565 6.48593C33.4314 5.8169 32.4427 5.55654 31.6353 5.00945C31.6353 4.86774 31.6353 4.58431 31.6353 4.4426C32.5712 3.8263 33.7478 3.54287 34.4761 2.63655C35.1221 1.9148 35.3165 0.883246 35.9889 0.187855ZM34.1136 4.72932C34.9046 5.36539 35.6132 6.09703 36.2262 6.90777C36.8359 6.09703 37.5444 5.36539 38.3354 4.72932C37.5477 4.08996 36.8359 3.3649 36.2262 2.55087C35.6132 3.36161 34.9013 4.08996 34.1136 4.72932Z" fill="#FF8890" />
            <path d="M18.9336 5.63901C19.4543 4.90078 19.5499 3.29578 20.6935 3.43419C21.2142 4.09004 21.5174 4.881 21.8766 5.62913C22.6841 5.96199 24.1243 5.70822 24.3583 6.71671C24.065 7.47801 23.3135 7.93941 22.773 8.51945C22.8686 9.51475 23.2707 10.6188 22.572 11.4922C21.847 11.1099 21.1318 10.7111 20.4166 10.309C19.5927 10.6056 18.6337 11.8053 17.8097 10.9714C17.7867 10.1475 17.9416 9.33349 18.0404 8.51945C17.4769 7.86031 16.4717 7.37255 16.4453 6.41021C17.177 5.90597 18.0998 5.86642 18.9336 5.63901ZM18.6238 7.15174C19.2994 7.72519 19.4708 8.53593 19.3653 9.38951C19.708 9.19836 20.0541 9.01051 20.4001 8.82265C20.7495 9.00062 21.0988 9.18188 21.4482 9.36315C21.3394 8.51615 21.5306 7.72848 22.1897 7.15503C21.3889 6.99684 20.7462 6.55851 20.4067 5.80709C20.0607 6.55522 19.4279 7.00343 18.6238 7.15174Z" fill="#FF8890" />
            <path d="M3.57895 13.5749C5.38499 12.662 7.70845 14.2538 7.83039 16.2016C7.96222 17.9582 7.85346 19.728 7.87653 21.4879C8.72023 21.3231 9.70564 21.0166 10.4472 21.6263C11.6732 22.5853 12.6619 23.8048 13.7956 24.866C15.0447 26.148 16.508 27.3311 17.2001 29.035C17.9251 30.673 17.7603 32.5054 17.7702 34.2488C18.1953 34.463 19.0885 34.374 19.0522 35.0562C19.1116 36.9018 19.1643 38.7639 19.016 40.6062C18.9402 40.682 18.7919 40.8336 18.7161 40.9094C16.8705 41.0907 15.0117 40.9358 13.1595 40.9819C11.3073 40.9325 9.44528 41.094 7.5997 40.9061C7.52389 40.8369 7.37559 40.6985 7.29979 40.6293C7.14819 38.7705 7.20092 36.892 7.26024 35.0266C7.25694 34.3213 8.24236 34.463 8.6708 34.2093C8.39725 32.5746 6.96692 31.5463 6.05402 30.2643C4.96973 28.7747 3.51963 27.5124 2.65945 25.881C2.52433 22.7831 2.66275 19.6785 2.60013 16.5806C2.59683 15.526 2.48807 14.1616 3.57895 13.5749ZM3.96455 15.2656C3.85908 18.1197 3.97443 20.9803 3.91511 23.8377C3.96784 24.5858 3.75033 25.4724 4.32707 26.0755C5.98481 28.1452 7.70845 30.1621 9.36948 32.2285C9.93634 32.8053 9.84077 33.649 9.92646 34.3905C12.1016 34.3905 14.2768 34.3905 16.4519 34.3905C16.419 32.7064 16.6563 30.9202 15.8983 29.3514C15.1402 27.7695 13.6868 26.7017 12.5103 25.4493C11.4919 24.4968 10.6251 23.3565 9.46176 22.5722C8.58511 22.4337 8.08416 23.3565 7.46787 23.8146C8.64443 24.9813 9.81111 26.1612 10.9877 27.3311C10.6746 27.6442 10.3648 27.954 10.055 28.2638C8.69716 26.7709 7.02954 25.535 5.88593 23.8674C6.07379 23.4917 6.31108 23.1489 6.54507 22.8029C6.55826 20.7167 6.61428 18.6272 6.53848 16.5443C6.55496 15.6182 5.81673 14.7581 4.89393 14.6493C4.44572 14.4812 3.94148 14.7548 3.96455 15.2656ZM8.54226 35.7088C8.54226 37.0271 8.54226 38.3454 8.54226 39.6636C11.6171 39.6636 14.6953 39.6636 17.7702 39.6636C17.7702 38.3454 17.7702 37.0271 17.7702 35.7088C14.6953 35.7088 11.6171 35.7088 8.54226 35.7088Z" fill="#FF8890" />
            <path d="M32.9834 16.1981C33.102 14.2504 35.4288 12.6619 37.2315 13.5748C38.4377 14.2405 38.184 15.7763 38.2136 16.9265C38.118 19.9717 38.3586 23.0433 38.0983 26.0753C37.0239 27.6902 35.6496 29.0876 34.4664 30.6267C33.626 31.7736 32.3605 32.7227 32.143 34.2091C32.5813 34.4629 33.57 34.3179 33.5535 35.0495C33.6491 36.5919 33.5865 38.1376 33.5931 39.6833C33.537 40.1018 33.6853 40.6753 33.2173 40.906C31.3685 41.0938 29.5031 40.9323 27.6476 40.9818C25.7954 40.9356 23.9367 41.0905 22.0911 40.9093C22.0186 40.8335 21.8703 40.6819 21.7978 40.6061C21.6626 38.6583 21.6725 36.6875 21.7945 34.7364C22.1405 34.4332 22.6283 34.4003 23.0435 34.2454C23.0534 32.4986 22.8853 30.6662 23.6104 29.0283C24.3387 27.2519 25.8976 26.0457 27.1928 24.6977C28.2639 23.6827 29.2098 22.5292 30.3798 21.6196C31.118 21.0132 32.1001 21.3262 32.9372 21.4877C32.957 19.7245 32.8515 17.958 32.9834 16.1981ZM34.2753 16.5508C34.2028 18.6337 34.2555 20.7198 34.2687 22.8027C34.4994 23.1521 34.74 23.4981 34.9344 23.8672C33.7578 25.5546 32.0803 26.83 30.6764 28.323C30.4292 27.9769 30.1655 27.6375 29.9777 27.2552C31.0158 26.0292 32.2385 24.9746 33.3459 23.8145C32.69 23.2773 31.8266 21.9854 30.9301 22.862C29.1505 24.5758 27.384 26.3126 25.6966 28.1154C24.1509 29.8291 24.3289 32.2515 24.3618 34.3904C26.537 34.3904 28.7121 34.3904 30.8873 34.3904C30.9895 33.7312 30.851 32.9633 31.3124 32.413C33.0394 30.2081 34.9014 28.1022 36.6185 25.8875C36.9547 25.5085 36.8558 24.9779 36.8953 24.5132C36.8855 21.7646 36.8789 19.016 36.8953 16.2674C36.836 15.7203 37.014 15.0216 36.5097 14.6327C35.3826 14.4547 34.2324 15.3907 34.2753 16.5508ZM23.0435 35.7086C23.0435 37.0269 23.0435 38.3452 23.0435 39.6635C26.1184 39.6635 29.1966 39.6635 32.2715 39.6635C32.2715 38.3452 32.2715 37.0269 32.2715 35.7086C29.1966 35.7086 26.1184 35.7086 23.0435 35.7086Z" fill="#FF8890" />
            <path d="M13.1562 15.9346C17.991 15.9346 22.8225 15.9346 27.6573 15.9346C27.6573 16.3729 27.6573 16.8145 27.6573 17.2528C22.8225 17.2528 17.991 17.2528 13.1562 17.2528C13.1562 16.8145 13.1562 16.3729 13.1562 15.9346Z" fill="#FF8890" />
        </svg>
    );
}

export function cardServiceIconActive() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
            <path d="M4.29758 0.141479C4.4393 0.138184 4.72273 0.138184 4.86444 0.138184C5.48403 0.991768 5.76087 2.06946 6.51559 2.83077C7.24394 3.60525 8.31503 3.86561 9.17521 4.44566C9.17521 4.58737 9.17521 4.8741 9.17521 5.01911C8.30185 5.58926 7.22416 5.85951 6.49581 6.64388C5.75428 7.3986 5.48403 8.46311 4.87763 9.3101C4.73262 9.3101 4.4426 9.31339 4.29758 9.31669C3.69447 8.46311 3.41434 7.40189 2.6761 6.64059C1.94775 5.85951 0.866767 5.59585 0 5.01252C0 4.8708 0 4.58407 0 4.44236C0.870063 3.86232 1.94446 3.59537 2.6761 2.81429C3.41434 2.05298 3.69447 0.991768 4.29758 0.141479ZM2.47507 4.72908C3.26603 5.36515 3.97461 6.0968 4.58761 6.90754C5.19731 6.0968 5.90588 5.36515 6.70015 4.72908C5.90918 4.08972 5.19731 3.36467 4.58761 2.55393C3.97461 3.36137 3.26274 4.08972 2.47507 4.72908Z" fill="#fff" />
            <path d="M17.1938 0.88019C19.7447 -0.438087 23.047 0.0101268 25.176 1.92492C26.2537 2.83124 26.9095 4.10667 27.4434 5.3854C28.6233 5.47768 30.0602 5.06902 31.0193 5.95885C31.7641 6.65424 31.5828 7.75501 31.6224 8.67121C31.5762 11.7626 31.6619 14.8572 31.5894 17.9453C31.6323 19.1383 30.4656 20.015 29.3286 19.8865C23.6073 19.9062 17.8826 19.8733 12.1613 19.8996C11.3506 19.8733 10.3849 19.9656 9.77853 19.3031C9.11609 18.6967 9.21826 17.7278 9.19189 16.917C9.22156 13.829 9.18201 10.7409 9.20508 7.65284C9.13257 6.72675 9.65 5.71827 10.6025 5.49086C11.5121 5.31619 12.448 5.39529 13.3708 5.3854C14.0761 3.50356 15.3318 1.75025 17.1938 0.88019ZM18.9043 1.62502C16.4787 2.23142 14.5836 4.51534 14.4914 7.02336C14.3398 9.25455 15.5822 11.4989 17.5531 12.5535C19.6425 13.7433 22.4439 13.4533 24.2532 11.868C26.0856 10.3454 26.8107 7.66273 25.9999 5.41836C25.0771 2.56758 21.7913 0.814276 18.9043 1.62502ZM10.8793 6.78278C10.3586 7.11894 10.586 7.82751 10.5102 8.34494C10.5563 11.5451 10.4574 14.7518 10.5563 17.9486C10.5234 18.6143 11.3605 18.5583 11.812 18.5748C17.7541 18.5418 23.6995 18.6242 29.6417 18.5352C30.3272 18.5715 30.2942 17.7607 30.2975 17.2862C30.2843 14.0729 30.3173 10.8595 30.281 7.64625C30.3931 6.23569 28.5574 6.83221 27.7005 6.68391C27.7565 9.10624 26.837 11.6143 24.8464 13.0874C22.2165 15.2099 18.1166 15.0846 15.6218 12.8073C13.8454 11.3111 13.0676 8.95793 13.1105 6.6872C12.3689 6.70038 11.6175 6.67072 10.8793 6.78278Z" fill="#fff" />
            <path d="M35.9889 0.187855L36.236 0C37.2313 0.767897 37.3335 2.22459 38.3387 3.00897C39.0901 3.76368 40.3161 3.85267 40.9588 4.72602C40.4776 5.45108 39.5416 5.5895 38.8594 6.06078C37.7751 6.67048 37.3335 7.8833 36.7699 8.91815C36.6216 9.29715 36.3415 9.42898 35.9295 9.31034C35.2935 8.40072 35.01 7.24723 34.1565 6.48593C33.4314 5.8169 32.4427 5.55654 31.6353 5.00945C31.6353 4.86774 31.6353 4.58431 31.6353 4.4426C32.5712 3.8263 33.7478 3.54287 34.4761 2.63655C35.1221 1.9148 35.3165 0.883246 35.9889 0.187855ZM34.1136 4.72932C34.9046 5.36539 35.6132 6.09703 36.2262 6.90777C36.8359 6.09703 37.5444 5.36539 38.3354 4.72932C37.5477 4.08996 36.8359 3.3649 36.2262 2.55087C35.6132 3.36161 34.9013 4.08996 34.1136 4.72932Z" fill="#fff" />
            <path d="M18.9336 5.63901C19.4543 4.90078 19.5499 3.29578 20.6935 3.43419C21.2142 4.09004 21.5174 4.881 21.8766 5.62913C22.6841 5.96199 24.1243 5.70822 24.3583 6.71671C24.065 7.47801 23.3135 7.93941 22.773 8.51945C22.8686 9.51475 23.2707 10.6188 22.572 11.4922C21.847 11.1099 21.1318 10.7111 20.4166 10.309C19.5927 10.6056 18.6337 11.8053 17.8097 10.9714C17.7867 10.1475 17.9416 9.33349 18.0404 8.51945C17.4769 7.86031 16.4717 7.37255 16.4453 6.41021C17.177 5.90597 18.0998 5.86642 18.9336 5.63901ZM18.6238 7.15174C19.2994 7.72519 19.4708 8.53593 19.3653 9.38951C19.708 9.19836 20.0541 9.01051 20.4001 8.82265C20.7495 9.00062 21.0988 9.18188 21.4482 9.36315C21.3394 8.51615 21.5306 7.72848 22.1897 7.15503C21.3889 6.99684 20.7462 6.55851 20.4067 5.80709C20.0607 6.55522 19.4279 7.00343 18.6238 7.15174Z" fill="#fff" />
            <path d="M3.57895 13.5749C5.38499 12.662 7.70845 14.2538 7.83039 16.2016C7.96222 17.9582 7.85346 19.728 7.87653 21.4879C8.72023 21.3231 9.70564 21.0166 10.4472 21.6263C11.6732 22.5853 12.6619 23.8048 13.7956 24.866C15.0447 26.148 16.508 27.3311 17.2001 29.035C17.9251 30.673 17.7603 32.5054 17.7702 34.2488C18.1953 34.463 19.0885 34.374 19.0522 35.0562C19.1116 36.9018 19.1643 38.7639 19.016 40.6062C18.9402 40.682 18.7919 40.8336 18.7161 40.9094C16.8705 41.0907 15.0117 40.9358 13.1595 40.9819C11.3073 40.9325 9.44528 41.094 7.5997 40.9061C7.52389 40.8369 7.37559 40.6985 7.29979 40.6293C7.14819 38.7705 7.20092 36.892 7.26024 35.0266C7.25694 34.3213 8.24236 34.463 8.6708 34.2093C8.39725 32.5746 6.96692 31.5463 6.05402 30.2643C4.96973 28.7747 3.51963 27.5124 2.65945 25.881C2.52433 22.7831 2.66275 19.6785 2.60013 16.5806C2.59683 15.526 2.48807 14.1616 3.57895 13.5749ZM3.96455 15.2656C3.85908 18.1197 3.97443 20.9803 3.91511 23.8377C3.96784 24.5858 3.75033 25.4724 4.32707 26.0755C5.98481 28.1452 7.70845 30.1621 9.36948 32.2285C9.93634 32.8053 9.84077 33.649 9.92646 34.3905C12.1016 34.3905 14.2768 34.3905 16.4519 34.3905C16.419 32.7064 16.6563 30.9202 15.8983 29.3514C15.1402 27.7695 13.6868 26.7017 12.5103 25.4493C11.4919 24.4968 10.6251 23.3565 9.46176 22.5722C8.58511 22.4337 8.08416 23.3565 7.46787 23.8146C8.64443 24.9813 9.81111 26.1612 10.9877 27.3311C10.6746 27.6442 10.3648 27.954 10.055 28.2638C8.69716 26.7709 7.02954 25.535 5.88593 23.8674C6.07379 23.4917 6.31108 23.1489 6.54507 22.8029C6.55826 20.7167 6.61428 18.6272 6.53848 16.5443C6.55496 15.6182 5.81673 14.7581 4.89393 14.6493C4.44572 14.4812 3.94148 14.7548 3.96455 15.2656ZM8.54226 35.7088C8.54226 37.0271 8.54226 38.3454 8.54226 39.6636C11.6171 39.6636 14.6953 39.6636 17.7702 39.6636C17.7702 38.3454 17.7702 37.0271 17.7702 35.7088C14.6953 35.7088 11.6171 35.7088 8.54226 35.7088Z" fill="#fff" />
            <path d="M32.9834 16.1981C33.102 14.2504 35.4288 12.6619 37.2315 13.5748C38.4377 14.2405 38.184 15.7763 38.2136 16.9265C38.118 19.9717 38.3586 23.0433 38.0983 26.0753C37.0239 27.6902 35.6496 29.0876 34.4664 30.6267C33.626 31.7736 32.3605 32.7227 32.143 34.2091C32.5813 34.4629 33.57 34.3179 33.5535 35.0495C33.6491 36.5919 33.5865 38.1376 33.5931 39.6833C33.537 40.1018 33.6853 40.6753 33.2173 40.906C31.3685 41.0938 29.5031 40.9323 27.6476 40.9818C25.7954 40.9356 23.9367 41.0905 22.0911 40.9093C22.0186 40.8335 21.8703 40.6819 21.7978 40.6061C21.6626 38.6583 21.6725 36.6875 21.7945 34.7364C22.1405 34.4332 22.6283 34.4003 23.0435 34.2454C23.0534 32.4986 22.8853 30.6662 23.6104 29.0283C24.3387 27.2519 25.8976 26.0457 27.1928 24.6977C28.2639 23.6827 29.2098 22.5292 30.3798 21.6196C31.118 21.0132 32.1001 21.3262 32.9372 21.4877C32.957 19.7245 32.8515 17.958 32.9834 16.1981ZM34.2753 16.5508C34.2028 18.6337 34.2555 20.7198 34.2687 22.8027C34.4994 23.1521 34.74 23.4981 34.9344 23.8672C33.7578 25.5546 32.0803 26.83 30.6764 28.323C30.4292 27.9769 30.1655 27.6375 29.9777 27.2552C31.0158 26.0292 32.2385 24.9746 33.3459 23.8145C32.69 23.2773 31.8266 21.9854 30.9301 22.862C29.1505 24.5758 27.384 26.3126 25.6966 28.1154C24.1509 29.8291 24.3289 32.2515 24.3618 34.3904C26.537 34.3904 28.7121 34.3904 30.8873 34.3904C30.9895 33.7312 30.851 32.9633 31.3124 32.413C33.0394 30.2081 34.9014 28.1022 36.6185 25.8875C36.9547 25.5085 36.8558 24.9779 36.8953 24.5132C36.8855 21.7646 36.8789 19.016 36.8953 16.2674C36.836 15.7203 37.014 15.0216 36.5097 14.6327C35.3826 14.4547 34.2324 15.3907 34.2753 16.5508ZM23.0435 35.7086C23.0435 37.0269 23.0435 38.3452 23.0435 39.6635C26.1184 39.6635 29.1966 39.6635 32.2715 39.6635C32.2715 38.3452 32.2715 37.0269 32.2715 35.7086C29.1966 35.7086 26.1184 35.7086 23.0435 35.7086Z" fill="#fff" />
            <path d="M13.1562 15.9346C17.991 15.9346 22.8225 15.9346 27.6573 15.9346C27.6573 16.3729 27.6573 16.8145 27.6573 17.2528C22.8225 17.2528 17.991 17.2528 13.1562 17.2528C13.1562 16.8145 13.1562 16.3729 13.1562 15.9346Z" fill="#fff" />
        </svg>
    );
}

export default cardServiceIcon;
