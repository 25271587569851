import { Col, Divider, Form, Input, Row, Switch } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import {
  ILoyaltyDataBody,
  IReward,
  RewardType,
} from 'features/settingService/services/types/paymentSetting';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type ILoyaltyRewardsProps = {};
type FormData = {
  [key: string]: any;
};
const LoyaltyRewards: React.FC<ILoyaltyRewardsProps> = () => {
  const dispatch = useAppDispatch();
  const loyaltyStore = settingServiceSelectors.paymentSetting.loyaltyReward();
  const [form] = Form.useForm();
  const rewardPointsUsageWatch = Form.useWatch(
    `${RewardType.LOYALTY_REWARD + '_active'}`,
    form
  );
  const loyaltyByVisitingWatch = Form.useWatch(
    `${RewardType.LOYALTY_ENROLLMENT_VISIT + '_active'}`,
    form
  );
  const referralWatch = Form.useWatch(
    `${RewardType.LOYALTY_ENROLLMENT_REFERRAL + '_active'}`,
    form
  );
  const redeemWatch = Form.useWatch(
    `${RewardType.LOYALTY_REDEMPTION + '_active'}`,
    form
  );

  const mergedData = loyaltyStore.reduce((acc, item) => {
    if (item.type) {
      return {
        ...acc,
        [`${item.type}_id`]: item.id,
        [`${item.type}_minBill`]: item.minBill,
        [`${item.type}_amount`]: item.amount,
        [`${item.type}_point`]: item.point,
        [`${item.type}_active`]: item.active,
      };
    }
    return acc;
  }, {});

  const mapFormDataToArray = (
    arrayData: IReward[],
    formData: FormData
  ): ILoyaltyDataBody[] => {
    return arrayData.map((data) => {
      const {
        [`${data.type}_active`]: active,
        [`${data.type}_minBill`]: minBill,
        [`${data.type}_point`]: point,
        [`${data.type}_amount`]: amount,
      } = formData;

      return {
        loyaltyType: data.type,
        enable: active,
        point: point || 0,
        minBill: minBill || 0,
        amount: amount || 0,
      };
    });
  };

  const onFinish = (values: any) => {
    const loyaltyStoreConvert = loyaltyStore.filter((item) => {
      return item.type;
    }, {});
    const body = mapFormDataToArray(loyaltyStoreConvert, values);
    dispatch(settingServiceActions.postLoyalty.fetch(body));
  };

  const handleSwitchChange = (name: string, val: boolean) => {
    if (val) {
      form.setFieldsValue({ [name]: !val });
    }
  };

  const handleDollarChange = (value: number) => {
    form.setFieldsValue({ equal_point: value });
  };

  useEffect(() => {
    if (mergedData) {
      form.setFieldsValue(mergedData);
    }
  }, [loyaltyStore]);

  useEffect(() => {
    dispatch(settingServiceActions.getLoyaltyReward.fetch());
  }, []);

  return (
    <LoyaltyRewardsStyled>
      <Form
        form={form}
        layout="vertical"
        initialValues={mergedData}
        onFinish={onFinish}
      >
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Box display="flex" gap="3" alignItems="center">
                <Form.Item
                  noStyle
                  valuePropName="checked"
                  name={RewardType.LOYALTY_REWARD + '_active'}
                >
                  <Switch
                    onChange={(val) =>
                      handleSwitchChange(
                        `${RewardType.LOYALTY_ENROLLMENT_VISIT + '_active'}`,
                        val
                      )
                    }
                  />
                </Form.Item>
                <Text variant="CONTENT_2" color="text_3">
                  Reward Points Usage
                </Text>
              </Box>
              {rewardPointsUsageWatch && (
                <Box>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Dollar"
                        name={RewardType.LOYALTY_REWARD + '_minBill'}
                        style={{ margin: 0 }}
                      >
                        <CurrencyInputPrecision
                          size="small"
                          onChange={handleDollarChange}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Equal Point"
                        name={RewardType.LOYALTY_REWARD + '_point'}
                        style={{ margin: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Box>
              )}
            </Col>
            <Col span={12}>
              <Box display="flex" gap="3" alignItems="center">
                <Form.Item
                  noStyle
                  valuePropName="checked"
                  name={RewardType.LOYALTY_ENROLLMENT_VISIT + '_active'}
                >
                  <Switch
                    onChange={(val) =>
                      handleSwitchChange(
                        `${RewardType.LOYALTY_REWARD + '_active'}`,
                        val
                      )
                    }
                  />
                </Form.Item>
                <Text variant="CONTENT_2" color="text_3">
                  Loyalty By Each Visiting
                </Text>
              </Box>
              {loyaltyByVisitingWatch && (
                <Box>
                  <Row gutter={[16, 16]}>
                    <Col span={12}>
                      <Form.Item
                        label="Min Ticket"
                        name={RewardType.LOYALTY_ENROLLMENT_VISIT + '_minBill'}
                        style={{ margin: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label="Point"
                        name={RewardType.LOYALTY_ENROLLMENT_VISIT + '_point'}
                        style={{ margin: 0 }}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Box>
              )}
            </Col>
          </Row>

          <Divider />
          <Box>
            <Box display="flex" gap="3" alignItems="center">
              <Form.Item
                noStyle
                valuePropName="checked"
                name={RewardType.LOYALTY_ENROLLMENT_REFERRAL + '_active'}
              >
                <Switch />
              </Form.Item>
              <Text variant="CONTENT_2" color="text_3">
                Referral
              </Text>
            </Box>
            {referralWatch && (
              <Box>
                <Form.Item
                  label="Loyalty Reward Each Referral"
                  name={RewardType.LOYALTY_ENROLLMENT_REFERRAL + '_amount'}
                >
                  <Input />
                </Form.Item>
              </Box>
            )}
          </Box>

          <Divider />
          <Box>
            <Box display="flex" gap="3" alignItems="center">
              <Form.Item
                noStyle
                valuePropName="checked"
                name={RewardType.LOYALTY_REDEMPTION + '_active'}
              >
                <Switch />
              </Form.Item>
              <Text variant="CONTENT_2" color="text_3">
                Redeem
              </Text>
            </Box>
            {redeemWatch && (
              <Box>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Point"
                      name={RewardType.LOYALTY_REDEMPTION + '_point'}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Dollar"
                      name={RewardType.LOYALTY_REDEMPTION + '_minBill'}
                    >
                      {/* <Input /> */}
                      <CurrencyInputPrecision size="small" />
                    </Form.Item>
                  </Col>
                </Row>
              </Box>
            )}
          </Box>
        </Box>
        <Box display="flex" gap="3" mt="4" justifyContent="end">
          <Button width="10rem" ntype="SECONDARY" htmlType="reset">
            Reset
          </Button>
          <Button width="10rem" ntype="PRIMARY" htmlType="submit">
            Save
          </Button>
        </Box>
      </Form>
    </LoyaltyRewardsStyled>
  );
};

export default LoyaltyRewards;
const LoyaltyRewardsStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
`;
