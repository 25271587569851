import { ICouponPrintData } from 'services/shop/types/coupon';
import CouponGiftCardUI from './CouponUI';
type Props = {
  data: ICouponPrintData['GIFT_CARD']
};
const CouponGiftCard = ({ data }: Props) => {
  return (
    <>{data.map((o, i) => <CouponGiftCardUI data={o} key={i} />)}</>
  );
};

export default CouponGiftCard;
