import React from 'react';
import AptStatusFilter from './AptStatusFilter';
import AppointmentDatePicker from './DatePicker';
import SwitchCalendarType from './SwitchCalendarType';
type ICalendarFilterProps = any;
const CalendarFilter: React.FC<ICalendarFilterProps> = () => {
  return (
    <>
      <AptStatusFilter />
      <AppointmentDatePicker />
      <SwitchCalendarType />
    </>
  );
};

export default CalendarFilter;
