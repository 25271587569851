import { Input } from 'antd';
import Icon from 'assets/Icons';
import React from 'react';
import { styled } from 'styled-components';

interface ISearchBox {
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
}
const SearchBox: React.FC<ISearchBox> = ({ onChange }) => {
    return (
        <SearchBoxStyled>
            <Input
                size="small"
                onChange={onChange}
                placeholder="Search"
                suffix={<Icon type="search"></Icon>}
                className="search-staff"
            />
        </SearchBoxStyled>
    );
};

export default SearchBox;
const SearchBoxStyled = styled.div`
  flex:1;
 .search-staff{
    min-width: 20rem;
    width: 100%;
 }
 .ant-input, .ant-input-suffix {
    height: 3.5rem;
 }
`;