
const Close = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Delete">
        <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M26.9097 5.08904C27.5123 5.69158 27.5123 6.66849 26.9097 7.27103L7.27184 26.9089C6.6693 27.5114 5.69239 27.5114 5.08985 26.9089C4.48731 26.3064 4.48731 25.3295 5.08985 24.7269L24.7277 5.08904C25.3303 4.4865 26.3072 4.4865 26.9097 5.08904Z" fill="#767676" />
        <path id="Vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M5.09016 5.08904C5.6927 4.4865 6.66961 4.4865 7.27215 5.08904L26.91 24.7269C27.5126 25.3295 27.5126 26.3064 26.91 26.9089C26.3075 27.5114 25.3306 27.5114 24.728 26.9089L5.09016 7.27103C4.48762 6.66849 4.48762 5.69158 5.09016 5.08904Z" fill="#767676" />
      </g>
    </svg>

  );
};

export default Close;
