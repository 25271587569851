import { Checkbox, Col, Form, Input, InputRef, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Modal from 'components/Modal';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import { IEditCateBodyType } from 'features/settingService/services/types/api';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import {
  ICategoryItemData,
  IServiceItemData,
} from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import AddServiceButton from './AddServiceButton';

interface FormValidationFailure {
  errorFields: {
    name: string[];
    errors: string[];
  }[];
  outOfDate: boolean;
}
type IAddCateButtonProps = {};
const AddCateButton: React.FC<IAddCateButtonProps> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ICategoryItemData>();
  const [open, setOpen] = useState(false);
  const categories = shopSelectors.data.categories();
  const applyFee = Form.useWatch('applyFee', form);
  const supplyFee = Form.useWatch('supplyFee', form);
  const sub_catagories = Form.useWatch('sub_catagories', form);
  const [errorIndex, setErrorIndex] = useState<string[]>([]);
  const inputRef = useRef<InputRef>(null);

  const handleShowModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmitModal = async () => {
    try {
      const values = await form.validateFields();
      const convertBody: IEditCateBodyType = {
        categoryId: values.id,
        cateName: values.categoryName,
        cateType: values.cateType || '',
        supplyFee: values.supplyFee || 0,
        orderNumber: values.orderNumber || 0,
        services: values.services.map((item) => ({
          ...item,
          serviceId: item.id,
          categoryId: item.catId,
          orderNumber: item.orderNumber || 0,
          supplyFee: item.supplyFee || 0,
          addOn: false,
        })),
      };
      dispatch(settingServiceActions.createCategory.fetch(convertBody));
      form.resetFields();
      setOpen(false);
    } catch (errorInfo) {
      const formError: FormValidationFailure =
        errorInfo as FormValidationFailure;
      const errorsFilter = formError?.errorFields?.filter(
        (item) => item.name[0] === 'new_services'
      );
      const errorsIndex = errorsFilter.map((item) => item.name[1]);
      const finalErrorIndex = errorsIndex.filter(
        (num, index, self) => self.indexOf(num) === index
      );
      setErrorIndex(finalErrorIndex);
    }
  };

  const onFinish = () => undefined;

  const focusInput = debounce(() => {
    inputRef.current?.focus();
  }, 100);

  const clearErrorByIndex = (index: string) => {
    const newErrors = errorIndex.filter((item) => item !== index);
    setErrorIndex(newErrors);
  };

  useEffect(() => {
    if (open && inputRef.current) {
      focusInput();
    }
  }, [open, inputRef]);

  useEffect(() => {
    if (sub_catagories < 5 && sub_catagories > 0) {
      const newService: IServiceItemData[] = Array.from({
        length: sub_catagories,
      }).map((_item, index) => ({
        orderNumber: index + 1,
        id: '',
        catId: '',
        serviceName: '',
        duration: 0,
        priceSell: 0,
        priceCost: 0,
        commission: 0,
        supplyFee: supplyFee || 0,
      }));

      form.setFieldsValue({ services: newService });
    } else {
      form.setFieldsValue({ services: [] });
    }
  }, [sub_catagories]);

  useEffect(() => {
    const currentServices: IServiceItemData[] = form.getFieldValue('services');
    if (currentServices) {
      const newServices = currentServices.map((item) => ({
        ...item,
        supplyFee: supplyFee,
      }));
      form.setFieldsValue({ services: newServices });
    }
  }, [supplyFee]);

  useEffect(() => {
    form.setFieldsValue({ orderNumber: categories.length + 1 });
  }, []);

  return (
    <AddCateButtonStyled>
      <ButtonBox onClick={handleShowModal}>
        <div className="wrapper">
          <div className="icon-opacity">
            <Icon type="addPlus"></Icon>
          </div>
          <Text color="text_2" variant="CONTENT_2">
            Add New Catagory
          </Text>
        </div>
      </ButtonBox>
      <Modal
        width={'62rem'}
        modalTitle="ADD NEW CATAGORY"
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        destroyOnClose={true}
        okTitle="Save"
      >
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={{
            cateType: 'SERVICE',
            orderNumber: categories.length + 1,
          }}
        >
          <Form.Item name="cateType" hidden>
            <Input />
          </Form.Item>
          <Box p="4">
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item
                  label="Ordinal Number"
                  name="orderNumber"
                  rules={[{ required: true, message: 'Missing orderNumber' }]}
                >
                  <Input type={'number'} />
                </Form.Item>
              </Col>
              <ColCenter span={12}>
                <Box className="center" width={'100%'}>
                  <Box width={'100%'}>
                    <FormItemNoMargin name="applyFee" valuePropName="checked">
                      <Checkbox>Apply Supply Fee</Checkbox>
                    </FormItemNoMargin>
                  </Box>
                  <Box width={'100%'}>
                    {applyFee && (
                      <FormItemNoMargin
                        name="supplyFee"
                        rules={[
                          { required: true, message: 'Missing supplyFee' },
                        ]}
                      >
                        <CurrencyInputPrecision />
                      </FormItemNoMargin>
                    )}
                  </Box>
                </Box>
              </ColCenter>
              <Col span={12}>
                <Form.Item
                  label="Category Name"
                  name="categoryName"
                  rules={[{ required: true, message: 'Missing categoryName' }]}
                >
                  <Input placeholder="Waxing" ref={inputRef} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Sub-Catagories"
                  name="sub_catagories"
                  rules={[
                    { required: true, message: 'Please enter a number!' },
                    () => ({
                      validator(_, value) {
                        if (!value || value <= 4) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error('The maximum value is 4')
                        );
                      },
                    }),
                  ]}
                >
                  <Input type={'number'} placeholder="0" />
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="services">
              {(fields) => {
                return (
                  <Row gutter={[12, 12]}>
                    {fields.map(({ key, name, ...restField }, index) => {
                      const isHaveError = errorIndex.some(
                        (item) => Number(item) === index
                      );
                      return (
                        <Col span={6} key={key}>
                          <AddServiceButton
                            nameField="services"
                            name={name}
                            restField={restField}
                            index={index}
                            clearErrorByIndex={clearErrorByIndex}
                          />
                          {isHaveError && (
                            <Text variant="CAPTION_1" color="error_4">
                              Check Validate
                            </Text>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                );
              }}
            </Form.List>
          </Box>
        </Form>
      </Modal>
    </AddCateButtonStyled>
  );
};

export default AddCateButton;
const AddCateButtonStyled = styled.div``;
const ColCenter = styled(Col)`
  display: flex;
  align-items: center;
`;

const ButtonBox = styled.div`
  cursor: pointer;
  border-radius: 5px;
  padding: 4px;
  border: 1px solid var(--line-line-3, #86909c);
  background: #e5e6eb;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:hover {
    background: var(--fill-fill-2, #adadad);
  }
  .wrapper {
    height: calc(4rem - 10px);
    text-align: center;
    padding: 1rem;
    @media only screen and (max-width: 1400px) {
      padding: 1.25rem;
    }
    border-radius: 2px;
    border: 1px dashed var(--line-line-3, #86909c);
    .icon-opacity {
      opacity: 0.5;
    }
  }
`;
const FormItemNoMargin = styled(Form.Item)`
  margin: 0 !important;
`;
