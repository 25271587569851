const OutlineAddStaff = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9117 6.02339C23.2372 6.01609 24.3176 7.08467 24.3249 8.41013L24.4746 35.5626C24.4819 36.8881 23.4133 37.9685 22.0878 37.9758C20.7624 37.9831 19.682 36.9145 19.6747 35.5891L19.525 8.43659C19.5177 7.11112 20.5863 6.0307 21.9117 6.02339Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9764 22C37.9765 23.3255 36.9019 24.4 35.5764 24.4H8.42355C7.09807 24.4 6.02355 23.3255 6.02355 22C6.02355 20.6745 7.09807 19.6 8.42355 19.6H35.5765C36.9019 19.6 37.9765 20.6745 37.9764 22Z"
        fill="white"
      />
    </svg>
  );
};

export default OutlineAddStaff;
