import { AutoComplete, Col, Row, Select } from 'antd';
import CurrencyInput, { CurrencyInputRef, unmaskedCurrency } from 'components/CurrencyInputMasked';
import { toCurrency } from 'components/CurrencyInputMasked/currency-conversion.js';
import Form, { FormItem } from 'components/Form';
import PercentInputMasked, { maskPercent, unmaskedPercent } from 'components/PercentInputMasked';
import Text from 'components/Text';
import { forwardRef, memo, useImperativeHandle, useRef, useState, useTransition } from 'react';
import { styled } from 'styled-components';
import { isMobileBrowser } from 'utils/detachBehavior';
import InputKeyboardView, { InputViewProps } from '../../../../../components/NumberKeyBoard/InputKeyboardView';
import NumberPad from '../../../../../components/NumberKeyBoard/NumberPad';
import PercentInput from '../PercentInput';
const reasons = [
  { value: 'Unhappy' },
  { value: 'Dislike the services' },
  { value: 'The color unmatched' },
];
const isMobile = isMobileBrowser();

export enum DISCOUNT_VALUE_TYPE {
  PERCENT = 'PERCENT',
  DOLLAR = 'DOLLAR',
}
export interface IAmountDiscountKeyboard {
  value?: number | null;
  onChange?: (value: IAmountDiscountKeyboard['value']) => void;
  disableInput?: boolean;
  inputViewProps?: InputViewProps;
  inputClassName?: string;
  ownerPercent: number;
  staffPercent: number;
  setOwnerPercent: (val: number) => void;
  setStaffPercent: (val: number) => void;
  discountValueType: DISCOUNT_VALUE_TYPE;
  setDiscountValueType: (val: DISCOUNT_VALUE_TYPE) => void;
  isItemDiscount?: boolean;
  textNote: string;
  setTextNote: (val: string) => void;
}

export type AmountDiscountKeyboardRef = {
  showError: (text: string) => void,
  hideError: () => void,
  clear: () => void,
  setValue: (val: string) => void,
};
const inputPlaceholder = '$0.00';
const AmountDiscountKeyboard = forwardRef<AmountDiscountKeyboardRef, IAmountDiscountKeyboard>(({
  value,
  onChange = () => undefined,
  inputViewProps = {},
  inputClassName = '',
  staffPercent,
  ownerPercent,
  setOwnerPercent,
  setStaffPercent,
  discountValueType,
  setDiscountValueType,
  isItemDiscount,
  setTextNote,
}, ref) => {
  const inputRef = useRef<CurrencyInputRef>(null);
  const [text, setText] = useState<string>();
  const [error, setError] = useState<string | undefined>();
  const [, startTransition] = useTransition();

  const handleSetStaffPercent = (val: number) => {
    startTransition(() => {
      setStaffPercent(val);
      const owner = 100 - val;
      setOwnerPercent(owner > 0 ? owner : 0);
    });
  };

  const handleSetOwnerPercent = (val: number) => {
    startTransition(() => {
      setOwnerPercent(val);
      const staff = 100 - val;
      setStaffPercent(staff > 0 ? staff : 0);
    });
  };

  const onWebInputChange = (val: number | null | undefined, format: string) => {
    setText(format);
    onChange(val);
  };

  const onNumberPadPriceChange = (val: string) => {
    if (isMobile) {
      const _val = toCurrency(val);
      setText(_val);
      onChange(unmaskedCurrency(_val));
      return;
    }
    inputRef.current?.setValue(val);
  };

  const onWebChangePercent = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(unmaskedPercent(e.target.value));
    setText(e.target.value);
  };

  const onNumberPadPercentChange = (val: string) => {
    const percent = maskPercent(val);
    onChange(unmaskedPercent(percent));
    setText(percent);
  };

  useImperativeHandle(ref, () => ({
    showError: (text: string) => setError(text),
    hideError: () => setError(''),
    clear: () => onNumberPadPriceChange(''),
    setValue: (_text: string) => onNumberPadPriceChange(_text),
  }));

  return (
    <AmountDiscountKeyboardStyled>
      <Form>
        <Row wrap={false} gutter={[24, 24]}>
          <Col flex={'auto'}>
            <FormItem label={'Discount'}>
              <InputWrapStyled>
                <div className='input-flex'>
                  {isMobile ? (
                    <InputKeyboardView
                      className={`InputKeyboardView ${inputClassName ?? ''}`}
                      value={text}
                      {...inputViewProps}
                      type={'CURRENCY'}
                      placeholder={inputPlaceholder}
                    />
                  ) : (
                    discountValueType === DISCOUNT_VALUE_TYPE.PERCENT ?
                      <PercentInputMasked
                        value={text}
                        onChange={onWebChangePercent}
                      />
                      :
                      <CurrencyInput
                        ref={inputRef}
                        defaultValue={value}
                        onChange={onWebInputChange}
                      />
                  )}
                </div>
                <SelectStyled
                  value={discountValueType}
                  onChange={(e: any) => setDiscountValueType(e as DISCOUNT_VALUE_TYPE)}
                  defaultValue={DISCOUNT_VALUE_TYPE.PERCENT}
                  suffixIcon={<ArrowDown />}
                  style={{ width: '104px' }}
                >
                  <Select.Option value={DISCOUNT_VALUE_TYPE.PERCENT}>Percent</Select.Option>
                  <Select.Option value={DISCOUNT_VALUE_TYPE.DOLLAR}>Dollar</Select.Option>
                </SelectStyled>
              </InputWrapStyled>
            </FormItem>
          </Col>
          <Col span={10}>
            <Row wrap={false} gutter={[24, 24]}>
              <Col>
                <PercentInput label='Staff' value={staffPercent} onChange={handleSetStaffPercent} />
              </Col>
              <Col>
                <PercentInput label='Company' value={ownerPercent} onChange={handleSetOwnerPercent} />
              </Col>
            </Row>
          </Col>
        </Row>
        {isItemDiscount && <Row>
          <Col span={24}>
            <FormItem label='Reason'>
              <AutoComplete
                options={reasons}
                filterOption={(inputValue, option) =>
                  option?.value?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
                }
                onChange={val => setTextNote(val)}
                placeholder='Select'
              />
            </FormItem>
          </Col>
        </Row>}
      </Form>
      {error && <Text textAlign='center' mt={0.5} style={{ color: '#f53f3f' }}>{error}</Text>}
      <Text mb={1} />
      {discountValueType === DISCOUNT_VALUE_TYPE.PERCENT ?
        <NumberPad
          value={text?.replace('%', '')}
          onChange={onNumberPadPercentChange}
          type={'NUMBER'}
        />
        :
        <NumberPad
          value={text?.toString()}
          onChange={onNumberPadPriceChange}
          type={'NUMBER'}
        />}
    </AmountDiscountKeyboardStyled >
  );
});
AmountDiscountKeyboard.displayName = 'AmountDiscountKeyboard';
export default memo(AmountDiscountKeyboard);

const SelectStyled = styled(Select)`
  background: var(--fill-fill-5, #E5E6EB);
  .ant-select-selector {
    .ant-select-selection-search-input {
      color: var(--text-text-2, #767676);
      text-align: center;
      font-family: Open Sans;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;


const AmountDiscountKeyboardStyled = styled.div`
  display: flex;
  flex-direction: column;
  .ant-form-item {
    margin-bottom: 1rem;
  }
`;
const InputWrapStyled = styled.div`
  display: flex;
  width: 100%;
  height: calc(3.5rem - 2px);
  border-radius: 2px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-0, #FFF);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .InputKeyboardView {
    border:none;
    box-shadow: none;
    background: transparent;
  }
  .ant-input-number-group-wrapper {
    width: 100%;
  }
  .ant-select {
    height: unset !important;
  }
  .input-flex{
    flex:1;
    flex-grow:1;
    display: flex;
  }
  input {
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 0 1rem;
    border:none;
    outline: none;
    text-align: left;
    &:focus, &:hover, &:focus-visible {
      border:none;
      outline: none;
    }
  }
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
      border: none;
    }
  }
  .ant-input-group-addon {
    overflow: hidden;
  }
  .ant-select {
    width: 100%;
    border: none;
    box-shadow: none;
    background: transparent;
    .ant-select-selection-item {
      text-align: center;
      display: flex;
      flex: 1;
      color: var(--text-text-2, #767676) !important;
      text-align: center;
      font-family: Open Sans;
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    &.ant-select-focused,
    &.ant-select-open {
      border: none;
      box-shadow: none;
      .ant-select-selector {
        border: none!important;
        box-shadow: none!important;
      } 
    }
    .ant-select-selector {
      border: none!important;
      border-radius: 0;
      border-left: 1px solid var(--line-line-1, #DADADA);
      background: var(--fill-fill-5, #E5E6EB);
    }
  }
`;

const ArrowDown = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.49292 9.42871L4.90713 8.0145L11.9782 15.0856L19.0493 8.0145L20.4635 9.42871L12.6854 17.2068C12.6854 17.2068 12.6853 17.2069 11.9782 16.4998L12.6854 17.2068L11.9782 17.914L3.49292 9.42871Z" fill="#8E9BA9" />
  </svg>
);