import PrivateLayout from 'components/layout/PrivateLayout';
import useRefreshToken from 'hooks/useRefreshToken';
import { Route, Routes } from 'react-router-dom';
import NotFoundPage from './NotFound';
// import CheckInInputPage from './checkIn/pages/CheckInInputPage';
import useInitSocket, { SocketContext } from 'hooks/useSocket';
import RefreshPage from './RefreshPage';
import ShopWrapperLayout from './ShopWrapperLayout';
import AppointmentDetail from './appointment/pages/AppointmentDetail';
import AppointmentsPage from './appointment/pages/AppointmentsPage';
import LoginPage from './auth/pages/Login';
import OwnerChooseDevicePage from './auth/pages/OwnerChooseDevice';
import OwnerDashboard from './auth/pages/OwnerDashboard';
import AddTicketPage from './cashier/pages/AddTicketPage';
import EditTicketPage from './cashier/pages/EditTicketPage';
import CheckInShield from './checkIn/components/CheckInShield';
import CheckInInputPage from './checkIn/pages/CheckInInputPage';
import ChooseCheckInTypePage from './checkIn/pages/ChooseCheckInTypePage';
import CustomerInfoPage from './checkIn/pages/CustomerInfoPage';
import ServiceSelectionPage from './checkIn/pages/ServiceSelectionPage';
import SignUpPage from './checkIn/pages/SignUpPage';
import StaffPage from './checkIn/pages/StaffPage';
import CheckInTicketDetail from './checkIn/pages/TicketDetaiPage';
import CheckInAppointmentPage from './checkIn/pages/TicketDetaiPage/AppointmentPage';
import CustomerDetails from './customer/pages/CustomerDetails';
import EditCustomers from './customer/pages/EditCustomer';
import ListCustomerPage from './customer/pages/ListCustomerPage';
import GiftCards from './giftCards/pages';
import GiftCardDetail from './giftCards/pages/Detail';
import CustomerRatingPage from './payment/pages/CustomerRatingPage';
import CashierSide from './payment/pages/PaymentPage/CashierSide';
import CustomerSide from './payment/pages/PaymentPage/CustomerSide';
import CustomerSideEffectComponents from './payment/pages/PaymentPage/CustomerSide/CustomerSideEffectComponents';
import PrintPage from './payment/pages/PrintPage';
import QRPaymentPage from './payment/pages/QRPaymentPage';
import TipBillPrintPage from './payment/pages/TipPrintPage';
import ReportPage from './report/pages/ReportPage';
import TimeClockReportPrintPage from './report/pages/ReportPage/ClockReport/Print';
import EmployeeSalaryReportDateRangePrintPage from './report/pages/ReportPage/EmployeeSalary/DateRangePrint';
import EmployeeSalaryDetail from './report/pages/ReportPage/EmployeeSalary/Detail';
import EmployeeSalaryStaffDetail from './report/pages/ReportPage/EmployeeSalary/Detail/Staff';
import StaffSalaryDateRangeReportPrintPage from './report/pages/ReportPage/EmployeeSalary/Detail/Staff/DateRangePrint';
import StaffSalaryReportPrintPage from './report/pages/ReportPage/EmployeeSalary/Detail/Staff/Print';
import EmployeeSalaryReportPrintPage from './report/pages/ReportPage/EmployeeSalary/Print';
import ReportPrintPageV2 from './report/pages/ReportPage/SalonReport/PrintV2';
import ServicesReportPrintPage from './report/pages/ReportPage/ServicesReport/Print';
import SettingServicePage from './settingService/pages/SettingPage';
import AddStaff from './settingStaff/pages/AddStaff';
import SettingStaffList from './settingStaff/pages/SettingStaffList';
import SettingStaffPage from './settingStaff/pages/SettingStaffPage';
import StoryboardPage from './storyboard';
import ListTicketPage from './ticketList/pages/ListTicketPage';
import CreditCardPage from './ticketList/pages/ListTicketPage/CreditCardPage';
import TicketDetailsPage from './ticketList/pages/TicketDetailsPage';
import TurnPage from './turn/pages';
import ClockInClockOutPage from './user/pages/clockInClockOut';

const RootScreens = () => {
  const { shield } = useRefreshToken();
  const socketContext = useInitSocket();

  return (
    <SocketContext.Provider value={socketContext}>
      <>
        <Routes>
          <Route path="refresh" element={<RefreshPage />} />
          <Route path="store/:shop_id" element={shield(ShopWrapperLayout)}>
            <Route path="" element={<ClockInClockOutPage />} />
            <Route path="ticket">
              <Route path="edit/:id" element={<EditTicketPage />} />
              <Route path="add-new" element={<AddTicketPage />} />

              <Route path="payment">
                <Route path="cashier-side/:id" element={<CashierSide />} />
                <Route path="customer-side/:id" element={<CustomerSide />} />
                <Route
                  path="customer-side/rating/:ticket_id"
                  element={<CustomerRatingPage />}
                />
              </Route>
              <Route path="qr-payment/:type/:id" element={<QRPaymentPage />} />
              <Route
                path="qr-payment/customer-side/:type/:id"
                element={<QRPaymentPage isCustomer />}
              />
              <Route path="pay/:id/completed" element={<PrintPage />} />
            </Route>

            <Route path="print/tip-bill" element={<TipBillPrintPage />} />

            <Route path="check-in">
              <Route path="sign-in" element={<CheckInInputPage />} />
              <Route path="sign-up" element={<SignUpPage />} />
              <Route path="" element={CheckInShield(PrivateLayout)}>
                <Route path="customer-info" element={<CustomerInfoPage />} />
                <Route
                  path="choose-check-in-type"
                  element={<ChooseCheckInTypePage />}
                />
                <Route
                  path="appointments"
                  element={<CheckInAppointmentPage />}
                />
                <Route path="services" element={<ServiceSelectionPage />} />
                <Route path="staff" element={<StaffPage />} />
                <Route path="ticket-detail" element={<CheckInTicketDetail />} />
              </Route>
            </Route>
            <Route path="appointments" element={<AppointmentsPage />} />
            <Route
              path="appointments/:appointment_id"
              element={<AppointmentDetail />}
            />
            <Route path="setting-service">
              <Route path="" element={<SettingServicePage />} />
              <Route path=":tab" element={<SettingServicePage />} />
            </Route>

            <Route path="setting-staff">
              <Route path="" element={<SettingStaffList />} />
              <Route path=":tab" element={<SettingStaffPage />} />
              <Route path="add-new" element={<AddStaff />} />
            </Route>
            <Route path="tickets" element={<ListTicketPage />} />
            <Route path="credit-cards" element={<CreditCardPage />} />
            <Route path="tickets/:id" element={<TicketDetailsPage />} />

            <Route path="customers" element={<ListCustomerPage />} />
            <Route path="customers/:id">
              <Route path="" element={<CustomerDetails />} />
              <Route path="edit" element={<EditCustomers />} />
            </Route>
            <Route path="report" element={<ReportPage />} />
            <Route path="report/:tab" element={<ReportPage />} />
            <Route
              path="report/:tab/staff/:staff_id"
              element={<EmployeeSalaryStaffDetail />}
            />
            <Route
              path="report/:tab/employee/:employee_id"
              element={<EmployeeSalaryDetail />}
            />
            <Route path="report/print">
              <Route path="" element={<ReportPrintPageV2 />} />
              <Route
                path="employee-salary"
                element={<EmployeeSalaryReportPrintPage />}
              />
              <Route
                path="employee-salary-date-range"
                element={<EmployeeSalaryReportDateRangePrintPage />}
              />
              <Route
                path="services-report"
                element={<ServicesReportPrintPage />}
              />
              <Route
                path="staff-salary/:staff_id"
                element={<StaffSalaryReportPrintPage />}
              />
              <Route
                path="staff-salary-date-range/:staff_id"
                element={<StaffSalaryDateRangeReportPrintPage />}
              />
              <Route path="time-clock" element={<TimeClockReportPrintPage />} />
              <Route path="time-clock/:staff_id" element={<TimeClockReportPrintPage />} />
            </Route>
            <Route path="turn" element={<TurnPage />} />
            <Route path="gift-cards" element={<GiftCards />} />
            <Route path="gift-cards/:gift_card_id" element={<GiftCardDetail />} />
          </Route>
          <Route path="/sign-in" element={<LoginPage />} />
          <Route path="/owner/dashboard" element={shield(OwnerDashboard)} />
          <Route
            path="/owner/devices/:shop_id"
            element={shield(OwnerChooseDevicePage)}
          />

          <Route path="/" element={<LoginPage />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/dev/storyboard" element={<StoryboardPage />} />
        </Routes>
        <CustomerSideEffectComponents />
      </>
    </SocketContext.Provider>
  );
};

export default RootScreens;
