
import Button from 'components/Button';
import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import { ICreateCustomerBody } from 'features/cashier/services/types/api';
import { ICustomerFormValues } from 'features/cashier/services/types/customer';
import { useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { PHONE_MASKED } from 'utils/mask';
import FormAddCustomer from './Form';
import Icon from 'assets/Icons';
import { InputRef, Row } from 'antd';
type Props = {
  model?: 'default' | 'new-ticket';
};
const ButtonAddCustomer = ({
  model = 'default'
}: Props) => {
  const inputRef = useRef<InputRef>(null);
  const [visible, setVisible] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const onOpen = () => {
    setTimeout(() => inputRef.current?.focus(), 10);
    setVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };
  const valid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  const onSubmit = async () => {
    if (!await valid()) return;
    form.submit();
  };

  const handleFinish = async (values: ICustomerFormValues) => {
    onClose();
    PHONE_MASKED.resolve(values?.phone);
    const phone = PHONE_MASKED.unmaskedValue;
    const payload: ICreateCustomerBody = {
      phone: phone || null,
      name: values.name || null,
      email: values.email || null,
      birthday: values.birthday || null,
    };
    dispatch(cashierActions.addNewCustomer.fetch(payload));
  };

  return (
    <>
      {model === 'new-ticket' ? <Button
        onClick={onOpen}
        icon='addPlus'
        ntype='PRIMARY'
      /> : <button onClick={onOpen}>
        <Row align={'middle'}>
          <Icon type='addNewPlus' />
          <Text color="info_3" variant="CONTENT_2">
            Add New Customer
          </Text>
        </Row>
      </button>}
      <Modal
        visible={visible}
        modalTitle='ADD NEW CUSTOMER'
        onClose={onClose}
        onSubmit={onSubmit}
        okTitle='Done'
      >
        <Form
          form={form}
          onFinish={handleFinish}
        >
          <FormAddCustomer inputRef={inputRef} />
        </Form>
      </Modal>
    </>
  );
};

export default ButtonAddCustomer;
