import { ISagaFunc } from 'services/actionConfigs';
import {
  Bill,
  IAdjustTip,
  IApiGetTicketsParam,
  IEditStaffByBillId,
  IEditStaffPayload,
  IRefundBody,
  IResponseTicket,
} from './types/api';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import actions from './actions';
import { AxiosResponse } from 'axios';
import uiActions from 'services/UI/actions';
import { PATH_LOADING } from './constants';
import { getStoreAdjustTips, getTicketsFilterParams } from './selectors';
import shopActions from 'services/shop/actions';

const getTicketsData: ISagaFunc<IApiGetTicketsParam> = function* ({ payload }) {
  yield put(
    uiActions.setLoading({ path: PATH_LOADING.getTicketsData, result: true })
  );
  yield delay(200);
  const params: IApiGetTicketsParam = payload;
  try {
    const resData: AxiosResponse<{ data: IResponseTicket }> = yield call(
      apis.getTicketsData,
      params
    );
    if (resData?.data?.data) {
      yield put(actions.getTickets.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTickets.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({ path: PATH_LOADING.getTicketsData, result: false })
    );
  }
};

const setTicketsParams: ISagaFunc<IApiGetTicketsParam> = function* ({
  payload,
}) {
  const storeParams = yield select(getTicketsFilterParams);
  const _params = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };
  yield put(actions.getTickets.fetch(_params));
};

const getTicketDetailsData: ISagaFunc<string> = function* ({ payload }) {
  yield put(
    uiActions.setLoading({
      path: PATH_LOADING.getTicketDetailsData,
      result: true,
    })
  );
  yield delay(200);
  const id: string = payload;
  try {
    const resData: AxiosResponse<{ data: Bill }> = yield call(
      apis.getTicketDetailsData,
      id
    );
    if (resData?.data?.data) {
      yield put(actions.getTicketDetails.success(resData.data.data));
      yield put(shopActions.get.staffs.fetch());
    }
  } catch (error) {
    yield put(actions.getTicketDetails.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({
        path: PATH_LOADING.getTicketDetailsData,
        result: false,
      })
    );
  }
};
const editStaffByBillId: ISagaFunc<IEditStaffPayload> = function* ({
  payload,
}) {
  yield put(
    uiActions.setLoading({
      path: PATH_LOADING.getTicketDetailsData,
      result: true,
    })
  );
  yield delay(200);
  try {
    const body: IEditStaffByBillId = {
      billId: payload.billId,
      staffIdNew: payload.newStaff.id,
      staffIdOld: payload.existStaffId,
      pinCode: payload.pinCode,
    };
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.changeStaffByBillId,
      body
    );
    if (resData?.data?.data) {
      yield put(actions.editStaff.success(payload));
    }
  } catch (error) {
    yield put(actions.editStaff.fail({}));
  } finally {
    yield put(
      uiActions.setLoading({
        path: PATH_LOADING.getTicketDetailsData,
        result: false,
      })
    );
  }
};

const refundTicketByBillId: ISagaFunc<IRefundBody> = function* ({ payload }) {
  yield put(yield put(uiActions.setLoadingPage(true)));
  yield delay(200);

  try {
    if (payload) {
      const resData: AxiosResponse<{ data: any }> = yield call(
        apis.refundByBillId,
        payload
      );
      if (resData?.data?.data) {
        yield put(actions.refundTicketByBillId.success(resData.data.data));
        yield put(actions.getTicketDetails.fetch(payload.billId));
      }
    }
  } catch (error) {
    yield put(actions.refundTicketByBillId.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};
const voidTicketByBillId: ISagaFunc<{ id: string; reason: string }> =
  function* ({ payload }) {
    yield put(uiActions.setLoadingPage(true));
    yield delay(200);

    try {
      if (payload.id) {
        const resData: AxiosResponse<{ data: any }> = yield call(
          apis.voidByBillId,
          payload
        );
        if (resData?.data?.data) {
          yield put(actions.voidTicketByBillId.success(resData.data.data));
          yield put(actions.getTicketDetails.fetch(payload.id));
          const storeParams = yield select(getTicketsFilterParams);
          yield put(actions.getTickets.fetch(storeParams));
        }
      }
    } catch (error) {
      yield put(actions.voidTicketByBillId.fail({}));
    } finally {
      yield put(uiActions.setLoadingPage(false));
    }
  };
const adjustTip: ISagaFunc<{ billId?: string }> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  yield delay(200);

  try {
    const storeAdjustTipList: IAdjustTip[] = yield select(getStoreAdjustTips);
    if (payload.billId) {
      const body = storeAdjustTipList.filter(
        (item) => item.billId === payload.billId
      );
      const resData: AxiosResponse<{ data: any }> = yield call(
        apis.adjustTip,
        body
      );
      if (resData?.data?.data) {
        const successPayload = storeAdjustTipList.filter(
          (item) => item.billId !== payload.billId
        );
        yield put(actions.adjustTip.success(successPayload));
        const storeParams = yield select(getTicketsFilterParams);
        yield put(actions.getTickets.fetch(storeParams));
      }
    } else {
      const resData: AxiosResponse<{ data: any }> = yield call(
        apis.adjustTip,
        storeAdjustTipList
      );
      if (resData?.data?.data) {
        yield put(actions.adjustTip.success([]));
        const storeParams = yield select(getTicketsFilterParams);
        yield put(actions.getTickets.fetch(storeParams));
      }
    }
  } catch (error) {
    yield put(actions.adjustTip.fail({}));
  } finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const editCashByBillId: ISagaFunc<{ amount: number; billId: string }> =
  function* ({ payload }) {
    yield put(uiActions.setLoadingPage(true));
    yield delay(200);
    try {
      if (payload.billId) {
        const resData: AxiosResponse<{ data: any }> = yield call(
          apis.editCashByBillId,
          payload
        );
        if (resData.data.data) {
          yield put(actions.editCashByBillId.success(payload));
        }
      }
    } catch (error) {
      yield put(actions.editCashByBillId.fail({}));
    } finally {
      yield put(uiActions.setLoadingPage(false));
    }
  };

export default function* ticketListServiceSagas() {
  yield takeLatest(actions.getTickets.fetch, getTicketsData);
  yield takeLatest(actions.getTicketDetails.fetch, getTicketDetailsData);
  yield takeLatest(actions.setTicketsParams, setTicketsParams);
  yield takeLatest(actions.refundTicketByBillId.fetch, refundTicketByBillId);
  yield takeLatest(actions.voidTicketByBillId.fetch, voidTicketByBillId);
  yield takeLatest(actions.editStaff.fetch, editStaffByBillId);
  yield takeLatest(actions.adjustTip.fetch, adjustTip);
  yield takeLatest(actions.editCashByBillId.fetch, editCashByBillId);
}
