import React from 'react';

const AndroidIcon = () => {
  return (
    <svg width="42" height="19" viewBox="0 0 42 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 625349">
        <g id="Group 625345">
          <path id="Vector" d="M3.56112 0.692147C3.64013 0.684246 3.71914 0.677925 3.79815 0.671604C4.02649 1.08167 4.24614 1.49727 4.47053 1.90891C5.60355 1.43169 6.92303 1.42932 8.05762 1.90338C8.2828 1.49095 8.5064 1.07851 8.72921 0.665283C8.80348 0.673184 8.87696 0.685036 8.95044 0.700048C9.01049 0.858859 8.89039 0.998709 8.82481 1.13224C8.666 1.43801 8.46769 1.72561 8.3547 2.05271C9.56514 2.65714 10.4722 3.96003 10.3742 5.34351C7.63254 5.34272 4.89008 5.3443 2.14841 5.34272C2.08836 3.96082 2.96775 2.66505 4.18056 2.0535C4.02491 1.57707 3.61406 1.19703 3.56112 0.692147ZM4.21454 3.15491C3.96723 3.27343 3.94669 3.67006 4.17661 3.81702C4.41365 3.98848 4.78658 3.79411 4.77393 3.49782C4.785 3.21733 4.45947 3.02849 4.21454 3.15491ZM7.94543 3.15965C7.71076 3.28686 7.6839 3.65584 7.90987 3.80596C8.13426 3.99085 8.53485 3.8107 8.51114 3.50888C8.54512 3.21338 8.19273 3.02375 7.94543 3.15965Z" fill="black" />
          <path id="Vector_2" d="M0.647145 5.53135C1.18442 5.35437 1.80623 5.7755 1.82124 6.34437C1.83231 7.63383 1.8244 8.92486 1.82519 10.2143C1.84337 10.7018 1.41197 11.1443 0.924472 11.138C0.431445 11.1577 -0.0197059 10.7168 0.00162703 10.2214C-0.000743293 8.95568 0.000837054 7.68914 0.000837054 6.42338C-0.0173354 6.02359 0.262362 5.6396 0.647145 5.53135Z" fill="black" />
          <path id="Vector_3" d="M11.3248 5.53706C11.8683 5.34506 12.5178 5.77172 12.5162 6.3564C12.5226 7.64427 12.5178 8.93215 12.5186 10.2192C12.5423 10.7131 12.0919 11.1547 11.6029 11.1381C11.1106 11.1476 10.68 10.7004 10.6942 10.2105C10.6895 8.94953 10.6903 7.68694 10.6935 6.42593C10.6856 6.03324 10.9495 5.65636 11.3248 5.53706Z" fill="black" />
          <path id="Vector_4" d="M2.14286 5.65509C4.88848 5.6543 7.63489 5.65351 10.3805 5.65588C10.3797 7.6509 10.3813 9.64513 10.3797 11.6402C10.4003 12.0976 10.0092 12.5338 9.5509 12.5543C9.27042 12.5662 8.98914 12.5551 8.70786 12.5583C8.70233 13.2543 8.70944 13.9504 8.70549 14.6473C8.72445 15.1324 8.28594 15.5694 7.80319 15.5607C7.3386 15.5757 6.90325 15.179 6.88745 14.7121C6.87876 13.9947 6.88824 13.2765 6.8835 12.559C6.4766 12.5575 6.0689 12.5575 5.66121 12.5598C5.65488 13.2828 5.67148 14.0065 5.65409 14.7295C5.63829 15.2849 5.01569 15.6981 4.49343 15.5235C4.11181 15.4184 3.83764 15.0455 3.83527 14.652C3.82895 13.9536 3.83843 13.2559 3.83211 12.5575C3.52397 12.5535 3.21503 12.5717 2.90847 12.5488C2.48023 12.4887 2.13338 12.081 2.14365 11.6481C2.14128 9.65066 2.14444 7.65248 2.14286 5.65509Z" fill="black" />
        </g>
        <g id="Group 625348">
          <g id="#383e41ff">
            <path id="Vector_5" d="M16.0259 1.39992C17.4144 1.39903 18.8038 1.40081 20.1932 1.39992C20.5839 1.40081 20.9773 1.41327 21.3618 1.49159C22.4549 1.70343 23.4179 2.41816 24.0054 3.35452C24.6133 4.34517 24.68 5.6251 24.2626 6.69942C23.693 8.06746 22.3009 9.05099 20.8189 9.12398C19.7793 9.15513 18.7388 9.12843 17.6983 9.13822C17.6965 10.8979 17.6992 12.6585 17.6974 14.4181C17.1402 14.419 16.5821 14.419 16.025 14.4181C16.0259 10.079 16.025 5.73903 16.0259 1.39992ZM17.6992 3.01451C17.6965 4.52497 17.6974 6.03631 17.6983 7.54677C18.5902 7.54855 19.4811 7.54677 20.373 7.54766C20.7157 7.54855 21.0637 7.51294 21.3841 7.38388C22.2724 7.03497 22.8999 6.08794 22.8144 5.12755C22.7468 3.98914 21.701 2.99671 20.5545 3.01718C19.6022 3.01006 18.6507 3.0154 17.6992 3.01451Z" fill="black" />
            <path id="Vector_6" d="M28.1229 5.27183C29.3868 5.11518 30.7985 5.2923 31.7811 6.17081C32.5457 6.83213 32.9009 7.85661 32.9 8.84904C32.908 10.7048 32.9008 12.5615 32.9035 14.4173C32.3721 14.4191 31.8408 14.4191 31.3094 14.4173C31.3041 13.9946 31.3156 13.5709 31.3014 13.1472C30.9187 13.5842 30.5528 14.0622 30.0206 14.3292C28.5617 15.0893 26.5626 14.6879 25.5675 13.3573C24.9863 12.5971 24.8777 11.5406 25.1848 10.6488C25.4358 9.9287 26.0393 9.38219 26.7166 9.06355C27.4812 8.70485 28.3428 8.6194 29.1777 8.65144C29.9111 8.68081 30.6534 8.83035 31.2996 9.18994C31.3067 8.78762 31.3085 8.36662 31.1243 7.99813C30.7148 7.15701 29.7544 6.64522 28.8288 6.72443C27.9983 6.74223 27.2311 7.21753 26.7763 7.90111C26.2849 7.59493 25.7954 7.28607 25.305 6.97899C25.9351 6.02929 26.9943 5.40178 28.1229 5.27183ZM27.0166 10.8615C26.6534 11.3315 26.6463 12.0506 27.0299 12.5117C27.3824 12.9309 27.9209 13.181 28.4665 13.2042C29.9066 13.2976 31.3236 12.0391 31.3076 10.5767C30.6 10.0578 29.6859 9.92781 28.8288 9.98032C28.1461 10.0275 27.4385 10.3016 27.0166 10.8615Z" fill="black" />
            <path id="Vector_7" d="M33.0967 5.50658C33.7037 5.50658 34.3099 5.50391 34.9169 5.50747C35.8078 7.64809 36.6819 9.79584 37.5853 11.9311C38.4549 9.79228 39.313 7.64809 40.179 5.50747C40.786 5.50391 41.3931 5.50658 42.0001 5.50658V5.51281C40.1354 9.78427 38.284 14.062 36.4211 18.3343C35.847 18.3343 35.2729 18.3352 34.6988 18.3343C35.3868 16.8363 36.0829 15.3428 36.7709 13.8457C35.5488 11.0642 34.3188 8.28717 33.0967 5.50658Z" fill="black" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AndroidIcon;
