import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IStaffItemData } from 'services/shop/types/staff';
import { IStaffInfo, IStaffOffDay, IStaffScheduler } from './types/staff';
import { set } from 'lodash';
import moment from 'moment';
import { IStaffDayOffParams, IUpdateStaffAvatar } from './types/api';

const initialState: IState = {
  staffParams: {
    page: 1,
    size: 9,
  },
  totalElement: 0,
  cateSelected: '',
  servicesSelected: [],
  errorMessage: '',
  selectedStaff: null,
  selectedStaffSalary: null,
  scheduler: null,
  staffs: [],
  staffsDisplay: [],
  listParams: {
    position: null,
  },
  staffDaysOff: [],
  staffDayOffParams: {
    staffId: '',
    startTime: moment().startOf('year').format('MM-DD-YYYY'),
    endTime: moment().endOf('year').format('MM-DD-YYYY'),
  },
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.setParams, (state, { payload }) => {
        const _params = {
          ...(state.staffParams ?? {}),
          ...(payload ?? {}),
        };
        state.staffParams = _params;
      })
      .addCase(actions.getStaffList.success, (state, { payload }) => {
        state.staffs = payload.content;
        state.totalElement = payload.totalElements;
      })
      .addCase(actions.getSelectedStaffInfo.success, (state, { payload }) => {
        state.selectedStaff = payload;
        state.staffDayOffParams.staffId = payload?.id;
      })
      .addCase(actions.getSelectedStaffSalary.success, (state, { payload }) => {
        state.selectedStaffSalary = payload;
      })
      .addCase(
        actions.getSelectedStaffServices.success,
        (state, { payload }) => {
          state.servicesSelected = payload;
        }
      )
      .addCase(actions.cateSelected, (state, { payload }) => {
        state.cateSelected = payload;
      })
      .addCase(
        actions.createOrUpdateStaff.success,
        (state, { payload }: PayloadAction<IStaffInfo>) => {
          const index = state.staffs.findIndex(
            (staff) => staff.id === payload.id
          );
          if (index !== -1) {
            set(state, `staffs[${index}]`, payload);
          }
        }
      )
      .addCase(
        actions.staffScheduler.success,
        (state, { payload }: PayloadAction<IStaffScheduler>) => {
          state.scheduler = payload;
        }
      )
      // .addCase(
      //   actions.staffUpdateSalary.success,
      //   (state, { payload }: PayloadAction<ISalaryConfig>) => {
      //     const index = state.staffs.findIndex(
      //       (staff) => staff.id === payload.staffId
      //     );
      //     if (index !== -1) {
      //       set(state, `staffs[${index}]`, payload);
      //     }
      //   }
      // )
      .addCase(actions.clickService, (state, { payload }) => {
        const index = state.servicesSelected.findIndex(
          (item) => item.serviceId === payload.serviceId
        );
        if (index !== -1) {
          state.servicesSelected.splice(index, 1);
        } else {
          state.servicesSelected.push(payload);
        }
      })
      .addCase(
        actions.getStaffDayOff.success,
        (state, { payload }: PayloadAction<IStaffOffDay[]>) => {
          state.staffDaysOff = payload;
        }
      )
      .addCase(
        actions.setStaffDayOffParams,
        (state, { payload }: PayloadAction<IStaffDayOffParams>) => {
          const _params = {
            ...(state.staffDayOffParams ?? {}),
            ...(payload ?? {}),
          };
          state.staffDayOffParams = _params;
        }
      )
      .addCase(
        actions.updateStaffAvatar.success,
        (state, { payload }: PayloadAction<IUpdateStaffAvatar>) => {
          if (state.selectedStaff) {
            state.selectedStaff.urlImage = payload.avatar;
            state.staffs = state.staffs.map((item) => {
              if (item.id === payload.staffId) {
                return { ...item, urlImage: payload.avatar };
              }
              return item;
            });
          }
        }
      )

      .addCase(
        actions.searchStaff,
        (state, { payload }: PayloadAction<IStaffItemData[]>) => {
          state.staffsDisplay = payload;
        }
      )
      .addCase(actions.doInit, (state) => {
        state.staffParams = {
          page: 1,
          size: 9,
        };
        state.totalElement = 0;
        state.cateSelected = '';
        state.servicesSelected = [];
        state.errorMessage = '';
        state.selectedStaff = null;
        state.selectedStaffSalary = null;
        state.scheduler = null;
        state.staffs = [];
        state.staffsDisplay = [];
        state.listParams = {
          position: null,
        };
        state.staffDaysOff = [];
        state.staffDayOffParams = {
          staffId: '',
          startTime: moment().startOf('year').format('MM-DD-YYYY'),
          endTime: moment().endOf('year').format('MM-DD-YYYY'),
        };
      })
      ;
  },
});

const settingStaffReducer = Slice.reducer;
export default settingStaffReducer;
