import { Row } from 'antd';
import styled from 'styled-components';

export const PageStyled = styled.div`
    min-height: 100vh;
    background: var(--fill-fill-3, #E5E5E5);
    display: flex;
    flex-direction: column;
`;

export const HeadStyled = styled(Row)`
    padding: 2rem;
    padding-bottom: 0;
    .box-ticket-info {
        .ticket-number {
            border-radius: 5px;
            background: var(--fill-fill-2, #ADADAD);
            display: flex;
            width: 134.638px;
            padding: 0px 4px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: var(--text-text-1, #FFF);
            font-family: Open Sans;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        display: flex;
        align-items: flex-end;

        .customer-name {
            font-size: 20px;
        }
        .create-date {
            font-size: 16px;
        }
    }
`;

export const HeadActions = styled(Row)`
    gap: 24px;
`;

export const PaymentFormStyled = styled.div`
    display: flex; 
    justify-content: center;
    align-items: start;
    flex-grow:1;
    padding: 2rem;
`;
