import { Avatar } from 'antd';
import Empty from 'components/Empty';
import Text from 'components/Text';
import { get } from 'lodash';
import React from 'react';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import styled from 'styled-components';

interface ISearchResult {
  className?: string;
  listStaff?: IStaffAvailableItem[];
  chooseStaff?: (item: IStaffAvailableItem) => void;
}

const SearchResult: React.FC<ISearchResult> = ({
  listStaff = [],
  chooseStaff = () => undefined,
}) => {
  if (listStaff.length > 0) {
    return <SearchResultStyled>{
      listStaff.map((item) => {
        return (
          <button
            onClick={(e) => {
              e.stopPropagation();
              chooseStaff(item);
            }}
            key={item.staffId}
            className="staff-search-item"
          >
            <Avatar className="circle-avatar" src={item.staffAvatar} alt="staff-avatar" >{get(item.staffName, [0], '')}</Avatar>
            <Text variant="CONTENT_1">{item.staffName}</Text>
          </button>
        );
      })
    }</SearchResultStyled>;
  }

  return <SearchResultStyled><Empty description='No staff match with search condition!' /></SearchResultStyled>;
};

interface ISearchResultStyled { }
const SearchResultStyled = styled.div<ISearchResultStyled>`
    border-radius: 5px;
    border: 1px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-0, #FFF);
    box-shadow: 0px 0px 20px 0px rgba(88, 104, 233, 0.10);
    max-height: 42vh;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 628px;
    width: 40vw;
    z-index: 10;
    .staff-search-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap:0.5rem;
      padding: 0.5rem 1rem;
      background-color: #ffffff;
      &:hover {
        background-color: #E5E6EB;
      }

      .circle-avatar {
        border-radius: 50%;
      }
    }
    .ant-empty {
      padding: 1rem;
    }
`;

export default SearchResult;
