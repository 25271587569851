import { Input, InputProps } from 'antd';
import React from 'react';
import { styled } from 'styled-components';
import { PHONE_MASKED } from 'utils/mask';

interface InputPhoneProps extends InputProps { }
const InputPhone = ({ ...props }: InputPhoneProps) => {
  return (
    <Styled
      {...props}
      onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
        const input = event.target as HTMLInputElement;
        input.value = PHONE_MASKED.resolve(input.value);
      }}
      maxLength={14}
    />
  );
};

export default InputPhone;

const Styled = styled(Input) <InputPhoneProps>``;
