import { AutoComplete, Avatar, Col, Input, InputRef, Row } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import Text from 'components/Text';
import { get } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';

type Props = {
  onPickStaff: (option: IStaffItemData) => void;
};
const StaffSearchInput = ({ onPickStaff }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const staffList = shopSelectors.data.staffs();

  const initialOptions = useMemo(() => {
    return staffList.map(o => {
      const staffName = o.firstName + ' ' + o.lastName;
      return ({
        value: o.id,
        name: staffName,
        keywords: [staffName?.toLocaleLowerCase()],
        label: <Row align={'middle'} gutter={[8, 8]}>
          <Col><Avatar src={o.urlImage}>{get(staffName, [0], '')}</Avatar></Col>
          <Col><Text>{staffName}</Text></Col>
        </Row>,
      });
    });
  }, [staffList]);

  const [searchText, setSearchText] = useState<string>('');

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return initialOptions.filter((item) => item.keywords.some(kw => kw?.includes(searchText?.toLocaleLowerCase())));
  }, [initialOptions, searchText]);

  const handleSwitchCustomer = (data: string) => {
    setSearchText('');
    inputRef.current?.blur();
    const result = staffList?.find((item) => item.id === data);
    if (!result) return;
    onPickStaff(result);
  };


  return (
    <AutoComplete
      style={{ width: '100%' }}
      options={options}
      onSelect={handleSwitchCustomer}
      value={searchText}
      onChange={setSearchText}
    >
      <Input
        ref={inputRef}
        suffix={<SearchIcon />}
        placeholder='Staff name'
      />
    </AutoComplete>
  );
};

export default StaffSearchInput;
