import { toast as KitToast } from 'react-toastify';

const IconError = () => <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" >
  <path fillRule="evenodd" clipRule="evenodd" d="M1.33325 8.00016C1.33325 4.31826 4.31802 1.3335 7.99992 1.3335C11.6818 1.3335 14.6666 4.31826 14.6666 8.00016C14.6666 11.6821 11.6818 14.6668 7.99992 14.6668C4.31802 14.6668 1.33325 11.6821 1.33325 8.00016ZM5.40937 6.35252L7.05929 8.00243L5.40937 9.65235L6.35218 10.5952L8.0021 8.94524L9.65201 10.5952L10.5948 9.65235L8.94491 8.00243L10.5948 6.35252L9.65201 5.40971L8.0021 7.05962L6.35218 5.40971L5.40937 6.35252Z" fill="#FF8890" />
</svg>;

const error = (msg: string) => {
  KitToast.error(msg, {
    icon: <IconError />,
    style: {
      color: 'var(--text- text - 3, #1D2129)',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      borderRadius: '2px',
      border: '1px solid var(--primary-primary-hover, #F5767F)',
      background: 'var(--primary-button, #FFE5E7)',
      boxShadow: '-1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    autoClose: 3000,
  });
};
const IconSuccess = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.33325 8.00016C1.33325 4.31826 4.31802 1.3335 7.99992 1.3335C11.6818 1.3335 14.6666 4.31826 14.6666 8.00016C14.6666 11.6821 11.6818 14.6668 7.99992 14.6668C4.31802 14.6668 1.33325 11.6821 1.33325 8.00016ZM7.33326 10.6096L11.638 6.30491L10.6952 5.3621L7.33326 8.72403L5.47133 6.8621L4.52852 7.80491L7.33326 10.6096Z" fill="#00B42A" />
  </svg>
);

const success = (msg: string) => {
  KitToast.success(msg, {
    icon: <IconSuccess />,
    style: {
      borderRadius: '2px',
      border: '1px solid #00B42A',
      background: 'var(--success-success-1, #E8FFEA)',
      boxShadow: '-1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    },
    autoClose: 3000,
  });
};
const IconInfo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M1.33325 8.00016C1.33325 4.31826 4.31802 1.3335 7.99992 1.3335C11.6818 1.3335 14.6666 4.31826 14.6666 8.00016C14.6666 11.6821 11.6818 14.6668 7.99992 14.6668C4.31802 14.6668 1.33325 11.6821 1.33325 8.00016ZM8.66659 6.00016V4.66683H7.33325V6.00016H8.66659ZM7.33325 6.66683V11.3335H8.66659V6.66683H7.33325Z" fill="#6FABB6" />
  </svg>
);

const info = (msg: string) => {
  KitToast.info(msg, {
    icon: <IconInfo />,
    style: {
      borderRadius: '2px',
      border: '1px solid var(--info-infor-3, #6FABB6)',
      background: 'var(--blue-headline, #C6E9EF)',
      boxShadow: '-1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    }
  });
};

const toast = {
  error,
  success,
  info,
};

export default toast;
