const SearchIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75ZM0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4875 15.9547C15.78 15.6615 16.2549 15.6608 16.5482 15.9534L20.0722 19.4682C20.3655 19.7607 20.3661 20.2356 20.0736 20.5289C19.7811 20.8222 19.3062 20.8228 19.0129 20.5303L15.4889 17.0154C15.1956 16.7229 15.195 16.248 15.4875 15.9547Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default SearchIcon;
