import { IGiftCardPaymentValue } from 'features/cashier/services/types/giftCard';
import { useEffect, useRef } from 'react';
import { useAppDispatch } from 'store/hooks';
import ModalPaymentGiftCard, { ModalPaymentGiftCardRefs } from './ModalPaymentGiftCard';
import CardBox from 'features/payment/components/CardBox';
import multiplePaymentSelectors from '../../../services/selectors';
import multiplePaymentActions from '../../../services/actions';
import { IStateDetailPayment } from 'features/payment/services/types/payment';

type IGiftCardButtonProps = {
  active?: boolean;
  amountKB?: number;
  disabled?: boolean
  onRedeem: (_detailPayment: IStateDetailPayment) => Promise<void>;
};

const ButtonGiftCard = ({ active = false, disabled, amountKB = 0 }: IGiftCardButtonProps) => {
  const dispatch = useAppDispatch();
  const refModal = useRef<ModalPaymentGiftCardRefs>(null);
  const detail = multiplePaymentSelectors.getDetail();

  const visible = multiplePaymentSelectors.getVisibleModalGiftCard();
  const setOpen = (val: boolean) => {
    dispatch(multiplePaymentActions.setVisibleModalGiftCard(val));
  };

  useEffect(() => {
    if (!visible) return;
    refModal.current?.setAmount(amountKB || 0);
  }, [visible, detail]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRedeem = (val: IGiftCardPaymentValue) => {
    dispatch(multiplePaymentActions.setGiftCardPayment(val));

    /**
     * AUTO COMPLETE PAYMENT
    */
    // if ((remaining - val.amount) <= 0) {
    //   onRedeem({
    //     ...detailPayment,
    //     GIFT_CARD: val,
    //   });
    // }
  };

  return (
    <>
      <CardBox disabled={disabled} onClick={handleOpen}
        type={active ? 'ACTIVE' : 'DEFAUL'}
        icon="giftCardDefault" >
        Gift Card
      </CardBox>
      <ModalPaymentGiftCard
        ref={refModal}
        visible={visible}
        onClose={handleClose}
        onSubmit={handleRedeem}
        total={amountKB || 0}
      />
    </>
  );
};
ButtonGiftCard.displayName = 'ButtonGiftCard';
export default ButtonGiftCard;
