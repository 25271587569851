import { Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Spin from 'components/Spin';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { isFalsy } from 'utils/falsy';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import { momentTimezone } from 'utils/time';
import EditStaffButton from './ModalButton/EditStaffButton';
type ILeftTicketDetailsProps = {
  doPrint: () => void;
};
const LeftTicketDetails: React.FC<ILeftTicketDetailsProps> = () => {
  const navigate = useNavigate();
  const details = ticketListSelectors.ticketDetailsData.data();
  const loading = ticketListSelectors.ticketDetailsData.loading();

  const isVoided = details?.billStatus === 'VOID';

  const creditPaymentItem = details?.payments?.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <LeftTicketDetailsStyled>
        <Spin></Spin>
      </LeftTicketDetailsStyled>
    );
  }

  return (
    <LeftTicketDetailsStyled>
      <MainContentStyled>
        <Box display="flex" gap="3">
          <Button ntype="SECONDARY" icon="back" onClick={handleBack}></Button>
          <Row align={'middle'} justify={'space-between'} style={{ width: '100%' }}>
            <Text variant="H5" color="text_3">
              Transaction Detail
            </Text>
            {creditPaymentItem && <Row align={'middle'}>
              <Box className="center" gap="1" mr='1'>
                <Icon type="card" />
                <Text variant="CONTENT_2" color="text_3" style={{ fontSize: '1.125rem' }}>
                  {creditPaymentItem?.last4 || '--'}
                </Text>
              </Box>

              <Box bgColor='black' className='trans-box'>
                <Text variant='H6' color='fill_1' style={{ fontWeight: 'bold' }}>Trans: #{creditPaymentItem?.transNumber || ''}</Text>
              </Box>
            </Row>}

          </Row>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          mt="4"
        >
          <Box>
            <Text variant="H7" color="text_3">
              #{details?.ticketNumber} - {details?.customerName}
            </Text>
            <Text variant="CONTENT_1" color="text_2">
              {maskPhone(details?.customerPhone)}
            </Text>
          </Box>
          <Text variant="H8" color="text_3">
            {details?.checkInType}
          </Text>
        </Box>
        <Box bt="line_3" my="4"></Box>
        <Box px="4" display="flex" flexDirection="column">
          <Box display="flex" gap="4" alignItems="center">
            <Text variant="H7" color="text_3">
              Timeline:
            </Text>
            <Text variant="H9" color="text_3">
              {momentTimezone(details?.startTime).format('DD/MM/YYYY')}
            </Text>
            <Text variant="H8" color="text_3">
              {momentTimezone(details?.startTime).format('hh:mm A')} -{' '}
              {momentTimezone(details?.endTime).format('hh:mm A')}
            </Text>
          </Box>
          <Box>
            <Text variant="H6" color="text_2">
              Services
            </Text>
          </Box>
          {details?.items.map((item) => {
            return (
              <Box key={item?.staffId} style={{ marginTop: '0.25rem' }}>
                <Box
                  bb="line_3"
                  bStyle="dotted"
                  pb="1"
                  display='flex'
                  alignItems='center'
                >
                  <Text variant="H9" color="text_3" mr={1}>{item?.staffName}</Text>
                  {!isVoided && <EditStaffButton staffId={item.staffId} />}
                </Box>
                <Box mt="1">
                  {item.services.map((service) => (
                    <Box key={service.itemId} className="space-between">
                      <Text variant="H8" color="text_3">
                        {service.itemName} {`(${service.duration}m)`}
                      </Text>
                      <Box display="flex" gap="1">
                        <TextDiscount>
                          {isFalsy(service.discount) &&
                            service.discount !== 0 &&
                            formatCurrency(service.price + service.discount)}
                        </TextDiscount>
                        <Text variant="H9" color="text_3">
                          {formatCurrency(service.price)}
                        </Text>
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Box
                  className="space-between"
                  pb="1"
                >
                  <Text variant="H9" color="text_3">
                  </Text>
                  <Row>
                    <Text variant="H9" color="text_3">
                      Tip:
                    </Text>
                    <Text variant="H9" color="error_4" style={{ width: '6rem' }} textAlign='right'>
                      {formatCurrency(item.tip)}
                    </Text>
                  </Row>
                </Box>
                <Box
                  className="space-between"
                  pb="1"
                >
                  <Text variant="H9" color="text_3">
                  </Text>
                  <Row>
                    <Text variant="H9" color="text_3">
                      Sub-total:
                    </Text>
                    <Text variant="H9" color="info_4" style={{ width: '6rem' }} textAlign='right'>
                      {formatCurrency(item.subTotal)}
                    </Text>
                  </Row>
                </Box>
              </Box>
            );
          })}
        </Box>
      </MainContentStyled>

    </LeftTicketDetailsStyled >
  );
};

export default LeftTicketDetails;
const LeftTicketDetailsStyled = styled.div`
  width: 60%;
  border-right: 1px solid ${colorTheme.line_3};
  background-color: #d9d9d9;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  height: 100%;
`;
const MainContentStyled = styled.div`
  padding: 1.5rem;
  overflow: auto;
  height: 100%;

  .trans-box {
    white-space: nowrap;
    background: #767676;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    height: 40px;
  }
`;

const TextDiscount = styled.div`
  color: var(--text-text-3, #1d2129);
  text-align: right;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: strikethrough;
`;
