import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import ticketListActions from 'features/ticketList/services/actions';
import { IApiGetTicketsParam } from 'features/ticketList/services/types/api';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import HeaderTickets from './components/HeaderTickets';
import TicketOnTabs from './components/TicketOnTabs';
import ListTicketPageStyled from './styles';
type IListTicketPageProps = any;
const ListTicketPage: React.FC<IListTicketPageProps> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(ticketListActions.doInit());
    const params: IApiGetTicketsParam = {
      status: 'OPEN_TICKET',
      page: 1,
      size: 10,
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
    };
    dispatch(ticketListActions.setVisibleCusPhone(true));
    dispatch(ticketListActions.setTicketsParams(params));
  }, []);

  return (
    <ListTicketPageStyled>
      <Sidebar />
      <BodyStyled>
        <HeaderTickets />
        <TicketOnTabs />
      </BodyStyled>
    </ListTicketPageStyled>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
  padding: 0 1.5rem;
`;

export default ListTicketPage;
