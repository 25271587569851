import { Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import IconGeneral from './generalIcon';
import IconList from './listIcon';
import appointmentSelectors from 'features/appointment/services/selectors';
import { APPOINTMENT_LAYOUT } from 'features/appointment/services/constants';
import { useAppDispatch } from 'store/hooks';
import appointmentActions from 'features/appointment/services/actions';
type ILayoutButtonProps = any;
const LayoutButton: React.FC<ILayoutButtonProps> = () => {
  const dispatch = useAppDispatch();
  const value = appointmentSelectors.getAppointmentLayout();
  const setValue = (val: APPOINTMENT_LAYOUT) => () => {
    dispatch(appointmentActions.setAppointmentLayout(val));
  };
  return (
    <LayoutButtonStyled>
      <button onClick={setValue(APPOINTMENT_LAYOUT.CALENDAR)}><IconGeneral active={value === APPOINTMENT_LAYOUT.CALENDAR} /></button>
      <button onClick={setValue(APPOINTMENT_LAYOUT.LIST)}><IconList active={value === APPOINTMENT_LAYOUT.LIST} /></button>
    </LayoutButtonStyled>
  );
};

export default LayoutButton;
type LayoutButtonStyledProps = {}
const LayoutButtonStyled = styled(Row) <LayoutButtonStyledProps>`
  gap: 12px;
`;