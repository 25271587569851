import CarouserBox from 'components/Carosel/CarouserBox';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import cashierActions from 'features/cashier/services/actions';
import useAddNewTicket from 'hooks/useAddNewTicket';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import AvatarBox from './AvatarBox';
type IStaffAvailableProps = any;

const StaffAvailable: React.FC<IStaffAvailableProps> = () => {
  const modal = useModalConfirmRef();
  const staffList = shopSelectors.data.staffsAvailable();
  const dispatch = useAppDispatch();
  const getNumberTicket = useAddNewTicket(modal);

  const staffClick = (item: IStaffAvailableItem) => () => {
    dispatch(cashierActions.setDraftStaffForNewTicket(item));
    getNumberTicket();
  };
  return (
    <>
      <StaffAvailableStyled>
        <CarouserBox
          content={`AVAILABLE STAFFS (${staffList?.length})`}
          list={staffList}
          contentProps={{ variant: 'H7', color: 'text_3' }}
          rowPerPage={16}
          colSpan={3}
          itemContent={(item: IStaffAvailableItem) => (
            <AvatarBox
              key={item.staffId}
              username={item.staffName}
              url={item.staffAvatar}
              isSelected={false}
              isBusy={!!item.isBusy}
              onClick={staffClick(item)}
            />
          )}
        ></CarouserBox>
      </StaffAvailableStyled>
      <ModalConfirm ref={modal} okTitle='Yes' cancelTitle='No' />
    </>
  );
};

export default StaffAvailable;
type StaffAvailableStyledProps = {}
const StaffAvailableStyled = styled.div<StaffAvailableStyledProps>``;
