import React, { useMemo } from 'react';
import { Button } from 'antd';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { Bill } from 'features/ticketList/services/types/api';

type IAdjustTipButtonProps = {
  record: Bill;
};
const AdjustTipButton: React.FC<IAdjustTipButtonProps> = ({ record }) => {
  const dispatch = useAppDispatch();
  const adjustTipStore = ticketListSelectors.adjustTip();

  const isDisabled = useMemo(() => {
    const exist = adjustTipStore?.find((o) => o.billId === record.billId);
    return !exist || !exist.tip;
  }, [adjustTipStore]);

  const handleClick = () => {
    const billId = record.billId;
    dispatch(ticketListActions.adjustTip.fetch({ billId }));
  };

  return (
    <AdjustTipButtonStyled>
      <ActionButton onClick={handleClick} disabled={isDisabled}>
        ADJUST
      </ActionButton>
    </AdjustTipButtonStyled>
  );
};

export default AdjustTipButton;
const AdjustTipButtonStyled = styled.div``;
const ActionButton = styled(Button)`
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: var(--info-infor-3, #6fabb6) !important;
  flex-grow: 1;
  flex-basis: 1rem;
  cursor: pointer;
  color: var(--text-text-1, #fff) !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-color: var(--info-infor-3, #6fabb6) !important;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
