import React from 'react';
import { ICouponPrintDiscount } from 'services/shop/types/coupon';
import styled from 'styled-components';
type Props = {
  isLarge?: boolean;
  data: ICouponPrintDiscount,
};
const Percent = ({ isLarge, data }: Props) => {
  return (
    <TopPercentStyled >
      <MoneyBox isLarge={isLarge}>{data.discountValue}</MoneyBox>
      <MoneyContent isLarge={isLarge}>
        <div className="top">
          <span className='money-suffix'>%</span>
          <span className='money-off'>OFF</span>
        </div>
        <div className="coupon-name">{data.couponName}</div>
      </MoneyContent>
    </TopPercentStyled>
  );
};

export default Percent;

const TopPercentStyled = styled.div`
  display: flex;
  justify-content: center;
`;

const MoneyBox = styled.span<{ isLarge?: boolean }>`
  color: black;
  font-size: 150px;
  font-style: normal;
  font-weight: 400;
  line-height: 120px; /* 80% */
  font-family: Archivo Black;
  ${({ isLarge }) => isLarge && `
    font-size: 100px;
  `}
`;

const MoneyContent = styled.div<{ isLarge?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transform: translateY(-15px);
  font-family: Archivo Black;
  .top {
    display: flex;
    align-items: center;
    .money-suffix {
      color: black;
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
    }
    .money-off {
      color: black;
      font-family: Playfair Display;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 55px; /* 100% */
    }
  }
  .coupon-name {
    color: black;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }

  ${({ isLarge }) => isLarge && `
  transform: none;
  padding-bottom: 18px;
  padding-top: 5px;
    .coupon-name {
      font-size: 22px;
    }
  `}
`;
