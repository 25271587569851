const TwoLineOpen = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.84314 9.49609H19.8431"
        stroke="#1D2129"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.84351 14.9961H16.8435"
        stroke="#1D2129"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default TwoLineOpen;
