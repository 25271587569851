import _, { get, unionBy } from 'lodash';
import uiSelector from 'services/UI/selectors';
import shopSelectors from 'services/shop/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IDateRangeItem } from '../utils/getRangeDates';
import { CALENDAR_ITEM_TYPES, CALENDAR_VIEW_TYPE, PATH_LOADING } from './constants';
import { IApiGetAppointments, IApiGetTableAppointment } from './types/api';
import { IAppointmentItemData, IStaffAppointmentItemData } from './types/appointment';
import { ICalendarItemData } from './types/calendar';

type MyState = RootState['appointment'];

const getCurrentState = (state: RootState): MyState => state['appointment'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

export const getPureParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.params ?? {};
};

export const getPureTableParams = (root: RootState) => {
  const state = getCurrentState(root);
  return state.table.params ?? {};
};

const getParams = () => selector('params') as IApiGetAppointments;

const getStaffAppointments = () => selector('staffAppointments') as IStaffAppointmentItemData[];

const getAppointmentsLoading = () => uiSelector.getLoading(PATH_LOADING.getAppointments) as boolean;

const getDateRanges = () => selector('dateRanges') as IDateRangeItem[];

const getDistanceTimeline = () => selector('distanceTimeline') as MyState['distanceTimeline'];

const getViewType = () => selector('viewType') as MyState['viewType'];

const getDateRangeList = () => selector('dateRangeList') as MyState['dateRangeList'];
const getStaffPage = () => (selector('staffListPage') as number) ?? 0;
const getStaffList = () => {
  const data = shopSelectors.data.staffs();
  const page = getStaffPage();
  const limit = 8;
  return _(data)
    .drop((page - 1) * limit)
    .take(limit)
    .value();
};

const getAppointmentList = () => selector('appointmentList') as MyState['appointmentList'];

const table = {
  appointments: () => selector('table.data') as IAppointmentItemData[],
  params: () => selector('table.params') as IApiGetTableAppointment,
  loading: () => uiSelector.getLoading(PATH_LOADING.getTableAppointments) as boolean,
  getParamKey: <Type = any>(key: keyof IApiGetTableAppointment) => selector(`table.params.${key}`) as Type,
};

const getCalendarLoading = () => {
  const staffLoading = shopSelectors.loading.staffs();
  const appointmentLoading = uiSelector.getLoading(PATH_LOADING.getAppointments);
  const lockTimeLoading = uiSelector.getLoading(PATH_LOADING.getLockBreakTimes);

  return appointmentLoading || lockTimeLoading || staffLoading;
};

const getLockBreakTimes = () => selector('lockBreakTimes') as MyState['lockBreakTimes'];

const getAppointmentLayout = () => selector('appointmentLayout') as MyState['appointmentLayout'];

const getSelectedCalendarItemTypes = () => selector('selectedCalendarItemTypes') as MyState['selectedCalendarItemTypes'];
const getAppointmentStatusFilter = () => selector('appointmentStatus') as MyState['appointmentStatus'];

const getCalendarItems = () => {
  const lockBreakTimes = getLockBreakTimes();
  const appointmentStatusFilter = getAppointmentStatusFilter();
  const viewType = getViewType();
  let dataLockBreakTimes: ICalendarItemData[] = [];
  if (viewType === CALENDAR_VIEW_TYPE.DAY_VIEW) {
    dataLockBreakTimes = lockBreakTimes?.map(o => {
      return ({
        data: o,
        endTime: o.endTime,
        startTime: o.startTime,
        id: o.id,
        staffId: o.staffId,
        type: o.type === 'BREAK' ? CALENDAR_ITEM_TYPES.BREAK_STAFF : CALENDAR_ITEM_TYPES.BLOCK_HOURS
      });
    });
  }

  const appointments = getAppointmentList();
  const dataAppointments: ICalendarItemData[] = appointments?.filter(o => appointmentStatusFilter.includes(o.status))?.map(o => {
    return ({
      data: o,
      endTime: o.endTime,
      startTime: o.startTime,
      id: o.appointmentId,
      staffId: o.staffId,
      type: CALENDAR_ITEM_TYPES.APPOINTMENT,
    });
  });

  const result = unionBy(dataLockBreakTimes, dataAppointments, 'id') ?? [];

  return result;
};

const getCategories = () => shopSelectors.data.categoriesIncludeAddon();

const getAppointmentDetailData = () => selector('detail.data') as (IAppointmentItemData | null);

const getNewAppointmentDraftData = () => selector('newAppointmentDraftData') as MyState['newAppointmentDraftData'];

const getNewBlockHourDraftData = () => selector('newBlockHourDraftData') as MyState['newBlockHourDraftData'];

const getNewBreakTimeDraftData = () => selector('newBreakTimeDraftData') as MyState['newBreakTimeDraftData'];

const getDraftAppointmentDetail = () => selector('draftAppointmentData') as MyState['draftAppointmentData'];

const appointmentSelectors = {
  getStaffAppointments,
  getAppointmentsLoading,
  getDateRanges,
  getDistanceTimeline,
  getViewType,
  getDateRangeList,
  getStaffList,
  getAppointmentList,
  getCalendarLoading,
  getLockBreakTimes,
  getCalendarItems,
  getAppointmentLayout,
  getSelectedCalendarItemTypes,
  getParams,
  getCategories,
  getAppointmentDetailData,
  getStaffPage,
  getNewAppointmentDraftData,
  getNewBlockHourDraftData,
  getNewBreakTimeDraftData,
  getDraftAppointmentDetail,
  table,
  getAppointmentStatusFilter,
};
export default appointmentSelectors;
