import CarouserBox from 'components/Carosel/CarouserBox';
import ServiceItem from 'components/ServiceItem';
import Spin from 'components/Spin';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { IServiceCategory } from 'features/cashier/services/types/api';
import { useCallback } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IServiceItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';

const AddOnServices = () => {
  const loading = shopSelectors.loading.addonServices();
  const addOnList = shopSelectors.data.addonServices();
  const selectedServices = checkInSelectors.getSelectedServices();
  const dispatch = useAppDispatch();

  const handleClickAddOnItem = (addOnItem: IServiceCategory) => () => {
    dispatch(checkInActions.chooseService(addOnItem));
  };

  const renderItem = useCallback(
    (item: IServiceItemData) => {
      return (
        <ServiceItem
          price={item.priceSell}
          service={item.serviceName}
          size={'SMALL'}
          onClick={handleClickAddOnItem(item)}
          selected={!!selectedServices.find(o => o.id === item.id)}
          addOn
        />
      );
    },
    [handleClickAddOnItem]
  );
  if (loading) {
    return <Spin></Spin>;
  }
  return (
    <CarouserBox
      content="ADD - ON"
      list={addOnList || []}
      rowPerPage={3}
      colSpan={24}
      itemContent={renderItem}
    />
  );
};

export default AddOnServices;
