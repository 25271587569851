import { Col, Row } from 'antd';
import Modal from 'components/Modal';
import Text from 'components/Text';
import _ from 'lodash';
import { useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopApis from 'services/shop/apis';
import { SHOP_DEVICE_TYPE } from 'services/shop/constants';
import shopSelectors from 'services/shop/selectors';
import { IBodyApiAddDeviceWithStation, IDeviceItem, IStationGroupItem, IStationItem } from 'services/shop/types/station';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage, { getDeviceId } from 'utils/sessionStorage';
import Header from './components/Header';
import { IconCheckIn, IconPOS } from './components/icons';
type IDetailRoute = '/turn' | '' | '/appointments';
const OwnerChooseDevicePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setLoadingPage = useSetLoadingPage();
  const [, startTransition] = useTransition();
  const { shop_id = '' } = useParams();
  const [visibleExist, setVisibleExist] = useState(false);

  const totalStation = shopSelectors.data.totalStation();

  const [visibleChooseStation, setVisibleChooseStation] = useState(false);
  const [deviceType, setDeviceType] = useState<SHOP_DEVICE_TYPE | null>(null);
  const [devicePOSRoute, setDevicePOSRoute] = useState<IDetailRoute>('');
  const [curStation, serCurStation] = useState<IStationItem | null>(null);

  const refDeviceType = useRef<SHOP_DEVICE_TYPE | null>(null);

  const stations = useMemo(() => Array.from(Array(totalStation).keys()).map(o => ({
    id: o + 1,
    stationNumber: o + 1,
  })), [totalStation]);

  const [stationDevices, setStationDevices] = useState<IStationGroupItem[]>([]);
  const getDevices = async () => {
    try {
      const res: IResponseDataBody<IStationGroupItem['devices']> = await shopApis.getShopDevices(shop_id);
      if (res.data.data) {
        const data = _.chain(res.data.data)
          .groupBy('stationNumber')
          .map((value, key) => ({ stationNumber: key, devices: _.sortBy(value, o => o.deviceType === SHOP_DEVICE_TYPE.CHECK_IN) }))
          .value();
        setStationDevices(_.sortBy(data, o => +o.stationNumber));
      }
    } catch (error) { }
  };

  useEffect(() => {
    getDevices();
  }, []);

  const moveToChooseStation = (method: SHOP_DEVICE_TYPE, detailRoute: IDetailRoute = '') => () => {
    setDeviceType(method);
    refDeviceType.current = method;
    setDevicePOSRoute(detailRoute);
    if (stations?.length === 1) {
      onChooseStation(stations[0])();
    } else {
      setVisibleChooseStation(true);
    }
  };

  const addDeviceId = async (shopId: string, deviceId: string, method: SHOP_DEVICE_TYPE) => {
    const res: IResponseDataBody<boolean> = await shopApis.addDevice(shopId, deviceId, method);
    return res.data.data;
  };

  const goShopDashboard = async (deviceId: string) => {
    if (!shop_id) return;
    storage.device_type.set(SHOP_DEVICE_TYPE.POS);
    try {
      const result = await addDeviceId(shop_id, deviceId, SHOP_DEVICE_TYPE.POS);
      if (result) {
        dispatch(shopActions.get.shopInfo.fetch(shop_id));
        setTimeout(() => navigate('/store/' + shop_id + devicePOSRoute), 10);
      }
    } catch (error) { }
  };

  const goShopCheckIn = async (deviceId: string) => {
    if (!shop_id) return;
    storage.device_type.set(SHOP_DEVICE_TYPE.CHECK_IN);
    try {
      const result = await addDeviceId(shop_id, deviceId, SHOP_DEVICE_TYPE.CHECK_IN);
      if (result) {
        dispatch(shopActions.get.shopInfo.fetch(shop_id));
        setTimeout(() => navigate('/store/' + shop_id + '/check-in/sign-in'), 10);
      }
    } catch (error) { }
  };

  const onChooseStation = (o: IStationItem, byPass?: boolean) => async () => {
    const _deviceType = refDeviceType.current || deviceType;
    if (!_deviceType) return;

    if (!byPass) {
      const devices = _.get(
        stationDevices.find(s => s.stationNumber.toString() === o.stationNumber.toString()),
        'devices',
        []
      ) || [];

      const isRunning = _.find(devices, (d: IDeviceItem) => d.deviceType === _deviceType)?.running || false;
      if (isRunning) {
        startTransition(() => {
          serCurStation(o);
          setVisibleExist(true);
        });
        return;
      }
    }

    const deviceId = getDeviceId();
    const body: IBodyApiAddDeviceWithStation = {
      deviceId,
      deviceType: _deviceType,
      stationNumber: o.stationNumber,
    };
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await shopApis.addDeviceWithStation(shop_id, body);
      if (res.data.data) {
        storage.station_number.set(body.stationNumber?.toString());
        storage.device_id.set(deviceId);
        storage.shop_id.set(shop_id);
        switch (_deviceType) {
          case SHOP_DEVICE_TYPE.POS: return await goShopDashboard(deviceId);
          case SHOP_DEVICE_TYPE.CHECK_IN: return await goShopCheckIn(deviceId);
        }
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const handleCloseReplaceDeviceModal = () => startTransition(() => {
    serCurStation(null);
    setVisibleExist(false);
  });

  const handleSubmitReplaceDeviceModal = () => {
    if (!curStation) return;
    handleCloseReplaceDeviceModal();
    onChooseStation(curStation, true)();
  };

  return (
    <>
      <PageStyled>
        <Header />
        <ContainerStyled>
          <Text mb={2} variant='H3'>MAIN DEVICE CONNECTORS</Text>
          <GroupDeviceType gutter={[16, 16]}>
            <Col span={12}>
              <ButtonDeviceTypeStyled onClick={moveToChooseStation(SHOP_DEVICE_TYPE.POS)}>
                <IconPOS />
                <Text variant='H7' color='text_3' className='text'>POS</Text>
                <div className="arrow"><IconArrowRight /></div>
              </ButtonDeviceTypeStyled>
            </Col>
            <Col span={12}>
              <ButtonDeviceTypeStyled onClick={moveToChooseStation(SHOP_DEVICE_TYPE.CHECK_IN)}>
                <IconCheckIn />
                <Text variant='H7' color='text_3' className='text'>CHECK IN</Text>
                <div className="arrow"><IconArrowRight /></div>
              </ButtonDeviceTypeStyled>
            </Col>
            <Col span={12}>
              <ButtonDeviceTypeStyled onClick={moveToChooseStation(SHOP_DEVICE_TYPE.POS, '/turn')}>
                <IconTurn />
                <Text variant='H7' color='text_3' className='text'>TURN</Text>
                <div className="arrow"><IconArrowRight /></div>
              </ButtonDeviceTypeStyled>
            </Col>
            <Col span={12}>
              <ButtonDeviceTypeStyled onClick={moveToChooseStation(SHOP_DEVICE_TYPE.POS, '/appointments')}>
                <IconApt />
                <Text variant='H7' color='text_3' className='text'>APPOINTMENT</Text>
                <div className="arrow"><IconArrowRight /></div>
              </ButtonDeviceTypeStyled>
            </Col>

          </GroupDeviceType>
          {/* <TableDevices stationDevices={stationDevices} /> */}
        </ContainerStyled>
      </PageStyled>
      <Modal
        modalTitle={'DEVICE NUMBER'}
        visible={!!visibleChooseStation}
        onClose={() => setVisibleChooseStation(false)}
        noneBodyStyle
        noneFooterStyle
        containerPadding={1.5}
        hiddenOkButton
        className='modal-overflow-unset'
        cancelTitle='Close'
        width={'46.125rem'}
      >
        <Buttons gutter={[16, 16]}>
          {stations.map((o, i) => (
            <Col key={o.id} span={(i === (stations.length - 1) && i % 2 === 0) ? 24 : 12}>
              <ButtonStation onClick={onChooseStation(o)}>
                {deviceType === SHOP_DEVICE_TYPE.POS ? <IconPOS /> : <IconCheckIn />}
                <Text variant='H7' color='text_3' className='text'>STATION {o.stationNumber}</Text>
                <IconArrowRight />
              </ButtonStation>
            </Col>
          ))}
        </Buttons>
      </Modal>
      <Modal
        visible={visibleExist}
        onClose={handleCloseReplaceDeviceModal}
        hiddenHeader
        okTitle={'Ok'}
        onSubmit={handleSubmitReplaceDeviceModal}
      >
        <ModalStyled>
          <IconWarningBig />
          <Text variant='H7' mt={2} mb={1} >
            Do you want to replace the device?
          </Text>
          {/* <Text mb={0.5}>Cannot be combined with any other offers</Text> */}
        </ModalStyled>
      </Modal>
    </>
  );
};

export default OwnerChooseDevicePage;

const PageStyled = styled.div`
  background: var(--fill-fill-3, #E5E5E5);
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const ContainerStyled = styled.div`
  flex:1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
`;

const Buttons = styled(Row)``;
const ButtonStation = styled.button`
  height: 6.25rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-1, #F6F7FC);
  display: flex;
  align-items: center;
  padding: 1rem;
  .text {
    flex-grow: 1;
    padding: 0.5rem 0;
    text-align: left;
    padding-left: 1rem;
    margin-left: 1rem;
    border-left: 1px solid var(--line-line-3, #86909C);
  }
  &:hover {
    border: 1px solid #86909C !important;
    background-color: #E5E6EB !important;
  }
  &:disabled {
    background-color: #E5E6EB !important;
  }
`;

const GroupDeviceType = styled(Row)`
  height: 40vh;
  width: 60vw;
`;
const ButtonDeviceTypeStyled = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 5px;
  border: 1px solid var(--info-infor-3, #6FABB6);
  background: var(--blue-headline, #C6E9EF);
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: relative;
  .text {
    padding: 0.5rem 0;
    text-align: left;
    padding-left: 1rem;
    margin-left: 1rem;
    border-left: 1px solid var(--line-line-3, #86909C);
    font-size: 1.75rem;
  }
  .arrow {
    position: absolute;
    right: 1rem;
  }
`;


const IconArrowRight = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M23.7502 17.3333L17.5425 23.541L19.4281 25.4267L28.8562 15.9986L19.4281 6.57048L17.5425 8.4561L23.753 14.6666L3.33333 14.6666L3.33333 17.3333L23.7502 17.3333Z" fill="#6FABB6" />
  </svg>
);


const ModalStyled = styled.div`
  text-align: center;
`;

const IconWarningBig = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="97" height="96" viewBox="0 0 97 96" fill="none">
    <path d="M47.1016 0.0228096C55.1129 -0.22689 63.1789 1.57766 70.3131 5.23293C80.7502 10.506 89.0973 19.7575 93.2729 30.6813C96.9722 40.1783 97.5136 50.865 94.7963 60.6872C92.5469 68.9693 88.0082 76.6156 81.8203 82.5622C75.9639 88.2318 68.624 92.355 60.7385 94.4198C51.8584 96.7699 42.2985 96.4971 33.5758 93.6119C23.9194 90.4917 15.3351 84.1821 9.45146 75.9147C5.22546 70.0184 2.37175 63.1465 1.17361 55.9933C-0.943594 43.7496 1.96467 30.7359 9.10944 20.5716C14.9679 12.1091 23.6382 5.6421 33.4163 2.43168C37.8291 0.975446 42.4538 0.14661 47.1016 0.0228096ZM46.4909 13.7206C44.5878 14.1676 42.8441 15.2692 41.6312 16.803C40.2296 18.5363 39.556 20.8171 39.7428 23.0329C40.6178 34.8842 41.4991 46.7355 42.3741 58.5868C42.4223 59.0841 42.4013 59.5982 42.544 60.085C42.7161 60.5299 43.2155 60.7292 43.6687 60.6914C46.961 60.683 50.2532 60.6998 53.5434 60.683C54.1393 60.7124 54.5883 60.1459 54.5611 59.5793C55.4654 47.3294 56.3824 35.0794 57.2784 22.8294C57.4756 19.9694 56.1642 17.0632 53.8833 15.3237C51.827 13.7122 49.0299 13.1121 46.4909 13.7206ZM46.7218 68.2957C45.2278 68.6671 43.868 69.5316 42.8923 70.7193C40.7521 73.2268 40.6933 77.2031 42.7601 79.7714C44.7682 82.4594 48.7319 83.3679 51.7095 81.8215C54.9493 80.2939 56.6027 76.1812 55.2955 72.8386C54.133 69.433 50.1945 67.3389 46.7218 68.2957Z" fill="#FFDC7C" />
  </svg>
);

const IconTurn = () => (
  <svg width="44" height="40" viewBox="0 0 44 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="#000000ff">
      <path id="Vector" d="M7.68787 0.990351C8.39257 0.175451 9.82227 0.32335 10.3791 1.23105C10.8112 1.85745 10.7561 2.75355 10.2428 3.31905C8.90587 4.91405 7.48777 6.44525 6.15377 8.04315C12.1249 8.03735 18.096 8.04605 24.0671 8.04025C26.2508 8.00255 28.4258 8.83775 30.0179 10.3313C32.1552 12.3004 33.1528 15.4121 32.5351 18.2541C31.9667 21.1599 29.7482 23.6568 26.9381 24.5819C25.085 25.246 23.0869 24.9763 21.1613 25.043C20.358 25.0546 19.3807 25.0807 18.879 24.3267C18.1424 23.4132 18.6731 21.8704 19.8244 21.6181C21.837 21.3484 23.9366 21.882 25.8999 21.2411C27.7443 20.5799 29.0928 18.7616 29.1711 16.8012C29.3016 14.806 28.1039 12.8195 26.2885 11.9959C25.1488 11.4188 23.8409 11.5203 22.6055 11.5203C17.1187 11.529 11.629 11.5174 6.14217 11.5261C7.51967 13.1472 8.96967 14.7074 10.324 16.3488C11.1157 17.2942 10.4632 18.8979 9.25677 19.0748C8.56077 19.2372 7.88217 18.866 7.45587 18.3324C4.97057 15.4817 2.47947 12.6339 -0.00872803 9.78025C2.56067 6.85415 5.10107 3.89905 7.68787 0.990351Z" fill="#1D2129" />
      <path id="Vector_2" d="M16.5619 15.4061C18.4179 14.742 20.416 15.0233 22.3445 14.9508C23.1507 14.9334 24.1396 14.9189 24.6297 15.6903C25.346 16.6096 24.8066 18.1234 23.6669 18.3757C21.7181 18.6338 19.7055 18.1611 17.7886 18.6802C15.9413 19.2631 14.5261 20.9886 14.3434 22.92C14.0882 24.9993 15.3033 27.1424 17.2115 28.0008C18.3483 28.5721 19.6533 28.4764 20.8858 28.4735C26.3755 28.4677 31.8652 28.4764 37.352 28.4706C35.9716 26.8437 34.5187 25.2777 33.1615 23.6305C32.4481 22.7576 32.9295 21.296 33.9996 20.977C34.7507 20.6899 35.5743 21.0524 36.0412 21.6643C38.5265 24.515 41.0147 27.3628 43.5029 30.2135C41.122 32.9511 38.7179 35.6713 36.3486 38.4205C35.8788 38.9686 35.3249 39.5979 34.5303 39.5428C33.234 39.5863 32.3176 37.9217 33.1093 36.8748C34.4665 35.187 35.9542 33.6065 37.3578 31.9564C31.3867 31.9506 25.4156 31.9535 19.4474 31.9564C17.2724 31.9912 15.0974 31.1763 13.5053 29.6915C11.2781 27.6673 10.2863 24.399 11.0171 21.4787C11.6609 18.6773 13.8359 16.3051 16.5619 15.4061Z" fill="#1D2129" />
    </g>
  </svg>
);

const IconApt = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="59" height="58" viewBox="0 0 59 58" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.4583 10.875C9.45631 10.875 7.83334 12.498 7.83334 14.5V47.125C7.83334 49.127 9.45631 50.75 11.4583 50.75H47.7083C49.7104 50.75 51.3333 49.127 51.3333 47.125V14.5C51.3333 12.498 49.7104 10.875 47.7083 10.875H11.4583ZM4.20834 14.5C4.20834 10.4959 7.45428 7.25 11.4583 7.25H47.7083C51.7124 7.25 54.9583 10.4959 54.9583 14.5V47.125C54.9583 51.1291 51.7124 54.375 47.7083 54.375H11.4583C7.45428 54.375 4.20834 51.1291 4.20834 47.125V14.5Z" fill="#1D2129" />
    <path d="M34.1146 29C35.6161 29 36.8333 27.7828 36.8333 26.2812C36.8333 24.7797 35.6161 23.5625 34.1146 23.5625C32.6131 23.5625 31.3958 24.7797 31.3958 26.2812C31.3958 27.7828 32.6131 29 34.1146 29Z" fill="#1D2129" />
    <path d="M43.1771 29C44.6786 29 45.8958 27.7828 45.8958 26.2812C45.8958 24.7797 44.6786 23.5625 43.1771 23.5625C41.6756 23.5625 40.4583 24.7797 40.4583 26.2812C40.4583 27.7828 41.6756 29 43.1771 29Z" fill="#1D2129" />
    <path d="M34.1146 38.0625C35.6161 38.0625 36.8333 36.8453 36.8333 35.3438C36.8333 33.8422 35.6161 32.625 34.1146 32.625C32.6131 32.625 31.3958 33.8422 31.3958 35.3438C31.3958 36.8453 32.6131 38.0625 34.1146 38.0625Z" fill="#1D2129" />
    <path d="M43.1771 38.0625C44.6786 38.0625 45.8958 36.8453 45.8958 35.3438C45.8958 33.8422 44.6786 32.625 43.1771 32.625C41.6756 32.625 40.4583 33.8422 40.4583 35.3438C40.4583 36.8453 41.6756 38.0625 43.1771 38.0625Z" fill="#1D2129" />
    <path d="M15.9896 38.0625C17.4911 38.0625 18.7083 36.8453 18.7083 35.3438C18.7083 33.8422 17.4911 32.625 15.9896 32.625C14.4881 32.625 13.2708 33.8422 13.2708 35.3438C13.2708 36.8453 14.4881 38.0625 15.9896 38.0625Z" fill="#1D2129" />
    <path d="M25.0521 38.0625C26.5536 38.0625 27.7708 36.8453 27.7708 35.3438C27.7708 33.8422 26.5536 32.625 25.0521 32.625C23.5506 32.625 22.3333 33.8422 22.3333 35.3438C22.3333 36.8453 23.5506 38.0625 25.0521 38.0625Z" fill="#1D2129" />
    <path d="M15.9896 47.125C17.4911 47.125 18.7083 45.9078 18.7083 44.4062C18.7083 42.9047 17.4911 41.6875 15.9896 41.6875C14.4881 41.6875 13.2708 42.9047 13.2708 44.4062C13.2708 45.9078 14.4881 47.125 15.9896 47.125Z" fill="#1D2129" />
    <path d="M25.0521 47.125C26.5536 47.125 27.7708 45.9078 27.7708 44.4062C27.7708 42.9047 26.5536 41.6875 25.0521 41.6875C23.5506 41.6875 22.3333 42.9047 22.3333 44.4062C22.3333 45.9078 23.5506 47.125 25.0521 47.125Z" fill="#1D2129" />
    <path d="M34.1146 47.125C35.6161 47.125 36.8333 45.9078 36.8333 44.4062C36.8333 42.9047 35.6161 41.6875 34.1146 41.6875C32.6131 41.6875 31.3958 42.9047 31.3958 44.4062C31.3958 45.9078 32.6131 47.125 34.1146 47.125Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.0833 3.625C16.0844 3.625 16.8958 4.43648 16.8958 5.4375V9.0625C16.8958 10.0635 16.0844 10.875 15.0833 10.875C14.0823 10.875 13.2708 10.0635 13.2708 9.0625V5.4375C13.2708 4.43648 14.0823 3.625 15.0833 3.625Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M44.0833 3.625C45.0844 3.625 45.8958 4.43648 45.8958 5.4375V9.0625C45.8958 10.0635 45.0844 10.875 44.0833 10.875C43.0823 10.875 42.2708 10.0635 42.2708 9.0625V5.4375C42.2708 4.43648 43.0823 3.625 44.0833 3.625Z" fill="#1D2129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.02084 16.3125H53.1458V19.9375H6.02084V16.3125Z" fill="#1D2129" />
  </svg>
);
