import React from 'react';
import styled from 'styled-components';
import CouponTable from '../Table/CouponTable';
type ICouponTabProps = {};
const CouponTab: React.FC<ICouponTabProps> = () => {
  return (
    <CouponTabStyled>
      <CouponTable />
    </CouponTabStyled>
  );
};

export default CouponTab;
const CouponTabStyled = styled.div``;
