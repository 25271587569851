import { Divider, Form, Input, Switch } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import FormItemUploadImg from './components/FormItemUploadImg';

type ICheckProps = {};
const Check: React.FC<ICheckProps> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const otherStore =
    settingServiceSelectors.paymentSetting.otherPaymentMethod();

  const onFinish = (values: any) => {
    dispatch(
      settingServiceActions.postOtherPaymentMethod.fetch(values?.others)
    );
  };

  // const transformFiles = (id: number, src: string) => {
  //   if (src)
  //     return [
  //       {
  //         uid: id + '-qr',
  //         name: src + '-qrCode',
  //         status: 'done',
  //         url: src,
  //       },
  //     ];
  //   return [];
  // };

  useEffect(() => {
    if (otherStore && otherStore.length > 0) {
      form.setFieldsValue({ others: otherStore });
    }
  }, [otherStore]);

  useEffect(() => {
    dispatch(settingServiceActions.getOtherPaymentMethod.fetch());
  }, []);
  return (
    <CheckStyled>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Text variant="H9" color="text_3">
            Check
          </Text>
          <Form.Item label="Line" name="line">
            <Input placeholder="* There Will Be A $25 Service Charge On All Returned Checks" />
          </Form.Item>
          <Divider />
          <Text variant="H9" color="text_3">
            Others
          </Text>

          <Form.List name="others">
            {(fields, { add }) => (
              <div>
                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <Box
                      key={key}
                      bgColor="fill_3"
                      display="flex"
                      gap="3"
                      p="2"
                      alignItems="center"
                    >
                      <Form.Item
                        {...restField}
                        label="Active"
                        valuePropName="checked"
                        name={[name, 'active']}
                      >
                        <Switch />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label="Account Name"
                        name={[name, 'name']}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        label="User ID"
                        name={[name, 'email']}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        label="Phone"
                        name={[name, 'phone']}
                      >
                        <Input />
                      </Form.Item>

                      <FormItemUploadImg
                        restField={restField}
                        label="QR Image"
                        item_name="imageQrCode"
                        isQr={true}
                        name={name}
                      />
                      <FormItemUploadImg
                        restField={restField}
                        label="Logo Image"
                        item_name="icon"
                        name={name}
                      />
                    </Box>
                  );
                })}
                <Box mt="3">
                  <Button
                    ntype="LIGHT_BLUE"
                    icon="addPlus"
                    onClick={() => add()}
                  >
                    Add New Payment Type
                  </Button>
                </Box>
              </div>
            )}
          </Form.List>
        </Box>
        <Box display="flex" gap="3" mt="4" justifyContent="end">
          <Button width="10rem" ntype="SECONDARY" htmlType="reset">
            Reset
          </Button>
          <Button width="10rem" ntype="PRIMARY" htmlType="submit">
            Save
          </Button>
        </Box>
      </Form>
    </CheckStyled>
  );
};

export default Check;
const CheckStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
`;
