import { DatePicker } from 'antd';
import type { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import type { Moment } from 'moment';
import * as React from 'react';

export interface TimePickerProps extends Omit<PickerTimeProps<Moment>, 'picker'> { }

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => (
    // @ts-ignore
    <DatePicker {...props} format={FORMAT_TIME} picker="time" mode={undefined} ref={ref} />
));

TimePicker.displayName = 'TimePicker';

export default TimePicker;