import Modal from 'components/Modal';
import NumberKeyBoard, { NumberKeyBoardRef, PIN_LENGTH } from 'components/NumberKeyBoard';
import React, { useEffect, useRef, useState } from 'react';
type IModalPassword = {
  onSubmit?: (value: string) => void;
  isShown: boolean;
  onClose: () => void;
};
const ModalPassword: React.FC<IModalPassword> = ({ onSubmit, isShown, onClose }) => {
  const passRef = useRef<NumberKeyBoardRef>(null);
  const [text, setText] = useState('');

  useEffect(() => {
    if (!isShown) return;
    setTimeout(() => passRef.current?.focusInput(), 200);
  }, [isShown]);

  useEffect(() => {
    if (text.length === PIN_LENGTH) {
      try {
        const isExcept = true;
        if (isExcept) {
          onSubmit && onSubmit(text);
        }
      } catch (error) {
        setText('');
      }
    }
  }, [text]);

  useEffect(() => {
    if (!text) {
      setText(String(''));
    }
  }, []);

  return (
    <Modal
      modalTitle="ADMIN PASSWORD"
      visible={isShown}
      onClose={onClose}
      hiddenOkButton={true}
      destroyOnClose={true}
      okTitle=""
      cancelTitle="Close"
    >
      <NumberKeyBoard
        ref={passRef}
        inputClassName="custom-number-input-keyboard"
        value={text}
        onChange={setText}
        type="PIN-PASSWORD"
      />
    </Modal>
  );
};

export default ModalPassword;

