import { Form } from 'antd';
import Button from 'components/Button';
import React from 'react';
import styled from 'styled-components';
type IAddButtonProps = {};
const AddButton: React.FC<IAddButtonProps> = () => {
  const form = Form.useFormInstance();
  const [submittable, setSubmittable] = React.useState(false);
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
  }, [values]);

  return (
    <AddButtonStyled>
      <Button ntype="PRIMARY" htmlType="submit" block disabled={!submittable}>
        Add
      </Button>
    </AddButtonStyled>
  );
};

export default AddButton;
const AddButtonStyled = styled.div``;
