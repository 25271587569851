
import apis from 'features/payment/services/apis';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { get } from 'lodash';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import actions from './actions';
import { MyState, getCurrentState } from './selectors';
import { ICustomerInfoData } from './types/customer';
import { Message } from 'stompjs';
const getStoreData = function* (key: keyof MyState) {
  const store: MyState = yield select(root => getCurrentState(root));
  return get(store, key);
};

const getTransactionDetail: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);

  try {
    const res: IResponseDataBody<IBillDetailData> = yield call(apis.getTransactionDetail, payload);
    const detail = res?.data?.data;
    if (detail) {
      yield put(actions.getCustomerInfo.fetch(detail.customerPhone));
      yield put(actions.getTransactionDetail.success(detail));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

const syncDetailData = function* () {
  const storeDetailData: MyState['detail'] = yield getStoreData('detail');
  if (!storeDetailData?.billId) return;

  yield delay(200);
  try {
    const res: IResponseDataBody<IBillDetailData> = yield call(apis.getTransactionDetail, storeDetailData?.billId);
    const detail = res?.data?.data;
    if (detail) {
      yield put(actions.getTransactionDetail.success(detail));
    }
  } catch (error) { }
};

const getCustomerInfo: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<ICustomerInfoData> = yield call(apis.getCustomerInfo, payload);
    const detail = res?.data?.data;
    if (detail) {
      yield put(actions.getCustomerInfo.success(detail));
    }
  } catch (error) { }
};

const setCustomerMsg: ISagaFunc<Message> = function* ({ payload }) {
  yield delay(200);
  yield put(actions.customerSide.setCustomerMsg.success(payload));
};

const setCashierMsg: ISagaFunc<Message> = function* ({ payload }) {
  yield delay(200);
  yield put(actions.setCashierMsg.success(payload));
};

const setDraftCheckSignature: ISagaFunc<string> = function* ({ payload }) {
  yield delay(500);
  yield put(actions.setDraftCheckSignature.success(payload));
};

export default function* multiplePaymentServiceSagas() {
  yield takeLatest(actions.getTransactionDetail.fetch, getTransactionDetail);
  yield takeLatest(actions.getCustomerInfo.fetch, getCustomerInfo);
  yield takeLatest(actions.syncDetailData, syncDetailData);
  yield takeLatest(actions.setDraftCheckSignature.fetch, setDraftCheckSignature);
  yield takeLatest(actions.customerSide.setCustomerMsg.fetch, setCustomerMsg);
  yield takeLatest(actions.setCashierMsg.fetch, setCashierMsg);
}
