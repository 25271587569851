import React from 'react';
import styled from 'styled-components';
import Text from 'components/Text';
import BoundingBox from 'components/BoundingBox';
type IComponentProps = {
  children?: React.ReactNode;
};
const Header: React.FC<IComponentProps> = ({ children }) => {
  return (
    <BackgroundStyled>
      <BoundingBox className="header-box" color="primary_bg">
        <Text variant="H7">{children}</Text>
      </BoundingBox>
    </BackgroundStyled>
  );
};

export default Header;
const BackgroundStyled = styled.div`
    .header-box {
        box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4.5rem;
    }
`;
