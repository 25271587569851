import React, { useEffect } from 'react';
import Box from 'components/Box';
import settingServiceActions from 'features/settingService/services/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import DiscountPercentage from './components/DiscountPercentage';
import DiscountSpecial from './components/DiscountSpecial';
type IDiscountProps = {};
const Discount: React.FC<IDiscountProps> = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(settingServiceActions.getDiscount.fetch());
    dispatch(settingServiceActions.getSpecialDiscount.fetch());
  }, []);

  return (
    <DiscountStyled>
      <DiscountPercentage />
      <Box mt="4">
        <DiscountSpecial />
      </Box>
    </DiscountStyled>
  );
};

export default Discount;
const DiscountStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
`;
