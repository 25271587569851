import { AutoComplete, Col, Input, InputRef, Row } from 'antd';
import CurrencyInput, { CurrencyInputFormStyled, CurrencyInputRef } from 'components/CurrencyInputMasked';
import { FormItem, useFormInstance } from 'components/Form';
import InputPhone from 'components/InputPhone';
import { Ref } from 'react';
import styled from 'styled-components';
const bankNameOptions = [
  { value: 'Wells Fargo' },
  { value: 'PNC Bank' },
  { value: 'Capital One Bank' },
  { value: 'Chase Bank' },
];

const InfoForm = ({ currencyRef, inputRef }: { currencyRef: Ref<CurrencyInputRef>, inputRef: Ref<InputRef> }) => {
  const form = useFormInstance();
  return (
    <InfoFormStyled>
      <FormItem label='Customer' name={'name'} rules={[{ required: true }]} >
        <Input
          placeholder='Please enter'
        />
      </FormItem>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormItem label='Phone' name={'phone'} rules={[{ required: true }]} >
            <InputPhone placeholder='Please enter' />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label='Address' name={'address'} rules={[{ required: true }]} >
            <Input
              placeholder='Please enter'
            />
          </FormItem>
        </Col>
      </Row>
      <FormItem label='Amount' name={'amount'} rules={[{ required: true }]} >
        <CurrencyInputFormStyled>
          <CurrencyInput ref={currencyRef} onChange={val => form.setFieldValue('amount', val)} />
        </CurrencyInputFormStyled>
      </FormItem>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormItem label='Pay To The Order Of' name={'companyName'} rules={[{ required: true }]} >
            <Input
              ref={inputRef}
              placeholder='Please enter'
            />
          </FormItem>

        </Col>
        <Col span={12}>
          <FormItem label='No.' name={'no'} rules={[{ required: true }]} >
            <Input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder='0'
            />
          </FormItem>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <FormItem label='Bank' name={'bankName'} rules={[{ required: true }]} >
            <AutoComplete
              options={bankNameOptions}
              filterOption={(inputValue, option) =>
                option?.value?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
              }
              placeholder='Select/Input'
            />
          </FormItem>
        </Col>
        <Col span={12}>
          <FormItem label='For' name={'bankFor'} rules={[{ required: true }]} >
            <Input />
          </FormItem>
        </Col>
      </Row>


      <Row gutter={[16, 16]}>
        <Col span={8}>
          <FormItem label='DL#' name={'dlInformation'} rules={[{ required: true }]} >
            <Input
              placeholder='0'
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label='Routing Number' name={'routingNumber'} rules={[{ required: true }]} >
            <Input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder='0'
            />
          </FormItem>
        </Col>
        <Col span={8}>
          <FormItem label='Account Number' name={'accountNumber'} rules={[{ required: true }]} >
            <Input
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              placeholder='0'
            />
          </FormItem>
        </Col>
      </Row>
    </InfoFormStyled>
  );
};

export default InfoForm;


const InfoFormStyled = styled.div`
.ant-input, .ant-input-suffix, .ant-input-number, .ant-select {
  height: 3rem;
  width: 100%;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input-wrap {
  height: 100%;
  input { 
    height: 100%;
  };
}
`;