import { Col, Row } from 'antd';
import Text from 'components/Text';
import userSelectors from 'features/user/services/selectors';
import { styled } from 'styled-components';
import WalkInItem from './Item';
import Empty from 'components/Empty';
import Spin from 'components/Spin';

const WalkInWaitingList = () => {
  const data = userSelectors.getListWalkIn();
  const loading = userSelectors.getWaitingListLoading();
  const isClockIn = userSelectors.staff.isClockIn();

  const RowData = () => {
    if (loading) return <Spin />;

    return (
      data.length > 0 ? <Row gutter={[12, 12]}>
        {data.map(o => (
          <Col key={o.checkInId}
            span={6}>
            <WalkInItem data={o} viewOnly={!isClockIn} />
          </Col>
        ))}
      </Row> : <Empty mt={5} description={'Empty walk in ticket'} />
    );
  };

  return (
    <Styled>
      <Row>
        <Text variant='H8' color='text_3' mb={0.5}>Walk - In</Text>
      </Row>
      <RowData />
    </Styled>
  );
};

export default WalkInWaitingList;

const Styled = styled.div`
  flex:1;
`;