import { get } from 'lodash';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { ITurnDetail, ITurnTable } from './types';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['turn']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['turn']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getTurn = () => selector('turn') as ITurnTable;
const getTurnLoading = () => selector('turnLoading') as MyState['turnLoading'];
const getTurnDetail = () => selector('turnDetail') as ITurnDetail;
const getShowModalTurnDetail = () => selector('showModalTurnDetail') as boolean;
const getTurnStaffsAvailable = () => selector('turnStaffsAvailable') as IStaffAvailableItem[];
const selectWaitingListResItem = () => selector('selectWaitingListResItem') as MyState['selectWaitingListResItem'];

/**
 * please replace FEATURE_NAME to your feature name
 * ex:
 * FEATURE_NAMESelectors => bookingSelectors
 */
const turnSelectors = {
  getTurn,
  getTurnDetail,
  getShowModalTurnDetail,
  getTurnStaffsAvailable,
  selectWaitingListResItem,
  getTurnLoading
};
export default turnSelectors;
