
import { v4 as uuid } from 'uuid';
type IKeyStore = 'station_number' | 'token' | 'shop_id' | 'refresh_token' | 'shop_info' | 'device_id' | 'device_type' | 'shop_timezone';

const session = {
  set: (keyValue: IKeyStore, value: string) => {
    return sessionStorage.setItem(keyValue, value);
  },

  get: (keyValue: IKeyStore) => {
    return sessionStorage.getItem(keyValue);
  },
};

const local = {
  set: (keyValue: IKeyStore, value: string) => {
    return localStorage.setItem(keyValue, value);
  },

  get: (keyValue: IKeyStore) => {
    return localStorage.getItem(keyValue);
  },
};

const genGetSetKeyLocal = (key: IKeyStore) => ({
  get: () => local.get(key) ?? '',
  set: (value: string) => local.set(key, value),
});

const genGetSetKeySession = (key: IKeyStore) => ({
  get: () => session.get(key) ?? '',
  set: (value: string) => session.set(key, value),
});

export const getDeviceId = () => {
  const device_id = storage.device_id.get();
  if (device_id) return device_id;
  const new_device_id = uuid();
  storage.device_id.set(new_device_id);
  return new_device_id;
};

const storage = {
  get: session.get,
  set: session.set,
  genGetSetKeyLocal,
  genGetSetKeySession,
  device_id: genGetSetKeyLocal('device_id'),
  device_type: genGetSetKeyLocal('device_type'),
  station_number: genGetSetKeyLocal('station_number'),
  token: genGetSetKeyLocal('token'),
  refresh_token: genGetSetKeyLocal('refresh_token'),
  shop_id: genGetSetKeySession('shop_id'),
  shop_timezone: genGetSetKeySession('shop_timezone'),
};

export default storage;
