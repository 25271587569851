import React from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { SalaryPayrollTipOption } from 'features/settingStaff/services/constants';
import styled from 'styled-components';
import Box from 'components/Box';
import Text from 'components/Text';
type IPayrollTipOptionsProps = {};
const PayrollTipOptions: React.FC<IPayrollTipOptionsProps> = () => {
  const form = Form.useFormInstance();
  const enumKeyToLabel = (key: string): string => {
    return key
      .split('_')
      .map((word) => word.charAt(0) + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Map the enum to an array of { label, value } objects
  const salaryPayrollTipOptionsArray = Object.entries(
    SalaryPayrollTipOption
  ).map(([key, value]) => ({
    label: enumKeyToLabel(key),
    value: value,
  }));

  const handleCashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let cashValue = Number(e.target.value);
    if (cashValue > 100) cashValue = 100; // Limiting max value to 100
    const checkValue = 100 - cashValue;
    form.setFieldsValue({
      payrollTypeCheck: checkValue,
    });
  };
  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let checkValue = Number(e.target.value);
    if (checkValue > 100) checkValue = 100; // Limiting max value to 100
    const cashValue = 100 - checkValue;
    form.setFieldsValue({
      payrollTypeCash: cashValue,
    });
  };

  return (
    <PayrollTipOptionsStyled>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Form.Item name="payrollType" label="Select Tip Methods">
            <Select options={salaryPayrollTipOptionsArray} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="payrollTipFee" label="Tip Fee">
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="payrollTypeCash"
            label={
              <Box display="flex" gap="1">
                % Cash<Text color="error_6">(*)</Text>
              </Box>
            }
            rules={[
              {
                required: true,
                message: 'Missing cash',
              },
              () => ({
                validator(_, value) {
                  if (!value || (value >= 0 && value <= 100)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Cash should be between 0 and 100')
                  );
                },
              }),
            ]}
          >
            <Input type="number" onChange={handleCashChange} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="payrollTypeCheck"
            label={
              <Box display="flex" gap="1">
                % Check<Text color="error_6">(*)</Text>
              </Box>
            }
            rules={[
              {
                required: true,
                message: 'Missing check',
              },
              () => ({
                validator(_, value) {
                  if (!value || (value >= 0 && value <= 100)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Check should be between 0 and 100')
                  );
                },
              }),
            ]}
          >
            <Input type="number" onChange={handleCheckChange} />
          </Form.Item>
        </Col>
      </Row>
    </PayrollTipOptionsStyled>
  );
};

export default PayrollTipOptions;
const PayrollTipOptionsStyled = styled.div`
  padding: 1rem;
  background: var(--fill-fill-1, #f6f7fc);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
  .ant-select {
    height: 3rem;
  }
`;
