import { styled } from 'styled-components';

const PrintPageStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export default PrintPageStyled;
