import { Col, Layout } from 'antd';
import Text from 'components/Text';
import TicketWaitingList from 'features/user/widgets/WaitingList/Ticket';
import WalkInWaitingList from 'features/user/widgets/WaitingList/WalkIn';
import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Summary from './Summary';

type IMainProps = {};
const Main: React.FC<IMainProps> = () => {
  return (
    <MainStyled>
      <Header />
      <Layout.Content>
        <Layout hasSider className='container'>
          <Layout.Content className='ant-content-overflow children-container'>
            <Summary />
            <div className='wrap-waiting-ticket'>
              <Col span={24}>
                <Text variant='H7' mb={1}>WAITING LIST</Text>
              </Col>
              <Col span={24} className='waiting-ticket'>
                <WalkInWaitingList />
              </Col>
            </div>
          </Layout.Content>
          <Layout.Sider width={'35rem'} className='sider'>
            <TicketWaitingList />
          </Layout.Sider>
        </Layout>
      </Layout.Content>
    </MainStyled>
  );
};

export default Main;
const MainStyled = styled(Layout)`
  .container {
    height:100%;
    background: var(--fill-fill-3, #E5E5E5);
  }
  .ant-content-overflow {
    max-height: 100%;
  }
  .children-container {
    padding: 1.125rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
  }
  .sider {
    background:#fff;
    min-height: 40vw;
  }
  .footer {
    position: sticky;
    bottom: 0;
    background:red;
  }
  .wrap-waiting-ticket {
    flex: 1;
  }
  .waiting-ticket {
    display: flex;
    flex:1;
    min-height:50vh;
    gap:14px;
    .divider {
      width:1px;
      background: #86909C;
    }
  }
`;
