import { Avatar, Col, Row } from 'antd';
import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import Text from 'components/Text';
import appointmentActions from 'features/appointment/services/actions';
import appointmentSelectors from 'features/appointment/services/selectors';
import { IAppointmentItemData, IAppointmentServiceItem } from 'features/appointment/services/types/appointment';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import { get } from 'lodash';
import { memo, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';

const AppointmentDetail = () => {
  const draffData = appointmentSelectors.getDraftAppointmentDetail();
  const headerData = useMemo(() => draffData?.headerData, [draffData]);
  const data = useMemo(() => draffData?.data, [draffData]);
  const date = useMemo(() => get(data, 'data.startTime'), [data]);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const extraData = useMemo(() => {
    return data?.data as IAppointmentItemData || null;
  }, [data]);

  const time = useMemo(() => {
    const startTime = momentTimezone(data?.startTime);
    const endTime = momentTimezone(data?.endTime);
    return `${startTime.format(FORMAT_TIME)} - ${endTime.format(FORMAT_TIME)}`;
  }, [data]);

  const onClose = () => {
    dispatch(appointmentActions.setDraftAppointmentDetail(null));
  };

  const goToDetail = () => {
    navigate(`/store/${storage.shop_id.get()}/appointments/${data?.id || ''}`);
    dispatch(appointmentActions.setAppointmentDetail(extraData));
    dispatch(appointmentActions.setDraftAppointmentDetail(null));
  };

  return (
    <Modal
      visible={!!draffData}
      noneBodyStyle
      noneFooterStyle
      hiddenHeader
      hiddenFooter
      className='modal-appointment-detail'
      footer={<></>}
    >
      <AppointmentDetailStyled>
        <div className="head">
          <button onClick={onClose}>
            <Icon type='close' />
          </button>
        </div>
        <div className="body">
          <Row>
            <Col span={24}><Text variant='H5' color='text_3' mb={0.25} >{extraData?.customerName}</Text></Col>
            <Col span={24}><Text variant='CONTENT_1' color='text_2' >0 Point</Text></Col>
          </Row>
          <div className="divider" />
          <Col>
            <div className='row-item'>
              <Icon type='time' />
              <Text variant='H9' color='text_3'>{date ? momentTimezone(date).format('MM-DD-YYYY') : null}</Text>
            </div>
            <div className='row-item'>
              <span style={{ opacity: 0 }}><Icon type='time' /></span>
              <Text variant='H9' color='text_2'>{time}</Text>
            </div>
          </Col>
          {headerData && headerData.type === 'staff' && <div className='row-item'><Avatar size={'small'} style={{ backgroundColor: '#f56a00' }}>{headerData.label?.[0]}</Avatar> <Text variant='H9' color='text_3'>{headerData.label}</Text></div>}
          <Row className='services' gutter={[16, 16]}>
            {extraData?.services.map(o => (<Col span={24} key={o.serviceId} ><ServiceItem data={o} /></Col>))}
          </Row>
        </div>
        <div className='footer'>
          <button onClick={goToDetail}><p>View detail {'>>'} </p></button>
        </div>
      </AppointmentDetailStyled>
    </Modal>
  );
};

export default memo(AppointmentDetail);

const AppointmentDetailStyled = styled.div`
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    padding-top:1.5rem;
    padding-bottom: 0;

    border-radius: 5px;
    border: 1px solid var(--line-line-3, #86909C);
    // background: var(--fill-fill-0, #FFF);
    // box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .divider {
      background: #86909C;
      width: 100%;
      height: 1px;
    }

    .head {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1.5rem;
      padding: 0 1.5rem;
    }

    .body {
      gap: 16px;
      display: flex;
      flex-direction: column;
      padding: 0 1.5rem;
      padding-bottom: 1.5rem;
    }
    .services {
      max-height: 40vh;
      overflow-y: auto;
    }
    .footer {
      border-radius: 0px 0px 5px 5px;
      background: var(--fill-fill-3, #E5E5E5);
      padding: 7px;
      text-align: center;

      p {
        color: var(--text-text-3, #1D2129);
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 166.667%;
        text-decoration-line: underline;
        text-transform: capitalize;
      }

    }
    .row-item {
      gap: 11px;
      display: flex;
      align-items: center;
    }
    .list-item {
      gap: 16px;
      display: flex;
      flex-direction: column;
    }
`;


const ServiceItem = ({ data }: { data: IAppointmentServiceItem }) => {
  return (
    <ServiceItemStyled>
      <IconList />
      <Col flex={'auto'}>
        <Text variant='CONTENT_1' mb={0.25} color='text_3'>{data.serviceName}</Text>
        <Text variant='BODY_1' color='text_2'>{data.duration ?? 0}m</Text>
      </Col>
      <Text variant='CAPTION_2' color='text_3'>${data.price ?? 0}</Text>
    </ServiceItemStyled>
  );
};
const IconList = () => <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M4 8H21" stroke="#1D2129" strokeLinecap="round" />
  <path d="M4 12H21" stroke="#1D2129" strokeLinecap="round" />
  <path d="M4 16H12" stroke="#1D2129" strokeLinecap="round" />
</svg>;
const ServiceItemStyled = styled(Row)`
  gap: 10px;
  border-radius: 5px;
  background: var(--blue-headline, #C6E9EF);
  padding: 1rem;
`;
