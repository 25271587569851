import Modal from 'components/Modal';
import Text from 'components/Text';
import GroupButtonFooterModal, { BtnProps } from 'features/payment/components/GroupButtonFooterModal';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import ReactSignatureCanvas from 'react-signature-canvas';
import { styled } from 'styled-components';

interface ISignature {
  visible?: boolean;
  onClose?: () => void;
  onAgree: (val: string) => void;
}

export interface ISignatureRef {
  open: () => void;
  close: () => void;
}

const Signature = forwardRef<ISignatureRef, ISignature>(({ onAgree, onClose = () => undefined }, ref) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const signRef = useRef<ReactSignatureCanvas>(null);

  const handleClear = () => {
    signRef.current?.clear();
  };
  const handleAgree = () => {
    const val = signRef.current?.toDataURL();
    onAgree(val ?? '');
    onClose();
  };
  const btnProps: BtnProps[] = [
    {
      buttonName: 'Clear',
      onClick: handleClear,
      type: 'DEFAULT',
    },
    {
      buttonName: 'Agree',
      onClick: handleAgree,
      type: 'PRIMARY',
    },
  ];

  useImperativeHandle(ref, () => ({
    open: () => setIsOpenModal(true),
    close: () => setIsOpenModal(false),
  }));

  return (
    <>
      <SendEmailModalStyled
        footer={<GroupButtonFooterModal buttonSequence={btnProps} />}
        modalTitle='CUSTOMER SIGNATURE'
        closable={false}
        visible={isOpenModal}
        onClose={() => undefined}
        width={'auto'}
        noneBodyStyle
        containerPadding={0}
        className='modal-signature'
        footerPadding={1.5}
      >
        <div className="signature-container">
          <div className="signature-area">
            <ReactSignatureCanvas ref={signRef}
              canvasProps={{
                width: 650,
                height: 300,
                className: 'sigCanvas',
              }} />
          </div>
          <Text variant="H8" textAlign='left' ml={2} style={{ width: 100, alignSelf: 'start', transform: 'translateY(-1.5rem)', }} className='prevent-select' >Sign here X</Text>
          <Text mt={0.5} mb={1.5} className="signature-description prevent-select">
            Cardholder agrees to pay the card issuer the amount of service
            above, and it can not dispute or refundable.
          </Text>
        </div>
      </SendEmailModalStyled>
    </>
  );
});
Signature.displayName = 'Signature';
export default Signature;

const SendEmailModalStyled = styled(Modal)`

    .signature-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .signature-area {
      height: 300px;
      flex-shrink: 0;
      border-radius: 0.3125rem;
      background: #fff;
      position: relative;
      width: 786px;
      display: flex;
      align-items: center;
      justify-content: end;
      border-bottom: 1px solid;
    }
    .signature-description {
        color: var(--text-text-2, #767676);
        text-align: center;
        font-family: Open Sans;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
        padding:0 10%;
    }
    .signature-divider {
        width: 100%;
    }
    .ant-modal-footer .pay-modal-group-btn-footer {
      .pay-footer-modal-btn {
        width: 8.5rem;
        flex: unset;
      }
    }
`;
