import NumberKeyBoardButton from 'components/NumberKeyBoardButton';
import { IBillDetailData } from 'features/payment/services/types/bill';
type Props = {
  detailTicket?: IBillDetailData | null,
  onSubmit?: (value: number) => Promise<boolean>;
};
const TipButton = ({ detailTicket, onSubmit }: Props) => {
  return (
    <NumberKeyBoardButton
      btnTitle="Tip"
      ntype="LIGHT_BLUE"
      icon="tip"
      modalTitle="TIP"
      defaultValue={detailTicket?.tip}
      onSubmit={onSubmit}
    />
  );
};

export default TipButton;
