const RefundMoney = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.22906 4.24068C6.02375 2.43164 8.4567 1.24649 11.0045 1.04679C13.4309 0.806625 15.9344 1.42661 17.9731 2.76186C20.4818 4.37904 22.2934 7.05476 22.8168 9.99805C23.2306 12.2222 22.9408 14.5742 21.9763 16.6247C20.9034 18.9336 19.0003 20.8458 16.6927 21.9239C14.958 22.7527 13.0133 23.0843 11.0998 23.0086C11.1037 22.4016 11.1024 21.7947 11.1011 21.1878C12.7026 21.2543 14.3407 21.0546 15.8025 20.3655C18.0815 19.3317 19.9023 17.3347 20.6971 14.9592C21.4594 12.739 21.3223 10.2291 20.3251 8.10547C19.4572 6.23507 17.9314 4.68316 16.0884 3.76166C14.1292 2.80363 11.8242 2.53997 9.71102 3.10383C8.12778 3.50062 6.67375 4.35293 5.50949 5.4937C6.10859 6.15937 6.76773 6.76891 7.39816 7.40325C5.56953 7.71259 3.73829 8.00757 1.90967 8.32083C2.12112 6.39039 2.30907 4.45866 2.5179 2.52692C3.08176 3.10513 3.66129 3.66769 4.22906 4.24068Z"
        fill="#1D2129"
      />
      <path
        d="M11.0983 6.50391C11.7 6.51043 12.3004 6.51043 12.9022 6.50391C12.8982 6.85893 12.8982 7.21526 12.8956 7.57028C13.9633 7.95532 14.7373 9.00342 14.7504 10.1442C14.15 10.1403 13.5509 10.1416 12.9518 10.1429C12.88 9.79178 12.7155 9.41587 12.3448 9.2971C11.7496 9.0243 11.0135 9.56728 11.0631 10.2095C11.0944 10.4 11.2693 10.5175 11.409 10.6323C12.1595 11.1662 13.0627 11.4507 13.7871 12.0276C14.394 12.4884 14.8039 13.2454 14.7308 14.0194C14.6825 15.0988 13.8941 16.053 12.8969 16.4223C12.8982 16.7774 12.8982 17.1324 12.9022 17.4887C12.3004 17.4822 11.7 17.4822 11.0983 17.4887C11.1022 17.1324 11.1022 16.7774 11.1035 16.4223C10.0359 16.0412 9.25404 14.9918 9.25012 13.8484C9.84792 13.8523 10.4457 13.851 11.0435 13.8484C11.1166 14.1956 11.2771 14.5702 11.6413 14.6916C12.2717 15.0192 13.1697 14.2687 12.863 13.6135C12.0629 12.876 10.9195 12.6489 10.1011 11.9271C9.54119 11.4533 9.20444 10.7211 9.26579 9.98365C9.30494 8.89248 10.1064 7.9488 11.1049 7.56897C11.1022 7.21395 11.1022 6.85893 11.0983 6.50391Z"
        fill="#1D2129"
      />
      <path
        d="M1.5285 11.1616C2.21766 10.8366 3.04648 11.5662 2.76977 12.2854C2.58965 12.9354 1.63552 13.1207 1.21916 12.596C0.81584 12.1601 0.98813 11.3978 1.5285 11.1616Z"
        fill="#1D2129"
      />
      <path
        d="M2.56359 14.9562C2.94733 14.9249 3.36761 15.1311 3.50466 15.5084C3.78137 16.0631 3.27886 16.7966 2.66801 16.7679C2.22031 16.7783 1.82352 16.4077 1.75174 15.9743C1.70475 15.4888 2.07804 15.0163 2.56359 14.9562Z"
        fill="#1D2129"
      />
      <path
        d="M4.55126 18.2799C5.2339 17.9823 6.034 18.7641 5.72205 19.4455C5.54063 20.0707 4.64263 20.2677 4.20538 19.7939C3.73027 19.3619 3.91823 18.447 4.55126 18.2799Z"
        fill="#1D2129"
      />
      <path
        d="M7.81845 20.4764C8.45671 20.1879 9.26856 20.8954 9.01404 21.5649C8.8535 22.1928 7.9568 22.4903 7.50519 21.9787C7.01704 21.5493 7.22196 20.6891 7.81845 20.4764Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default RefundMoney;
