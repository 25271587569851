import { Button } from 'antd';
import Icon from 'assets/Icons';
import appointmentActions from 'features/appointment/services/actions';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const Arrows = () => {
  const dispatch = useAppDispatch();
  const viewType = appointmentSelectors.getViewType();
  const handleClickPrev = () => dispatch(appointmentActions.decreaseStaffPage());
  const handleClickNext = () => dispatch(appointmentActions.increaseStaffPage());


  if (viewType === CALENDAR_VIEW_TYPE.MONTH_VIEW) return null;
  return (
    <IconButtonContainer>
      <IconWrapper>
        <IconButton className='arrow-btn' onClick={handleClickPrev}>
          <Icon type="back" width="32" height="32"></Icon>
        </IconButton>
        <IconButton className='arrow-btn' onClick={handleClickNext}>
          <Icon type="next" width="32" height="32"></Icon>
        </IconButton>
      </IconWrapper>
    </IconButtonContainer>
  );
};

export default Arrows;


const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; // Default for small screens
  @media (min-width: 1200px) {
    // This is typically the breakpoint for large screens, but you can adjust.
    justify-content: flex-end;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const IconButton = styled(Button)`
  background-color: white;
  border-radius: 50%;
  border: 1px solid black;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
