import { Form, Input, Modal as ModalAntd, Upload } from 'antd';
import { UploadFile } from 'antd/lib';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Modal from 'components/Modal';
import { IProduct } from 'features/settingService/services/types/reducer';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';

type IModalAddNewProduct = {
    isShowModal: boolean,
    onClose: () => void,
    onSubmit: (data: IProduct) => void,
    data?: IProduct
};
interface File extends UploadFile {
}
const ModalAddNewProduct: React.FC<IModalAddNewProduct> = ({ isShowModal, onClose, onSubmit, data }) => {
    const [form] = Form.useForm<IProduct>();
    const [fileList, setFileList] = useState<File[]>([]);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    useEffect(() => {
        if (data?.image || data?.urlImage) {
            setFileList([{
                uid: uniqueId(),
                name: data?.productName || 'image_preview',
                status: 'done',
                url: data?.image ? 'data:image/png;base64,' + data.image : data?.urlImage ? data.urlImage : undefined,
            }]);
        } else {
            setFileList([]);
        }
    }, [data]);

    const handleSubmitModal = async () => {
        try {
            const values = await form.validateFields();
            const isOldImage = fileList?.[0]?.url?.toString() == data?.urlImage;
            const body = {
                id: data?.id ?? '',
                idTemp: data?.idTemp ?? '',
                productName: values.productName,
                priceSell: values.priceSell || 0,
                unit: values.unit || 0,
                commission: values.commission || 0,
                orderNumber: values.orderNumber || 0,
                image: isOldImage ? undefined : removeBase64Prefix(fileList?.[0]?.url?.toString())
            };
            onSubmit(body);
            form.resetFields();
            onClose();
        } catch (errorInfo) { }
    };
    const onFinish = () => {
        handleSubmitModal();
    };
    const getBase64 = (file: Blob) => {
        const reader = new FileReader();
        let isLoadedSuccess = false;
        reader.readAsDataURL(file);
        reader.onload = () => {
            // setImgProductBs64(reader.result);
            const imgBase64 = reader.result;
            setFileList([{
                uid: uniqueId(),
                name: 'image_preview',
                status: 'done',
                url: imgBase64?.toString() || '',

            }]);
            isLoadedSuccess = true;
        };
        reader.onerror = () => {
            setFileList([]);
            isLoadedSuccess = false;
        };
        return isLoadedSuccess;
    };
    return (
        <Modal
            modalTitle="New Product"
            visible={isShowModal}
            onClose={onClose}
            onSubmit={handleSubmitModal}
            destroyOnClose={true}
            okTitle="Save"
            cancelTitle="Close"
        >
            <Form
                initialValues={data}
                form={form}
                onFinish={onFinish}
                layout={'vertical'}
            >
                <Box p="4">
                    <Form.Item label="Image" name="image" valuePropName='fileList'
                        rules={[{ required: fileList.length == 0, message: 'Missing Image' }]}
                    >
                        <UploadImageBox selected={fileList.length > 0}>
                            <Upload
                                fileList={fileList}
                                listType="picture-card"
                                maxCount={1}
                                beforeUpload={file => {
                                    return getBase64(file);
                                }}
                                onRemove={() => {
                                    form.setFieldValue('image', []);
                                    setFileList([]);
                                }}
                                onPreview={() => setPreviewOpen(true)}
                            >
                                <div>
                                    <Icon type='imageThumb' />
                                </div>
                            </Upload>
                            <ModalAntd open={previewOpen} title="" footer={null} onCancel={() => setPreviewOpen(false)} width={526}>
                                <ImagePreview alt="preview" src={fileList?.[0]?.url} />
                            </ModalAntd>
                        </UploadImageBox>
                        {/* } */}
                    </Form.Item>
                    <Form.Item
                        label="Ordinal Number"
                        name="orderNumber"
                        rules={[{ required: true, message: 'Missing ordinal number' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label="Product Name"
                        name="productName"
                        rules={[{ required: true, message: 'Missing product name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item label="Unit" name="unit">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Commission Price" name="commission">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Sell Price" name="priceSell">
                        <Input />
                    </Form.Item>
                </Box>
            </Form>
        </Modal >
    );
};

export default ModalAddNewProduct;
const UploadImageBox = styled.div<{ selected?: boolean }>`
     > span{
        display: flex !important;
        justify-content: center !important;
        div > div.ant-upload.ant-upload-select {
        display: ${props => props?.selected ? 'none' : 'flex'} !important;
        }
        div > div > span {
            width: 100%;
        }
    }

`;

const ImagePreview = styled.img`
    width: 400px;
    object-fit: contain;
    object-position: center;
`;
