import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RefreshPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const screen = location.state?.screen;
    navigate(screen, { replace: true });
  }, [location]);
  return null;
};

export default RefreshPage;
