import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER, PAYMENT_TYPE } from './constants';
import { IState } from './types/reducer';
import { cloneDeep, set } from 'lodash';

const initialState: IState = {
  data: null,
  isSentPayInfo: false,
  staffWorkList: [],
  payment: {
    [PAYMENT_TYPE.LOYALTY_POINT]: null,
    [PAYMENT_TYPE.GIFT_CARD]: null,
    [PAYMENT_TYPE.CHECK]: null,
    [PAYMENT_TYPE.CASH]: null,
    [PAYMENT_TYPE.CREDIT_CARD]: null,
  },
  visibleShareTip: false,
  signatureValue: null,
  activePaymentType: null,
  infoPrintWaiting: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setSignatureValue, (state, { payload }) => {
        state.signatureValue = payload;
      })
      .addCase(actions.getInfoPrintWaiting.success, (state, { payload }) => {
        state.infoPrintWaiting = payload;
      })
      .addCase(actions.setActivePaymentType, (state, { payload }) => {
        state.activePaymentType = payload;
      })
      .addCase(actions.resetPaymentAmount, (state) => {
        state.payment = {
          [PAYMENT_TYPE.LOYALTY_POINT]: null,
          [PAYMENT_TYPE.GIFT_CARD]: null,
          [PAYMENT_TYPE.CHECK]: null,
          [PAYMENT_TYPE.CASH]: null,
          [PAYMENT_TYPE.CREDIT_CARD]: null,
        };
      })
      .addCase(actions.getPrintBillDetail.success, (state, { payload }) => {
        state.data = payload;
        const staffLength = state.data?.items.length ?? 0;
        const tip = state.data?.tip ?? 0;
        if (staffLength > 1 && tip > 0) {
          state.visibleShareTip = true;
        } else
          state.visibleShareTip = false;
      })
      .addCase(actions.setVisibleShareTip, (state, { payload }) => {
        state.visibleShareTip = payload;
      })
      .addCase(actions.updateCreditCardMethod, (state, { payload }) => {
        set(state.payment, [PAYMENT_TYPE.CREDIT_CARD, 'creditMethod'], payload);
      })
      .addCase(actions.updateCardAmount, (state, { payload }) => {
        set(state.payment, [PAYMENT_TYPE.CREDIT_CARD, 'amount'], payload);
      })
      .addCase(actions.updateCashAmount, (state, { payload }) => {
        set(state.payment, [PAYMENT_TYPE.CASH, 'amount'], payload);
        set(state.payment, [PAYMENT_TYPE.CASH, 'chance'], (state.data?.total ?? 0) - (payload ?? 0));
      })
      .addCase(actions.getBillDetail.success, (state, { payload }) => {
        state.data = payload;
      })
      .addCase(actions.sendInfoPaySuccess, (state, { payload }) => {
        state.isSentPayInfo = payload;
      })
      .addCase(actions.initSentInfoPay, (state) => {
        state.isSentPayInfo = cloneDeep(initialState['isSentPayInfo']);
      })
      .addCase(actions.setStaffWorkList, (state, { payload }) => {
        state.staffWorkList = payload;
      })
      ;
  },
});

const paymentServiceReducer = Slice.reducer;
export default paymentServiceReducer;
