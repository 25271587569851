import { Avatar, Col, Progress, Row, Select, Space, Tooltip } from 'antd';
import Icon from 'assets/Icons';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import turnActions from 'features/turn/services/actions';
import turnSelectors from 'features/turn/services/selectors';
import { IService } from 'features/turn/services/types';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useNavigate } from 'react-router-dom';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import storage from 'utils/sessionStorage';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import { TagBgColor, TurnStatus, TurnType } from '../constanst';
import { formatCurrency } from 'utils/formatCurrency';
dayjs.extend(customParseFormat);

const { Option } = Select;
type IModalDetail = {
    isShow: boolean;
    onClose: () => void;
};
const ModalDetail: React.FC<IModalDetail> = ({ isShow, onClose }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [selectedStaff, setSelectedStaff] = useState<string>();
    const [selectedTurn, setSelectedTurn] = useState<number>();
    const [selectedType, setSelectedType] = useState<string>();
    const [showPasswordWithStatus, setShowPassword] = useState<string | null>(null);
    const staffData = turnSelectors.getTurnStaffsAvailable();
    const turnDetail = turnSelectors.getTurnDetail();
    useEffect(() => {
        if (turnDetail && isShow) {
            setSelectedStaff(turnDetail.staffId);
            setSelectedTurn(turnDetail.turn);
            setSelectedType(turnDetail.type);
        }
    }, [turnDetail]);
    const onChangeSelectStaff = (value: any) => {
        setSelectedStaff(value);
    };
    const onChangeSelectTurn = (value: any) => {
        setSelectedTurn(value);
    };
    const onChangeSelectType = (value: any) => {
        setSelectedType(value);
    };
    const renderProgress = (status: string, value?: number) => {
        switch (status) {
            case TurnStatus.DONE:
                return <Icon type="turnFinish" width={85} height={85} />;
            case TurnStatus.VOIDED:
                return <Progress type="circle" showInfo={false} percent={100} size={85} strokeColor='#FEEDBF' />;
            case TurnStatus.FINISH_TURN:
                return <Progress type="circle" showInfo={false} percent={100} size={85} strokeColor='#FF7D00' />;
            case TurnStatus.IN_PROGRESS:
            default:
                return <Progress type="circle" showInfo={false} percent={value || 95} size={85} strokeColor='#FF8890' />;

        }
    };
    const renderStatusBar = (status: string) => {
        switch (status) {

            case TurnStatus.DONE:
                return <StatusBarCompleted>
                    <Text variant='H5'>Completed Ticket</Text>
                </StatusBarCompleted>;
            case TurnStatus.VOIDED:
                return <StatusBarVoided>
                    <Text variant='H5'>VOIDED</Text>
                </StatusBarVoided>;
            case TurnStatus.FINISH_TURN:
                return <StatusBarPending>
                    <Text variant='H5'>Finished Turn</Text>
                </StatusBarPending>;
            case TurnStatus.IN_PROGRESS:
            default:
                return <StatusBarProgress>
                    <Text variant='H5'>Working In Progress ...</Text>
                </StatusBarProgress>;

        }
    };
    const onPay = () => {
        onClose();
        navigate(`/store/${storage.shop_id.get()}/ticket/edit/${turnDetail.billId}`);
    };
    const onOK = (pinCode?: string) => {
        if (!pinCode) return;
        setShowPassword(null);

        const params = {
            staffId: selectedStaff || '',
            turnId: turnDetail.turnId,
            turn: selectedTurn == 1 ? 'FULL' : 'HALF',
            type: selectedType || '',
            // turnStatus: isFinishTurn.current ? TurnStatus.FINISH_TURN : TurnStatus.IN_PROGRESS,
            turnStatus: showPasswordWithStatus || '',
            pinCode
        };
        dispatch(turnActions.updateTurnDetail.fetch(params));
    };
    const renderFooterButton = (status: string) => {
        switch (status) {
            case TurnStatus.VOIDED:
                return <ButtonFull><Button ntype="SECONDARY" onClick={onClose}>Close</Button></ButtonFull>;
            case TurnStatus.DONE:
                return (
                    <>
                        <ButtonFull><Button ntype="SECONDARY" onClick={onClose}>Close</Button></ButtonFull>
                        <ButtonFull><Button ntype="PRIMARY" onClick={() => setShowPassword(TurnStatus.DONE)}>Update</Button></ButtonFull>
                    </>
                );

            case TurnStatus.FINISH_TURN:
                return <>
                    <ButtonGroup>
                        <Button ntype="SECONDARY" onClick={onClose}>Close</Button>
                        <Button ntype="PRIMARY" onClick={onPay}>Pay</Button>
                    </ButtonGroup>
                </>;
            case TurnStatus.IN_PROGRESS:
            default:
                return <>
                    <ButtonGroup>
                        <Button ntype="SECONDARY" onClick={onClose}>Close</Button>
                        <Button ntype="LIGHT_BLUE" onClick={() => setShowPassword(TurnStatus.IN_PROGRESS)}>Update</Button>
                        <Button ntype="PRIMARY" onClick={onPay}>Pay</Button>
                        <Button ntype="ORANGE" onClick={() => setShowPassword(TurnStatus.FINISH_TURN)}>Finish Turn</Button>
                    </ButtonGroup>
                </>;
        }

    };
    const getTagColor = (type: string) => {
        switch (type) {
            case TurnType.APPOINTMENT:
                return TagBgColor.APPOINTMENT;
            case TurnType.BONUS:
                return TagBgColor.BONUS;
            case TurnType.REQUEST:
                return TagBgColor.REQUEST;
            case TurnType.WALK_IN:
            default:
                return TagBgColor.WALK_IN;
        }
    };
    const isCanUpdate = turnDetail?.status == TurnStatus.IN_PROGRESS;
    return (
        <>
            <Modal
                modalTitle={turnDetail?.staffName}
                visible={isShow}
                onClose={onClose}
                destroyOnClose={true}
                cancelTitle="Close"
                footer={<>{renderFooterButton(turnDetail?.status)}</>}
                noneBodyStyle
            >
                <Content>
                    {turnDetail?.status === TurnStatus.IN_PROGRESS && <InlineRow>
                        <RowCus><IconCus type="time" width={30} height={30} /> <Text className='text-bolder'>{moment().format('MM-DD-YYYY')}</Text>
                            <Text variant='H9' className='text-bolder'>
                                {turnDetail?.status === TurnStatus.IN_PROGRESS ? (
                                    <Countdown date={moment(turnDetail?.startTime).add(turnDetail?.countDown, 'minute').toISOString()} daysInHours />
                                ) : ('00:00:00')}
                            </Text>
                        </RowCus>
                    </InlineRow>}
                    <InlineRow>
                        <FlexCol>
                            <InlineRow>
                                <RowCus><IconCus type="userSquare" width={30} height={30} /> <Text className='text-bolder'>{turnDetail?.customerName}</Text></RowCus>
                            </InlineRow>
                            <InlineRow>
                                <RowCus>
                                    <IconCus type="ticket" width={30} height={30} /> <Tag variant='H9' color='text_1' background={getTagColor(turnDetail?.type)}>#{turnDetail?.billNumber}</Tag>
                                    <Text variant='CONTENT_2' style={{ fontSize: '1.5rem', fontWeight: 'bolder', marginLeft: '1.5rem' }}>{formatCurrency(turnDetail?.amount)}</Text>
                                </RowCus>
                            </InlineRow>
                        </FlexCol>
                        <ProgressBox>
                            {renderProgress(turnDetail?.status)}
                        </ProgressBox>
                    </InlineRow>
                    <InlineRow>
                        <RowCus><IconCus type="listStatus" width={30} height={30} />
                            {renderStatusBar(turnDetail?.status)}
                        </RowCus>
                    </InlineRow>
                    <InlineRow>
                        <RowCus><IconCus type="item" width={30} height={30} /> <Text className='text-bolder'>Services</Text></RowCus>
                    </InlineRow>
                    <ServicesRow gutter={[24, 12]}>
                        {turnDetail?.services?.filter(o => !!o)?.map((service: IService) => {
                            return (
                                <Tooltip key={service.id} placement="bottom" title={`${service?.serviceName} (${service?.duration} m)`}>
                                    <ServiceCol span={12}>
                                        <ServicesItem className='text-bolder fs'>{service?.serviceName}</ServicesItem>
                                        <ServicesItem className='text-bolder fs'>({service?.duration} m)</ServicesItem>
                                    </ServiceCol>
                                </Tooltip>
                            );
                        })}
                    </ServicesRow>

                    {!(turnDetail?.status == 'PENDING') ? <>
                        <Col>
                            <Label className='text-bolder fs'>Staff</Label>
                            <Select
                                className='selector-cus'
                                style={selectAndDatePickerStyle}
                                placeholder="Staff Name"
                                onChange={onChangeSelectStaff}
                                optionLabelProp="label"
                                size='small'
                                suffixIcon={<Rotage90><Icon type="next" /></Rotage90>}
                                value={selectedStaff}
                                disabled={turnDetail?.status === TurnStatus.DONE ? false : !isCanUpdate}
                            >
                                {staffData.map((item: IStaffAvailableItem) => {
                                    return (
                                        <Option key={item.staffId} value={item.staffId} label={item.staffName}>
                                            <Space>
                                                <ImageContainer>
                                                    <Avatar
                                                        src={item.staffAvatar}
                                                    >{item?.staffName?.charAt(0)}</Avatar>
                                                </ImageContainer>
                                                {item.staffName}
                                            </Space>
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col>
                            <Label className='text-bolder fs'>Turn</Label>
                            <Select
                                className='selector-cus'
                                style={selectAndDatePickerStyle}
                                placeholder="Turn"
                                onChange={onChangeSelectTurn}
                                optionLabelProp="label"
                                size='small'
                                suffixIcon={<Rotage90><Icon type="next" /></Rotage90>}
                                value={selectedTurn}
                                disabled={turnDetail?.status !== TurnStatus.DONE}
                            >
                                <Option key={'full-turn'} value={1} label={'Full Turn'}>
                                    <Space> Full Turn</Space>
                                </Option>
                                <Option key={'half-turn'} value={0.5} label={'Half Turn'}>
                                    <Space> Half Turn</Space>
                                </Option>
                            </Select>
                        </Col>
                        <Col>
                            <Label className='text-bolder fs'>Type</Label>
                            <Select
                                className='selector-cus'
                                style={selectAndDatePickerStyle}
                                placeholder="Type"
                                onChange={onChangeSelectType}
                                optionLabelProp="label"
                                size='small'
                                suffixIcon={<Rotage90><Icon type="next" /></Rotage90>}
                                value={selectedType}
                                disabled={turnDetail?.status !== TurnStatus.DONE}
                            >
                                <Option key={'Walk-In'} value={TurnType.WALK_IN} label={'Walk-In'}>
                                    <Space>Walk-In</Space>
                                </Option>
                                <Option key={'Appointment'} value={TurnType.APPOINTMENT} label={'Appointment'}>
                                    <Space>Appointment</Space>
                                </Option>
                                <Option key={'Bonus'} value={TurnType.BONUS} label={'Bonus'}>
                                    <Space>Bonus</Space>
                                </Option>
                                <Option key={'Request'} value={TurnType.REQUEST} label={'Request'}>
                                    <Space>Request</Space>
                                </Option>
                            </Select>
                        </Col>
                    </>
                        : <></>
                    }
                </Content>
            </Modal>
            {!!showPasswordWithStatus && <RequestAdminLocalPW
                onlyAdmin
                visible
                onCorrect={onOK}
                onClose={() => setShowPassword(null)}
            />}
        </>
    );
};

export default ModalDetail;
const ImageContainer = styled.div`
  min-width: 20px;
  margin-right: 0.5rem;
`;

const selectAndDatePickerStyle = {
    borderRadius: 2,
    // border: '1px solid  #86909C',
    background: '#FFF',
    width: '100%',
};

const Content = styled.div`
 padding: 0.5rem 2rem;
    .text-bolder {
        font-size: 1.5rem; 
        font-weight: bolder;
        &.fs {
            font-size: 1rem;
        }
    }
`;

const InlineRow = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin: 10px 0px;
    .selector-cus {
      border-radius: 2px;
    }
    .staff-date-picker {
      border-radius: 2px;
    }
`;

const Label = styled(Text)`
    padding-top:10px;
    padding-bottom: 5px;
    font-size: 1.5rem; 
    font-weight: bolder;
`;

const FlexCol = styled.div`
    display: flex;
    flex-direction: column;
    flex:1;
`;

const ProgressBox = styled(FlexCol)`
    align-items: center;
    justify-content: center;
`;

const StatusBarProgress = styled.div<{}>`
    border-radius: 5px;
    background: var(--primary-button, #FFE5E7);
    display: flex;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1;
`;
const StatusBarVoided = styled(StatusBarProgress)`
    background: #FEEDBF;
`;
const StatusBarCompleted = styled(StatusBarProgress)`
    background: var(--blue-headline, #C6E9EF);
`;
const StatusBarPending = styled(StatusBarProgress)`
    background: var(--yellow-headline, #FEEDBF);
`;
const IconCus = styled(Icon)`
 padding-right: 1rem;
`;

const Rotage90 = styled.div`
    rotate: 90deg;
`;
const Tag = styled(Text) <{ background?: string }>`
    border-radius: 5px;
    background: ${props => props?.background ? props.background : TagBgColor.WALK_IN};
    text-align: center;
    padding: 5px !important;
`;

const RowCus = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    flex:1;
    div{
        padding-right: 2rem;
    }
`;
const ServicesRow = styled(Row)`
    display: flex;
    flex: 1;
    padding-left: 2.5rem;
`;
const ServiceCol = styled(Col)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const ServicesItem = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 75%;
    text-align: center;
`;

const ButtonGroup = styled(Row)`
    display:flex;
    width:100%;
    justify-content: space-between;
    margin: 20px 0px;
    padding: 0px 20px;
    button {
      width: 30%;
    }
`;

const ButtonFull = styled(Row)`
    display:flex;
    width:100%;
    margin: 20px 0px;
    padding: 0px 20px;
    button {
      width: 100%;
    }
`;