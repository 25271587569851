import { Row } from 'antd';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import Arrows from './Arrows';
import Actions from '../../Actions';
const listTypes = [
  {
    label: 'Appointment',
    fill: '#C6E9EF',
    stroke: '#6FABB6',
    percent: 0.5,
  },
  {
    label: 'Confirm',
    fill: '#BCEBCB',
    stroke: '#87D68D',
    percent: 0.1,
  },
  {
    label: 'Cancel',
    fill: '#FFE5E7',
    stroke: '#FF8890',
    percent: 0.1,
  },
  {
    label: 'No show',
    fill: '#F6F7FC',
    stroke: '#E5E5E5',
    percent: 0.1,
  },
  {
    label: 'On Break',
    fill: '#FEEDBF',
    stroke: '#FFDC7C',
    percent: 0.1,

  },
  {
    label: 'Block Hours',
    fill: '#ADADAD',
    stroke: '#ADADAD',
    percent: 0.1,
  },
];

type IHeadContentProps = any;
const HeadContent: React.FC<IHeadContentProps> = () => {
  return (
    <HeadContentStyled>
      <Row align={'middle'} justify={'space-between'}>
        <Row className='list-type' >
          {listTypes.map((o, i) => (
            <Row key={i} align={'middle'}>
              <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
                <circle cx="4.5" cy="5" r="4" fill={o.fill} stroke={o.stroke} />
              </svg>
              <Text ml={0.25} variant='CONTENT_1'>{o.label}</Text>
            </Row>
          ))}
        </Row>
        <Row style={{ gap: '1rem' }}>
          <Actions />
          <Arrows />
        </Row>
      </Row>
      <Row className='percent-bar' align={'middle'}>
        {listTypes.map((o, i) => (
          <div key={i} className='progress' style={{ width: `${o.percent * 100}%` }} >
            <div className="stroke" style={{ background: o.fill, border: `1px solid ${o.stroke}`, }}>

            </div>
          </div>
        ))}
      </Row>
    </HeadContentStyled>
  );
};

export default HeadContent;
type HeadContentStyledProps = {}
const HeadContentStyled = styled.div<HeadContentStyledProps>`
  display:flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom:1rem;
  .list-type {
    gap:1rem;
  }
  .percent-bar {
    .progress {
      height: 0.5rem;
      .stroke {
        height: 100%;
        width: 100%;
      }
    }
  }
`;
