import { DatePicker as AntDatePickerUI, Row } from 'antd';
import Text from 'components/Text';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import WeeklyPicker from 'features/report/pages/ReportPage/components/WeeklyPicker';
import ticketListSelectors from 'features/ticketList/services/selectors';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { RangeValue } from 'rc-picker/lib/interface';
import React from 'react';
import styled from 'styled-components';

type IDatePickerPeriodProps = {
  onChange: (date: Moment | null | RangeValue<Moment>) => void;
  size?: 'middle' | 'large' | 'small';
  picker?: 'date' | 'week' | 'month';
};
type DatePickerStyledProps = {};

const AntDatePicker =
  AntDatePickerUI.generatePicker<Moment>(momentGenerateConfig);

const DatePickerPeriod: React.FC<IDatePickerPeriodProps> = ({
  onChange,
  size = 'middle',
  picker = 'date',
}) => {
  const ticketParam = ticketListSelectors.ticketsTableData.params();
  const date = moment(ticketParam.date);
  const getDisplayValue = () => {
    if (!date || !date.isValid()) {
      return '';
    }

    if (picker === 'week') {
      return `${ticketParam.startDate} - ${ticketParam.endDate}`;
    }

    if (picker === 'month') {
      return date.format('MM-YYYY');
    }

    return date.format(DATE_FORMAT);
  };

  return (
    <DatePickerPeriodStyled>
      {picker == 'week' ? (
        <WeeklyPicker
          onSubmit={onChange}
          defaultValue={[
            moment(ticketParam.startDate),
            moment(ticketParam.endDate),
          ]}
        />
      ) : (
        <DatePickerStyled
          size={size}
          picker={picker}
          value={date && date.isValid() ? date : null}
          onChange={onChange}
          inputReadOnly
          clearIcon={false}
          inputRender={(props: any) => (
            <InputUI {...props} justify={'space-between'} align={'middle'}>
              <Text variant='CONTENT_2' color='text_2' mr={2}>
                Time Period:
              </Text>
              <Text variant='CONTENT_2' color='text_3'>
                {getDisplayValue()}
              </Text>
            </InputUI>
          )}
        />
      )}
    </DatePickerPeriodStyled>
  );
};

export default DatePickerPeriod;
const DatePickerPeriodStyled = styled.div``;

const DatePickerStyled = styled(AntDatePicker)<DatePickerStyledProps>`
  // min-width: 20rem;
  width: 100%;
`;
const InputUI = styled(Row)`
  flex: 1;
  padding: 0.5rem;
  margin-right: 0.5rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;
