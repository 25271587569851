import colorTheme from 'constants/color';
import React from 'react';
import styled from 'styled-components';
interface ILinkProps {
  children?: React.ReactNode;
  url: string;
  color?: keyof typeof colorTheme;
  className?: string;
}
const Link: React.FC<ILinkProps> = (props) => {
  const { color, url, children, className } = props;
  return (
    <LinkStyled className={`${className ?? ''}`} color={color} href={url}>
      {children}
    </LinkStyled>
  );
};

export default Link;
interface LinkStyledProps {
  color?: ILinkProps['color'];
}
const LinkStyled = styled.a<LinkStyledProps>`
    color: ${({ color }) => (color ? colorTheme[color] : 'unset')};
    display: flex;
    align-items: end;
`;
