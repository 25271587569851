import authSelectors from 'features/auth/services/selectors';
import { Navigate } from 'react-router-dom';

type ShieldComponent = ((props: any) => JSX.Element) | React.LazyExoticComponent<(props: any) => JSX.Element>;

const useRefreshToken = () => {
  const shield = (Component: ShieldComponent) => {
    const token = authSelectors.owner.token();
    if (!token) return <Navigate to={'/sign-in'} replace />;
    return <Component />;
  };

  return { shield };
};

export default useRefreshToken;
