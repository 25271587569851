export type IApiGetDemoDataParam = any;

export type ISignUpForm = {
    name: string | null;
    phone: string | null;
    birthday: string | null;
    email: string | null;
}
export const labels = {
    name: 'name',
    phone: 'phone',
    birthday: 'dayOfBirth',
    email: 'email',
};

export type IInputItem = {
    label: keyof typeof labels;
    value: string;
}

export type IStaffQueryParams = {
    textSearch?: string;
    position?: string;
}

export enum ECheckInType {
    WALK_IN = 'WALK_IN',
    APPOINTMENT = 'APPOINTMENT'
}

export type IConfirmCheckInParams = {
    customerId: string,
    checkInType: ECheckInType,
    staffId: string,
    group: number,
    serviceIds: string[]
}