import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';

const PrivateLayout = () => {
  const dispatch = useAppDispatch();
  const { shop_id = '' } = useParams();
  useEffect(() => {
    dispatch(shopActions.get.shopInfo.fetch(shop_id));
  }, []);

  return (
    <div><Outlet /></div>
  );
};

export default PrivateLayout;
