const BackCarousel = () => {
  return (
    <svg
      width="58"
      height="58"
      viewBox="0 0 58 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5">
        <g filter="url(#filter0_di_5944_88790)">
          <circle
            cx="29"
            cy="25"
            r="24"
            transform="rotate(180 29 25)"
            fill="#F6F7FC"
          />
          <circle
            cx="29"
            cy="25"
            r="24"
            transform="rotate(180 29 25)"
            stroke="#86909C"
            strokeWidth="2"
          />
        </g>
        <path
          d="M33.6827 33.9405L25.4915 25.7512L33.6837 17.562C34.0742 17.1715 34.0742 16.54 33.6837 16.1495C33.2933 15.7601 32.6607 15.7601 32.2703 16.1495L23.3723 25.0445C22.9819 25.434 22.9819 26.0666 23.3723 26.456L32.2702 35.3511C32.6606 35.7405 33.2942 35.7405 33.6846 35.3511C34.0732 34.9626 34.0732 34.3299 33.6827 33.9405Z"
          fill="#1D2129"
        />
      </g>
      <defs>
        <filter
          id="filter0_di_5944_88790"
          x="0"
          y="2.14577e-06"
          width="58"
          height="58"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5944_88790"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5944_88790"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_5944_88790"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BackCarousel;
