const RemoveAllIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4793 7.52067C26.1736 8.21489 26.1736 9.34045 25.4793 10.0347L11.0347 24.4793C10.3404 25.1736 9.21489 25.1736 8.52067 24.4793C7.82644 23.7851 7.82644 22.6596 8.52067 21.9653L22.9653 7.52067C23.6596 6.82644 24.7851 6.82644 25.4793 7.52067Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.52067 7.52067C9.21489 6.82644 10.3404 6.82644 11.0347 7.52067L25.4793 21.9653C26.1736 22.6596 26.1736 23.7851 25.4793 24.4793C24.7851 25.1736 23.6596 25.1736 22.9653 24.4793L8.52067 10.0347C7.82644 9.34045 7.82644 8.21489 8.52067 7.52067Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.4793 7.52067C26.1736 8.21489 26.1736 9.34045 25.4793 10.0347L11.0347 24.4793C10.3404 25.1736 9.21489 25.1736 8.52067 24.4793C7.82644 23.7851 7.82644 22.6596 8.52067 21.9653L22.9653 7.52067C23.6596 6.82644 24.7851 6.82644 25.4793 7.52067Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.52067 7.52067C9.21489 6.82644 10.3404 6.82644 11.0347 7.52067L25.4793 21.9653C26.1736 22.6596 26.1736 23.7851 25.4793 24.4793C24.7851 25.1736 23.6596 25.1736 22.9653 24.4793L8.52067 10.0347C7.82644 9.34045 7.82644 8.21489 8.52067 7.52067Z"
        fill="#1D2129"
      />
    </svg>
  );
};
export default RemoveAllIcon;
