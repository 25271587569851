import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';

export const useAppNavigate = () => {
  const shopId = storage.shop_id.get();
  const navigate = useNavigate();

  const appNavigate = (url: string) => {
    const appPath = `/store/${shopId}/${url}`;
    navigate(appPath);
  };

  return {
    appNavigate,
  };
};
