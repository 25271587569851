import { daysOfWeek } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { ICalendarViewMonthColItem, ICalendarViewMonthRowItem } from 'features/appointment/services/types/calendar';
import getRangeDates from 'features/appointment/utils/getRangeDates';
import _ from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import { momentTimezone } from 'utils/time';

const useMappingDataMonthView = () => {
  const data = appointmentSelectors.getCalendarItems();
  const params = appointmentSelectors.getParams();
  const currentMonth = useMemo(() => {
    return momentTimezone(params.startTime).get('month');
  }, [params]);

  const arrays: ICalendarViewMonthRowItem[] = useMemo(() => {
    const endDate = moment(params.endTime);
    const now = endDate.clone();
    const startDate = now.startOf('month').isoWeekday() !== 1 ? now.startOf('month').clone().subtract(now.startOf('month').isoWeekday() - 1, 'day') : now.startOf('month');
    const days = getRangeDates(startDate.format(), endDate.format());

    return Array.from(Array(days.length > 35 ? 6 : 5).keys()).map(o => {
      const page = o + 1;
      const limit = daysOfWeek.length;
      const list: ICalendarViewMonthColItem[] = _(days)
        .drop((page - 1) * limit)
        .take(limit)
        .value().map(o => ({
          ...o,
          data: data.filter(it => momentTimezone(it.startTime).isSame(moment(o.value), 'day')),
        }));

      if (list.length === 7) return { id: page + 'calendarRow', colData: list };
      return { id: page + 'calendarRow', colData: _.assign(_.fill(new Array(7), null), list) };
    });
  }, [data, params]);

  return { data: arrays, currentMonth };
};

export default useMappingDataMonthView;
