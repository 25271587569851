
import { DatePicker as DatePickerAnt } from 'antd';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import type { Moment as MomentType } from 'moment';
import moment from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { useMemo, useState } from 'react';
import { styled } from 'styled-components';
const DatePickerAntUI = DatePickerAnt.generatePicker<MomentType>(momentGenerateConfig);


export type DatePickerLabelProps = {
  value?: string | null;
  onChange: (value: string | null) => void;
};
const DatePickerLabel = ({ value, onChange = () => undefined }: DatePickerLabelProps) => {
  const [open, setOpen] = useState(false);
  const date = useMemo(() => value ? moment(value) : null, [value]);
  return (
    <DatePickerAntUI
      format={DATE_FORMAT}
      style={{
        height: 'auto',
        width: 'auto',
        border: 'none',
        padding: 0,
      }}
      open={open}
      onOpenChange={setOpen}
      value={date}
      onChange={(e, str) => onChange(str)}
      showNow={false}
      suffixIcon={null}
      clearIcon={false}
      inputRender={() => (
        <DatePickerLabelStyled onClick={() => setOpen(true)}>
          {date?.format(DATE_FORMAT) ?? ''}
        </DatePickerLabelStyled>
      )
      }
    />
  );
};

export default DatePickerLabel;
const DatePickerLabelStyled = styled.button`
  color: var(--info-infor-3, #6FABB6);
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-thickness: from-font;
`;
