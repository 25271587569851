import { Rate } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import paymentApis from 'features/payment/services/apis';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';

const CustomerRatingPage = () => {
  const { ticket_id = '' } = useParams();
  const [rating, setRating] = useState<number>();
  const setLoadingPage = useSetLoadingPage();

  const navigate = useNavigate();

  const handleDone = async () => {
    if (!ticket_id || !rating) return;
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.makeRating(ticket_id, rating);
      if (res.data.data) {
        navigate(`/store/${storage.shop_id.get()}/check-in/sign-in`);
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };
  return (
    <CustomerRatingPageStyled>
      <div className="box">
        <Rate onChange={setRating} value={rating} />
        <Text mt={3} mb={2} className="divider" />
        <Text className='title'>THANK YOU</Text>
        <Text className='sub-title'>FOR YOUR BUSINESS</Text>
        <Text px={0.5} mb={4} className='sub-text'>
          How satisfied are you with your visit today?
        </Text>
        <Button width='50%' ntype='PRIMARY' onClick={handleDone} disabled={!rating} >DONE</Button>
      </div>
      <CountDown />
    </CustomerRatingPageStyled>
  );
};

export default CustomerRatingPage;

const CountDown = () => {
  const [count, setCount] = useState(10);
  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    navigate(`/store/${storage.shop_id.get()}/check-in/sign-in`);
  }, []);

  useEffect(() => {
    if (count === 0) {
      handleNavigate();
    } else {
      const interval = setInterval(() => {
        setCount((previous) => previous - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [count]);

  return null;
};

const CustomerRatingPageStyled = styled.div`
  background: var(--fill-fill-3, #E5E5E5);
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background: #FFF;
    padding: 3rem;
  }
  .ant-rate {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 6.5rem;
    padding: 0 1.5rem;
  }
  .divider {
    background: #86909C;
    height: 1px;
    width: 100%;
  }
  .title {
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 3rem;
    font-style: normal;
    font-weight: bolder;
    line-height: normal;
  }
  .sub-title {
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .sub-text {
    color: var(--text-text-2, #767676);
    text-align: center;
    font-family: Open Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;