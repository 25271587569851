import { Col, Form, Input, Row, Select } from 'antd';
import { Rule } from 'antd/es/form';
import { RcFile } from 'antd/es/upload';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import InputDOB from 'components/InputDOB';
import InputEmail from 'components/InputEmail';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import {
  INewStaffPayload,
  IStaffPayload,
} from 'features/checkIn/services/types/demoData';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingApis from 'features/settingStaff/services/apis';
import { positionsArray } from 'features/settingStaff/services/constants';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import { getBase64 } from 'utils/getBase64';
import storage from 'utils/sessionStorage';
import AddButton from './components/AddButton';
import UploadAddStaff from './components/UploadAddStaff';
type IAddStaffProps = {};

const AddStaff: React.FC<IAddStaffProps> = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const setLoadingPage = useSetLoadingPage();

  const dispatch = useAppDispatch();
  const formItems = [
    {
      span: 8,
      name: 'firstName',
      label: 'First Name',
      placeholder: 'First Name',
      rules: [
        { required: true, message: 'Please input your first name!' },
      ] as Rule[],
    },
    {
      span: 8,
      name: 'lastName',
      label: 'Last Name',
      placeholder: 'Last Name',
    },
    {
      span: 8,
      name: 'email',
      label: 'Email (Optional)',
      rules: [
        { type: 'email', message: 'Please enter a valid email!' },
      ] as Rule[],
    },
    {
      span: 8,
      name: 'phone',
      label: 'Phone',
      // rules: [
      //   {
      //     validator: validatePhoneNumber,
      //     message: 'Please follow the required phone format: (098) 76-5432',
      //   },
      // ] as Rule[],
      component: (
        <Input
          style={{ height: '3.5rem' }}
          placeholder="Enter your phone number"
          onChange={(e) => {
            const formattedValue = formatPhone(e.target.value);
            form.setFieldsValue({ phone: formattedValue });
          }}
        />
      ),
    },
    {
      span: 8,
      name: 'dob',
      label: 'DOB',
      component: <InputDOB style={{ height: '3.5rem' }} />,
    },
    {
      span: 8,
      name: 'staffType',
      label: 'Position',
      component: (
        <Select placeholder="Select">
          {positionsArray.map((item) => (
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      ),
      rules: [
        { required: true, message: 'Please select a position!' },
      ] as Rule[],
    },
    {
      span: 6,
      name: 'street',
      label: 'Street',
      placeholder: 'Street',
    },
    {
      span: 6,
      name: 'city',
      label: 'City',
      placeholder: 'City',
    },
    {
      span: 6,
      name: 'state',
      label: 'State',
      placeholder: 'State',
    },
    {
      span: 6,
      name: 'zip',
      label: 'Zip Code',
      placeholder: '0',
    },
  ];

  const handleReset = () => {
    form.resetFields();
  };
  const handleBack = () => {
    navigate(-1);
  };
  const onFinish = async (values: INewStaffPayload) => {
    let _avatar = '';

    if (values?.avatar) {
      const base64Url = await getBase64(
        values.avatar[0]?.originFileObj as RcFile
      );
      const avatarSubmit = base64Url.replace(/^data:image\/[^;]+;base64,/g, '');
      _avatar = avatarSubmit;
    }

    const body: IStaffPayload = {
      avatar: _avatar,
      staffId: '',
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      staffType: values.staffType,
      // gender: values.gender,
      dob: values.dob ? moment(values.dob).format('MM-DD-YYYY') : '',
      street: values.street || '',
      city: values.city || '',
      state: values.state || '',
      zip: values.zip || '',
      status: values.status,
    };
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.createOrUpdateStaff(body);
      if (res.data.data) {
        dispatch(settingStaffActions.createOrUpdateStaff.success(res.data.data));
        navigate(`/store/${storage.shop_id.get()}/setting-staff`);
      }
    } catch (error) {
      dispatch(settingStaffActions.createOrUpdateStaff.fail({}));
    } finally {
      setLoadingPage(false);
    }
  };

  return (
    <AddStaffStyled>
      <Sidebar />
      <BodyStyled>
        <Box display="flex" gap="3" p="4" alignItems="center">
          <BackButtonStyled onClick={handleBack}>
            <Icon type="back" />
          </BackButtonStyled>
          <Text variant="H5" color="text_3">
            Add Staff
          </Text>
        </Box>

        <Box display="flex" gap="3" p="4" alignItems="center">
          <Form form={form} onFinish={onFinish} layout="vertical">
            <Box>
              <Box className="center">
                <UploadAddStaff />
              </Box>
              <Box>
                <Row gutter={[16, 16]}>
                  {formItems.map((item) => (
                    <Col key={item.name} span={item.span}>
                      {item.name === 'email' ? (
                        <InputEmail
                          name={item.name}
                          label={item.label}
                          rules={item.rules}
                        />
                      ) : (
                        <Form.Item
                          name={item.name}
                          label={item.label}
                          rules={item.rules} // Add the rules prop here
                        >
                          {item.component || (
                            <Input
                              placeholder={item.placeholder}
                              style={{ height: '3.5rem' }}
                            />
                          )}
                        </Form.Item>
                      )}
                    </Col>
                  ))}
                </Row>
              </Box>
            </Box>
            <Box className="center" gap="4" mt="4">
              <Box width="27.5rem">
                <Button ntype="SECONDARY" block onClick={handleReset}>
                  Reset
                </Button>
              </Box>
              <Box width="27.5rem">
                <AddButton />
              </Box>
            </Box>
          </Form>
        </Box>
      </BodyStyled>
    </AddStaffStyled>
  );
};

export default AddStaff;
const AddStaffStyled = styled.div`
  display: flex;
  .ant-form-item {
    margin: 0;
  }
`;
const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  max-width: calc(100vw - 6.5rem);
  background: var(--fill-fill-3, #e5e5e5);
`;
const BackButtonStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-5, #e5e6eb);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
