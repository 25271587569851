import { Input } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import MultipleSelect from 'components/MultipleSelect';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import {
  optionsPoint,
  optionsSpendingAmount,
  optionsStatus,
} from 'features/customer/services/constants';
import customerSelectors, {
  getPointByKey,
  getSpendingAmountByKey,
} from 'features/customer/services/selectors';
import { IGetCustomersParam } from 'features/customer/services/types/api';
import { debounce } from 'lodash';
import React, { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ButtonAddCustomer from 'widgets/Customer/AddCustomer';
import SelectFromToOptions from './SelectFromToOptions';
type ICustomersHeaderProps = {};
// type ChangeDisplayTypeIconProps = {
//   disabled?: boolean;
// };
const CustomersHeader: React.FC<ICustomersHeaderProps> = () => {
  const totalElement = customerSelectors.customersTableData.totalElement();
  const dispatch = useAppDispatch();
  // const isWindows = customerSelectors.getIsWindowDisplay();
  const paramStatus = customerSelectors.paramStatus();
  // const handleChangeDisplayType = () => {
  //   dispatch(customerActions.handleChangeDisplayType());
  // };

  const handleChangeSpendingAmount = (value: string) => {
    const getData: IGetCustomersParam = getSpendingAmountByKey(value);
    dispatch(customerActions.setCustomerParam(getData));
  };
  const handleChangePoint = (value: string) => {
    const getData: IGetCustomersParam = getPointByKey(value);
    dispatch(customerActions.setCustomerParam(getData));
  };
  const handleChangeStatus = (value: string[]) => {
    const param: IGetCustomersParam = { types: value.join(',') };
    dispatch(customerActions.setCustomerParam(param));
  };

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      const param: IGetCustomersParam = { lastPhoneNumber: value };
      dispatch(customerActions.setCustomerParam(param));
    }, 300),
    []
  );

  const handleChangeLastNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    debouncedSearch(newValue || '');
  };

  return (
    <CustomersHeaderStyled>
      <Box display="flex" gap="2" alignItems="center" pb="4" px="4">
        <Text variant="H5" style={{ flex: 1, flexGrow: 1 }}>
          Customers ({totalElement})
        </Text>
        {/* <ChangeDisplayTypeIcon onClick={handleChangeDisplayType} disabled={true}>
          <Icon type={isWindows ? 'windowsActive' : 'windows'}></Icon>
        </ChangeDisplayTypeIcon>
        <ChangeDisplayTypeIcon onClick={handleChangeDisplayType}>
          <Icon type={!isWindows ? 'lineListActive' : 'lineList'}></Icon>
        </ChangeDisplayTypeIcon> */}
        <Box width={'13.5rem'}>
          <SelectFromToOptions
            placeholder="Spending Amount"
            onChange={handleChangeSpendingAmount}
            options={optionsSpendingAmount}
          />
        </Box>
        <Box width={'10rem'}>
          <SelectFromToOptions
            placeholder="Point"
            onChange={handleChangePoint}
            options={optionsPoint}
          />
        </Box>
        <Box width={'10rem'}>
          <MultipleSelect
            placeholder="Status"
            width="100%"
            height="3.5rem"
            value={paramStatus}
            onChange={handleChangeStatus}
            options={optionsStatus}
          />
        </Box>
        <Box width={'16.5rem'}>
          <InputSearch
            placeholder="Last 4 number / Name"
            size="middle"
            suffix={<Icon className="search-icon" type="search" />}
            onChange={handleChangeLastNumber}
          />
        </Box>
        <Box className="center" gap="3">
          <ButtonAddCustomer model="new-ticket" />
        </Box>
      </Box>
    </CustomersHeaderStyled>
  );
};

export default CustomersHeader;
const CustomersHeaderStyled = styled.div`
  padding-top: 1.5rem;
`;
// const ChangeDisplayTypeIcon = styled.div<ChangeDisplayTypeIconProps>`
//   cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

//   // Optionally, you can add other styles for the disabled state:
//   opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
//   pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
// `;

const InputSearch = styled(Input)`
  height: 3.5rem;
  .ant-input-suffix {
    opacity: 0.5;
  }
`;
