import React from 'react';
import PrintHeader from '../PrintPage/components/PrintHeader';
import TipBillPageStyled from './style';
import Button from 'components/Button';
import styled from 'styled-components';
import paymentSelectors from 'features/payment/services/selectors';
import TipBillCoupon from '../PrintPage/components/TipBillCoupon';

type IPrintPageProps = any;
const TipBillPrintPage: React.FC<IPrintPageProps> = () => {
  const staffWorkList = paymentSelectors.getStaffWorkList();
  return (
    <TipBillPageStyled>
      {/* absolute */}
      <div className="group-btn-print-tip-bill">
        <Button ntype="SECONDARY" icon="print">
          Print
        </Button>
        <Button ntype="PRIMARY" icon="category">
          Home
        </Button>
      </div>
      <PrintHeader />
      {/* absolute */}

      <TipBillContainerStyled>
        {staffWorkList.map((item) => (
          <TipBillCoupon key={item.staffId} item={item}></TipBillCoupon>
        ))}
      </TipBillContainerStyled>
    </TipBillPageStyled>
  );
};

export default TipBillPrintPage;
const TipBillContainerStyled = styled.div`
  height: calc(100vh - 1.5rem);
  overflow: auto;
  padding: 1.5rem;
  .tip_item_coupon:first-child {
    .tip-border {
      display: none;
    }
  }
`;
