import { Col, Row } from 'antd';
import BoundingBox from 'components/BoundingBox';
import ServiceItem from 'components/ServiceItem';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import ButtonGroupFooter from 'features/checkIn/components/ButtonGroupFooter';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { IServiceCategory } from 'features/cashier/services/types/api';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

interface IServicePartProps {
  color?: keyof typeof colorTheme;
}
const ServicePart: React.FC<IServicePartProps> = () => {
  const dispatch = useAppDispatch();
  const category = checkInSelectors.getActiveCategory();
  const selectedServices = checkInSelectors.getSelectedServices();

  const navigate = useNavigate();
  const { shop_id = '' } = useParams();
  const clickBack = () => {
    navigate(-1);
  };

  const clickNext = () => {
    navigate(`/store/${shop_id}/check-in/staff`);
  };


  const handleClickService = (item: IServiceCategory) => {
    dispatch(checkInActions.chooseService(item));
  };

  return (
    <IServicePartStyled>
      <BoundingBox className="title-bounding-box" color="info_bg_3">
        <Text variant="H7">SERVICES</Text>
      </BoundingBox>
      <div className="row-box">
        <Row gutter={[24, 16]} className="service-grid">
          {category?.services?.map((item) => {
            return (
              <Col key={item.id} span={12}>
                <ServiceItem
                  size="LARGE"
                  price={item.priceSell}
                  service={item.serviceName}
                  onClick={() => handleClickService(item)}
                  selected={!!selectedServices.find(o => o.id === item.id)}
                />
              </Col>
            );
          })}
        </Row>
      </div>
      <ButtonGroupFooter
        clickBack={clickBack}
        clickSkip={clickNext}
        clickNext={clickNext}
        hasNext
        hasSkip
      ></ButtonGroupFooter>
    </IServicePartStyled>
  );
};
interface IServicePartStyled {
  color?: keyof typeof colorTheme;
}
const IServicePartStyled = styled.div<IServicePartStyled>`
  height: 100vh;
  width: 65vw;
  background-color: ${({ color }) => (color ? colorTheme[color] : '#E5E5E5')};
  padding-bottom: 1rem;

  .title-bounding-box {
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: center;
  }
  .title-bounding-box .H7 {
    margin-left: 2.5rem;
  }

  .row-box {
    width: 100%;
    max-height: 72%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
  }
  .service-grid {
    margin: 2rem 2rem;
    width: 98%;
  }
`;
export default ServicePart;
