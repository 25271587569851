import { DownOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Dropdown, InputNumber, MenuProps, Modal as ModalAntd, Row, Select, Space, Upload } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { UploadFile } from 'antd/lib';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { IVIPs } from 'features/settingService/services/types/reducer';
import { uniqueId } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
const { Option } = Select;
export interface IVIPsUpdate extends IVIPs {
    fileList:File[]
}
interface IVIPCard {
    data?: IVIPs;
    returnData?: (data: IVIPsUpdate) => void;
    returnChecked?: (id: string, checked: boolean) => void;
}

interface File extends UploadFile {
}

interface ImperativeHandleRefs {
    resetData: () => void;
}
const VIPCard = forwardRef<ImperativeHandleRefs, IVIPCard>(({ data, returnData = () => undefined , returnChecked = ()=> undefined}, ref) => {
    const [checked, setChecked] = useState<boolean>(false);
    const [fileList, setFileList] = useState<File[]>([]);
    const [yearlyPrice, setYearlyPrice] = useState<number>(0);
    const [discountValue, setDiscountValue] = useState<number>(0);
    const [discountType, setDiscountType] = useState<'MONEY'|'PERCENT' | string>('MONEY');
    const [expiresDate, setExpiresDate] = useState<number|string>(365);
    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const resetData = () => {
        setFileList([]);
        setYearlyPrice(0);
        setDiscountValue(0);
        setDiscountType('MONEY');
        setExpiresDate(365);
    };
    useImperativeHandle(ref, () => {
        return { resetData };
    }, []);
    useEffect(() => {
        if (data) {
            setFileList([{
                uid: uniqueId(),
                name: 'image_preview',
                status: 'done',
                url: data?.image ? 'data:image/png;base64,' + data.image : data?.urlImage ? data.urlImage : undefined,
            }]);
            setYearlyPrice(data.yearlyPrice);
            setDiscountValue(data.discountValue);
            setDiscountType(data.discountType);
            setExpiresDate(data.expiresDate);
        }
    }, [data]);
    useEffect(() => {
        const _returnDate:IVIPsUpdate = {
            id: data?.id,
            fileList, 
            yearlyPrice, 
            expiresDate, 
            discountValue, 
            discountType,
            image: removeBase64Prefix(fileList?.[0]?.url?.toString())
        };
        returnData(_returnDate);
    }, [yearlyPrice, expiresDate, discountValue, discountType, fileList]);
    useEffect(()=> {
        if(data && data.id){
            returnChecked(data.id, checked);
        }
    },[checked]);
    const getBase64 = (file: Blob) => {
        const reader = new FileReader();
        let isLoadedSuccess = false;
        reader.readAsDataURL(file);
        reader.onload = () => {
            // setImgProductBs64(reader.result);
            const imgBase64 = reader.result;
            setFileList([{
                uid: uniqueId(),
                name: 'image_preview',
                status: 'done',
                url: imgBase64?.toString() || '',

            }]);
            isLoadedSuccess = true;
        };
        reader.onerror = () => {
            setFileList([]);
            isLoadedSuccess = false;
        };
        return isLoadedSuccess;
    };
    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
        e.preventDefault();
        setChecked(e.target.checked);
    };
    const handleMenuClick: MenuProps['onClick'] = (e) => {
        setExpiresDate(e.key);
    };
    const menuProps = {
        items,
        onClick: handleMenuClick,
    };
    const onChangeYearPrice = (value: any) => {
        setYearlyPrice(value);
    };
    const onChangeDiscountTicket = (value: any) => {
        setDiscountValue(value);
    };
    const onChangeTypeDiscount = (value: string) => {
        setDiscountType(value);
    };
    const selectAfter = (
        <Select value={discountType} style={{ width: 90, height: '2.5rem' }} onChange={onChangeTypeDiscount}>
            <Option value="MONEY">Dollar</Option>
            <Option value="PERCENT">Percent</Option>
        </Select>
    );
    return (
        <CardStyled>
            {data ? <CheckboxStyled checked={checked} onChange={onChangeCheckbox} /> : <></>}

            <RowCenter>
                <UploadImageBox selected={fileList.length > 0} error={data && fileList.length === 0}>
                    <Upload
                        fileList={fileList}
                        listType="picture-card"
                        maxCount={1}
                        beforeUpload={file => {
                            return getBase64(file);
                        }}
                        onRemove={() => {
                            setFileList([]);
                        }}
                        onPreview={() => setPreviewOpen(true)}
                    >
                        <Col>
                            <Icon type='imageThumb' />
                            <Text>Image Card</Text>
                        </Col>
                    </Upload>
                    <ModalAntd open={previewOpen} title="" footer={null} onCancel={() => setPreviewOpen(false)} width={526}>
                        <ImagePreviewBox>
                            <ImagePreview alt="preview" src={fileList?.[0]?.url} />
                        </ImagePreviewBox>
                    </ModalAntd>
                </UploadImageBox>
            </RowCenter>
            <RowBetween>
                <Text variant='CONTENT_1' color='text_2'>Yearly Price</Text>
                <InputNumberStyled
                    size='small'
                    value={yearlyPrice}
                    formatter={(value: any) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    // eslint-disable-next-line
                    parser={(value: any) => value!.replace(/\$\s?|(,*)/g, '')}
                    onChange={onChangeYearPrice}
                    controls={false}
                />
            </RowBetween>
            <RowBetween>
                <Text variant='CONTENT_1' color='text_2'>Discount Ticket</Text>
                <InputNumberStyled
                    key={data?.id + discountType}
                    size='small'
                    value={discountValue}
                    formatter={(value: any) => `${discountType == 'MONEY' ? '$':'%'} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                   // eslint-disable-next-line
                    parser={(value: any) => discountType == 'MONEY' ? value!.replace(/\$\s?|(,*)/g, '') : value!.replace(/%\s?|(,*)/g, '')}
                    onChange={onChangeDiscountTicket}
                    controls={false}
                    addonAfter={selectAfter}
                />
            </RowBetween>
            <RowBetween>
                <Text variant='CONTENT_1' color='text_2'>Expires Date</Text>
                <DropdownStyled menu={menuProps} trigger={['click']}>
                    <Button>
                        <Space>
                            {/* @ts-ignore */}
                            {items?.find(f => f?.key == expiresDate)?.label ?? ''}
                            <DownOutlined />
                        </Space>
                    </Button>
                </DropdownStyled>
            </RowBetween>
        </CardStyled>
    );
});
export default VIPCard;
VIPCard.displayName = 'VIPCard';

const items: MenuProps['items'] = [
    {
        label: '30 days',
        key: 30,
    },
    {
        label: '60 days',
        key: 60,
    },
    {
        label: '90 days',
        key: 90,
    },
    {
        label: '180 days',
        key: 180,
    },
    {
        label: '365 days',
        key: 365,
    },
    {
        label: 'Unlimit',
        key: -1,
    },
];
const CardStyled = styled(Card)`
    width: 310px;
    height: 435px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--fill-fill-1, #F6F7FC);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    .ant-card-body {
        padding: 0px !important;
        padding-top: 10px !important;
    }
`;
const CheckboxStyled = styled(Checkbox)`
    position: absolute;
    top: 5px;
    left: 20px;
`;
const RowCenter = styled(Row)`
  justify-content: center;
  div{
    margin: 0 !important;
  }
`;
const UploadImageBox = styled.div<{ selected?: boolean, error?: boolean }>`
     span{
        display: flex !important;
        justify-content: center !important;
        div > div.ant-upload.ant-upload-select {
            display: ${props => props?.selected ? 'none' : 'flex'} !important;
            width: 145px!important;
            height: 245px!important;
            border: ${props => props?.error ? '2px dashed red' : '1px dashed #86909C'} !important;
            overflow: hidden;
            > span {
                width: 100%;
            }
        }

        div .ant-upload-list-item-container {
            width: unset!important;
            height: unset!important;
        div {
            width: 145px!important;
            height: 245px!important;
            padding: 0px!important;
            border: unset!important;
            overflow: hidden;
           
        }
        div:before {
            width: 145px!important;
            height: 245px!important;
        }
        .ant-upload-list-item-actions {
            align-items: center !important;
        }
     }
    }

`;
const ImagePreviewBox = styled.div`
    background: white;
`;
const ImagePreview = styled.img`
    width: 400px;
    object-fit: contain;
    object-position: center;
`;
const RowBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px;
    .ant-input-number-input-wrap {
        input{ 
            text-align: left !important;
        }
    }
`;
const InputNumberStyled = styled(InputNumber)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    text-align: center;
    width: 150px;
    div .ant-input-number {
        display: flex;
        align-items: center;
    }
`;

const DropdownStyled = styled(Dropdown)`
    width: 150px;
    height: 2.5rem;
    > div {
        justify-content: space-between;
        width: 100%;
    }
`;
