import React from 'react';
import styled from 'styled-components';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { MenuProps } from 'antd/lib';

type IMoreOutlinedDotProps = {
  onClick?: (value: string) => void;
};

const MoreOutlinedDot: React.FC<IMoreOutlinedDotProps> = ({ onClick }) => {
  const items: MenuProps['items'] = [
    {
      key: 'edit',
      label: 'Edit Name',
    },
    {
      key: 'delete',
      label: 'Delete Category',
    },
    {
      key: 'delete_service',
      label: 'Delete Services',
    },
  ];
  const handleMenuClick = (event: any) => {
    if (onClick) {
      onClick(event.key);
    }
  };

  return (
    <MoreOutlinedDotStyled>
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        dropdownRender={(menu) => (
          <StyledMenuWrapper>
            {React.cloneElement(menu as React.ReactElement, {
              onClick: handleMenuClick,
            })}
          </StyledMenuWrapper>
        )}
      >
        <MoreOutlined style={{ fontSize: '24px' }} />
      </Dropdown>
    </MoreOutlinedDotStyled>
  );
};

export default MoreOutlinedDot;

const MoreOutlinedDotStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledMenuWrapper = styled.div`
  .ant-dropdown-menu-item {
    &:hover {
      background: var(--primary-button, #ffe5e7) !important;
    }
  }
`;
