const QrCode = () => {
  return (
    <svg
      width="57"
      height="57"
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <rect
          x="0.949951"
          y="0.699219"
          width="56"
          height="56"
          rx="5"
          fill="black"
        />
      </g>
    </svg>
  );
};

export default QrCode;
