import Loading from 'components/Loading';
import { debounce } from 'lodash';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingPageStyled } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import storage from 'utils/sessionStorage';
import cashierApis from '../services/apis';

export interface IAddNewResData {
  customerInfo: null;
  checkInId: null;
  ticketInfo: TicketInfo;
  items: any[];
}

export interface TicketInfo {
  billId: string;
  discount: number;
  tip: number;
  saleTax: number;
  useTax: number;
  total: number;
  subTotal: number;
  createdDate: string;
  ticketNumber: number;
}


function AddTicketPage() {
  const navigate = useNavigate();
  const generateTicket = debounce(async () => {
    try {
      const res: IResponseDataBody<IAddNewResData> = await cashierApis.addTicket();
      const data = res.data.data;
      if (data) {
        navigate(`/store/${storage.shop_id.get()}/ticket/edit/${data.ticketInfo.billId}`);
      } else {
        throw 'fail';
      }
    } catch (error) {
      navigate(-1);
    }
  }, 200);

  useEffect(() => {
    generateTicket();
  }, []);

  return <LoadingPageStyled>
    <div className="box">
      <Loading />
    </div>
  </LoadingPageStyled>;
}

export default AddTicketPage;