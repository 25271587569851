import { styled } from 'styled-components';

const PageStyled = styled.div`
    align-self: stretch;
    width: 100%;
    display: flex;
    flex-direction: column;
    .main {
        margin: 4rem auto;
        margin-bottom: 8rem;
        display: flex;
        flex-direction: column;
    }
`;

export default PageStyled;
