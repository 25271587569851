import fetch from 'services/request';
import {
  ICreateAddOnServiceBodyType,
  IEditCateBodyType,
  IGetProductsByCategoryId,
  IUpdateCateBodyForProduct,
  IUpdateLateForWork,
  IDeleteTurnSettingService,
  IUpdateTurnSettingService,
  IUpdateTurnSettingTicket,
  IgetVIPParams,
  IDeleteVIPs,
  IChangeCommissionPrice,
  IDeleteProduct,
  IUpdateQuickPayBody,
  IDayOffParams,
} from './types/api';
import { IBodyItemNotificationPut } from './types/generalSetting';
import {
  IShopDiscountParams,
  ITaxParams,
  IPaymentMethodParams,
} from './types/paymentSetting';
import { IVIPs, IProduct, IGiftCard } from './types/reducer';

const baseURL = 'api/v1';
export const editCategory = (body: IEditCateBodyType) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/update`,
    body,
  });
};
export const createCategory = (body: IEditCateBodyType) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/create`,
    body,
  });
};
export const createOrUpdateServiceAddOn = (
  body: ICreateAddOnServiceBodyType
) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/service/create`,
    body,
  });
};

export const addMoreServiceByCate = (body: IEditCateBodyType) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/update-service-by-category`,
    body,
  });
};

export const getListCategoriesForProduct = (params: {}) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/product/get-all-by-category`,
    params,
  });
};

export const createCategoryForProduct = (body: IUpdateCateBodyForProduct) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/create`,
    body,
  });
};

export const updateNameCategoryForProduct = (
  body: IUpdateCateBodyForProduct
) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/update`,
    body,
  });
};

export const deleteCategoryForProduct = (params: {
  id: string;
  pinCode: string;
}) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/category/delete/${params.id}`,
    pinCode: params.pinCode,
  });
};
export const deleteAddOnService = (params: { id: string; pinCode: string }) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/service/delete/${params.id}`,
    pinCode: params.pinCode,
  });
};

export const getProductsByCategoryId = (request: IGetProductsByCategoryId) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/get`,
    body: request.body,
    params: request.params,
  });
};

export const createProduct = (body: IProduct) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/create`,
    body,
  });
};

export const updateProduct = (body: IProduct) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/update`,
    body,
  });
};

export const deleteProduct = (body: IDeleteProduct) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/delete`,
    body,
  });
};
export const changeCommissionPrice = (body: IChangeCommissionPrice) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/product/change-commission-price`,
    body,
  });
};

export const getVIP = (params: IgetVIPParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/vip/get`,
    params,
  });
};

export const createVIP = (body: IVIPs) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/vip/create`,
    body,
  });
};
export const updateVIP = (body: IVIPs[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/vip/update`,
    body,
  });
};
export const deleteVIP = (body: IDeleteVIPs) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/vip/delete`,
    body,
  });
};
export const getQuickPayList = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/category/list-quick-pay`,
  });
};
export const updateQuickPayList = (body: IUpdateQuickPayBody) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/category/list-quick-pay`,
    body,
  });
};
export const getTurnSetting = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/turn/setting`,
  });
};
export const updateTurnSettingTiket = (body: IUpdateTurnSettingTicket) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/turn/setting/ticket`,
    body,
  });
};
export const updateTurnSettingService = (body: IUpdateTurnSettingService) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/turn/setting/service`,
    body,
  });
};
export const deleteTurnSettingService = (data: IDeleteTurnSettingService) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/turn/setting/service`,
    configs: { data },
  });
};
export const getLateForWork = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/turn/setting/late-for-work`,
  });
};
export const updateLateForWork = (body: IUpdateLateForWork) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/turn/setting/late-for-work`,
    body,
  });
};
// start api for Payment Setting
export const getTax = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/tax-config`,
  });
};
export const getDiscount = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/discount-by-shop`,
  });
};
export const getSpecialDiscount = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/special-discount/list`,
  });
};
export const getLoyaltyReward = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/get-loyalty-program`,
  });
};
export const getOtherPaymentMethod = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/other-payment-method`,
  });
};
export const getPolicyTerm = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/policy-term`,
  });
};
export const getGiftCard = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/setting/gift-card-commission`,
  });
};
export const postTax = (body: ITaxParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/update-tax`,
    body,
  });
};
export const postDiscount = (body: IShopDiscountParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/update-discount-shop`,
    body,
  });
};
export const postSpecialDiscount = (body: any) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/special-discount/update`,
    body,
  });
};
export const postLoyalty = (body: any) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/update-loyalty-program`,
    body,
  });
};
export const postOtherPaymentMethod = (body: IPaymentMethodParams[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/other-payment-method`,
    body,
  });
};
export const postGiftCard = (body: IGiftCard) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/setting/gift-card-commission`,
    body,
  });
};

export const getNotification = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/configuration/notification`,
  });
};
export const postNotification = (body: IBodyItemNotificationPut[]) => {
  return fetch({
    method: 'put',
    url: `${baseURL}/configuration/notification`,
    body,
  });
};

export const getScheduler = () => {
  return fetch({
    method: 'get',
    url: `${baseURL}/shop/scheduler-booking`,
  });
};
export const postScheduler = (body: any) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/shop/scheduler`,
    body,
  });
};

// end api for Payment Setting

// start day off api
export const getDayOffList = (params: IDayOffParams) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/off-day/shop`,
    params,
  });
};
export const postDayOffList = (body: IDayOffParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/off-day/shop`,
    body,
  });
};
export const deleteDayOffList = (id: string) => {
  return fetch({
    method: 'delete',
    url: `${baseURL}/off-day/shop/${id}`,
  });
};
export const deleteMultiple = (body: string[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/service/deleteMultiple`,
    body,
  });
};
// end day off api

const settingApis = {
  editCategory,
  createCategory,
  createOrUpdateServiceAddOn,
  addMoreServiceByCate,
  getListCategoriesForProduct,
  createCategoryForProduct,
  updateNameCategoryForProduct,
  deleteCategoryForProduct,
  getProductsByCategoryId,
  createProduct,
  updateProduct,
  deleteProduct,
  changeCommissionPrice,
  getVIP,
  createVIP,
  updateVIP,
  deleteVIP,
  getTurnSetting,
  updateTurnSettingTiket,
  updateTurnSettingService,
  deleteTurnSettingService,
  getLateForWork,
  updateLateForWork,
  getQuickPayList,
  updateQuickPayList,
  getTax,
  getDiscount,
  getSpecialDiscount,
  getLoyaltyReward,
  getOtherPaymentMethod,
  getPolicyTerm,
  getGiftCard,
  postTax,
  postDiscount,
  postSpecialDiscount,
  postLoyalty,
  postOtherPaymentMethod,
  postGiftCard,
  getNotification,
  postNotification,
  getScheduler,
  postScheduler,
  deleteAddOnService,
  getDayOffList,
  postDayOffList,
  deleteDayOffList,
  deleteMultiple,
};

export default settingApis;
