import { Col, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Spin from 'components/Spin';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IServiceItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import AddNewAddOnButton from './ButtonModal/AddNewAddOnButton';
type IAddOnListProps = {};
const AddOnList: React.FC<IAddOnListProps> = () => {
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();
  const addOnLoading = settingServiceSelectors.dataLoading.addOn();
  const categories = shopSelectors.data.addonServices();
  const serviceSelected = settingServiceSelectors.getAddOnServiceSelected();
  const rowPerPage = 5;

  const listDisplay = categories.slice(
    (page - 1) * rowPerPage,
    page * rowPerPage
  );
  const totalPage = Math.ceil(categories.length / rowPerPage);

  const handlePrev = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handleClickService = (cate: IServiceItemData) => {
    dispatch(settingServiceActions.clickAddOnService(cate));
  };

  return (
    <AddOnListStyled>
      <Box className="space-between" mb="2">
        <Text variant="H5" color="text_3">
          Add - On
        </Text>
        <Box display="flex" gap="2">
          <IconButtonBox onClick={handlePrev} disabled={page === 1}>
            <Icon type="back" />
          </IconButtonBox>
          <IconButtonBox onClick={handleNext} disabled={totalPage === page}>
            <Icon type="next" />
          </IconButtonBox>
        </Box>
      </Box>
      {addOnLoading ? (
        <Box className="center">
          <Spin />
        </Box>
      ) : (
        <Row gutter={[24, 24]}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <AddNewAddOnButton />
          </Col>
          {listDisplay?.map((item) => {
            const isSelected = item.id === serviceSelected?.id;
            return (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
                key={item.id}
              >
                <CateItem
                  isSelected={isSelected}
                  onClick={() => handleClickService(item)}
                >
                  <Text>{item.serviceName}</Text>
                </CateItem>
              </Col>
            );
          })}
        </Row>
      )}
    </AddOnListStyled>
  );
};

export default AddOnList;
const AddOnListStyled = styled.div``;

const IconButtonBox = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  border-radius: 50%;
  background-color: ${colorTheme.fill_2};
  border: 1px solid ${colorTheme.line_1};
  cursor: pointer;
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.5;
  pointer-events: none;
  `}
`;

const CateItem = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isSelected'].includes(prop),
})<{ isSelected?: boolean }>`
  // height: 2rem;
  padding: 14px 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  border-radius: 5px;
  background: var(--fill-fill-5, #e5e6eb);
  ${(props) =>
    props.isSelected &&
    `
    border: 1px solid var(--primary-primary-hover, #F5767F);
    background: var(--primary-button, #FFE5E7);
  `}
`;
