import React from 'react';
import ServiceSelectionPageStyled from './styles';
import CategoryPart from './components/CatergoryPart';
import ServicePart from './components/ServicePart';

interface IServiceSelectionPageProps { }
const ServiceSelectionPage: React.FC<IServiceSelectionPageProps> = () => {
  return (
    <ServiceSelectionPageStyled>
      <CategoryPart />
      <ServicePart />
    </ServiceSelectionPageStyled>
  );
};

export default ServiceSelectionPage;
