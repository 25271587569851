import Modal from 'components/Modal';
import AddStaffModalChildren from './AddStaffForm';

import { InputRef } from 'antd';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { IStaff } from 'features/cashier/services/types/api';
import ModalClockInOut from 'features/user/pages/clockInClockOut/components/ModalClockInOut';
import { useRef } from 'react';
import { useAppDispatch } from 'store/hooks';

const ModalChooseStaff = () => {
  const inputRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const staffAvailable = cashierSelectors.getAvailableStaff();
  const visible = cashierSelectors.getVisibleChooseStaff();
  const setVisible = (val: boolean) => {
    dispatch(cashierActions.setVisibleChooseStaff(val ? 'default' : null));
  };

  const handleCloseAddStaffModal = () => {
    setVisible(false);
  };
  const handleSubmitAddStaffModal = () => {
    setVisible(false);
  };

  const handleAddStaff = (data: IStaff) => {
    dispatch(cashierActions.addStaffItem(data));
    setVisible(false);
  };

  if (visible && staffAvailable.length === 0) {
    return (
      <ModalClockInOut isClockIn onPickStaff={handleAddStaff} onClose={handleCloseAddStaffModal} />
    );
  }


  return (
    <>
      <Modal
        visible={!!visible}
        onClose={handleCloseAddStaffModal}
        modalTitle={'SELECT STAFF'}
        onSubmit={handleSubmitAddStaffModal}
        hiddenOkButton={true}
        footer={<></>}
        noneBodyStyle
        noneFooterStyle={true}
        containerPadding={1.5}
        footerPadding={0.5}
        width={'60rem'}
        showClose
      >
        <AddStaffModalChildren
          inputRef={inputRef}
          onPickStaff={handleAddStaff}
        />
      </Modal>
    </>
  );
};

export default ModalChooseStaff;
