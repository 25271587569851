import ActionBtn from 'components/ActionBtn';
import Box from 'components/Box';
import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import cashierSelectors from 'features/cashier/services/selectors';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import CountingFooter from 'features/payment/pages/PaymentPage/CashierSide/components/LoyaltyPoint/CountingFooter';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { useSocketContext } from 'hooks/useSocket';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import useGetDetailTicket from '../useGetDetailTicket';
import useUpdateTicket from '../useUpdateTicket';

const ButtonLoyaltyPoint = () => {
  const [form] = useForm();
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateTicket = useUpdateTicket();
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();
  const ticketContext = useTicketWrapper();
  const socketContext = useSocketContext();

  const customer = cashierSelectors.getSelectedCustomer();
  const [visible, setVisible] = useState(false);
  const [detailTicket, getDetailTicket] = useGetDetailTicket();

  const [amount, setAmount] = useState<number | null>(null);

  const exchangeDollar = (point: number) => {
    if (!loyaltySetting) return 0;

    return +(point) / loyaltySetting?.oneDollarToPoint;
  };

  const _amount = amount || 0;
  const redeemPoint = loyaltySetting ? (+_amount) * (loyaltySetting?.oneDollarToPoint || 0) : 0;
  const balancePoint = +(customer?.point || '') - redeemPoint;

  const onLoyalty = async () => {
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;

      const billId = rest.billId;
      const detail = await getDetailTicket(billId);
      if (!detail) return;
      // const availablePointEx = exchangeDollar(customer?.point || 0);
      // const amountVal = availablePointEx - detail?.total < 0 ? availablePointEx : detail?.total;
      socketContext.switchCusPayment(billId);
      setTimeout(() => currencyRef.current?.setValue(0?.toFixed(2)), 100);
      setVisible(true);
    } catch (error) { }
  };

  const onClose = () => {
    form.resetFields();
    socketContext.backToCheckIn();
    setVisible(false);
  };

  const handleSubmit = async () => {
    if (!detailTicket) return;
    onClose();
    const loyaltyPointVal = {
      amount: _amount,
      point: redeemPoint,
    };
    if (_amount < detailTicket.total) {
      dispatch(multiplePaymentActions.setDraftMultiPayment({ [PAYMENT_TYPE.LOYALTY_POINT]: loyaltyPointVal }));
      dispatch(multiplePaymentActions.setActivePayment(PAYMENT_TYPE.CASH));
      navigate(`/store/${storage.shop_id.get()}/ticket/payment/cashier-side/${detailTicket.billId}`);
      return;
    }

    const body: IBodyAPIPayment = {
      billId: detailTicket.billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.LOYALTY_POINT,
          amount: _amount,
          point: redeemPoint,
        },
      ]
    };

    loadingRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        loadingRef.current?.setVisible(false);
        socketContext.switchCusRating(detailTicket.billId);

        ticketContext.completedTicket(detailTicket?.billId, true);
      } else {
        loadingRef.current?.setVisible(false);
      }
    } catch (error) {
      loadingRef.current?.setVisible(false);
    }
  };

  const BalancePoint = () => {
    if (!loyaltySetting) return <>{('0 point = $0')}</>;
    if (balancePoint < 0) {
      return <>0 Point = $0</>;
    }
    return (
      <>
        {balancePoint?.toFixed(2)} point = {formatCurrency(exchangeDollar(+(balancePoint || '')))}
      </>
    );
  };

  const AvailablePoint = () => {
    return (
      <>
        {customer?.point} point = {formatCurrency(exchangeDollar(+(customer?.point || '')))}
      </>
    );
  };

  if (!loyaltySetting?.active) return null;

  return (
    <div>
      <ActionBtn onClick={onLoyalty} disabled={!customer?.point} icon='loyaltyPointBtn' ntype='YELLOW' small label='Points' />
      <Modal
        // width="auto"
        footerBgColor={colorTheme.fill_5}
        headerBgColor={colorTheme.info_bg_3}
        visible={visible}
        onClose={onClose}
        onSubmit={onClose}
        modalTitle="POINTS"
        width={800}
        noneBodyStyle
        footer={
          <CountingFooter
            submitButtonTitle="Redeem"
            isValid={_amount > 0 && !(balancePoint < 0)}
            onSubmit={handleSubmit}
            handleCloseModal={onClose}
            style={{ padding: '1.5rem', paddingTop: '1rem' }}
          >
            <Box className="center">
              <Box display="flex" alignItems="center" gap="4">
                <Text variant="CONTENT_1" color="text_3" style={{ whiteSpace: 'nowrap' }}>
                  Balance point:
                </Text>
                <Text variant="H5" color="text_3" whiteSpace='nowrap' >
                  <BalancePoint />
                </Text>
              </Box>
            </Box>
            <Text mb={1} />
          </CountingFooter>
        }
      >
        <BodyFormStyled form={form} initialValues={{ amount: '$' }}>
          <Box>
            <Box className="space-between" mb="5">
              <Text variant="CONTENT_1" color="text_3">
                Available Point:
              </Text>
              <Text variant="H9" color="text_3">
                <AvailablePoint />
              </Text>
            </Box>
            <Text variant="H6" color="text_3">
              Redeem
            </Text>
            <CurrencyKeyBoard ref={currencyRef} value={amount} onChange={val => setAmount(val || 0)} />
          </Box>
        </BodyFormStyled>
      </Modal>
      <PaymentLoading ref={loadingRef} />
    </div>
  );
};

export default ButtonLoyaltyPoint;
const BodyFormStyled = styled(Form)`
padding: 1rem 3rem;
`;