import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import CenterMainBody from './CenterMainBody';
import LeftMainBody from './LeftMainBody';
import RightMainBody from './RightMainBody';
type IComponentProps = {};
const MainBody: React.FC<IComponentProps> = () => {
  return (
    <MainBodyStyled>
      <Row wrap={false}>
        <Col className='left-box'>
          <LeftMainBody />
        </Col>
        <Col flex={'auto'}>
          <CenterMainBody />
        </Col>
        <Col className='right-box'>
          <RightMainBody />
        </Col>
      </Row>
    </MainBodyStyled>
  );
};

export default MainBody;
const MainBodyStyled = styled.div`
  height: calc(100vh - 6.375rem);
  background-color: #fff;
  .left-box {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .center-box {
  }
  .right-box {
    display: block;
    flex: 0 0 40%;
    max-width: 40%;
  }
`;
