import { Card, Col, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import IconAndPrice, { IIconAndPriceProps } from 'features/report/components/IconAndPrice';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAppDispatch } from 'store/hooks';
import reportSelectors from 'features/report/services/selectors';
import reportActions from 'features/report/services/actions';
import { ReportType } from '../../constants';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface ICategoriesChart { }
interface IListCategoriesItem extends IIconAndPriceProps { }
const defaultListCategoriesItem: IListCategoriesItem[] = [
    {
        title: 'TOTAL SALES',
        icon: 'moneyExchange',
        percent: 0,
        price: 0,
        increase: false,
        decrease: false,
        isPercentageInTittle: true,
    },
    {
        title: 'AVERAGE TICKET',
        icon: 'moneyExchange',
        percent: 0,
        price: 0,
        increase: false,
        decrease: false,
        isPercentageInTittle: true
    },
    {
        title: 'TOTAL SERVICES',
        percent: 0,
        price: 0,
        increase: false,
        decrease: false,
        isPercentageInTittle: true,
        isHidePercentage: true,
        isHideCurrency: true
    }
];

interface IDataChart {
    labels: string[],
    datasets: IDataset[]
}
interface IDataset{
    data: number[],
    backgroundColor: string,
    barThickness?: number,
    [key:string]: any
}
const CategoriesChart: React.FC<ICategoriesChart> = () => {

    const [listCategoriesItem, setListCategoriesItem] = useState<IListCategoriesItem[]>(defaultListCategoriesItem);
    const [dataChart, setDataChart] = useState<IDataChart>({
        labels: [''],
        datasets: [{ data: [0], backgroundColor: 'red', barThickness: 60 }]
    });
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: () => undefined,
                }
            },
        }
    };
    const dispatch = useAppDispatch();
    const summaryServiceCategory = reportSelectors.getSummaryServiceCategory();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.SERVICES];
    useEffect(
        () => {
            if (dataAppointment?.startTime && dataAppointment?.endTime) {
                dispatch(reportActions.getSummaryServiceCategory.fetch({
                    startDate: dataAppointment?.startTime,
                    endDate: dataAppointment?.endTime
                }));
            }
        }, [dataAppointment]
    );

    useEffect(
        () => {
            setListCategoriesItem([
                {
                    title: 'NET SALES',
                    icon: 'moneyExchange',
                    percent: summaryServiceCategory?.netSalePercent ?? 0,
                    price: summaryServiceCategory?.netSale ?? 0,
                    increase: summaryServiceCategory?.netSalePercent >= 0,
                    decrease: summaryServiceCategory?.netSalePercent < 0,
                    isHidePercentage: (summaryServiceCategory?.netSalePercent ?? 0) == 0,
                },
                {
                    title: 'AVERAGE TICKET',
                    icon: 'ticketReport',
                    percent: summaryServiceCategory?.averagePercent ?? 0,
                    price: summaryServiceCategory?.averageTicket ?? 0,
                    increase: summaryServiceCategory?.averagePercent >= 0,
                    decrease: summaryServiceCategory?.averagePercent < 0,
                    isHidePercentage: (summaryServiceCategory?.averagePercent ?? 0) == 0,
                },
                {
                    title: 'TOTAL SERVICES',
                    icon: 'glass',
                    percent: summaryServiceCategory?.servicePercent ?? 0,
                    price: summaryServiceCategory?.totalService ?? 0,
                    increase: summaryServiceCategory?.servicePercent >= 0,
                    decrease: summaryServiceCategory?.servicePercent < 0,
                    isHidePercentage: (summaryServiceCategory?.servicePercent ?? 0) == 0,
                }
            ]);
            setDataChart({
                labels: summaryServiceCategory?.chartData?.map(item => item.categoryName),
                datasets: [
                    {
                        data: summaryServiceCategory?.chartData?.map(item => item.totalSale),
                        backgroundColor: 'rgba(255, 136, 144, 1)',
                    }
                ]
            });
        }
        , [summaryServiceCategory]);

    return (
        <StyledCard>
            <Box>
                <Text variant="H5">CATEGORIES</Text>
            </Box>
            <Chart>
                <Bar options={options} data={dataChart} style={{ width: '100%', height: 'auto' }} />
            </Chart>
            <Row gutter={[24, 24]}>
                {
                    listCategoriesItem.map((item, index) => {
                        return (
                            <Col
                                key={index}
                                xs={{ span: 24 }}
                                sm={{ span: 24 }}
                                md={{ span: 12 }}
                                lg={{ span: index == listCategoriesItem.length - 1 ? 6 : 9 }}
                            >
                                <IconAndPrice
                                    title={item?.title}
                                    icon={item?.icon}
                                    percent={item?.percent}
                                    price={item?.price}
                                    increase={item?.increase}
                                    decrease={item?.decrease}
                                    isPercentageInTittle={item?.isPercentageInTittle}
                                    isHidePercentage={item?.isHidePercentage}
                                    isHideCurrency={item?.isHideCurrency}
                                    background={'#E5E6EB'}
                                />
                            </Col>
                        );
                    })
                }
            </Row>
        </StyledCard>
    );
};

export default CategoriesChart;

const StyledCard = styled(Card)`
    flex: 1;
    border-radius: 3.358px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0.672px solid #86909C;
    background: #FFF;
`;

const Chart = styled.div`
    margin: 1rem 0rem;
    min-height: 400px;
`;
