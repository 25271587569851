import { Col, Row } from 'antd';
import React, { useState } from 'react';
import styled from 'styled-components';
import Categories from './Categories';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import Text from 'components/Text';
import Empty from 'components/Empty';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  selected: IServiceItemData[];
  handleChooseServices: (sle: IServiceItemData) => void;
  single?: boolean;
};
const ServicePicker = ({ single, selected, handleChooseServices }: Props) => {
  const [activeCategory, setActiveCategory] = useState<ICategoryItemData | null>(null);
  return (
    <ServicesTabStyled gutter={[24, 24]} wrap={false}>
      <Col span={8}>
        <Categories selectedServices={selected} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
      </Col>
      <Col span={16} >
        <Text mb={1} className='section-label'>Services</Text>
        <div className='services'>
          <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
            {activeCategory?.services.length ? activeCategory?.services.map(o => (
              <Col key={o.id} span={8}>
                <ServiceItem
                  onClick={() => handleChooseServices(o)}
                  selected={!!selected.find(a => a.id === o.id)}
                  name={o.serviceName}
                  price={o.priceSell}
                  single={single}
                />
              </Col>
            )) : <Empty />}
          </Row>
        </div>
      </Col>
    </ServicesTabStyled>
  );
};

export default ServicePicker;

type ServicesTabStyledProps = {}
const ServicesTabStyled = styled(Row) <ServicesTabStyledProps>`
  .arrow-btn {
    width: 2.5rem;
    height: 2.5rem;
  }
  .services {
    max-height: 25rem;
    min-height: 25rem;
    overflow-y: auto;
    margin-right: -8px;
    margin-left: -8px;
  }
`;
type ServiceItemProps = {
  selected?: boolean;
  name?: string;
  price?: number;
  single?: boolean;
  onClick: () => void;
};
const ServiceItem = ({ single = false, selected = false, price = 0, name = '', onClick }: ServiceItemProps) => {
  return (
    <ServiceItemStyled onClick={onClick}>
      <div className='content'>
        <Text variant='BODY_1' className='service-name text-overflow'>{name}</Text>
        <Text variant='BODY_1' className='service-price' style={{ fontWeight: '600' }}>{formatCurrency(price)}</Text>
      </div>
      {!single ? <>{selected ? <IconChecked /> : <IconCheckEmpty />}</> : null}
    </ServiceItemStyled>
  );
};

const ServiceItemStyled = styled.button`
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-3, #E5E5E5);
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  text-align: left !important;
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 75%;
    .service-name {
      font-size: 1.25rem;
    }
    .service-price {
      font-size: 1.25rem;
    }
  }
`;

const IconChecked = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <rect y="0.838867" width="24" height="24" rx="2" fill="#6FABB6" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.7969 6.98008C16.5899 6.77367 16.2507 6.78816 16.062 7.01149L10.4252 13.683L8.21622 11.3763C8.02224 11.1737 7.69963 11.1703 7.50139 11.3687L6.36968 12.5014C6.17754 12.6937 6.17424 13.0043 6.36226 13.2006L10.0807 17.0836C10.2194 17.2285 10.4238 17.2715 10.6015 17.2118C10.7445 17.2129 10.8873 17.1532 10.9876 17.0345L11.7292 16.1568L12.2729 15.6126L12.2283 15.566L17.9575 8.78508C18.1255 8.58633 18.1129 8.292 17.9286 8.10829L16.7969 6.98008Z" fill="white" />
  </svg>
);

const IconCheckEmpty = () => (
  <div style={{ width: '24px', height: '24px', background: '#fff', border: '1px solid #86909C' }} />
);