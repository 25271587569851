import styled from 'styled-components';

const StaffPageStyled = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .list-staff-container {
        flex-grow: 1;
    }
    .staff-list-custom-group-btn {
        bottom: 2.125rem; 
        padding-right: 6.4rem;
        position: sticky;
    }
    background: var(--fill-fill-3, #E5E5E5);
`;

export default StaffPageStyled;
