import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
type IPrintHeaderProps = {
  onBack: () => void;
  onPrint: () => void;
};
const PrintHeader: React.FC<IPrintHeaderProps> = ({onBack, onPrint}) => {
  return (
    <PrintHeaderStyled>
      <Text printMode variant="H5" color="text_3">
        PRINT PREVIEW
      </Text>
      <Box display="flex" gap="3">
        <Button ntype="DEFAULT" icon="back" onClick={onBack}>Back</Button>
        <Button ntype="DEFAULT" icon="print" onClick={onPrint}>Print</Button>
      </Box>
    </PrintHeaderStyled>
  );
};

export default PrintHeader;
const PrintHeaderStyled = styled.div`
  width: calc(100% - 2rem);
  padding: 1rem;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
