import { Checkbox, Radio } from 'antd';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { DiscountType } from 'features/cashier/services/constants';
import { IDiscountTicketFormValue } from 'features/cashier/services/types/discount';
import { useCallback, useEffect, useState, useTransition } from 'react';
import shopSelectors from 'services/shop/selectors';
import { styled } from 'styled-components';
import AmountDiscountKeyboard, { DISCOUNT_VALUE_TYPE, IAmountDiscountKeyboard } from '../AmountDiscount';
import CouponKeyboard, { ICouponKeyboard } from '../Coupon';
import { ManualFormStyled } from './Manual';
import SpecialDiscountForm from './SpecialDiscount';

enum EDiscountType {
  COUPON = 'COUPON',
  SALON_DISCOUNT = 'SALON_DISCOUNT',
}

export type ISubmitModalDiscount = (value: IDiscountTicketFormValue) => void;
export interface IDiscountMethodModal {
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: ISubmitModalDiscount;
  initialAmount?: number;
  isItemDiscount?: boolean;
}
const DiscountMethodModal = ({ visible, isItemDiscount = false, onClose = () => undefined, onSubmit = () => undefined }: IDiscountMethodModal) => {

  const [discountType, setDiscountType] = useState<EDiscountType>(EDiscountType.SALON_DISCOUNT);
  const [coupon, setCoupon] = useState<ICouponKeyboard['value']>(null);
  const [couponStaffPercent, setCouponStaffPercent] = useState<number>(0);
  const [couponOwnerPercent, setCouponOwnerPercent] = useState<number>(0);
  const shopSetting = shopSelectors.data.allSetting();
  const [discountValueType, setDiscountValueType] = useState(DISCOUNT_VALUE_TYPE.PERCENT);

  const [specialDiscount, setSpecialDiscount] = useState(false);
  const [specialDiscountId, setSpecialDiscountId] = useState<string>();

  const [textNote, setTextNote] = useState('');

  const [amount, setAmount] = useState<IAmountDiscountKeyboard['value']>();
  const [staffPercent, setStaffPercent] = useState(0);
  const [ownerPercent, setOwnerPercent] = useState(0);
  const discountSetting = shopSelectors.data.discountSetting();

  const specialDiscounts = shopSelectors.data.specialDiscounts();

  const [, startTransition] = useTransition();

  useEffect(() => {
    if (!discountSetting) return;
    const { discountOwner, discountStaff } = discountSetting;
    startTransition(() => {
      setStaffPercent(discountStaff);
      setOwnerPercent(discountOwner);
      setCouponStaffPercent(discountStaff);
      setCouponOwnerPercent(discountOwner);
    });
  }, [discountSetting]);

  useEffect(() => {
    if (!visible) return;
    if (isItemDiscount) {
      startTransition(() => {
        setStaffPercent(100);
        setOwnerPercent(0);
      });
    } else {
      if (!discountSetting) return;
      const { discountOwner, discountStaff } = discountSetting;
      startTransition(() => {
        setStaffPercent(discountStaff);
        setOwnerPercent(discountOwner);
        setCouponStaffPercent(discountStaff);
        setCouponOwnerPercent(discountOwner);
      });
    }
  }, [visible]);

  const handleSubmit = () => {
    if (discountType === EDiscountType.COUPON) {
      if (!coupon) return;
      onSubmit({
        discount: coupon.discountValue,
        discountSetting: {
          ownerPaidPercent: couponOwnerPercent ?? coupon?.ownerDiscountPercent,
          staffPaidPercent: couponStaffPercent ?? coupon?.staffDiscountPercent,
        },
        discountType: coupon.discountType === 'PERCENT' ? DiscountType.PERCENT : DiscountType.MONEY,
        couponId: coupon.id,
        reason: null,
      });
      onClose();
      return;
    }

    if (!!specialDiscount && !!specialDiscountId?.toString()) {
      const result = specialDiscounts.find(o => o.id.toString() === specialDiscountId?.toString());
      if (!result) return;
      const { discountValue, discountType, ownerDiscountPercent, staffDiscountPercent } = result;
      onSubmit({
        discount: discountValue,
        discountSetting: {
          ownerPaidPercent: ownerDiscountPercent,
          staffPaidPercent: staffDiscountPercent,
        },
        discountType: discountType === 'PERCENT' ? DiscountType.PERCENT : DiscountType.MONEY,
        couponId: null,
        reason: null,
      });
      onClose();
      return;
    }

    if (!specialDiscount) {
      onSubmit({
        discount: amount || 0,
        discountSetting: {
          ownerPaidPercent: ownerPercent,
          staffPaidPercent: staffPercent,
        },
        discountType: discountValueType === DISCOUNT_VALUE_TYPE.DOLLAR ? DiscountType.MONEY : DiscountType.PERCENT,
        couponId: null,
        reason: textNote,
      });
      onClose();
      return;
    }
  };

  const Radios = useCallback(() => (
    <RadioGroupStyled className='RadioGroupStyled' onChange={(e: any) => setDiscountType(e.target.value)} value={discountType}>
      <Radio className={EDiscountType.SALON_DISCOUNT} value={EDiscountType.SALON_DISCOUNT}>Salon’s Discount</Radio>
      <Radio className={EDiscountType.COUPON} value={EDiscountType.COUPON}>Coupon</Radio>
    </RadioGroupStyled>
  ), [discountType]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      okTitle={'Done'}
      modalTitle='DISCOUNT METHOD SYSTEM'
      onSubmit={handleSubmit}
      width={'46.125rem'}
      noneBodyStyle
      noneFooterStyle
      containerPadding={2}
    >
      {!isItemDiscount && <>
        <Radios />
        <Text mb={1.5} />
      </>}

      {discountType === EDiscountType.COUPON && (
        <>
          <CouponKeyboard
            value={coupon}
            onChange={setCoupon}
            staffPercent={couponStaffPercent}
            setStaffPercent={setCouponStaffPercent}
            ownerPercent={couponOwnerPercent}
            setOwnerPercent={setCouponOwnerPercent}
          />
        </>
      )}
      {discountType === EDiscountType.SALON_DISCOUNT && <>
        {!specialDiscount && (
          <>
            <ManualFormStyled>
              <AmountDiscountKeyboard
                isItemDiscount={isItemDiscount}
                value={amount}
                onChange={setAmount}
                ownerPercent={ownerPercent}
                staffPercent={staffPercent}
                setOwnerPercent={setOwnerPercent}
                setStaffPercent={setStaffPercent}
                discountValueType={discountValueType}
                setDiscountValueType={setDiscountValueType}
                textNote={textNote}
                setTextNote={setTextNote}
              />
            </ManualFormStyled>
          </>
        )}
        {!!shopSetting?.enableDiscountSpecial && <>
          <Text mt={1.5} />
          <CheckboxStyled className='CheckboxStyled' checked={specialDiscount} onChange={(e: any) => setSpecialDiscount(e.target.checked)}>Special Discount</CheckboxStyled>
          {specialDiscount && <>
            <Text mt={1.5} />
            <SpecialDiscountForm
              value={specialDiscountId}
              onChange={setSpecialDiscountId}
            />
          </>}
        </>}
      </>}

    </Modal>
  );
};

export default DiscountMethodModal;

const RadioGroupStyled = styled(Radio.Group)`
  width: 100%;
  display: flex;
  .ant-radio-wrapper {
    flex:1;
    &.COUPON {
      flex:0.8;
    }
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: #6FABB6;
        background-color: #6FABB6;
      }
    }
    .ant-radio-inner {
      border-color: #E5E6EB;
      border-width: 5px; 
    }
  }
`;

const CheckboxStyled = styled(Checkbox)`
&.ant-checkbox-wrapper {
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: #6FABB6;
      background-color: #6FABB6;
    }
  }
  .ant-checkbox-inner {
    border-color: #E5E6EB;
  }
}
`;