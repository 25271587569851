import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import customerActions from 'features/customer/services/actions';
// import customerSelectors from 'features/customer/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CustomersBodyDisplay from './components/CustomersBodyDisplay';
// import CustomersBodyWindowDisplay from './components/CustomersBodyWindowDisplay';
import CustomersHeader from './components/CustomersHeader';
import ListCustomerPageStyled from './styles';
type IListCustomerPageProps = any;
const ListCustomerPage: React.FC<IListCustomerPageProps> = () => {
  // const isWindow = customerSelectors.getIsWindowDisplay();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(customerActions.doInit());
    const params = {
      page: 1,
      size: 10,
    };
    dispatch(customerActions.setCustomerParam(params));
  }, []);

  return (
    <ListCustomerPageStyled>
      <Sidebar />
      <BodyStyled>
        <CustomersHeader />
        {/* {isWindow && <CustomersBodyWindowDisplay />}
        {!isWindow && <CustomersBodyDisplay />} */}
        <CustomersBodyDisplay />
      </BodyStyled>
    </ListCustomerPageStyled>
  );
};

export default ListCustomerPage;

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
`;
