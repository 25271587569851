const Email = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 3C3.17157 3 2.5 3.67157 2.5 4.5V19.5C2.5 20.3284 3.17157 21 4 21H21C21.8284 21 22.5 20.3284 22.5 19.5V4.5C22.5 3.67157 21.8284 3 21 3H4ZM4.5 19V5H20.5V19H4.5ZM6.56921 7.6778L12.5 11.7837L18.4308 7.6778L19.5692 9.32218L13.3538 13.6251C12.8401 13.9808 12.1598 13.9808 11.6462 13.6251L5.43079 9.32218L6.56921 7.6778Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default Email;
