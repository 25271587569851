
const RightDoubleArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="14"
      viewBox="0 0 17 14"
      fill="none"
    >
      <path
        d="M1.75 13L7.75 7L1.75 1"
        stroke="#1D2129"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25 13L15.25 7L9.25 1"
        stroke="#1D2129"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RightDoubleArrow;
