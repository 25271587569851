import { Spin, Tabs, TabsProps } from 'antd';
import Icon, { typeIcon } from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import DeleteModalButton from 'features/customer/components/DeleteModalButton';
import customerSelectors from 'features/customer/services/selectors';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import AppointmentTab from './tabs/AppointmentTab';
import CouponTab from './tabs/CouponTab';
import ReferralTab from './tabs/ReferralTab';
import TransactionsTab from './tabs/TransactionsTab';
type IRightCustomerDetailsProps = {};
type Item = {
  keyIndex: number;
  iconType: typeIcon;
  value: string | number;
  label: string;
};

const items: TabsProps['items'] = [
  {
    key: 'appointment',
    label: 'Appointment',
    children: <AppointmentTab />,
  },
  {
    key: 'transactions',
    label: 'Transactions',
    children: <TransactionsTab />,
  },
  {
    key: 'referral',
    label: 'Referral',
    children: <ReferralTab />,
  },

  {
    key: 'coupon',
    label: 'Coupon',
    children: <CouponTab />,
  },
];

const RightCustomerDetails: React.FC<IRightCustomerDetailsProps> = () => {
  const navigate = useNavigate();
  const customerDetails = customerSelectors.getCustomerDetails();
  const summaryReportData = customerSelectors.summaryReportData.data();
  const summaryReportLoading = customerSelectors.summaryReportData.loading();

  const itemsReview: Item[] = [
    {
      keyIndex: 1,
      iconType: 'tip',
      value: formatCurrency(summaryReportData.amountSpend || 0),
      label: 'Amount Spent',
    },
    {
      keyIndex: 2,
      iconType: 'loyaltyPoint',
      value: summaryReportData.point || 0,
      label: 'Point',
    },
    {
      keyIndex: 3,
      iconType: 'walkIn',
      value: summaryReportData.totalWalkIn || 0,
      label: 'Walk-In',
    },
    {
      keyIndex: 4,
      iconType: 'calendar',
      value: summaryReportData.totalAppointment || 0,
      label: 'Appointment',
    },
  ];

  const onChange = () => undefined;
  const handleEdit = () => {
    navigate(
      `/store/${storage.shop_id.get()}/customers/${customerDetails.id}/edit`
    );
  };

  return (
    <RightCustomerDetailsStyled>
      <Box className="space-between">
        <Box display="flex" alignItems="center" gap="3">
          <Text variant="H5" color="text_3">
            {customerDetails?.name || ''}
          </Text>
          <ChipStatusStyled>
            {customerDetails?.accountPackage || ''}
          </ChipStatusStyled>
        </Box>
        <Box display="flex" alignItems="center" gap="3">
          <DeleteModalButton />
          <Button icon="edit" ntype="LIGHT_BLUE" onClick={handleEdit}>
            <Text variant="CONTENT_2" color="text_3">
              Edit Info
            </Text>
          </Button>
        </Box>
      </Box>
      <Box display="flex" mt="2">
        <Box br="line_2" pr="3" mr="3">
          <Text variant="CONTENT_1" color="text_2">
            {customerDetails?.phone ? maskPhone(customerDetails?.phone) : '--'}
          </Text>
          <Text variant="CONTENT_1" color="text_2">
            Email: {customerDetails?.email || '--'}
          </Text>
          <Text variant="CONTENT_1" color="text_2">
            DOB:{' '}
            {customerDetails?.birthday
              ? moment(customerDetails?.birthday).format('MM/DD/YYYY')
              : '--'}
          </Text>
        </Box>
        <Box>
          <Text variant="CONTENT_1" color="text_2">
            Created Date:{' '}
            {customerDetails?.createdDate
              ? moment(customerDetails?.createdDate).format('MM/DD/YYYY')
              : '--'}
          </Text>
          <Text variant="CONTENT_1" color="text_2">
            Last Visit Date:{' '}
            {customerDetails?.lastVisitedDate
              ? moment(customerDetails?.lastVisitedDate).format('MM/DD/YYYY')
              : '--'}
          </Text>
          <Text variant="CONTENT_1" color="text_2">
            Visited: {customerDetails?.numberVisit || '--'}
          </Text>
        </Box>
      </Box>

      <BoxIconStyled>
        {summaryReportLoading ? (
          <Spin />
        ) : (
          itemsReview.map((item: Item) => (
            <Box
              display="flex"
              alignItems="center"
              gap="2"
              className="item-icon"
              key={item.keyIndex}
            >
              <Box width="3rem" height="3rem">
                <Icon type={item.iconType} className="icon-item" />
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
              >
                <Text variant="H9" color="text_3">
                  {item.value}
                </Text>
                <Text variant="CONTENT_1" color="text_2">
                  {item.label}
                </Text>
              </Box>
            </Box>
          ))
        )}
      </BoxIconStyled>

      <Tabs items={items} defaultActiveKey="OPEN_TICKET" onChange={onChange} />
    </RightCustomerDetailsStyled>
  );
};

export default RightCustomerDetails;
const RightCustomerDetailsStyled = styled.div`
  padding: 1.5rem;
  width: 100%;
`;
const ChipStatusStyled = styled.div`
  display: flex;
  width: 126px;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: var(--yellow-headline, #feedbf);
`;

const BoxIconStyled = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  background: var(--fill-fill-3, #e5e5e5);
  .item-icon {
    flex-grow: 1;
    flex-basis: 5rem;
  }
  svg {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
`;
