import { Select, SelectProps } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import React from 'react';
import styled from 'styled-components';
interface ISelectMergeProps extends SelectProps<any, DefaultOptionType | BaseOptionType> {}

const SelectMerge: React.FC<ISelectMergeProps> = (props) => {
  return <SelectMergeStyled {...props}></SelectMergeStyled>;
};

export default SelectMerge;
const SelectMergeStyled = styled(Select)`
  width: 100%;
  .ant-select-selector {
    height: 3.5rem !important;
    align-items: center;
  }
  .ant-select-selection-item {
    display: flex;
  }
  .box-option {

  }
`;
