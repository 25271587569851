
const Trash = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="30" viewBox="0 0 24 30" fill="none" {...props}>
        <path d="M6.66876 1.62625C6.66938 0.930625 7.30001 0.32 7.99376 0.334375C10.6819 0.33375 13.37 0.330625 16.0575 0.33625C16.7344 0.346875 17.3281 0.949375 17.3306 1.62625C17.3375 2.52875 17.33 3.43125 17.3344 4.33375C19.1 4.33313 20.8656 4.33375 22.6306 4.33313C23.0775 4.32 23.5213 4.54563 23.7694 4.91813C24.055 5.32938 24.075 5.90438 23.8194 6.335C23.5825 6.75313 23.1069 7.015 22.6269 7C15.5425 7 8.45751 7 1.37313 7C0.980006 7.01063 0.587506 6.84188 0.330631 6.54375C0.0112557 6.18688 -0.0868692 5.65188 0.0831308 5.205C0.228756 4.80125 0.582506 4.48125 0.998756 4.37625C1.20251 4.32063 1.41563 4.33438 1.62501 4.33313C3.30501 4.33375 4.98563 4.33313 6.66563 4.33375C6.66938 3.43125 6.66251 2.52875 6.66876 1.62625ZM9.33313 3.00063C9.33563 3.44563 9.32938 3.89 9.33626 4.33438C11.1131 4.33188 12.8894 4.33375 14.6656 4.33375C14.6681 3.88938 14.6656 3.445 14.6669 3.00125C12.8888 2.99938 11.1113 2.99938 9.33313 3.00063Z" fill={props?.fill ? props.fill : '#1D2129'}/>
        <path d="M2.2106 8.41311C2.8406 8.16811 3.61498 8.49499 3.8806 9.11562C4.01373 9.38999 4.0031 9.70061 3.99998 9.99811C3.99998 14.8331 3.99998 19.6687 3.99998 24.5037C4.00873 25.0956 4.23748 25.6806 4.62873 26.1244C5.09373 26.6594 5.78873 26.9912 6.49998 26.9994C10.1456 27.0006 13.7912 26.9994 17.4369 27.0006C18.1094 27.0069 18.7781 26.73 19.2519 26.2531C19.7131 25.7944 19.9906 25.1544 19.9994 24.5031C20.0006 19.5637 19.9994 14.6237 20.0006 9.68436C19.9912 9.23624 20.2256 8.79374 20.6031 8.55186C20.9719 8.30436 21.4669 8.26499 21.8712 8.44686C22.3 8.63124 22.6119 9.05749 22.6575 9.52187C22.6725 9.70187 22.6656 9.88249 22.6669 10.0631C22.6669 14.7712 22.6662 19.4794 22.6669 24.1881C22.7475 25.8344 22.0081 27.4962 20.7119 28.5181C19.8106 29.2481 18.6581 29.6594 17.4981 29.6662C13.8312 29.6669 10.1644 29.6669 6.49685 29.6662C5.22185 29.6594 3.9606 29.1562 3.02435 28.2919C1.96998 27.3337 1.33748 25.9287 1.33373 24.5025C1.3331 19.5631 1.3331 14.6231 1.33373 9.68311C1.3181 9.12936 1.68935 8.59749 2.2106 8.41311Z" fill={props?.fill ? props.fill : '#1D2129'}/>
        <path d="M9.02571 11.0363C9.44321 10.935 9.90571 11.0513 10.2232 11.3419C10.5132 11.5975 10.6757 11.9838 10.6663 12.37C10.667 15.4569 10.667 18.5438 10.667 21.6307C10.6801 22.0932 10.4363 22.5525 10.0407 22.795C9.59883 23.0807 8.98696 23.0638 8.56196 22.7532C8.20196 22.5025 7.98696 22.0663 8.00008 21.6288C8.00133 18.5219 7.99758 15.415 8.00195 12.3088C8.00008 11.7157 8.44821 11.1638 9.02571 11.0363Z" fill={props?.fill ? props.fill : '#1D2129'}/>
        <path d="M14.3425 11.04C14.7588 10.9337 15.2238 11.0443 15.5444 11.3306C15.8425 11.5868 16.0094 11.9793 16 12.3706C16 15.4543 16 18.5381 16 21.6219C16.0132 22.0469 15.8144 22.4718 15.4732 22.7268C15.1325 22.9906 14.6607 23.0693 14.2525 22.9331C13.8269 22.7968 13.4832 22.4325 13.3763 21.9987C13.3207 21.795 13.3344 21.5818 13.3332 21.3731C13.335 18.3518 13.3313 15.3312 13.335 12.31C13.3332 11.7225 13.7719 11.1737 14.3425 11.04Z" fill={props?.fill ? props.fill : '#1D2129'}/>
        </svg>
    );
};

export default Trash;
