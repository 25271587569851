import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import {
  IApiGetDashboardTimeClockReportParams,
  IApiGetDemoDataParam,
  IApiGetPrintSalaryStaffDetailParams,
  IApiGetPrintSalarySummaryStaffOneDayParams,
  IApiGetPrintSalarySummaryStaffRangeDateParams,
  IApiGetPrintSummaryServiceCategoryParam,
  IApiGetSalaryStaffDetailParam,
  IApiGetSummarySalaryStaffParam,
  IApiGetSummarySalonParam,
  IApiGetSummaryTicketParam,
  IApiGetTimeClockReportHistoryParams,
  IApiGetTopRankingServiceParam
} from './types/api';
import { IFilterTypeSalaryStaff, IParams, IViewType } from './types/reportStaff';

const getDemoData = createAsyncAction<IApiGetDemoDataParam>(PREFIX_ACTIONS + 'getDemoData');

const getPrintSummarySalon = createAsyncAction<IApiGetSummarySalonParam>(PREFIX_ACTIONS + 'getPrintSummarySalon');

const getSummarySalon = createAsyncAction<IApiGetSummarySalonParam>(PREFIX_ACTIONS + 'getSummarySalon');

const getSummaryServiceCategory = createAsyncAction<IApiGetSummarySalonParam>(PREFIX_ACTIONS + 'getSummaryServiceCategory');

const getSummaryTicket = createAsyncAction<IApiGetSummaryTicketParam>(PREFIX_ACTIONS + 'getSummaryTicket');

const getTopRankingService = createAsyncAction<IApiGetTopRankingServiceParam>(PREFIX_ACTIONS + 'getTopRankingService');

const getSummarySalaryStaff = createAsyncAction<IApiGetSummarySalaryStaffParam>(PREFIX_ACTIONS + 'getSummarySalaryStaff');

const getSummarySalarySalon = createAsyncAction<IApiGetSummarySalaryStaffParam>(PREFIX_ACTIONS + 'getSummarySalarySalon');

const getSummaryDashboardSalaryStaff = createAsyncAction<IApiGetSummarySalaryStaffParam>(PREFIX_ACTIONS + 'getSummaryDashboardSalaryStaff');

const setParams = createAction<IParams | undefined>(PREFIX_ACTIONS + 'setParams');

const setCalendarViewType = createAction<IViewType>(PREFIX_ACTIONS + 'setCalendarViewType');

const setFilterTypeSalaryStaff = createAction<IFilterTypeSalaryStaff>(PREFIX_ACTIONS + 'setFilterTypeSalaryStaff');

const getSalaryStaffDetail = createAsyncAction<IApiGetSalaryStaffDetailParam>(PREFIX_ACTIONS + 'getSalaryStaffDetail');

const getPrintSummaryServiceCategory = createAsyncAction<IApiGetPrintSummaryServiceCategoryParam>(PREFIX_ACTIONS + 'getPrintSummaryServiceCategory');

const getDashboardTimeClockReport = createAsyncAction<IApiGetDashboardTimeClockReportParams>(PREFIX_ACTIONS + 'getDashboardTimeClockReport');

const getTimeClockReportHistory = createAsyncAction<IApiGetTimeClockReportHistoryParams>(PREFIX_ACTIONS + 'getTimeClockReportHistory');

const getPrintSalaryStaffDetailOneDay = createAsyncAction<IApiGetPrintSalaryStaffDetailParams>(PREFIX_ACTIONS + 'getPrintSalaryStaffDetailOneDay');

const getPrintSalaryStaffDetailRangeDate = createAsyncAction<IApiGetPrintSalaryStaffDetailParams>(PREFIX_ACTIONS + 'getPrintSalaryStaffDetailRangeDate');

const getPrintSalarySummaryStaffOneDay = createAsyncAction<IApiGetPrintSalarySummaryStaffOneDayParams>(PREFIX_ACTIONS + 'getPrintSalarySummaryStaffOneDay');

const getPrintSalarySummaryStaffRangeDate = createAsyncAction<IApiGetPrintSalarySummaryStaffRangeDateParams>(PREFIX_ACTIONS + 'getPrintSalarySummaryStaffRangeDate');

/**
 * TODO:
 * please replace report to your feature name
 * ex:
 * reportActions => bookingActions
 */

const reportActions = {
  getDemoData,
  getSummarySalon,
  getPrintSummarySalon,
  getSummaryServiceCategory,
  getSummaryTicket,
  getTopRankingService,
  getSummarySalaryStaff,
  getSummarySalarySalon,
  getSummaryDashboardSalaryStaff,
  setParams,
  setCalendarViewType,
  setFilterTypeSalaryStaff,
  getSalaryStaffDetail,
  getPrintSummaryServiceCategory,
  getDashboardTimeClockReport,
  getTimeClockReportHistory,
  getPrintSalaryStaffDetailOneDay,
  getPrintSalaryStaffDetailRangeDate,
  getPrintSalarySummaryStaffOneDay,
  getPrintSalarySummaryStaffRangeDate
};

export default reportActions;

