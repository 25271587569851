import { DatePicker as AntDatePickerUI, Row } from 'antd';
import { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import Text from 'components/Text';
import appointmentActions from 'features/appointment/services/actions';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { DATE_FORMAT, DATE_FORMAT_VIEW } from 'features/appointment/utils/format';
import { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { momentTimezone } from 'utils/time';
const AntDatePicker = AntDatePickerUI.generatePicker<Moment>(momentGenerateConfig);

type IDatePickerProps = PickerDateProps<Moment> & {
  viewType: CALENDAR_VIEW_TYPE;
};
const AppointmentDatePicker: React.FC<IDatePickerProps> = ({ viewType }) => {
  const dispatch = useAppDispatch();
  const params = appointmentSelectors.table.params();


  const startDate = useMemo(() => {
    return params?.startTime ? momentTimezone(params?.startTime) : null;
  }, [params]);

  const endDate = useMemo(() => {
    return params?.endTime ? momentTimezone(params?.endTime) : null;
  }, [params]);


  if (viewType === CALENDAR_VIEW_TYPE.DAY_VIEW) {
    const onChange: IDatePickerProps['onChange'] = (date) => {
      if (!date) return;
      dispatch(appointmentActions.setTableParams({
        startTime: date?.format(DATE_FORMAT),
        endTime: date?.format(DATE_FORMAT),
      }));
    };

    return (
      <Row>
        <DatePickerStyled
          onChange={onChange}
          size='small'
          inputReadOnly
          clearIcon={false}
          value={startDate}
          inputRender={(props: any) => (
            <InputUI {...props} justify={'space-between'} align={'middle'} >
              <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>
              <Text variant='CONTENT_2' color='text_3' >{startDate?.format(DATE_FORMAT_VIEW)}</Text>
            </InputUI>
          )}
        />
      </Row>
    );
  }

  if (viewType === CALENDAR_VIEW_TYPE.WEEK_VIEW) {
    const onChange: IDatePickerProps['onChange'] = (date) => {
      if (!date) return;
      dispatch(appointmentActions.setTableParams({
        startTime: date?.startOf('week').format(DATE_FORMAT),
        endTime: date?.endOf('week').format(DATE_FORMAT),
      }));
    };

    return (
      <Row>
        <DatePickerStyled
          onChange={onChange}
          picker="week"
          size='small'
          clearIcon={false}
          inputReadOnly
          value={startDate}
          inputRender={(props: any) => (
            <InputUI {...props} justify={'space-between'} align={'middle'} >
              <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>
              <Text variant='CONTENT_2' color='text_3' >{startDate?.format(DATE_FORMAT_VIEW)} - {endDate?.format(DATE_FORMAT_VIEW)}</Text>
            </InputUI>
          )}
        />
      </Row>
    );
  }

  if (viewType === CALENDAR_VIEW_TYPE.MONTH_VIEW) {
    const onChange: IDatePickerProps['onChange'] = (date) => {
      if (!date) return;
      dispatch(appointmentActions.setTableParams({
        startTime: date?.startOf('month').format(DATE_FORMAT),
        endTime: date?.endOf('month').format(DATE_FORMAT),
      }));
    };
    return (
      <Row>
        <DatePickerStyled
          clearIcon={false}
          inputReadOnly
          value={startDate}
          onChange={onChange}
          picker="month"
          inputRender={(props: any) => (
            <InputUI {...props} justify={'space-between'} align={'middle'} >
              <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>
              <Text variant='CONTENT_2' color='text_3' >{startDate?.format('MM/YYYY')}</Text>
            </InputUI>
          )}
          size='small'
        />
      </Row>
    );
  }

  return null;
};

export default AppointmentDatePicker;
type DatePickerStyledProps = {}
const DatePickerStyled = styled(AntDatePicker) <DatePickerStyledProps>`
  min-width: 20rem;
`;

const InputUI = styled(Row)`
flex: 1;
padding: 0.5rem;
margin-right: 0.5rem;
-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;