import { AutoComplete, Avatar, Col, Input, InputRef, Row } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import Empty from 'components/Empty';
import Text from 'components/Text';
import { get } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
const ALL_VALUE = 'ALL_VALUE';

type IStaffItemOpt = {
  value: string;
  name: string;
  keywords: string[];
  label: JSX.Element | string;
};

type Props = {
  onPickStaff: (option: IStaffItemData) => void;
  onPickAll: () => void;
};
const StaffSearchInput = ({ onPickStaff, onPickAll }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const staffListStore = shopSelectors.data.staffs();
  const initialOptions = useMemo(() => {
    const staffs: IStaffItemOpt[] = [{
      value: ALL_VALUE,
      name: 'All staffs',
      keywords: [],
      label: (
        <Row align={'middle'} gutter={[8, 8]}>
          <Col><Avatar>all</Avatar></Col>
          <Col><Text>All staffs</Text></Col>
        </Row>
      ),
    }];
    staffListStore.forEach(o => {
      const staffName = o.firstName + ' ' + o.lastName;
      const item = {
        value: o.id,
        name: staffName,
        keywords: [staffName?.toLowerCase(), o.firstName?.toLowerCase(), o.lastName?.toLowerCase()],
        label: <Row align={'middle'} gutter={[8, 8]}>
          <Col><Avatar src={o.urlImage}>{get(o.firstName, [0], '')}</Avatar></Col>
          <Col><Text>{staffName}</Text></Col>
        </Row>,
      } as IStaffItemOpt;

      staffs.push(item);
    });
    return staffs;
  }, [staffListStore]);

  const [searchText, setSearchText] = useState<string>('All staffs');

  const options = useMemo(() => {
    if (!searchText || searchText.toLowerCase() === 'all staffs') return initialOptions;
    return initialOptions.filter((item) => item.value === ALL_VALUE ? true : item.keywords.some(kw => kw?.includes(searchText?.toLocaleLowerCase())));
  }, [initialOptions, searchText]);

  const handleSwitchCustomer = (data: string) => {
    inputRef?.current?.blur();

    if (data === ALL_VALUE) {
      setSearchText('All staffs');
      onPickAll();
      return;
    }

    const result = staffListStore?.find((item) => item.id === data);
    const staffName = result?.firstName + ' ' + result?.lastName;
    setSearchText(staffName || '');
    if (!result) return;
    onPickStaff(result);
  };

  return (
    <AutoComplete
      style={{ width: '100%', height: 'unset' }}
      options={options}
      onSelect={handleSwitchCustomer}
      value={searchText}
      onChange={setSearchText}
      notFoundContent={<Empty description='Not found' />}
    >
      <Input
        ref={inputRef}
        autoFocus
        suffix={<SearchIcon />}
        placeholder='Staff name'
      />
    </AutoComplete>
  );
};

export default StaffSearchInput;
