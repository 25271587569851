import { Tabs, TabsProps } from 'antd';
import Box from 'components/Box';
import Sidebar from 'components/layout/Sidebar';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ClockReport from './ClockReport';
import EmployeeSalary from './EmployeeSalary';
import SalonReport from './SalonReport';
import ServicesReport from './ServicesReport';
import ReportPageStyled from './styles';

type IReportPageProps = any;
const items: TabsProps['items'] = [
  {
    key: 'salon-report',
    label: <Box pl="4">INCOME REPORTS</Box>,
    children: <SalonReport />,
  },
  {
    key: 'services-report',
    label: 'Services Reports',
    children: <ServicesReport />,
  },
  {
    key: 'employee-salary',
    label: 'PAYROLL REPORTS',
    children: <EmployeeSalary />,
  },
  {
    key: 'clock-report',
    label: 'TIME CLOCK REPORTS',
    children: <ClockReport />,
  }
];

const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
  <StickyTabStyled>
    <DefaultTabBar {...props} className='tab-no-margin' />
  </StickyTabStyled>
);

const ReportPage: React.FC<IReportPageProps> = () => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const onChange = (key: string) => {
    navigate(`/store/${storage.shop_id.get()}/report/${key}`);
  };

  return (
    <ReportPageStyled>
      <Sidebar />
      <BodyStyled>
        <Tabs
          activeKey={tab}
          renderTabBar={renderTabBar}
          items={items}
          onChange={onChange}
          defaultActiveKey='salon-report'
          style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 18 }}
        />
      </BodyStyled>
    </ReportPageStyled>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
`;
const StickyTabStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  .ant-tabs-nav {
    margin: 0 !important;
  }
`;

export default ReportPage;
