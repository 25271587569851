import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import DaySchedule from './StaffSchedule/DaySchedule';
import dayjs from 'dayjs';
import Box from 'components/Box';
import DaysOffBox from './StaffSchedule/DaysOffBox';
import ButtonGroup from './ButtonGroup';
import { IStaffScheduler } from 'features/settingStaff/services/types/staff';
import Text from 'components/Text';
import { set } from 'lodash';

type IStaffScheduleProps = {};

const StaffSchedule: React.FC<IStaffScheduleProps> = () => {
  const [initialForm, setInitialForm] = useState<IStaffScheduler>();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const staffSelected = settingStaffSelectors.selectedStaffData.data();
  const staffSelectedScheduler =
    settingStaffSelectors.selectedStaffData.dataScheduler();

  const processInitialValues = (scheduler: IStaffScheduler) => {
    const initialValues = { ...scheduler };
    Object.keys(scheduler).forEach((key) => {
      if (key.endsWith('StartTime') || key.endsWith('EndTime')) {
        initialValues[key] = dayjs(scheduler[key], 'HH:mm');
      } else {
        initialValues[key] = scheduler[key];
      }
    });
    return initialValues;
  };

  const onFinish = async (values: IStaffScheduler) => {
    if (values) {
      const data = { ...values };
      Object.keys(data).forEach((key) => {
        if (key.endsWith('StartTime') || key.endsWith('EndTime')) {
          data[key] = dayjs(values[key], 'HH:mm').format('HH:mm');
        } else {
          data[key] = values[key];
        }
      });
      
      set(data, 'staffId', staffSelectedScheduler.staffId);
      
      dispatch(settingStaffActions.updateStaffScheduler.fetch(data));
    }
  };

  useEffect(() => {
    if (staffSelectedScheduler) {
      const dataForm = processInitialValues(staffSelectedScheduler);
      setInitialForm(dataForm);
      form.setFieldsValue(dataForm);
    }
  }, [staffSelectedScheduler]);

  useEffect(() => {
    if (staffSelected.id) {
      dispatch(settingStaffActions.staffScheduler.fetch(staffSelected.id));
      const params = {
        staffId: staffSelected.id,
      };
      dispatch(settingStaffActions.setStaffDayOffParams(params));
    }
  }, [staffSelected]);

  return (
    <StaffScheduleStyled>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialForm}
      >
        <Text variant="H7" color="text_3" mb={1}>
          Regular Time
        </Text>
        <RowScheduleStyled>
          <DaySchedule
            day="Monday"
            enable="enableMon"
            start="monStartTime"
            end="monEndTime"
          />
          <DaySchedule
            day="Tuesday"
            enable="enableTue"
            start="tueStartTime"
            end="tueEndTime"
          />
          <DaySchedule
            day="Wednesday"
            enable="enableWed"
            start="wedStartTime"
            end="wedEndTime"
          />
          <DaySchedule
            day="Thursday"
            enable="enableThu"
            start="thuStartTime"
            end="thuEndTime"
          />
          <DaySchedule
            day="Friday"
            enable="enableFri"
            start="friStartTime"
            end="friEndTime"
          />
          <DaySchedule
            day="Saturday"
            enable="enableSat"
            start="satStartTime"
            end="satEndTime"
          />
          <DaySchedule
            day="Sunday"
            enable="enableSun"
            start="sunStartTime"
            end="sunEndTime"
          />
        </RowScheduleStyled>
        <DaysOffBox />
        <Box mt="3">
          <ButtonGroup />
        </Box>
      </Form>
    </StaffScheduleStyled>
  );
};

export default StaffSchedule;
const StaffScheduleStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-0, #fff);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;
const RowScheduleStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-0, #fff);
  display: flex;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .header {
    border-bottom: 1px solid var(--line-line-3, #c9cdd4);
  }
  .item-schedule {
    width: 100%;
    border-right: 1px solid var(--line-line-3, #c9cdd4);
    input {
      text-align: center;
    }
  }
  .item-schedule:first-child .header {
    border-top-left-radius: 5px;
  }
  .item-schedule:last-child .header {
    border-top-right-radius: 5px;
  }
  .item-schedule:last-child {
    border-right: none;
  }
`;
