import CarouserBox from 'components/Carosel/CarouserBox';
import ServiceItem from 'components/ServiceItem';
import Spin from 'components/Spin';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { IServiceCategory } from 'features/cashier/services/types/api';
import { useCallback } from 'react';
import { useAppDispatch } from 'store/hooks';

const ActiveServices = () => {
  const loading = cashierSelectors.loading.getCategories();
  const dispatch = useAppDispatch();
  const services = cashierSelectors.getServicesByCateActive();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();

  const handleClickService = useCallback((serviceItem: IServiceCategory) => () => {
    dispatch(cashierActions.selectServiceItem(serviceItem));
  }, []);

  const renderItem = useCallback((item: IServiceCategory) => {
    const itemSelected = serviceIdsSelected.find(o => o.id === item.id);
    return (
      <ServiceItem
        size="LARGE"
        price={item.priceSell}
        service={item.serviceName}
        onClick={handleClickService(item)}
        selected={!!itemSelected}
        count={itemSelected?.count}
      />
    );
  }, [handleClickService, serviceIdsSelected]);

  return (
    <>
      <CarouserBox
        content="SERVICES"
        list={services || []}
        rowPerPage={8}
        colSpan={12}
        itemContent={renderItem}
      />
      {loading && <Spin />}
    </>
  );
};

export default ActiveServices;