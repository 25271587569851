import React, { useCallback, useEffect, useState } from 'react';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import DiscountMethodModal, { ISubmitModalDiscount } from './DiscountMethodModal';
type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: ISubmitModalDiscount;
  initialAmount: number;
};
const DiscountTicket = ({ visible, onClose, onSubmit, initialAmount = 0 }: Props) => {
  const [visibleAdmin, setVisibleAdmin] = useState(true);

  useEffect(() => {
    if (visible) setVisibleAdmin(true);
  }, [visible]);

  const onVerifyAdminSuccess = useCallback(() => {
    setVisibleAdmin(false);
  }, []);

  if (!visible) return null;
  if (visibleAdmin) return <RequestAdminLocalPW visible onCorrect={onVerifyAdminSuccess} onClose={onClose} />;

  return <DiscountMethodModal
    visible
    onClose={onClose}
    onSubmit={onSubmit}
    initialAmount={initialAmount}
  />;
};

export default DiscountTicket;
