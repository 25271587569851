export interface ITaxParams {
  id?: string;
  saleTaxPercent: number;
  enableSaleTax: boolean;
  enableUseTax: boolean;
  useTaxPercent: number;
}

export interface IShopDiscountParams {
  id?: string;
  discountOwner: number;
  discountStaff: number;
}

export interface ISpecialDiscount {
  id: number;
  discountName: string;
  ownerDiscountPercent: number;
  staffDiscountPercent: number;
  discountType: 'PERCENT' | 'MONEY' | string;
  discountValue: number;
}

export interface IPaymentMethodParams {
  id: number;
  methodType: string;
  icon: string | null;
  methodName: string;
  phone: string | null;
  email: string | null;
  name: string;
  imageQrCode: string;
  active: boolean;
}

export enum RewardType {
  LOYALTY_REWARD = 'LOYALTY_REWARD', // diem to dollar
  LOYALTY_REDEMPTION = 'LOYALTY_REDEMPTION', // dollar to point
  LOYALTY_ENROLLMENT_VISIT = 'LOYALTY_ENROLLMENT_VISIT', // cong point cho khach check in
  LOYALTY_ENROLLMENT_REFERRAL = 'LOYALTY_ENROLLMENT_REFERRAL', // cong point cho customer referral
}

export interface IReward {
  id: string;
  minBill: number;
  amount: number;
  point: number;
  type: RewardType;
  active: boolean;
}

export type ILoyaltyDataBody = {
  loyaltyType: RewardType;
  enable: boolean;
  point: number;
  minBill: number;
  amount: number;
};
