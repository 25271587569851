import { Col, Row } from 'antd';
import Empty from 'components/Empty';
import Spin from 'components/Spin';
import Text from 'components/Text';
import userSelectors from 'features/user/services/selectors';
import { styled } from 'styled-components';
import TicketItem from './Item';
import ButtonScanQR from './ButtonScanQR';

const TicketWaitingList = () => {
  const data = userSelectors.getBillWaitingTickets();
  const loading = userSelectors.getBillWaitingTicketLoading();
  const isClockIn = userSelectors.staff.isClockIn();
  const RowData = () => {
    if (loading) return <Spin />;

    return (
      data.length > 0 ?
        <Row gutter={[14, 14]}>
          {
            data.map(o => (
              <Col key={o.billId} span={8}>
                <TicketItem data={o} viewOnly={!isClockIn} />
              </Col>
            ))
          }
        </Row>
        : <Empty description='Empty ticket' centerFloating />
    );
  };
  return (
    <Styled>
      <Row>
        <Text variant='H7' mb={1.5} mr={0.5}>PENDING TICKETS {data.length > 0 ? `(${data.length})` : ''}</Text>
        <ButtonScanQR />
      </Row>
      <RowData />
    </Styled>
  );
};

export default TicketWaitingList;

const Styled = styled.div`
  padding: 1.5rem;
`;