import ActionBtn from 'components/ActionBtn';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import cashierSelectors from 'features/cashier/services/selectors';
import { useParams } from 'react-router-dom';

const  ButtonPrint = () => {
  const { id = '' } = useParams();
  const ticketContext = useTicketWrapper();
  const ticketDetailStore = cashierSelectors.getTicketDetails();

  return (
    <ActionBtn
      ntype="SECONDARY"
      icon="print"
      label='Print'
      onClick={() => ticketContext.print(id)}
      disabled={!!ticketDetailStore?.checkInId || !ticketDetailStore?.items?.length}
    />
  );
};

export default ButtonPrint;