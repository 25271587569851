import React from 'react';
import LoginPageStyled from './styles';
import FormOwnerLogin from './components/FormOwnerLogin';
type ILoginPageProps = any;
const LoginPage: React.FC<ILoginPageProps> = () => {
  return (
    <LoginPageStyled>
      <FormOwnerLogin />
    </LoginPageStyled>
  );
};

export default LoginPage;

