import Modal from 'components/Modal';
import CurrencyKeyBoard, { useCurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';

const UpdateQuickPay = () => {
  const inputRef = useCurrencyKeyBoardRef();
  const dispatch = useAppDispatch();
  const draffQuickPay = cashierSelectors.getDraftQuickPay();
  const [amount, setAmount] = useState<number | null>();

  const handleClose = () => dispatch(cashierActions.setDraftQuickPay(null));

  useEffect(() => {
    if (!draffQuickPay) return;
    setTimeout(() => {
      inputRef.current?.setValue(0?.toFixed(2));
    }, 100);
  }, [draffQuickPay]);

  const handleSubmit = () => {
    if (!draffQuickPay) return;
    dispatch(cashierActions.updateQuickPayItem({
      staffId: draffQuickPay.staffId || '',
      itemId: draffQuickPay.itemId || '',
      value: amount || 0,
      itemName: draffQuickPay.itemName || '',
    }));
  };

  return (
    <Modal
      visible={!!draffQuickPay}
      onClose={handleClose}
      modalTitle={draffQuickPay?.itemName?.toLocaleLowerCase() === 'quick pay' ? 'ADD PRICE' : draffQuickPay?.itemName}
      okTitle="Done"
      onSubmit={handleSubmit}
      disableOk={!amount}
    >
      <CurrencyKeyBoard ref={inputRef} onChange={setAmount} value={amount} />
    </Modal>
  );
};

export default UpdateQuickPay;
