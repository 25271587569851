import fetch from 'services/request';
import { IApiGetTurnDetailParam, IApiGetTurnParam, IApiUpdateTurnDetailParam, ISyncWaitngToTicket } from './types/api';

export const getTurn = (params: IApiGetTurnParam) => {
  return fetch({
    method: 'get',
    url: '/api/v1/turn',
    params,
  });
};

export const getTurnDetail = (params: IApiGetTurnDetailParam) => {
  return fetch({
    method: 'get',
    url: `/api/v1/turn/${params.id}`,
  });
};

export const getTurnStaffsAvailable = (params: IApiGetTurnParam) => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/list-available-clock-in',
    params
  });
};

export const updateTurnDetail = (body: IApiUpdateTurnDetailParam) => {
  return fetch({
    method: 'put',
    url: '/api/v1/turn',
    body,
    pinCode: body.pinCode
  });
};
export const syncWaitngToTicket = (body: ISyncWaitngToTicket) => {
  return fetch({
    method: 'post',
    url: '/api/v1/bill/sync-waiting-to-ticket',
    body
  });
};

export const clearAllTurn = () => {
  return fetch({
    method: 'put',
    url: '/api/v1/turn/clear-all',
  });
};

/**
 * TODO:
 * please replace TURN to your feature name
 * ex:
 * FEATURE_NAMEApis => bookingApis
 */
const turnApis = {
  getTurn,
  getTurnDetail,
  getTurnStaffsAvailable,
  updateTurnDetail,
  syncWaitngToTicket,
  clearAllTurn
};

export default turnApis;
