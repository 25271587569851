import Button from 'components/Button';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { useCurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';
import { ModalAddStaff } from '../RightMainBody/GroupActions/ButtonAddStaff';
import { cloneDeep, first } from 'lodash';
import { IStaff } from 'features/cashier/services/types/api';

const ButtonQuickPay = () => {
  const inputRef = useCurrencyKeyBoardRef();
  const dispatch = useAppDispatch();
  const selectedItems = cashierSelectors.getSelectedItems();
  const [visibleChooseStaff, setVisibleChooseStaff] = useState(false);
  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState<number | null>();
  const [, startTransition] = useTransition();
  const handleClose = () => startTransition(() => {
    setVisible(false);
    setAmount(undefined);
  });

  const handleSubmit = () => {
    if (!amount) return;
    setTimeout(() => { dispatch(cashierActions.addQuickPay(amount)); }, 200);
    handleClose();
  };

  const handleOpen = () => {
    if (selectedItems.length === 0 || !first(selectedItems)?.staffId) {
      setVisibleChooseStaff(true);
      return;
    }

    setVisible(true);
    setTimeout(() => inputRef.current?.setValue(''), 100);
  };

  const onPickStaff = (staff: IStaff) => {
    dispatch(cashierActions.addStaffItem(cloneDeep(staff)));
    setVisibleChooseStaff(false);
    setVisible(true);
    setTimeout(() => inputRef.current?.setValue(''), 100);
  };

  return (
    <>
      <Button block ntype="SECONDARY" onClick={handleOpen}>
        Add Price
      </Button>
      <Modal
        visible={visible}
        onClose={handleClose}
        modalTitle='ADD PRICE'
        okTitle="Done"
        onSubmit={handleSubmit}
        disableOk={!amount}
      >
        {visible && <CurrencyKeyBoard ref={inputRef} onChange={setAmount} value={amount} />}
      </Modal>
      {visibleChooseStaff && <ModalAddStaff
        visible={visibleChooseStaff}
        onPickStaff={onPickStaff}
        onClose={() => setVisibleChooseStaff(false)}
      />}
    </>
  );
};

export default ButtonQuickPay;
