import React from 'react';
import styled from 'styled-components';
import { Form, Select, Space } from 'antd';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import PercentageInputPrecision from 'components/PercentageInputPrecision';
type IAmountFormItemProps = {
  name: number;
};
const AmountFormItem: React.FC<IAmountFormItemProps> = ({ name }) => {
  const form = Form.useFormInstance();
  const special_discount = Form.useWatch('special_discount', form);
  const discountType = special_discount?.[name]?.discountType;

  return (
    <AmountFormItemStyled block>
      {discountType === 'MONEY' && (
        <div style={{ width: '50%' }}>
          <FormItemStyled name={[name, 'discountValue']} noStyle>
            <CurrencyInputPrecision
              height="calc(4rem - 2px)"
              bordered={false}
            />
          </FormItemStyled>
        </div>
      )}

      {discountType === 'PERCENT' && (
        <div style={{ width: '50%' }}>
          <PercentageInputPrecision
            height="calc(4rem - 2px)"
            size="large"
            name={[name, 'discountValue']}
            bordered={false}
            noStyle
          />
        </div>
      )}
      <Form.Item
        name={[name, 'discountType']}
        style={{
          width: '50%',
          margin: 0,
        }}
      >
        <Select
          bordered={false}
          style={{
            backgroundColor: '#E5E6EB',
            borderRadius: '5px',
            height: '100%',
            flexGrow: 1,
            width: '100%',
          }}
        >
          <Select.Option value="MONEY">Dollar</Select.Option>
          <Select.Option value="PERCENT">Percent</Select.Option>
        </Select>
      </Form.Item>
    </AmountFormItemStyled>
  );
};

export default AmountFormItem;
const AmountFormItemStyled = styled(Space.Compact)`
  display: flex;
  height: calc(100%);
  border: 1px solid var(--line-line-3, #86909c);
  border-radius: 5px;
  box-sizing: border-box;
  .ant-row {
    height: 100%;
  }
  .ant-form-item-control-input {
    height: 100%;
  }
  .ant-form-item-control-input-content {
    height: 100%;
  }
`;
const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;
