import { Card, Col, Progress, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { ReportType } from '../../constants';

interface ITopServicesSale { }
const TopServicesSale: React.FC<ITopServicesSale> = () => {
    const dispatch = useAppDispatch();
    const topRankingService = reportSelectors.getTopRankingService();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.SERVICES];
    useEffect(
        () => {
            if (dataAppointment?.startTime && dataAppointment?.endTime) {
                dispatch(reportActions.getTopRankingService.fetch({
                    startDate: dataAppointment?.startTime,
                    endDate: dataAppointment?.endTime
                }));
            }
        }, [dataAppointment]
    );
    return (
        <StyledCard>
            <Box>
                <Text variant="H5">TOP 5 RANKING SALES</Text>
                {topRankingService.map((item, index) => {
                    //   "serviceId": "168bf7e3-bfed-47e3-ae3a-2a330d2be8bd",
                    //   "totalSale": 200,
                    //   "percent": 5.0,
                    //   "serviceName": "Kha ha ma",
                    //   "serviceTimes": 10,
                    //   "couponUsed": 5
                    return (
                        <ItemServiceSale
                            key={index}
                            percent={item?.percent}
                            title={item?.serviceName}
                            quantity={item?.serviceTimes}
                            total={item?.totalSale}
                            couponUsed={item?.couponUsed}
                        />
                    );
                })}
            </Box>
        </StyledCard>
    );
};

interface IItemServiceSale {
    percent: number,
    title: string,
    quantity: number,
    total: number,
    couponUsed: number,
}
const ItemServiceSale: React.FC<IItemServiceSale> = ({
    percent,
    title,
    quantity,
    total,
    couponUsed
}) => {
    return (
        <StyledItemServiceSale>
            <StyledContent>
                <StyledProgress type="circle" percent={percent} size={55} strokeColor='#FF8890' />
                <StyledCol>
                    <TextTitle>{title}</TextTitle>
                    <TextSub>Service: {quantity} times</TextSub>
                    <TextSub>Coupon Code Used: {couponUsed} </TextSub>
                </StyledCol>
            </StyledContent>
            <TextTotal>{formatCurrency(total)}</TextTotal>
        </StyledItemServiceSale>
    );
};
export default TopServicesSale;

const StyledCard = styled(Card)`
    border-radius: 3.358px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0.672px solid #86909C;
    background: #FFF;
    height: 100%;
`;

const StyledItemServiceSale = styled(Row)`
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    &:nth-child(2) {
        margin-top: 1rem;
    };
    &:not(:last-child) {
        border-bottom: 1px dashed #C9CDD4;
    }
`;
const StyledContent = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
`;
const StyledCol = styled(Col)`
    margin-left: 10px;
    display: flex;
    flex-direction: column;
`;

const StyledProgress = styled(Progress)`
color: var(--text-text-3, #1D2129);
.ant-progress-text {
    text-align: center;
    /* Content/Content 2 - 16 semibold */
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
`;

const TextTitle = styled.span`
    color: var(--text-text-3, #1D2129);
    /* Title/H8 - 18 regular */
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;
const TextSub = styled.span`
    color: var(--text-text-2, #767676);

    /* Body/Body 1 - 14 regular - 21 */
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const TextTotal = styled.span`
    color: var(--text-text-3, #1D2129);
    text-align: right;

    /* Title/H9 - 18 semibold */
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
`;