import moment from 'moment';
import { IDayOffParams } from './types/api';

/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR FEATURE NAME HERE
 * ex:
 *  NAME_REDUCER = 'NAME_REDUCER'
 *   =>
 *  NAME_REDUCER = 'booking'
 */
export const NAME_REDUCER = 'setting_service';

/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR PREFIX ACTION NAME HERE
 * ex:
 *  PREFIX_ACTIONS = 'PREFIX_ACTIONS';
 *   =>
 *  PREFIX_ACTIONS = 'booking_feature_';
 */
export const PREFIX_ACTIONS = 'setting_service_feature_';

export const PATH_LOADING = {
  editCategory: `loading.${NAME_REDUCER}.editCategory`,
  createCategory: `loading.${NAME_REDUCER}.createCategory`,
  createOrUpdateServiceAddOn: `loading.${NAME_REDUCER}.createOrUpdateServiceAddOn`,
  editServiceAddOn: `loading.${NAME_REDUCER}.editServiceAddOn`,
  addMoreServiceByCate: `loading.${NAME_REDUCER}.addMoreServiceByCate`,
  getListCategoriesForProduct: `loading.${NAME_REDUCER}.getListCategoriesForProduct`,
  getProductsByCategoryId: `loading.${NAME_REDUCER}.getProductsByCategoryId`,
  createProduct: `loading.${NAME_REDUCER}.createProduct`,
  updateProduct: `loading.${NAME_REDUCER}.updateProduct`,
  deleteProduct: `loading.${NAME_REDUCER}.deleteProduct`,
  getVIP: `loading.${NAME_REDUCER}.getVIP`,
  createVIP: `loading.${NAME_REDUCER}.createVIP`,
  updateVIP: `loading.${NAME_REDUCER}.updateVIP`,
  deleteVIP: `loading.${NAME_REDUCER}.deleteVIP`,
  changeCommissionPrice: `loading.${NAME_REDUCER}.changeCommissionPrice`,
  getTurnSetting: `loading.${NAME_REDUCER}.getTurnSetting`,
  updateTurnSettingTiket: `loading.${NAME_REDUCER}.updateTurnSettingTiket`,
  getLateForWork: `loading.${NAME_REDUCER}.getLateForWork`,
  updateLateForWork: `loading.${NAME_REDUCER}.updateLateForWork`,
  getTax: `loading.${NAME_REDUCER}.getTax`,
  getDiscount: `loading.${NAME_REDUCER}.getDiscount`,
  getSpecialDiscount: `loading.${NAME_REDUCER}.getSpecialDiscount`,
  getLoyaltyReward: `loading.${NAME_REDUCER}.getLoyaltyReward`,
  getOtherPaymentMethod: `loading.${NAME_REDUCER}.getOtherPaymentMethod`,
  getPolicyTerm: `loading.${NAME_REDUCER}.getPolicyTerm`,
  getGiftCard: `loading.${NAME_REDUCER}.getGiftCard`,
};

export const dayOffParams: IDayOffParams = {
  startTime: moment().startOf('year').format('MM-DD-YYYY'),
  endTime: moment().endOf('year').format('MM-DD-YYYY'),
};
