import { AxiosResponse } from 'axios';
import fetch from 'services/request';
import { IConfirmCheckInParams, ISignUpForm, IStaffQueryParams } from './types/api';
import { IAppointmentRes, ICheckedInRes, IStaffRes } from './types/demoData';

// const baseURL = 'api/v1/:merchant_code/demoData';

export const getDemoData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data: { data: [] } });
    }, 1000);
  });
  // return fetch({
  //   method: 'get',
  //   url: `${baseURL}/getDemoData`,
  //   params: params as any
  // });
};

export const checkIn = (phoneNumber: string): Promise<AxiosResponse<ICheckedInRes>> => {
  const params = {
    phoneNumber
  };
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-info-by-phone',
    params: params as any
  });
};


export const customerSignUp = (body: ISignUpForm): Promise<AxiosResponse<ICheckedInRes>> => {
  return fetch({
    method: 'post',
    url: 'api/v1/customer/create',
    body: body,
    autoToast: false,
  });
};

export const getStaffList = (): Promise<AxiosResponse<IStaffRes>> => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/get-all-by-shop',
  });
};


export const getAppointmentDetail = (customerId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-info-appointment-by-customer',
    params: { customerId }
  });
};

export const confirmWalkIn = (body: IConfirmCheckInParams): Promise<AxiosResponse<IAppointmentRes>> => {
  return fetch({
    method: 'post',
    url: 'api/v1/check-in/confirm',
    body: body
  });
};


const getStaffListCondition = ({ position }: IStaffQueryParams): Promise<AxiosResponse<IAppointmentRes>> => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/get-all-by-shop',
    params: {
      staffType: position
    }
  });
};

const getCheckInDetail = (id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/detail/' + id,
  });
};

const checkInApis = {
  getDemoData,
  checkIn,
  getStaffList,
  getStaffListCondition,
  customerSignUp,
  getAppointmentDetail,
  confirmWalkIn,
  getCheckInDetail,
};

export default checkInApis;
