import { Col, Row } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import CategoriesChart from './components/CategoriesChart';
import TopServicesSale from './components/TopServicesSale';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import AppointmentDatePicker from '../components/DatePicker';
import SwitchCalendarType from '../components/SwitchCalendarType';
import { useAppDispatch } from 'store/hooks';
import moment from 'moment';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { ReportType } from '../constants';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';

type IServicesReportProps = {};
const ServicesReport: React.FC<IServicesReportProps> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.SERVICES];
    useEffect(() => {
        dispatch(reportActions.setParams({
            [ReportType.SERVICES]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
            }
        }));
    }, []);

    const onPrint = () => {
        navigate(`/store/${storage.shop_id.get()}/report/print/services-report`);
    };
    return (
        <ServicesReportStyled>
            {/* header */}
            <HeaderBox>
                <Box>
                    <Text variant="H5">SERVICES REPORTS</Text>
                </Box>
                <HeaderBox>
                    <AppointmentDatePicker size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.SERVICES} />
                    <SwitchCalendarType actions={reportActions} selectors={reportSelectors} type={ReportType.SERVICES} />
                    <Button icon="print" onClick={onPrint}>
                        Preview
                    </Button>
                </HeaderBox>
            </HeaderBox>
            {/* container */}
            <BodyBox gutter={[24, 24]}>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={16}
                    xxl={16}
                >
                    <CategoriesChart />
                </Col>
                <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={8}
                    xxl={8}
                >
                    <TopServicesSale />
                </Col>
            </BodyBox>
        </ServicesReportStyled>
    );
};

export default ServicesReport;
const ServicesReportStyled = styled.div`
    min-height: 100vh;
    background-color: ${colorTheme.fill_3};
    padding: 1.5rem;
    direction: flex;
    flex-direction: column;
    gap: 1.5rem;
`;

const HeaderBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
`;

const BodyBox = styled(Row)`
    margin: 1rem 0;
`;