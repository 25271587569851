import { Input, Select } from 'antd';
import Icon from 'assets/Icons';
import CurrencyInput, { CurrencyInputFormStyled } from 'components/CurrencyInputMasked';
import { FormItem, useFormInstance } from 'components/Form';
import moment from 'moment';
import { useMemo } from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { styled } from 'styled-components';
export type IssueFormValues = {
  'staff': IStaffItemData,
  'giftNumber': string,
  'amount': number,
  'expiration': number,
}
const IssueGiftCard = () => {
  const form = useFormInstance();
  const onScan = () => undefined;

  const periodDates = useMemo(() => [
    { label: '1 month', value: moment().add('month', 1).valueOf(), },
    { label: '3 month', value: moment().add('month', 3).valueOf(), },
    { label: '6 month', value: moment().add('month', 6).valueOf(), },
    { label: '12 month', value: moment().add('month', 12).valueOf(), },
    { label: 'No limit', value: moment('9999-12-12').valueOf(), },
  ], []);

  return (
    <IssueGiftCardStyled>
      <FormItem label={'Gift Number'} name={'giftNumber'} rules={[{ required: true }]}>
        <Input
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          placeholder='0'
          addonAfter={
            <button onClick={onScan}>
              {<Icon type={'QR'} />}
            </button>
          }
        />
      </FormItem>
      <FormItem label='Amount' name={'amount'} rules={[{ required: true }]}>
        <CurrencyInputFormStyled>
          <CurrencyInput onChange={val => form.setFieldValue('amount', val)} />
        </CurrencyInputFormStyled>
      </FormItem>
      <FormItem label={'Expiration'} name={'expiration'} rules={[{ required: true }]}>
        <Select options={periodDates} />
      </FormItem>
    </IssueGiftCardStyled>
  );
};

export default IssueGiftCard;
const IssueGiftCardStyled = styled.div`
.ant-input-number {
  width: 100%;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input-wrap {
  height: 100%;
  input {
    height: 100%;
    color: var(--text-text-3, #1D2129);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.ant-input {
  height: 3.5rem;
}
`;