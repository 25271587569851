import { Col, Input, InputRef, Row } from 'antd';
import SearchIcon from 'assets/Icons/search';
import AvatarBox from 'components/AvatarBox';
import staffAvatar from 'features/ticketList/pages/TicketDetailsPage/images/staff_avatar.png';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';

type ISwitchStaffProps = {
  onPickStaff: (option: IStaffItemData) => void;
};
const SwitchStaff: React.FC<ISwitchStaffProps> = ({ onPickStaff }) => {
  const inputRef = useRef<InputRef>(null);
  const staffList = shopSelectors.data.staffs();
  const details = ticketListSelectors.ticketDetailsData.data();
  const [searchText, setSearchText] = useState('');

  const initOptions = useMemo(() => {
    return staffList.map((o: IStaffItemData) => ({
      ...o,
      label: o.firstName,
      value: o.id,
      keyword: (o.lastName + ' ' + o.firstName).toLowerCase(),
    }));
  }, [staffList]);

  const options = useMemo(() => {
    if (!searchText.trim()) return initOptions;

    return initOptions.filter((item: { keyword: string | string[] }) =>
      item.keyword.includes(searchText.toLowerCase())
    );
  }, [initOptions, searchText]);

  const onSearch = (value: string) => {
    setSearchText(value);
  };

  const handleClickItem = (data: IStaffItemData) => {
    onPickStaff(data);
  };

  const handleCheckSelected = (id: string) => {
    const isSelected = details.items.some((item) => item.staffId === id);
    return isSelected;
  };

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 100);
  }, []);

  return (
    <SwitchStaffStyled>
      <Input
        ref={inputRef}
        suffix={<SearchIcon />}
        placeholder="Staff name"
        onChange={(e) => onSearch(e.target.value)}
      />
      <ListContainer>
        <Row gutter={[16, 16]} justify="start">
          {options.map((item: IStaffItemData) => {
            const isSelected = handleCheckSelected(item.id);
            return (
              <Col
                key={item.id}
                span={24}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 6 }}
                onClick={() => handleClickItem(item)}
              >
                <AvatarBox
                  selected={isSelected}
                  src={item.urlImage ? item.urlImage : staffAvatar}
                  name={item.firstName}
                />
              </Col>
            );
          })}
        </Row>
      </ListContainer>
    </SwitchStaffStyled>
  );
};

export default SwitchStaff;
const SwitchStaffStyled = styled.div`
  padding: 1.5rem;
`;
const ListContainer = styled.div`
  padding: 1.5rem 1rem;
  height: 20rem;
  overflow: auto;
`;
