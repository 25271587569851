import { Moment } from 'moment';
import React from 'react';
import { ButtonActions } from '../helpers';
import TimeSlotPicker, { ITimeSlotPickerRef } from './TimeSlotPicker';
type ITimeTabProps = {
  staffId: string;
  timeSlot: Moment | null;
  setTimeSlot: (dat: Moment) => void;
  next: () => void;
  back: () => void;
  timeSlotRef?: React.RefObject<ITimeSlotPickerRef>;
};

const TimeTab: React.FC<ITimeTabProps> = ({ next, back, staffId, timeSlot, setTimeSlot, timeSlotRef }) => {
  const handleBack = () => back();

  const onChooseTimeSlot = (time: Moment) => {
    setTimeSlot(time);
    next();
  };

  return (
    <>
      <TimeSlotPicker
        ref={timeSlotRef}
        onChooseTimeSlot={onChooseTimeSlot}
        staffId={staffId}
        timeSlot={timeSlot}
      />
      <ButtonActions onBack={handleBack} onOk={next} disabledOk={!timeSlot} />
    </>
  );
};

export default TimeTab;
