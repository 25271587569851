import { Avatar, Row, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Button from 'components/Button';
import Text from 'components/Text';
import { ISalaryStaffInfo } from 'features/report/services/types/reportStaff';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

interface DataType extends ISalaryStaffInfo {
    key: string;
    staffId: string;
    name: string;
    service: any;
    product: any;
    hourly: any;
    type: any;
    discount: any;
    tip: any;
    cashbonus: any;
    check: any;
    urlImage: any;
}
interface ITableEmployeeSalary {
    data: ISalaryStaffInfo[]
}

const TableEmployeeSalary: React.FC<ITableEmployeeSalary> = ({ data }) => {
    const navigate = useNavigate();
    // const { staff_id, tab } = useParams();
    const [dataTable, setDataTable] = useState<DataType[]>();
    useEffect(() => {
        setDataTable(data.map(d => {
            return {
                key: d.staffId,
                name: d.staffName,
                service: {
                    total: d.numberService,
                    price: d.totalSaleService
                },
                product: {
                    total: d.numberProduct,
                    price: d.totalSaleProduct
                },
                hourly: d.hourlyValue,
                type: {
                    name: d.salaryType,
                    price: d.commissionValue
                },
                cashbonus: d.payInCash,
                check: d.payInCheck,
                urlImage: d.avatar,
                ...d
            };
        }));
    }, [data]);

    const columns: ColumnsType<DataType> = [
        {
            title: 'NAME',
            width: 95,
            dataIndex: 'name',
            key: '1.name',
            render: (_, { name, avatar }) => (
                <NameBox>
                    <ImageContainer>
                        <Avatar
                            src={avatar}
                        >{name?.charAt(0)}</Avatar>
                    </ImageContainer>
                    <Text variant="CONTENT_1" color="text_3">
                        {name}
                    </Text>
                </NameBox>
            ),
        },
        {
            title: 'TYPE',
            dataIndex: 'type',
            key: '6.type',
            width: 100,
            render: (type: any) => (
                <ServiceBox>
                    <Text variant="CAPTION_1" color="text_3">{type.name}</Text>
                </ServiceBox>),
            align: 'center'
        },
        {
            title: 'SERVICES',
            dataIndex: 'service',
            key: '2.service',
            width: 105,
            render: (service: any) => (
                <ServiceBox>
                    <Text variant="CAPTION_1" color="text_3">{formatCurrency(service.price)}</Text>
                </ServiceBox>),
            align: 'center'
        },
        {
            title: 'PRODUCTS',
            dataIndex: 'product',
            key: '3.product',
            width: 120,
            render: (product: any) => (
                <ServiceBox>
                    <Text variant="CAPTION_1" color="text_3">{formatCurrency(product.price)}</Text>
                </ServiceBox>),
            align: 'center'
        },
        {
            title: 'SUPPLY FEES',
            dataIndex: 'supplyFees',
            key: '5.supplyFee',
            width: 110,
            render: (supplyFee) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(supplyFee)}</Text>,
            align: 'center'
        },

        {
            title: 'TIPS',
            dataIndex: 'tip',
            key: '8.tip',
            width: 95,
            render: (_, record) => <TipRow justify={'center'}>
                <Text variant="CAPTION_1" color="text_3" textAlign='center'>{formatCurrency(record.tip)}</Text>
                {record.tipFee ? <Tooltip placement="bottomRight" color="#86909C"
                    title={<>
                        <Text variant="CAPTION_3" color="text_3" textAlign='center' >Total Tip: {formatCurrency(record.tip * record.tipFee / 100)}</Text>
                        <Text variant="CAPTION_3" color="text_3">Tip Fee: {record.tipFee}%</Text>
                    </>}
                >
                    <Hint>?</Hint>
                </Tooltip> : <></>}
            </TipRow>,
            align: 'center'
        },
        {
            title: 'DISCOUNTS',
            dataIndex: 'discount',
            key: '7.discount',
            width: 120,
            render: (data: any) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(data)}</Text>,
            align: 'center'
        },
        {
            title: 'PAID OUT',
            dataIndex: 'type',
            key: '6.type',
            width: 100,
            render: (type: any) => (
                <ServiceBox>
                    <Text variant="CAPTION_2" color="text_3">{formatCurrency(type.price)}</Text>
                </ServiceBox>),
            align: 'center'
        },
        {
            title: 'CASH /BONUS',
            dataIndex: 'cashbonus',
            key: '9.cashBonus',
            width: 110,
            render: (data: any) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(data)}</Text>,
            align: 'center'
        },
        {
            title: 'CHECK',
            dataIndex: 'check',
            key: '10.check',
            width: 95,
            render: (data: any) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(data)}</Text>,
            align: 'center'
        },
        {
            title: ' ',
            width: 95,
            fixed: 'right',
            render: (data: any) => <Button size='small' ntype="LIGHT_BLUE" onClick={() => onViewDetail(data)} >DETAIL</Button>,
        }
    ];
    const onViewDetail = (data: any) => {
        navigate(
            `staff/${data.key}`
        );
    };
    return (
        <StyledTableEmployeeSalary>
            <Table
                columns={columns}
                dataSource={dataTable}
                style={{ width: '100%', padding: '1rem' }}
                rowClassName={'row-table'}
                pagination={{ pageSize: 50 }}
            />
        </StyledTableEmployeeSalary>
    );
};

export default TableEmployeeSalary;
const BORDER_WIDTH = '10px';
const BORDER_ROUND_WIDTH = '15px';


const StyledTableEmployeeSalary = styled(Row)`
    flex: 1;
    border-radius: 5px;
    border: 1px solid #86909C;
    background: #FFF;

    /* box */
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

.ant-table-thead > tr > th {
    color: #767676 !important;
    background: #FFF !important;
    border-bottom: unset !important;
    transition: unset !important;
}
.ant-table-thead > tr > th::before{
    background-color: transparent !important;
    content: "";
}
.row-table {
    
}
.row-table td {
  border-top: ${BORDER_WIDTH};
  border-bottom:  ${BORDER_WIDTH} !important;
  border-color: #FFF;
  border-style: solid;
  border-right-width: 0px;
  border-left-width: 0px;
  background-color: #F6F7FC !important;
}
.row-table td:first-child {
 border-top-left-radius: ${BORDER_ROUND_WIDTH};
 border-bottom-left-radius: ${BORDER_ROUND_WIDTH};
 border-left-width:  ${BORDER_WIDTH};
 border-right-width: 0px;
}

.row-table td:last-child {
  border-top-right-radius: ${BORDER_ROUND_WIDTH};
  border-bottom-right-radius:  ${BORDER_ROUND_WIDTH};
  border-left-width: 0px; 
  border-right-width:  ${BORDER_WIDTH};
 }
`;

const ImageContainer = styled.div`
  min-width: 40px;
  margin-right: 0.5rem;
`;

const NameBox = styled(Row)`
  align-items: center;
  flex-wrap: nowrap;
`;

const ServiceBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const TipRow = styled(Row)`
    align-items: center;
`;

const Hint = styled.div`
    display: flex;
    width: 12px;
    height: 12px;
    border-radius: 50px;
    color: rgba(245, 118, 127, 1);
    border: 1px solid rgba(245, 118, 127, 1);
    align-items: center;
    justify-content: center;
    font-size: 10px;
    margin-left: 4px;
`;