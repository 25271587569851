const Split = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_862_3877)">
        <path
          d="M8.29625 4.23353C8.42375 4.09853 8.61969 4.08306 8.79453 4.08306C9.13953 4.08446 9.485 4.08353 9.83047 4.08306C10.003 4.08165 10.1952 4.10931 10.3138 4.24853C10.482 4.42946 10.4445 4.69478 10.4502 4.92118C10.4473 9.71837 10.4497 14.5156 10.4492 19.3127C10.4473 19.4665 10.4263 19.6348 10.3138 19.751C10.2073 19.8766 10.0358 19.9127 9.87828 19.9137C9.53328 19.917 9.18828 19.9109 8.84328 19.9156C8.66422 19.9137 8.46031 19.9188 8.32016 19.7881C8.18047 19.6742 8.14625 19.4857 8.14813 19.3151C8.14719 17.2624 8.15234 15.2093 8.14578 13.1562C7.17547 13.1445 6.20516 13.1562 5.23484 13.1506C5.06938 13.1492 4.90344 13.1515 4.73844 13.1623C5.03047 13.5204 5.39422 13.8124 5.69797 14.1602C5.78422 14.2606 5.84469 14.3909 5.82969 14.5268C5.81938 14.7049 5.68859 14.8404 5.57047 14.9609C5.33422 15.1934 5.09984 15.4282 4.86734 15.664C4.75063 15.7807 4.61375 15.9031 4.43844 15.9049C4.27016 15.9209 4.12156 15.8248 4.00859 15.7109C2.90656 14.6093 1.80547 13.5059 0.702031 12.4057C0.599375 12.3049 0.53 12.1746 0.5 12.034V11.9656C0.53 11.8249 0.599375 11.6942 0.702031 11.5934C1.80594 10.4937 2.90656 9.39025 4.00812 8.28868C4.11219 8.1879 4.23875 8.09462 4.39016 8.09462C4.5575 8.08056 4.70609 8.17525 4.81906 8.28915C5.07031 8.53806 5.31781 8.79118 5.57 9.03868C5.69891 9.16712 5.83906 9.31993 5.83109 9.5154C5.83016 9.75821 5.62297 9.91899 5.47062 10.0798C5.22781 10.3338 4.95828 10.5626 4.73844 10.8373C4.90344 10.8476 5.06891 10.8504 5.23438 10.849C6.20469 10.8434 7.17547 10.8556 8.14578 10.8429C8.15234 8.79025 8.14719 6.73712 8.14813 4.68446C8.14813 4.52415 8.17344 4.34884 8.29625 4.23353Z"
          fill="#1D2129"
        />
        <path
          d="M14.9534 4.10125C15.2431 4.06234 15.5366 4.09328 15.8281 4.08437C16.0611 4.0914 16.3016 4.05672 16.5289 4.12187C16.7427 4.19359 16.8598 4.42093 16.8505 4.63797C16.8552 6.70609 16.8462 8.77468 16.8547 10.8433C17.637 10.8545 18.4203 10.8447 19.2031 10.848C19.5556 10.8437 19.9091 10.8592 20.2616 10.8372C19.963 10.4697 19.5866 10.1739 19.2814 9.81297C19.1534 9.66437 19.1309 9.43515 19.2364 9.26875C19.3456 9.1 19.5036 8.97203 19.6419 8.82859C19.858 8.62281 20.0539 8.39594 20.2812 8.20234C20.4805 8.03078 20.7959 8.07437 20.967 8.26562C22.0789 9.37328 23.1856 10.4861 24.298 11.5937C24.4002 11.6945 24.47 11.8244 24.5 11.9655V12.0339C24.4559 12.2955 24.2375 12.4633 24.0622 12.64C23.0464 13.6558 22.0297 14.6706 21.0158 15.6878C20.915 15.7839 20.802 15.8861 20.6567 15.9006C20.4875 15.9297 20.3225 15.8505 20.2053 15.7328C19.9747 15.5102 19.7539 15.2772 19.5237 15.0541C19.408 14.9359 19.2762 14.8244 19.2069 14.6711C19.1356 14.5117 19.1689 14.3186 19.2814 14.1864C19.5866 13.8255 19.963 13.5297 20.2616 13.1622C19.9091 13.1402 19.5561 13.1556 19.2031 13.1514C18.4203 13.1547 17.637 13.1448 16.8542 13.1561C16.8477 15.2092 16.8528 17.2623 16.8519 19.315C16.8537 19.4861 16.8195 19.6745 16.6794 19.788C16.5402 19.9211 16.3353 19.9141 16.1562 19.9155C15.8272 19.9112 15.4986 19.915 15.1695 19.9145C15.0078 19.9141 14.8288 19.8962 14.7092 19.7748C14.5803 19.6586 14.5508 19.4777 14.5508 19.3127C14.5527 14.4212 14.5489 9.52984 14.5527 4.6389C14.5391 4.39422 14.7003 4.13828 14.9534 4.10125Z"
          fill="#1D2129"
        />
      </g>
      <defs>
        <clipPath id="clip0_862_3877">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Split;
