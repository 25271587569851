import { AxiosResponse } from 'axios';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import actions from './actions';
import apis from './apis';
import {
  ICreateCustomerBody,
  ISaveApiBodyType,
  ITicketDetails
} from './types/api';
import { ICustomerResItem } from './types/customer';
import { IDiscountTicketFormValue } from './types/discount';
import { ICustomerVIPPackage } from './types/vip';
import { checkChangeStateTicket, getPurePaymentInfo, getPureSelectedItems } from './selectors';
import { first } from 'lodash';
import { RootState } from 'store';

const getDetail: ISagaFunc<string> = function* ({ payload }) {
  const params: string = payload;
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resTicket: AxiosResponse<{ data: ITicketDetails }> = yield call(apis.getDetailsTicket, params);
    const resTicketDetail = resTicket?.data?.data;
    if (resTicketDetail) {
      yield put(actions.getTicketDetails.success(resTicketDetail));
      yield put(actions.checkCustomerPackage.fetch(resTicketDetail?.customerInfo?.customerId || ''));
      return;
    }
    const resBill: AxiosResponse<{ data: ITicketDetails }> = yield call(apis.getDetailBill, params);
    const resBillDetail = resBill?.data?.data;
    if (resBillDetail) {
      yield put(actions.getTicketDetails.success(resBillDetail));
      yield put(actions.checkCustomerPackage.fetch(resBillDetail?.customerInfo?.customerId || ''));
      return;
    }

  } catch (error) { } finally {
    yield setLoadingPage(false);
  }
};
const addNewCustomer: ISagaFunc<ICreateCustomerBody> = function* ({ payload }) {
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<ICustomerResItem | null> = yield call(apis.addNewCustomer, payload);
    if (res.data.data) {
      yield put(actions.switchSelectedCustomer(res.data.data));
      yield put(shopActions.get.customers.fetch());
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};
const initData = function* ({ }) {
  yield all([
    put(shopActions.doInit.editTicket()),
  ]);
};

const calculateTotal = function* () {
  yield put(actions.calculateTicketPaymentInfo());
};

const checkCustomerPackage: ISagaFunc<string> = function* ({ payload }) {
  yield put(actions.checkCustomerPackage.success(null));
  if (!payload) return;
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<ICustomerVIPPackage> = yield call(apis.getVIPPackageWithCustomerId, payload);
    const data = res?.data?.data;

    if (data) {
      const discountData: IDiscountTicketFormValue = {
        discount: data.discountValue,
        discountSetting: {
          ownerPaidPercent: 100,
          staffPaidPercent: 0
        },
        discountType: data.discountType,
        couponId: null,
        reason: ''
      };

      yield put(actions.setDiscountTicket(discountData));
      yield put(actions.checkCustomerPackage.success(data));
    } else {
      throw 'fail';
    }

  } catch (error) {
    yield put(actions.checkCustomerPackage.success(null));
  }
  finally {
    yield setLoadingPage(false);
  }
};

const switchSelectedCustomer: ISagaFunc<ICustomerResItem> = function* ({ payload }) {
  if (payload.accountPackage !== 'VIP') {
    yield put(actions.checkCustomerPackage.success(null));
    return;
  }

  yield put(actions.checkCustomerPackage.fetch(payload.id || ''));
};

const checkCashierWillUnmount = function* () {
  const pathName = location.pathname;
  if (!pathName.includes('ticket/edit')) return;

  const isChanged: boolean = yield select(checkChangeStateTicket);
  if (!isChanged) return;
  const paymentInfo: ISaveApiBodyType = yield select(getPurePaymentInfo);
  if (paymentInfo.items.length === 0) {
    return null;
  } else if (paymentInfo.items.length === 1) {
    const services = first(paymentInfo.items)?.services || [];
    if (services.length === 0) {
      return null;
    }
  }

  try {
    yield call(apis.editTicketBackground, paymentInfo);
  } catch (error) { }
};

const checkClearCustomer = function* () {
  const items: RootState['cashier']['selectedItems'] = yield select(getPureSelectedItems);
  const existVIPPackage = items.some(o => !!o.services.find(s => s.itemType === 'VIP'));
  if (existVIPPackage) {
    yield put(actions.setVisibleModalWarningVIPPackage(true));
    return;
  }
  yield put(actions.clearCustomer.success({}));
};

export default function* cashierServiceSagas() {
  yield takeLatest(actions.initData, initData);
  yield takeLatest(actions.getDetail.fetch, getDetail);

  yield takeLatest(actions.addNewCustomer.fetch, addNewCustomer);

  yield takeLatest(actions.selectAddOnItem, calculateTotal);
  yield takeLatest(actions.selectServiceItem, calculateTotal);
  yield takeLatest(actions.addStaffItem, calculateTotal);
  yield takeLatest(actions.switchStaffItem, calculateTotal);
  yield takeLatest(actions.removeServiceOfItem, calculateTotal);
  yield takeLatest(actions.switchSelectedCustomer, calculateTotal);
  yield takeLatest(actions.removeSelectedCustomer, calculateTotal);
  yield takeLatest(actions.setTip, calculateTotal);
  yield takeLatest(actions.setDiscountTicket, calculateTotal);
  yield takeLatest(actions.setDiscountItem, calculateTotal);
  yield takeLatest(actions.addQuickPay, calculateTotal);
  yield takeLatest(actions.addGiftCard, calculateTotal);
  yield takeLatest(actions.clearTicket, calculateTotal);
  yield takeLatest(actions.selectProductItem, calculateTotal);
  yield takeLatest(actions.selectProductVIPItem, calculateTotal);
  yield takeLatest(actions.updateQuickPayItem, calculateTotal);
  yield takeLatest(actions.removeStaffItem, calculateTotal);
  yield takeLatest(actions.switchSelectedCustomer, switchSelectedCustomer);
  yield takeLatest(actions.checkCustomerPackage.fetch, checkCustomerPackage);
  yield takeLatest(actions.checkCashierWillUnmount, checkCashierWillUnmount);
  yield takeLatest(actions.clearCustomer.fetch, checkClearCustomer);
}
