import React, { useCallback } from 'react';
import styled from 'styled-components';
import AddOnServices from './AddOnServices';
import Category from './Category';
import Customer from '../RightMainBody/Customer';
import Text from 'components/Text';
import cashierSelectors from 'features/cashier/services/selectors';
import { FEATURE_PRODUCT_MODE } from 'features/cashier/services/constants';
import ProductCategory from './ProductCategory';
type IComponentProps = {};
const LeftMainBody: React.FC<IComponentProps> = () => {
  const featureProductMode = cashierSelectors.getFeatureProductMode();
  const TargetCategory = useCallback(() => {
    return (
      <>
        {/* PRODUCTS */}
        <div className={featureProductMode === FEATURE_PRODUCT_MODE.PRODUCT ? 'show' : 'hide'}>
          <div className='container-carousel '>
            <ProductCategory />
          </div>
        </div>

        {/* SERVICES */}
        <div className={featureProductMode === FEATURE_PRODUCT_MODE.SERVICE ? 'show' : 'hide'}>
          <div className='container-carousel'>
            <Category />
          </div>
          <Text mb={1} />
          <div className='container-carousel'>
            <AddOnServices />
          </div>
        </div>
      </>
    );
  }, [featureProductMode]);

  return (
    <LeftMainBodyStyled>
      <div className='container-carousel'>
        <Text mb={1.5} />
        <Customer />
      </div>
      <TargetCategory />
      <Text mb={1} />
    </LeftMainBodyStyled>
  );
};

export default LeftMainBody;
const LeftMainBodyStyled = styled.div`
  height: calc(100vh - 6.375rem);
  background-color: #fff;
  overflow: auto;
  background-color: #e5e5e5;
  .container-carousel {
    padding: 0 1.5rem;
  }
  display: flex;
  gap: 1rem;
  flex-direction: column;
  .hide {
    display: none;
    opacity: 0;
  }
  .show {
    display: block;
    opacity: 1;
  }
`;