import ActionBtn from 'components/ActionBtn';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import useUpdateTicket from '../useUpdateTicket';
import { useAppDispatch } from 'store/hooks';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import { PAYMENT_TYPE } from 'features/payment/services/constants';

const ButtonMultiplePayment = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const updateTicket = useUpdateTicket();
  const onCash = async () => {
    const rest: { billId: string } | null = await updateTicket();
    if (!rest) return;

    const billId = rest.billId;
    dispatch(multiplePaymentActions.setActivePayment(PAYMENT_TYPE.CASH));
    navigate(`/store/${storage.shop_id.get()}/ticket/payment/cashier-side/${billId}`);
  };

  return (
    <ActionBtn icon='multiPay' ntype='YELLOW' small label={'Multi-Pays'} onClick={onCash} />
  );
};

export default ButtonMultiplePayment;
