import { Col, Input, InputRef, Row } from 'antd';
import Button from 'components/Button';
import Form, { FormItem, useForm } from 'components/Form';
import InputDOB from 'components/InputDOB';
import InputEmail from 'components/InputEmail';
import InputPhone from 'components/InputPhone';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { ISignUpForm } from 'features/checkIn/services/types/api';
import { ICustomerFormValues } from 'features/cashier/services/types/customer';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { PHONE_MASKED } from 'utils/mask';
import { useEffect, useRef } from 'react';

const SignUpForm = () => {
  const [form] = useForm();

  const inputNameRef = useRef<InputRef>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const formSignUp = checkInSelectors.getSignUpFormValues();
  const clickBack = () => {
    dispatch(checkInActions.setInitCheckIn());
    navigate(-1);
  };

  useEffect(() => {
    setTimeout(() => inputNameRef.current?.focus(), 100);
  }, []);

  const handleFinish = async (values: ICustomerFormValues) => {
    PHONE_MASKED.resolve(values?.phone);
    const phone = PHONE_MASKED.unmaskedValue;
    const payload: ISignUpForm = {
      phone: phone || null,
      name: values.name || null,
      email: values.email || null,
      birthday: values.birthday || null,
    };
    dispatch(checkInActions.signUp.fetch(payload));
  };


  return (

    <SignUpFormStyled className="sign-up-footer-btn">
      <Form
        initialValues={{
          phone: PHONE_MASKED.resolve(formSignUp?.phone ?? ''),
        }}
        form={form}
        onFinish={handleFinish}
      >
        <FormCustomerStyled gutter={[16, 16]}>
          <Col span={12}>
            <FormItem className='mb-0' name='name' label={'Full name'} rules={[{ required: true, message: 'Please input your name!' }]}>
              <Input ref={inputNameRef} />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem className='mb-0' name='phone' label={'Phone'} rules={[{ required: true, message: 'Please input your phone!' }]}>
              <InputPhone />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem className='mb-0' name='birthday' label={'DOB'}>
              <InputDOB inputReadOnly={false} />
            </FormItem>
          </Col>
          <Col span={12}>
            <InputEmail
              name={'email'} label="Email" rules={[
                { type: 'email' },
              ]}
            />
          </Col>
          <Col span={12}>
            <Button onClick={clickBack} className="footer-btn" ntype="SECONDARY">
              Back
            </Button>
          </Col>
          <Col span={12}>
            <Button className="footer-btn" ntype="PRIMARY" htmlType='submit'>
              Next
            </Button>
          </Col>
        </FormCustomerStyled>
      </Form>
    </SignUpFormStyled>
  );
};

export default SignUpForm;
const SignUpFormStyled = styled.div`
    margin-top: 3.875rem;
    margin-left: 11%;
    margin-right: 11%;

    .custom-input .email-domain {
      margin-top: 0.75rem;
    }

    .sign-up-footer {
      margin-top: 2.75rem;
    }

    .footer-btn {
      width: 100%;
    }
    .input-email {
      height: 3rem !important;
    }
`;

const FormCustomerStyled = styled(Row)`
`;
