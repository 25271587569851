const LogoImageUpload = () => {
  return (
    <svg
      width="141"
      height="36"
      viewBox="0 0 141 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <rect
          x="0.799973"
          y="0.150391"
          width="140"
          height="35"
          rx="5"
          fill="black"
        />
      </g>
      <path
        d="M68.0961 9.18749C68.6375 9.1284 69.1836 9.15602 69.7262 9.16244C70.6703 9.13868 71.6139 9.17593 72.5575 9.15474C72.976 9.16116 73.4071 9.11556 73.8106 9.26136C74.3031 9.43478 74.774 9.74758 75.0655 10.2132C75.2485 10.6006 75.4226 10.9924 75.596 11.3842C76.7913 11.3835 77.9865 11.3854 79.1818 11.3835C79.9688 11.3739 80.751 11.7689 81.24 12.4266C81.6278 12.9295 81.8361 13.5834 81.8223 14.2334C81.8229 17.5952 81.8229 20.9564 81.8217 24.3182C81.8367 25.1037 81.5134 25.8835 80.9696 26.4108C80.4759 26.9054 79.7948 27.1636 79.1204 27.1449C73.7817 27.1571 68.4411 27.1443 63.103 27.1494C62.5761 27.1417 62.0288 27.1867 61.5278 26.9773C60.6787 26.6587 60.0212 25.8366 59.8496 24.8956C59.787 24.5989 59.8026 24.2938 59.8002 23.9925C59.8008 20.7187 59.799 17.4449 59.8008 14.1711C59.793 13.2956 60.2109 12.4369 60.8738 11.925C61.3237 11.5724 61.8806 11.3784 62.4388 11.3835C63.6347 11.3854 64.8306 11.3842 66.0259 11.3842C66.209 10.9802 66.3806 10.5691 66.5805 10.1741C66.937 9.65059 67.5054 9.30825 68.0961 9.18749ZM68.2954 10.6455C68.0455 10.7194 67.7691 10.8568 67.6686 11.1311C67.4735 11.5704 67.2766 12.0091 67.0749 12.4452C66.9683 12.6855 66.7292 12.8492 66.4805 12.8544C65.116 12.8576 63.7509 12.8525 62.3865 12.857C61.7145 12.8589 61.1448 13.5224 61.1743 14.2334C61.1725 17.2953 61.1876 20.3577 61.1725 23.419C61.2123 23.909 61.0906 24.4312 61.2911 24.8943C61.4826 25.3696 61.9571 25.6837 62.4401 25.6747C68.0208 25.6754 73.6016 25.6754 79.1824 25.6747C79.8388 25.6991 80.4476 25.0864 80.4385 24.3811C80.4566 20.9981 80.4427 17.6145 80.4476 14.2302C80.4602 13.7067 80.1688 13.1922 79.7189 12.9777C79.4395 12.8293 79.1204 12.8544 78.8181 12.855C77.5963 12.855 76.374 12.8557 75.1522 12.855C74.9089 12.8525 74.6717 12.7015 74.5603 12.4703C74.32 11.9764 74.1171 11.4606 73.8702 10.9712C73.6739 10.7521 73.3867 10.6147 73.1006 10.6269C71.6555 10.6295 70.2109 10.6275 68.7657 10.6275C68.6092 10.6307 68.4502 10.614 68.2954 10.6455Z"
        fill="white"
      />
      <path
        d="M70.3693 13.9637C71.3514 13.8436 72.369 14.042 73.2355 14.554C74.3851 15.2123 75.257 16.3922 75.5918 17.7378C75.8483 18.7745 75.8025 19.8998 75.4382 20.9012C75.0107 22.1029 74.1424 23.1197 73.0591 23.683C72.201 24.1384 71.2117 24.3047 70.2639 24.1596C69.2619 24.0131 68.3075 23.5147 67.5855 22.7593C66.6534 21.8049 66.0976 20.4336 66.1235 19.052C66.1096 17.9916 66.4402 16.9369 67.0189 16.0781C67.7926 14.9143 69.0403 14.1262 70.3693 13.9637ZM70.3645 15.45C69.3275 15.633 68.3936 16.355 67.9022 17.3454C67.4512 18.2331 67.3717 19.323 67.6873 20.2749C67.9607 21.1125 68.5285 21.8344 69.2541 22.2673C69.9935 22.7163 70.8944 22.8551 71.7223 22.6405C72.6111 22.4228 73.4017 21.8036 73.8738 20.9731C74.3465 20.1561 74.4947 19.1368 74.2779 18.2054C74.046 17.1623 73.356 16.2509 72.4545 15.7795C71.8133 15.4365 71.0708 15.3266 70.3645 15.45Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoImageUpload;
