import { ICustomerItemData } from 'services/shop/types/customer';

export const NAME_REDUCER = 'cashier';

export const PREFIX_ACTIONS = 'cashier_feature_';


export const PATH_LOADING = {
  getDetail: `loading.${NAME_REDUCER}.getDetail`,
  getCategories: `loading.${NAME_REDUCER}.getCategories`,
  getAddOnList: `loading.${NAME_REDUCER}.getAddOnList`,
  getStaffList: `loading.${NAME_REDUCER}.getStaffList`,
  getCustomerList: `loading.${NAME_REDUCER}.getCustomerList`,
};

export const FIELD_KEYS = {
  DURATION: 'duration',
  PRICECOST: 'priceCost',
  PRICESELL: 'priceSell',
  COMMISSION: 'commission'
} as const;

export type FieldKeys = typeof FIELD_KEYS[keyof typeof FIELD_KEYS];

const SymbolDraff = '/';

export const encodeDraftServiceSwitchStaffId = (data: { staffId: string, itemId: string }) => {
  if (!data) return '';
  const { itemId, staffId } = data;
  return staffId + SymbolDraff + itemId;
};

export const decodeDraftServiceSwitchStaffId = (draftValue: string) => {
  if (!draftValue) return null;
  const [staffId, itemId] = draftValue.split(SymbolDraff);

  return ({
    staffId,
    itemId,
  });
};

export enum ITEM_HANDLE_MODE {
  DISCOUNT_ITEM = 'DISCOUNT_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',
}

export enum FEATURE_PRODUCT_MODE {
  SERVICE = 'SERVICE',
  PRODUCT = 'PRODUCT',
}

export const QUICK_PAY_ID = 'QUICK_PAY_ID';

export enum DiscountType {
  PERCENT = 'PERCENT',
  MONEY = 'MONEY'
}

export const UNKNOWN_CUSTOMER: ICustomerItemData = {
  email: '',
  id: '',
  imageAvatarUrl: '',
  name: '',
  numberVisit: 0,
  phone: '',
  point: 0,
  status: ''
};