import IconLogo from 'assets/Icons/logo';
import React from 'react';
import styled from 'styled-components';
type IComponentProps = {};
const LogoBox: React.FC<IComponentProps> = () => {
  return (
    <BackgroundStyled>
      <IconLogo />
    </BackgroundStyled>
  );
};

export default LogoBox;
const BackgroundStyled = styled.div`
    position: absolute;
    top: 1.6785rem;
    left: 2.5rem;
    display: flex;
    align-items: center;
    z-index: 2;
`;
