import GroupButtons from 'components/GroupButtons';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { IParams } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
const options = [
    {
        label: 'Daily',
        value: CALENDAR_VIEW_TYPE.DAY_VIEW,
    },
    {
        label: 'Weekly',
        value: CALENDAR_VIEW_TYPE.WEEK_VIEW,
    },
    {
        label: 'Monthly',
        value: CALENDAR_VIEW_TYPE.MONTH_VIEW,
    },
];
type ISwitchCalendarTypeProps = {
    actions: any,
    selectors: any,
    type: string,
};
const SwitchCalendarType: React.FC<ISwitchCalendarTypeProps> = ({
    actions,
    selectors,
    type = 'DEFAULT'
}) => {
    const dispatch = useAppDispatch();
    const viewType = selectors.getViewType()?.[type] || CALENDAR_VIEW_TYPE.DAY_VIEW;

    const onChange = (val: string) => {
        const payload = {
            [type]: val as CALENDAR_VIEW_TYPE,
        };
        dispatch(actions.setCalendarViewType(payload));

        const now = moment();
        let params: IParams | null = null;
        switch (val) {
            case CALENDAR_VIEW_TYPE.DAY_VIEW: {
                params = {
                    [type]: {
                        startTime: now.format(DATE_FORMAT),
                        endTime: now.format(DATE_FORMAT)
                    }
                };
                break;
            }
            case CALENDAR_VIEW_TYPE.WEEK_VIEW: {
                const startTime = now.startOf('week').format(DATE_FORMAT);
                const endTime = now.endOf('week').format(DATE_FORMAT);
                params = {
                    [type]: {
                        startTime,
                        endTime
                    }
                };
                break;
            }
            case CALENDAR_VIEW_TYPE.MONTH_VIEW: {
                const startTime = now.startOf('month').format(DATE_FORMAT);
                const endTime = now.endOf('month').format(DATE_FORMAT);

                params = {
                    [type]: {
                        startTime,
                        endTime
                    }
                };
                break;
            }
            default:
                break;
        }

        if (params)
            dispatch(actions.setParams(params));
    };

    return (
        <SwitchCalendarTypeStyled>
            <GroupButtons
                options={options}
                value={viewType}
                onChange={onChange}
            />
        </SwitchCalendarTypeStyled>
    );
};

export default SwitchCalendarType;
type SwitchCalendarTypeStyledProps = {}
const SwitchCalendarTypeStyled = styled.div<SwitchCalendarTypeStyledProps>``;
