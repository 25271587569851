import Icon from 'assets/Icons';
import Text from 'components/Text';
import { IProductItemData } from 'services/shop/types/product';
import styled from 'styled-components';
type Props = {
  data: IProductItemData;
  onClick?: () => void;
  selected?: boolean;
  count?: number;
};
const ProductItem = ({ data, onClick, selected, count }: Props) => {
  return (
    <ProductItemStyled onClick={onClick}>
      {selected && (
        <IconBadge>
          {count ? (
            <IconCount>
              <Text className='text'>{count}</Text>
            </IconCount>
          ) : <Icon type="tick" />}
        </IconBadge>
      )}
      <div className="wrap-img">
        <div className="icon"><ImageIcon /></div>
        <img
          width={'100%'}
          src={data.urlImage}
        />
      </div>
      <div className='footer'>
        <Text variant='CONTENT_2' className='product-name'>{data.productName}</Text>
        <Text className='available'>Available: {data.unit} items</Text>
      </div>
    </ProductItemStyled>
  );
};

export default ProductItem;

const ProductItemStyled = styled.button`
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  .wrap-img {
    background: #D9D9D9;
    height: 22vh;
    display:flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index:1;
    img {
      width: 100%;
      height: 100%;
      background: #D9D9D9;
      position: relative;
    }
    .icon {
      position: absolute;
      opacity: 0.5;
    }
  }
  .footer {
    border-radius: 0px 0px 5px 5px;
    background: #BBC8C0;
    align-self: stretch;
  }
  .product-name {
    margin: 0.25rem 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;


    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .available {
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0.25rem;
  }
`;

const IconBadge = styled.div`
  position: absolute;
  top: -13px;
  right: 0px;
  z-index: 2;
`;

const IconCount = styled.div`
  background: var(--primary-primary-active, #FF8890);
  width: 28px;
  height: 28px;
  border-radius:28px;
  display:flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #FFF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ImageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <rect width="32" height="32" rx="16" fill="black" fillOpacity="0.4" />
    <path d="M13.7667 7.96147C14.2501 7.90871 14.7377 7.93337 15.2221 7.93911C16.0652 7.91789 16.9077 7.95115 17.7502 7.93222C18.1238 7.93796 18.5088 7.89724 18.869 8.02742C19.3088 8.18227 19.7292 8.46156 19.9895 8.87734C20.1529 9.22315 20.3083 9.57298 20.4631 9.92281C21.5304 9.92224 22.5976 9.92396 23.6648 9.92224C24.3675 9.91363 25.0659 10.2663 25.5025 10.8536C25.8487 11.3026 26.0348 11.8864 26.0224 12.4668C26.0229 15.4685 26.0229 18.4695 26.0219 21.4712C26.0353 22.1726 25.7466 22.8688 25.2611 23.3396C24.8202 23.7812 24.2121 24.0117 23.61 23.9951C18.8432 24.006 14.0748 23.9945 9.30857 23.9991C8.83813 23.9922 8.34941 24.0324 7.90209 23.8454C7.144 23.561 6.55689 22.8269 6.40366 21.9868C6.34775 21.7218 6.36173 21.4494 6.35958 21.1804C6.36011 18.2573 6.3585 15.3343 6.36011 12.4112C6.35312 11.6295 6.72625 10.8628 7.3182 10.4057C7.71982 10.0908 8.21715 9.91765 8.71555 9.92224C9.78331 9.92396 10.8511 9.92281 11.9183 9.92281C12.0818 9.56208 12.235 9.19505 12.4135 8.84235C12.7318 8.37496 13.2393 8.06929 13.7667 7.96147ZM13.9447 9.2633C13.7216 9.32925 13.4748 9.45197 13.385 9.69685C13.2108 10.0891 13.035 10.4808 12.8549 10.8702C12.7597 11.0847 12.5463 11.2309 12.3242 11.2355C11.1059 11.2384 9.88708 11.2338 8.66877 11.2378C8.06876 11.2395 7.56014 11.832 7.58649 12.4668C7.58487 15.2006 7.59831 17.935 7.58487 20.6683C7.62036 21.1059 7.51175 21.5721 7.69079 21.9856C7.86176 22.41 8.28543 22.6904 8.71662 22.6824C13.6995 22.683 18.6824 22.683 23.6654 22.6824C24.2514 22.7042 24.795 22.1571 24.7869 21.5274C24.803 18.5068 24.7907 15.4857 24.795 12.4639C24.8062 11.9966 24.546 11.5372 24.1444 11.3456C23.8949 11.2132 23.61 11.2355 23.3401 11.2361C22.2492 11.2361 21.1578 11.2367 20.0669 11.2361C19.8497 11.2338 19.6378 11.099 19.5384 10.8926C19.3239 10.4516 19.1427 9.99105 18.9222 9.55405C18.747 9.35849 18.4905 9.23577 18.2351 9.24666C16.9448 9.24896 15.655 9.24724 14.3646 9.24724C14.2248 9.2501 14.0829 9.23519 13.9447 9.2633Z" fill="white" />
    <path d="M15.7966 12.2253C16.6735 12.1181 17.5821 12.2953 18.3558 12.7523C19.3822 13.3402 20.1607 14.3937 20.4596 15.5951C20.6887 16.5207 20.6478 17.5255 20.3225 18.4196C19.9408 19.4926 19.1655 20.4004 18.1983 20.9034C17.4321 21.31 16.5488 21.4585 15.7025 21.3289C14.8079 21.1981 13.9557 20.7531 13.3111 20.0787C12.4788 19.2265 11.9825 18.0021 12.0057 16.7685C11.9933 15.8217 12.2885 14.88 12.8051 14.1132C13.496 13.0741 14.61 12.3704 15.7966 12.2253ZM15.7923 13.5524C14.8665 13.7158 14.0326 14.3604 13.5939 15.2447C13.1912 16.0373 13.1202 17.0105 13.4019 17.8604C13.646 18.6082 14.153 19.2529 14.8009 19.6394C15.4611 20.0403 16.2654 20.1641 17.0047 19.9726C17.7983 19.7782 18.5042 19.2253 18.9257 18.4838C19.3478 17.7543 19.48 16.8442 19.2865 16.0126C19.0795 15.0813 18.4633 14.2675 17.6585 13.8466C17.0859 13.5403 16.423 13.4422 15.7923 13.5524Z" fill="white" />
  </svg>
);