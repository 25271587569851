import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

type MyState = RootState['auth'];
const getCurrentState = (state: RootState): MyState => state['auth'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getAutoSelectShop = () => selector('isAutoSelectShop') as MyState['isAutoSelectShop'];

const owner = {
  getShops: () => selector('shops') as MyState['shops'],
  token: () => selector('owner_access_token') as MyState['owner_access_token'],
  ownerInfo: () => selector('ownerInfo') as MyState['ownerInfo'],
};

const authSelectors = {
  owner,
  getAutoSelectShop,
};
export default authSelectors;
