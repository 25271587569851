import { Col, Row } from 'antd';
import Text from 'components/Text';
import React from 'react';
import { CUSTOMER_TARGET_TYPE } from 'services/shop/constants';
import { ICustomerItemData } from 'services/shop/types/customer';
import styled from 'styled-components';
import { maskPhone } from 'utils/formatPhone';
type ICustomerItemProps = {
  data: ICustomerItemData;
  type?: CUSTOMER_TARGET_TYPE;
  selected?: boolean;
  disabledPoint?: boolean;
};
const CustomerItem: React.FC<ICustomerItemProps> = ({ selected, data, type = CUSTOMER_TARGET_TYPE.NORMAL, disabledPoint }) => {
  return (
    <CustomerItemStyled className={`${type}`}>
      <Row className='box-name' align={'middle'} justify={'space-between'} wrap={false}>
        <Text className='name' variant='CONTENT_2' wrapWidth='75%'>{data.name || 'UNKNOWN CUSTOMER'}</Text>
        <Icons type={type} />
      </Row>
      {!disabledPoint && <Text variant='BODY_1' color='text_2' >{data.point || 0} point</Text>}
      <Col flex={'auto'}></Col>
      <Text>{maskPhone(data.phone)}</Text>
      {selected && <div className="selected"><CheckedIcon /></div>}
    </CustomerItemStyled>
  );
};

export default CustomerItem;
type CustomerItemStyledProps = {}
const CustomerItemStyled = styled.button<CustomerItemStyledProps>`
width: 100%;
border-radius: 10px;
height: 5rem;
flex-shrink: 0;
padding: 0.5rem;
text-align: left;
display: flex;
flex-direction: column;
position: relative;
.selected {
  position: absolute;
  top:0;
  right:0;
  z-index: 99;
  transform: translate(15px, -15px);
}

.box-name {
  width: 100%;
}
.name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
}

  &.${CUSTOMER_TARGET_TYPE.NORMAL} {
    border: 1px solid var(--line-line-3, #86909C);
    background: #F5FEFF;
  }
  &.${CUSTOMER_TARGET_TYPE.LOYAL} {
    border: 1px solid var(--line-line-3, #86909C);
    background: #FFF8FA;
  }
  &.${CUSTOMER_TARGET_TYPE.VIP} {
    border: 1px solid var(--line-line-3, #86909C);
    background: #FFFEF7;
  }
`;

const Icons = ({ type = CUSTOMER_TARGET_TYPE.NORMAL }: { type?: CUSTOMER_TARGET_TYPE }) => {
  switch (type) {
    case CUSTOMER_TARGET_TYPE.VIP:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" viewBox="0 0 16 14" fill="none">
          <path d="M7.3039 1.34581C7.4139 1.11476 7.64455 0.945605 7.89843 0.91482C8.14037 0.880795 8.39489 0.970557 8.56166 1.15008C8.76296 1.3591 8.82973 1.68671 8.72489 1.95794C8.6365 2.20163 8.41908 2.38343 8.17101 2.44564C9.23232 3.75319 10.2936 5.06075 11.3556 6.36765C12.4375 5.82454 13.5172 5.27721 14.5982 4.7328C14.5185 4.6061 14.4553 4.46384 14.4534 4.31186C14.4389 4.04225 14.5776 3.77134 14.8043 3.62649C15.0273 3.47743 15.3286 3.45928 15.5679 3.58015C15.8073 3.69584 15.976 3.94374 15.9966 4.20978C16.0215 4.46579 15.9076 4.72892 15.7063 4.88738C15.5098 5.04714 15.2337 5.09607 14.9937 5.01667C14.2069 7.01543 13.4292 9.01807 12.6453 11.0181C9.54878 11.0184 6.45195 11.0181 3.35545 11.0184C2.57221 9.01775 1.79607 7.01446 1.00896 5.0157C0.768952 5.09639 0.492174 5.04843 0.295396 4.8877C0.074102 4.71693 -0.0378353 4.42042 0.0115204 4.14497C0.0647471 3.80634 0.36572 3.52733 0.706693 3.50335C1.01121 3.47224 1.32186 3.64367 1.46025 3.91749C1.59768 4.17219 1.56864 4.49722 1.40251 4.73216C2.48285 5.29504 3.56577 5.85273 4.64547 6.41658C5.70871 5.09412 6.76809 3.76843 7.83294 2.44726C7.63842 2.39574 7.45906 2.27973 7.35068 2.10733C7.20325 1.88503 7.1868 1.58496 7.3039 1.34581Z" fill="#FFDC7C" />
          <path d="M3.35538 11.5368C6.45221 11.5358 9.54903 11.5371 12.6459 11.5361C12.6449 12.0546 12.6465 12.5731 12.6452 13.0916C9.54839 13.0926 6.45156 13.0913 3.35474 13.0922C3.3557 12.5737 3.35409 12.0553 3.35538 11.5368Z" fill="#FFDC7C" />
        </svg>
      );
    case CUSTOMER_TARGET_TYPE.LOYAL:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <rect width="20" height="20" rx="10" fill="white" />
          <path d="M9.39921 2.43304C10.6729 2.15454 12.0703 2.81588 12.6642 3.97565C13.0703 4.7309 13.1281 5.66272 12.8199 6.46292C12.5518 7.17563 12.0021 7.77598 11.3166 8.10666C10.6416 8.43653 9.84064 8.49833 9.12231 8.27841C8.34539 8.04726 7.67521 7.48464 7.30922 6.76149C6.88063 5.9332 6.8718 4.90266 7.28595 4.06715C7.68805 3.23404 8.49146 2.61122 9.39921 2.43304Z" fill="#FF8890" />
          <path d="M6.28755 9.65823C6.56445 9.19032 6.91278 8.75851 7.35822 8.44068C8.24911 9.20476 9.47549 9.56834 10.6361 9.36769C11.3777 9.25292 12.0679 8.90379 12.6402 8.42383C13.2132 8.8476 13.6177 9.45116 13.938 10.078C14.3842 10.9585 14.6972 11.9007 14.9452 12.8542C15.1146 13.4867 14.8818 14.2002 14.3706 14.6087C14.0166 14.9153 13.5399 15.0068 13.084 14.9972C12.9452 15.8263 12.4772 16.5992 11.7782 17.0727C10.9435 17.6602 9.81179 17.7854 8.86712 17.4034C7.85744 17.0141 7.11503 16.0606 6.94488 14.9972C6.48017 15.0068 5.9946 14.9129 5.63342 14.6023C5.11173 14.1881 4.87416 13.4586 5.06117 12.8173C5.35011 11.7241 5.71128 10.6366 6.28755 9.65823ZM9.47068 12.2924C8.57898 12.4922 7.85423 13.274 7.73143 14.1809C7.58536 15.0566 8.00994 15.9868 8.76118 16.4571C9.5084 16.9491 10.5462 16.9371 11.2822 16.4274C12.1867 15.8415 12.5519 14.5806 12.0992 13.6014C11.6835 12.6086 10.5141 12.0251 9.47068 12.2924Z" fill="#FF8890" />
          <path d="M9.61843 13.3043C9.70752 12.972 10.2236 12.9391 10.36 13.2521C10.4411 13.5338 10.3897 13.8348 10.4002 14.1245C10.6771 14.1294 10.958 14.0981 11.2317 14.1478C11.5519 14.2465 11.58 14.7441 11.2766 14.8822C10.9941 14.9665 10.6907 14.9159 10.3994 14.9255C10.3929 15.2097 10.4331 15.5002 10.3681 15.7795C10.2485 16.0965 9.74123 16.0933 9.62726 15.7731C9.57028 15.4946 9.60479 15.2073 9.59917 14.9247C9.31023 14.9167 9.01086 14.9641 8.72995 14.887C8.41693 14.7546 8.44101 14.2433 8.76928 14.147C9.04296 14.0989 9.32307 14.1286 9.59917 14.1245C9.60158 13.8508 9.5775 13.5756 9.61843 13.3043Z" fill="#FF8890" />
        </svg>
      );

    default:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <rect width="20" height="20" rx="10" fill="white" />
          <path d="M6.361 4H13.3887C13.8847 4.06667 14.3541 4.36107 14.5786 4.816C14.7903 5.2032 14.7423 5.65653 14.7455 6.08C14.7445 8.87147 14.7445 11.6635 14.7455 14.4549C14.753 14.8859 14.5775 15.3184 14.2549 15.6069C14.0186 15.8304 13.7034 15.9456 13.3882 16H6.36047C5.86394 15.9344 5.39354 15.6405 5.16794 15.1856C4.9578 14.7979 4.99354 14.3451 4.99407 13.92C4.99514 11.1456 4.99514 8.3712 4.99354 5.5968C4.9914 5.25013 5.08047 4.89653 5.2906 4.61653C5.53754 4.26667 5.94287 4.06027 6.361 4ZM6.35247 5.15253C6.17007 5.2144 6.10607 5.42027 6.11674 5.59573C6.11887 8.40587 6.11887 11.2165 6.11727 14.0267C6.12207 14.2293 6.0954 14.4373 6.14607 14.6363C6.20634 14.8208 6.41167 14.8928 6.59034 14.8811C8.79727 14.8773 11.0042 14.8816 13.2111 14.8789C13.3898 14.8891 13.5738 14.776 13.6117 14.5952C13.6474 14.3723 13.625 14.1451 13.6293 13.9205C13.6287 11.1456 13.6277 8.37067 13.6298 5.59573C13.6719 5.3216 13.4357 5.07573 13.1589 5.11893C10.969 5.12107 8.77914 5.12107 6.58927 5.11893C6.5098 5.12213 6.4282 5.12427 6.35247 5.15253Z" fill="#6FABB6" />
          <path d="M15.5648 5.66488C15.6682 5.50008 15.9098 5.45422 16.072 5.55715C16.161 5.62115 16.2032 5.72728 16.248 5.82328V7.46275C16.185 7.55982 16.1226 7.66915 16.0133 7.72035C15.841 7.79288 15.6144 7.71395 15.5418 7.53742C15.4933 7.41422 15.5013 7.27875 15.4976 7.14915C15.5018 6.79128 15.5008 6.43395 15.4981 6.07608C15.5029 5.93848 15.4922 5.78862 15.5648 5.66488Z" fill="#6FABB6" />
          <path d="M9.6559 7.0204C10.1951 6.9356 10.7663 7.17347 11.0826 7.6188C11.5492 8.23054 11.4255 9.1932 10.8154 9.6636C10.2692 10.1223 9.39777 10.0999 8.87404 9.61614C8.35084 9.16547 8.22497 8.33774 8.57857 7.74734C8.80364 7.3596 9.2095 7.07747 9.6559 7.0204Z" fill="#6FABB6" />
          <path d="M15.5159 8.80425C15.5367 8.61012 15.7388 8.47785 15.926 8.50825C16.0695 8.52639 16.1596 8.65332 16.2481 8.75412V10.5013C16.1575 10.6 16.0657 10.7227 15.9239 10.7419C15.7351 10.7717 15.5329 10.6336 15.5143 10.4389C15.4823 10.1787 15.5057 9.91519 15.5009 9.65332C15.5068 9.37065 15.4801 9.08585 15.5159 8.80425Z" fill="#6FABB6" />
          <path d="M8.74577 10.7909C9.30523 10.7184 9.87217 10.7765 10.4348 10.7562C10.8306 10.7365 11.238 10.8032 11.5788 11.0154C12.1426 11.344 12.5127 11.9882 12.4903 12.6426C12.4924 12.8538 12.2935 13.0117 12.0898 12.9925C10.6482 12.9957 9.20657 12.9893 7.76497 12.9952C7.61457 12.9941 7.4343 12.9946 7.3351 12.8613C7.21403 12.7029 7.25563 12.4885 7.27857 12.3061C7.3959 11.5621 8.00817 10.9349 8.74577 10.7909Z" fill="#6FABB6" />
          <path d="M15.5093 11.8415C15.5162 11.6538 15.6869 11.4933 15.8752 11.5039C16.0378 11.4959 16.1466 11.6357 16.248 11.7418V13.4309C16.2074 13.5146 16.1685 13.6031 16.0992 13.6677C15.9237 13.8202 15.6042 13.7418 15.5333 13.5157C15.4784 13.3178 15.5034 13.1087 15.4992 12.9061C15.5098 12.5514 15.4826 12.1957 15.5093 11.8415Z" fill="#6FABB6" />
        </svg>
      );
  }
};

const CheckedIcon = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="14.5" cy="14" r="14" fill="#FF8890" />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.4939 7.43558C22.8056 7.70834 22.8372 8.18216 22.5644 8.49389L12.0644 20.4939C11.9277 20.6501 11.7324 20.7427 11.525 20.7496C11.3175 20.7565 11.1164 20.6771 10.9697 20.5303L6.46967 16.0303C6.17678 15.7374 6.17678 15.2626 6.46967 14.9697C6.76256 14.6768 7.23744 14.6768 7.53033 14.9697L11.4635 18.9028L21.4356 7.50613C21.7083 7.1944 22.1822 7.16282 22.4939 7.43558Z" fill="white" />
  </svg>
);