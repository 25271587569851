import { Segmented } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { CALENDAR_PICKER_TYPE } from 'features/settingStaff/services/constants';
import React from 'react';
import styled from 'styled-components';
type ISegmentedTimeProps = {
  value: string | number;
  handleChange: (value: SegmentedValue) => void;
};
const SegmentedTime: React.FC<ISegmentedTimeProps> = ({
  value,
  handleChange,
}) => {
  return (
    <SegmentedTimeStyled
      value={value}
      onChange={handleChange}
      options={[
        {
          label: (
            <Text style={{ zIndex: 1 }} variant="CONTENT_1" color="text_3">
              Daily
            </Text>
          ),
          value: CALENDAR_PICKER_TYPE.DATE,
        },
        {
          label: (
            <Text style={{ zIndex: 1 }} variant="CONTENT_1" color="text_3">
              Weekly
            </Text>
          ),
          value: CALENDAR_PICKER_TYPE.WEEK,
        },
        {
          label: (
            <Text style={{ zIndex: 1 }} variant="CONTENT_1" color="text_3">
              Monthly
            </Text>
          ),
          value: CALENDAR_PICKER_TYPE.MONTH,
        },
      ]}
    />
  );
};

export default SegmentedTime;
const SegmentedTimeStyled = styled(Segmented)`
  padding: 0;
  border: 1px solid ${colorTheme.line_3};
  .ant-segmented-group {
    height: 100%;
    .ant-segmented-item-label {
      height: 100%;
      display: flex;
      align-items: center;
    }
  }
`;
