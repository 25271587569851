import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';
import Modal from 'components/Modal';
import { useAppDispatch } from 'store/hooks';
import colorTheme from 'constants/color';
import ticketListActions from 'features/ticketList/services/actions';
import CurrencyKeyBoard, {
  CurrencyKeyBoardRef,
} from 'components/NumberKeyBoard/Currency';

type IRefundModalButtonProps = {
  billId: string;
  maxAmount: number;
};
const RefundModalButton: React.FC<IRefundModalButtonProps> = ({
  billId,
  maxAmount,
}) => {
  const dispatch = useAppDispatch();
  const [openRefund, setOpenRefund] = useState(false);
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const [refundValue, setRefundValue] = useState<number | null | undefined>();

  const handleShowRefundModal = () => {
    setOpenRefund(true);
  };
  const handleCloseRefundModal = () => {
    currencyRef.current?.hideError();
    setRefundValue(0);
    setOpenRefund(false);
  };

  const handleSubmitRefund = () => {
    const payload = {
      billId: billId || '',
      amount: Number(refundValue),
    };
    dispatch(ticketListActions.refundTicketByBillId.fetch(payload));
    handleCloseRefundModal();
  };

  useEffect(() => {
    if (refundValue && openRefund && refundValue > maxAmount) {
      currencyRef.current?.showError(
        `Max refund must be $${maxAmount?.toFixed(2)}`
      );
    } else {
      currencyRef.current?.hideError();
    }
  }, [refundValue, openRefund]);

  return (
    <RefundModalButtonStyled>
      <ActionButton
        color={colorTheme.text_3}
        bgColor={colorTheme.info_orange}
        onClick={handleShowRefundModal}
      >
        Refund
      </ActionButton>
      <Modal
        modalTitle={'REFUND'}
        visible={openRefund}
        onClose={handleCloseRefundModal}
        onSubmit={handleSubmitRefund}
        destroyOnClose={true}
        disableOk={!!(refundValue && refundValue > maxAmount)}
      >
        <CurrencyKeyBoard
          ref={currencyRef}
          value={refundValue}
          onChange={setRefundValue}
        />
      </Modal>
    </RefundModalButtonStyled>
  );
};

export default RefundModalButton;
const RefundModalButtonStyled = styled.div``;
const ActionButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['bgColor'].includes(prop),
})<{ bgColor?: string; color?: string }>`
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${(props) =>
    props.bgColor || 'var(--info-infor-3, #6fabb6)'} !important;
  flex-grow: 1;
  flex-basis: 1rem;
  cursor: pointer;
  color: ${(props) => props.color || 'var(--text-text-1, #fff)'} !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-color: ${(props) =>
    props.bgColor || 'var(--info-infor-3, #6fabb6)'} !important;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
