import ActionBtn from 'components/ActionBtn';
import React, { useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import ModalExistDiscount, { ModalExistDiscountRefs } from '../Discount/ModalExistDiscount';
import DiscountTicket from '../Discount/DiscountTicket';
import { ISubmitModalDiscount } from '../Discount/DiscountMethodModal';

const ButtonDiscountTicket = () => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const visibleExistRef = useRef<ModalExistDiscountRefs>(null);
  const open = () => setVisible(true);
  const close = () => setVisible(false);
  const ticketInfo = cashierSelectors.getTicketTotalInfo();
  const discountTicket = cashierSelectors.getDiscountTicket();

  const handleSubmit: ISubmitModalDiscount = (discount) => {
    if (!discountTicket?.discount) {
      dispatch(cashierActions.setDiscountTicket(discount));
      return;
    }
    const callback = () => dispatch(cashierActions.setDiscountTicket(discount));
    visibleExistRef.current?.open(callback);
  };

  return (
    <>
      <ActionBtn
        label='Disc. Ticket'
        ntype="LIGHT_BLUE"
        icon='discount'
        small
        onClick={open}
      />
      {visible &&
        <DiscountTicket
          visible
          onClose={close}
          onSubmit={handleSubmit}
          initialAmount={ticketInfo.subtotal ?? 0}
        />}
      {/* {visible &&
        <DiscountMethodModal
          visible={visible}
          onClose={close}
          onSubmit={handleSubmit}
          initialAmount={ticketInfo.subtotal ?? 0}
        />
      } */}
      <ModalExistDiscount ref={visibleExistRef} target='ticket' />
    </>
  );
};

export default ButtonDiscountTicket;
