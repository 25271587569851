import DaySchedule from 'features/settingStaff/pages/SettingStaffPage/components/StaffSchedule/DaySchedule';
import React from 'react';
import styled from 'styled-components';
type ISchedulerTableProps = {};
const SchedulerTable: React.FC<ISchedulerTableProps> = () => {
  return (
    <SchedulerTableStyled>
      <RowScheduleStyled>
        <DaySchedule
          day="Monday"
          enable="enableMon"
          start="monStartTime"
          end="monEndTime"
        />
        <DaySchedule
          day="Tuesday"
          enable="enableTue"
          start="tueStartTime"
          end="tueEndTime"
        />
        <DaySchedule
          day="Wednesday"
          enable="enableWed"
          start="wedStartTime"
          end="wedEndTime"
        />
        <DaySchedule
          day="Thursday"
          enable="enableThu"
          start="thuStartTime"
          end="thuEndTime"
        />
        <DaySchedule
          day="Friday"
          enable="enableFri"
          start="friStartTime"
          end="friEndTime"
        />
        <DaySchedule
          day="Saturday"
          enable="enableSat"
          start="satStartTime"
          end="satEndTime"
        />
        <DaySchedule
          day="Sunday"
          enable="enableSun"
          start="sunStartTime"
          end="sunEndTime"
        />
      </RowScheduleStyled>
    </SchedulerTableStyled>
  );
};

export default SchedulerTable;
const SchedulerTableStyled = styled.div`
  margin-top: 1rem;
`;
const RowScheduleStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-0, #fff);
  display: flex;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .header {
    border-bottom: 1px solid var(--line-line-3, #c9cdd4);
  }
  .item-schedule {
    width: 100%;
    border-right: 1px solid var(--line-line-3, #c9cdd4);
    input {
      text-align: center;
    }
  }
  .item-schedule:first-child .header {
    border-top-left-radius: 5px;
  }
  .item-schedule:last-child .header {
    border-top-right-radius: 5px;
  }
  .item-schedule:last-child {
    border-right: none;
  }
`;
