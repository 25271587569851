import { isEmpty, isNaN, isNil } from 'lodash';

export const isFalsy = (value: any): boolean => {
  return (
    isEmpty(value) ||
    isNil(value) ||
    value === false ||
    value !== value ||
    (value instanceof Date && isNaN(value.getTime())) ||
    value === 'Invalid date'
  );
};
