import React from 'react';
import { Form as FormAnt, FormProps as FormAntProps } from 'antd';
import { styled } from 'styled-components';
export interface FormProps extends FormAntProps { }
const Form = ({ ...props }: FormProps) => {
    return (
        <Styled
            {...props}
            name="basic"
            autoComplete="off"
            layout="vertical"
        />
    );
};

export default Form;

const Styled = styled(FormAnt) <FormProps>`
.ant-form-item-control-input {
    min-height: unset;
}
`;

export const useForm = FormAnt.useForm;
export const useFormInstance = FormAnt.useFormInstance;
export const FormItem = FormAnt.Item;
export const useWatch = FormAnt.useWatch;