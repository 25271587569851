


const EditPen = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M15.7291 0.0327744C16.4548 -0.0661495 17.2103 0.0593375 17.8587 0.402823C18.2236 0.587848 18.5328 0.85943 18.8606 1.10033C19.2192 1.37786 19.6069 1.62792 19.901 1.97874C20.4095 2.55854 20.725 3.30597 20.7767 4.07629C20.8375 4.92585 20.5814 5.79601 20.0656 6.47428C17.101 10.3126 14.1318 14.1473 11.1663 17.9847C11.0895 18.0901 10.9861 18.1752 10.864 18.2229C8.83776 19.0509 6.81333 19.884 4.78708 20.7125C4.51088 20.832 4.16654 20.7079 4.01883 20.4487C3.89399 20.2508 3.94384 20.0085 3.95344 19.7892C4.07005 17.7406 4.18757 15.6925 4.30373 13.644C4.31927 13.4113 4.29824 13.1498 4.46058 12.9607C7.35659 9.20751 10.2535 5.4539 13.1495 1.70028C13.3233 1.47908 13.4847 1.24643 13.6878 1.04995C14.231 0.49671 14.9618 0.13582 15.7291 0.0327744ZM15.0221 1.57755C14.4615 1.8963 14.1538 2.4871 13.7477 2.96385C15.3089 4.16056 16.8787 5.3449 18.4427 6.53748C18.6462 6.27094 18.8519 6.00668 19.0568 5.74105C19.381 5.32154 19.5553 4.78799 19.5383 4.25765C19.5233 3.56839 19.173 2.89974 18.6251 2.48526C18.257 2.20773 17.8921 1.92653 17.5217 1.65311C16.7964 1.14338 15.778 1.11819 15.0221 1.57755ZM5.80547 13.2616C7.37397 14.4381 8.94339 15.6138 10.5078 16.7953C12.8967 13.7031 15.2929 10.6163 17.6767 7.51985C16.1114 6.33322 14.5479 5.14385 12.9863 3.95309C10.5933 7.05638 8.19664 10.1574 5.80547 13.2616ZM5.23751 19.1805C6.61121 18.6163 7.98582 18.053 9.36044 17.4906C8.07317 16.5224 6.78407 15.556 5.49679 14.5879C5.4122 16.1189 5.32028 17.6495 5.23751 19.1805Z" fill={props?.fill ? props.fill : '#1D2129'} />
            <path d="M0.407594 22.7885C0.547524 22.7368 0.698429 22.751 0.844762 22.7496C8.35804 22.751 15.8713 22.7487 23.3846 22.751C23.7074 22.7464 23.9974 23.0326 23.9974 23.3559C24.0189 23.7095 23.6914 24.0241 23.3402 23.9985C15.7817 24.0022 8.22314 23.998 0.66459 23.9985C0.389759 24.0141 0.118129 23.8373 0.0344447 23.573C-0.0794205 23.2653 0.100295 22.8957 0.407594 22.7885Z" fill={props?.fill ? props.fill : '#1D2129'} />
        </svg>
    );
};

export default EditPen;
