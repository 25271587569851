import { Col, Divider, Row } from 'antd';
import { IconStoryBoard } from 'assets/Icons';
import AvatarBox from 'components/AvatarBox';
import Button from 'components/Button';
import CarouserBox from 'components/Carosel/CarouserBox';
import CategoryItem from 'components/Category';
import { ModalStoryBoard } from 'components/Modal';
import NumberKeyBoard, {
  NumberKeyBoardStoryboard,
} from 'components/NumberKeyBoard';
import ServiceItem from 'components/ServiceItem';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import React, { useState } from 'react';
import StaffList from './checkIn/pages/StaffPage/components/StaffList';
type IStoryboardPageProps = any;
const StoryboardPage: React.FC<IStoryboardPageProps> = () => {
  const categories = [
    { name: 'waxing', isSelected: true, id: 'waxing' },
    { name: 'nail', isSelected: true, id: 'nail' },
    { name: 'manicure', isSelected: false, id: 'manicure' },
    { name: 'predicure', isSelected: false, id: 'predicure' },
    { name: 'shave', isSelected: false, id: 'shave' },
    { name: 'shave1', isSelected: false, id: 'shave1' },
    { name: 'shave2', isSelected: false, id: 'shave2' },
    { name: 'shave3', isSelected: false, id: 'shave3' },
    { name: 'waxing', isSelected: false, id: 'waxing' },
    { name: 'waxing1', isSelected: false, id: 'waxing1' },
    { name: 'waxing2', isSelected: false, id: 'waxing2' },
    { name: 'waxing3', isSelected: false, id: 'waxing3' },
  ];
  const [listDisplay, setListDisplay] = useState(categories);

  const handleClick = (id: string) => {
    setListDisplay((preState) => {
      return preState.map((item) => {
        if (item.id === id) {
          return { ...item, isSelected: !item.isSelected };
        }
        return item;
      });
    });
  };

  const listttest = [
    {
      value: '1 non box shadow',
      isSelected: false,
      src: '',
      isBusy: false,
      boxShadow: false,
    },
    {
      value: '2 is disabled',
      isSelected: false,
      src: '',
      isBusy: true,
      boxShadow: false,
    },
    {
      value: '3 isselected',
      isSelected: true,
      src: '',
      isBusy: false,
      boxShadow: false,
    },
    {
      value: '3 boxsHADOW',
      isSelected: false,
      src: '',
      isBusy: false,
      boxShadow: true,
    },
  ];
  const [options, setOptions] = useState(listttest);

  const handleClickAvatarBoxItem = (value: string) => {
    setOptions((prev) =>
      prev.map((item) => {
        if (item.value === value) {
          return { ...item, isSelected: !item.isSelected };
        }
        return { ...item, isSelected: false };
      })
    );
  };
  return (
    <div className="page-overflow">
      <div style={{ padding: '24px', width: '30%' }}>
        <Row>
          {options.map((item) => {
            return (
              <Col span={8} key={item.value}>
                <AvatarBox
                  src={item.src}
                  name={item.value}
                  selected={item.isSelected}
                  onClick={() => handleClickAvatarBoxItem(item.value)}
                  isBusy={item.isBusy}
                  boxShadow={item.boxShadow}
                />
              </Col>
            );
          })}
        </Row>
      </div>
      <ModalStoryBoard />
      <NumberKeyBoardStoryboard />
      <IconStoryBoard />
      <div style={{ padding: '24px', width: '30%' }}>
        <CarouserBox
          content="CATEGORIES"
          list={listDisplay}
          rowPerPage={4}
          colSpan={12}
          itemContent={(item) => {
            return (
              <CategoryItem
                onClick={() => handleClick(item.id)}
                selected={item.isSelected}
              >
                {item.name}
              </CategoryItem>
            );
          }}
        />
      </div>
      <div style={{ padding: '2rem' }}>
        <CategoryItem selected={false}>test</CategoryItem>
        <Divider />
        <CategoryItem selected={true}>test</CategoryItem>
      </div>
      <div style={{ padding: '2rem' }}>
        <ServiceItem
          size="LARGE"
          service="Waxing 1.1"
          price={50}
          selected={false}
        />
        <Divider />
        <ServiceItem
          size="LARGE"
          service="Waxing 1.2"
          price={50}
          selected={true}
        />
        <Divider />
        <ServiceItem
          size="SMALL"
          service="Waxing 1.2"
          price={50}
          selected={true}
        />
      </div>
      <div>
        <Button block icon="back" ntype="DEFAULT">
          BLOCK DEFAULT
        </Button>
        <Divider />
        <Row gutter={16}>
          <Col>
            <Button ntype="DEFAULT">DEFAULT</Button>
          </Col>
          <Col>
            <Button icon="back" ntype="DEFAULT">
              DEFAULT
            </Button>
          </Col>
          <Col>
            <Button icon="back" disabled ntype="DEFAULT">
              DEFAULT disabled
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col>
            <Button icon="back" ntype="PRIMARY">
              PRIMARY
            </Button>
          </Col>
          <Col>
            <Button disabled icon="back" ntype="PRIMARY">
              PRIMARY disabled
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col>
            <Button icon="back" ntype="SECONDARY">
              SECONDARY
            </Button>
          </Col>
          <Col>
            <Button disabled icon="back" ntype="SECONDARY">
              SECONDARY disabled
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col>
            <Button icon="back" ntype="DANGER">
              DANGER
            </Button>
          </Col>
          <Col>
            <Button disabled icon="back" ntype="DANGER">
              DANGER disabled
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col>
            <Button icon="back" ntype="YELLOW">
              YELLOW
            </Button>
          </Col>
          <Col>
            <Button disabled icon="back" ntype="YELLOW">
              YELLOW disabled
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={16}>
          <Col>
            <Button icon="back" ntype="LIGHT_BLUE">
              LIGHT_BLUE
            </Button>
          </Col>
          <Col>
            <Button disabled icon="back" ntype="LIGHT_BLUE">
              LIGHT_BLUE disabled
            </Button>
          </Col>
        </Row>
      </div>
      <Divider />
      <Text variant="H1">H1 - The quick brown fox...</Text>
      <Text variant="H2">H2 - The quick brown fox...</Text>
      <Text variant="H3">H3 - The quick brown fox...</Text>
      <Text variant="H4">H4 - The quick brown fox...</Text>
      <Text variant="H5">H5 - The quick brown fox...</Text>
      <Text variant="H6">H6 - The quick brown fox...</Text>
      <Text variant="H7">H7 - The quick brown fox...</Text>
      <Text variant="H8">H8 - The quick brown fox...</Text>
      <Text variant="H9">H9 - The quick brown fox...</Text>
      <Text variant="BODY_1">BODY_1 - The quick brown fox...</Text>
      <Text variant="BODY_2">BODY_2 - The quick brown fox...</Text>
      <Text variant="CAPTION_1">CAPTION_1 - The quick brown fox...</Text>
      <Text variant="CAPTION_2">CAPTION_2 - The quick brown fox...</Text>
      <Text variant="CAPTION_3">CAPTION_3 - The quick brown fox...</Text>
      <Text variant="CAPTION_4">CAPTION_4 - The quick brown fox...</Text>
      <NumberKeyBoard></NumberKeyBoard>
      <StaffList></StaffList>
      <Sidebar />
    </div>
  );
};

export default StoryboardPage;
