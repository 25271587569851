import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IApiGetTurnDetailParam, IApiGetTurnParam, IApiGetTurnStaffsAvailable, IApiUpdateTurnDetailParam, ISyncWaitngToTicket } from './types/api';
import { IWaitingListResItem } from 'features/user/services/types/waiting';

const getTurn = createAsyncAction<IApiGetTurnParam>(PREFIX_ACTIONS + 'getTurn');
const setTurnLoading = createAction<boolean>(PREFIX_ACTIONS + 'setTurnLoading');
const getTurnDetail = createAsyncAction<IApiGetTurnDetailParam>(PREFIX_ACTIONS + 'getTurnDetail');
const setShowModalTurnDetail = createAction<boolean>(PREFIX_ACTIONS + 'showModalTurnDetail');
const getTurnStaffsAvailable = createAsyncAction<IApiGetTurnStaffsAvailable>(PREFIX_ACTIONS + 'getTurnStaffsAvailable');
const updateTurnDetail = createAsyncAction<IApiUpdateTurnDetailParam>(PREFIX_ACTIONS + 'updateTurnDetail');
const syncWaitngToTicket = createAsyncAction<ISyncWaitngToTicket>(PREFIX_ACTIONS + 'syncWaitngToTicket');
const selectWaitingListResItem = createAction<IWaitingListResItem | undefined>(PREFIX_ACTIONS + 'selectWaitingListResItem');

const clearAllTurn = createAction(PREFIX_ACTIONS + 'clearAllTurn');

const syncTurn = createAction(PREFIX_ACTIONS + 'syncTurn');

/**
 * TODO:
 * please replace FEATURE_NAME to your feature name
 * ex:
 * FEATURE_NAMEActions => bookingActions
 */
const turnActions = {
  getTurn,
  getTurnDetail,
  setShowModalTurnDetail,
  getTurnStaffsAvailable,
  updateTurnDetail,
  syncTurn,
  selectWaitingListResItem,
  syncWaitngToTicket,
  setTurnLoading,
  clearAllTurn,
};

export default turnActions;

