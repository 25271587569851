import { Radio, RadioChangeEvent, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import TurnService from './Components/TurnService';
import TurnTicket from './Components/TurnTicket';
type ITurnSetting = {};
type TypaRadio = 'TICKET' | 'SERVICE' | string;
const TurnSetting: React.FC<ITurnSetting> = () => {
    const [typeRadio, setTypeRadio] = useState<TypaRadio>('TICKET');
    const dispatch = useAppDispatch();
    const turnSetting = settingServiceSelectors.getTurnSetting();
    const reloadTurnSetting = settingServiceSelectors.reloadTurnSetting();
    useEffect(() => {
        dispatch(settingServiceActions.getTurnSetting.fetch({}));
    }, []);
    useEffect(() => {
        if (reloadTurnSetting) {
            dispatch(settingServiceActions.getTurnSetting.fetch({}));
        }
    }, [reloadTurnSetting]);
    useEffect(() => {
        setTypeRadio(turnSetting.type);
    }, [turnSetting]);
    const onChangeType = (e: RadioChangeEvent) => {
        setTypeRadio(e.target.value);
    };
    return (
        <TurnSettingStyled>
            <Box bgColor="fill_1" p="3" borderRadius="5px" >
                <Radio.Group onChange={onChangeType} value={typeRadio} style={{ width: '100%' }}>
                    <Row wrap={false}>
                        <SelectBox onClick={() => setTypeRadio('TICKET')}>
                            <Icon type='turnTicketSetting' />
                            <Box bgColor="fill_1" width={'100%'} justifyContent='center' p="3" display='flex'>
                                <Radio value={'TICKET'}>By Ticket</Radio>
                            </Box>
                        </SelectBox>
                        <SelectBox onClick={() => setTypeRadio('SERVICE')}>
                            <Icon type='turnServiceSetting' />
                            <Box bgColor="fill_1" width={'100%'} justifyContent='center' p="3" display='flex'>
                                <Radio value={'SERVICE'} >By Service</Radio>
                            </Box>
                        </SelectBox>
                    </Row>
                </Radio.Group>
                {
                    typeRadio == 'TICKET' ?
                        <TurnTicket key={'TICKET'} data={turnSetting?.turnTicket} /> :
                        <TurnService key={'SERVICE'}  data={turnSetting?.turnServices}/>
                }
            </Box>
        </TurnSettingStyled>
    );
};

export default TurnSetting;
const TurnSettingStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
`;

const SelectBox = styled.div`
    height: 232px;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--fill-fill-2, #ADADAD);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    margin: 0px 10px;
    display: flex;
    flex:1;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
`;