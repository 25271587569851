import queryString from 'query-string';
import {
  AddTipParams,
  IAdjustTip,
  IApiGetTicketsParam,
  IEditStaffByBillId,
  IRefundBody,
  VoidByBillIdParams,
} from './types/api';
import fetch from 'services/request';

const baseURL = 'api/v1';

export const getTicketsData = (params: IApiGetTicketsParam) => {
  const paramsPasser = queryString.stringify(
    { ...params },
    { arrayFormat: 'bracket' }
  );

  return fetch({
    method: 'get',
    url: `${baseURL}/bill/list-ticket-by-status?${paramsPasser?.replaceAll('[]', '')}`,
  });
};

export const getTicketDetailsData = (id: string) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/bill/transaction-detail/${id}`,
  });
};

export const changeStaffByBillId = (payload: IEditStaffByBillId) => {
  const _body = {
    billId: payload.billId,
    staffIdOld: payload.staffIdOld,
    staffIdNew: payload.staffIdNew,
  };

  return fetch({
    method: 'post',
    url: `${baseURL}/payment/edit-ticket-staff`,
    body: _body,
    pinCode: payload.pinCode,
  });
};
export const refundByBillId = (payload: IRefundBody) => {
  const _body = {
    billId: payload.billId,
    amount: payload.amount,
  };
  return fetch({
    method: 'post',
    url: `${baseURL}/payment/refund-ticket`,
    body: _body,
    pinCode: payload.pinCode,
  });
};

export const voidByBillId = ({ id, reason }: VoidByBillIdParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/payment/void-ticket`,
    body: { billId: id, reason }
  });
};

export const addTipByBillId = (body: AddTipParams) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/payment/add-tip-credit-card`,
    body,
  });
};

export const editCashByBillId = (body: IRefundBody) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/payment/edit-cash`,
    body,
  });
};
export const adjustTip = (body: IAdjustTip[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/payment/adjust-multiple-tip-card`,
    body,
  });
};

const ticketListApis = {
  getTicketsData,
  getTicketDetailsData,
  refundByBillId,
  voidByBillId,
  changeStaffByBillId,
  editCashByBillId,
  adjustTip,
};

export default ticketListApis;
