import { Row } from 'antd';
import { styled } from 'styled-components';
import MergeTicketButton from '../../Button/MergeTicketButton';
import SplitButtonButton from '../../Button/SplitButton';
import ButtonPrint from '../ButtonPrint';
import ButtonAddStaff from './ButtonAddStaff';
import ModalChooseStaff from './ButtonAddStaff/ModalChooseStaff';
import DeleteItemButton from './DeleteItemButton';
import DiscountItemButton from './DiscountItemButton';

const GroupActions = () => {
  return (
    <GroupActionStyled>
      <div className='row-actions'>
        <DiscountItemButton />
        <DeleteItemButton />
        <ButtonPrint />
      </div>
      <div className='row-actions'>
        <ButtonAddStaff />
        <MergeTicketButton />
        <SplitButtonButton />
      </div>
      <ModalChooseStaff />
    </GroupActionStyled>
  );
};

export default GroupActions;

const GroupActionStyled = styled(Row)`
  justify-content: flex-end;
  gap: 0.5rem;
  .row-actions {
    display: flex;
    gap: 1rem;
  }
`;