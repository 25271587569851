import { Col, Collapse, CollapseProps, Row } from 'antd';
import { RowProps } from 'antd/lib';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import { DiscountType, ITEM_HANDLE_MODE, encodeDraftServiceSwitchStaffId } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import { IService, IStaff, IStaffItem } from 'features/cashier/services/types/api';
import { first } from 'lodash';
import React, { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PaymentResult from '../PaymentResult';
import TotalLabel from '../TickerFooter/TotalLabel';
import { ModalAddStaff } from './GroupActions/ButtonAddStaff';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
type IComponentProps = {
  staffAndService?: string;
};

const BillSelectedItems: React.FC<IComponentProps> = () => {
  const modalConfirm = useModalConfirmRef();
  const dispatch = useAppDispatch();
  const itemMode = cashierSelectors.getItemHandleMode();
  const highlightItemId = cashierSelectors.getHighlightItemId();

  const [draffStaffId, setDraffStaffId] = useState<string | null>(null);

  const draftServiceSwitchStaff =
    cashierSelectors.getDraftServiceSwitchStaff();

  const selectedItems = cashierSelectors.getSelectedItems();
  const activeTab = cashierSelectors.getActiveItemsCollapse();
  const activeStaff = cashierSelectors.getActiveStaffId();

  const setActiveTab = (staffId: string[]) => {
    dispatch(cashierActions.setActiveItemsCollapse(staffId));
  };

  const setActiveStaff = (staffId: string | string[]) => {
    const id = typeof staffId === 'string' ? staffId : first(staffId) || '';
    if (!id) return;
    dispatch(cashierActions.setActiveStaffId(id));
  };

  const handleSwitchStaff = (staffId: string) => {
    dispatch(cashierActions.switchStaffItem(staffId));
  };

  const handleRemoveStaff = (staffId: string) => {
    modalConfirm.current?.open('Do you like to remove this items?', () => dispatch(cashierActions.removeStaffItem(staffId)));
  };

  const setDraftServiceSwitchStaff = (staffId: string, itemId: string) => {
    if (selectedItems.length < 2) return;
    dispatch(
      cashierActions.setDraftServiceSwitchStaff(
        encodeDraftServiceSwitchStaffId({ staffId, itemId })
      )
    );
  };

  const setDraffQuickPay = (staffId: string, itemId: string, value: number, itemName: string) => {
    dispatch(cashierActions.setDraftQuickPay({
      staffId,
      itemId,
      value,
      itemName,
    }));
  };

  const onPickNewStaff = (newStaff: IStaff) => {
    if (!draffStaffId) return;
    dispatch(cashierActions.switchStaffNew({
      currStaffId: draffStaffId,
      newStaff,
    }));
    setDraffStaffId(null);
  };

  const displayCollapse: CollapseProps['items'] = selectedItems?.map((item) => {
    const visibleSwitchStaff = !!(
      selectedItems.length > 1 &&
      draftServiceSwitchStaff &&
      !draftServiceSwitchStaff.includes(item.staffId)
    );

    const onHandleDiscount = (e: React.MouseEvent, staff: IStaffItem, service: IService) => {
      if (itemMode === ITEM_HANDLE_MODE.DISCOUNT_ITEM) {
        e.stopPropagation();
        dispatch(
          cashierActions.setDraftDiscountItemModal({
            staffId: staff.staffId,
            service_uuid_local: service.uuid_local || '',
            amount: service.price,
            discount: service.discount,
          })
        );
      }
      return;
    };

    return {
      className: selectedItems?.length > 1 && item.staffId === activeStaff ? 'active-staff' : '',
      key: item.staffId,
      label: (
        <BillPanelItemHeader align={'middle'} justify={'space-between'} onClick={(e: any) => [e.stopPropagation(), setActiveStaff(item.staffId)]}>
          <Row align={'middle'} style={{ gap: '0.5rem' }}>
            <Text variant="H9" color="text_3">
              {item.staffName || ''}
            </Text>
            <ButtonSwitchStaffItem onClick={() => setDraffStaffId(item.staffId)} />
          </Row>
          <div style={{ flex: 1 }} />
          {visibleSwitchStaff && (
            <ButtonSwitchStaff
              onClick={() => handleSwitchStaff(item.staffId)}
            />
          )}
          <ButtonRemoveStaff onClick={() => handleRemoveStaff(item.staffId)} />
        </BillPanelItemHeader>
      ),
      children: (
        <div
          style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
        >
          {item.services.length > 0 ? (
            item.services.map((serviceItem) => {
              const draftSwitchStaffActive = serviceItem?.uuid_local
                ? draftServiceSwitchStaff?.includes(serviceItem?.uuid_local)
                : false;

              const discount = serviceItem.discountType === DiscountType.PERCENT ? ((serviceItem.price ?? 0) * serviceItem.discount / 100) : serviceItem.discount ?? 0;
              const afterDiscountPrice = serviceItem.price - discount;
              const isHighLight = (serviceItem?.uuid_local === highlightItemId) && !draftSwitchStaffActive;
              return (
                <ItemCollapseStyled
                  key={serviceItem?.uuid_local}
                  active={draftSwitchStaffActive}
                  enableSwitchStaff={selectedItems.length > 1}
                  highlightItemId={isHighLight}
                  onClick={() =>
                    setDraftServiceSwitchStaff(
                      item.staffId,
                      serviceItem?.uuid_local ?? ''
                    )
                  }
                >
                  <Row align={'middle'} style={{ width: '100%' }}>
                    <Col>
                      <Text variant="CONTENT_1" color={isHighLight ? 'fill_1' : 'text_3'}>{serviceItem.itemType === 'VIP' ? (serviceItem.itemName || 'VIP') : serviceItem.itemName}</Text>
                    </Col>
                    <Col flex={'auto'} />
                    <Col>
                      <PriceBoxContainerStyled>
                        <PriceBoxStyled onClick={(event: React.MouseEvent) => onHandleDiscount(event, item, serviceItem)}>
                          <CenterBox>

                            {!!serviceItem?.discount && <Text
                              textDecorate="line-through"
                              variant="H9"
                              color={isHighLight ? 'fill_1' : 'primary_active'}
                              mr={0.5}
                            >
                              {formatCurrency(serviceItem.price)}
                            </Text>}
                            <button onClick={(e) => [e.stopPropagation(), setDraffQuickPay(item.staffId, serviceItem?.uuid_local ?? '', serviceItem.price, serviceItem.itemName)]}>
                              <Text variant="H9" color={isHighLight ? 'fill_1' : 'text_3'} mr={0.5}>
                                {formatCurrency(afterDiscountPrice)}
                              </Text>
                            </button>
                          </CenterBox>
                          <ActionItem staff={item} service={serviceItem} itemMode={itemMode} isHighLight={isHighLight} />
                        </PriceBoxStyled>
                      </PriceBoxContainerStyled>
                    </Col>
                  </Row>
                </ItemCollapseStyled>
              );
            })
          ) : (
            <ItemCollapseNoneStyled>
              <Text variant="H8" color="text_2">
                Select services
              </Text>
            </ItemCollapseNoneStyled>
          )}
        </div >
      ),
    };
  });

  return (
    <>
      <WrapStyled className='box'>
        <WrapStyled>
          <BillSelectedItemsStyled className={`flex-grow ${selectedItems.length > 1 ? '' : 'singleItem'}`}>
            <Collapse
              defaultActiveKey={[0]}
              activeKey={activeTab}
              size="middle"
              bordered={false}
              //@ts-ignore
              onChange={setActiveTab}
              expandIconPosition={'end'}
              items={displayCollapse}
            />
          </BillSelectedItemsStyled>
        </WrapStyled>
        <BillSelectedItemsStyled>
          <PaymentResult />
          <TotalLabel />
        </BillSelectedItemsStyled>
      </WrapStyled>
      <ModalAddStaff
        visible={!!draffStaffId}
        onPickStaff={onPickNewStaff}
        onClose={() => setDraffStaffId(null)}
      />
      <ModalConfirm ref={modalConfirm} />
    </>
  );
};

export default BillSelectedItems;

const WrapStyled = styled.div`
  max-height: 100%;
  overflow: auto;
  
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  .flex-grow {
    flex: 1;
    flex-grow: 1;
  }
  background-color: #f6f7fc;
  &.box {
    width: 100%;
    margin-top: 1rem;
    border-radius: 5px;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
`;

const BillPanelItemHeader = styled(Row) <RowProps>`
  padding: 12px 16px;
  padding-right: 0px;
`;

const BillSelectedItemsStyled = styled.div`
  border-radius: 5px;
  .ant-collapse-header {
    background-color: white;
    border-radius: 5px !important;
    
  }
  .ant-collapse-content-box {
    overflow: auto;
    padding: 1rem !important;
    padding-top: 0 !important;
    background-color: #f6f7fc !important;
    border-radius: 5px;
  }
  .ant-collapse-item {
    background: #f6f7fc;
  }

  .ant-collapse-item > .ant-collapse-header {
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #86909c;
    padding:0;
    &:hover {
      background: #ffe5e782;
    }
  }
  
  .active-staff {
    .ant-collapse-header {
      background: var(--primary-button, #FFE5E7);
      border-bottom: 2px solid #F5767F;
      &:hover {
        background: #ffe5e782;
      }
    }
  }

  &.singleItem {
    .ant-collapse-item > .ant-collapse-header {
      &:hover {
        background: #fff;
      }
    }
  }

  .ant-collapse.ant-collapse-icon-position-end> .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon {
    padding: 20px;
    height: 100%;
    &:hover {
      background: rgba(0, 0, 0, 0.10);
    }
  }
  .ant-collapse-borderless > .ant-collapse-item {
    border-bottom: none;
  }
`;
const ItemCollapseStyled = styled.button.withConfig({
  shouldForwardProp: (prop) => !['enableSwitchStaff'].includes(prop),
}) <{ active?: boolean; enableSwitchStaff?: boolean, highlightItemId?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.25rem 0.5rem;
  width: 100%;
  border-radius: 5px;
  background: transparent;
  &:hover {
    background: #e5e6eb;
  }
  ${({ active }) =>
    active
      ? `
    background: var(--blue-headline, #C6E9EF);
    &:hover {
      background: var(--blue-headline, #C6E9EF);
    }
  `
      : ''}
   
  ${({ enableSwitchStaff }) =>
    !enableSwitchStaff
      ? `
    &:hover {
      background: transparent;
    }
  `
      : ''}
  
  ${({ highlightItemId }) =>
    highlightItemId
      ? `
    background: black;
    &:hover {
      background: black;
    }
`
      : ''}
`;
const ItemCollapseNoneStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const PriceBoxStyled = styled.div`
  display: flex;
  align-items: center;
  button {
    display: flex;
  }
`;
const CenterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PriceBoxContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonSwitchStaffStyled = styled.button`
  padding:0 0.5rem;
  border-radius: 5px;
  &:hover {
    background: rgba(255,255,255);
  }
  &.changeStaff {
    padding: 0;
  }
`;

const ButtonSwitchStaffItem = ({ onClick }: { onClick: () => void }) => {
  return (
    <ButtonSwitchStaffStyled className='changeStaff' onClick={(e: any) => {
      e.stopPropagation();
      onClick();
    }}><Icon type="changeCustomer" /></ButtonSwitchStaffStyled>
  );
};

const ButtonSwitchStaff = ({ onClick }: { onClick: () => void }) => {
  return (
    <ButtonSwitchStaffStyled
      onClick={(e: any) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Row align={'middle'} justify={'center'}>
        <Icon type="switch" />
        <Text ml={0.25} variant="H9" color="info_3">
          Switch
        </Text>
      </Row>
    </ButtonSwitchStaffStyled>
  );
};

const ButtonRemoveStaff = ({ onClick }: { onClick: () => void }) => {
  return (
    <ButtonSwitchStaffStyled
      onClick={(e: any) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Row align={'middle'} justify={'center'}>
        <Icon type='deleteIconCashier' />
      </Row>
    </ButtonSwitchStaffStyled>
  );
};



const ActionItem = ({ staff, service, itemMode, isHighLight }: { staff: IStaffItem, service: IService, itemMode: ITEM_HANDLE_MODE | null, isHighLight?: boolean }) => {
  const dispatch = useAppDispatch();

  const handleClickCloseService = () => {
    dispatch(
      cashierActions.removeServiceOfItem({
        staffId: staff.staffId,
        serviceId: service.uuid_local,
      })
    );
  };

  const handleClickDiscountItem = () => {
    dispatch(
      cashierActions.setDraftDiscountItemModal({
        staffId: staff.staffId,
        service_uuid_local: service.uuid_local || '',
        amount: service.price,
        discount: service.discount,
      })
    );
  };

  if (itemMode === ITEM_HANDLE_MODE.DELETE_ITEM) {
    return (
      <ActionItemStyled
        onClick={(e: any) => [
          e.stopPropagation(),
          handleClickCloseService(),
        ]}
      >
        <Icon type="circleClose" />
      </ActionItemStyled>
    );
  }

  if (itemMode === ITEM_HANDLE_MODE.DISCOUNT_ITEM) {
    return (
      <ActionItemStyled
        onClick={(e: any) => [
          e.stopPropagation(),
          handleClickDiscountItem(),
        ]}
      >
        <Icon type="discount" fill={isHighLight ? '#fff' : '#000'} />
      </ActionItemStyled>
    );
  }

  return <></>;
};

const ActionItemStyled = styled.button`
  height: 1.5rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;