import { InputRef } from 'antd';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import { useRef } from 'react';
import { ICustomerItemData } from 'services/shop/types/customer';
import { useAppDispatch } from 'store/hooks';
import CustomerSearchInput from 'widgets/Customer/CustomerSearchInput';
import ButtonAddCustomer from './AddCustomer';
import CustomerInfo from './CustomerInfo';
import ModalChooseCustomer from './ModalChooseCustomer';
import ModalWarningVIPPackage from './ModalWarningVIPPackage';
import { ContentBoxStyled, CustomerStyled } from './styles';
const Customer = () => {
  const inputRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();

  const onPickCustomer = (cus: ICustomerItemData) => {
    dispatch(cashierActions.switchSelectedCustomer(cus));
  };

  return (
    <CustomerStyled>
      <ContentBoxStyled>
        <Text variant="H6" color="text_2">CUSTOMER</Text>
        <ButtonAddCustomer />
      </ContentBoxStyled>
      <CustomerSearchInput inputRef={inputRef} onSelect={onPickCustomer} />
      <CustomerInfo />
      <ModalChooseCustomer />
      <ModalWarningVIPPackage />
    </CustomerStyled>
  );
};

export default Customer;
