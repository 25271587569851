import { Col, Row } from 'antd';
import Modal from 'components/Modal';
import colorTheme from 'constants/color';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import shopSelectors from 'services/shop/selectors';
import { IProductVIPItemData } from 'services/shop/types/vip';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CardTicketItem from '../CardTicketItem';
import CardItem, { CardFirst } from './CardItem';
import close from './close-circle-fill.png';

function VIP() {
  const dispatch = useAppDispatch();
  const visible = cashierSelectors.getVisibleVIP();
  const productsVIP = shopSelectors.data.productsVIP();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();
  const customer = cashierSelectors.getSelectedCustomer();
  const setVisible = (val: boolean) => {
    dispatch(cashierActions.setVisibleVIP(val));
  };

  const handleBuy = (o: IProductVIPItemData) => () => {
    dispatch(cashierActions.selectProductVIPItem(o));
    setVisible(false);
  };

  const handleVIP = () => {
    setVisible(true);
    if (!customer?.customerId) {
      dispatch(cashierActions.setVisibleChooseCustomer(true));
      return;
    }
  };

  return (
    <>
      <CardTicketItem
        icon={'cardVIP'}
        content={'VIP'}
        nbgcolor={colorTheme.info_bg_2}
        textColor={colorTheme.text_3}
        border_color={colorTheme.hover_info}
        onClick={handleVIP}
      />
      {customer?.customerId && <Modal
        visible={visible}
        modalTitle='VIP'
        footer={null}
        closeIcon={<IconClose />}
        closable
        noneBodyStyle
        noneFooterStyle
        className='modal-vip'
        showClose
        onClose={() => setVisible(false)}
        width={'120vh'}
      >

        <VIPBody gutter={[20, 20]} style={{ margin: '0' }} wrap={false}>
          <Col span={6} className='sticky'>
            <CardFirst />
          </Col>
          {productsVIP.map(o => (
            <Col span={6} key={o.id} className='item-box'>
              <CardItem
                data={o}
                onBuy={handleBuy(o)}
                selected={!!serviceIdsSelected.find(s => s.id === o.id)}
              />
            </Col>
          ))}
        </VIPBody>
      </Modal>}
    </>
  );
}

export default VIP;

const VIPBody = styled(Row)`
  height: 60vh;
  padding: 1.5rem 0rem;
  overflow-x: auto;
  margin: 0 10px;
  .sticky {
    position: sticky;
    left:0px;
    background:#fff;
    z-index:2;
  }
  .item-box {
    position: relative;
    z-index:1;
  }
`;

const IconClose = () => (
  <Row>
    <img src={close} style={{ height: '100%', width: '100%' }} />
  </Row>
);
