import React, { useEffect } from 'react';
import Sidebar from 'components/layout/Sidebar';
import settingStaffActions from 'features/settingStaff/services/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import StaffDetails from './components/StaffDetails';
import StaffList from './components/StaffList';
import SettingStaffPageStyled from './styles';
type ISettingStaffPageProps = any;
const SettingStaffPage: React.FC<ISettingStaffPageProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const params = {
      page: 1,
      size: 1000,
    };
    dispatch(settingStaffActions.setParams(params));
  }, []);

  return (
    <SettingStaffPageStyled>
      <Sidebar />
      <BodyStyled>
        <StaffList />
        <StaffDetails />
      </BodyStyled>
    </SettingStaffPageStyled>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  max-width: calc(100vw - 6.5rem);
`;

export default SettingStaffPage;
