import { daysOfWeek } from 'features/appointment/services/constants';
import React from 'react';
import CalendarMonthStyled from './styles';
type IHeaderProps = any;
const Header: React.FC<IHeaderProps> = () => {
  return (
    <CalendarMonthStyled.THead>
      {
        daysOfWeek.map(o => (
          <CalendarMonthStyled.TH key={o.value} fixed>
            <p className='subtext'>{o.label}</p>
          </CalendarMonthStyled.TH>
        ))
      }
    </CalendarMonthStyled.THead>
  );
};

export default Header;
