import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IOwnerLoginResData } from './types/owner';
import storage from 'utils/sessionStorage';

const initialState: IState = {
  owner_access_token: storage.token.get(),
  owner_refresh_token: storage.refresh_token.get(),
  ownerInfo: null,
  shops: [],
  isAutoSelectShop: false,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setAutoSelectShop, ((state, { payload }) => {
        state.isAutoSelectShop = payload;
      }))
      .addCase(actions.ownerLogin.success, (state, { payload }) => {
        const resData = payload as IOwnerLoginResData;
        state.owner_access_token = resData?.accessToken || null;
        state.owner_refresh_token = resData?.refreshToken || null;
        state.ownerInfo = resData?.accountInfoDto || null;
        state.shops = resData?.shops || [];
        state.isAutoSelectShop = false;
      })
      .addCase(actions.ownerLogout, (state) => {
        state.owner_access_token = null;
        state.owner_refresh_token = null;
        state.ownerInfo = null;
        state.shops = [];
        state.isAutoSelectShop = false;
      });
  },
});

const authServiceReducer = Slice.reducer;
export default authServiceReducer;
