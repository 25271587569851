import { Avatar } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { get } from 'lodash';
import React from 'react';
import styled, { css } from 'styled-components';
type IComponentProps = {
  selected?: boolean;
  name?: string;
  src?: string;
  onClick?: () => void;
  isBusy?: boolean;
  bgColor?: string;
  className?: string;
  boxShadow?: boolean;
  margin?: string;
};
type AvatarTicketBoxStyledPropsType = {
  selected?: boolean;
  bgColor?: string;
  boxShadow?: boolean;
  isBusy?: boolean;
  onClick?: () => void;
  margin?: string;
};
const AvatarBox: React.FC<IComponentProps> = ({
  selected,
  name,
  src,
  onClick,
  isBusy, // just like disabled
  bgColor,
  boxShadow, // have border or not
  className = '',
}) => {
  return (
    <AvatarTicketBoxStyled
      onClick={onClick}
      selected={selected}
      boxShadow={boxShadow}
      bgColor={bgColor}
      isBusy={isBusy}
      className={className}
    >
      {selected && (
        <IconBadgeStyled>
          <Icon type="tick" />
        </IconBadgeStyled>
      )}
      <Avatar className="circle-avatar" src={src}>
        {get(name, [0], '')}
      </Avatar>
      <Text textAlign="center" variant="CONTENT_2" color="text_3">
        {name}
      </Text>
    </AvatarTicketBoxStyled>
  );
};

export default AvatarBox;

const AvatarTicketBoxStyledCSS = css<AvatarTicketBoxStyledPropsType>`
  pointer-events: ${({ isBusy }) => (isBusy ? 'none' : 'auto')};
  cursor: ${({ isBusy }) => (isBusy ? 'not-allowed' : 'pointer')};
  opacity: ${({ isBusy }) => (isBusy ? 0.5 : 1)};
  border: 1px solid
    ${({ selected, bgColor }) =>
      selected ? '#6fabb6' : bgColor ? bgColor : '#ffffff'};
  background: ${({ selected, isBusy }) =>
    selected ? '#C6E9EF' : isBusy ? '#E5E6EB' : '#ffffff'};
  box-shadow: ${({ selected, boxShadow }) =>
    selected
      ? '-1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);'
      : boxShadow
      ? '-1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)'
      : 'unset'};
`;

const AvatarTicketBoxStyled = styled.div.withConfig({
  shouldForwardProp: (prop) => !['isBusy', 'boxShadow'].includes(prop),
})<AvatarTicketBoxStyledPropsType>`
  // margin: ${({ margin }) => margin ? margin : 'auto;'};
  border-radius: 10px;
  display: flex;
  width: 5.5rem;
  min-height: 6.5rem;
  padding: 0.5rem 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  ${AvatarTicketBoxStyledCSS}
  .circle-avatar {
    width: 5.5rem;
    min-height: 5.5rem;
    border-radius: 50%;
    font-size: 2rem;
    display: flex;
    align-items: center;
  }
`;

const IconBadgeStyled = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;
