const EclipseAddStaff = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="77"
      height="77"
      viewBox="0 0 85 85"
      fill="none"
    >
      <circle cx="42.5" cy="42.5" r="42.5" fill="#6FABB6" />
    </svg>
  );
};

export default EclipseAddStaff;
