import { Avatar, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Button from 'components/Button';
import Text from 'components/Text';
import { IApiCreateUpdateClockInOut, IApiDeleteClockInOut } from 'features/report/services/types/api';
import { ITimeClockReportHistory } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import ModalDeleteTimeInOut from './ModalDeleteTimeInOut';
import ModalEditTimeInOut from './ModalEditTimeInOut';
import { formatCurrency } from 'utils/formatCurrency';
import { useAppDispatch } from 'store/hooks';
import reportSelectors from 'features/report/services/selectors';
import { ReportType } from '../../constants';
import reportActions from 'features/report/services/actions';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';

interface IStaffTableClock {
    data: ITimeClockReportHistory[],
    onEdit: (payload: IApiCreateUpdateClockInOut) => void,
    onDelete: (payload: IApiDeleteClockInOut) => void
}
interface DataType extends ITimeClockReportHistory {
    id: string,
    staffName: string,
    createDated: any,
    workTimeHour: number,
    clockInTime: string,
    clockOutTime: string,
    urlImage: any
}
const StaffTableClock: React.FC<IStaffTableClock> = ({ data, onEdit, onDelete }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.CLOCK_TIME];
    const handlePreviewWithStaff = (o: DataType) => {
        if (!dataAppointment || !o?.id) return;
        dispatch(reportActions.getDashboardTimeClockReport.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: o?.id
        }));
        dispatch(reportActions.getTimeClockReportHistory.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: o?.id
        }));
        navigate(`/store/${storage.shop_id.get()}/report/print/time-clock/${o?.id}`);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'STAFF',
            dataIndex: 'staffName',
            key: '1',
            width: 100,
            render: (_, record) => (
                <NameBox>
                    <ImageContainer>
                        <Avatar
                            src={record.urlImage}
                        >{record?.staffName?.charAt(0)}</Avatar>
                    </ImageContainer>

                    <Text variant="CONTENT_1" color="text_3">
                        {record.staffName}
                    </Text>
                </NameBox>
            ),
            align: 'center'
        },
        {
            title: 'DATE',
            dataIndex: 'createDated',
            key: '2',
            width: 100,
            render: (_, record) => <Text variant="CONTENT_1" color="text_3">{record?.createDated ? moment(record.createDated).format('MM-DD-YYYY') : '--/--/--'}</Text>,
            align: 'center'
        },
        {
            title: 'CLOCK IN',
            dataIndex: 'clockInTime',
            key: '2',
            width: 100,
            render: (_, record) => (
                <Text variant="CONTENT_1" color="text_3">{moment(record.clockInTime).format('HH:mm A')}</Text>),
            align: 'center'
        },
        {
            title: 'CLOCK OUT',
            dataIndex: 'breakBlockTime',
            key: '3',
            width: 100,
            render: (_, record) => (
                <Text variant="CONTENT_1" color="text_3">{moment(record.clockOutTime).format('HH:mm A')}</Text>),
            align: 'center'
        },
        {
            title: 'WORK TIME',
            dataIndex: 'workTimeHour',
            key: '4',
            width: 110,
            render: (_, record) => <Text variant="CONTENT_1" color="text_3">{record.workTimeHour.toString()}H</Text>,
            align: 'center'
        },
        {
            title: 'SALARY',
            dataIndex: 'commission',
            key: 'commission',
            width: 100,
            render: (value) => <Text variant="CONTENT_1" color="text_3">{formatCurrency(value)}</Text>,
            align: 'center'
        },
        {
            title: 'TIP',
            dataIndex: 'tip',
            key: 'tip',
            width: 100,
            render: (value) => <Text variant="CONTENT_1" color="text_3">{formatCurrency(value)}</Text>,
            align: 'center'
        },
        {
            title: 'DISCOUNT',
            dataIndex: 'discount',
            key: 'discount',
            width: 100,
            render: (value) => <Text variant="CONTENT_1" color="text_3">{formatCurrency(value)}</Text>,
            align: 'center'
        },
        {
            title: 'ACTIONS',
            width: 210,
            fixed: 'right',
            render: (_, record) => {
                return (
                    <Row style={{ gap: '12px' }}>
                        <Button ntype="LIGHT_BLUE" onClick={() => {
                            !dataEditModal && setDataEditModal(record);
                        }} icon='edit' style={{ width: 60, height: 50 }} />
                        <Button ntype="SECONDARY" onClick={() => {
                            !dataDeleteModal && setDataDeleteModal(record);
                        }} icon='deleteIcon' style={{ width: 60, height: 50 }} />
                        <Button ntype="SECONDARY" onClick={() => handlePreviewWithStaff(record)} >Preview</Button>

                    </Row>
                );
            },
        }
    ];

    const [dataEditModal, setDataEditModal] = useState<ITimeClockReportHistory>();
    const [dataDeleteModal, setDataDeleteModal] = useState<ITimeClockReportHistory>();
    const [dataTable, setDataTable] = useState<DataType[]>([]);
    useEffect(() => {
        const _mapData = data.map(m => {
            return {
                ...m,
                key: m.id,
                urlImage: m.avatar
            };
        });
        setDataTable(_mapData);
    }, [data]);
    return (
        <StyledStaffTableClock>
            <Table
                columns={columns}
                dataSource={dataTable}
                style={{ width: '100%', padding: '1rem' }}
                rowClassName={'row-table'}
                pagination={{ pageSize: 50 }}
                scroll={{ y: 500 }}
            />
            <ModalEditTimeInOut
                data={dataEditModal}
                key={Math.random()}
                isShown={dataEditModal ? true : false}
                onClose={() => setDataEditModal(undefined)}
                onSubmit={onEdit}
                onDelete={onDelete}
            />
            <ModalDeleteTimeInOut
                isShown={dataDeleteModal ? true : false}
                onClose={() => setDataDeleteModal(undefined)}
                onSubmit={onDelete}
                data={dataDeleteModal}
            />
        </StyledStaffTableClock>
    );
};
export default StaffTableClock;
const BORDER_WIDTH = '10px';
const BORDER_ROUND_WIDTH = '15px';


const StyledStaffTableClock = styled(Row)`
    flex: 1;
    border-radius: 5px;
    border: 1px solid #86909C;
    background: #FFF;

    /* box */
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

.ant-table-thead > tr > th {
    color: #767676 !important;
    background: #FFF !important;
    border-bottom: unset !important;
    transition: unset !important;
}
.ant-table-thead > tr > th::before{
    background-color: transparent !important;
    content: "";
}
.row-table {
    
}
.row-table td {
  border-top: ${BORDER_WIDTH};
  border-bottom:  ${BORDER_WIDTH} !important;
  border-color: #FFF;
  border-style: solid;
  border-right-width: 0px;
  border-left-width: 0px;
  background-color: #F6F7FC !important;
}
.row-table td:first-child {
 border-top-left-radius: ${BORDER_ROUND_WIDTH};
 border-bottom-left-radius: ${BORDER_ROUND_WIDTH};
 border-left-width:  ${BORDER_WIDTH};
 border-right-width: 0px;
}

.row-table td:last-child {
  border-top-right-radius: ${BORDER_ROUND_WIDTH};
  border-bottom-right-radius:  ${BORDER_ROUND_WIDTH};
  border-left-width: 0px; 
  border-right-width:  ${BORDER_WIDTH};
 }
`;

const ImageContainer = styled.div`
  min-width: 40px;
  margin-right: 0.5rem;
`;

const NameBox = styled(Row)`
  align-items: center;
  flex-wrap: nowrap;
  text-align: start;
`;
