import Text from 'components/Text';
import { uniqueId } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import styled from 'styled-components';
interface ICountUp {
    createDate: string;
    color?: string;
}
const CountUp: React.FC<ICountUp> = ({ createDate, color }) => {
    const uuid = uniqueId('CU-');
    useEffect(() => {
        const id = startTime();
        return () => clearInterval(id);
    }, []);
    const startTime = () => {
        const id = setInterval(() => {
            const now = moment();
            const _createDate = moment(createDate);
            const minute = now.diff(_createDate, 'minutes');
            const hour = now.diff(_createDate, 'hours');
            const countUpElement = document.getElementById(uuid);
            if (countUpElement) countUpElement.innerHTML = `${hour.toString().padStart(2, '0')}:${(minute % 59).toString().padStart(2, '0')}`;
        }, 1000 * 19);
        return id;
    };
    const initTime = () => {
        const now = moment();
        const _createDate = moment(createDate);
        const minute = now.diff(_createDate, 'minutes');
        const hour = now.diff(_createDate, 'hours');
        return `${hour.toString().padStart(2, '0')}:${(minute % 59).toString().padStart(2, '0')}`;
    };
    return <CountUpText variant='BODY_1' id={uuid} style={{ color }}>{initTime()}</CountUpText>;
};
export default CountUp;

const CountUpText = styled(Text)`
  color: #FFDC7C;
  padding: 0px 5px;
  width: 40px;
  text-align: center;
`; 