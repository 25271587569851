import fetch from 'services/request';
import { IApiBodyOwnerForgotPass, IApiBodyOwnerLogin } from './types/api';
const baseURL = 'api/v1/pos-service/auth';
const ownerLogin = (body: IApiBodyOwnerLogin) => {
  return fetch({
    method: 'post',
    url: baseURL + '/login',
    body,
  });
};

const ownerForgotPass = (body: IApiBodyOwnerForgotPass) => {
  return fetch({
    method: 'post',
    url: baseURL + '/forgot-password',
    body,
  });
};

const ownerLogout = () => {
  return fetch({
    method: 'post',
    url: baseURL + '/logout',
  });
};

const authApis = {
  ownerLogin,
  ownerForgotPass,
  ownerLogout,
};

export default authApis;
