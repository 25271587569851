import { Input } from 'antd';
import { FormItem, useFormInstance } from 'components/Form';
import InputDOB from 'components/InputDOB';
import InputEmail from 'components/InputEmail';
import InputPhone from 'components/InputPhone';
import { ICustomerFormValues } from 'features/cashier/services/types/customer';
import React, { useEffect } from 'react';
import styled from 'styled-components';
type IFormCustomerProps = {
  value?: ICustomerFormValues;
};
const FormCustomer: React.FC<IFormCustomerProps> = ({ value }) => {
  const form = useFormInstance();
  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);


  return (
    <FormCustomerStyled>
      <FormItem name='name' label={'Full name'} rules={[{ required: true, message: 'Please input your name!' }]}>
        <Input />
      </FormItem>
      <FormItem name='phone' label={<>Phone<span className='required'>(*)</span></>} rules={[{ required: true, message: 'Please input your phone!' }]}>
        <InputPhone />
      </FormItem>
      <FormItem name='birthday' label={'DOB'}>
        <InputDOB />
      </FormItem>
      <InputEmail
        name={'email'} label="Email" rules={[
          { type: 'email' },
          { required: true, message: 'Please input your email!' }
        ]}
      />
    </FormCustomerStyled>
  );
};

export default FormCustomer;
type FormCustomerStyledProps = {}
const FormCustomerStyled = styled.div<FormCustomerStyledProps>`
  .required {
    color: var(--primary-primary-active, #FF8890);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
