const Card = () => {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1027_66755)">
        <path
          d="M1.89875 5.89656C2.3725 5.73156 2.8825 5.74968 3.37687 5.75031C11.7087 5.75031 20.0406 5.75031 28.3725 5.75031C28.8312 5.75406 29.2931 5.72843 29.7481 5.80406C30.3144 5.91031 30.8444 6.19968 31.2394 6.61968C31.7112 7.11031 31.9669 7.77781 32 8.45406V24.0084C31.9681 24.6709 31.7238 25.3266 31.2681 25.8141C30.8706 26.2516 30.3275 26.5528 29.7469 26.6616C29.2938 26.7366 28.8331 26.7116 28.3762 26.7153C20.0425 26.7153 11.7094 26.7153 3.37562 26.7153C2.88187 26.7153 2.3725 26.7347 1.89937 26.5691C1.08312 26.3072 0.414375 25.6366 0.14875 24.8222C0.058125 24.5609 0.02625 24.2841 0 24.0103V8.45781C0.02625 8.18406 0.05875 7.90843 0.1475 7.64718C0.411875 6.83093 1.08125 6.15968 1.89875 5.89656ZM1.02875 7.63281C0.8725 7.93218 0.815 8.27156 0.815 8.60656C0.813125 9.15531 0.815 9.70343 0.814375 10.2522C10.9381 10.2522 21.0619 10.2522 31.1856 10.2516C31.1838 9.68218 31.1894 9.11218 31.1831 8.54218C31.18 8.19906 31.1019 7.85343 30.9287 7.55531C30.5869 6.93531 29.89 6.55093 29.185 6.56468C20.3931 6.56218 11.6012 6.56156 2.80938 6.56468C2.07562 6.55031 1.355 6.97281 1.02875 7.63281ZM0.814375 12.2197C0.815625 16.1203 0.81125 20.0209 0.816875 23.9209C0.80625 24.6122 1.18375 25.2928 1.78688 25.6341C2.13063 25.8422 2.53938 25.9078 2.93625 25.9034C11.6862 25.9009 20.4363 25.9053 29.1863 25.9009C29.9375 25.9178 30.675 25.4747 30.9919 24.7903C31.2525 24.2691 31.1725 23.6728 31.1856 23.1109C31.1856 19.4803 31.1856 15.8503 31.185 12.2197C21.0619 12.2191 10.9381 12.2191 0.814375 12.2197Z"
          fill="#1D2129"
        />
        <path
          d="M4.82812 16.7592C6.13562 16.7292 7.44437 16.7548 8.75187 16.7467C9.14749 16.7554 9.54437 16.7248 9.93812 16.7679C10.6319 16.8517 11.2162 17.4517 11.2856 18.146C11.3219 18.5898 11.295 19.0354 11.3031 19.4798C11.2912 19.9479 11.3606 20.4454 11.1475 20.8823C10.8944 21.4373 10.3 21.8048 9.69062 21.7917C8.18999 21.7967 6.68874 21.791 5.18812 21.7948C4.93374 21.7942 4.67374 21.7873 4.43374 21.6948C3.93124 21.5167 3.53562 21.066 3.43062 20.5429C3.36374 20.1929 3.39874 19.8342 3.39062 19.4804C3.39812 19.0385 3.37312 18.5954 3.40562 18.1542C3.47062 17.4285 4.10312 16.8117 4.82812 16.7592ZM4.83812 17.5754C4.48124 17.6429 4.19249 17.986 4.20437 18.3529C4.20187 18.9592 4.20249 19.566 4.20374 20.1723C4.18124 20.5992 4.56937 20.9954 4.99624 20.9798C6.56062 20.9823 8.12499 20.9817 9.68874 20.9798C10.1194 21.0004 10.5106 20.6017 10.4875 20.1717C10.4887 19.566 10.49 18.9598 10.4869 18.3542C10.5031 17.9304 10.1156 17.5417 9.69124 17.5623C8.23124 17.5592 6.77124 17.5629 5.31124 17.5604C5.15374 17.561 4.99499 17.5535 4.83812 17.5754Z"
          fill="#1D2129"
        />
        <path
          d="M15.0199 16.7551C19.4093 16.7339 23.7999 16.7526 28.1899 16.7457C28.4199 16.7176 28.6812 16.8582 28.7012 17.107C28.7418 17.3626 28.4981 17.587 28.2481 17.5595C23.8737 17.562 19.4993 17.5614 15.1256 17.5595C14.9418 17.5726 14.7481 17.4745 14.6874 17.2939C14.5918 17.0601 14.7693 16.7745 15.0199 16.7551Z"
          fill="#1D2129"
        />
        <path
          d="M14.9043 19.958C15.0143 19.9168 15.1356 19.9237 15.2518 19.9199C17.2943 19.9249 19.3368 19.918 21.3793 19.9237C21.6425 19.8968 21.8875 20.1574 21.815 20.4199C21.77 20.648 21.525 20.7599 21.31 20.7349C19.225 20.7299 17.1393 20.7424 15.055 20.7287C14.6387 20.7449 14.5162 20.103 14.9043 19.958Z"
          fill="#1D2129"
        />
      </g>
      <defs>
        <clipPath id="clip0_1027_66755">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(0 0.232422)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Card;
