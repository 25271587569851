import React from 'react';
import Header from './components/Header';
import CustomerInfoPageStyled from './styles';
import Main from './components/Main';
type ICustomerInfoPageProps = any;
const CustomerInfoPage: React.FC<ICustomerInfoPageProps> = () => {
  return (
    <CustomerInfoPageStyled>
      <Header />
      <Main />
    </CustomerInfoPageStyled>
  );
};

export default CustomerInfoPage;
