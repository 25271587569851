import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { ISignUpForm } from './types/api';
import { ICheckedInRes, ISignUpRes, IStaff, IWalkInData } from './types/demoData';
import shopSelectors from 'services/shop/selectors';

type MyState = RootState['checkIn'];
const getCurrentState = (state: RootState): MyState => state['checkIn'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getCheckedInData = () => selector('checkedIn') as ICheckedInRes;

const getPhoneNumber = () => selector('phoneNumber') as string;

const getSignUpFormValues = () => selector('formSignUp') as ISignUpForm;

const getStaffList = () => selector('staffList') as IStaff[];

const getStaffListCondition = () => selector('staffListCondition') as IStaff[];

const getSignUpData = () => selector('signedUp') as ISignUpRes;

const getWalkInData = () => selector('walkIn') as IWalkInData;

const getConfirmedCheckIn = () => selector('isConfirmWalkIn') as boolean;

const getActiveCategory = () => selector('activeCategory') as MyState['activeCategory'];

const getSelectedServices = () => selector('selectedServices') as MyState['selectedServices'];

const getSelectedStaff = () => selector('selectedStaff') as MyState['selectedStaff'];

const getStaffsAvailable = () => {
  const staffsAvailable = shopSelectors.data.staffsAvailable();
  return staffsAvailable;
};

const getAppointmentDetail = () => selector('appointmentDetail') as MyState['appointmentDetail'];

const checkInSelectors = {
  getCheckedInData,
  getPhoneNumber,
  getSignUpFormValues,
  getStaffList,
  getStaffListCondition,
  getSignUpData,
  getWalkInData,
  getConfirmedCheckIn,
  getActiveCategory,
  getSelectedServices,
  getSelectedStaff,
  getAppointmentDetail,
  getStaffsAvailable,
};
export default checkInSelectors;
