import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { ICreateCustomerBody } from './types/customer';

const get = {
  categories: createAsyncAction(PREFIX_ACTIONS + 'getCategories'),
  addOnServices: createAsyncAction(PREFIX_ACTIONS + 'getAddOnServices'),
  staffs: createAsyncAction(PREFIX_ACTIONS + 'getStaffList'),
  staffsAvailable: createAsyncAction(PREFIX_ACTIONS + 'staffsAvailable'),
  customers: createAsyncAction(PREFIX_ACTIONS + 'getCustomerList'),
  recentCustomers: createAsyncAction(PREFIX_ACTIONS + 'getRecentCustomerList'),
  shopInfo: createAsyncAction<string>(PREFIX_ACTIONS + 'getShopInfo'),
  specialDiscount: createAsyncAction(PREFIX_ACTIONS + 'getSpecialDiscount'),
  coupons: createAsyncAction(PREFIX_ACTIONS + 'getListCoupon'),
  discountSetting: createAsyncAction(PREFIX_ACTIONS + 'getDiscountSetting'),
  otherPaymentMethod: createAsyncAction(PREFIX_ACTIONS + 'getOtherPaymentMethod'),
  allSetting: createAsyncAction(PREFIX_ACTIONS + 'getShopAllSetting'),
  productCategories: createAsyncAction(PREFIX_ACTIONS + 'getProductCategories'),
  productsVIP: createAsyncAction(PREFIX_ACTIONS + 'getProductsVIP'),
  totalStation: createAsyncAction<string>(PREFIX_ACTIONS + 'getTotalStation'),
  scheduleBooking: createAsyncAction(PREFIX_ACTIONS + 'getShopScheduleBooking'),
  couponPrint: createAsyncAction<string>(PREFIX_ACTIONS + 'getCouponPrint'),
  feeCreditCard: createAsyncAction(PREFIX_ACTIONS + 'getFeeCreditCard'),
  taxConfig: createAsyncAction(PREFIX_ACTIONS + 'getTaxConfig'),
};

const initShopData = createAction(PREFIX_ACTIONS + 'initShopData');
const getNewCategories = createAsyncAction(PREFIX_ACTIONS + 'getNewCategories');
const getNewAddOnList = createAsyncAction(PREFIX_ACTIONS + 'getNewAddOnList');

const doInit = {
  checkIn: createAction(PREFIX_ACTIONS + 'initCheckInShopData'),
  editTicket: createAction(PREFIX_ACTIONS + 'initEditTicketShopData'),
};

const doAdd = {
  customer: createAsyncAction<ICreateCustomerBody>(PREFIX_ACTIONS + 'doAddNewCustomer'),
};

const shopActions = {
  get,
  initShopData,
  getNewCategories,
  getNewAddOnList,
  doInit,
  doAdd,
};

export default shopActions;
