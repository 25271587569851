import CardBox from 'features/cashier/components/CardBox';
type Props = {
  active?: boolean;
  disabled?: boolean;
  onRedeem: () => void;
};
function CreditCard({ active, disabled, onRedeem }: Props) {
  // const shopSetting = shopSelectors.data.allSetting();
  return (
    <CardBox
      icon="cash"
      type={active ? 'ACTIVE' : 'DEFAUL'}
      disabled={disabled}
      onClick={onRedeem}
    >
      Credit Card
    </CardBox>
  );
}

export default CreditCard;
