import React from 'react';
import styled from 'styled-components';
import settingServiceSelectors from 'features/settingService/services/selectors';
import RightAddOnForm from './RightAddOnForm';
import RightCateForm from './RightCateForm';
import { FormInstance } from 'antd';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';

type IRightDetailsProps = {
  cateFormRef: React.MutableRefObject<FormInstance<ICategoryItemData> | null>
  addOnFormRef: React.MutableRefObject<FormInstance<IServiceItemData> | null>
};
const RightDetails: React.FC<IRightDetailsProps> = ({cateFormRef, addOnFormRef}) => {
  const isShowCate = settingServiceSelectors.getCateSelected();
  const isShowAddOn = settingServiceSelectors.getAddOnServiceSelected();

  return (
    <RightDetailsStyled>
      {!!isShowCate && <RightCateForm cateFormRef={cateFormRef} />}
      {!!isShowAddOn && <RightAddOnForm addOnFormRef={addOnFormRef} />}
    </RightDetailsStyled>
  );
};

export default RightDetails;
const RightDetailsStyled = styled.div`
  width: 100%;
`;
