import React, { useEffect } from 'react';
import { ColumnsType } from 'antd/es/table';
import Table from 'components/Table';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { ICoupon } from 'features/customer/services/types/coupon';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';
type ICouponsTableProps = {};

const columns: ColumnsType<ICoupon> = [
  {
    title: 'Date Used',
    dataIndex: 'createdDate',
    align: 'center',
    render(createdDate) {
      return (
        <Text variant="BODY_1" color="text_3">
          {createdDate
            ? momentTimezone(createdDate).format('MM/DD/YYYY hh:mm a')
            : '--'}
        </Text>
      );
    },
  },
  {
    title: 'Code',
    dataIndex: 'couponCode',
    align: 'center',
    render(couponCode) {
      return (
        <Text variant="BODY_1" color="text_3">
          {couponCode || '--'}
        </Text>
      );
    },
  },
  {
    title: 'Coupon Name',
    dataIndex: 'couponName',
    align: 'center',
    render(couponName) {
      return (
        <Text variant="BODY_1" color="text_3">
          {couponName || '--'}
        </Text>
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'discount',
    align: 'center',
    render(discount) {
      return (
        <Text variant="BODY_1" color="text_3">
          {discount ? formatCurrency(discount) : '--'}
        </Text>
      );
    },
  },
  {
    title: 'Ticket Number',
    dataIndex: 'ticketNumber',
    align: 'center',
    render(ticketNumber) {
      return (
        <Text variant="BODY_1" color="text_3">
          {ticketNumber || '--'}
        </Text>
      );
    },
  },
];

const CouponTable: React.FC<ICouponsTableProps> = () => {
  const dispatch = useAppDispatch();
  const customerDetails = customerSelectors.getCustomerDetails();

  const data = customerSelectors.couponTableData.data();
  const loading = customerSelectors.couponTableData.loading();
  const params = customerSelectors.couponTableData.params();
  const totalElement = customerSelectors.couponTableData.totalElement();

  const handlePageChange = async (page?: number) => {
    const params = {
      page: page,
    };
    dispatch(customerActions.setCouponsParams(params));
  };

  useEffect(() => {
    if (customerDetails?.id) {
      const params = {
        customerId: customerDetails?.id || '',
      };
      dispatch(customerActions.setCouponsParams(params));
    }
  }, [customerDetails?.id]);

  return (
    <CouponsTableStyled>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={'couponCode'}
        pagination={{
          total: totalElement ?? 0,
          pageSize: params.size,
          current: params.page,
          showSizeChanger: false,
        }}
        handleChangePage={handlePageChange}
      />
    </CouponsTableStyled>
  );
};

export default CouponTable;
const CouponsTableStyled = styled.div``;
