import ModalTipMethodSystem from '../../PrintPage/components/Modals/BtnTipMethodSysModal';

const CustomerSideEffectComponents = () => {
  return (
    <>
      <ModalTipMethodSystem />
    </>
  );
};

export default CustomerSideEffectComponents;
