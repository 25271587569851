import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import Text from 'components/Text';
import { IBillWaitingTicketResItem } from 'features/user/services/types/ticket';
import { first } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
type ITicketItemProps = {
  data: IBillWaitingTicketResItem;
  viewOnly?: boolean;
};
const TicketItem: React.FC<ITicketItemProps> = ({ data, viewOnly }) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (viewOnly) return;
    if (!data) return;
    navigate(`/store/${storage.shop_id.get()}/ticket/edit/${data.billId}`);
  };

  const StaffAvatar = () => {
    const staff = first(data.staffs);
    if (!staff) return <Avatar size="small" icon={<UserOutlined />} />;

    return (
      <Avatar.Group maxCount={2}>
        {data.staffs?.map(o => <Avatar key={o.staffId} size="default" src={o.avatar} >{o.staffName?.[0] || 'A'}</Avatar>)}
      </Avatar.Group>
    );
  };

  if (!data) return null;
  return (
    <TicketItemStyled onClick={onClick}>
      <div className="ticket-header">
        <StaffAvatar />
        <TicketNumber variant='BODY_2' color='text_3' style={{ fontSize: '1rem' }}>#{data.ticketNumber}</TicketNumber>
      </div>
      <div className="ticket-body">
        <Text variant='BODY_2' color='text_3' className='customer-name'>{data.customerName || 'Unknown'}</Text>
        <Text variant='CAPTION_3' color='text_2' className='staff-name'>Staff: {data.staffs?.map(o => o.staffName).join(', ')}</Text>
        <Text variant='BODY_2' color='text_3' className='ticket-price'>{formatCurrency(data.totalPrice)}</Text>
      </div>
    </TicketItemStyled>
  );
};

export default TicketItem;
type TicketItemStyledProps = {}
const TicketItemStyled = styled.button<TicketItemStyledProps>`
  height:100%;
  width:100%;
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-1, #F6F7FC);
  padding: 0.5rem;
  .ticket-header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-bottom: 0.5rem;
  }
  .ticket-body {
    text-align: center;
  }

`;
const TicketNumber = styled(Text)`
  display: inline-flex;
  padding: 5px;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: var(--fill-fill-4, #1D2129);
  color: white;
`;
