import { get, unionBy, uniqueId } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { PATH_LOADING } from './constants';
import { ICategoryItemData } from './types/categories';
import { ILoyaltySetting } from './types/setting';

export type MyState = RootState['shop'];

export type IShopState = MyState;

export const getCurrentState = (state: RootState): MyState => state['shop'];

export const getPureStoreData = (root: RootState) => {
  const state = getCurrentState(root);
  return ({
    categories: state?.categories ?? [],
    addonServices: state?.addonServices ?? [],
    staffs: state?.staffs ?? [],
    customers: state?.customers ?? [],
  });
};

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getCategories = () => selector('categories') as MyState['categories'];

const data = {
  categories: () => selector('categories') as MyState['categories'],
  addonServices: () => selector('addonServices') as MyState['addonServices'],
  staffs: () => selector('staffs') as MyState['staffs'],
  staffsAvailable: () => selector('staffsAvailable') as MyState['staffsAvailable'],
  customers: () => selector('customers') as MyState['customers'],
  recentCustomers: () => selector('recentCustomers') as MyState['recentCustomers'],
  shopInfo: () => selector('shopInfo') as MyState['shopInfo'],
  specialDiscounts: () => selector('specialDiscounts') as MyState['specialDiscounts'],
  coupons: () => selector('coupons') as MyState['coupons'],
  discountSetting: () => selector('discountSetting') as MyState['discountSetting'],
  orderPaymentMethods: () => selector('orderPaymentMethods') as MyState['orderPaymentMethods'],
  allSetting: () => selector('allSetting') as MyState['allSetting'],
  getLoyaltySetting: () => selector('allSetting' + '.' + 'loyaltySetting', null) as ILoyaltySetting | null,

  categoriesIncludeAddon: () => {
    const categories = data.categories();
    const addonServices = data.addonServices();
    const categoryAddon: ICategoryItemData = {
      categoryName: 'Add-on',
      id: uniqueId(),
      services: addonServices,
    };
    return unionBy(categories, [categoryAddon], 'id') ?? [];
  },

  productCategories: () => selector('productCategories') as MyState['productCategories'],
  productsVIP: () => selector('productsVIP') as MyState['productsVIP'],
  totalStation: () => selector('totalStation') as MyState['totalStation'],
  scheduleBooking: () => selector('scheduleBooking') as MyState['scheduleBooking'],
  couponPrint: () => selector('couponPrint') as MyState['couponPrint'],
  feeCreditCard: () => selector('feeCreditCard') as MyState['feeCreditCard'],
  taxConfig: () => selector('taxConfig') as MyState['taxConfig'],
};

const loading = {
  categories: () => uiSelector.getLoading(PATH_LOADING.getCategories) as boolean,
  productCategories: () => uiSelector.getLoading(PATH_LOADING.getProductCategories) as boolean,
  addonServices: () => uiSelector.getLoading(PATH_LOADING.getAddOnList) as boolean,
  staffs: () => uiSelector.getLoading(PATH_LOADING.getStaffList) as boolean,
  customers: () => uiSelector.getLoading(PATH_LOADING.getCustomerList) as boolean,
  productsVIP: () => uiSelector.getLoading(PATH_LOADING.getProductsVIP) as boolean,
};


const shopSelectors = {
  data,
  loading,
  getCategories,
};
export default shopSelectors;
