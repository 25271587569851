import { Col, Row } from 'antd';
import ActionBtn from 'components/ActionBtn';
import NumberKeyBoardButton from 'components/NumberKeyBoardButton';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ButtonCard from './ButtonCard';
import ButtonCash from './ButtonCash';
import ButtonCheckOthers from './ButtonCheckOthers';
import ButtonDiscountTicket from './ButtonDiscountTicket';
import ButtonGiftCard from './ButtonGiftCard';
import ButtonLoyaltyPoint from './ButtonLoyaltyPoint';
import ButtonSave from './ButtonSave';
import ButtonCalculator from './ButtonCalculator';
import ButtonMultiplePayment from './ButtonMultiPayment';

type IComponentProps = {};
const TickerFooter: React.FC<IComponentProps> = () => {
  const dispatch = useAppDispatch();
  const detailPayment = cashierSelectors.getPaymentInfo();

  const onChange = async (val: number) => {
    dispatch(cashierActions.setTip(val));
    return true;
  };

  const clearTicket = () => {
    dispatch(cashierActions.clearTicket());
  };

  return (
    <ComponentStyled>
      <ButtonCalculator />
      <ActionBtn
        label='Clear Ticket'
        ntype="LIGHT_BLUE"
        icon='backSpace'
        small
        onClick={clearTicket}
      />
      <ButtonDiscountTicket />
      <NumberKeyBoardButton
        btnTitle="Tip"
        ntype="LIGHT_BLUE"
        icon="tip"
        modalTitle="TIP"
        onSubmit={onChange}
        defaultValue={detailPayment.tip}
        verticalButton
      />
      <ButtonSave />
      <Col flex={'auto'} />
      <Row align={'middle'} justify={'end'} className='payment-actions'>
        <ButtonCard />
        <ButtonCash />
        <ButtonMultiplePayment />
        <ButtonCheckOthers />
        <ButtonGiftCard />
        <ButtonLoyaltyPoint />
      </Row>
    </ComponentStyled>
  );
};

export default TickerFooter;
const ComponentStyled = styled.div`
  height: 6.375rem;
  background-color: #fff;
  background: #FFF;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  .payment-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
`;
