import { Checkbox, Select, SelectProps } from 'antd';
import React, { useState } from 'react';
import { styled } from 'styled-components';
type MultipleSelectProps = SelectProps & {
  value?: string[];
  options?: { value: any, label: string }[];
  onChange?: (val: string[]) => void;
  width?: string
  height?: string
  placeholder?: string
};
const MultipleSelect = ({ value = [], options = [], onChange = () => undefined, width='15rem', height, placeholder='Select type ...' }: MultipleSelectProps) => {
  const [dirty, setDirty] = useState(false);
  const handleChange = (value: string[]) => {
    onChange(value);
    setDirty(true);
  };

  const selectProps: SelectProps = {
    mode: 'multiple',
    style: { width: width, height },
    value: value,
    options: options.map(o => ({
      ...o,
      label: (
        <Checkbox
          onClick={(e) => {
            if (dirty) e.stopPropagation();
            setDirty(false);
          }}
          checked={value.includes(o.value)}
        >
          {o.label}
        </Checkbox>
      )
    })),
    onChange: (val: string[]) => handleChange(val),
    onMouseDown: () => setDirty(false),
    tagRender: (props: any) => {
      return value.length > 1 ? <></> : <div className='tag-label'>{props.label}</div>;
    },
    placeholder: placeholder,
    maxTagCount: 1,
    maxTagPlaceholder: () => value.length === options.length ? 'All ' + ('types') : `${value.length} ${'types'}`,
    size: 'small',
    showSearch: false,
  };
  return (
    // @ts-ignore
    <TypeItemPickerStyled {...selectProps} />
  );
};

export default MultipleSelect;
const TypeItemPickerStyled = styled(Select) <MultipleSelectProps>`
  &.ant-select-multiple {
    .ant-select-selection-item {
      background: 0;
    }
    .tag-label {
      .ant-checkbox {
        display:none;
      }
    }
  }
`;
