import { Col, Row } from 'antd';
import Text from 'components/Text';
import { styled } from 'styled-components';
import set from 'lodash/set';
import { FC, SVGProps as RNSvgProps } from 'react';
import back from './back';
import clockIn from './clockIn';
import removeAll from './removeAll';
import backSpace from './backSpace';
import arrowForwardOutline from './arrowForwardOutline';
import ticket, { TicketActive as ticketActive } from './ticket';
import report, { ReportActive as reportActive } from './report';
import category, { CategoryActive as categoryActive } from './category';
import userSquare, { UserSquareActive as userSquareActive } from './userSquare';
import calendar, { CalendarActive as calendarActive } from './calendar';
import calculator, { CalculatorActive as calculatorActive } from './calculator';
import turn, { TurnActive as turnActive } from './turn';
import settingLight from './settingLight';
import tick from './tick';
import clockOut from './clockOut';
import next from './next';
import giftCard, { GiftCardDefault as giftCardDefault } from './giftCard';
import kingVip from './kingVip';
import product from './product';
import addPlus from './add';
import print from './print';
import merge from './merge';
import split from './split';
import addNewPlus from './addNewPlus';
import getIn from './getIn';
import QR from './QR';
import change from './change';
import circleClose from './circleClose';
import discount from './discount';
import rightDoubleArrow from './rightDoubleArrow';
import search from './search';
import datePicker from './datePicker';
import walkInClock from './walkInClock';
import totalIcon from './totalIcon';
import tip from './tip';
import avatarUnset from './avatarUnset';
import employee, { EmployeeActive as employeeActive } from './employee';
import Switch from './switch';
import loyaltyPoint from './loyaltyPoint';
import epay from './epay';
import cash from './cash';
import card from './card';
import logo from './logo';
import logoSmall from './logoSmall';
import email from './email';
import sendSms from './sendSms';
import checkIn from './checkIn';
import close from './close';
import time from './time';
import addAppointment, { minusAppointment } from './addAppointment';
import exclamationCircle from './exclamationCircle';
import arrowRight from './arrowRight';
import arrowLeft from './arrowLeft';
import edit from './edit';
import nextCarousel from './nextCarousel';
import backCarousel from './backCarousel';
import upArrow from './upArrow';
import downArrow from './downArrow';
import crossSale from './crossSale';
import moneyExchange from './moneyExchange';
import closeTicket from './closeTicket';
import walkIn from './walkinIcon';
import appointments from './appointments';
import deleteIcon from './deleteIcon';
import windows, { WindowstActive as windowsActive } from './windows';
import lineList, { LineListActive as lineListActive } from './lineList';
import voidList from './voidList';
import refundMoney from './refundMoney';
import changeCustomer from './changeCustomer';
import salary from './salary';
import filter from './filter';
import crown from './crown';
import customerInfo, {
  CustomerInfoActive as customerInfoActive,
} from './customerInfo';
import cashDrawer, {
  CashDrawerActive as cashDrawerActive,
} from './cashDrawerActive';
import closeModal from './closeModal';
import save from './save';
import listStatus from './listStatus';
import item from './item';
import turnFinish from './turnFinish';
import turnPending from './turnPending';
import ticketReport from './ticketReport';
import glass from './glass';
import cardBtn from './cardBtn';
import cashBtn from './cashBtn';
import giftCardBtn from './giftCardBtn';
import loyaltyPointBtn from './loyaltyPointBtn';
import calculatorBtn from './calculatorBtn';
import ellipsis from './ellipsis';
import trash from './trash';
import editPen from './editPen';
import camera from './camera';
import imageThumb from './imageThumb';
import twoLineOpen from './twoLineOpen';
import checkMask from './checkMask';
import cardServiceIcon, { cardServiceIconActive } from './cardServiceIcon';
import cardGiftCard, { cardGiftCardActive } from './cardGiftCard';
import cardVIP from './cardVIP';
import cardProduct, { cardProductActive } from './cardProduct';
import adjustTipAll from './adjustTipAll';
import qrCode from './qrCode';
import logoImageUpload from './logoImageUpload';
import turnTicketSetting from './turnTicketSetting';
import turnServiceSetting from './turnServiceSetting';
import eclipseAddStaff from './eclipseAddStaff';
import outlineAddStaff from './outlineAddStaff';
import multiPay from './multiPay';
import ePayIcon from './ePayIcon';
import clearTurn from './clearTurn';
import refresh from './refresh';
import deleteIconCashier from './deleteIconCashier';

export type SVGprops = RNSvgProps<any>;
// eslint-disable-next-line
const getIcon = (IconComponent: FC<SVGprops>) => (props: SVGprops) =>
  <IconComponent {...props} />;

const data = {
  logo,
  logoSmall,
  back,
  clockIn,
  arrowForwardOutline,
  category,
  categoryActive,
  userSquare,
  userSquareActive,
  calendar,
  calendarActive,
  report,
  reportActive,
  ticket,
  ticketActive,
  calculator,
  calculatorActive,
  turn,
  turnActive,
  settingLight,
  tick,
  clockOut,
  next,
  giftCard,
  giftCardDefault,
  kingVip,
  product,
  addPlus,
  print,
  merge,
  split,
  addNewPlus,
  getIn,
  QR,
  removeAll,
  backSpace,
  change,
  circleClose,
  discount,
  rightDoubleArrow,
  search,
  datePicker,
  walkInClock,
  totalIcon,
  tip,
  avatarUnset,
  employee,
  employeeActive,
  switch: Switch,
  loyaltyPoint,
  epay,
  cash,
  card,
  email,
  sendSms,
  checkIn,
  close,
  time,
  addAppointment,
  exclamationCircle,
  arrowRight,
  arrowLeft,
  edit,
  nextCarousel,
  backCarousel,
  upArrow,
  downArrow,
  crossSale,
  moneyExchange,
  closeTicket,
  walkIn,
  appointments,
  deleteIcon,
  windows,
  windowsActive,
  lineList,
  lineListActive,
  voidList,
  refundMoney,
  changeCustomer,
  salary,
  customerInfo,
  customerInfoActive,
  cashDrawer,
  cashDrawerActive,
  closeModal,
  save,
  filter,
  crown,
  listStatus,
  item,
  turnFinish,
  turnPending,
  ticketReport,
  glass,
  cardBtn,
  cashBtn,
  giftCardBtn,
  loyaltyPointBtn,
  calculatorBtn,
  ellipsis,
  trash,
  editPen,
  camera,
  imageThumb,
  twoLineOpen,
  checkMask,
  cardServiceIcon,
  cardServiceIconActive,
  cardGiftCard,
  cardGiftCardActive,
  cardVIP,
  cardProduct,
  cardProductActive,
  adjustTipAll,
  qrCode,
  logoImageUpload,
  turnTicketSetting,
  turnServiceSetting,
  eclipseAddStaff,
  outlineAddStaff,
  minusAppointment,
  multiPay,
  ePayIcon,
  clearTurn,
  refresh,
  deleteIconCashier,
};
type IResult = {
  [key in keyof typeof data]: FC;
};
const mappingIcons = () => {
  const result = {};
  for (const [key, value] of Object.entries(data)) {
    set(result, [key], getIcon(value));
  }
  return result as IResult;
};

const icons = mappingIcons();
export type typeIcon = keyof typeof icons;

interface Props extends SVGprops {
  type: typeIcon;
}
const Icon: FC<Props> = ({ type, ...props }) => {
  return icons[type](props);
};

export default Icon;

export const IconStoryBoard = () => {
  return (
    <>
      <Text variant="H3">Icons</Text>
      <IconStoryBoardStyled>
        <Row gutter={[25, 25]}>
          {Object.keys(data).map((key: any) => (
            <Col key={key} span={6}>
              <Icon type={key} />
              <Text variant="H9">{key}</Text>
            </Col>
          ))}
        </Row>
      </IconStoryBoardStyled>
    </>
  );
};
const IconStoryBoardStyled = styled.div`
  padding: 2%;
  margin: 2%;
  border: 2px solid black;
  background: rgba(0, 0, 0, 0.125);
`;
