const AdjustTipAll = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.49136 1.52675C9.78011 1.508 13.0698 1.52394 16.359 1.51879C16.8892 1.52629 17.4226 1.493 17.9495 1.56847C19.2076 1.73582 20.3907 2.38644 21.2129 3.35207C22.021 4.28629 22.4795 5.51347 22.48 6.7491C22.4814 9.98441 22.48 13.2197 22.4804 16.4546C22.4767 17.0185 22.5057 17.5871 22.3998 18.1444C22.1528 19.5849 21.265 20.8964 20.027 21.6708C19.1893 22.2038 18.197 22.4874 17.2046 22.4789C16.8067 22.4747 16.4575 22.1049 16.4814 21.7064C16.4832 21.3066 16.8512 20.9616 17.2501 20.9803C18.0723 20.9775 18.894 20.7033 19.5418 20.1947C20.2646 19.6374 20.7657 18.803 20.9204 17.9039C20.9973 17.5027 20.9785 17.093 20.9804 16.6871C20.9804 13.546 20.9804 10.4044 20.9804 7.26332C20.9785 6.90191 20.9945 6.53816 20.9345 6.18004C20.8014 5.28144 20.3232 4.43957 19.6192 3.86535C18.8865 3.25175 17.9148 2.97097 16.967 3.01879C13.5606 3.01925 10.1542 3.01785 6.74824 3.01925C5.79199 3.01832 4.84136 3.398 4.15558 4.0655C3.42058 4.76722 3.00574 5.78113 3.01886 6.79597C3.0212 10.3257 3.01417 13.8558 3.02261 17.386C3.05261 18.2513 3.39245 19.1025 3.97417 19.7452C4.55214 20.393 5.36589 20.8224 6.22558 20.9414C6.56917 20.9935 6.91745 20.9794 7.26386 20.9813C9.59214 20.9813 11.9204 20.9808 14.2487 20.9818C14.5323 20.9752 14.8023 21.1524 14.9237 21.406C15.0671 21.6863 15.0001 22.0528 14.7704 22.2675C14.6228 22.4157 14.4118 22.4883 14.2042 22.4813C11.8131 22.4813 9.42199 22.4813 7.03089 22.4813C6.62167 22.486 6.21058 22.4691 5.80745 22.3913C4.45464 22.1447 3.21855 21.3361 2.44277 20.2013C1.83714 19.3275 1.51136 18.2653 1.51886 17.2022C1.51886 13.718 1.51792 10.2333 1.51933 6.74863C1.51745 5.42347 2.04808 4.1091 2.96542 3.15379C3.87667 2.18863 5.1648 1.59004 6.49136 1.52675Z"
        fill="white"
      />
      <path
        d="M6.68406 6.68536C7.28922 6.36942 8.02187 6.30802 8.67062 6.52177C9.48015 6.77817 10.1289 7.45833 10.375 8.26786C12.9166 8.27021 15.4586 8.26739 18.0002 8.26927C18.3747 8.26036 18.7164 8.57677 18.7459 8.94849C18.782 9.26817 18.5823 9.58974 18.2875 9.71114C18.123 9.7838 17.9397 9.76786 17.7653 9.7688C15.302 9.7688 12.8387 9.76833 10.3759 9.76927C10.1678 10.4424 9.68922 11.0293 9.06109 11.3527C8.41844 11.6916 7.63 11.7343 6.95265 11.4722C6.32594 11.2365 5.79812 10.7508 5.50937 10.1471C5.23844 9.58974 5.1789 8.93442 5.3425 8.33677C5.53187 7.63083 6.03297 7.01677 6.68406 6.68536ZM7.51703 7.95005C7.16969 8.06067 6.8875 8.35411 6.79281 8.70661C6.66578 9.14161 6.82328 9.6488 7.19312 9.91739C7.64312 10.2708 8.36031 10.2 8.72687 9.75849C9.12812 9.31177 9.07656 8.54864 8.61297 8.16474C8.31859 7.90692 7.88687 7.82958 7.51703 7.95005Z"
        fill="white"
      />
      <path
        d="M14.9054 12.7023C15.5148 12.3695 16.261 12.3062 16.9206 12.5213C17.6026 12.737 18.1824 13.247 18.4899 13.892C18.7595 14.4479 18.8199 15.1018 18.6563 15.6976C18.4698 16.3965 17.9795 17.0049 17.3396 17.3401C16.7002 17.6813 15.9142 17.7395 15.2354 17.483C14.4681 17.206 13.8601 16.5474 13.6229 15.7693C11.0823 15.7679 8.54165 15.7698 6.00103 15.7684C5.68369 15.7726 5.37947 15.5499 5.28478 15.2476C5.12541 14.8051 5.48025 14.2838 5.94994 14.2716C8.50791 14.2641 11.0663 14.2726 13.6243 14.2674C13.8259 13.6051 14.2937 13.0285 14.9054 12.7023ZM15.7679 13.9501C15.6067 14.0021 15.4571 14.0916 15.3362 14.2107C14.8787 14.6363 14.8918 15.4463 15.3662 15.8546C15.8082 16.2732 16.5887 16.2282 16.9773 15.758C17.3771 15.3118 17.326 14.5505 16.8643 14.1666C16.5704 13.9079 16.1382 13.8291 15.7679 13.9501Z"
        fill="white"
      />
    </svg>
  );
};

export default AdjustTipAll;
