import ActionBtn from 'components/ActionBtn';
import cashierActions from 'features/cashier/services/actions';
import { ITEM_HANDLE_MODE } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';

const DiscountItemButton = () => {
  const dispatch = useAppDispatch();
  const active = cashierSelectors.getItemModeVisible(ITEM_HANDLE_MODE.DISCOUNT_ITEM);
  return (
    <ActionBtn
      ntype={active ? 'PRIMARY' : 'SECONDARY'}
      icon="discount"
      label='Disc. Item'
      onClick={() => dispatch(cashierActions.toggleVisibleDiscountItemMode())}
    />
  );
};

export default DiscountItemButton;