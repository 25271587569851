import Box from 'components/Box';
import Form from 'components/Form';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import CardBox from 'features/cashier/components/CardBox';
import { IStateDetailPayment } from 'features/payment/services/types/payment';
import { useEffect, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import multiplePaymentActions from '../../../services/actions';
import multiplePaymentSelectors from '../../../services/selectors';
import CountingFooter from './CountingFooter';
type Props = {
  active?: boolean;
  amountKB?: number;
  disabled?: boolean;
  onRedeem: (_detailPayment: IStateDetailPayment) => Promise<void>;
};
const LoyaltyPoint = ({ active = false, disabled, amountKB }: Props) => {
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const [amount, setAmount] = useState<number | null>(null);

  const dispatch = useAppDispatch();
  const detail = multiplePaymentSelectors.getDetail();
  const customer = multiplePaymentSelectors.getCustomerInfo();

  const visible = multiplePaymentSelectors.getVisibleModalPoint();
  const setVisible = (value: boolean) => {
    dispatch(multiplePaymentActions.setVisibleModalPoint(value));
  };
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();
  const customerInfo = multiplePaymentSelectors.getCustomerInfo();

  const exchangeDollar = (point: number) => {
    if (!loyaltySetting) return 0;

    return +(point) / loyaltySetting?.oneDollarToPoint;
  };


  useEffect(() => {
    if (!visible) return;
    const _amountKB = amountKB || 0;
    const availablePointEx = exchangeDollar(+(customerInfo?.point || ''));
    const amountVal = availablePointEx - _amountKB < 0 ? availablePointEx : _amountKB;

    setTimeout(() => currencyRef.current?.setValue(amountVal?.toFixed(2) || ''), 100);
  }, [visible, detail]);

  const handleOpen = () => {
    setVisible(true);
  };

  const _amount = amount || 0;
  const redeemPoint = loyaltySetting ? (+_amount) * (loyaltySetting?.oneDollarToPoint || 0) : 0;

  const AvailablePoint = () => {
    return (
      <>
        {customerInfo?.point} point = {formatCurrency(exchangeDollar(+(customerInfo?.point || '')))}
      </>
    );
  };


  const BalancePoint = () => {
    if (!loyaltySetting) return <>{('0 point = $0')}</>;
    const balancePoint = +(customerInfo?.point || '') - redeemPoint;

    if (balancePoint <= 0) return <>0 point = $0</>;

    return (
      <>
        {balancePoint.toFixed(2)} point = {formatCurrency(exchangeDollar(+(balancePoint || '')))}
      </>
    );
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    onClose();
    const loyaltyPointVal = {
      amount: _amount || 0,
      point: redeemPoint,
    };

    dispatch(multiplePaymentActions.setLoyaltyPointPayment(loyaltyPointVal));

    /**
     * AUTO COMPLETE PAYMENT
    */
    // if ((remaining - _amount) <= 0) {
    //   onRedeem({
    //     ...detailPayment,
    //     [PAYMENT_TYPE.LOYALTY_POINT]: loyaltyPointVal
    //   });
    // }
  };

  return (
    <>
      <CardBox
        type={active ? 'ACTIVE' : 'DEFAUL'}
        icon="loyaltyPoint"
        disabled={!customer?.point || disabled}
        onClick={handleOpen}
      >
        Point
      </CardBox>
      <Modal
        // width="auto"
        footerBgColor={colorTheme.fill_5}
        headerBgColor={colorTheme.info_bg_3}
        visible={visible}
        onClose={onClose}
        onSubmit={onClose}
        modalTitle="POINTS"
        width={800}
        noneBodyStyle
        footer={
          <CountingFooter
            submitButtonTitle="Redeem"
            isValid={_amount > 0}
            onSubmit={handleSubmit}
            handleCloseModal={onClose}
            style={{ padding: '1.5rem', paddingTop: '1rem' }}
          >
            <Box className="center">
              <Box display="flex" alignItems="center" gap="4">
                <Text variant="CONTENT_1" color="text_3">
                  Balance point:
                </Text>
                <Text variant="H5" color="text_3" whiteSpace='nowrap' >
                  <BalancePoint />
                </Text>
              </Box>
            </Box>
            <Text mb={1} />
          </CountingFooter>
        }
      >
        <BodyFormStyled>
          <Box >
            <Box className="space-between" mb="5">
              <Text variant="CONTENT_1" color="text_3">
                Available Point:
              </Text>
              <Text variant="H9" color="text_3">
                <AvailablePoint />
              </Text>
            </Box>
            <Text variant="H6" color="text_3">
              Redeem
            </Text>
            <CurrencyKeyBoard ref={currencyRef} value={amount} onChange={val => setAmount(val || 0)} />
          </Box>
        </BodyFormStyled>
      </Modal>
    </>
  );
};

export default LoyaltyPoint;
const BodyFormStyled = styled(Form)`
padding: 1rem 3rem;
`;