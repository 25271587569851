import Button from 'components/Button';
import authActions from 'features/auth/services/actions';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type IHeaderProps = any;
const Header: React.FC<IHeaderProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const logout = () => {
    navigate('/sign-in');
    dispatch(authActions.ownerLogout());
  };
  return (
    <HeaderStyled>
      <LogoBox />
      <Button icon='back' ntype='SECONDARY' onClick={logout} >Back</Button>
    </HeaderStyled>
  );
};

export default Header;
type HeaderStyledProps = {}
const HeaderStyled = styled.div<HeaderStyledProps>`
  // position: fixed;
  top:0;
  left:0;
  right:0;
  padding: 0 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:4.5rem;
  background: var(--primary-button, #FFE5E7);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;


const LogoBox = () => (
  <svg width="131" height="35" viewBox="0 0 131 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="106786A8-CBC9-4063-BD57-061526D4AA1D 1">
      <g id="#f38375ff">
        <path id="Vector" d="M80.1204 4.13773C80.6562 4.07725 81.2036 4.07796 81.7379 4.15765C82.4754 4.32699 83.2092 4.7112 83.6097 5.37006C84.0137 5.98196 84.049 6.78739 83.7775 7.45763C83.5268 7.98415 83.1055 8.44094 82.5705 8.69068C81.8654 9.06707 81.0379 9.09481 80.258 9.02651C79.3909 8.91836 78.5238 8.47082 78.0744 7.70879C77.6178 6.94819 77.6833 5.93144 78.2076 5.21922C78.6585 4.61942 79.3851 4.26153 80.1204 4.13773Z" fill="#F38375" />
        <path id="Vector_2" d="M78.4638 11.5659C80.0554 11.5623 81.6462 11.5545 83.2378 11.5687C83.2465 17.7852 83.2465 24.0016 83.2378 30.2181C81.647 30.2081 80.0554 30.2159 78.4645 30.2131C78.4811 25.917 78.4674 21.6202 78.4717 17.3234C78.4667 15.4037 78.4818 13.4848 78.4638 11.5659Z" fill="#F38375" />
      </g>
      <g id="#000000ff">
        <path id="Vector_3" d="M2.88616 5.53802C10.3313 5.54372 17.7772 5.53731 25.2224 5.54087C25.2181 7.00658 25.2224 8.47158 25.2203 9.93729C19.4675 9.93515 13.7154 9.93586 7.9634 9.93657C7.96412 12.0846 7.96268 14.232 7.96412 16.3793C12.8484 16.3786 17.7326 16.3779 22.6168 16.38C22.6175 17.7554 22.6147 19.1307 22.6183 20.5068C17.7333 20.5096 12.8484 20.5082 7.9634 20.5068C7.96484 23.737 7.96196 26.9666 7.96484 30.1968C6.2717 30.2025 4.57857 30.1975 2.88544 30.1989C2.8876 21.9789 2.88616 13.7588 2.88616 5.53802Z" fill="black" />
        <path id="Vector_4" d="M92.1065 5.79536C93.6851 5.79322 95.263 5.79322 96.8416 5.79465C96.8438 7.72284 96.8438 9.65103 96.8416 11.5792C98.3605 11.5771 99.8786 11.5799 101.397 11.5778C101.393 12.7497 101.395 13.9222 101.396 15.0941C99.8779 15.0898 98.3598 15.0919 96.8416 15.0927C96.8452 20.1273 96.8424 25.1619 96.8431 30.1966C95.2637 30.2037 93.6844 30.203 92.1058 30.1966C92.1094 25.1612 92.1065 20.1259 92.1065 15.0905C90.5884 15.0934 89.0709 15.0905 87.5528 15.0919C87.5564 13.9208 87.5579 12.7489 87.5521 11.5778C89.0695 11.5799 90.5869 11.5764 92.1051 11.5799C92.1094 9.65174 92.1065 7.72355 92.1065 5.79536Z" fill="black" />
        <path id="Vector_5" d="M56.0078 11.5787C62.1566 11.578 68.3048 11.5673 74.4536 11.5716C70.5827 16.4867 66.7089 21.399 62.8293 26.307C66.4892 26.3013 70.1499 26.3183 73.8098 26.3091C73.8055 27.6069 73.8127 28.9054 73.8069 30.2032C67.2447 30.1989 60.6832 30.2039 54.1216 30.201C57.9587 25.2845 61.8153 20.3822 65.6596 15.4707C62.4426 15.4671 59.2263 15.4749 56.0092 15.4664C56.0128 14.1707 56.0157 12.8751 56.0078 11.5787Z" fill="black" />
      </g>
      <g id="#747a7aff">
        <path id="Vector_6" d="M39.3989 11.2328C40.0945 11.1674 40.7902 11.2492 41.4866 11.2492C42.5835 11.3652 43.6911 11.5139 44.7325 11.8888C45.5391 12.1343 46.2974 12.505 47.0435 12.8906C47.5167 13.193 48.0179 13.462 48.4255 13.8533C48.6862 13.9992 48.8605 14.2518 49.114 14.4097C49.6772 15.0052 50.2195 15.6314 50.6336 16.3407C51.3329 17.5076 51.7456 18.8467 51.8363 20.2C51.9134 20.7051 51.8975 21.2245 51.819 21.7283C51.8219 22.1395 51.711 22.5373 51.6483 22.9407C51.6022 23.0332 51.5914 23.1349 51.5763 23.2346C51.2587 24.3424 50.7589 25.4082 50.0387 26.3182C48.8339 27.9298 47.0658 29.0519 45.1862 29.7527C42.8413 30.6079 40.2768 30.7445 37.8137 30.44C36.3273 30.2543 34.8848 29.7933 33.5539 29.118C33.3825 29.0376 33.2334 28.911 33.0455 28.8697C32.8128 28.6648 32.5349 28.5104 32.2641 28.3603C32.0415 28.1219 31.7362 27.9796 31.5072 27.7477C31.2854 27.5129 30.9966 27.3471 30.8151 27.0753C30.3657 26.6519 30.0063 26.1489 29.6657 25.6395C28.9037 24.4192 28.4443 23.019 28.3442 21.5888C28.3607 20.9492 28.2909 20.3024 28.4104 19.6706C28.427 19.3319 28.5264 19.0046 28.5782 18.6709C28.7496 18.1387 28.9102 17.6015 29.1515 17.0949C29.6952 15.9259 30.5184 14.8957 31.4935 14.049C31.945 13.7082 32.3692 13.3218 32.882 13.0706C33.5561 12.5961 34.3375 12.3072 35.1008 12.0062C36.4764 11.5103 37.9383 11.2549 39.3989 11.2328ZM38.9084 15.4293C37.3514 15.6179 35.8117 16.2653 34.7386 17.4194C33.9644 18.2725 33.4516 19.3803 33.4041 20.5315C33.2932 21.7105 33.6519 22.9122 34.3447 23.8742C34.6046 24.2513 34.9561 24.5523 35.2831 24.8703C36.8941 26.1767 39.0899 26.5779 41.1215 26.3552C42.8744 26.1823 44.6518 25.4281 45.7371 24.0151C46.7627 22.7123 47.0954 20.9044 46.5646 19.3355C46.242 18.2547 45.5031 17.3326 44.5935 16.6687C43.5593 15.9395 42.3177 15.5168 41.0545 15.4108C40.621 15.4215 40.1896 15.319 39.7575 15.3816C39.4759 15.4179 39.19 15.3937 38.9084 15.4293Z" fill="#747A7A" />
      </g>
      <g id="#6babb7ff">
        <path id="Vector_7" d="M115.335 11.252C116.224 11.173 117.117 11.2534 118.005 11.3146C120.136 11.5202 122.209 12.2566 123.977 13.4477C124.436 13.7964 124.888 14.1564 125.314 14.5449C125.773 15.0678 126.239 15.5929 126.598 16.1913C127.368 17.43 127.825 18.8573 127.903 20.3088C127.906 21.0345 127.936 21.7667 127.787 22.4817C127.517 24.0449 126.81 25.5306 125.764 26.733C125.054 27.5591 124.19 28.245 123.252 28.8056C122.112 29.4247 120.908 29.9533 119.626 30.2095C117.878 30.5972 116.065 30.6506 114.287 30.4713C112.779 30.3084 111.285 29.9241 109.931 29.2404C108.591 28.6142 107.368 27.7185 106.438 26.5736C105.404 25.342 104.732 23.8194 104.504 22.2356C104.314 20.7549 104.423 19.2237 104.94 17.8142C105.303 16.8352 105.833 15.9159 106.515 15.1198C106.724 14.9205 106.896 14.69 107.092 14.4794C107.713 13.9458 108.338 13.4057 109.062 13.0087C110.305 12.3093 111.65 11.7565 113.063 11.5067C113.811 11.3559 114.571 11.2684 115.335 11.252ZM115.097 15.4186C113.576 15.5844 112.085 16.197 110.976 17.25C110.62 17.6243 110.302 18.0377 110.067 18.4966C109.352 19.7873 109.259 21.396 109.815 22.7599C110.257 23.8948 111.138 24.8361 112.201 25.4352C113.668 26.2784 115.425 26.5217 117.096 26.3616C118.363 26.2471 119.623 25.8386 120.66 25.0994C121.677 24.3737 122.449 23.2971 122.734 22.0833C123.129 20.4973 122.696 18.7278 121.588 17.5111C120.714 16.5207 119.472 15.8925 118.192 15.5837C117.18 15.3418 116.128 15.3503 115.097 15.4186Z" fill="#6BABB7" />
      </g>
      <g id="#ffdf7aff">
        <path id="Vector_8" d="M38.9081 15.4293C39.1897 15.3937 39.4756 15.4179 39.7572 15.3816C40.1893 15.319 40.6207 15.4215 41.0542 15.4108C42.3174 15.5168 43.559 15.9394 44.5932 16.6687C45.5028 17.3326 46.2417 18.2547 46.5643 19.3355C47.0951 20.9043 46.7624 22.7123 45.7368 24.0151C44.6515 25.4281 42.8741 26.1823 41.1212 26.3552C39.0896 26.5779 36.8938 26.1766 35.2828 24.8703C34.9558 24.5523 34.6044 24.2513 34.3444 23.8742C33.6516 22.9122 33.2929 21.7105 33.4038 20.5315C33.4513 19.3803 33.9641 18.2725 34.7383 17.4194C35.8114 16.2653 37.3511 15.6178 38.9081 15.4293Z" fill="#FFDF7A" />
      </g>
    </g>
  </svg>
);
