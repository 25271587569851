/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR FEATURE NAME HERE
 * ex:
 *  NAME_REDUCER = 'NAME_REDUCER'
 *   => 
 *  NAME_REDUCER = 'booking'
 */
export const NAME_REDUCER = 'ticketList';

/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR PREFIX ACTION NAME HERE
 * ex:
 *  PREFIX_ACTIONS = 'PREFIX_ACTIONS';
 *   => 
 *  PREFIX_ACTIONS = 'booking_feature_';
 */
export const PREFIX_ACTIONS = 'ticketList_feature_';


export const PATH_LOADING = {
  getTicketsData: `loading.${NAME_REDUCER}.getTicketsData`,
  getTicketDetailsData: `loading.${NAME_REDUCER}.getTicketDetailsData`,
  refundTicketByBillId: `loading.${NAME_REDUCER}.refundTicketByBillId`,
};
