import { Avatar, Col, Row } from 'antd';
import { DropdownIcon } from 'assets/Icons/AppointmentIcons';
import Button from 'components/Button';
import Text from 'components/Text';
import { get } from 'lodash';
import { useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import { styled } from 'styled-components';
import ModalChooseStaff from './ModalChooseStaff';
type Props = {
  value?: string;
  onChange: (val: IStaffItemData) => void;
};
const DropDownSelectStaff = ({ value, onChange }: Props) => {
  const staffs = shopSelectors.data.staffs();
  const [visible, setVisible] = useState(false);

  const activeStaff = useMemo(() => {
    return staffs.find(o => o.id === value);
  }, [value, staffs]);

  return (
    <div>
      <Select textWidth='100%' onClick={() => setVisible(true)}>
        <Row align={'middle'} className='row-info' wrap={false}  >
          <Avatar size={'small'}
            src={activeStaff?.urlImage}
            style={{ backgroundColor: '#f56a00' }}>{get(activeStaff?.firstName, [0], '')}</Avatar>
          <Text variant='H9' className='text-overflow'  >{activeStaff?.firstName}</Text>
          <Col flex={'auto'}></Col>
          <DropdownIcon />
        </Row>
      </Select>
      <ModalChooseStaff visible={visible} onChooseStaff={onChange} onClose={() => setVisible(false)} />
    </div>
  );
};

export default DropDownSelectStaff;

const Select = styled(Button)`
  width: 12rem;
  .row-info {
    gap: 0.5rem;
  }
`;