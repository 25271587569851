import { MutableRefObject, Ref, forwardRef } from 'react';
import MaskedInput, { MaskedInputProps, conformToMask } from 'react-text-mask';
import { styled } from 'styled-components';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '',
  suffix: '%',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: false,
  decimalSymbol: '.',
  decimalLimit: 0, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};
const currencyMask = createNumberMask(defaultMaskOptions);

export const unmaskedPercent = (value: string) => {
  return +(value?.replaceAll('_', '')?.replaceAll('%', '')?.replaceAll(',', '') ?? '');
};

export const maskPercent = (value: string) => {
  const val = unmaskedPercent(value).toString();
  if (!val) return '';
  const a = conformToMask(val, currencyMask);
  return a?.conformedValue;
};

interface Props extends Omit<MaskedInputProps, 'mask'> {
  mask?: null;
}
const PercentInputMasked = forwardRef((topLevelProps: Props, ref: Ref<HTMLInputElement>) => {
  return <PercentInputMaskedStyled
    mask={currencyMask}
    render={(textMaskRef: any, props: any) => (
      <input
        {...props}
        ref={(node) => {
          if (node) {
            textMaskRef(node);
            if (ref) {
              (ref as MutableRefObject<HTMLInputElement>).current = node;
            }
          }
        }}
      />
    )}
    {...topLevelProps}
  />;
});
PercentInputMasked.displayName = 'PercentInputMasked';
export default PercentInputMasked;

const PercentInputMaskedStyled = styled(MaskedInput)`
border-radius: 2px;
border: 1px solid var(--primary-primary-hover, #F5767F);
background: var(--fill-fill-0, #FFF);
outline: none;
/* box */
height: 100%;

color: var(--text-text-3, #1D2129);
text-align: center;

/* Title/H5 - 24 semibold */
font-family: Open Sans;
font-size: 1rem;
font-style: normal;
// font-weight: 600;
line-height: normal;
width: 100%;
&::placeholder {
    opacity: 0.5;
  }
`;