import BoundingBox from 'components/BoundingBox';
import Text from 'components/Text';
import React from 'react';
import { styled } from 'styled-components';
import AddOnServices from './AddOnServices';
import Categories from './Categories';

interface IServicePartProps { }
const CategoryPart: React.FC<IServicePartProps> = () => {
  return (
    <ServicePartStyled className="container">
      <BoundingBox className="category-bounding-box" color="primary_bg">
        <Text variant="H7">CATEGORIES</Text>
      </BoundingBox>
      <div className="cate-container">
        <Categories />
        <AddOnServices />
      </div>
    </ServicePartStyled>
  );
};

export default CategoryPart;

const ServicePartStyled = styled.div`
  &.container {
    width: 35vw;
  }

  .cate-container {
    margin: 2.5rem;
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
  }

  .category-bounding-box {
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: center;
  }
  .category-bounding-box .H7 {
    margin-left: 2.5rem;
  }
`;
