import React from 'react';
import { Form, Checkbox } from 'antd';
import styled from 'styled-components';
import Box from 'components/Box';
import TimePicker from 'components/TimePicker';
import Text from 'components/Text';
// import useFormInstance from 'antd/lib/form/hooks/useFormInstance';

const DaySchedule: React.FC<{
  day: string;
  enable: string;
  start: string;
  end: string;
}> = ({ day, enable, start, end }) => {
  //   const form = useFormInstance();

  return (
    <DayScheduleStyled className="item-schedule">
      <Box className="center header" bgColor="fill_2">
        <FormItemNoMargin name={enable} valuePropName="checked">
          <Checkbox className="custom-checkbox">
            <Text variant="H9" color="text_3">
              {day}
            </Text>
          </Checkbox>
        </FormItemNoMargin>
      </Box>
      <Box className="center">
        <FormItemNoMargin name={start}>
          <TimePickerStyled
            use12Hours
            bordered={false}
            allowClear={false}
            suffixIcon={null}
            format="hh:mm a"
          />
        </FormItemNoMargin>
      </Box>
      <Box className="center">
        <FormItemNoMargin name={end}>
          <TimePickerStyled
            format="hh:mm a"
            use12Hours
            bordered={false}
            allowClear={false}
            suffixIcon={null}
          />
        </FormItemNoMargin>
      </Box>
    </DayScheduleStyled>
  );
};

export default DaySchedule;
const TimePickerStyled = styled(TimePicker)`
  .ant-picker-suffix {
    display: none;
  }
`;
const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
  padding: 1rem 0.5rem;
`;
const DayScheduleStyled = styled.div`
  .ant-picker-input {
    position: relative;
    display: inline-block; /* or block, depending on your layout */
  }
  .ant-picker-input::after {
    content: "";
    position: absolute;
    left: 10%; /* centers the border */
    bottom: 0;
    width: 80%;
    border-bottom: 1px solid #1d2129;
  }

  .ant-checkbox .ant-checkbox-inner {
    width: 2rem;
    height: 2rem;
    background-color: #e5e5e5;
    border-color: #e5e5e5;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    width: 2rem;
    height: 2rem;
    background-color: #ff8890;
    border-color: #ff8890;
  }

  .custom-checkbox .ant-checkbox .ant-checkbox-inner::after {
    left: 35%;
  }
`;
