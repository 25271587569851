import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import calculate from './logic/calculate';
import styled from 'styled-components';

class Calculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      next: null,
      operation: null,

    };
  }

  changeOutput = (event) => {
    this.setState((prevState) => (
      calculate(prevState, event.target.name)
    ));
  };

  showTotal = () => {
    const { total, next } = this.state;
    if (total === 0 && next === null) {
      return 0;
    } if (total !== 0 && total !== null) {
      return total;
    }
    return '';
  };

  render() {
    const { operation, next } = this.state;
    const inputs = [
      'AC',
      '+/-',
      '%',
      '÷',
      '7',
      '8',
      '9',
      'x',
      '4',
      '5',
      '6',
      '-',
      '1',
      '2',
      '3',
      '+',
      '0',
      '.',
      '=',
    ];
    return (
      <CalculatorStyled>
        <form className="calc-container">
          <input className="digit" type="text" key={uuidv4()} value={(this.showTotal()) + (operation || '') + (next || '')} disabled />
          {inputs.map((input) => (
            <button key={uuidv4()} name={input} type="button" onClick={this.changeOutput} className="digit">{input}</button>
          ))}
        </form>
      </CalculatorStyled>
    );
  }
}

export default Calculator;

const CalculatorStyled = styled.div`
.calc-container {
  height: 70vh;
  width: 65vw;
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-template-rows: auto auto auto auto auto;
}

.digit {
  font-family: Inter;
  cursor: pointer;
  font-weight: 600;
  padding: 10px;
  border-radius: 5px;
  font-size: 2.5rem;
  box-shadow: #0000003d 0 4px 4px;
  background-color: #dfdede;
  border: none;
  margin: 4px;
  color: var(--text-text-3, #1D2129);
}

.digit:nth-child(4n + 5),
.digit:nth-child(20) {
  background-color: #d68f75;
}

.digit:nth-child(4n + 5):hover,
.digit:nth-child(20):hover {
  background-color: #a35c42;
}

.digit:nth-child(4n + 5):active,
.digit:nth-child(20):active {
  background-color: #793a23;
}

.digit:nth-child(1) {
  grid-column-end: span 4;
  background-color: #444;
  color: white;
  text-align: right;
  font-size: 3rem;
  font-family: Inter;
  letter-spacing: 5px;
  cursor: none;
}

.digit:nth-child(18) {
  grid-column-end: span 2;
}

.digit:hover {
  background-color: #bdbbbb;
  transition: all 100ms ease-in-out;
}

.digit:nth-child(1):hover {
  background-color: #444;
}
.digit:active {
  background-color: #858383;
  transition: all 100ms ease-in-out;
}

@media screen and (max-width: 450px) {
  .calc-container {
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    height: 100vh;
  }
  header {
    display: none;
  }
}

`;
