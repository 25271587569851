import CarouserBox from 'components/Carosel/CarouserBox';
import ProductItem from 'components/ProductItem';
import Spin from 'components/Spin';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { useCallback } from 'react';
import { IProductItemData } from 'services/shop/types/product';
import { useAppDispatch } from 'store/hooks';

const ActiveProducts = () => {
  const loading = cashierSelectors.loading.getCategories();
  const dispatch = useAppDispatch();
  const products = cashierSelectors.getProductsByProductCateActive();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();

  const handleClickService = useCallback((serviceItem: IProductItemData) => () => {
    dispatch(cashierActions.selectProductItem(serviceItem));
  }, []);

  const renderItem = useCallback((item: IProductItemData) => {
    const itemSelected = serviceIdsSelected.find(o => o.id === item.id);
    return (
      <ProductItem
        key={item.id}
        data={item}
        onClick={handleClickService(item)}
        selected={!!itemSelected}
        count={itemSelected?.count}
      />
    );
  }, [handleClickService, serviceIdsSelected]);

  return (
    <>
      <CarouserBox
        content="PRODUCTS"
        list={products || []}
        rowPerPage={6}
        colSpan={8}
        itemContent={renderItem}
      />
      {loading && <Spin />}
    </>
  );
};

export default ActiveProducts;