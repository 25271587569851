
const Category = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M17.5013 2.41699C16.3439 2.41699 15.3955 3.36433 15.3955 4.54695V8.38833C15.3955 9.57095 16.3439 10.5183 17.5013 10.5183H21.3106C22.468 10.5183 23.4164 9.57095 23.4164 8.38833V4.54695C23.4164 3.36433 22.468 2.41699 21.3106 2.41699H17.5013ZM13.8955 4.54695C13.8955 2.54782 15.5036 0.916992 17.5013 0.916992H21.3106C23.3083 0.916992 24.9164 2.54782 24.9164 4.54695V8.38833C24.9164 10.3875 23.3083 12.0183 21.3106 12.0183H17.5013C15.5036 12.0183 13.8955 10.3875 13.8955 8.38833V4.54695Z" fill="#1D2129" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.916504 4.54695C0.916504 2.54782 2.52464 0.916992 4.52234 0.916992H8.33155C10.3293 0.916992 11.9374 2.54782 11.9374 4.54695V8.38833C11.9374 10.3875 10.3293 12.0183 8.33155 12.0183H4.52234C2.52464 12.0183 0.916504 10.3875 0.916504 8.38833V4.54695ZM4.52234 2.41699C3.36494 2.41699 2.4165 3.36433 2.4165 4.54695V8.38833C2.4165 9.57095 3.36494 10.5183 4.52234 10.5183H8.33155C9.48896 10.5183 10.4374 9.57095 10.4374 8.38833V4.54695C10.4374 3.36433 9.48896 2.41699 8.33155 2.41699H4.52234ZM0.916504 17.4456C0.916504 15.4465 2.52464 13.8156 4.52234 13.8156H8.33155C10.3293 13.8156 11.9374 15.4465 11.9374 17.4456V21.287C11.9374 23.2848 10.3294 24.9169 8.33155 24.9169H4.52234C2.52446 24.9169 0.916504 23.2848 0.916504 21.287V17.4456ZM4.52234 15.3156C3.36494 15.3156 2.4165 16.263 2.4165 17.4456V21.287C2.4165 22.4687 3.36512 23.4169 4.52234 23.4169H8.33155C9.48878 23.4169 10.4374 22.4687 10.4374 21.287V17.4456C10.4374 16.263 9.48896 15.3156 8.33155 15.3156H4.52234ZM17.5014 15.3156C16.344 15.3156 15.3956 16.263 15.3956 17.4456V21.287C15.3956 22.4687 16.3442 23.4169 17.5014 23.4169H21.3107C22.4679 23.4169 23.4165 22.4687 23.4165 21.287V17.4456C23.4165 16.263 22.4681 15.3156 21.3107 15.3156H17.5014ZM13.8956 17.4456C13.8956 15.4465 15.5037 13.8156 17.5014 13.8156H21.3107C23.3084 13.8156 24.9165 15.4465 24.9165 17.4456V21.287C24.9165 23.2848 23.3085 24.9169 21.3107 24.9169H17.5014C15.5036 24.9169 13.8956 23.2848 13.8956 21.287V17.4456Z" fill="#1D2129" />
    </svg>
  );
};

export default Category;


export const CategoryActive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
      <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M17.5016 2.41699C16.3442 2.41699 15.3958 3.36433 15.3958 4.54695V8.38833C15.3958 9.57095 16.3442 10.5183 17.5016 10.5183H21.3108C22.4682 10.5183 23.4166 9.57095 23.4166 8.38833V4.54695C23.4166 3.36433 22.4682 2.41699 21.3108 2.41699H17.5016ZM13.8958 4.54695C13.8958 2.54782 15.5039 0.916992 17.5016 0.916992H21.3108C23.3085 0.916992 24.9166 2.54782 24.9166 4.54695V8.38833C24.9166 10.3875 23.3085 12.0183 21.3108 12.0183H17.5016C15.5039 12.0183 13.8958 10.3875 13.8958 8.38833V4.54695Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M0.916748 4.54695C0.916748 2.54782 2.52489 0.916992 4.52259 0.916992H8.3318C10.3295 0.916992 11.9376 2.54782 11.9376 4.54695V8.38833C11.9376 10.3875 10.3295 12.0183 8.3318 12.0183H4.52259C2.52489 12.0183 0.916748 10.3875 0.916748 8.38833V4.54695ZM4.52259 2.41699C3.36518 2.41699 2.41675 3.36433 2.41675 4.54695V8.38833C2.41675 9.57095 3.36518 10.5183 4.52259 10.5183H8.3318C9.4892 10.5183 10.4376 9.57095 10.4376 8.38833V4.54695C10.4376 3.36433 9.4892 2.41699 8.3318 2.41699H4.52259ZM0.916748 17.4456C0.916748 15.4465 2.52489 13.8156 4.52259 13.8156H8.3318C10.3295 13.8156 11.9376 15.4465 11.9376 17.4456V21.287C11.9376 23.2848 10.3297 24.9169 8.3318 24.9169H4.52259C2.52471 24.9169 0.916748 23.2848 0.916748 21.287V17.4456ZM4.52259 15.3156C3.36518 15.3156 2.41675 16.263 2.41675 17.4456V21.287C2.41675 22.4687 3.36537 23.4169 4.52259 23.4169H8.3318C9.48902 23.4169 10.4376 22.4687 10.4376 21.287V17.4456C10.4376 16.263 9.4892 15.3156 8.3318 15.3156H4.52259ZM17.5017 15.3156C16.3443 15.3156 15.3958 16.263 15.3958 17.4456V21.287C15.3958 22.4687 16.3445 23.4169 17.5017 23.4169H21.3109C22.4681 23.4169 23.4167 22.4687 23.4167 21.287V17.4456C23.4167 16.263 22.4683 15.3156 21.3109 15.3156H17.5017ZM13.8958 17.4456C13.8958 15.4465 15.504 13.8156 17.5017 13.8156H21.3109C23.3086 13.8156 24.9167 15.4465 24.9167 17.4456V21.287C24.9167 23.2848 23.3088 24.9169 21.3109 24.9169H17.5017C15.5038 24.9169 13.8958 23.2848 13.8958 21.287V17.4456Z" fill="white" />
    </svg>
  );
};
