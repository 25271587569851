import { Col, InputRef, Row } from 'antd';
import Text from 'components/Text';
import { useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { ICustomerItemData, ICustomerItemDataOpt } from 'services/shop/types/customer';
import styled from 'styled-components';
import ButtonAddCustomer from '../AddCustomer';
import CustomerSearchInput from '../CustomerSearchInput';
import CustomerItem from './CustomerItem';
import { UNKNOWN_CUSTOMER } from 'features/cashier/services/constants';
export type ChooseCustomerFormProps = {
  onPickCustomer?: (cus: ICustomerItemData) => void;
  inputRef: React.RefObject<InputRef>;
  customer?: ICustomerItemData;
  recentList?: ICustomerItemData[];
  onChooseUnknown?: () => void;
};
const ChooseCustomerForm = ({ inputRef, customer, onPickCustomer = () => undefined, onChooseUnknown }: ChooseCustomerFormProps) => {
  const customers = shopSelectors.data.customers();
  const [searchText] = useState('');

  const initOptions = useMemo(() => {
    return customers.map(o => ({
      ...o,
      label: o.name,
      value: o.id,
      keyword: o.name.toLowerCase(),
    }));
  }, [customers]);

  // const recentListOptions = useMemo(() => {
  //   return recentList.map(o => ({
  //     ...o,
  //     label: o.name,
  //     value: o.id,
  //     keyword: o.name.toLowerCase(),
  //   }));
  // }, [recentList]);

  const options = useMemo(() => {
    if (!searchText.trim()) return initOptions;

    return initOptions.filter((item) =>
      item.keyword.includes(searchText.toLowerCase())
    );
  }, [initOptions, searchText]);

  return (
    <ChooseCustomerFormStyled>
      <Row gutter={16} className='sticky-box'>
        <Col flex={'auto'}>
          <CustomerSearchInput inputRef={inputRef} onSelect={onPickCustomer} />
        </Col>
        <Col>
          <ButtonAddCustomer
            model='new-ticket'
          />
        </Col>
      </Row>
      <Text mb={1.5} />
      {customer && (
        <Row gutter={[16, 16]} justify="start" className='container'>
          <Col span={4} >
            <CustomerItem disabledPoint={!customer.id} selected data={customer} />
          </Col>
          {onChooseUnknown && customer.id && <Col span={4} onClick={onChooseUnknown}>
            <CustomerItem disabledPoint data={UNKNOWN_CUSTOMER} />
          </Col>}
        </Row>
      )}
      <Text mb={1.5} />
      <Text variant='H8' mb={1}>All Customer</Text>
      <ListContainer>
        <Row gutter={[16, 16]} justify="start">
          {options.map((item: ICustomerItemDataOpt) => {
            return (
              <Col
                span={4}
                key={item.id}
                onClick={() => onPickCustomer(item)}
              >
                <CustomerItem
                  data={item}
                />
              </Col>
            );
          })}
        </Row>
      </ListContainer>
    </ChooseCustomerFormStyled>
  );
};

export default ChooseCustomerForm;
const ChooseCustomerFormStyled = styled.div`
  .container {
    padding: 0 1rem;
  }
`;
const ListContainer = styled.div`
  padding: 1.5rem 1rem;
  padding-top: 0;
  max-height: 20rem;
  min-height: 20rem;
  overflow: auto;
`;
