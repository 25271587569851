import { Row } from 'antd';
import styled from 'styled-components';
import StaffPicker from './StaffPicker';
import SwitchCalendarType from './SwitchCalendarType';
import TypeItemPicker from './TypeItemPicker';

const TableFilter = () => {
  return (
    <CalendarFilterStyled align={'middle'}>
      <TypeItemPicker />
      <StaffPicker />
      <SwitchCalendarType />
    </CalendarFilterStyled>
  );
};

export default TableFilter;

const CalendarFilterStyled = styled(Row)`
  gap: 12px;
`;
