import { ColumnsType } from 'antd/es/table';
import Table from 'components/Table';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import { Bill, StaffItem } from 'features/ticketList/services/types/api';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';

type ITransactionsTabProps = {};
const TransactionsTab: React.FC<ITransactionsTabProps> = () => {
  const dispatch = useAppDispatch();
  const customerDetails = customerSelectors.getCustomerDetails();
  const data = customerSelectors.transactionTableData.data();
  const loading = customerSelectors.transactionTableData.loading();
  const params = customerSelectors.transactionTableData.params();
  const totalElement = customerSelectors.transactionTableData.totalElement();

  const baseColumns: ColumnsType<Bill> = [
    {
      title: 'Date',
      dataIndex: 'startTime',
      align: 'center',
      render(startTime: string) {
        return (
          <Text variant="BODY_1" color="text_3">
            {startTime ? momentTimezone(startTime).format('MM-DD-YYYY') : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Ticket Number',
      dataIndex: 'ticketNumber',
      align: 'center',
      render(text) {
        return (
          <Text variant="BODY_1" color="text_3">
            {text ? `#${text}` : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Services',
      dataIndex: 'items',
      align: 'center',
      render(items: StaffItem[]) {
        const allItemNames: string[] = items.flatMap((item) =>
          item.services.map((service) => service.itemName)
        );
        const concatenatedString: string = allItemNames.join(', ');
        return (
          <BoxMarginStyled width={'10rem'}>
            <Text variant="BODY_1" color="text_3">
              {concatenatedString || '--'}
            </Text>
          </BoxMarginStyled>
        );
      },
    },
    {
      title: 'Staff',
      dataIndex: 'items',
      align: 'center',
      render(items: StaffItem[]) {
        const allStaffNames: string[] = items.map((item) => item.staffName);
        const concatenatedStaffNames: string = allStaffNames.join(', ');
        return (
          <BoxMarginStyled width={'5rem'}>
            <Text variant="BODY_1" color="text_3">
              {concatenatedStaffNames || '--'}
            </Text>
          </BoxMarginStyled>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'checkInType',
      align: 'center',
      render(value) {
        return (
          <Text variant="BODY_1" color="text_3">
            {value}
          </Text>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      align: 'center',
      render(total: number) {
        return (
          <Text variant="BODY_1" color="text_3">
            {formatCurrency(total)}
          </Text>
        );
      },
    },
  ];

  const handlePageChange = async (page?: number) => {
    const params = {
      page: page,
    };
    dispatch(customerActions.setTransactionsParams(params));
  };

  useEffect(() => {
    if (customerDetails?.id) {
      const params = {
        customerId: customerDetails?.id || '',
      };
      dispatch(customerActions.setTransactionsParams(params));
    }
  }, []);

  return (
    <TransactionsTabStyled>
      <Table
        loading={loading}
        columns={baseColumns}
        dataSource={data}
        rowKey={'billId'}
        pagination={{
          total: totalElement ?? 0,
          pageSize: params.size,
          current: params.page,
          showSizeChanger: false,
        }}
        handleChangePage={handlePageChange}
      />
    </TransactionsTabStyled>
  );
};

export default TransactionsTab;
const TransactionsTabStyled = styled.div``;

const BoxMarginStyled = styled.div<{ width: string }>`
  margin: auto;
  width: ${({ width }) => width};
`;
