import { Card, Col, ColProps, Row } from 'antd';
import Icon, { typeIcon } from 'assets/Icons';
import Text from 'components/Text';
import { IDashboardTimeClockReport } from 'features/report/services/types/reportStaff';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

interface IDashboard { 
    data: IDashboardTimeClockReport
}
const Dashboard: React.FC<IDashboard> = ({data}) => {
    return (
        <Row gutter={[24, 24]}>
            <DashboardItem title="Hourly Payroll" value={data.hourlyPayroll+'h'} icon="datePicker" />
            <DashboardItem title="Hourly Pay Out" value={formatCurrency(data.hourlyPayOut)} icon="salary" />
            <DashboardItem title="Tips" value={formatCurrency(data.totalTip)} icon="moneyExchange" />
            <DashboardItem title="Discount" value={formatCurrency(data.totalDiscount)} icon="moneyExchange" />
        </Row>
    );
};

interface IDashboardItem extends ColProps { title: string, value: string, icon: typeIcon }
const DashboardItem: React.FC<IDashboardItem> = ({ title, value, icon, ...props }) => {
    return (
        <Col xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            {...props}
        >
            <StyledCard>
                <StyledCardBody>
                    <TextBox>
                        <Text variant='CONTENT_1' color='text_2'>{title}</Text>
                        <Text variant='CONTENT_2' color='text_3'>{value}</Text>
                    </TextBox>
                    {icon && <IconBox>
                        <Icon type={icon} />
                    </IconBox>}
                </StyledCardBody>
            </StyledCard>
        </Col>
    );
};
export default Dashboard;

const StyledCard = styled(Card)`
    border-radius: 3.358px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0.672px solid #86909C;
    background: #FFF;
    display:flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    .ant-card-body {
        flex: 1;
        padding: 0;
    }
`;

const StyledCardBody = styled(Row)`
    flex-wrap: nowrap;
    padding: 20px;
    justify-content: space-between;
`;

const TextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
`;

const IconBox = styled.div`
    border-radius: 10px;
    background: #fff;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    width: 67px;
    height: 67px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
`;