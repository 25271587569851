import { Card, Carousel, Col, Row } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import turnActions from 'features/turn/services/actions';
import turnSelectors from 'features/turn/services/selectors';
import userSelectors from 'features/user/services/selectors';
import { IWaitingListResItem } from 'features/user/services/types/waiting';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import CountUp from './CountUp';
type ICarouselProps = {
};
const WIDTH_ITEM_CAROUSEL = 140;
const StaffCarousel: React.FC<ICarouselProps> = ({
}) => {
  const dispatch = useDispatch();
  const carouselRef = React.createRef<CarouselRef>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [arrayByPage, setArrayByPage] = useState<number[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [widthItem, setWidthItem] = useState<number>(WIDTH_ITEM_CAROUSEL);
  const [lstCustomer, setLstCustomer] = useState<any[]>([]);
  const waitingList = userSelectors.getWaitingListData();
  const selectWaitingListResItem = turnSelectors.selectWaitingListResItem();
  useEffect(() => {
    setLstCustomer(waitingList);
  }, [waitingList]);
  useEffect(() => {
    reCalcTotalPage();
  }, [lstCustomer]);

  const reCalcTotalPage = () => {
    const currWidth = window.screen.availWidth;
    const widthCarousel = currWidth - 172 - 40;
    const maxPageSize = Math.floor(widthCarousel / WIDTH_ITEM_CAROUSEL);
    const remainWidthCarousel = widthCarousel - maxPageSize * WIDTH_ITEM_CAROUSEL;
    const newWidthItem = WIDTH_ITEM_CAROUSEL + (remainWidthCarousel) / maxPageSize;
    const _totalPage = Math.ceil(lstCustomer.length / maxPageSize);
    const _arrayByPage = Array.from({ length: _totalPage }, (_, i) => i + 1);
    setTotalPage(_totalPage);
    setPageSize(maxPageSize);
    setArrayByPage(_arrayByPage);
    setWidthItem(newWidthItem);
  };
  // const onCloseModalDetail = () => setSelected(undefined);

  return (
    <CarouselContainerStyled>
      <Carousel ref={carouselRef} dots={false}>
        {arrayByPage.map((page) => (
          <div key={page}>
            <Row gutter={[12, 12]} style={{ padding: '20px 0px', overflowY: 'auto' }}>
              {lstCustomer.slice((page - 1) * pageSize, page * pageSize).map((customer: IWaitingListResItem) => {
                return (
                  <ColBox key={customer.checkInId}
                    width={widthItem}
                  >
                    <CardCus
                      isselected={customer.checkInId == selectWaitingListResItem?.checkInId ? 'true' : 'false'}
                      onClick={() => {
                        if (customer.checkInId == selectWaitingListResItem?.checkInId) {
                          return dispatch(turnActions.selectWaitingListResItem(undefined));
                        }
                        dispatch(turnActions.selectWaitingListResItem(customer));
                      }}
                    >
                      <Body>
                        <Row style={{ alignItems: 'center' }}>
                          <StaffBox>Staff: <span>{customer.staffName}</span></StaffBox>
                          {/* <Customer variant='H9'>{customer.customerName} </Customer> */}
                          {/* {customer?.isVIP && <Icon type='crown' />} */}
                        </Row>
                        <Row>
                          <Services variant='BODY_1'> <CountUp color='#1D2129' createDate={customer.createdDate} /></Services>
                          {/* <CountUp variant='BODY_1'>{customer.createdDate}</CountUp> */}
                          {/* <CountUp createDate={customer.createdDate} /> */}
                        </Row>
                      </Body>
                      <Footer>
                        <Row align={'middle'} justify={'space-between'} style={{ width: '100%', padding: '0 4px' }}>
                          <Text variant='H9'>{customer.customerName}</Text>
                          <Text variant='H9'>#{customer.ticketNumber}</Text>
                        </Row>
                      </Footer>
                    </CardCus>
                  </ColBox>
                );
              })}
            </Row>
          </div>
        ))}
      </Carousel>
      {totalPage > 1 && <NextButton
        onClick={() => {
          carouselRef.current?.next();
          reCalcTotalPage();
        }}
      >
        <Icon type="nextCarousel"></Icon>
      </NextButton>}
      {/* <ModalWaitingListDetail key={uniqueId()} isShow={!!selected} data={selected} onClose={onCloseModalDetail}/> */}
    </CarouselContainerStyled>
  );
};

export default StaffCarousel;
const CarouselContainerStyled = styled.div`
  position: relative;
  width: calc(100vw - 104px);
  padding: 0px 20px 20px;
`;
const NextButton = styled.div`
  position: absolute;
  top: 50px;
  right: 35px;
  bottom: 0;
  z-index: 1;
  text-align: center;
`;
const ColBox = styled(Col) <{ width?: number }>`
 width: ${props => props.width ? props.width + 'px' : 'undefined'};
`;
const CardCus = styled(Card) <{ isselected?: string }>`
    border-radius: 5px;
    margin: 0px 2px;
    min-width: 8.5rem;
    min-height: 5.5rem;
    border-radius:  ${props => props.isselected == 'true' ? '14px' : '10px'};
    border: ${props => props.isselected == 'true' ? '2px dashed var(--primary-primary-active, #FF8890)' : 'unset'};
    background: var(--fill-fill-0, #FFF);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    height: 100%;
    width: calc(100% - 30px);
    .ant-card-body{
      padding: 3px;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    }
    > div {
      margin: auto;
    }
`;

const Body = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: var(--fill-fill-2, #ADADAD);
  display: flex;
  flex-direction: column;
  flex: 2;
  align-items: center;
  justify-content: space-between; 
  padding-bottom: 3px;
`;
const TextTemp = styled(Text)`
  text-align: center;
  text-overflow: ellipsis;
    
  /* Body/Body 1 - 14 semibold */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  line-height: normal;
`;

const StaffBox = styled(TextTemp)`
  color: var(--text-text-1, #FFF);
  font-weight: 500;
  padding: 0px 5px;
  span {
    font-weight: 600;
  }

`;

const Services = styled(TextTemp)`
  background: var(--info-infor-2, #FFDC7C);
`;

const Footer = styled.div`
  border-radius: 0px 0px 8px 8px;
  background: var(--fill-fill-3, #E5E5E5);
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;