import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import checkInActions from 'features/checkIn/services/actions';
import { CHECK_IN_COUNTDOWN } from 'features/checkIn/services/constants';
type IButtonCountDownProps = {};
const ButtonCountDown: React.FC<IButtonCountDownProps> = () => {
  const [count, setCount] = useState(CHECK_IN_COUNTDOWN);
  const { shop_id = '' } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleNavigate = useCallback(() => {
    dispatch(checkInActions.resetForm('all'));
    navigate(`/store/${shop_id}/check-in/sign-in`);
  }, []);
  useEffect(() => {
    if (count === 0) {
      handleNavigate();
    } else {
      const interval = setInterval(() => {
        setCount((previous) => previous - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [count]);
  return (
    <ButtonCountDownStyled className="ant-layout-ButtonCountDown-cus">
      <Button onClick={handleNavigate} ntype="DEFAULT">{`Done${count > 0 ? ` (${count}s)` : ''
        }`}</Button>
    </ButtonCountDownStyled>
  );
};

export default ButtonCountDown;
const ButtonCountDownStyled = styled.div`
  position: absolute;
  right: 2.3rem;
  top: 0.5rem;
`;
