import { IAppointmentCustomerDetail } from 'features/checkIn/services/types/customer';
import styled from 'styled-components';
import CustomerInfo from './CustomerInfo';
import TargetServices from './TargetServices';
import TicketHeader from './TicketHeader';

type service = {
  serviceId: string;
  serviceName: string;
  price: number;
};
export type StaffServices = {
  id: string;
  staffName: string;
  services: service[];
};
export type ITicketData = {
  ticketNumber: number;
  customerName: string;
  point: number;
  items: StaffServices[];
};

const TicketDetailBox = ({ data, isAppointment }: { data: IAppointmentCustomerDetail | null, isAppointment?: boolean }) => {
  if (!data) return null;

  return (
    <TicketDetailBoxStyled>
      <div className="ticket-detail-box">
        <div className='container head'>
          <TicketHeader ticketId={data?.ticketNumber?.toString()} />
          <CustomerInfo
            customerName={data.customerName ?? 'No name'}
            point={data.point?.toString() ?? '0'}
          />
        </div>
        <TargetServices isAppointment={isAppointment} staffs={data.items} />
      </div>
    </TicketDetailBoxStyled>
  );
};
export default TicketDetailBox;

const TicketDetailBoxStyled = styled.div`
    display: flex;
    justify-content: center;
    height: 100vh;
    .container {
      padding: 0 1.625rem;
      &.head {
        padding: 1.625rem;
      }
    }

    .ticket-detail-box{
        margin-top: 2rem;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;
        max-width: 22rem;
        width: 40vw;
        height: 70vh;
        border-radius: 10px;
        background: #FFF;

        /* box */
        box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
`;
