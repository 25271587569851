import { Col, Row, RowProps } from 'antd';
import Empty from 'components/Empty';
import Spin from 'components/Spin';
import Text from 'components/Text';
import appointmentApis from 'features/appointment/services/apis';
import { DATE_FORMAT, FORMAT_TIME } from 'features/appointment/utils/format';
import moment, { Moment } from 'moment';
import { memo, useCallback, useEffect, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import CalendarPicker from '../../AppointmentsPage/components/Calendar/TableView/Body/Modal/NewAppointmentV2/TimeTab/CalendarPicker';
type TimeSlotPickerProps = {
  timeSlot: Moment | null;
  onChooseTimeSlot: (time: Moment) => void;
};
const TimeSlotPicker = ({ timeSlot, onChooseTimeSlot }: TimeSlotPickerProps) => {
  const [slots, setSlots] = useState<Moment[]>([]);
  const [date, setDate] = useState<Moment>(() => timeSlot ? moment(timeSlot) : moment());
  const [loading, setLoading] = useState(false);

  const fetchSlot = async () => {
    if (!date) return;

    setLoading(true);
    try {
      const resData: IResponseDataBody<string[]> = await appointmentApis.getStaffTimeSlotByDate(date?.format(DATE_FORMAT) || '');
      const list = resData.data.data;

      if (list) {
        const obSetDate = {
          date: date.get('date'),
          month: date.get('month'),
          year: date.get('year'),
        };
        const data: Moment[] = list.map(d => moment(d, 'HH:mm').set(obSetDate));
        setSlots(data);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSlot();
  }, [date]);

  const ListSlot = useCallback(() => {
    if (loading) return <Row align={'middle'} justify={'center'}><Spin /></Row>;

    if (!slots.length) return (
      <div className='empty-container'>
        <Empty />
      </div>
    );

    return (
      <TimeSlotStyled>
        <Row className='list' gutter={[8, 8]}>
          {
            slots.map((s, i) => (
              <Col span={6} key={i}>
                <button className={`time-slot ${s.format(FORMAT_TIME) === timeSlot?.format(FORMAT_TIME) ? 'active' : ''}`} onClick={() => onChooseTimeSlot(s)}>
                  <Text className='time-slot-text'>{s.format(FORMAT_TIME)}</Text>
                </button>
              </Col>
            ))
          }
        </Row>
      </TimeSlotStyled>
    );
  }, [slots, timeSlot, loading]);


  return (
    <TimeTabStyled gutter={[16, 16]}>
      <Col span={12}><CalendarPicker value={date} onChange={setDate} /></Col>
      <Col span={12} style={{ position: 'relative', overflowY: 'auto', zIndex: 1 }}>
        <div style={slots.length === 0 ? {} : { position: 'absolute' }}>
          <ListSlot />
        </div>
      </Col>
    </TimeTabStyled>
  );
};

export default memo(TimeSlotPicker);

type TimeTabStyledProps = RowProps & {};
const TimeTabStyled = styled(Row) <TimeTabStyledProps>`
  display: flex;
  
  .section-label {
    color: var(--text-text-2, #767676);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: sticky;
    top:0;
    background:#fff;
    z-index:99;
    padding-bottom: 0.5rem;
  }

  .ant-picker-cell {
    &.ant-picker-cell-selected {
      .ant-picker-cell-inner {
        border-radius: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        background: var(--primary-primary-hover, #F5767F);
        .ant-picker-calendar-date-value {
          color: #fff;
          background: var(--primary-primary-hover, #F5767F) !important;
          font-weight: 600;
          border-radius: 100px;
        }
      }
    }
  }
`;


const TimeSlotStyled = styled.div`
  .list {
    width: 100%;
    margin: 0 -4px;
    max-height: 100%;
    overflow-y: auto;
  }
  .time-slot {
    width:100%;
    border-radius: 5px;
    border: 1px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-5, #E5E6EB);
    height: 3.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    .time-slot-text {
      color: var(--text-text-3, #1D2129);
      text-align: center;
      font-family: Open Sans;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

    }
    &.active {
      background: var(--primary-primary-hover, #F5767F);
      .time-slot-text {
        color: #fff;
      }
    }
  }
`;
