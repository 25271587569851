import Box from 'components/Box';
import Button from 'components/Button';
import CurrencyKeyBoard, { CurrencyKeyBoardRef, ICurrencyKeyBoard } from 'components/NumberKeyBoard/Currency';
import { styled } from 'styled-components';
type Props = {
  onBack: () => void;
  onDone: () => void;
  onClear: () => void;
  remaining?: number;
  value?: ICurrencyKeyBoard['value'];
  onChange?: ICurrencyKeyBoard['onChange'];
  keyboardRef: React.RefObject<CurrencyKeyBoardRef>;
};
const PaymentKeyboard = ({
  onBack,
  keyboardRef,
  value,
  onClear,
  remaining = 0,
  onChange,
  onDone,
}: Props) => {
  return (
    <PaymentKeyboardStyled>
      <div className='keyboard'>
        <CurrencyKeyBoard ref={keyboardRef} value={value} onChange={onChange} />
      </div>
      <Box className="center">
        <Box display="flex" gap="2">
          <Button
            width="8.25rem"
            ntype="SECONDARY"
            onClick={onBack}
          >
            Close
          </Button>
          <Button onClick={onClear} width="8.25rem" ntype="DEFAULT">
            Clear
          </Button>

          <Button onClick={onDone} width="8.25rem" ntype="PRIMARY" disabled={remaining > 0}>
            Done
          </Button>

        </Box>
      </Box>
    </PaymentKeyboardStyled>
  );
};

export default PaymentKeyboard;

const PaymentKeyboardStyled = styled.div`
  flex-grow:1;
  display:flex;
  flex-direction: column;
  padding: 1rem 0;
  .keyboard {
    flex: 1;
    padding: 0 1.5rem;
  }
`;
