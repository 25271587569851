const Employee = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_464_15198)">
        <path
          d="M11.141 0H12.8612C14.4974 0.2424 16.022 1.1832 16.9286 2.5728C17.4452 3.3264 17.732 4.2126 17.8736 5.109C18.0494 6.4926 17.7374 7.9404 16.9784 9.114C16.0364 10.6104 14.366 11.622 12.6038 11.7612C12.0428 11.7522 11.477 11.8002 10.9214 11.7042C9.73215 11.4984 8.61135 10.9152 7.76715 10.0524C6.71235 8.9838 6.10935 7.5006 6.08055 6.003C6.02055 3.9768 7.07775 1.953 8.79975 0.8742C9.50535 0.417 10.3136 0.1332 11.141 0Z"
          fill="#1D2129"
        />
        <path
          d="M3.32468 14.9078C4.50728 13.5722 6.01508 12.5234 7.68068 11.8838C8.53988 12.431 9.49508 12.8366 10.4983 13.022C10.8907 13.1198 11.2951 13.1654 11.7001 13.1618C12.1195 13.1576 12.5413 13.1774 12.9583 13.1246C14.0587 12.9554 15.1507 12.6194 16.0909 12.0104C16.1875 11.9636 16.2901 11.8628 16.4041 11.9156C18.7747 12.8456 20.7907 14.6144 22.0639 16.8146C22.6477 17.7674 23.0593 18.8162 23.3773 19.8836C23.6449 20.8106 23.7769 21.7676 23.8819 22.7246C23.0551 22.901 22.2349 23.1038 21.4027 23.2544C20.9017 23.3084 20.4151 23.4524 19.9129 23.4956C19.3921 23.5352 18.8845 23.6726 18.3619 23.6966C17.5009 23.7638 16.6471 23.9078 15.7819 23.9228C15.4075 23.9666 15.0247 23.9102 14.6557 23.9996H9.34508C8.97608 23.9108 8.59268 23.966 8.21828 23.9228C7.29428 23.9048 6.38228 23.7476 5.46188 23.681C5.00048 23.645 4.54808 23.5394 4.08728 23.4956C3.58448 23.4536 3.09788 23.306 2.59628 23.2544C1.76588 23.0978 0.942076 22.9088 0.117676 22.7246C0.154276 22.2476 0.246076 21.7778 0.301876 21.3032C0.351676 20.9192 0.477076 20.5502 0.545476 20.1698C1.05128 18.2348 1.98128 16.397 3.32468 14.9078ZM17.6437 15.7232C17.6365 16.6418 17.6443 17.561 17.6401 18.4796C16.7209 18.4838 15.8023 18.4766 14.8831 18.4826C14.8795 19.001 14.8795 19.52 14.8831 20.0384C15.8011 20.0504 16.7191 20.0318 17.6365 20.0474C17.6497 20.9678 17.6329 21.8882 17.6449 22.8086C18.1813 22.8242 18.7189 22.823 19.2559 22.8092C19.2643 21.9278 19.2535 21.0458 19.2595 20.1644C19.2475 20.0684 19.3657 20.0324 19.4413 20.0414C20.2999 20.0384 21.1579 20.0474 22.0165 20.0384C22.0207 19.5194 22.0207 19.001 22.0165 18.4826C21.0979 18.4766 20.1787 18.4832 19.2595 18.4802C19.2553 17.561 19.2625 16.6418 19.2565 15.7226C18.7189 15.719 18.1813 15.719 17.6437 15.7232Z"
          fill="#1D2129"
        />
      </g>
      <defs>
        <clipPath id="clip0_464_15198">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Employee;


export const EmployeeActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_464_15198)">
        <path
          d="M11.141 0H12.8612C14.4974 0.2424 16.022 1.1832 16.9286 2.5728C17.4452 3.3264 17.732 4.2126 17.8736 5.109C18.0494 6.4926 17.7374 7.9404 16.9784 9.114C16.0364 10.6104 14.366 11.622 12.6038 11.7612C12.0428 11.7522 11.477 11.8002 10.9214 11.7042C9.73215 11.4984 8.61135 10.9152 7.76715 10.0524C6.71235 8.9838 6.10935 7.5006 6.08055 6.003C6.02055 3.9768 7.07775 1.953 8.79975 0.8742C9.50535 0.417 10.3136 0.1332 11.141 0Z"
          fill="white"
        />
        <path
          d="M3.32468 14.9078C4.50728 13.5722 6.01508 12.5234 7.68068 11.8838C8.53988 12.431 9.49508 12.8366 10.4983 13.022C10.8907 13.1198 11.2951 13.1654 11.7001 13.1618C12.1195 13.1576 12.5413 13.1774 12.9583 13.1246C14.0587 12.9554 15.1507 12.6194 16.0909 12.0104C16.1875 11.9636 16.2901 11.8628 16.4041 11.9156C18.7747 12.8456 20.7907 14.6144 22.0639 16.8146C22.6477 17.7674 23.0593 18.8162 23.3773 19.8836C23.6449 20.8106 23.7769 21.7676 23.8819 22.7246C23.0551 22.901 22.2349 23.1038 21.4027 23.2544C20.9017 23.3084 20.4151 23.4524 19.9129 23.4956C19.3921 23.5352 18.8845 23.6726 18.3619 23.6966C17.5009 23.7638 16.6471 23.9078 15.7819 23.9228C15.4075 23.9666 15.0247 23.9102 14.6557 23.9996H9.34508C8.97608 23.9108 8.59268 23.966 8.21828 23.9228C7.29428 23.9048 6.38228 23.7476 5.46188 23.681C5.00048 23.645 4.54808 23.5394 4.08728 23.4956C3.58448 23.4536 3.09788 23.306 2.59628 23.2544C1.76588 23.0978 0.942076 22.9088 0.117676 22.7246C0.154276 22.2476 0.246076 21.7778 0.301876 21.3032C0.351676 20.9192 0.477076 20.5502 0.545476 20.1698C1.05128 18.2348 1.98128 16.397 3.32468 14.9078ZM17.6437 15.7232C17.6365 16.6418 17.6443 17.561 17.6401 18.4796C16.7209 18.4838 15.8023 18.4766 14.8831 18.4826C14.8795 19.001 14.8795 19.52 14.8831 20.0384C15.8011 20.0504 16.7191 20.0318 17.6365 20.0474C17.6497 20.9678 17.6329 21.8882 17.6449 22.8086C18.1813 22.8242 18.7189 22.823 19.2559 22.8092C19.2643 21.9278 19.2535 21.0458 19.2595 20.1644C19.2475 20.0684 19.3657 20.0324 19.4413 20.0414C20.2999 20.0384 21.1579 20.0474 22.0165 20.0384C22.0207 19.5194 22.0207 19.001 22.0165 18.4826C21.0979 18.4766 20.1787 18.4832 19.2595 18.4802C19.2553 17.561 19.2625 16.6418 19.2565 15.7226C18.7189 15.719 18.1813 15.719 17.6437 15.7232Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_464_15198">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
