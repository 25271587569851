import GroupButtons from 'components/GroupButtons';
import appointmentActions from 'features/appointment/services/actions';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { IApiGetAppointments } from 'features/appointment/services/types/api';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment from 'moment';
import React, { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import AppointmentDatePicker from './DatePicker';
const options = [
  {
    label: 'Daily',
    value: CALENDAR_VIEW_TYPE.DAY_VIEW,
  },
  {
    label: 'Weekly',
    value: CALENDAR_VIEW_TYPE.WEEK_VIEW,
  },
  {
    label: 'Monthly',
    value: CALENDAR_VIEW_TYPE.MONTH_VIEW,
  },
];
type ISwitchCalendarTypeProps = any;
const SwitchCalendarType: React.FC<ISwitchCalendarTypeProps> = () => {
  const dispatch = useAppDispatch();
  const [viewType, setViewType] = useState<CALENDAR_VIEW_TYPE>(CALENDAR_VIEW_TYPE.DAY_VIEW);
  const onChange = (val: string) => {
    setViewType(val as CALENDAR_VIEW_TYPE);

    const now = moment();
    let params: IApiGetAppointments | null = null;
    switch (val) {
      case CALENDAR_VIEW_TYPE.DAY_VIEW: {

        params = {
          startTime: now.format(DATE_FORMAT),
          endTime: now.format(DATE_FORMAT)
        };
        break;
      }
      case CALENDAR_VIEW_TYPE.WEEK_VIEW: {
        const startTime = now.startOf('week').format(DATE_FORMAT);
        const endTime = now.endOf('week').format(DATE_FORMAT);

        params = {
          startTime,
          endTime
        };
        break;
      }
      case CALENDAR_VIEW_TYPE.MONTH_VIEW: {
        const startTime = now.startOf('month').format(DATE_FORMAT);
        const endTime = now.endOf('month').format(DATE_FORMAT);

        params = {
          startTime,
          endTime
        };
        break;
      }
      default:
        break;
    }

    if (params)
      dispatch(appointmentActions.setTableParams(params));
  };

  return (
    <>
      <AppointmentDatePicker viewType={viewType} />
      <SwitchCalendarTypeStyled>
        <GroupButtons
          options={options}
          value={viewType}
          onChange={onChange}
        />
      </SwitchCalendarTypeStyled>
    </>
  );
};

export default SwitchCalendarType;
type SwitchCalendarTypeStyledProps = {}
const SwitchCalendarTypeStyled = styled.div<SwitchCalendarTypeStyledProps>``;
