import { Row } from 'antd';
import ModelBox from 'features/checkIn/components/ModelBox';
import PolicyBox from 'features/checkIn/components/PolicyBox';
import React from 'react';
import styled from 'styled-components';
import AlarmClockUI from './AlarmClockUI';
import ModalHelpers from './ModalHelpers';
type IComponentProps = {};
const BackgroundLeft: React.FC<IComponentProps> = () => {
  return (
    <ComponentStyled>
      <ModalHelpers />
      <Row className='clock-wrapper' align={'middle'} justify={'center'}>
        <AlarmClockUI />
      </Row>
      <PolicyBox />
      <ModelBox />
    </ComponentStyled>
  );
};

export default BackgroundLeft;
const ComponentStyled = styled.div`
  position: relative;
  height: 100vh;
  width: 54.5vw;
  .clock-wrapper {
    margin-top: 15vh;
  }
`;
