const GetIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="112"
      height="76"
      viewBox="0 0 112 76"
      fill="none"
    >
      <circle
        cx="74"
        cy="38"
        r="37.5"
        transform="rotate(90 74 38)"
        stroke="#3A0F0F"
      />
      <path
        d="M93.0607 39.0607C93.6464 38.4749 93.6464 37.5251 93.0607 36.9393L83.5147 27.3934C82.9289 26.8076 81.9792 26.8076 81.3934 27.3934C80.8076 27.9792 80.8076 28.9289 81.3934 29.5147L89.8787 38L81.3934 46.4853C80.8076 47.0711 80.8076 48.0208 81.3934 48.6066C81.9792 49.1924 82.9289 49.1924 83.5147 48.6066L93.0607 39.0607ZM-1.31134e-07 39.5L92 39.5L92 36.5L1.31134e-07 36.5L-1.31134e-07 39.5Z"
        fill="#89706D"
      />
    </svg>
  );
};

export default GetIn;
