import React, { useRef } from 'react';
import styled from 'styled-components';
import Box from 'components/Box';
import colorTheme from 'constants/color';
import AddOnList from './AddOnList';
import CateList from './CateList';
import RightDetails from './RightDetails';
import { FormInstance } from 'antd';
import {
  ICategoryItemData,
  IServiceItemData,
} from 'services/shop/types/categories';
import Button from 'components/Button';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { useAppDispatch } from 'store/hooks';
import settingServiceActions from 'features/settingService/services/actions';

type IServicesSettingProps = {};
const ServicesSetting: React.FC<IServicesSettingProps> = () => {
  const dispatch = useAppDispatch();
  const cateFormRef = useRef<FormInstance<ICategoryItemData> | null>(null);
  const addOnFormRef = useRef<FormInstance<IServiceItemData> | null>(null);
  const cateSelected = settingServiceSelectors.getCateSelected();
  const addOnSelected = settingServiceSelectors.getAddOnServiceSelected();

  const handleSubmit = () => {
    if (cateSelected && cateFormRef.current) {
      cateFormRef?.current?.submit();
    } else if (addOnSelected && addOnFormRef.current) {
      addOnFormRef.current.submit();
    }
  };

  const handleResetForm = () => {
    dispatch(settingServiceActions.resetServiceSetting());
    cateFormRef.current?.resetFields();
    addOnFormRef.current?.resetFields();
  };

  return (
    <ServicesSettingStyled>
      <WrapperBoxStyled>
        <CateAddOnBox>
          <ListWrapperStyled>
            <Box bb="line_3" pb="4">
              <CateList />
            </Box>
            <Box mt="2">
              <AddOnList />
            </Box>
          </ListWrapperStyled>
          <ButtonGroupStyled>
            <Box width={'10rem'}>
              <Button block ntype="SECONDARY" onClick={handleResetForm}>
                Reset
              </Button>
            </Box>
            <Box width={'10rem'}>
              <Button block ntype="PRIMARY" onClick={handleSubmit}>
                Save
              </Button>
            </Box>
          </ButtonGroupStyled>
        </CateAddOnBox>
        <RightBox>
          <RightDetails cateFormRef={cateFormRef} addOnFormRef={addOnFormRef} />
        </RightBox>
      </WrapperBoxStyled>
    </ServicesSettingStyled>
  );
};

export default ServicesSetting;
const ServicesSettingStyled = styled.div`
  height: calc(100vh - 3rem);
  background-color: ${colorTheme.fill_3};
  @media only screen and (max-width: 1400px) {
    height: calc(100vh - 3.25rem);
  }
`;
const WrapperBoxStyled = styled.div`
  display: flex;
  padding: 1.5rem 0 1.5rem 1.5rem;
`;

const CateAddOnBox = styled.div`
  position: relative;
  height: calc(100vh - 9rem - 1px);
  background-color: white;
  overflow: auto;
  width: 60%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 1.5rem;
  @media only screen and (max-width: 1400px) {
    height: calc(100vh - 9.25rem - 1px);
  }
  display: flex;
  flex-direction: column;
`;
const RightBox = styled.div`
  height: calc(100vh - 6rem - 1px);
  background-color: transparent;
  overflow: auto;
  width: 40%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  @media only screen and (max-width: 1400px) {
    height: calc(100vh - 6.25rem - 1px);
  }
`;
const ButtonGroupStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1rem;
  margin-top: 0.5rem;
`;
const ListWrapperStyled = styled.div`
  flex-grow: 1;
`;
