import { Card, Row } from 'antd';
import IconAndPrice from 'features/report/components/IconAndPrice';
import { ISummaryDashboardSalaryStaff } from 'features/report/services/types/reportStaff';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

interface IDashboardRow {
    data: ISummaryDashboardSalaryStaff;
}

const labelStyle: CSSProperties = {
    fontSize: '1.4rem',
    fontWeight: '700',
};

const DashboardRow: React.FC<IDashboardRow> = ({ data }) => {

    const getPaidOut = () => {
        const commissions = data.employeeSalaries || 0;
        const tip = data.totalTip || 0;
        const discount = data.totalDiscount || 0;
        return commissions + tip - discount;
    };

    return (
        <StyledDashboardRow style={{ gap: '1.5rem' }} wrap={false}>
            <StyledCard>
                <IconAndPrice
                    title='TOTAL SALES'
                    icon='crossSale'
                    labelStyle={labelStyle}
                    price={data.crossSale ?? 0}
                />
            </StyledCard>
            <StyledCard>
                <IconAndPrice
                    title='SUPPLY FEES'
                    icon='closeTicket'
                    labelStyle={labelStyle}
                    price={data.totalSupplyFee ?? 0}
                />
            </StyledCard>
            <StyledCard>
                <IconAndPrice
                    title='COMMISSIONS'
                    icon='salary'
                    labelStyle={labelStyle}
                    price={data.employeeSalaries ?? 0}
                />
            </StyledCard>
            <StyledCard>
                <IconAndPrice
                    title='DISCOUNTS'
                    icon='discount'
                    labelStyle={labelStyle}
                    price={data.totalDiscount ?? 0}
                />
            </StyledCard>
            <StyledCard>
                <IconAndPrice
                    title='TOTAL TIP'
                    icon='moneyExchange'
                    labelStyle={labelStyle}
                    price={data.totalTip ?? 0}
                />
            </StyledCard>
            <StyledCard>
                <IconAndPrice
                    title='TOTAL PAID OUT'
                    icon='moneyExchange'
                    labelStyle={labelStyle}
                    price={getPaidOut()}
                />
            </StyledCard>
        </StyledDashboardRow>

    );
};

export default DashboardRow;
const StyledDashboardRow = styled(Row)`
    margin: 1rem 0;
`;

const StyledCard = styled(Card)`
    flex: 1;
    border-radius: 3.358px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0.672px solid #86909C;
    background: #FFF;
    height: 100%;
`;