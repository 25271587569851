import appointmentServiceReducer from 'features/appointment/services/reducers';
import authServiceReducer from 'features/auth/services/reducers';
import checkInServiceReducer from 'features/checkIn/services/reducers';
import customerServiceReducer from 'features/customer/services/reducers';
import cashierServiceReducer from 'features/cashier/services/reducers';
import paymentServiceReducer from 'features/payment/services/reducers';
import reportServiceReducer from 'features/report/services/reducers';
import settingServiceReducer from 'features/settingService/services/reducers';
import settingStaffReducer from 'features/settingStaff/services/reducers';
import ticketListServiceReducer from 'features/ticketList/services/reducers';
import turnServiceReducer from 'features/turn/services/reducers';
import userServiceReducer from 'features/user/services/reducers';
import uiServiceReducer from 'services/UI/reducer';
import shopServiceReducer from 'services/shop/reducers';
import multiplePaymentServiceReducer from 'features/payment/pages/PaymentPage/services/reducers';
import giftCardsReducer from 'features/giftCards/services/reducers';

const rootReducer = {
  ui: uiServiceReducer,
  checkIn: checkInServiceReducer,
  user: userServiceReducer,
  cashier: cashierServiceReducer,
  payment: paymentServiceReducer,
  appointment: appointmentServiceReducer,
  settingService: settingServiceReducer,
  settingStaff: settingStaffReducer,
  ticketList: ticketListServiceReducer,
  customer: customerServiceReducer,
  report: reportServiceReducer,
  auth: authServiceReducer,
  shop: shopServiceReducer,
  turn: turnServiceReducer,
  multiplePayment: multiplePaymentServiceReducer,
  giftCards: giftCardsReducer
};

export default rootReducer;
