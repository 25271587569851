import { Col, Form, InputNumber, Row } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IShopDiscountParams } from 'features/settingService/services/types/paymentSetting';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type IDiscountPercentageProps = {};
const DiscountPercentage: React.FC<IDiscountPercentageProps> = () => {
  const dispatch = useAppDispatch();
  const discountStore = settingServiceSelectors.paymentSetting.discount();
  const [form] = Form.useForm<IShopDiscountParams>();

  const onFinish = (values: IShopDiscountParams) => {
    dispatch(settingServiceActions.postDiscount.fetch(values));
  };

  const handleStaffChange = (val: number | null, targetName: string) => {
    form.setFieldsValue({
      [targetName]: 100 - (val || 0),
    });
  };

  useEffect(() => {
    if (discountStore) {
      form.setFieldsValue(discountStore);
    }
  }, [discountStore]);

  return (
    <DiscountPercentageStyled>
      <Form
        form={form}
        layout="vertical"
        initialValues={discountStore}
        onFinish={onFinish}
      >
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Text variant="H9" color="text_3">
            Discount Percent
          </Text>
          <Box>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="discountStaff" label="Staff">
                  <InputNumber
                    onChange={(val) => handleStaffChange(val, 'discountOwner')}
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value?.replace('%', '')}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="discountOwner" label="Company">
                  <InputNumber
                    onChange={(val) => handleStaffChange(val, 'discountStaff')}
                    style={{ width: '100%' }}
                    formatter={(value) => `${value}%`}
                    parser={(value: any) => value?.replace('%', '')}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Box>
          <Box display="flex" gap="3" mt="4" justifyContent="end">
            <Button width="10rem" ntype="SECONDARY" htmlType="reset">
              Reset
            </Button>
            <Form.Item>
              <Button width="10rem" ntype="PRIMARY" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Box>
        </Box>
      </Form>
    </DiscountPercentageStyled>
  );
};

export default DiscountPercentage;
const DiscountPercentageStyled = styled.div`
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  flex-grow: 1;
`;
