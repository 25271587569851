import React from 'react';

const TicketWrapperContext = React.createContext<{
    print: (billId: string, useLoading?: boolean) => Promise<void>;
    completedTicket: (billId: string, useLoading?: boolean) => Promise<void>;
}>({
    print: () => new Promise(() => undefined),
    completedTicket: () => new Promise(() => undefined),
});

export const useTicketWrapper = () => React.useContext(TicketWrapperContext);

export default TicketWrapperContext.Provider;