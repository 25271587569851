import Box from 'components/Box';
import Text from 'components/Text';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IStateDetailPayment } from 'features/payment/services/types/payment';
import { get } from 'lodash';
import { useMemo } from 'react';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IStateDetailPayment | null;
  remaining: number;
};
const DetailPayment = ({ data, remaining }: Props) => {
  const describeCard = useMemo(() => [
    { name: 'Points', price: get(data, [PAYMENT_TYPE.LOYALTY_POINT, 'amount'], 0), },
    { name: 'Gift Card', price: get(data, [PAYMENT_TYPE.GIFT_CARD, 'amount'], 0), },
    { name: 'Check / Other(s)', price: get(data, [PAYMENT_TYPE.CHECK, 'amount'], 0), },
    { name: 'Cash', price: get(data, [PAYMENT_TYPE.CASH, 'amount'], 0), },
    { name: 'Credit Card', price: get(data, [PAYMENT_TYPE.CREDIT_CARD, 'amount'], 0), },
  ], [data]);

  const chance = get(data, [PAYMENT_TYPE.CASH, 'chance'], 0);

  return (
    <div>
      <Box
        px="4"
        py="3"
        display="flex"
        flexDirection="column"
        gap="2"
        bStyle="dotted"
        bb="line_3"
      >
        {describeCard.map((item) => {
          return (
            <Box className="space-between" key={item.name}>
              <Text variant="BODY_1" color="text_3">
                {item.name}
              </Text>
              <Text variant="CONTENT_2" color="text_3">
                {formatCurrency(item.price)}
              </Text>
            </Box>
          );
        })}
      </Box>
      <Box p="2" px='4' className="space-between" bgColor="yellow_headline">
        <Text variant="CONTENT_2" color="text_3">
          Remaining
        </Text>
        <Text variant="H5" color="text_3">
          {remaining >= 0 ? formatCurrency(remaining) : 0}
        </Text>
      </Box>
      <Box p="2" px='4' className="space-between" opacity={chance < 0 ? 1 : 0}>
        <Text variant="CONTENT_2" color="text_3">
          Chance
        </Text>
        <Text variant="H5" color="text_3">
          {formatCurrency(chance)}
        </Text>
      </Box>
    </div>
  );
};

export default DetailPayment;
