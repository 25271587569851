import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import customerActions from '../services/actions';
import customerSelectors from '../services/selectors';
type IDeleteModalButtonProps = {};
const { confirm } = Modal;
const DeleteModalButton: React.FC<IDeleteModalButtonProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customerDetails = customerSelectors.getCustomerDetails();
  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure delete this customer?',
      icon: <ExclamationCircleFilled />,
      content: '',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        dispatch(customerActions.deleteCustomer.fetch(customerDetails?.id));
        navigate(-1);
      },
    });
  };

  return (
    <DeleteModalButtonStyled>
      <Button icon="deleteIcon" ntype="SECONDARY" onClick={showDeleteConfirm}>
        <Text variant="CONTENT_2" color="text_3">
          Delete
        </Text>
      </Button>
    </DeleteModalButtonStyled>
  );
};

export default DeleteModalButton;
const DeleteModalButtonStyled = styled.div``;
