import paymentApis from 'features/payment/services/apis';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useState } from 'react';
import { IResponseDataBody } from 'services/response';

const useGetDetailTicket = (): [IBillDetailData | null, (id: string) => Promise<IBillDetailData | null>] => {
  const [data, setData] = useState<IBillDetailData | null>(null);
  const getDetail = async (id: string) => {
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(id);
      const resData = res?.data?.data;
      if (resData) {
        setData(resData);
        return resData;
      } else
        throw 'fail';

    } catch (error) {
      return null;
    }
  };
  return [data, getDetail];
};

export default useGetDetailTicket;
