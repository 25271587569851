import { Col, Modal, Row } from 'antd';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalaryStaffDetailOneDay_Detail } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import { renderTime } from '../../utils';

interface IModalViewInfo {
  isShow: boolean;
  onClose: () => void;
}

const ModalViewInfo: React.FC<IModalViewInfo> = ({ isShow, onClose }) => {
  const navigate = useNavigate();
  const dataPrintSalaryStaffDetail =
    reportSelectors.getPrintSalaryStaffDetailOneDay();
  const dataAppointment = {
    startTime: moment().format(DATE_FORMAT),
    endTime: moment().format(DATE_FORMAT),
  };
  const RowItem = (item: IPrintSalaryStaffDetailOneDay_Detail) => {
    return (
      <Row style={{ padding: '2px 0px' }}>
        <ColHeader span={3}>
          <Row>
            <Text variant='CAPTION_2' printMode>#{item.ticketNumber}</Text>
          </Row>
          {/* <Row>
            <Text variant='CAPTION_3' printMode >
              {moment(item.createdDate).format('HH:mm A')}
            </Text>
          </Row> */}
        </ColHeader>
        <ColHeader span={9}>
          {item.itemNames.map((itemName, index) => {
            return (
              <Row
                key={index + '_' + itemName.toString()}
                style={{ justifyContent: 'start' }}
              >
                <Text variant='CAPTION_3' printMode textAlign='left'>{itemName}</Text>
              </Row>
            );
          })}
        </ColHeader>
        <ColHeader span={7} style={{ textAlign: 'start' }}>
          <Text variant='CAPTION_3' printMode textAlign='left'>{formatCurrency(item.price)}</Text>
        </ColHeader>
        <ColHeader span={5} style={{ textAlign: 'end' }}>
          <Text variant='CAPTION_3' printMode textAlign='right'>{formatCurrency(item.tip)}</Text>
        </ColHeader>
      </Row>
    );
  };
  const divRef = useRef<HTMLDivElement>(null);

  const onPrint = useReactToPrint({
    content: () => divRef.current,
    copyStyles: true,
  });

  const onViewDetail = () => {
    onClose();
    navigate(
      `/store/${storage.shop_id.get()}/report/employee-salary/staff/${dataPrintSalaryStaffDetail.staffId
      }`
    );
  };

  return (
    <Modal
      open={isShow}
      footer={<>
        <Button size='small' ntype="DEFAULT" icon="print" onClick={onPrint}>
          Print
        </Button>
        <Button size='small' ntype="LIGHT_BLUE" onClick={onViewDetail}>
          View Detail
        </Button>
      </>}
      onCancel={onClose}
      className='modal-max-height-unset'
      closeIcon={null}
      width={350}
      bodyStyle={{
        margin: 0,
        padding: 0,
        borderRadius: 25,
        minHeight: 300,
      }}
    >
      <Box className="center" overflow="auto">
        <TicketBox>
          <PrintArea ref={divRef}>
            <Box
              className="center"
              overflow="auto"
              display="flex"
              flexDirection="column"
            >
              <LogoBox>
                <IconLogo />
              </LogoBox>
              <Box className="center" width={'100%'}>
                <TextDate variant="CONTENT_2" printMode >
                  {renderTime(dataAppointment)}
                </TextDate>
              </Box>
              <Box className="center" width={'100%'} highlight >
                <Text
                  variant="CAPTION_1"
                  printMode
                  style={{ paddingTop: '2.5px' }}
                  className='title-highlight'

                >
                  {dataPrintSalaryStaffDetail.staffName}
                </Text>
              </Box>
              <Box width={'100%'}>
                <Header>
                  <ColHeader span={3}>No.</ColHeader>
                  <ColHeader span={9} style={{ textAlign: 'center' }}>
                    ITEMS
                  </ColHeader>
                  <ColHeader span={7} style={{ textAlign: 'start' }}>
                    PRICE
                  </ColHeader>
                  <ColHeader span={5} style={{ textAlign: 'end' }}>
                    TIPS
                  </ColHeader>
                </Header>
                {dataPrintSalaryStaffDetail?.details?.map((m) => RowItem(m))}
                <Total>
                  <ColHeader span={8}>
                    <Text variant="CAPTION_2" printMode >
                      TOTAL SALES
                    </Text>
                  </ColHeader>
                  <ColHeader span={16} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end' }}>
                    <Text variant="CAPTION_2" printMode>
                      {formatCurrency(
                        dataPrintSalaryStaffDetail.totalSales
                      )}
                    </Text>
                  </ColHeader>
                </Total>
                <Discount>
                  <ColHeader span={16}><Text printMode variant='CAPTION_2' >SUPPLY FEE</Text></ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(dataPrintSalaryStaffDetail?.supplyFee)}</Text></ColHeader>
                </Discount>
                <Discount>
                  <ColHeader span={16}>
                    <Text variant="CAPTION_2" printMode>
                      COMMISSION
                    </Text>
                  </ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}>
                    <Text variant="CAPTION_2" printMode>
                      {formatCurrency(dataPrintSalaryStaffDetail.totalCommission)}
                    </Text>
                  </ColHeader>
                </Discount>
                <Discount>
                  <ColHeader span={16}>
                    <Text variant="CAPTION_2" printMode>
                      TIP
                    </Text>
                  </ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}>
                    <Text variant="CAPTION_2" printMode>
                      {formatCurrency(dataPrintSalaryStaffDetail.totalTip)}
                    </Text>
                  </ColHeader>
                </Discount>
                <Discount>
                  <ColHeader span={16}>
                    <Text variant="CAPTION_2" printMode>
                      DISCOUNT
                    </Text>
                  </ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}>
                    <Text variant="CAPTION_2" printMode>
                      {formatCurrency(dataPrintSalaryStaffDetail.staffDiscount)}
                    </Text>
                  </ColHeader>
                </Discount>
                <Discount style={{ borderBottom: 'unset' }}>
                  <ColHeader span={16}><Text printMode variant='CAPTION_2' >PAYROLL</Text></ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(dataPrintSalaryStaffDetail?.totalSalary)}</Text></ColHeader>
                </Discount>
              </Box>
            </Box>
          </PrintArea>
        </TicketBox>
      </Box>
    </Modal>
  );
};
export default ModalViewInfo;

const TicketBox = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
`;
const PrintArea = styled.div`
  background: #fff;
  height: fit-content;
  width: 72mm;
  padding: 0px 5px;
  margin: 0 auto;
`;
const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32.292px;
  gap: 4.348px;
  flex-shrink: 0;
`;
const Header = styled(Row)`
  border-top: 1px solid black;
  padding: 2.5px 0px;
  border-bottom: 1px dashed black;
`;

const ColHeader = styled(Col)`
  color: #767676;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Total = styled(Header)`
  border-bottom: 1px solid black;
`;

const Discount = styled(Header)`
  border-top: unset;
`;

const TextDate = styled(Text)`
  color: var(--text-text-3, black);
  text-align: center;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: normal !important;
  letter-spacing: 1px !important;
`;
