const BackSpaceIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3033 6L25.6955 6L25.7 6.00001C26.4415 6.00333 27.1518 6.29938 27.6761 6.82374C28.2005 7.34811 28.4966 8.05835 28.4999 8.7999L28.4999 8.80437V23.1956L28.4999 23.2001C28.4966 23.9417 28.2005 24.6519 27.6761 25.1763C27.1518 25.7006 26.4415 25.9967 25.7 26L25.6955 26H10.3039C9.90442 25.9998 9.50965 25.9142 9.14605 25.7488C8.78246 25.5835 8.45846 25.3422 8.19582 25.0413C8.15656 24.9963 8.12164 24.9481 8.09133 24.8975L2.56189 16.5523C2.34007 16.2176 2.34004 15.7826 2.56183 15.4478L8.1162 7.06275C8.14091 7.02545 8.16809 6.98985 8.19757 6.95619C8.46032 6.65615 8.78408 6.41565 9.14722 6.25075C9.51036 6.08586 9.90451 6.00037 10.3033 6ZM10.3048 8C10.1907 8.00016 10.078 8.02463 9.97413 8.0718C9.88651 8.11159 9.8069 8.16674 9.739 8.23451L4.59505 15.9999L9.74256 23.7687C9.80957 23.835 9.88791 23.8891 9.97404 23.9283C10.0779 23.9755 10.1906 24 10.3047 24H25.6928C25.9064 23.9986 26.1109 23.9131 26.2619 23.762C26.413 23.611 26.4985 23.4065 26.4999 23.1929V8.80712C26.4985 8.59352 26.413 8.38903 26.2619 8.23796C26.1109 8.0869 25.9064 8.00143 25.6928 8H10.3048Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.249 11.3135C22.6395 11.704 22.6395 12.3372 22.249 12.7277L14.1234 20.8534C13.7328 21.2439 13.0997 21.2439 12.7092 20.8534C12.3186 20.4628 12.3186 19.8297 12.7092 19.4391L20.8348 11.3135C21.2253 10.923 21.8585 10.923 22.249 11.3135Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7092 11.3135C13.0997 10.923 13.7328 10.923 14.1234 11.3135L22.249 19.4391C22.6395 19.8297 22.6395 20.4628 22.249 20.8534C21.8585 21.2439 21.2253 21.2439 20.8348 20.8534L12.7092 12.7277C12.3186 12.3372 12.3186 11.704 12.7092 11.3135Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.249 11.3135C22.6395 11.704 22.6395 12.3372 22.249 12.7277L14.1234 20.8534C13.7328 21.2439 13.0997 21.2439 12.7092 20.8534C12.3186 20.4628 12.3186 19.8297 12.7092 19.4391L20.8348 11.3135C21.2253 10.923 21.8585 10.923 22.249 11.3135Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7092 11.3135C13.0997 10.923 13.7328 10.923 14.1234 11.3135L22.249 19.4391C22.6395 19.8297 22.6395 20.4628 22.249 20.8534C21.8585 21.2439 21.2253 21.2439 20.8348 20.8534L12.7092 12.7277C12.3186 12.3372 12.3186 11.704 12.7092 11.3135Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default BackSpaceIcon;
