const Tick = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="14" cy="14" r="14" fill="#FF8890" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9939 7.43558C22.3056 7.70834 22.3372 8.18216 22.0644 8.49389L11.5644 20.4939C11.4277 20.6501 11.2324 20.7427 11.025 20.7496C10.8175 20.7565 10.6164 20.6771 10.4697 20.5303L5.96967 16.0303C5.67678 15.7374 5.67678 15.2626 5.96967 14.9697C6.26256 14.6768 6.73744 14.6768 7.03033 14.9697L10.9635 18.9028L20.9356 7.50613C21.2083 7.1944 21.6822 7.16282 21.9939 7.43558Z"
        fill="white"
      />
    </svg>
  );
};

export default Tick;
