import { useSocketContext } from 'hooks/useSocket';
import { debounce, set } from 'lodash';
import { forwardRef, memo, useEffect, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import multiplePaymentActions from '../services/actions';
import multiplePaymentSelectors from '../services/selectors';
import { ICustomerPaymentDetail } from '../services/types/customer';
import { PaymentSocketData, TypePaymentActors, TypePaymentPassData } from '../services/types/socketPayment';

type Props = {
  onDone: () => void;
  remaining: number;
};
export type PassDataPaymentRef = {
  requestSignature: () => void;
  requestCheckInfoSignature: (data: any) => void;
};
const PassDataPayment = forwardRef<PassDataPaymentRef, Props>(({ onDone, remaining }, ref) => {
  const dispatch = useAppDispatch();
  const detailTicket = multiplePaymentSelectors.getDetail();
  const detailPayment = multiplePaymentSelectors.getDetailPayment();
  const activePayment = multiplePaymentSelectors.getActivePayment();
  const { id = '' } = useParams();
  const cashierMsg = multiplePaymentSelectors.getCashierSocketMsg();

  const socketContext = useSocketContext();

  const sendingData = (type: TypePaymentPassData, data?: any) => {
    if (!socketContext.isConnect()) return;
    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detailTicket?.billId || '',
      actor: TypePaymentActors.CASHIER,
    };
    switch (type) {
      case TypePaymentPassData.PASS_DATA: {
        set(body, 'action', TypePaymentPassData.PASS_DATA);
        set(body, 'data', {
          cash: detailPayment.CASH?.amount || 0,
          remaining: remaining || 0,
          chanceCash: detailPayment.CASH?.chance || 0,
          loyalty: detailPayment.LOYALTY_POINT?.amount || 0,
          giftCard: detailPayment.GIFT_CARD?.amount || 0,
          checkOthers: detailPayment.CHECK?.amount || 0,
          creditCard: detailPayment.CREDIT_CARD?.amount || 0,
        } as ICustomerPaymentDetail);
        break;
      }
      case TypePaymentPassData.REQUEST_SIGNATURE: {
        set(body, 'action', TypePaymentPassData.REQUEST_SIGNATURE);
        break;
      }
      case TypePaymentPassData.REQUEST_SIGNATURE_CHECK: {
        set(body, 'action', TypePaymentPassData.REQUEST_SIGNATURE_CHECK);
        set(body, 'data', data);
        break;
      }
      default:
        break;
    }

    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
  };

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData = JSON.parse(message.body);
    if (!payment) return;

    if (payment.shopId !== storage.shop_id.get()) return;

    if (payment.actor !== TypePaymentActors.CUSTOMER) return;


    switch (payment.action) {
      case TypePaymentPassData.COMPLETED_PAYMENT: {
        onDone();
        break;
      }
      case TypePaymentPassData.SEND_INFO_SIGNATURE_CHECK: {
        dispatch(multiplePaymentActions.setDraftCheckSignature.fetch(payment.data));
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (!cashierMsg) return;
    listeningData(cashierMsg);
  }, [cashierMsg]);

  const passPaymentData = debounce(() => sendingData(TypePaymentPassData.PASS_DATA), 200);

  useEffect(() => passPaymentData(), [detailPayment, detailTicket, activePayment]);

  useEffect(() => {
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/${id}`);
  }, [id]);

  useImperativeHandle(ref, () => ({
    requestSignature: () => sendingData(TypePaymentPassData.REQUEST_SIGNATURE),
    requestCheckInfoSignature: (data: any) => sendingData(TypePaymentPassData.REQUEST_SIGNATURE_CHECK, data),
  }));

  return null;
});
PassDataPayment.displayName = 'PassDataPayment';
export default memo(PassDataPayment);