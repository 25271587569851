import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { APPOINTMENT_LAYOUT, AllAppointmentStatus, CALENDAR_DISTANCE_TIMELINE, CALENDAR_ITEM_TYPES, CALENDAR_VIEW_TYPE, NAME_REDUCER, PROLONGED_TIME_STATUS } from './constants';
import { IState } from './types/reducer';

import authActions from 'features/auth/services/actions';
import moment from 'moment';
import shopActions from 'services/shop/actions';
import { momentTimezone } from 'utils/time';
import { DATE_FORMAT } from '../utils/format';
import getRangeDates from '../utils/getRangeDates';

const initialState: IState = {
  params: {
    startTime: moment().format(DATE_FORMAT),
    endTime: moment().format(DATE_FORMAT),
  },
  appointmentList: [],
  dateRangeList: [],
  distanceTimeline: CALENDAR_DISTANCE_TIMELINE.QUARTER_HOUR,
  viewType: CALENDAR_VIEW_TYPE.DAY_VIEW,
  lockBreakTimes: [],
  appointmentLayout: APPOINTMENT_LAYOUT.CALENDAR,
  selectedCalendarItemTypes: Object.values(CALENDAR_ITEM_TYPES),
  appointmentStatus: AllAppointmentStatus,
  staffListPage: 1,
  staffTotalPage: 1,
  detail: {
    data: null,
  },
  newAppointmentDraftData: null,
  newBlockHourDraftData: null,
  newBreakTimeDraftData: null,
  draftAppointmentData: null,
  table: {
    params: {
      startTime: moment().format(DATE_FORMAT),
      endTime: moment().format(DATE_FORMAT),
    },
    data: [],
    pagination: null,
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setAppointmentStatusFilter, (state, { payload }) => {
        state.appointmentStatus = payload;
      })
      .addCase(shopActions.get.staffs.success, (state, { payload }) => {
        const length = payload?.length || 0;
        state.staffTotalPage = length ? Math.ceil(length / 8) : 1;
      })
      .addCase(actions.addMoreTimeAppointment, (state, { payload }) => {
        state.appointmentList = state.appointmentList.map(o => {
          if (o.appointmentId === payload.appointmentId) {
            const endTime = momentTimezone(o.endTime);
            if (payload.status === PROLONGED_TIME_STATUS.ADD) {
              endTime.add(15, 'minutes');
            } else {
              endTime.subtract(15, 'minutes');
            }
            return ({
              ...o,
              endTime: endTime.format('MM-DD-YYYY HH:mm:ss')
            });
          }
          return o;
        });
      })
      .addCase(actions.updateAppointmentWithDrop, (state, { payload }) => {
        state.appointmentList = state.appointmentList.map(o => {
          if (o.appointmentId === payload.appointmentId) {
            const endTime = momentTimezone(payload.startTime).add(payload.distance, 'minutes');
            return ({
              ...o,
              staffId: payload.staffId || o.staffId,
              startTime: payload.startTime,
              endTime: endTime.format('MM-DD-YYYY HH:mm:ss')
            });
          }
          return o;
        });
      })
      .addCase(actions.setDraftAppointmentDetail, (state, { payload }) => {
        state.draftAppointmentData = payload;
      })
      .addCase(actions.setNewBreakTimeDraftData, (state, { payload }) => {
        state.newBreakTimeDraftData = payload;
      })
      .addCase(actions.setNewBlockHourDraftData, (state, { payload }) => {
        state.newBlockHourDraftData = payload;
      })
      .addCase(actions.setNewAppointmentDraftData, (state, { payload }) => {
        state.newAppointmentDraftData = payload;
      })
      .addCase(actions.increaseStaffPage, (state) => {
        const nextPage = state.staffListPage + 1;
        if (nextPage > state.staffTotalPage) return;
        state.staffListPage = nextPage;
      })
      .addCase(actions.decreaseStaffPage, (state) => {
        if (state.staffListPage > 0) {
          state.staffListPage = state.staffListPage - 1;
        }
      })
      .addCase(actions.setAppointmentDetail, (state, { payload }) => {
        state.detail.data = payload;
      })
      .addCase(actions.setCalendarViewType, (state, { payload }) => {
        state.viewType = payload;
      })
      .addCase(actions.setSelectedCalendarItemTypes, (state, { payload }) => {
        state.selectedCalendarItemTypes = payload;
      })
      .addCase(actions.setAppointmentLayout, (state, { payload }) => {
        state.appointmentLayout = payload;
      })
      .addCase(actions.getLockBreakTimes.success, (state, { payload }) => {
        state.lockBreakTimes = payload;
      })
      .addCase(actions.getAppointments.success, (state, { payload }) => {
        state.appointmentList = payload;
      })
      .addCase(actions.setParams, (state, { payload }) => {
        const _params = {
          ...state.params ?? {},
          ...payload ?? {},
        };
        state.params = _params;
        state.dateRangeList = getRangeDates(_params?.startTime, _params?.endTime, DATE_FORMAT);
        // state.appointmentList = [];
        // state.lockBreakTimes = [];
      })
      .addCase(actions.setTableParams, (state, { payload }) => {
        const _params = {
          ...state.table.params ?? {},
          ...payload ?? {},
        };
        if (_params.staffId === undefined) delete _params.staffId;
        state.table.params = _params;
      })
      .addCase(actions.getTableAppointments.success, (state, { payload }) => {
        state.table.data = payload;
      })
      .addCase(authActions.ownerLogout, (state) => {
        state.params = {
          startTime: moment().format(DATE_FORMAT),
          endTime: moment().format(DATE_FORMAT),
        };
        state.appointmentList = [];
        state.dateRangeList = [];
        state.distanceTimeline = CALENDAR_DISTANCE_TIMELINE.QUARTER_HOUR;
        state.viewType = CALENDAR_VIEW_TYPE.DAY_VIEW;
        state.lockBreakTimes = [];
        state.appointmentLayout = APPOINTMENT_LAYOUT.CALENDAR;
        state.selectedCalendarItemTypes = Object.values(CALENDAR_ITEM_TYPES);
        state.staffListPage = 1;
        state.staffTotalPage = 1;
        state.detail = {
          data: null,
        };
        state.newAppointmentDraftData = null;
        state.newBlockHourDraftData = null;
        state.newBreakTimeDraftData = null;
        state.draftAppointmentData = null;
        state.table = {
          params: {
            startTime: moment().format(DATE_FORMAT),
            endTime: moment().format(DATE_FORMAT),
          },
          data: [],
          pagination: null,
        };
      })
      ;
  },
});

const appointmentServiceReducer = Slice.reducer;
export default appointmentServiceReducer;
