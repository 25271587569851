import Sidebar from 'components/layout/Sidebar';
import userActions from 'features/user/services/actions';
import moment from 'moment';
import React, { useEffect } from 'react';
import uiActions from 'services/UI/actions';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import Main from './components/Main';
import ClockInClockOutPageStyled from './styles';
import { useSocketContext } from 'hooks/useSocket';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
type IClockInClockOutPageProps = any;
const ClockInClockOutPage: React.FC<IClockInClockOutPageProps> = () => {
  const dispatch = useAppDispatch();
  const socket = useSocketContext();

  useEffect(() => {
    dispatch(userActions.doFilterData({}));
    dispatch(userActions.getWaitingList.fetch());
    dispatch(userActions.getBillWaitingTicket.fetch());
    dispatch(shopActions.get.staffsAvailable.fetch());
    dispatch(multiplePaymentActions.customerSide.setCustomerMsg.fetch(null));
    socket.unsubscribePassData();

    const countdown = setInterval(() => {
      dispatch(uiActions.setCurrentTime(moment().format()));
    }, 5000);
    return () => {
      clearInterval(countdown);
    };
  }, []);

  return (
    <ClockInClockOutPageStyled>
      <Sidebar />
      <Main />
    </ClockInClockOutPageStyled>
  );
};

export default ClockInClockOutPage;

