import { Col, Row } from 'antd';
import Text from 'components/Text';
import { IAppointmentServiceItem } from 'features/appointment/services/types/appointment';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import DropDownSelectStaff from 'widgets/Staff/DropDown';
type IServiceItemProps = {
  staffId?: string;
  data: IAppointmentServiceItem;
  onPickStaff: (val: IStaffItemData) => void;
  onRemove: () => void;
};
const ServiceItem: React.FC<IServiceItemProps> = ({ staffId, data, onPickStaff, onRemove }) => {
  return (
    <ServiceItemStyled wrap={false}>
      <Col flex={'auto'}>
        <Text variant='H9' color='text_3' className='text-overflow' pr={.5} >{data.serviceName}</Text>
        <Text variant='H9' color='text_2' >({data.duration}m)</Text>
      </Col>
      <Col>
        <DropDownSelectStaff
          value={staffId}
          onChange={onPickStaff}
        />
      </Col>
      <Col className='col-delete'>
        <button type='button' onClick={onRemove}><IconClear /></button>
      </Col>
    </ServiceItemStyled>
  );
};

export default ServiceItem;
type ServiceItemStyledProps = {}
const ServiceItemStyled = styled(Row) <ServiceItemStyledProps>`
  background: var(--fill-fill-1, #F6F7FC);
  padding: 0.5rem 1.5rem;
  width: 100%;
  .col-delete {
    align-items: center;
    display: flex;
    margin-left: 10px;
    button {
      width: 30px;
      display: flex;
      align-self: center;
      svg {
        height: 100%;
        width: 100%;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.10);
      }
    }
  }
`;

const IconClear = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M40.365 7.63454C41.2688 8.53835 41.2688 10.0037 40.365 10.9075L10.9082 40.3643C10.0044 41.2681 8.53902 41.2681 7.63521 40.3643C6.7314 39.4605 6.7314 37.9952 7.63521 37.0914L37.092 7.63454C37.9958 6.73073 39.4612 6.73073 40.365 7.63454Z" fill="#FF8890" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.63499 7.63454C8.5388 6.73073 10.0042 6.73073 10.908 7.63454L40.3648 37.0914C41.2686 37.9952 41.2686 39.4605 40.3648 40.3643C39.461 41.2681 37.9956 41.2681 37.0918 40.3643L7.63499 10.9075C6.73119 10.0037 6.73119 8.53835 7.63499 7.63454Z" fill="#FF8890" />
  </svg>
);
