import React from 'react';
import { Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import Text from 'components/Text';
import Box from 'components/Box';
type ISalaryPerTypesProps = {};
const SalaryPerTypes: React.FC<ISalaryPerTypesProps> = () => {
  const form = Form.useFormInstance();

  const handleCashChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let cashValue = Number(e.target.value);
    if (cashValue > 100) cashValue = 100; // Limiting max value to 100
    const checkValue = 100 - cashValue;
    form.setFieldsValue({
      perTypeCheck: checkValue,
    });
  };
  const handleCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let checkValue = Number(e.target.value);
    if (checkValue > 100) checkValue = 100; // Limiting max value to 100
    const cashValue = 100 - checkValue;
    form.setFieldsValue({
      perTypeCash: cashValue,
    });
  };

  return (
    <SalaryPerTypesStyled>
      <Row gutter={[16, 16]}>
        <Col flex={1}>
          <Form.Item
            required={true}
            name="perTypeCash"
            label={
              <Box display="flex" gap="1">
                % Cash <Text color="error_6">(*)</Text>
              </Box>
            }
            rules={[
              {
                required: true,
                message: 'Missing cash',
              },
              () => ({
                validator(_, value) {
                  if (!value || (value >= 0 && value <= 100)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Cash should be between 0 and 100')
                  );
                },
              }),
            ]}
          >
            <Input type="number" onChange={handleCashChange} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item
            required={true}
            name="perTypeCheck"
            label={
              <Box display="flex" gap="1">
                % Check<Text color="error_6">(*)</Text>
              </Box>
            }
            rules={[
              {
                required: true,
                message: 'Missing check',
              },
              () => ({
                validator(_, value) {
                  if (!value || (value >= 0 && value <= 100)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Check should be between 0 and 100')
                  );
                },
              }),
            ]}
          >
            <Input type="number" onChange={handleCheckChange} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <Form.Item name="special_fee" label="Special Fee">
            <Input type="number" />
          </Form.Item>
        </Col>
      </Row>
    </SalaryPerTypesStyled>
  );
};

export default SalaryPerTypes;
const SalaryPerTypesStyled = styled.div`
  padding: 1rem;
  background: var(--fill-fill-1, #f6f7fc);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1rem;
`;
