
const IconClockIn = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.5 2.5L15.5 2.5L15.5 4.5L4.5 4.5L4.5 19.5L15.5 19.5L15.5 21.5L2.5 21.5L2.5 2.5ZM16.6714 6.75789L21.914 12.0005L16.6714 17.2432L15.2571 15.829L18.0861 13L8.42871 13L8.42871 11L18.085 11L15.2571 8.1721L16.6714 6.75789Z" fill="#1D2129" />
    </svg>
  );
};

export default IconClockIn;
