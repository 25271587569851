import { Col, InputNumber, Row } from 'antd';
import AmountKeyboard, { IAmountKeyboard } from 'components/NumberKeyBoard/Amount';
import Text from 'components/Text';
import { IDiscountTicketFormValue } from 'features/cashier/services/types/discount';
import React, { memo, useEffect, useState, useTransition } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
type IManualFormProps = {
  setSoftDiscount: (val: IDiscountTicketFormValue | null) => void;
};
const ManualForm: React.FC<IManualFormProps> = () => {
  const [amount, setAmount] = useState<IAmountKeyboard['value']>('');
  const [staffPercent, setStaffPercent] = useState(0);
  const [ownerPercent, setOwnerPercent] = useState(0);
  const discountSetting = shopSelectors.data.discountSetting();

  const [, startTransition] = useTransition();

  useEffect(() => {
    if (!discountSetting) return;
    const { discountOwner, discountStaff } = discountSetting;
    startTransition(() => {
      setStaffPercent(discountStaff);
      setOwnerPercent(discountOwner);
    });
  }, [discountSetting]);

  return (
    <ManualFormStyled>
      <AmountKeyboard
        value={amount}
        onChangeNoneDebounce={setAmount}
      />
      <Text mb={1} />
      <Row wrap={false} style={{ gap: 24 }}>
        <PercentInput label='Staff' value={staffPercent} onChange={setStaffPercent} />
        <PercentInput label='Company' value={ownerPercent} onChange={setOwnerPercent} />
      </Row>
    </ManualFormStyled>
  );
};

export default memo(ManualForm);
type ManualFormStyledProps = {}
export const ManualFormStyled = styled.div<ManualFormStyledProps>``;

export const InputNumberStyled = styled(Col)`
display: flex;
flex-direction: row;
align-items: center;
.ant-input-number {
  width: 100%;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input-wrap {
  height: 100%;
  input {
    height: 100%;
    color: var(--text-text-3, #1D2129);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.label {
  color: var(--text-text-3, #1D2129);
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 1.5rem;
}
`;
export const PercentInput = ({
  value, onChange, label,
}: { label: string, value: any, onChange: (val: any) => void }) => {
  return (
    <InputNumberStyled flex={'auto'}>
      <div className='label'>{label}</div>
      <InputNumber
        defaultValue={100}
        min={0}
        max={100}
        value={value}
        formatter={(value) => `${value}%`}
        parser={(value: any) => value?.replace('%', '') || ''}
        onChange={onChange}
      />
    </InputNumberStyled>
  );
};

