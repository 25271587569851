const ArrowLeft = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Arrow--right">
        <path id="Vector" d="M10.5 19.5L11.5725 18.4552L5.8875 12.75L21 12.75L21 11.25L5.8875 11.25L11.5725 5.57025L10.5 4.5L3 12L10.5 19.5Z" fill="#19161D" />
      </g>
    </svg>

  );
};

export default ArrowLeft;
