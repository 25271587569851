import Text from 'components/Text';
import checkInActions from 'features/checkIn/services/actions';
import checkInApis from 'features/checkIn/services/apis';
import checkInSelectors from 'features/checkIn/services/selectors';
import { IAppointmentCustomerDetail } from 'features/checkIn/services/types/customer';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import toast from 'utils/toast';
import iconCalendar from '../../../images/icon-calendar.png';
import ButtonWalkIn from './ButtonWalkIn';
type IMainProps = {};
const Main: React.FC<IMainProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customerId = checkInSelectors.getCheckedInData().data?.id;
  const { shop_id } = useParams();
  const setLoadingPage = useSetLoadingPage();

  const handleChoseAppointment = async () => {
    if (!customerId) return;
    setLoadingPage(true);
    try {
      const resData: IResponseDataBody<IAppointmentCustomerDetail> = await checkInApis.getAppointmentDetail(customerId);
      const detail = resData?.data?.data;
      if (detail) {
        dispatch(checkInActions.getAppointmentDetail.success(detail));
        if (!detail?.items?.length) {
          throw 'fail';
        } else {
          navigate(`/store/${shop_id}/check-in/appointments`);
        }
      } else {
        throw 'fail';
      }
    } catch (error) {
      toast.info('not available appointment, please select services!');
      navigate(`/store/${storage.shop_id.get()}/check-in/services`);
    } finally {
      setLoadingPage(false);
    }

  };

  return (
    <MainStyled>
      <div className="body">
        <div className="box walkIn">
          <Text variant="H2" className="title">
            WALK - IN
          </Text>
          <ButtonWalkIn />
        </div>
        <div className="box appointment">
          <Text variant="H2" className="title">
            APPOINTMENT
          </Text>
          <button className="icon" onClick={handleChoseAppointment}>
            <img src={iconCalendar} />
          </button>
        </div>
      </div>
    </MainStyled>
  );
};

export default Main;
const MainStyled = styled.div`
display: flex;
justify-content: center;
align-items: center;
height:100vh;
.body {
  display:flex;
  height:100%;
  width: 100%;
}
.box {
  flex:1;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.walkIn {
    background: var(--fill-fill-3, #E5E5E5);
  }
  .title {
    margin-bottom: 3rem;
  }
  .icon {
    border-radius: 100%;
    background: #FFF;
    height: 25rem;
    width: 25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: -5px 5px 8px 0px rgba(0, 0, 0, 0.10) inset;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
    img {
      width: 60%;
    }
    margin-bottom: 5rem;
  }
  button {
    width: 25rem;
  }
}
`;
