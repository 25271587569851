import { Popover } from 'antd';
import Icon from 'assets/Icons';
import { CALENDAR_ITEM_TYPES } from 'features/appointment/services/constants';
import { ICalendarHeader, ICalendarItemData } from 'features/appointment/services/types/calendar';
import { get } from 'lodash';
import React, { useMemo } from 'react';
import { styled } from 'styled-components';
import Appointment from './Appointment';
import BlockHour from './BlockHour';
import BreakStaff from './BreakStaff';
import MultiAppointment from './MultiAppointment';
type ICalendarItemProps = {
  data: ICalendarItemData[];
  headerData?: ICalendarHeader;
  isMonthView?: boolean;
  dateOfMonth?: string;
};
const CalendarItem: React.FC<ICalendarItemProps> = ({ data, headerData, isMonthView, dateOfMonth }) => {
  const appointments = useMemo(() => data.filter(o => o.type === CALENDAR_ITEM_TYPES.APPOINTMENT), [data]);

  const lockTimes = useMemo(() => data.filter(o => o.type === CALENDAR_ITEM_TYPES.BLOCK_HOURS), [data]);
  const breakStaffs = useMemo(() => data.filter(o => o.type === CALENDAR_ITEM_TYPES.BREAK_STAFF), [data]);

  const content = useMemo(() => {
    return (
      <ContentStyled>
        {lockTimes?.length > 0 ? <BlockHour data={lockTimes} isMonthView={isMonthView} /> : null}
        {breakStaffs?.length > 0 ? <BreakStaff data={breakStaffs} isMonthView={isMonthView} /> : null}
      </ContentStyled>
    );
  }, [lockTimes, breakStaffs]);

  const contentBreakTime = useMemo(() => {
    if (!breakStaffs?.length) return null;
    return (
      <ContentStyled>
        <BreakStaff data={breakStaffs} isMonthView={isMonthView} />
      </ContentStyled>
    );
  }, [breakStaffs]);

  const Appointments = () => (
    appointments.length > 1 ?
      <MultiAppointment
        headerData={headerData}
        data={appointments}
        isMonthView={isMonthView}
        dateOfMonth={dateOfMonth}
      /> : <Appointment
        headerData={headerData}
        data={get(appointments, [0])}
        isMonthView={isMonthView}
        dateOfMonth={dateOfMonth}
      />
  );

  if (appointments?.length > 0) {
    return <CalendarItemStyled isMonthView={isMonthView}>
      {(lockTimes.length > 0 || breakStaffs.length > 0) ? <div className="top">
        <Popover content={content} trigger={['click']}>
          <button><Icon type='exclamationCircle' /></button>
        </Popover>
      </div> : null}
      <Appointments />
    </CalendarItemStyled>;
  }

  if (lockTimes?.length > 0) {
    return <CalendarItemStyled isMonthView={isMonthView}>
      {(breakStaffs.length > 0) ? <div className="top">
        <Popover content={contentBreakTime} trigger={['click']}>
          <button><Icon type='exclamationCircle' /></button>
        </Popover>
      </div> : null}
      {lockTimes?.length > 0 ? <BlockHour data={lockTimes} isMonthView={isMonthView} dateOfMonth={dateOfMonth} /> : null}
    </CalendarItemStyled>;
  }

  return <CalendarItemStyled isMonthView={isMonthView}>
    {lockTimes?.length > 0 ? <BlockHour data={lockTimes} isMonthView={isMonthView} dateOfMonth={dateOfMonth} /> : null}
    {breakStaffs?.length > 0 ? <BreakStaff data={breakStaffs} isMonthView={isMonthView} dateOfMonth={dateOfMonth} /> : null}
  </CalendarItemStyled>;
};

export default CalendarItem;
type CalendarItemStyledProps = {
  isMonthView?: boolean;
};
const CalendarItemStyled = styled.div<CalendarItemStyledProps>`
  height: 100%;
  width: 100%;
  position:relative;
  .top {
    position: absolute;
    top: -10px;
    right:-10px;
    z-index: 1;
  }
`;

const ContentStyled = styled.div`
  display:flex;
  flex-direction: column;
  gap:12px;
  height: 8rem;
  width: 15rem;
  margin-bottom: 1rem;
`;