import { Form, InputNumber, Select } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
const { Option } = Select;
type ILateForWork = {};
const LateForWork: React.FC<ILateForWork> = () => {
  const dispatch = useAppDispatch();
  const reloadLateForWork = settingServiceSelectors.reloadLateForWork();
  const lateForWork = settingServiceSelectors.getLateForWork();
  const [form] = Form.useForm();

  useEffect(()=> {
    dispatch(settingServiceActions.getLateForWork.fetch({}));
  },[]);
  useEffect(()=> {
   form.setFieldsValue({lateTime: lateForWork?.lateTime, turn: lateForWork?.turn});
  },[lateForWork]);

  useEffect(()=> {
    if(reloadLateForWork){
        dispatch(settingServiceActions.getLateForWork.fetch({}));
    }
  },[reloadLateForWork]);
  
  const onFinish = (values: any) => {
    const payload = { lateTime : values.lateTime, turn: values.turn};
    dispatch(settingServiceActions.updateLateForWork.fetch(payload));
  };

  return (
    <LateForWorkStyled>
      <Box>
        <Form
          form={form}
          layout='vertical'
          onFinish={onFinish}
        >
          <Box bgColor='fill_1' p='3' borderRadius='5px'>
            <Box display='flex' flexWrap='nowrap' gap='4' alignItems='end'>
              <ItemFormStyled>
                <FormItemStyled
                  name={'lateTime'}
                  label='Late Time'
                  rules={[{ required: true, message: 'Missing Late Time' }]}
                >
                  <InputNumber
                    placeholder='0 minute'
                    style={{ width: '100%', height: '4rem' }}
                    min={0}
                    max={100}
                  />
                </FormItemStyled>
              </ItemFormStyled>
              <ItemFormStyled>
                <FormItemStyled
                  name={'turn'}
                  label='Turn'
                  rules={[{ required: true, message: 'Missing Turn' }]}
                >
                  <Select
                    placeholder='Select a turn'
                    style={{ width: '100%', height: '4rem' }}
                  >
                    <Option value={1}>Full Turn</Option>
                    <Option value={0.5}>Half Turn</Option>
                    <Option value={0}>0 Turn</Option>
                  </Select>
                </FormItemStyled>
              </ItemFormStyled>
            </Box>
          </Box>
          <Box display='flex' gap='3' mt='4' justifyContent='end'>
            <Button width='10rem' ntype='SECONDARY'>
              Close
            </Button>
            <Form.Item>
              <Button width='10rem' ntype='PRIMARY' htmlType='submit'>
                Save
              </Button>
            </Form.Item>
          </Box>
        </Form>
      </Box>
    </LateForWorkStyled>
  );
};

export default LateForWork;
const LateForWorkStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
`;
const ItemFormStyled = styled.div`
  width: 100%;
`;

const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;
