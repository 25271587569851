
import { call, takeLatest } from 'redux-saga/effects';
import { ISagaFunc } from 'services/actionConfigs';
import storage from 'utils/sessionStorage';
import actions from './actions';
import { IOwnerLoginResData } from './types/owner';
import { IResponseDataBody } from 'services/response';
import apis from './apis';
const ownerLoginSuccess: ISagaFunc<IOwnerLoginResData> = function* ({ payload }) {
  const { accessToken, refreshToken } = payload;
  yield storage.token.set(accessToken);
  yield storage.refresh_token.set(refreshToken);
};

const ownerLogout = function* () {
  const shopId: string = yield storage.shop_id.get();
  if (!shopId) return;
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.ownerLogout);
    if (res.data.data) {
      yield storage.shop_id.set('');
      yield storage.station_number.set('');
    }
  } catch (error) {

  }
};

export default function* authServiceSagas() {
  yield takeLatest(actions.ownerLogin.success, ownerLoginSuccess);
  yield takeLatest(actions.ownerLogout, ownerLogout);
}
