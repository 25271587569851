const nextCarousel = () => {
  return (
    <svg width="56" height="58" viewBox="0 0 56 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_di_333_34036)">
    <circle cx="29" cy="25" r="24" fill="#F6F7FC"/>
    <circle cx="29" cy="25" r="24" stroke="#86909C" strokeWidth="2"/>
    </g>
    <path d="M24.3173 16.0595L32.5085 24.2488L24.3163 32.438C23.9258 32.8285 23.9258 33.4601 24.3163 33.8505C24.7067 34.2399 25.3393 34.2399 25.7297 33.8505L34.6277 24.9555C35.0181 24.566 35.0181 23.9334 34.6277 23.544L25.7298 14.649C25.3394 14.2595 24.7058 14.2595 24.3154 14.649C23.9268 15.0374 23.9268 15.6701 24.3173 16.0595Z" fill="#1D2129"/>
    <defs>
    <filter id="filter0_di_333_34036" x="0" y="0" width="58" height="58" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="4"/>
    <feGaussianBlur stdDeviation="2"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_333_34036"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_333_34036" result="shape"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dx="-1" dy="1"/>
    <feGaussianBlur stdDeviation="2"/>
    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
    <feBlend mode="normal" in2="shape" result="effect2_innerShadow_333_34036"/>
    </filter>
    </defs>
    </svg>
    
  );
};

export default nextCarousel;
