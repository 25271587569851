import { Menu, MenuProps } from 'antd';
import Box from 'components/Box';
import React, { useState } from 'react';
import styled from 'styled-components';
import Check from './PaymentTabMenu/Check';
import Discount from './PaymentTabMenu/Discount';
import GiftCard from './PaymentTabMenu/GiftCard';
import LoyaltyRewards from './PaymentTabMenu/LoyaltyRewards';
import Tax from './PaymentTabMenu/Tax';
type IPaymentsTabProps = {};
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

enum MenuKeys {
  TAX = 'tax',
  DISCOUNT = 'discount',
  LOYALTY_REWARDS = 'loyalty_rewards',
  CHECK = 'check',
  GIFT_CARD = 'gift_card',
}

const items: MenuItem[] = [
  getItem('Tax', MenuKeys.TAX),
  getItem('Discount', MenuKeys.DISCOUNT),
  getItem('Loyalty Rewards', MenuKeys.LOYALTY_REWARDS),
  getItem('Check (Others)', MenuKeys.CHECK),
  getItem('Gift Card', MenuKeys.GIFT_CARD),
];

const PaymentsTab: React.FC<IPaymentsTabProps> = () => {
  const [selectedKey, setSelectedKey] = useState<MenuKeys>(MenuKeys.TAX);

  const handleMenuSelect = (info: { key: string }) => {
    setSelectedKey(info.key as MenuKeys);
  };

  return (
    <PaymentsTabStyled>
      <BoxStyled display="flex" p="4" gap="4" alignItems="stretch">
        <LeftBoxStyled>
          <MenuBoxStyled>
            <Menu
              style={{ width: '100%' }}
              defaultSelectedKeys={[MenuKeys.TAX]}
              items={items}
              onSelect={handleMenuSelect}
            />
          </MenuBoxStyled>
        </LeftBoxStyled>
        {selectedKey === MenuKeys.TAX && <Tax />}
        {selectedKey === MenuKeys.DISCOUNT && <Discount />}
        {selectedKey === MenuKeys.LOYALTY_REWARDS && <LoyaltyRewards />}
        {selectedKey === MenuKeys.CHECK && <Check />}
        {selectedKey === MenuKeys.GIFT_CARD && <GiftCard />}
      </BoxStyled>
    </PaymentsTabStyled>
  );
};

export default PaymentsTab;
const PaymentsTabStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--fill-fill-3, #e5e5e5);
  height: calc(100vh - 3.125rem);
  min-width: 50rem;
  overflow: auto;
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
`;

const LeftBoxStyled = styled.div`
  border-radius: 5px;
  min-width: 16.5rem;
  background-color: white;
  gap: 1.5rem;
`;

const MenuBoxStyled = styled.div`
  padding: 0.25rem 0;
  .ant-menu-item {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;
