
export enum TypePaymentPassData {
    PASS_DATA = 'PASS_DATA',
    COMPLETED_PAYMENT = 'COMPLETED_PAYMENT',
    REQUEST_SIGNATURE = 'REQUEST_SIGNATURE',

    REQUEST_SIGNATURE_CHECK = 'REQUEST_SIGNATURE_CHECK',
    SEND_INFO_SIGNATURE_CHECK = 'SEND_INFO_SIGNATURE_CHECK',
    CASHIER_FORCE_CLOSE_SIGNATURE_CHECK = 'CASHIER_FORCE_CLOSE_SIGNATURE_CHECK',
}

export enum TypePaymentActors {
    CASHIER = 'CASHIER',
    CUSTOMER = 'CUSTOMER',
}

export type PaymentSocketData = {
    shopId: string;
    billId: string;
    action?: TypePaymentPassData,
    actor: TypePaymentActors,
    data?: any;
};