const appointments = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
        <path  d="M6.125 6.125C5.08947 6.125 4.25 6.96447 4.25 8V24.875C4.25 25.9105 5.08947 26.75 6.125 26.75H24.875C25.9105 26.75 26.75 25.9105 26.75 24.875V8C26.75 6.96447 25.9105 6.125 24.875 6.125H6.125ZM2.375 8C2.375 5.92893 4.05393 4.25 6.125 4.25H24.875C26.9461 4.25 28.625 5.92893 28.625 8V24.875C28.625 26.9461 26.9461 28.625 24.875 28.625H6.125C4.05393 28.625 2.375 26.9461 2.375 24.875V8Z" fill="#767676" />
        <path d="M17.8438 15.5C18.6204 15.5 19.25 14.8704 19.25 14.0938C19.25 13.3171 18.6204 12.6875 17.8438 12.6875C17.0671 12.6875 16.4375 13.3171 16.4375 14.0938C16.4375 14.8704 17.0671 15.5 17.8438 15.5Z" fill="#767676" />
        <path d="M22.5312 15.5C23.3079 15.5 23.9375 14.8704 23.9375 14.0938C23.9375 13.3171 23.3079 12.6875 22.5312 12.6875C21.7546 12.6875 21.125 13.3171 21.125 14.0938C21.125 14.8704 21.7546 15.5 22.5312 15.5Z" fill="#767676" />
        <path d="M17.8438 20.1875C18.6204 20.1875 19.25 19.5579 19.25 18.7812C19.25 18.0046 18.6204 17.375 17.8438 17.375C17.0671 17.375 16.4375 18.0046 16.4375 18.7812C16.4375 19.5579 17.0671 20.1875 17.8438 20.1875Z" fill="#767676" />
        <path d="M22.5312 20.1875C23.3079 20.1875 23.9375 19.5579 23.9375 18.7812C23.9375 18.0046 23.3079 17.375 22.5312 17.375C21.7546 17.375 21.125 18.0046 21.125 18.7812C21.125 19.5579 21.7546 20.1875 22.5312 20.1875Z" fill="#767676" />
        <path d="M8.46875 20.1875C9.2454 20.1875 9.875 19.5579 9.875 18.7812C9.875 18.0046 9.2454 17.375 8.46875 17.375C7.6921 17.375 7.0625 18.0046 7.0625 18.7812C7.0625 19.5579 7.6921 20.1875 8.46875 20.1875Z" fill="#767676" />
        <path d="M13.1562 20.1875C13.9329 20.1875 14.5625 19.5579 14.5625 18.7812C14.5625 18.0046 13.9329 17.375 13.1562 17.375C12.3796 17.375 11.75 18.0046 11.75 18.7812C11.75 19.5579 12.3796 20.1875 13.1562 20.1875Z" fill="#767676" />
        <path d="M8.46875 24.875C9.2454 24.875 9.875 24.2454 9.875 23.4688C9.875 22.6921 9.2454 22.0625 8.46875 22.0625C7.6921 22.0625 7.0625 22.6921 7.0625 23.4688C7.0625 24.2454 7.6921 24.875 8.46875 24.875Z" fill="#767676" />
        <path d="M13.1562 24.875C13.9329 24.875 14.5625 24.2454 14.5625 23.4688C14.5625 22.6921 13.9329 22.0625 13.1562 22.0625C12.3796 22.0625 11.75 22.6921 11.75 23.4688C11.75 24.2454 12.3796 24.875 13.1562 24.875Z" fill="#767676" />
        <path d="M17.8438 24.875C18.6204 24.875 19.25 24.2454 19.25 23.4688C19.25 22.6921 18.6204 22.0625 17.8438 22.0625C17.0671 22.0625 16.4375 22.6921 16.4375 23.4688C16.4375 24.2454 17.0671 24.875 17.8438 24.875Z" fill="#767676" />
        <path  d="M8 2.375C8.51777 2.375 8.9375 2.79473 8.9375 3.3125V5.1875C8.9375 5.70527 8.51777 6.125 8 6.125C7.48223 6.125 7.0625 5.70527 7.0625 5.1875V3.3125C7.0625 2.79473 7.48223 2.375 8 2.375Z" fill="#767676" />
        <path  d="M23 2.375C23.5178 2.375 23.9375 2.79473 23.9375 3.3125V5.1875C23.9375 5.70527 23.5178 6.125 23 6.125C22.4822 6.125 22.0625 5.70527 22.0625 5.1875V3.3125C22.0625 2.79473 22.4822 2.375 23 2.375Z" fill="#767676" />
        <path  d="M3.3125 8.9375H27.6875V10.8125H3.3125V8.9375Z" fill="#767676" />
    </svg>);
};

export default appointments;