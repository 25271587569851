import { Input, Layout } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import AppointmentDatePicker from 'features/report/pages/ReportPage/components/DatePicker';
import SwitchCalendarType from 'features/report/pages/ReportPage/components/SwitchCalendarType';
import { debounce } from 'lodash';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import SelectFromToOptions from '../components/SelectFromToOptions';
import TableGiftCards from '../components/TableGiftCards';
import giftCardsActions from '../services/actions';
import giftCardsSelectors from '../services/selectors';

interface IGiftCards {

}
export const GIFT_CARDS = 'GIFT_CARDS';
const PAGE_SIZE = 2;
const GiftCards: FC<IGiftCards> = () => {
    const dispatch = useAppDispatch();
    const [resetTime, setResetTime] = useState<number>();
    const [searchText, setSearchText] = useState<string>();
    const [resetTimeChecked, setResetTimeChecked] = useState<string[]>([]);
    const dataAppointment = giftCardsSelectors.getParams()?.[GIFT_CARDS];
    const totalElements = giftCardsSelectors.getTotalElements();
    const resetGiftCardStatus = giftCardsSelectors.getResetTimeGiftCardStatus();

    useEffect(() => {
        if (resetGiftCardStatus) {
            setResetTimeChecked([]);
            dispatch(giftCardsActions.resetGiftCardDetail.fail(false));
        }
    }, [resetGiftCardStatus]);
    useEffect(
        () => {
            if (dataAppointment?.startTime && dataAppointment?.endTime) {
                const payload = {
                    page: 1,
                    size: PAGE_SIZE,
                    resetNumber: resetTime,
                    keySearch: searchText,
                    startDate: dataAppointment?.startTime,
                    endDate: dataAppointment?.endTime
                };
                dispatch(giftCardsActions.getListGiftCard.fetch(payload));
            }
        }, [dataAppointment, resetTime, searchText]
    );

    useEffect(() => {
        dispatch(giftCardsActions.setParams({
            [GIFT_CARDS]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
            }
        }));
    }, []);

    const handleChangeResetTime = (value: number) => {
        setResetTime(value);
    };
    const handleSearch = debounce((event: any) => {
        setSearchText(event?.target?.value);
    }, 300);
    const onReset = () => {
        dispatch(giftCardsActions.resetGiftCardDetail.fetch(resetTimeChecked));
    };
    return (
        <GiftCardsStyled>
            <Sidebar />
            <Container>
                <HeaderBox>
                    <Box pt='2'>
                        <Text variant="H5">Gift Cards ({totalElements ?? 0})</Text>
                    </Box>
                    <HeaderBox>
                        <Button icon="change" ntype='SECONDARY' onClick={onReset}>
                            Reset
                        </Button>
                        <SelectResetTimesStyled
                            placeholder="Reset Time"
                            onChange={handleChangeResetTime}
                            value={resetTime}
                            options={optionsResetTime}
                            allowClear
                        />
                        <InputStyled
                            placeholder="Search by staff,customer,card number"
                            suffix={<Icon type='search' />}
                            // @ts-ignore
                            onChange={handleSearch} />
                        <AppointmentDatePicker size='middle' actions={giftCardsActions} selectors={giftCardsSelectors} type='GIFT_CARDS' />
                        <SwitchCalendarType actions={giftCardsActions} selectors={giftCardsSelectors} type='GIFT_CARDS' />
                    </HeaderBox>
                </HeaderBox>
                <TableGiftCards
                    resetTime={resetTime}
                    searchText={searchText}
                    pageSize={PAGE_SIZE}
                    resetTimeChecked={resetTimeChecked}
                    setResetTimeChecked={setResetTimeChecked}
                />
            </Container>
        </GiftCardsStyled>
    );
};
const optionsResetTime = [
    { value: 0, label: 'All' },
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
];
export default GiftCards;
const GiftCardsStyled = styled(Layout)` 
`;
const Container = styled(Layout)`
    background-color: ${colorTheme.fill_3};
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem;
`;
const InputStyled = styled(Input)`
    height: 3.5rem;
    width:100%;
    display: flex;
    flex:1;
`;
const SelectResetTimesStyled = styled(SelectFromToOptions)`
    width: 150px;
    height: 3.5rem;
`;