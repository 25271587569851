const LineList = () => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12.25H8V10.25H10V12.25ZM27 12.25H12V10.25H27V12.25Z"
        fill="#ADADAD"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.25H8V17.25H10V19.25ZM27 19.25H12V17.25H27V19.25ZM10 25.75H8V23.75H10V25.75ZM27 25.75H12V23.75H27V25.75Z"
        fill="#ADADAD"
      />
    </svg>
  );
};

export default LineList;

export const LineListActive = () => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="35" height="35" rx="5" fill="#FF8890" />
      <path
        opacity="0.4"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 12.25H8V10.25H10V12.25ZM27 12.25H12V10.25H27V12.25Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.25H8V17.25H10V19.25ZM27 19.25H12V17.25H27V19.25ZM10 25.75H8V23.75H10V25.75ZM27 25.75H12V23.75H27V25.75Z"
        fill="white"
      />
    </svg>
  );
};
