import React, { memo } from 'react';
import styled from 'styled-components';
import HeadContent from '../TableView/HeadContent';
import LoadingCalendar from '../TableView/Loading';
import Header from './Header';
import CalendarMonthRow from './Row';
import CalendarMonthStyled from './styles';
import useMappingDataMonthView from './useMappingDataMonthView';
type ICalendarMonthViewProps = any;
const CalendarMonthView: React.FC<ICalendarMonthViewProps> = () => {
  const { data, currentMonth } = useMappingDataMonthView();

  return (
    <>
      <HeadContent />
      <CalendarMonthViewStyled>
        <CalendarMonthStyled.Table>
          <Header />
          {data.map(row => (
            <CalendarMonthRow currentMonth={currentMonth} data={row} key={row.id} />
          ))}
        </CalendarMonthStyled.Table>
        <LoadingCalendar />
      </CalendarMonthViewStyled>
    </>
  );
};

export default memo(CalendarMonthView);
type CalendarMonthViewStyledProps = {}
const CalendarMonthViewStyled = styled.div<CalendarMonthViewStyledProps>`
  position: relative;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
`;
