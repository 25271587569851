import { useSetLoadingPage } from 'services/UI/LoadingPage';
import cashierSelectors from '../services/selectors';
import { IResponseDataBody } from 'services/response';
import cashierApis from '../services/apis';
import { useAppDispatch } from 'store/hooks';
import cashierActions from '../services/actions';
import { first } from 'lodash';

const useUpdateTicket = () => {
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const paymentInfo = cashierSelectors.getPaymentInfo();


  return async () => {

    // if (!paymentInfo.customerId) {
    //   dispatch(cashierActions.setVisibleChooseCustomer(true));
    //   return null;
    // }

    if (paymentInfo.items.length === 0) {
      dispatch(cashierActions.setVisibleChooseStaff('default'));
      return null;
    } else if (paymentInfo.items.length === 1) {
      const services = first(paymentInfo.items)?.services || [];
      if (services.length === 0) {
        dispatch(cashierActions.setVisibleModalWarningEmptyServices(true));
        return null;
      }
    }

    setLoadingPage(true);
    const res: IResponseDataBody<{ billId: string }> = await cashierApis.editTicket(paymentInfo).finally(() => setLoadingPage(false));
    const resData = res?.data?.data;
    if (resData) return resData;
    throw 'fail';
  };
};

export default useUpdateTicket;
