import { Player } from '@lottiefiles/react-lottie-player';
import { Input, InputRef, Row } from 'antd';
import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import paymentApis from 'features/payment/services/apis';
import React, { useRef, useState, useTransition } from 'react';
import styled from 'styled-components';
import animationQR from './animationQR.json';
import animationSuccess from './animationSuccess.json';

const ButtonScanQR = () => {
  const [text, setText] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [, startTransition] = useTransition();
  // const [loading, setLoading] = useState(false);

  const onScan: React.MouseEventHandler<HTMLDivElement> = () => {
    startTransition(() => {
      setIsFocus(true);
      setText('');
      setIsSuccess(false);
    });
    setTimeout(() => inputRef.current?.focus(), 100);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleClose = () => {
    setIsFocus(false);
    setText('');
    inputRef.current?.blur();
  };

  const onEnter = async () => {
    try {
      const res = await paymentApis.getBillInfoWithQRCode('886396');
      console.log('res', res);

    } catch (error) {

    }
    setIsSuccess(true);

    // console.log('onKeyDown value', text);
    // handleClose();
  };

  return (
    <>
      <ButtonScanQRStyled>
        <div onClick={onScan} className='button'>
          {<Icon type={'QR'} />}
        </div>
      </ButtonScanQRStyled>
      <Modal width={'300px'} showClose destroyOnClose visible={isFocus} hiddenHeader okTitle={'close'} hiddenFooter onClose={handleClose}>
        <Row style={{ height: '220px', width: '220px', }} align={'middle'} justify={'center'}>
          {
            isSuccess ?
              <Player
                autoplay={true}
                loop={false}
                keepLastFrame
                controls={true}
                speed={1.5}
                src={animationSuccess}
                style={{ height: '150px', width: '150px' }}
              ></Player>
              :
              <Player
                autoplay={true}
                loop={true}
                controls={true}
                src={animationQR}
                style={{ height: '200px', width: '200px' }}
              ></Player>}
        </Row>
        <div style={{ opacity: 0 }}>
          <Input
            style={{ height: 0, width: 0 }}
            ref={inputRef}
            className='hiddenInput'
            value={text}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onEnter();
                return;
              }
            }}
            onChange={onChange}
            placeholder='0'
          />
        </div>
      </Modal>
    </>
  );
};

export default ButtonScanQR;
const ButtonScanQRStyled = styled.div`
.button {
  &:hover {
    cursor: pointer;
  }
}
  .hiddenInput {
    opacity: 0;
  }
`;
export const LoadingPageStyled = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content:center;
  z-index: 9999;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 132px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 6px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #363565;
    }
  }
`;
