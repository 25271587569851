import { Col, Row } from 'antd';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalaryStaffDetailOneDay, IPrintSalaryStaffDetailOneDay_Detail } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../components/PrintHeader';
import { ReportType } from '../constants';
interface IEmployeeSalaryReportPrintPage { }

const EmployeeSalaryReportPrintPage: React.FC<IEmployeeSalaryReportPrintPage> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dataPrintSalaryStaffOnDay = reportSelectors.getPrintSalarySummaryStaffOneDay();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.EMPLOYEE_SALARY];
    const filterTypeSalaryStaff = reportSelectors.getFilterTypeSalaryStaff();
    useEffect(() => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalarySummaryStaffOneDay.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            salaryType: filterTypeSalaryStaff
        }));
    }, [dataAppointment, filterTypeSalaryStaff]);

    const rowItemStyle = { padding: '2px 0px' };
    const RowItem = (item: IPrintSalaryStaffDetailOneDay_Detail) => {
        return (
            <StyledRow style={rowItemStyle}>
                <ColHeader span={3}>
                    <StyledRow><Text printMode variant='CAPTION_3' color="text_3">#{item?.ticketNumber}</Text></StyledRow>
                </ColHeader>
                <ColHeader span={9}>
                    {item?.itemNames?.map((m: any, index: number) => {
                        return (
                            <StyledRow key={index} style={{ justifyContent: 'start' }}><Text printMode variant='CAPTION_3' color="text_3" textAlign='left'>{m}</Text></StyledRow>
                        );
                    })}
                </ColHeader>
                <ColHeader span={7} style={{ textAlign: 'start' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(item?.price)}</Text></ColHeader>
                <ColHeader span={5} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(item?.tip)}</Text></ColHeader>
            </StyledRow>
        );
    };
    const UserDetail = (data: IPrintSalaryStaffDetailOneDay) => {
        return (
            <Box mt='1' px='3'>
                <Box className="center" highlight>
                    <Text printMode variant="CAPTION_2" className='title-highlight'>
                        {data?.staffName || '----'}
                    </Text>
                </Box>
                <Box>
                    <Header>
                        <ColHeader span={3}><Text printMode variant='CAPTION_3' color='text_2' className='th'>No.</Text></ColHeader>
                        <ColHeader span={9} style={{ textAlign: 'center' }}><Text printMode variant='CAPTION_3' color='text_2' className='th'>ITEMS</Text></ColHeader>
                        <ColHeader span={7} style={{ textAlign: 'start' }}><Text printMode variant='CAPTION_3' color='text_2' className='th'>PRICE</Text></ColHeader>
                        <ColHeader span={5} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color='text_2' className='th'>TIPS</Text></ColHeader>
                    </Header>
                    {data?.details?.map(item => RowItem(item))}
                    <Total>
                        <ColHeader span={12}><Text printMode variant='CAPTION_2' color="text_3">TOTAL SALES</Text></ColHeader>
                        <ColHeader span={12} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_2' color="text_3">{formatCurrency(data?.totalSales)}</Text></ColHeader>
                    </Total>
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">SUPPLY FEE</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.supplyFee)}</Text></ColHeader>
                    </Discount>
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">COMMISSION</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalCommission)}</Text></ColHeader>
                    </Discount>
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">TIP</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalTip)}</Text></ColHeader>
                    </Discount>
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">DISCOUNT</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.staffDiscount)}</Text></ColHeader>
                    </Discount>
                    <Discount style={{ borderBottom: 'unset' }}>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">PAYROLL</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalSalary)}</Text></ColHeader>
                    </Discount>
                </Box>
            </Box>
        );
    };
    const divRef = useRef<HTMLDivElement>(null);

    const onBack = () => {
        navigate(-1);
    };
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });

    return (
        <Flex>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <Box className="center" overflow="auto" >
                        <TicketBox>
                            <Box px='3' py='1'>
                                <LogoBox>
                                    <IconLogo />
                                </LogoBox>
                                <Box className="center" pt='1'>
                                    <Text printMode variant="CONTENT_2" color="text_2">
                                        {moment(dataPrintSalaryStaffOnDay?.createdDate).format('MM/DD/YYYY')}
                                    </Text>
                                </Box>
                            </Box>
                            <Box px='3'>
                                <Box className="space-between" highlight>
                                    <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight'>
                                        Employee Salaries
                                    </Text>
                                    <Text printMode variant="CAPTION_2" color="text_3" className='text-highlight'>
                                        {formatCurrency(dataPrintSalaryStaffOnDay?.totalSalary)}
                                    </Text>
                                </Box>
                                <Text mb={0.25} />
                                <Box className="space-between" >
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        Total Cash
                                    </Text>
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        {formatCurrency(dataPrintSalaryStaffOnDay?.totalPayCash)}
                                    </Text>
                                </Box>
                                <Box className="space-between">
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        Total Check
                                    </Text>
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        {formatCurrency(dataPrintSalaryStaffOnDay?.totalPayCheck)}
                                    </Text>
                                </Box>
                                <Text mb={0.5} />
                            </Box>
                            {dataPrintSalaryStaffOnDay?.reportStaff?.map((m, i) => {
                                const isLast = (dataPrintSalaryStaffOnDay?.reportStaff.length - 1) === i;
                                return (
                                    <div key={i}>
                                        {UserDetail(m)}
                                        {!isLast && <DividerSlit />}
                                    </div>
                                );
                            })}
                        </TicketBox>
                    </Box>
                </div>
            </ContainerStyled>
        </Flex>
    );
};

export default EmployeeSalaryReportPrintPage;

const Flex = styled.div`
 display: flex;
`;

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 20.5rem;
  height: fit-content;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Header = styled(Row)`
  border-top:1px solid black;
  padding: 2.5px 0px;
  border-bottom: 1px dashed black;
  .th {
    color: #767676 !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ColHeader = styled(Col)`
    font-family: 'Roboto';
    color:  black;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Total = styled(Header)`
  border-bottom: 1px solid black;
`;

const Discount = styled(Header)`
  border-top: unset;
`;

const StyledRow = styled(Row)`
    font-family: 'Roboto';
`;

const DividerSlit = styled.div`
border-bottom: 1px dashed black;
margin: 1rem 0;
`;