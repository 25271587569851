import { Avatar, Input, Row } from 'antd';
import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import appointmentActions from 'features/appointment/services/actions';
import appointmentApis from 'features/appointment/services/apis';
import { CALENDAR_TIME_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { IBodyApiMakeLockTime } from 'features/appointment/services/types/api';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import { get } from 'lodash';
import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ModalChooseStaff from './ChooseStaff';
import DatePickerLabel from './DatePickerLabel';
import { ListIcon, StaffIcon } from './Icons';
import TimePickerLabel from './TimePickerLabel';

const BreakTime = () => {
  const data = appointmentSelectors.getNewBreakTimeDraftData();
  const [, startTransition] = useTransition();
  const setLoadingPage = useSetLoadingPage();
  const [note, setNote] = useState('');

  const dispatch = useAppDispatch();

  const [timeStartStr, setTimeStartStr] = useState<string | undefined | null>();
  const [staff, setStaff] = useState<IStaffItemData>();


  useEffect(() => {
    if (data) {
      if (!timeStartStr) setTimeStartStr(data?.colTime);
    }
  }, [data]);

  const reset = () => {
    startTransition(() => {
      setTimeStartStr(null);
      setStaff(undefined);
      setNote('');
    });
  };


  const onClose = () => {
    reset();
    dispatch(appointmentActions.setNewBreakTimeDraftData(null));
  };

  const timeStart = useMemo(() => {
    return timeStartStr ? moment(timeStartStr) : null;
  }, [timeStartStr]);

  const timeEnd = useMemo(() => {
    const time = timeStart?.clone().add(15, 'minute');
    return time || null;
  }, [timeStart]);

  const TimeInfo = useCallback(() => {
    return (
      <div className='row-item row-time'>
        <Icon type='time' />
        <div className="row-grow">
          <DatePickerLabel value={timeStartStr} onChange={e => {
            const newDate = moment(e).set({
              hour: timeStart?.get('hour'),
              minute: timeStart?.get('minute'),
              second: 0
            });
            setTimeStartStr(newDate?.format());
          }} />
          <Text mx={1.5} />
          <TimePickerLabel value={timeStartStr} onChange={setTimeStartStr} />
          <Text variant='H8' color='text_3' mx={1}>-</Text>
          <Text variant='H8' color='text_3'>{timeEnd?.format(FORMAT_TIME)}</Text>
        </div>
      </div>
    );
  }, [timeStartStr]);

  const StaffInfo = useCallback(() => {
    if (!data?.staff) return (
      <div className="row-item">
        <StaffIcon />
        <div className="row-grow">
          <div className="row-staff">
            <ModalChooseStaff staff={staff} onAddStaff={setStaff} />
          </div>
        </div>
      </div>
    );

    const staffName = data?.staff?.name;
    return (
      <div className="row-item">
        <StaffIcon />
        <div className="row-grow">
          <div className="row-staff">
            <Avatar size={'small'}>{get(staffName, [0], '')}</Avatar>
            <Text variant='H9' color='text_3'>{staffName}</Text>
          </div>
        </div>
      </div>
    );
  }, [data, staff]);



  const onSubmit = async () => {
    if (!timeStart) return;
    setLoadingPage(true);
    const payload: IBodyApiMakeLockTime = {
      staffId: staff ? staff.id : data?.staff?.id || '',
      startTime: timeStart?.format('MM-DD-YYYY HH:mm:ss') || '',
      endTime: timeEnd?.format('MM-DD-YYYY HH:mm:ss') || '',
      note,
      type: CALENDAR_TIME_TYPE.BREAK,
    };
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.makeLockTime(payload);
      if (res?.data?.data) {
        dispatch(appointmentActions.setParams());
        onClose();
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };


  return (
    <>
      <Modal
        modalTitle="BREAK TIME"
        visible={!!data}
        onClose={onClose}
        noneBodyStyle
        noneFooterStyle
        containerPadding={1.5}
        headerBgColor={colorTheme.info_bg_3}
        okTitle={okTitle}
        onSubmit={onSubmit}
        disableOk={!(staff ? staff.id : data?.staff?.id || '')}
      >
        <ModalBreakTimeStyled>
          <TimeInfo />
          <StaffInfo />

          <Text mb={1} />
          <div className="row-item" style={{ alignItems: 'start' }}>
            <ListIcon />
            <div className='w-100'>
              <Input.TextArea
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder='Reason ...'
              />
            </div>
          </div>
        </ModalBreakTimeStyled>
      </Modal>
    </>
  );
};

export default memo(BreakTime);

const okTitle = (
  <Row align={'middle'} justify={'center'} className='prevent-select'>
    <Icon type='addPlus' />
    <Text variant='CONTENT_2' color='text_3' ml={0.5}>Add Break Time</Text>
  </Row>
);

type ModalBreakTimeStyledProps = {}
const ModalBreakTimeStyled = styled.div<ModalBreakTimeStyledProps>`
  display: flex;
  flex-direction: column;

  .row-item {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
  .row-grow {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .row-time {
    margin-bottom: 0.5rem;
  }
  .w-100 {
    width: 100%;
  }
  .row-staff {
    gap: 11px;
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
  }
`;