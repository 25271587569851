import Header from 'features/checkIn/components/Header';
import checkInSelectors from 'features/checkIn/services/selectors';
import { styled } from 'styled-components';
import ButtonCountDown from './component/ButtonCountDown';
import TicketDetailBox from './component/TicketDetailBox';

const AppointmentPage = () => {
  const detail = checkInSelectors.getAppointmentDetail();

  return (
    <AppointmentPageStyled>
      <ButtonCountDown />
      <Header>APPOINTMENT</Header>
      <TicketDetailBox data={detail} isAppointment />
    </AppointmentPageStyled>
  );
};

export default AppointmentPage;

const AppointmentPageStyled = styled.div`
`;
