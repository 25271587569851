import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import TicketDetail from './components/TicketDetail';
import PaymentDetail from './components/PaymentDetail';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import multiplePaymentActions from '../services/actions';

const CustomerSide = () => {
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();

  useEffect(() => {
    dispatch(multiplePaymentActions.getTransactionDetail.fetch(id));
  }, []);

  return (
    <CustomerSidePage>
      <Col span={13}>
        <TicketDetail />
      </Col>
      <Col span={11}>
        <PaymentDetail />
      </Col>
    </CustomerSidePage>
  );
};

export default CustomerSide;

const CustomerSidePage = styled(Row)`
  background: var(--fill-fill-3, #E5E5E5);
  min-height: 100vh;
  padding: 5vw;
`;
