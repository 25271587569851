import { Switch } from 'antd';
import { SwitchProps } from 'antd/lib';
import React from 'react';
import styled from 'styled-components';

type ISwitchCustomProps = SwitchProps & {
  // Add custom props here if you have any
};
const SwitchCustom: React.FC<ISwitchCustomProps> = (props) => {
  return <SwitchCustomStyled {...props} />;
};

export default SwitchCustom;
const SwitchCustomStyled = styled(Switch)`
  height: 1.75rem;
  min-width: 3.375rem;
  @media only screen and (max-width: 1400px) {
    height: 2rem;
  }
  .ant-switch-handle {
    top: 4px;
    inset-inline-start: 5px;
  }

  &.ant-switch-checked,
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: var(--info-infor-3, #6fabb6);
  }

  &.ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 25px);
  }
`;
