import React from 'react';
import { Form, InputNumber } from 'antd';
import styled from 'styled-components';

interface PercentageInputProps {
  size?: 'large' | 'middle' | 'small';
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  label?: string;
  name?: string | (string | number)[];
  noStyle?: boolean;
  bordered?: boolean;
}

const PercentageInputPrecision: React.FC<PercentageInputProps> = ({
  size = 'middle',
  width = '100%',
  height,
  name,
  label = '',
  noStyle = false,
  bordered = true,
}) => {
  let heightBySize = '3.5rem'; // default for 'medium'
  switch (size) {
    case 'large':
      heightBySize = '4rem';
      break;
    case 'small':
      heightBySize = '3rem';
      break;
  }

  return (
    <InputNumberStyled style={{ width: width, height: height || heightBySize }}>
      <Form.Item label={label} name={name} noStyle={noStyle}>
        <InputNumber
          style={{ width: '100%' }}
          bordered={bordered}
          formatter={(val) => `${val}%`}
          parser={(val: any) => val?.replace('%', '') || ''}
        />
      </Form.Item>
    </InputNumberStyled>
  );
};

export default PercentageInputPrecision;

<div style={{ border: '1px solid black' }}>
  <div style={{ height: '3rem' }}>height 3rem</div>
</div>;

const InputNumberStyled = styled.div`
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
      color: var(--text-text-3, #1d2129);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-input-number {
    flex-grow: 1;
    height: 100%;
  }
`;
