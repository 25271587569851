import { Row } from 'antd';
import Button from 'components/Button';
import appointmentActions from 'features/appointment/services/actions';
import { APPOINTMENT_LAYOUT } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import moment from 'moment';
import { useAppDispatch } from 'store/hooks';
import { momentTimezone } from 'utils/time';

const Actions = () => {
  const params = appointmentSelectors.getParams();
  const tableParams = appointmentSelectors.table.params();
  const layout = appointmentSelectors.getAppointmentLayout();

  const colTime = () => {
    const now = moment();
    const nowTime = [0, 15, 30, 45, 60].map(o => ({ key: o, value: (now.get('minute') - o) })).filter(o => o.value >= 0);

    const minute = nowTime.find(o => o.value === Math.min(...nowTime.map(o => o.value)))?.key;
    if (layout === APPOINTMENT_LAYOUT.CALENDAR) {
      if (!params?.startTime) return undefined;

      const startTime = momentTimezone(params?.startTime).set({
        hour: now.get('hour'),
        minute: minute ?? now.get('minute'),
        second: 0,
      });
      if (startTime.isSameOrAfter(now, 'date'))
        return startTime.format();
      return undefined;
    } else if (layout === APPOINTMENT_LAYOUT.LIST) {
      if (!tableParams?.startTime) return undefined;
      const startTime = momentTimezone(tableParams?.startTime).set({
        hour: now.get('hour'),
        minute: minute ?? now.get('minute'),
        second: 0,
      });
      if (startTime.isSameOrAfter(now, 'date'))
        return startTime.format();
      return undefined;
    }

    return undefined;
  };

  const dispatch = useAppDispatch();
  const newAppointment = () => {
    dispatch(appointmentActions.setNewAppointmentDraftData({
      colTime: colTime(),
      newApt: true,
    }));
  };

  const newBlockHour = () => {
    dispatch(appointmentActions.setNewBlockHourDraftData({
      colTime: colTime(),
    }));
  };

  const newBreakTime = () => {
    dispatch(appointmentActions.setNewBreakTimeDraftData({
      colTime: colTime(),
    }));
  };
  return (
    <Row className='header-actions' style={{ gap: '1rem' }}>
      <Button ntype="SECONDARY" size='middle' icon='time' onClick={newBlockHour} >Block Hours</Button>
      <Button ntype="SECONDARY" size='middle' icon='time' onClick={newBreakTime} >On Break</Button>
      <Button ntype="PRIMARY" size='middle' icon='addPlus' onClick={newAppointment}>Add New Appointment</Button>
    </Row>
  );
};

export default Actions;