import { Avatar, Col, Row } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
interface IComponentProps {
  url?: string;
  username: String;
  isSelected: boolean;
  onClick?: () => void;
  isBusy?: boolean;
}
const AvatarBox: React.FC<IComponentProps> = ({
  username,
  isSelected,
  onClick,
  isBusy,
  url,
}) => {
  return (
    <AvatarBoxStyled
      className={isBusy ? 'busy-staff' : ''}
      onClick={onClick}
      selected={isSelected}
    >
      {isSelected && (
        <IconBadge>
          <Icon type="tick" />
        </IconBadge>
      )}
      <Row>
        <Col flex={'auto'} style={{ margin: '0.5rem 0' }}>
          <Row align={'middle'} justify={'center'}>
            <Avatar className="circle-avatar" src={url} alt="staff-avatar" >{get(username, [0], '')}</Avatar>
          </Row>
        </Col>
        <Text variant="BODY_1" color='text_3' className='text-overflow' textAlign='center' wrapWidth={'95%'} style={{ fontWeight: '600' }} >{username}</Text>
      </Row>
    </AvatarBoxStyled>
  );
};

export default AvatarBox;

const IconBadge = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;

interface IAvatarBoxStyled {
  selected: boolean;
  // busy?: boolean;
  color?: keyof typeof colorTheme;
}

const AvatarBoxStyled = styled.button<IAvatarBoxStyled>`
    cursor: pointer;
    row-gap: 0.8rem;
    height: 8rem;
    width: 7rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 16.896px;
    background: ${({ selected }) =>
    selected ? colorTheme['info_bg_3'] : '#FFF'};
    box-shadow: -1.6896352767944336px 1.6896352767944336px 6.758541107177734px 0px rgba(0, 0, 0, 0.10) inset, 0px 6.758541107177734px 6.758541107177734px 0px rgba(0, 0, 0, 0.25);

    .circle-avatar {
        width: 5.3rem;
        height: 5.3rem;
        border-radius: 50%;
        font-size: 2rem;
        display: flex;
        align-items: center;
    }

    &.busy-staff {
      background: #F8D9BB;
    }
`;
