import { Col, Form, Input, Row } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modal';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { ICategory } from 'features/settingService/services/types/reducer';
import { FC, useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

interface IModalEditCategoryName {
    isShown: boolean;
    onClose: () => void,
}
interface IFormCategory extends ICategory {
    subCategories: number
}
const ModalEditCategoryName: FC<IModalEditCategoryName> = ({ isShown, onClose }) => {
    const dispatch = useAppDispatch();
    const [form] = Form.useForm<IFormCategory>();
    const cateForProductSelected = settingServiceSelectors.getCateForProductSelected();
    const reloadCategorySuccess = settingServiceSelectors.getReloadCategorySuccess();
    const handleClose = () => {
        onClose();
    };
    const onFinish = async () => {
        try {
            const values = await form.validateFields();
            const body = {
                categoryId: cateForProductSelected?.id ?? '',
                cateName: values?.categoryName ?? '',
                cateType: 'PRODUCT',
            };
            dispatch(settingServiceActions.updateNameCategoryForProduct.fetch(body));
        } catch (errorInfo) { }
    };
    useEffect(() => {
        if (reloadCategorySuccess) {
            dispatch(settingServiceActions.reloadCategory(false));
            dispatch(settingServiceActions.getListCategoriesForProduct.fetch({}));
            form.resetFields();
            onClose();
        }
    }, [reloadCategorySuccess]);
    useEffect(() => {
        form.setFieldValue('categoryName', cateForProductSelected?.categoryName);
    }, [cateForProductSelected]);
    return (
        <Modal
            modalTitle="EDIT CATEGORY"
            visible={isShown}
            onClose={onClose}
            destroyOnClose={true}
            cancelTitle="Close"
            footer={<ButtonGroup>
                <Button ntype="SECONDARY" onClick={handleClose}>Close</Button>
                <Button ntype="PRIMARY" onClick={onFinish}>Save</Button>
            </ButtonGroup>}
            width={900}
            noneBodyStyle
        >
            <Content>
                <Form
                    form={form}
                    layout={'vertical'}
                >
                    <Row gutter={[12, 12]}>
                        <Col span="24">
                            <Form.Item
                                label="Category Name"
                                name="categoryName"
                                rules={[{ required: true, message: 'Missing category name' }]}
                            >
                                <Input placeholder='waxing' style={{ height: 48 }} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Content>
        </Modal>
    );
};

export default ModalEditCategoryName;

const Content = styled.div`
    padding: 20px;
`;
const ButtonGroup = styled(Row)`
    justify-content: center;
    width: 100%;
    padding: 0px 20px 20px;
`;
