import { Spin } from 'antd';
import Modal from 'components/Modal';
import NumberKeyBoard, { NumberKeyBoardRef } from 'components/NumberKeyBoard';
import colorTheme from 'constants/color';
import { STAFF_TYPE } from 'features/cashier/services/types/constant';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
import { IStaffItemData } from 'services/shop/types/staff';

type Props = {
  onClose?: () => void;
  onCorrect: (pin?: string) => void;
  visible: boolean;
  onlyAdmin?: boolean;
};

const RequestAdminLocalPW = ({ visible, onCorrect, onClose = () => undefined, onlyAdmin = false }: Props) => {
  const kbRef = useRef<NumberKeyBoardRef>(null);
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    kbRef.current?.reset();
    onClose();
  };

  const handle = useCallback(async (_pin: string) => {
    if (_pin.length < 4) return;
    setLoading(true);
    try {
      const res: IResponseDataBody<IStaffItemData> = await shopApis.verifyPinAdmin(_pin);
      const resData = res.data.data;
      if (resData) {
        if (onlyAdmin) {
          if (resData.staffType === STAFF_TYPE.ADMIN) {
            return onCorrect(_pin);
          }
        } else {
          return onCorrect(_pin);
        }
      }
      return kbRef.current?.showError('Password incorrect!');

    } catch (error) {
      kbRef.current?.showError('Password incorrect!');
    } finally {
      setLoading(false);
    }
  }, [onlyAdmin]);

  const _debounce = useCallback(debounce((_pin) => handle(_pin), 500), []);

  useEffect(() => {
    _debounce(pin);
  }, [pin]);

  return (
    <Modal
      modalTitle='ADMIN PASSWORD'
      hiddenOkButton
      headerBgColor={colorTheme.info_bg_3}
      visible={visible} onClose={handleClose}>
      <Spin spinning={loading}>
        <NumberKeyBoard
          ref={kbRef}
          type='PIN-PASSWORD'
          value={pin} onChange={(val) => setPin(val || '')}
        />
      </Spin>
    </Modal>
  );
};

export default memo(RequestAdminLocalPW);
