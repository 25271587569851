const CardBtn = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3926_69731)">
        <path d="M1.34928 3.33182C1.58615 3.24932 1.84115 3.25839 2.08834 3.2587C6.25428 3.2587 10.4202 3.2587 14.5862 3.2587C14.8155 3.26057 15.0465 3.24776 15.274 3.28557C15.5571 3.3387 15.8221 3.48339 16.0196 3.69339C16.2555 3.9387 16.3833 4.27245 16.3999 4.61057V12.3878C16.384 12.719 16.2618 13.0468 16.034 13.2906C15.8352 13.5093 15.5637 13.6599 15.2733 13.7143C15.0468 13.7518 14.8165 13.7393 14.588 13.7412C10.4212 13.7412 6.25459 13.7412 2.08771 13.7412C1.84084 13.7412 1.58615 13.7509 1.34959 13.6681C0.941465 13.5371 0.60709 13.2018 0.474277 12.7946C0.428965 12.664 0.413027 12.5256 0.399902 12.3887V4.61245C0.413027 4.47557 0.429277 4.33776 0.473652 4.20714C0.60584 3.79901 0.940527 3.46339 1.34928 3.33182ZM0.914277 4.19995C0.836152 4.34964 0.807402 4.51932 0.807402 4.68682C0.806465 4.9612 0.807402 5.23526 0.80709 5.50964C5.86896 5.50964 10.9308 5.50964 15.9927 5.50932C15.9918 5.22464 15.9946 4.93964 15.9915 4.65464C15.9899 4.48307 15.9508 4.31026 15.8643 4.1612C15.6933 3.8512 15.3449 3.65901 14.9924 3.66589C10.5965 3.66464 6.20053 3.66432 1.80459 3.66589C1.43771 3.6587 1.0774 3.86995 0.914277 4.19995ZM0.80709 6.49339C0.807715 8.4437 0.805527 10.394 0.80834 12.344C0.803027 12.6896 0.991777 13.03 1.29334 13.2006C1.46521 13.3046 1.66959 13.3375 1.86803 13.3353C6.24303 13.334 10.618 13.3362 14.993 13.334C15.3687 13.3424 15.7374 13.1209 15.8958 12.7787C16.0262 12.5181 15.9862 12.2199 15.9927 11.939C15.9927 10.1237 15.9927 8.3087 15.9924 6.49339C10.9308 6.49307 5.86896 6.49307 0.80709 6.49339Z" fill="#1D2129" />
        <path d="M2.81408 8.76313C3.46783 8.74813 4.12221 8.76094 4.77596 8.75688C4.97377 8.76125 5.17221 8.74594 5.36908 8.7675C5.71596 8.80938 6.00815 9.10938 6.04283 9.45656C6.06096 9.67844 6.04752 9.90125 6.05158 10.1234C6.04564 10.3575 6.08033 10.6063 5.97377 10.8247C5.84721 11.1022 5.55002 11.2859 5.24533 11.2794C4.49502 11.2819 3.7444 11.2791 2.99408 11.2809C2.8669 11.2806 2.7369 11.2772 2.6169 11.2309C2.36565 11.1419 2.16783 10.9166 2.11533 10.655C2.0819 10.48 2.0994 10.3006 2.09533 10.1238C2.09908 9.90281 2.08658 9.68125 2.10283 9.46063C2.13533 9.09781 2.45158 8.78938 2.81408 8.76313ZM2.81908 9.17125C2.64065 9.205 2.49627 9.37656 2.50221 9.56C2.50096 9.86313 2.50127 10.1666 2.5019 10.4697C2.49065 10.6831 2.68471 10.8813 2.89815 10.8734C3.68033 10.8747 4.46252 10.8744 5.2444 10.8734C5.45971 10.8838 5.65533 10.6844 5.64377 10.4694C5.6444 10.1666 5.64502 9.86344 5.64346 9.56063C5.65158 9.34875 5.45783 9.15438 5.24564 9.16469C4.51565 9.16313 3.78565 9.165 3.05565 9.16375C2.9769 9.16406 2.89752 9.16031 2.81908 9.17125Z" fill="#1D2129" />
        <path d="M7.90987 8.76122C10.1046 8.7506 12.2999 8.75997 14.4949 8.75654C14.6099 8.74247 14.7405 8.81279 14.7505 8.93716C14.7708 9.06497 14.6489 9.17716 14.5239 9.16341C12.3367 9.16466 10.1496 9.16435 7.96268 9.16341C7.87081 9.16997 7.77393 9.12091 7.74362 9.0306C7.69581 8.91372 7.78456 8.77091 7.90987 8.76122Z" fill="#1D2129" />
        <path d="M7.85207 10.3628C7.90707 10.3422 7.96769 10.3456 8.02582 10.3438C9.04707 10.3463 10.0683 10.3428 11.0896 10.3456C11.2211 10.3322 11.3436 10.4625 11.3074 10.5938C11.2849 10.7078 11.1624 10.7638 11.0549 10.7513C10.0124 10.7488 8.96957 10.755 7.92738 10.7481C7.71925 10.7563 7.658 10.4353 7.85207 10.3628Z" fill="#1D2129" />
      </g>
      <defs>
        <clipPath id="clip0_3926_69731">
          <rect width="16" height="16" fill="white" transform="translate(0.399902 0.5)" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default CardBtn;
