import Text from 'components/Text';
import { ICalendarViewMonthRowItem } from 'features/appointment/services/types/calendar';
import moment from 'moment';
import CalendarItem from '../TableView/Body/CalendarItem';
import CalendarMonthStyled from './styles';
type Props = {
  currentMonth: number;
  data: ICalendarViewMonthRowItem;
};
const CalendarMonthRow = ({ data, currentMonth }: Props) => {
  if (!data) return null;
  return (
    <CalendarMonthStyled.TRow>
      {data.colData.map((o, i) => {
        if (!o) return (
          <CalendarMonthStyled.TD key={i} />
        );

        const dateOfMonth = moment(o.value).format(o.month !== currentMonth ? 'DD MMM' : 'DD');
        return (
          <CalendarMonthStyled.TD key={o.id}>
            <div className='col-box'>
              <Text className='date-label' variant='BODY_1' color='text_2'>{dateOfMonth}</Text>
              <CalendarItem data={o.data} isMonthView dateOfMonth={dateOfMonth} />
            </div>
          </CalendarMonthStyled.TD>
        );
      })}
    </CalendarMonthStyled.TRow>
  );
};

export default CalendarMonthRow;
