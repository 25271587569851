import React, { useState } from 'react';
import LogoBox from 'features/checkIn/components/LogoBox';
import Modal from 'components/Modal';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import Text from 'components/Text';
enum ACTIONS {
  REFRESH = 'Refresh',
  BACK_TO_LOGIN = 'Back to login',
}
const ModalHelpers = () => {
  const [visible, setVisible] = useState(false);

  const handle = (action: ACTIONS) => {
    setVisible(false);
    switch (action) {
      case ACTIONS.REFRESH:
        window.location.reload();
        break;
      case ACTIONS.BACK_TO_LOGIN:
        window.location.href = `${location.origin}`;
        break;
      default:
        break;
    }
  };

  return (
    <>
      <button onClick={() => setVisible(true)}><LogoBox /></button>
      <Modal
        visible={!!visible}
        onClose={() => setVisible(false)}
        noneBodyStyle
        containerPadding={1.5}
        hiddenFooter
        modalTitle='Actions'
        className='modal-overflow-unset'
        cancelTitle='Close'
        width={'30.125rem'}
        showClose
      >
        <Buttons gutter={[16, 16]}>
          <Col span={24}>
            <ButtonStation onClick={() => handle(ACTIONS.REFRESH)}>
              <Text variant='H7' color='text_3' className='text'>{ACTIONS.REFRESH}</Text>
            </ButtonStation>
          </Col>
          <Col span={24}>
            <ButtonStation onClick={() => handle(ACTIONS.BACK_TO_LOGIN)}>
              <Text variant='H7' color='text_3' className='text'>{ACTIONS.BACK_TO_LOGIN}</Text>
            </ButtonStation>
          </Col>
        </Buttons>
      </Modal>
    </>
  );
};

export default ModalHelpers;

const Buttons = styled(Row)``;
const ButtonStation = styled.button`
  height: 4.25rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-1, #F6F7FC);
  display: flex;
  align-items: center;
  padding: 1rem;
  .text {
    flex-grow: 1;
    padding: 0.5rem 0;
    text-align: center;
  }
  &:hover {
    border: 1px solid #86909C !important;
    background-color: #E5E6EB !important;
  }
  &:disabled {
    background-color: #E5E6EB !important;
  }
`;
