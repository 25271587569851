const Print = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.87276 5.25L4.875 5.25H19.125L19.1272 5.25C19.8222 5.25208 20.4881 5.52907 20.9795 6.02048C21.4709 6.5119 21.7479 7.1778 21.75 7.87276L21.75 7.875V15.375L21.75 15.3772C21.7479 16.0722 21.4709 16.7381 20.9795 17.2295C20.4881 17.7209 19.8222 17.9979 19.1272 18L19.125 18H18V16.5H19.1238C19.4221 16.4988 19.7079 16.3798 19.9189 16.1689C20.1299 15.9579 20.2489 15.672 20.25 15.3736V7.87639C20.2489 7.57801 20.1299 7.29215 19.9189 7.08114C19.7079 6.87017 19.4221 6.75115 19.1237 6.75H4.87626C4.57792 6.75115 4.29212 6.87017 4.08114 7.08114C3.87017 7.29212 3.75115 7.57792 3.75 7.87626V15.3737C3.75115 15.6721 3.87017 15.9579 4.08114 16.1689C4.29211 16.3798 4.57789 16.4988 4.8762 16.5H6V18H4.875L4.87276 18C4.1778 17.9979 3.5119 17.7209 3.02048 17.2295C2.52907 16.7381 2.25208 16.0722 2.25 15.3772L2.25 15.375V7.875L2.25 7.87276C2.25208 7.1778 2.52907 6.5119 3.02048 6.02048C3.5119 5.52907 4.1778 5.25208 4.87276 5.25Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.14 12C6.92461 12 6.75 12.1746 6.75 12.39V19.86C6.75 20.0754 6.92461 20.25 7.14 20.25H16.86C17.0754 20.25 17.25 20.0754 17.25 19.86V12.39C17.25 12.1746 17.0754 12 16.86 12H7.14ZM5.25 12.39C5.25 11.3462 6.09618 10.5 7.14 10.5H16.86C17.9038 10.5 18.75 11.3462 18.75 12.39V19.86C18.75 20.9038 17.9038 21.75 16.86 21.75H7.14C6.09618 21.75 5.25 20.9038 5.25 19.86V12.39Z"
        fill="#1D2129"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.87276 2.25L7.875 2.25H16.125L16.1272 2.25C16.8222 2.25208 17.4881 2.52907 17.9795 3.02048C18.4709 3.5119 18.7479 4.1778 18.75 4.87276L18.75 4.875V6H17.25V4.8762C17.2488 4.57789 17.1298 4.29211 16.9189 4.08114C16.7079 3.87017 16.4221 3.75115 16.1237 3.75H7.87626C7.57792 3.75115 7.29212 3.87017 7.08114 4.08114C6.87017 4.29212 6.75115 4.57792 6.75 4.87626V6H5.25V4.875L5.25 4.87276C5.25208 4.1778 5.52907 3.5119 6.02048 3.02048C6.5119 2.52907 7.1778 2.25208 7.87276 2.25Z"
        fill="#1D2129"
      />
      <path
        d="M18.375 9.75C18.9963 9.75 19.5 9.24632 19.5 8.625C19.5 8.00368 18.9963 7.5 18.375 7.5C17.7537 7.5 17.25 8.00368 17.25 8.625C17.25 9.24632 17.7537 9.75 18.375 9.75Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default Print;
