import CurrencyInput, {
  unmaskedCurrency,
  useCurrencyInputRef,
} from 'components/CurrencyInputMasked';
import { toCurrency } from 'components/CurrencyInputMasked/currency-conversion';
import Text from 'components/Text';
import { debounce } from 'lodash';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { styled } from 'styled-components';
import { isMobileBrowser } from 'utils/detachBehavior';
import InputKeyboardView, { InputViewProps } from '../InputKeyboardView';
import NumberPad from '../NumberPad';

const isMobile = isMobileBrowser();

export interface ICurrencyKeyBoard {
  value?: number | null;
  onChange?: (value: ICurrencyKeyBoard['value']) => void;
  disableInput?: boolean;
  inputViewProps?: InputViewProps;
  inputClassName?: string;
}

export const useCurrencyKeyBoardRef = () => useRef<CurrencyKeyBoardRef>(null);

export type CurrencyKeyBoardRef = {
  showError: (text: string) => void;
  hideError: () => void;
  clear: () => void;
  setValue: (val: string) => void;
};
const inputPlaceholder = '$0.00';
const CurrencyKeyBoard = forwardRef<CurrencyKeyBoardRef, ICurrencyKeyBoard>(
  (
    { value, disableInput, onChange, inputViewProps = {}, inputClassName = '' },
    ref
  ) => {
    const currencyInputRef = useCurrencyInputRef();
    const [text, setText] = useState<string>();
    const [error, setError] = useState<string | undefined>();

    useImperativeHandle(ref, () => ({
      showError: (text: string) => setError(text),
      hideError: () => setError(''),
      clear: () => onNumberPadChange(''),
      setValue: (_text: string) => onNumberPadChange(_text),
    }));

    const _debounce = useCallback(
      debounce(
        (inputValue: ICurrencyKeyBoard['value']) =>
          typeof onChange === 'function' && onChange(inputValue),
        200
      ),
      [onChange]
    );

    const onNumberPadChange = (val: string) => {
      if (isMobile) {
        const _val = toCurrency(val);
        setText(_val);
        _debounce(unmaskedCurrency(_val));
        return;
      }
      currencyInputRef.current?.setValue(val);
    };

    const onWebInputChange = (
      val: number | null | undefined,
      format: string
    ) => {
      setText(format);
      if (typeof onChange === 'function') onChange(val || null);
    };

    return (
      <CurrencyKeyBoardStyled>
        {!disableInput && (
          <InputViewStyled>
            {isMobile ? (
              <>
                <InputKeyboardView
                  className={`${inputClassName ?? ''}`}
                  value={text}
                  {...inputViewProps}
                  type={'CURRENCY'}
                  placeholder={inputPlaceholder}
                />
              </>
            ) : (
              <>
                <CurrencyInput
                  ref={currencyInputRef}
                  defaultValue={value}
                  onChangeDebounce={onWebInputChange}
                />
              </>
            )}
          </InputViewStyled>
        )}
        {error && (
          <Text
            textAlign="center"
            variant="H5"
            mt={0.5}
            style={{ color: '#f53f3f' }}
          >
            {error}
          </Text>
        )}
        <Text mb={1} />
        <NumberPad value={text} onChange={onNumberPadChange} type={'NUMBER'} />
      </CurrencyKeyBoardStyled>
    );
  }
);
CurrencyKeyBoard.displayName = 'CurrencyKeyBoard';
export default CurrencyKeyBoard;

const CurrencyKeyBoardStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputViewStyled = styled.div`
  width: 100%;
  input {
    width: 100%;
    border-radius: 2px;
    border: 1px solid #86909c;
    background: var(--fill-fill-0, #fff);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    color: var(--text-text-3, #1d2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    height: 4.5rem;

    &:focus,
    &:hover {
      outline: none;
      border: 1px solid var(--primary-primary-hover, #f5767f);
    }
  }
`;
