import CurrencyInput, { CurrencyInputRef, unmaskedCurrency } from 'components/CurrencyInputMasked';
import { toCurrency } from 'components/CurrencyInputMasked/currency-conversion';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { isMobileBrowser } from 'utils/detachBehavior';
import InputKeyboardView, { InputViewProps } from '../InputKeyboardView';
import NumberPad from '../NumberPad';

const isMobile = isMobileBrowser();

export interface ICurrencyKeyBoardTip {
  value?: number | null;
  onChange?: (value: ICurrencyKeyBoardTip['value']) => void;
  disableInput?: boolean;
  inputViewProps?: InputViewProps;
  inputClassName?: string;
  initialAmount?: number;
  modeTip?: boolean;
}

export type CurrencyKeyBoardTipRef = {
  showError: (text: string) => void,
  hideError: () => void,
  clear: () => void,
  setValue: (val: string) => void,
};
const inputPlaceholder = '$0.00';
const CurrencyKeyBoardTip = forwardRef<CurrencyKeyBoardTipRef, ICurrencyKeyBoardTip>(({
  value,
  disableInput,
  onChange,
  inputViewProps = {},
  inputClassName = '',
  initialAmount = 0,
  modeTip,
}, ref) => {
  const inputRef = useRef<CurrencyInputRef>(null);
  const [text, setText] = useState<string>();
  const [error, setError] = useState<string | undefined>();

  useImperativeHandle(ref, () => ({
    showError: (text: string) => setError(text),
    hideError: () => setError(''),
    clear: () => setText(undefined),
    setValue: (_text: string) => onNumberPadChange(_text),
  }));

  const _debounce = useCallback(debounce((inputValue: ICurrencyKeyBoardTip['value']) => typeof onChange === 'function' && onChange(inputValue), 200), [onChange]);

  const onNumberPadChange = (val: string) => {
    if (isMobile) {
      const _val = toCurrency(val);
      setText(_val);
      _debounce(unmaskedCurrency(_val));
      return;
    }
    inputRef.current?.setValue(val);
  };

  const onWebInputChange = (val: number | null | undefined, format: string) => {
    setText(format);
    if (typeof onChange === 'function') onChange(val || null);
  };

  const percent = useMemo(() => {
    const val = unmaskedCurrency(text || '0.00');
    if (!initialAmount) return 0;
    const _percent = ((val / initialAmount) * 100);
    return !isNaN(_percent) ? (_percent)?.toFixed(2)?.replace('.00', '') : 0;
  }, [initialAmount, text]);

  return (
    <CurrencyKeyBoardTipStyled>
      {!disableInput &&
        <InputViewStyled>
          {
            isMobile ? <>
              <InputKeyboardView
                className={`input-mobile ${inputClassName ?? ''}`}
                value={text}
                {...inputViewProps}
                type={'CURRENCY'}
                placeholder={inputPlaceholder}
              />
            </> : <>
              <CurrencyInput
                ref={inputRef}
                defaultValue={value}
                onChangeDebounce={onWebInputChange}
              />
            </>}
        </InputViewStyled>}
      <Text className='text-percent'>{percent}%</Text>
      {error && <Text textAlign='center' mt={0.5} style={{ color: '#f53f3f' }}>{error}</Text>}
      <Text mb={1} />
      <NumberPad
        value={text}
        onChange={onNumberPadChange}
        type={'NUMBER'}
        hint
        modeTip={modeTip}
      />
    </CurrencyKeyBoardTipStyled >
  );
});
CurrencyKeyBoardTip.displayName = 'CurrencyKeyBoardTip';
export default CurrencyKeyBoardTip;

const CurrencyKeyBoardTipStyled = styled.div`
  display: flex;
  flex-direction: column;
  .text-percent {
    color: var(--text-text-2, #767676);
    text-align: center;
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .NUMBER-PAD-BUTTON {
    height: 6rem;
    width: 6rem;
  }
  .NUMBER-PAD {
    font-size: 2.5rem;
  }
`;

const InputViewStyled = styled.div`
  width: 100%;
  input {
    width: 100%;
    border-radius: 2px;
    border: 1px solid #86909C;
    background: none;
    box-shadow: none;
    border: none;

    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    // height: 4.5rem;

    &:focus, &:hover {
      outline: none;
      border: none;
    }

    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .input-mobile {
    background: none;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    .box {
      color: var(--text-text-3, #1D2129);
      &.placeholder {
        color: var(--text-text-3, #1D2129);
      }
      font-family: Open Sans;
      font-size: 3rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      &:after {
        font-size: 2rem;
      }
    }
  }
`;