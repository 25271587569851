import React, { useEffect, useMemo, useState } from 'react';
import { styled } from 'styled-components';
import { COL_CALENDAR_TIME_WIDTH, PIXEL_PER_MINUTE } from '../styles';
import moment from 'moment';
import { CALENDAR_TIME_SET } from 'features/appointment/services/constants';

const RealtimeLine = () => {
  const [currentTime, setCurrentTime] = useState<string>(moment().format());


  useEffect(() => {
    const time = setInterval(() => {
      const { close, open } = CALENDAR_TIME_SET;
      const now = moment().set({ second: 0 });
      if (now.isBetween(open, close)) {
        setCurrentTime(now.format());
      } else {
        clearInterval(time);
      }
    }, 30000);
    return () => {
      clearInterval(time);
    };
  }, []);

  const top = useMemo(() => {
    const { close, open } = CALENDAR_TIME_SET;
    const now = moment(currentTime);
    const currentSpaceMinutes = now.diff(open, 'minutes');
    let space = 0;
    if (now.isBetween(open, close))
      space = PIXEL_PER_MINUTE * currentSpaceMinutes;

    return space;
  }, [currentTime]);

  if (!top) return null;

  return (
    <RealtimeLineStyled style={{ top, left: COL_CALENDAR_TIME_WIDTH }}>
      <div className='circle left'></div>
      <div className='line'></div>
      <div className='circle'></div>
    </RealtimeLineStyled>
  );
};

export default RealtimeLine;

const RealtimeLineStyled = styled.div`
position: absolute;
left: 0;
right: 0;
top: 0;
z-index: 8;
display: flex;
align-items: center;
.line {
  height: 2px;
  background: #e95060;
  width: 100%;
}
.circle {
  width: 10px;
  height: 10px;
  background: #e95060;
  border-radius: 30px;
  &.left {
    position: sticky;
    left: 0;
    z-index: 11;
  }
}
`;