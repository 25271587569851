import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import moment from 'moment';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IHistoryClockInOutResData } from './types/clock-in-out';

const initialState: IState = {
  filterParams: {
    startDate: moment().format('MM-DD-YYYY'),
    endDate: moment().format('MM-DD-YYYY'),
  },
  waitingListData: [],
  summaryData: {
    totalBooking: 0,
    totalWalkIn: 0,
    totalCustomer: 0,
  },
  billWaitingTickets: [],
  staff: {
    clockIn: true,
    detail: null,
  },
  history: {
    data: [],
    total: null,
    params: { page: 1, size: 10 },
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getHistoryClockInOut.success, (state, { payload }) => {
        const data = payload as IHistoryClockInOutResData;
        state.history.data = data.content;
        state.history.total = data.totalElements;
      })
      .addCase(actions.setHistoryClockInOutParams, (state, { payload }) => {
        const _params = {
          ...state.history.params ?? {},
          ...payload,
        };
        state.history.params = _params;
      })
      .addCase(actions.doClockOut.success, (state) => {
        // state.staff.clockIn = false;
        state.staff.detail = null;
      })
      .addCase(actions.doClockIn.success, (state, { payload }) => {
        // state.staff.clockIn = true;
        state.staff.detail = payload;
      })
      .addCase(actions.doFilterData, (state, { payload }) => {
        const _params = {
          ...state.filterParams ?? {},
          ...payload ?? {},
        };
        state.filterParams = _params;
      })
      .addCase(actions.getBillWaitingTicket.success, (state, { payload }) => {
        state.billWaitingTickets = payload;
      })
      .addCase(actions.getSummaryData.success, (state, { payload }) => {
        merge(state.summaryData, payload);
      })
      .addCase(actions.getWaitingList.success, (state, { payload }) => {
        state.waitingListData = payload;
      })
      ;
  },
});

const userServiceReducer = Slice.reducer;
export default userServiceReducer;
