
import { AxiosResponse } from 'axios';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { ITurnDetail, ITurnTable } from './types';
import { IApiGetTurnDetailParam, IApiGetTurnParam, IApiGetTurnStaffsAvailable, IApiUpdateTurnDetailParam, ISyncWaitngToTicket } from './types/api';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import userActions from 'features/user/services/actions';

const getTurn: ISagaFunc<IApiGetTurnParam> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield actions.setTurnLoading(true);
  yield delay(200);
  const params: IApiGetTurnParam = payload;
  try {
    const resData: AxiosResponse<{ data: ITurnTable }> = yield call(apis.getTurn, params);
    if (resData?.data?.data) {
      yield put(actions.getTurn.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTurn.fail({}));
  } finally {
    yield setLoadingPage(false);
    yield actions.setTurnLoading(false);
  }
};

const getTurnDetail: ISagaFunc<IApiGetTurnDetailParam> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  const params: IApiGetTurnDetailParam = payload;
  try {
    const resData: AxiosResponse<{ data: ITurnDetail }> = yield call(apis.getTurnDetail, params);
    if (resData?.data?.data) {
      yield put(actions.getTurnDetail.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTurnDetail.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const getTurnStaffsAvailable: ISagaFunc<IApiGetTurnStaffsAvailable> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  const params: IApiGetTurnStaffsAvailable = payload;
  try {
    const resData: AxiosResponse<{ data: IStaffAvailableItem[] }> = yield call(apis.getTurnStaffsAvailable, params);
    if (resData?.data?.data) {
      yield put(actions.getTurnStaffsAvailable.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTurnStaffsAvailable.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const updateTurnDetail: ISagaFunc<IApiUpdateTurnDetailParam> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  const params: IApiUpdateTurnDetailParam = payload;
  try {
    const resData: AxiosResponse<{ data: boolean }> = yield call(apis.updateTurnDetail, params);
    if (resData?.data?.data) {
      yield all([
        yield put(actions.updateTurnDetail.success({})),
        yield put(actions.setShowModalTurnDetail(false)),
        yield put(actions.getTurn.fetch({}))
      ]);
    }
  } catch (error) {
    yield put(actions.updateTurnDetail.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const syncWaitngToTicket: ISagaFunc<ISyncWaitngToTicket> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  const params: ISyncWaitngToTicket = payload;
  try {
    const resData: AxiosResponse<{ data: boolean }> = yield call(apis.syncWaitngToTicket, params);
    if (resData?.data?.data) {
      yield all([
        yield put(userActions.getWaitingList.fetch()),
        yield put(actions.selectWaitingListResItem(undefined)),
        yield put(actions.getTurn.fetch({})),
        yield put(actions.syncWaitngToTicket.success({})),
      ]);
    }
  } catch (error) {
    yield put(actions.syncWaitngToTicket.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const syncTurn = function* () {
  yield delay(200);
  yield all([
    put(actions.getTurn.fetch({})),
    put(userActions.getWaitingList.fetch()),
  ]);
};

const clearAllTurn = function* () {
  yield setLoadingPage(true);
  yield delay(200);

  try {
    const resData: AxiosResponse<{ data: boolean }> = yield call(apis.clearAllTurn);
    if (resData?.data?.data) {
      yield syncTurn();
    }
  } catch (error) {
    yield put(actions.syncWaitngToTicket.fail({}));
  } finally {
    yield setLoadingPage(false);
  }


};

export default function* turnServiceSagas() {
  yield takeLatest(actions.syncTurn, syncTurn);
  yield takeLatest(actions.getTurn.fetch, getTurn);
  yield takeLatest(actions.getTurnDetail.fetch, getTurnDetail);
  yield takeLatest(actions.getTurnStaffsAvailable.fetch, getTurnStaffsAvailable);
  yield takeLatest(actions.updateTurnDetail.fetch, updateTurnDetail);
  yield takeLatest(actions.syncWaitngToTicket.fetch, syncWaitngToTicket);
  yield takeLatest(actions.clearAllTurn, clearAllTurn);
}
