import React from 'react';
import CardTicketItem from './CardTicketItem';
import colorTheme from 'constants/color';
import { useAppDispatch } from 'store/hooks';
import cashierActions from 'features/cashier/services/actions';
import { FEATURE_PRODUCT_MODE } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';

function Services() {
  const dispatch = useAppDispatch();
  const featureProductMode = cashierSelectors.getFeatureProductMode();
  const setFeatureProduct = () => {
    dispatch(cashierActions.setFeatureProductMode(FEATURE_PRODUCT_MODE.SERVICE));
  };

  const active = featureProductMode === FEATURE_PRODUCT_MODE.SERVICE;
  return (
    <CardTicketItem
      icon={active ? 'cardServiceIconActive' : 'cardServiceIcon'}
      content={'Services'}
      nbgcolor={colorTheme.primary_button}
      textColor={colorTheme.text_3}
      border_color={colorTheme.primary_hover}
      active={active}
      onClick={setFeatureProduct}
    />
  );
}

export default Services;