import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import styled from 'styled-components';
import colorTheme from 'constants/color';

export function Draggable(props: {
  id: any;
  children:
  | string
  | number
  | boolean
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | Iterable<React.ReactNode>
  | React.ReactPortal
  | null
  | undefined;
  data: any,
}) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
    data: props.data,
  });
  const styles = transform
    ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      borderRadius: '5px',
      backgroundColor: '#fff',
    }
    : undefined;
  // const styles = {
  //   transform: CSS.Transform.toString(transform),
  // };

  return (
    <DraggableStyled
      ref={setNodeRef}
      style={styles}
      {...listeners}
      {...attributes}
    >
      {props.children}
    </DraggableStyled>
  );
}
const DraggableStyled = styled.div`
  .service-item {
    border: 1px solid ${colorTheme.line_2};
  }
  .service-item:last-child {
    border: none;
  }
`;
