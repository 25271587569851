import { Modal } from 'antd';
import Button from 'components/Button';
import Form, { useForm } from 'components/Form';
import InputEmail from 'components/InputEmail';
import Text from 'components/Text';
import paymentActions from 'features/payment/services/actions';
import React, { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import send_email_success from '../../images/send_email_success.png';
import GroupButtonFooterModal, { BtnProps } from '../GroupButtonFooterModal';

const SendEmailSuccess = () => {
  return (
    <div className="pay-sent-email">
      <img src={send_email_success} />
      <Text variant="H5">Success</Text>
    </div>
  );
};

interface IBtnShowSendEmailModal { }

const BtnShowSendEmailModal: React.FC<IBtnShowSendEmailModal> = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isSentEmail, setIsSentEmail] = useState(false);

  const onFinish = () => undefined;
  const onFinishFailed = () => undefined;

  const [form] = useForm();
  const getValue = () => {
    // handle send email here
    setIsSentEmail(true);
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };
  const btnPropsBeforeSend: BtnProps[] = [
    {
      buttonName: 'Cancel',
      onClick: handleClose,
      type: 'DEFAULT',
    },
    {
      buttonName: 'Done',
      onClick: getValue,
      type: 'PRIMARY',
    },
  ];

  const dispatch = useAppDispatch();
  // sent email success
  const handleAfterSentEmail = () => {
    setIsOpenModal(false);
    dispatch(paymentActions.sendInfoPaySuccess(true));
  };
  const btnPropsAfterSend: BtnProps[] = [
    {
      buttonName: 'Success',
      onClick: handleAfterSentEmail,
      type: 'PRIMARY',
    },
  ];

  return (
    <>
      <Button
        onClick={() => {
          setIsOpenModal((previous) => !previous);
        }}
        ntype="DEFAULT"
        icon="email"
      >
        Send Email
      </Button>
      <SendEmailModalStyled
        footer={
          <GroupButtonFooterModal
            buttonSequence={
              isSentEmail ? btnPropsAfterSend : btnPropsBeforeSend
            }
          />
        }
        title={!isSentEmail ? 'Send Email' : ''}
        closable={false}
        open={isOpenModal}
        onCancel={() => undefined}
        width={550}
        centered
      >
        {!isSentEmail ? (
          <Form
            name="payment_basic"
            initialValues={{ email: '' }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            form={form}
          >
            <InputEmail
              name={'email'}
              rules={[
                { type: 'email' },
                { required: true, message: 'Please input your email!' },
              ]}
            />
          </Form>
        ) : (
          SendEmailSuccess()
        )}
      </SendEmailModalStyled>
    </>
  );
};

export default BtnShowSendEmailModal;

const SendEmailModalStyled = styled(Modal)`
  .pay-sent-email {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
