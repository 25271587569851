import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
type ICountingFooterProps = {
  children: React.ReactNode;
  closeButtonTitle?: string;
  submitButtonTitle?: string;
  handleCloseModal?: () => void;
  onSubmit?: () => void;
  isValid?: boolean;
  style?: CSSProperties,
};
const CountingFooter: React.FC<ICountingFooterProps> = ({
  children,
  closeButtonTitle = 'Cancel',
  submitButtonTitle = 'OK',
  handleCloseModal,
  onSubmit = () => undefined,
  isValid = false,
  style,
}) => {
  return (
    <CountingFooterStyled style={style}>
      {children}
      <Box display="flex" gap="5">
        <Button ntype="SECONDARY" onClick={handleCloseModal}>
          <Text variant="CONTENT_2" color="text_3">
            {closeButtonTitle}
          </Text>
        </Button>
        <Button ntype="PRIMARY" onClick={onSubmit} disabled={!isValid}>
          <Text variant="CONTENT_2" color="text_3">
            {submitButtonTitle}
          </Text>
        </Button>
      </Box>
    </CountingFooterStyled>
  );
};

export default CountingFooter;
const CountingFooterStyled = styled.div`
  border-radius: 0px 0px 5px 5px;
  background: #e5e6eb;
  width: 100%;
`;
