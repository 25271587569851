import React, { useEffect, useRef, useState } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import ticketListActions from 'features/ticketList/services/actions';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ticketListSelectors from 'features/ticketList/services/selectors';
import moment from 'moment';
import CurrencyKeyBoard, {
  CurrencyKeyBoardRef,
} from 'components/NumberKeyBoard/Currency';
type IRefundButtonProps = { maxAmount: number, disabled?: boolean };
const RefundButton: React.FC<IRefundButtonProps> = ({ maxAmount, disabled: _disabled }) => {
  const { id } = useParams();
  const ticketDetails = ticketListSelectors.ticketDetailsData.data();
  const currentTime = moment();
  const endTime = moment(ticketDetails?.endTime);
  const difference = endTime.diff(currentTime, 'hours');
  const disableButton = difference <= 24;
  const disabled = _disabled || ticketDetails?.billStatus !== 'CLOSED' && disableButton;
  const dispatch = useAppDispatch();
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const [openRefund, setOpenRefund] = useState(false);
  const [refundValue, setRefundValue] = useState<number | null | undefined>();

  const handleShowRefundModal = () => {
    setOpenRefund(true);
    setTimeout(() => currencyRef.current?.setValue(maxAmount?.toFixed(2)), 100);
  };
  const handleCloseRefundModal = () => {
    currencyRef.current?.hideError();
    setRefundValue(0);
    setOpenRefund(false);
  };

  const handleSubmitRefund = () => {
    const payload = {
      billId: id || '',
      amount: Number(refundValue),
    };
    dispatch(ticketListActions.refundTicketByBillId.fetch(payload));
    handleCloseRefundModal();
  };

  useEffect(() => {
    if (refundValue && openRefund && refundValue > maxAmount) {
      currencyRef.current?.showError(
        `Max refund must be $${maxAmount?.toFixed(2)}`
      );
    } else {
      currencyRef.current?.hideError();
    }
  }, [refundValue, openRefund]);

  return (
    <RefundButtonStyled>
      <Button
        disabled={disabled}
        ntype="ORANGE"
        icon="refundMoney"
        onClick={handleShowRefundModal}
      >
        Refund
      </Button>
      <Modal
        modalTitle={'REFUND'}
        visible={openRefund}
        onClose={handleCloseRefundModal}
        onSubmit={handleSubmitRefund}
        destroyOnClose={true}
        disableOk={!!(refundValue && refundValue > maxAmount)}
      >
        <CurrencyKeyBoard
          ref={currencyRef}
          value={refundValue}
          onChange={setRefundValue}
        />
      </Modal>
    </RefundButtonStyled>
  );
};

export default RefundButton;
const RefundButtonStyled = styled.div``;
