import { ConfigProvider } from 'antd';
import RootScreens from 'features/RootScreens';
import React, { useEffect } from 'react';
import 'react-multi-carousel/lib/styles.css';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingPage from 'services/UI/LoadingPage';
import store from 'store';
import { createGlobalStyle } from 'styled-components';

const AppLayout: React.FC = () => <RootScreens />;

const App: React.FC = () => {
  useEffect(() => {
    const handleKeyDown = () => {
      // Kiểm tra nếu mã phím là F12 (mã 123) hoặc ESC (mã 27) thì chặn sự kiện
      // if (event.keyCode === 123 || event.keyCode === 27) {
      //   event.preventDefault();
      //   return;
      // }
    };

    window.addEventListener('keydown', handleKeyDown, false);

    return () => {
      window.removeEventListener('keydown', handleKeyDown, false);
    };
  }, []);

  return (
    <>
      <ConfigProvider
        theme={{
          'token': {
            'colorPrimary': '#ff8890',
            'colorPrimaryBg': '#FFF1F6',
            'colorPrimaryActive': '#FF8890',
            'colorText': '#1D2129',
            'colorTextSecondary': '#767676',
            'colorBorder': '#86909C',
            'colorBorderSecondary': '#C9CDD4',
            'colorFill': '#E5E6EB',
            'colorFillSecondary': '#E5E5E5',
            'colorFillTertiary': '#F6F7FC',
            'colorBgLayout': '#E5E5E5',
            'colorBgSpotlight': '#1D2129',
            'colorBgMask': 'rgba(0, 0, 0, 0.2)',
            'colorTextBase': '#1d2129',
            'colorBgBase': '#ffffff',
            'colorWarning': '#ff7d00',
            'colorError': '#f53f3f',
            'colorInfo': '#6fabb6',
            'fontSize': 16,
            'sizeStep': 4,
            'borderRadius': 5,
            'borderRadiusXS': 2,
            'wireframe': false
          },
        }}
      >
        <Provider store={store}>
          <GlobalStyle />
          <AppLayout />
          <LoadingPage />
          <ToastContainer />
        </Provider>
      </ConfigProvider>
    </>
  );
};

export default App;

const GlobalStyle = createGlobalStyle<any>`
:root {
  
}
`;