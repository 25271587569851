export const TurnStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    DONE: 'DONE',
    FINISH_TURN: 'FINISH_TURN',
    VOIDED: 'VOIDED',
};

export const TurnType = {
    WALK_IN: 'WALK-IN',
    APPOINTMENT: 'APPOINTMENT',
    BONUS: 'BONUS',
    REQUEST: 'REQUEST',
    VOIDED: 'VOIDED',
};

export const TurnTypeSortName = {
    [TurnType.WALK_IN]: 'Walk-in',
    [TurnType.APPOINTMENT]: 'Appt.',
    [TurnType.VOIDED]: 'Voided',
    [TurnType.BONUS]: 'Bonus',
    [TurnType.REQUEST]: 'Reqs',
};

export const TagBgColor = {
    WALK_IN: '#adadad',
    APPOINTMENT: '#1d2129',
    BONUS: '#ff7d00',
    REQUEST: '#ffdc7c',
};

export const TURN_BOX_WITDH = 160;
export const MIN_TURN_BOX_WITDH_COL_SPAN = TURN_BOX_WITDH / 2 + 5;
export const TURN_BOX_HEIGHT = 90;
export const FULL_TURN_WITDH = TURN_BOX_WITDH;
export const HALF_TURN_WITDH = TURN_BOX_WITDH / 2;
export const MAX_TUR_DEFAULT = 10;
export const HALF_TURN = 0.5;
export const SCALE_TABLE_KEY_LOCALE_STORAGE = 'scale-view-turn-screen';
