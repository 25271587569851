import { Button as ButtonAnt } from 'antd';
import Button from 'components/Button';
import colorTheme from 'constants/color';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { useState } from 'react';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import { Bill } from '../../services/types/api';
import ModalInputReasonVoid from './ModalReason';
export type IVoidFuncType = (val: {
  id: string;
  reason: string;
}) => void;

type Props = {
  isDetail?: boolean;
  ticketDetails?: Bill | undefined;
  disabled?: boolean;
  onVoid?: IVoidFuncType;
};
const VoidButtonHandler = ({ isDetail, ticketDetails, disabled: _disabled, onVoid = () => undefined }: Props) => {
  const disabled = _disabled || ticketDetails?.billStatus !== 'CLOSED';
  const isCash = ticketDetails?.payments?.some(
    (item) => item?.paymentType === PAYMENT_TYPE.CASH
  );

  const [visiblePW, setVisiblePW] = useState(false);

  const [modalReason, setModalReason] = useState(false);

  const handleShowModal = () => {
    if (isCash) {
      setVisiblePW(true);
    } else {
      setModalReason(true);
    }
  };

  const onVerifyAdminSuccess = () => {
    setVisiblePW(false);
    setModalReason(true);
  };

  const handleVoid = (reason: string) => {
    const payload = {
      id: ticketDetails?.billId || '',
      reason,
    };
    onVoid(payload);
    setModalReason(false);
  };

  const ButtonUI = () => {
    if (isDetail) {
      return (
        <Button
          disabled={disabled}
          ntype="YELLOW"
          icon="voidList"
          onClick={handleShowModal}
        >
          Void
        </Button>
      );
    }

    return <ActionButton onClick={handleShowModal}>VOID</ActionButton>;
  };

  if (!ticketDetails) return null;

  return (
    <>
      <ButtonUI />
      {visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={() => setVisiblePW(false)}
        />
      )}
      {modalReason && (
        <ModalInputReasonVoid visible={modalReason} onVoid={handleVoid} onClose={() => setModalReason(false)} />
      )}
    </>
  );
};

export default VoidButtonHandler;

const ActionButton = styled(ButtonAnt)`
  display: flex;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${colorTheme.info_2} !important;
  flex-grow: 1;
  flex-basis: 1rem;
  cursor: pointer;
  color: ${colorTheme.text_3} !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-color: ${colorTheme.info_2} !important;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }
`;
