import { SummaryStaff } from 'features/report/services/types/reportStaff';
import { formatCurrency } from 'utils/formatCurrency';


const salary = (o: SummaryStaff) => {
  return o.commissionValue || 0;
};
const payroll = (o: SummaryStaff) => {
  const salaryValue = salary(o);
  return salaryValue + o.tip - o.discount;
};

export const minusValue = (o: number) => {
  if (o <= 0) return '$0.00';
  return `-${formatCurrency(o)}`;
};

const employeeCalcMethods = {
  salary,
  payroll,
};

export default employeeCalcMethods;
