import colorTheme from 'constants/color';
import React from 'react';
import styled from 'styled-components';

const BOUNDING_BOX_TYPE = {
  CIRCLE: 'CIRCLE_BOUNDING_BOX',
  SQUARE: 'SQUARE',
  RECTANGLE: 'RECTANGLE',
  SQUARE_ICON: 'SQUARE_ICON',
  ELLIPSE: 'ELLIPSE',
};

const BOUNDING_BOX_CSS = {
  [BOUNDING_BOX_TYPE.CIRCLE]: `
    border-radius: 50%;
    flex-shrink: 0;
    box-shadow: -5px 5px 8px 0px rgba(0, 0, 0, 0.10) inset;
    filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.25));
  `,

  [BOUNDING_BOX_TYPE.SQUARE]: `
    border-radius: 0.3125rem;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  `,

  [BOUNDING_BOX_TYPE.RECTANGLE]: `
    display: flex;
    padding: 0.845rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.845rem;
  `,

  [BOUNDING_BOX_TYPE.SQUARE_ICON]: `
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0.3125rem 0.3125rem 0;
  `,

  [BOUNDING_BOX_TYPE.ELLIPSE]: `
    border-radius: 3.125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.875rem;
  `,
};

interface IBoundingBoxProps {
  children?: React.ReactNode;
  type?: keyof typeof BOUNDING_BOX_TYPE;
  className?: string;
  color?: keyof typeof colorTheme;
  onClick?: () => void;
}

const BoundingBox: React.FC<IBoundingBoxProps> = ({
  onClick = () => undefined,
  children,
  type,
  className,
  color,
}) => {
  return (
    <BoundingBoxStyled
      onClick={onClick}
      color={color}
      className={`${type} ${className ?? ''}`}
    >
      {children}
    </BoundingBoxStyled>
  );
};

export default BoundingBox;
type BoundingBoxStyledProps = {
  color?: keyof typeof colorTheme;
};
const BoundingBoxStyled = styled.div<BoundingBoxStyledProps>`
    ${Object.entries(BOUNDING_BOX_CSS).map(
      ([key, css]) => `
      &.${key} {
        ${css}
      }
    `
    )}
    background-color: ${({ color }) =>
      color ? colorTheme[color] : 'transparent'}
`;
