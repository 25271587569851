import { styled } from 'styled-components';

export const CustomerStyled = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const ContentBoxStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
