import { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import type { Moment as MomentType } from 'moment';
import { styled } from 'styled-components';
import InputDOBMasked from './inputMasked';

export type InputDOBProps = Omit<PickerDateProps<MomentType>, 'format' | 'picker' | 'onKeyDown'> & {}

const InputDOB = ({ ...props }: InputDOBProps) => {
  return (
    <Styled>
      <InputDOBMasked
        {...props}
      />
    </Styled>
  );
};

export default InputDOB;

const Styled = styled.div`
  input {
    box-sizing: border-box;
    margin: 0;
    padding: 3px 11px;
    color: #1D2129;
    font-size: 16px;
    line-height: 1.5;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #86909C;
    border-radius: 5px;
    transition: all 0.2s;

    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
    height: 3rem;
    &:hover, &:focus, &:focus-visible {
      outline: none;
      border-color: #ffb0b3;
      border-inline-end-width: 1px;
    }
  }
`;