import Button from 'components/Button';
import Text from 'components/Text';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import cashapp from './cashapp.png';
import paypal from './paypal.png';
import venmo from './venmo.png';
import zelle from './zelle.png';

const ICON_PAYMENT = {
  ['VENMO']: venmo,
  ['PAYPAL']: paypal,
  ['CASH_APPS']: cashapp,
  ['ZELLE']: zelle,
};
type Props = {
  isCustomer?: boolean;
};
const QRPaymentPage = ({ isCustomer }: Props) => {
  const dispatch = useAppDispatch();
  const payments = shopSelectors.data.orderPaymentMethods();
  const { id = '', type = '' } = useParams();
  const [detailTicket, setDetailTicket] = useState<IBillDetailData | null>(null);
  const setLoadingPage = useSetLoadingPage();
  const socketContext = useSocketContext();
  const ticketContext = useTicketWrapper();

  const activePayment = useMemo(() => {
    return payments.find(o => o.methodType === type);
  }, [type, payments]);

  const activePaymentIcon = useMemo(() => {
    return get(ICON_PAYMENT, [type], '');
  }, [type]);

  const geDetailAsync = async () => {
    const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(id);
    const detail = res?.data?.data;
    if (detail) {
      setDetailTicket(detail);
      return detail;
    }
    return null;
  };
  const getDetailTicket = async () => {
    setLoadingPage(true);
    try {
      await geDetailAsync();

    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };
  useEffect(() => {
    if (detailTicket) return;
    dispatch(shopActions.get.otherPaymentMethod.fetch());
    getDetailTicket();
  }, []);

  const onDone = async () => {
    if (!detailTicket?.billId) return;

    const body: IBodyAPIPayment = {
      billId: detailTicket?.billId || '',
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.CHECK,
          amount: detailTicket?.total || 0,
          otherMethod: type,
        },
      ]
    };

    setLoadingPage(true);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${detailTicket?.billId}`);
        await ticketContext.completedTicket(detailTicket?.billId);
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return (
    <QRPaymentPageStyled>
      <Text className="title" mb={0.5}>Pay</Text>
      <Text className="price" mb={3.5}>{formatCurrency(detailTicket?.total)}</Text>
      <BoxQR>
        <Text variant='H3'>{activePayment?.name}</Text>
        <Text variant='H4' color='text_2' mb={1}>{activePayment?.email}</Text>
        <img
          src={activePayment?.imageQrCode}
          className='imageQR'
        />
        {activePaymentIcon && <div className='imageIcon'>
          <img
            src={activePaymentIcon}
          />
        </div>}
      </BoxQR>
      {!isCustomer && <Button onClick={onDone} width="8.25rem" ntype="PRIMARY" >
        Done
      </Button>}
    </QRPaymentPageStyled>
  );
};

export default QRPaymentPage;

const BoxQR = styled.div`
  border-radius: 15.37px;
  background: #FFF;
  box-shadow: -0.76851px 0.76851px 3.07404px 0px rgba(0, 0, 0, 0.10) inset, 0px 3.07404px 3.07404px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  padding: 1.5rem;
  max-width: 36.5rem;
  margin-bottom: 1rem;
  .imageQR {
    height: 13rem;
    width: 13rem;
    margin-bottom: 1rem;
  }
  .imageIcon {
    width: 11rem;
    height:3rem;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: 100%;
    }
  }
`;

const QRPaymentPageStyled = styled.div`
  background: var(--fill-fill-3, #E5E5E5);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .title { 
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 1.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .price {
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;