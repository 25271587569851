import { ISagaFunc } from 'services/actionConfigs';
import {
  ICouponsParams,
  ICustomer,
  IGetCustomersParam,
  IReferralsParams,
  IResCustomers,
  IResSummaryReport,
} from './types/api';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import actions from './actions';
import { setLoading, setLoadingPage } from 'services/UI/sagas';
import { AxiosResponse } from 'axios';
import { PATH_LOADING } from './constants';
import {
  getAppointmentParams,
  getCouponsParams,
  getCustomerFilterParams,
  getReferralsParams,
  getTransactionsParams,
} from './selectors';
import { IAppointmentParams, IResAppointment } from './types/appointment';
import { ITransactionsParams } from './types/reducer';
import { CustomerDetailsForm } from './types/customer';

const getCustomers: ISagaFunc<IGetCustomersParam> = function* ({ payload }) {
  yield delay(200);
  yield setLoading(PATH_LOADING.getCustomers, true);
  try {
    const resData: AxiosResponse<{ data: IResCustomers }> = yield call(
      apis.getCustomers,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getCustomers.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getCustomers.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCustomers, false);
  }
};

const setCustomersParams: ISagaFunc<IGetCustomersParam> = function* ({
  payload,
}) {
  const storeParams = yield select(getCustomerFilterParams);
  const _params = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };
  yield put(actions.getCustomers.fetch(_params));
};

const getCustomersDetails: ISagaFunc<string> = function* ({ payload }) {
  yield delay(200);
  yield setLoadingPage(true);
  try {
    const resData: AxiosResponse<{ data: ICustomer }> = yield call(
      apis.getCustomerDetails,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getCustomerDetails.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getCustomerDetails.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const getAppointments: ISagaFunc<IAppointmentParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getAppointments, true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: IResAppointment }> = yield call(
      apis.getAppointments,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getAppointmentList.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getAppointmentList.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getAppointments, false);
  }
};

const setAppointmentParams: ISagaFunc<IAppointmentParams> = function* ({
  payload,
}) {
  const storeParams = yield select(getAppointmentParams);
  const _params = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };
  yield put(actions.getAppointmentList.fetch(_params));
};

const getTransactions: ISagaFunc<ITransactionsParams> = function* ({
  payload,
}) {
  yield setLoading(PATH_LOADING.getTransactions, true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.getTransactions,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getTransactionsList.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTransactionsList.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getTransactions, false);
  }
};

const setTransactionsParams: ISagaFunc<ITransactionsParams> = function* ({
  payload,
}) {
  const storeParams = yield select(getTransactionsParams);
  const _params = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };
  yield put(actions.getTransactionsList.fetch(_params));
};
const getReferrals: ISagaFunc<IReferralsParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getReferrals, true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.getReferrals,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getReferrals.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getReferrals.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getReferrals, false);
  }
};

const setReferralsParams: ISagaFunc<IReferralsParams> = function* ({
  payload,
}) {
  const storeParams = yield select(getReferralsParams);
  const _params = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };
  yield put(actions.getReferrals.fetch(_params));
};
const getSummaryReport: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getSummaryReport, true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: IResSummaryReport }> = yield call(
      apis.getSummaryReport,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getSummaryReport.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getSummaryReport.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getSummaryReport, false);
  }
};
const getCustomersActive: ISagaFunc<{ lastPhoneNumber?: number | string }> =
  function* ({ payload }) {
    yield setLoading(PATH_LOADING.getCustomersActive, true);
    yield delay(200);
    try {
      const resData: AxiosResponse<{ data: any }> = yield call(
        apis.getCustomersActive,
        payload
      );
      if (resData?.data?.data) {
        yield put(actions.getCustomersActive.success(resData.data.data));
      }
    } catch (error) {
      yield put(actions.getCustomersActive.fail({}));
    } finally {
      yield setLoading(PATH_LOADING.getCustomersActive, false);
    }
  };

const deleteCustomer: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.deleteCustomer,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.deleteCustomer.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.deleteCustomer.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const editCustomer: ISagaFunc<CustomerDetailsForm> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.editCustomer,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.editCustomer.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.editCustomer.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const getCoupons: ISagaFunc<ICouponsParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getCoupons, true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.getCoupons,
      payload
    );
    if (resData?.data?.data) {
      yield put(actions.getCoupons.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getCoupons.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCoupons, false);
  }
};

const setCouponsParams: ISagaFunc<ICouponsParams> = function* ({ payload }) {
  const storeParams = yield select(getCouponsParams);
  const _params = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };
  yield put(actions.getCoupons.fetch(_params));
};

export default function* customerServiceSagas() {
  yield takeLatest(actions.getCustomers.fetch, getCustomers);
  yield takeLatest(actions.setCustomerParam, setCustomersParams);
  yield takeLatest(actions.getCustomerDetails.fetch, getCustomersDetails);
  yield takeLatest(actions.getAppointmentList.fetch, getAppointments);
  yield takeLatest(actions.setAppointmentParams, setAppointmentParams);
  yield takeLatest(actions.getReferrals.fetch, getReferrals);
  yield takeLatest(actions.setReferralsParams, setReferralsParams);
  yield takeLatest(actions.getTransactionsList.fetch, getTransactions);
  yield takeLatest(actions.setTransactionsParams, setTransactionsParams);
  yield takeLatest(actions.getSummaryReport.fetch, getSummaryReport);
  yield takeLatest(actions.getCustomersActive.fetch, getCustomersActive);
  yield takeLatest(actions.deleteCustomer.fetch, deleteCustomer);
  yield takeLatest(actions.editCustomer.fetch, editCustomer);

  yield takeLatest(actions.getCoupons.fetch, getCoupons);
  yield takeLatest(actions.setCouponsParams, setCouponsParams);
}
