import cashierSelectors from 'features/cashier/services/selectors';
import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import BillSelectedItems from './BillSelectedItems';
import GroupActions from './GroupActions';
import DiscountItemModal from '../Discount/DiscountItem';
import UpdateQuickPay from './UpdateQuickPay';
type IComponentProps = {};
const RightMainBody: React.FC<IComponentProps> = () => {
  const ticketDetails = cashierSelectors.getTicketDetails();
  const createdDate = useMemo(() => ticketDetails?.ticketInfo?.createdDate ? moment(ticketDetails?.ticketInfo?.createdDate) : null, [ticketDetails]);
  return (
    <RightMainStyled>
      <ContainerStyled>
        <ButtonContainerStyled>
          <div className='ticket-info'>
            <div className="ticket-number"><p>#{ticketDetails?.ticketInfo.ticketNumber}</p></div>
            <p className='bold-date'>{createdDate?.format('MM-DD-YYYY')}</p>
            <p className='bold-date'>{createdDate?.format('LT')}</p>
          </div>
          <GroupActions />
        </ButtonContainerStyled>
        <BillSelectedItems />
        <DiscountItemModal />
        <UpdateQuickPay />
      </ContainerStyled>
    </RightMainStyled>
  );
};

export default RightMainBody;
const RightMainStyled = styled.div`
  height: calc(100vh - 6.375rem);
  background-color: #fff;
  overflow: auto;
  background-color: #e5e5e5;
  display: flex;
`;
const ContainerStyled = styled.div`
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.75rem;
  .ticket-info {
    white-space: nowrap;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .bold-date {
      font-weight: bolder;
      font-size: 18px;
    }
  }
  .ticket-number {
    white-space: nowrap;
    border-radius: 5px;
    background: #1D2129;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding: 0 0.5rem;
    margin-bottom: 1rem;

    p {
      color: var(--text-text-1, #FFF);
      text-align: center;
      /* Title/H5 - 24 semibold */
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;
