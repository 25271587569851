import { Row } from 'antd';
import { ICalendarHeader, ICalendarSchema } from 'features/appointment/services/types/calendar';
import CalendarStyled, { fixedStyle } from './styles';
type Props = {
  headers: ICalendarHeader<any>[];
  renderHeader: ICalendarSchema['renderHeader'];
};
const CalendarViewHeader = ({ headers = [], renderHeader = () => undefined }: Props) => {
  return (
    <CalendarStyled.THead>
      <CalendarStyled.TRow>
        <CalendarStyled.TH fixedHigh style={fixedStyle}>
          <Row className='actions' justify={'space-between'} />
        </CalendarStyled.TH>
        {headers.length > 0 ? headers.map((item) => {
          return (
            <CalendarStyled.TH
              key={item.id}
              fixed
            >
              <div>
                {renderHeader(item)}
              </div>
            </CalendarStyled.TH>
          );
        }) : <>
          <CalendarStyled.TH fixed />
        </>}
      </CalendarStyled.TRow>
    </CalendarStyled.THead>
  );
};

export default CalendarViewHeader;
