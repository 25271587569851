
const EPayIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.88184 2.01373C6.23979 1.99337 9.59618 1.99807 12.9541 2.01216C12.9604 2.24553 12.9682 2.4789 12.9761 2.71226C9.85147 2.71069 6.72688 2.72792 3.60229 2.70443C3.61169 3.73656 3.60543 4.76869 3.61012 5.80083C3.36736 5.86817 3.1246 5.93709 2.88184 6.006C2.8975 4.67629 2.88653 3.34501 2.88184 2.01373Z" fill="#1D2129" />
      <path d="M4.28516 3.4863C7.63998 3.47847 10.9948 3.48004 14.3496 3.48473C14.8821 3.43775 15.4569 3.78231 15.5071 4.34928C15.554 5.12142 15.5133 5.89513 15.5227 6.66884C15.6402 6.66884 15.8751 6.66727 15.9926 6.66727C16.002 7.52242 15.991 8.37601 16.0004 9.22959C15.8814 9.22333 15.6464 9.2108 15.5274 9.20453C15.5086 9.97824 15.5572 10.7519 15.5039 11.5225C15.4742 12.0362 14.9824 12.409 14.4874 12.4027C12.6299 12.4168 10.7708 12.4012 8.91331 12.409C8.98692 12.1803 9.0574 11.9517 9.12788 11.7214C10.8257 11.6604 12.5234 11.7324 14.2212 11.6948C14.4295 11.6729 14.7302 11.64 14.7568 11.3769C14.8884 10.6689 14.785 9.94222 14.8273 9.22646C13.8782 9.21863 12.9291 9.21236 11.9784 9.22646C11.9831 8.37288 11.9784 7.51929 11.9768 6.66571C12.9275 6.66727 13.8766 6.66884 14.8273 6.66101C14.8179 5.92489 14.8038 5.19034 14.8054 4.45422C14.5783 4.30699 14.3293 4.17387 14.0458 4.20519C10.7943 4.20519 7.54287 4.19893 4.29142 4.20989C4.28829 3.96869 4.28672 3.7275 4.28516 3.4863ZM12.7051 7.37207C12.7004 7.75422 12.702 8.13638 12.7051 8.51853C13.565 8.5107 14.4264 8.51697 15.2878 8.5154C15.2878 8.13481 15.2878 7.75579 15.2893 7.3752C14.4279 7.37833 13.5665 7.3752 12.7051 7.37207Z" fill="#1D2129" />
      <path d="M3.45784 6.18227C5.61137 5.63723 8.00768 6.95128 8.70464 9.05784C9.44233 11.0501 8.50886 13.4886 6.61531 14.4581C5.15091 15.2788 3.22447 15.1488 1.86813 14.1652C0.380233 13.1237 -0.337091 11.1268 0.153133 9.37421C0.560347 7.808 1.88693 6.54877 3.45784 6.18227ZM3.25736 7.01863C2.12186 7.39609 1.20719 8.36557 0.889251 9.5183C0.558781 10.6569 0.820339 11.9444 1.56429 12.8684C2.51811 14.0822 4.25817 14.5787 5.71005 14.0493C6.48845 13.8128 7.08988 13.2349 7.59889 12.6257C7.34517 12.6272 7.08988 12.6288 6.83615 12.6319C6.13919 13.3242 5.16657 13.7878 4.16733 13.6562C2.70605 13.5341 1.48128 12.2748 1.33719 10.8277C3.63638 10.823 5.93558 10.8417 8.23634 10.8167C8.25514 9.61854 7.78841 8.3828 6.82206 7.63728C5.84161 6.83852 4.45081 6.59575 3.25736 7.01863Z" fill="#1D2129" />
      <path d="M3.13104 7.70422C4.1757 7.18737 5.49601 7.33773 6.40441 8.06445C7.11078 8.5719 7.48666 9.40199 7.63389 10.2383C5.53047 10.2509 3.42705 10.2556 1.3252 10.2336C1.47868 9.16549 2.13806 8.16312 3.13104 7.70422Z" fill="#1D2129" />
    </svg>
  );
};

export default EPayIcon;
