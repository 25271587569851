import React from 'react';

const GGPayIcon = () => {
  return (
    <svg width="42" height="17" viewBox="0 0 42 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 625348">
        <g id="Group 625347">
          <g id="#ff4031ff">
            <path id="Vector" d="M3.04043 1.14608C4.38144 0.292491 6.04304 -0.0172726 7.60576 0.250004C8.8031 0.445441 9.92782 1.01553 10.8131 1.84208C10.199 2.45929 9.58175 3.07341 8.96532 3.68907C8.03835 2.79686 6.6224 2.50332 5.40189 2.88029C4.20686 3.23563 3.23277 4.21281 2.8419 5.39238C2.12504 4.8362 1.40741 4.28001 0.690552 3.72306C1.22124 2.67249 2.04161 1.76869 3.04043 1.14608Z" fill="black" />
          </g>
          <g id="#ffbb00ff">
            <path id="Vector_2" d="M0.690533 3.72339C1.40739 4.28034 2.12502 4.83653 2.84188 5.39271C2.57383 6.19145 2.57383 7.07439 2.84188 7.87313C2.12425 8.42854 1.40739 8.9855 0.691305 9.54245C0.250994 8.67573 0.0200233 7.70859 -6.10352e-05 6.73759V6.41779C0.0401077 5.48463 0.265671 4.55766 0.690533 3.72339Z" fill="black" />
          </g>
          <g id="#0085f7ff">
            <path id="Vector_3" d="M6.47789 5.41726C8.50642 5.41648 10.5349 5.41648 12.5635 5.41803C12.7597 6.54816 12.7187 7.72464 12.4043 8.82928C12.1131 9.85668 11.5554 10.8138 10.7698 11.5407C10.0745 11.0015 9.37545 10.4669 8.68409 9.92311C9.3685 9.46735 9.8428 8.725 9.98339 7.91544C8.81464 7.91313 7.64588 7.91622 6.47712 7.9139C6.47712 7.08194 6.47635 6.24999 6.47789 5.41726Z" fill="black" />
          </g>
          <g id="#00a94bff">
            <path id="Vector_4" d="M0.691315 9.54212C1.4074 8.98517 2.12426 8.42821 2.84189 7.8728C3.27757 9.19374 4.45327 10.2528 5.83214 10.4869C6.80624 10.6444 7.85371 10.4722 8.68413 9.92295C9.37549 10.4668 10.0746 11.0013 10.7698 11.5405C9.01938 13.1573 6.31649 13.5096 4.12652 12.666C2.64954 12.0936 1.40277 10.9573 0.691315 9.54212Z" fill="black" />
          </g>
        </g>
        <g id="#383e41ff">
          <path id="Vector_5" d="M16.4075 0.156991C17.7756 0.156114 19.1446 0.157868 20.5136 0.156991C20.8986 0.157868 21.2862 0.170146 21.6651 0.247322C22.742 0.456047 23.6909 1.16028 24.2697 2.08288C24.8687 3.05898 24.9345 4.3201 24.5232 5.37863C23.9619 6.72658 22.5903 7.69566 21.1301 7.76757C20.1058 7.79827 19.0806 7.77196 18.0553 7.7816C18.0536 9.51543 18.0562 11.2501 18.0545 12.984C17.5055 12.9848 16.9556 12.9848 16.4066 12.984C16.4075 8.70859 16.4066 4.43235 16.4075 0.156991ZM18.0562 1.74786C18.0536 3.23613 18.0545 4.72527 18.0553 6.21353C18.9341 6.21529 19.812 6.21353 20.6907 6.21441C21.0284 6.21529 21.3713 6.18021 21.687 6.05304C22.5622 5.70926 23.1805 4.77614 23.0963 3.82986C23.0297 2.70818 21.9992 1.73032 20.8696 1.7505C19.9312 1.74348 18.9937 1.74874 18.0562 1.74786Z" fill="black" />
          <path id="Vector_6" d="M28.3267 3.97176C29.5721 3.81741 30.963 3.99194 31.9312 4.85753C32.6845 5.50914 33.0344 6.51856 33.0336 7.49642C33.0415 9.32495 33.0344 11.1544 33.0371 12.9829C32.5135 12.9847 31.9899 12.9847 31.4664 12.9829C31.4611 12.5663 31.4725 12.1489 31.4585 11.7314C31.0814 12.162 30.7209 12.633 30.1965 12.8961C28.7591 13.645 26.7894 13.2495 25.8089 11.9384C25.2362 11.1895 25.1292 10.1485 25.4318 9.2697C25.6791 8.56021 26.2737 8.02174 26.9411 7.70777C27.6944 7.35434 28.5433 7.27015 29.366 7.30172C30.0886 7.33066 30.82 7.478 31.4567 7.83231C31.4637 7.4359 31.4655 7.02108 31.284 6.65801C30.8805 5.82924 29.9343 5.32497 29.0222 5.40302C28.2039 5.42056 27.448 5.88888 26.9998 6.56241C26.5157 6.26073 26.0334 5.95641 25.5502 5.65385C26.1711 4.71809 27.2147 4.09981 28.3267 3.97176ZM27.2366 9.47931C26.8788 9.94236 26.8718 10.651 27.2498 11.1053C27.5971 11.5183 28.1277 11.7648 28.6653 11.7876C30.0842 11.8796 31.4804 10.6396 31.4646 9.19867C30.7674 8.68738 29.8667 8.55934 29.0222 8.61108C28.3495 8.65756 27.6523 8.92767 27.2366 9.47931Z" fill="black" />
          <path id="Vector_7" d="M33.2274 4.20358C33.8255 4.20358 34.4228 4.20095 35.0209 4.20446C35.8988 6.31364 36.76 8.42983 37.6501 10.5337C38.5069 8.42632 39.3524 6.31364 40.2057 4.20446C40.8038 4.20095 41.4019 4.20358 42 4.20358V4.20972C40.1627 8.41843 38.3386 12.6333 36.503 16.8429C35.9373 16.8429 35.3717 16.8437 34.806 16.8429C35.4839 15.3669 36.1697 13.8953 36.8477 12.4202C35.6435 9.67955 34.4315 6.94332 33.2274 4.20358Z" fill="black" />
        </g>
      </g>
    </svg>

  );
};

export default GGPayIcon;