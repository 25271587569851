import { Col, Row } from 'antd';
import Icon from 'assets/Icons';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalaryStaffDetailOneDay, IPrintSalaryStaffDetailOneDay_Detail } from 'features/report/services/types/reportStaff';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../../../components/PrintHeader';
import { ReportType } from '../../../constants';
import { renderTime } from '../../../utils';
interface IEmployeeSalaryReportPrintPage { }

const StaffSalaryReportPrintPage: React.FC<IEmployeeSalaryReportPrintPage> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { staff_id = '' } = useParams();
    const dataPrintSalaryStaffDetailOneDay = reportSelectors.getPrintSalaryStaffDetailOneDay();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.STAFF_SALARY_DETAIL];
    useEffect(() => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalaryStaffDetailOneDay.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: staff_id
        }));
    }, [dataAppointment, staff_id]);

    const RowItem = (item: IPrintSalaryStaffDetailOneDay_Detail) => {
        return (
            <Row style={{ padding: '2px 0px' }}>
                <ColHeader span={3}>
                    <Row>
                        <Text variant='CAPTION_2' printMode>#{item.ticketNumber}</Text>
                    </Row>
                </ColHeader>
                <ColHeader span={9}>
                    {item.itemNames.map((itemName, index) => {
                        return (
                            <Row
                                key={index + '_' + itemName.toString()}
                                style={{ justifyContent: 'start' }}
                            >
                                <Text variant='CAPTION_3' printMode textAlign='left'>{itemName}</Text>
                            </Row>
                        );
                    })}
                </ColHeader>
                <ColHeader span={7} style={{ textAlign: 'start' }}>
                    <Text variant='CAPTION_3' printMode textAlign='left'>{formatCurrency(item.price)}</Text>
                </ColHeader>
                <ColHeader span={5} style={{ textAlign: 'end' }}>
                    <Text variant='CAPTION_3' printMode textAlign='right'>{formatCurrency(item.tip)}</Text>
                </ColHeader>
            </Row>
        );
    };
    const UserDetail = (data: IPrintSalaryStaffDetailOneDay) => {
        return (
            <Box px='4' pb='1'>
                <Box className="center" highlight>
                    <Text printMode variant="CAPTION_2" className='title-highlight'>
                        {data?.staffName || '_____'}
                    </Text>
                </Box>
                <Box>
                    <Header>
                        <ColHeader span={3}>No.</ColHeader>
                        <ColHeader span={9} style={{ textAlign: 'center' }}>ITEMS</ColHeader>
                        <ColHeader span={7} style={{ textAlign: 'start' }}>PRICE</ColHeader>
                        <ColHeader span={5} style={{ textAlign: 'end' }}>TIPS</ColHeader>
                    </Header>
                    {data?.details?.map(item => RowItem(item))}
                    <Total>
                        <ColHeader span={12}><Text printMode variant='CAPTION_2' >TOTAL SALES</Text></ColHeader>
                        <ColHeader span={12} style={{ textAlign: 'left' }}><Text printMode variant='CAPTION_2'  textAlign='right'>{formatCurrency(data?.totalSales)}</Text></ColHeader>
                    </Total>
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' >SUPPLY FEE</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.supplyFee)}</Text></ColHeader>
                    </Discount>
                    <Discount>
                        <ColHeader span={16}>
                            <Text variant="CAPTION_2" printMode>
                                COMMISSION
                            </Text>
                        </ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}>
                            <Text variant="CAPTION_2" printMode>
                                {formatCurrency(data.totalCommission)}
                            </Text>
                        </ColHeader>
                    </Discount>
                    <Discount>
                        <ColHeader span={16}>
                            <Text variant="CAPTION_2" printMode>
                                TIP
                            </Text>
                        </ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}>
                            <Text variant="CAPTION_2" printMode>
                                {formatCurrency(data.totalTip)}
                            </Text>
                        </ColHeader>
                    </Discount>
                    <Discount>
                        <ColHeader span={16}>
                            <Text variant="CAPTION_2" printMode>
                                DISCOUNT
                            </Text>
                        </ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}>
                            <Text variant="CAPTION_2" printMode>
                                {formatCurrency(data.staffDiscount)}
                            </Text>
                        </ColHeader>
                    </Discount>
                    <Discount style={{ borderBottom: 'unset' }}>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' >SALARY</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.totalSalary)}</Text></ColHeader>
                    </Discount>
                </Box>
            </Box>
        );
    };
    const onBack = () => {
        navigate(-1);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });
    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <Box className="center" overflow="auto">
                        <TicketBox>
                            <Box px='4'>
                                <LogoBox>
                                    <Icon type="logoSmall" />
                                    <IconLogo />
                                </LogoBox>
                                <Box className="center">
                                    <Text printMode variant="CONTENT_2" color="text_2">
                                        {renderTime(dataAppointment)}
                                    </Text>
                                </Box>
                            </Box>
                            {UserDetail(dataPrintSalaryStaffDetailOneDay)}
                        </TicketBox>
                    </Box>
                </div>
            </ContainerStyled>
        </div>
    );
};

export default StaffSalaryReportPrintPage;

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 20.5rem;
  height: fit-content;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Header = styled(Row)`
  border-top:1px solid black;
  border-bottom: 1px dashed black;
`;

const ColHeader = styled(Col)`
    color: #767676;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Total = styled(Header)`
  border-bottom: 1px solid black;
`;

const Discount = styled(Header)`
  border-top: unset;
`;
