const Merge = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 3C6.99984 3 11.0002 3 15 3C15.0005 5.00016 14.9995 6.99984 15 9C17.0002 9.00047 18.9998 8.99953 21 9C21 12.9998 21 17.0002 21 21C17.0002 21 12.9998 21 9 21C8.99953 18.9998 9.00047 17.0002 9 15C6.99984 14.9995 5.00016 15.0005 3 15C3 11.0002 3 7.00031 3 3ZM4.5 4.5C4.5 7.5 4.5 10.5 4.5 13.5C6.15094 13.4977 7.80187 13.5047 9.45234 13.4963C9.61641 13.3148 9.79172 13.1438 9.95578 12.9628C10.3238 13.313 10.6739 13.6814 11.0377 14.0353C10.8642 14.2097 10.6856 14.3794 10.5047 14.5458C10.4939 16.1967 10.5028 17.8486 10.5 19.5C13.5 19.5 16.5 19.5 19.5 19.5C19.5 16.5 19.5 13.5 19.5 10.5C17.9222 10.4995 16.3444 10.5009 14.7666 10.4995C14.6934 10.5023 14.618 10.4902 14.5472 10.5103C14.3747 10.6828 14.2097 10.8633 14.04 11.0386C13.6791 10.68 13.3191 10.3209 12.9614 9.95953C13.1358 9.77906 13.3467 9.62203 13.4972 9.42609C13.5033 7.78453 13.4986 6.14203 13.5 4.5C10.5 4.5 7.5 4.5 4.5 4.5Z"
        fill="#1D2129"
      />
      <path
        d="M6.21143 7.29094C6.56861 6.92813 6.92908 6.56906 7.29049 6.21094C7.91486 6.83625 8.53877 7.46203 9.16549 8.085C9.6094 7.63922 10.0547 7.19484 10.5 6.75047C10.5 8.00016 10.5 9.24984 10.5 10.4995C9.25033 10.4995 8.00065 10.4995 6.75096 10.4991C7.19486 10.0538 7.64018 9.60891 8.08549 9.16453C7.46205 8.53875 6.83721 7.91438 6.21143 7.29094Z"
        fill="#1D2129"
      />
      <path
        d="M10.7119 12.2142C11.206 11.708 11.7122 11.2139 12.2091 10.7109C12.5714 11.0667 12.9263 11.4295 13.2882 11.7853C12.7936 12.2906 12.2879 12.7852 11.7914 13.2881C11.4282 12.9333 11.0747 12.5691 10.7119 12.2142Z"
        fill="#1D2129"
      />
      <path
        d="M13.5 13.5001C14.7497 13.5001 15.9994 13.4996 17.2491 13.5006C16.8056 13.9473 16.357 14.3893 15.915 14.8379C16.5398 15.4628 17.1675 16.0848 17.7881 16.7139C17.4267 17.0701 17.0714 17.4325 16.71 17.7887C16.0861 17.1634 15.4608 16.5404 14.8373 15.9146C14.3897 16.3576 13.9467 16.8053 13.5005 17.2492C13.5 15.9995 13.5 14.7498 13.5 13.5001Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default Merge;
