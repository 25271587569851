import ActionBtn from 'components/ActionBtn';
import Box from 'components/Box';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import CountingFooter from 'features/payment/pages/PaymentPage/CashierSide/components/LoyaltyPoint/CountingFooter';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { useSocketContext } from 'hooks/useSocket';
import { useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { formatCurrency } from 'utils/formatCurrency';
import useGetDetailTicket from '../useGetDetailTicket';
import useUpdateTicket from '../useUpdateTicket';

const ButtonCash = () => {
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const updateTicket = useUpdateTicket();
  const [detailTicket, getDetailTicket] = useGetDetailTicket();
  const ticketContext = useTicketWrapper();
  const socketContext = useSocketContext();

  const [visible, setVisible] = useState(false);
  const [amount, setAmount] = useState<number | null>(null);
  const _amount = amount || 0;
  const remaining = (detailTicket?.total || 0) - _amount;
  const chance = remaining >= 0 ? 0 : remaining;

  const onCash = async () => {
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;

      const billId = rest.billId;
      const detail = await getDetailTicket(billId);
      if (!detail) return;
      socketContext.switchCusPayment(billId);
      setTimeout(() => currencyRef.current?.setValue(0?.toFixed(2)), 100);
      setVisible(true);
    } catch (error) { }
  };

  const onClose = () => {
    socketContext.backToCheckIn();
    setVisible(false);
  };

  const handleSubmit = async () => {
    if (!detailTicket) return;
    onClose();

    const body: IBodyAPIPayment = {
      billId: detailTicket.billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.CASH,
          amount: _amount,
          chance: Math.abs(chance),
        },
      ]
    };

    loadingRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        loadingRef.current?.setVisible(false);
        socketContext.switchCusRating(detailTicket.billId);
        ticketContext.completedTicket(detailTicket?.billId, true);
      } else {
        loadingRef.current?.setVisible(false);
      }
    } catch (error) {
      loadingRef.current?.setVisible(false);
    }
  };

  return (
    <div>
      <ActionBtn onClick={onCash} icon='cashBtn' ntype='YELLOW' small label='Cash' />
      <Modal
        // width="auto"
        footerBgColor={colorTheme.fill_5}
        headerBgColor={colorTheme.info_bg_3}
        visible={visible}
        onClose={onClose}
        onSubmit={onClose}
        modalTitle="CASH"
        className=' modal-max-height-unset'
        containerPadding={1.5}
        noneBodyStyle
        footer={
          <CountingFooter
            submitButtonTitle="Pay"
            isValid={_amount > 0 && remaining <= 0}
            onSubmit={handleSubmit}
            handleCloseModal={onClose}
            style={{ padding: '0', paddingTop: '1rem' }}
            closeButtonTitle='Close'
          >
            <Box className="center">
              <Box display="flex" alignItems="center" gap="4">
                <Text variant="CONTENT_1" color="text_3" style={{ whiteSpace: 'nowrap' }}>
                  Balance:
                </Text>
                <Text variant="H5" color="text_3" whiteSpace='nowrap' >
                  {formatCurrency(remaining < 0 ? 0 : remaining)}
                </Text>
              </Box>
              <Text mx={2} />
              <Box display="flex" alignItems="center" gap="4">
                <Text variant="CONTENT_1" color="text_3" style={{ whiteSpace: 'nowrap' }}>
                  Change:
                </Text>
                <Text variant="H5" color="text_3" whiteSpace='nowrap' >
                  {formatCurrency(chance)}
                </Text>
              </Box>
            </Box>
            <Text mb={1} />
          </CountingFooter>
        }
      >
        <CurrencyKeyBoard ref={currencyRef} value={amount} onChange={val => setAmount(val || 0)} />
      </Modal>
      <PaymentLoading ref={loadingRef} />
    </div>
  );
};

export default ButtonCash;
