import { Modal as ModalAntUI, ModalProps, Row } from 'antd';
import Button from 'components/Button';
import NumberKeyBoard from 'components/NumberKeyBoard';
import Text from 'components/Text';
import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';
import close from './close-circle-fill.png';

type IModalProps = {
  children?: ReactNode;
  footer?: ReactNode;
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  modalTitle?: string;
  okTitle?: string | ReactNode;
  hiddenOkButton?: boolean;
  hiddenCloseButton?: boolean;
  cancelTitle?: string;
  width?: string | number;
  headerBgColor?: string;
  noneBodyStyle?: boolean;
  noneFooterStyle?: boolean;
  forceRender?: boolean;
  footerBgColor?: string;
  destroyOnClose?: boolean;
  containerPadding?: number;
  footerPadding?: number;
  closeIcon?: ReactNode;
  closable?: boolean;
  showClose?: boolean;
  hiddenFooter?: boolean;
  hiddenHeader?: boolean;
  className?: string;
  disableOk?: boolean;
  wrapClassName?: string;
  rootClassName?: string;
};
const Modal: React.FC<IModalProps> = ({
  visible = false,
  onClose = () => undefined,
  onSubmit = () => undefined,
  modalTitle = 'Modal',
  children = null,
  width = 550, // or 'auto
  okTitle = 'Done',
  cancelTitle = 'Close',
  hiddenOkButton = false,
  hiddenCloseButton = false,
  footer,
  headerBgColor,
  noneBodyStyle = false,
  noneFooterStyle = false,
  footerBgColor,
  destroyOnClose = false,
  containerPadding,
  hiddenFooter = false,
  hiddenHeader = false,
  showClose = false,
  disableOk = false,
  wrapClassName,
  rootClassName,
  ...rest
}) => {
  return (
    <ModalStyled
      footerBgColor={footerBgColor}
      // modal config
      open={visible} // open or close
      forceRender={true}
      className={`ant-modal-custom ${rest.className}`}
      maskClosable={false} // close modal if click outside of modal
      width={width} // if width = auto, it will fit the body content, if width = number, it will be 550px
      destroyOnClose={destroyOnClose} // unmount and clear data
      centered // modal in center of screen
      // header config
      title={modalTitle} // is Show header with title, if title = '', not show header
      headerBgColor={headerBgColor}
      containerPadding={containerPadding}
      // body config
      noneBodyStyle={noneBodyStyle}
      noneFooterStyle={noneFooterStyle}
      // bodyStyle={{ padding: 0, margin: 0 }} // style padding for content
      // footer config
      footer={footer}
      onCancel={onClose} // funtion for close button
      okText={okTitle} // text of button OK
      okButtonProps={{
        className: `${hiddenOkButton ? 'hidden' : ''}`, // OK button hidden
        disabled: disableOk,
      }}
      cancelButtonProps={{
        className: `${hiddenCloseButton ? 'hidden' : ''}`, // OK button hidden
      }}
      onOk={onSubmit} // funtion for OK button
      cancelText={cancelTitle} // text of button close or cancel
      hiddenFooter={hiddenFooter}
      hiddenHeader={hiddenHeader}
      hiddenCloseButton={hiddenCloseButton}
      wrapClassName={wrapClassName}
      rootClassName={rootClassName}
      {...rest}

      closeIcon={showClose ? <IconClose /> : rest.closeIcon}
      closable={showClose}
    >
      <ModalContentStyled>{children}</ModalContentStyled>
    </ModalStyled>
  );
};

export default Modal;
interface ModalPropsType extends ModalProps {
  headerBgColor?: string;
  noneBodyStyle?: boolean;
  footerBgColor?: string;
  noneFooterStyle?: boolean;
  hiddenCloseButton?: boolean;
  containerPadding?: number;
  footerPadding?: number;
  hiddenFooter?: boolean;
  hiddenHeader?: boolean;
}
const ModalStyled = styled(ModalAntUI) <ModalPropsType>`
  .ant-modal-header {
    height: 3.5rem;
    margin-bottom: 0;
    ${(props) =>
    props.headerBgColor &&
    `
      margin-bottom: 0;
      background-color: ${props.headerBgColor} !important;
    `}

    ${({ hiddenHeader }) => hiddenHeader ? `
      display: none !important;
    `: ''}
  }
  .ant-modal-body {
    max-height: 80vh !important;
    ${(props) =>
    props.noneBodyStyle
      ? `
      padding: 0 !important;
      margin: 0 !important;
    `
      : ''}

    ${({ containerPadding }) =>
    containerPadding
      ? `
      padding: ${containerPadding}rem ${containerPadding}rem !important;
      padding-bottom: ${containerPadding / 2}rem !important;
    `
      : ''}
  }
  .ant-modal-footer {
    ${(props) =>
    props.footerBgColor &&
    `
      background-color: ${props.footerBgColor} !important;
    `}
    ${(props) =>
    props.noneBodyStyle
      ? `
      padding: 0 !important;
      margin: 0 !important;
    `
      : ''}

    ${({ containerPadding }) =>
    containerPadding
      ? `
      padding: ${containerPadding / 2}rem ${containerPadding}rem !important;
      padding-bottom: ${containerPadding}rem !important;
    `
      : ''}
    ${({ footerPadding }) =>
    footerPadding
      ? `
      padding: 0 !important;
      padding-bottom: ${footerPadding}rem !important;
      padding-left:${footerPadding}rem !important;
      padding-right:${footerPadding}rem !important;

    `
      : ''}

    ${({ hiddenFooter }) => hiddenFooter ? `
      display: none !important;
    `: ''}

    ${({ hiddenCloseButton }) => hiddenCloseButton ? `
      .ant-btn {
        &.ant-btn-primary {
          margin-inline-start: 0 !important;
        }
      }
    ` : ''}
  }
`;
type ModalContentStyledProps = {};
const ModalContentStyled = styled.div<ModalContentStyledProps>``;

export const ModalStoryBoard = () => {
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState('');

  return (
    <div>
      <Text variant="H3">Modal</Text>
      <Button ntype="SECONDARY" size="large" onClick={() => setVisible(true)}>
        Modal
      </Button>
      <Modal
        noneBodyStyle={false}
        // headerBgColor="red"
        hiddenOkButton={false}
        visible={visible}
        onClose={() => setVisible(false)}
      // footer={testFooter()}
      >
        <NumberKeyBoard
          value={text}
          onChange={(val) => setText(val || '')}
        />
      </Modal>
    </div>
  );
};

const IconClose = () => (
  <Row>
    <img src={close} style={{ height: '100%', width: '100%' }} />
  </Row>
);