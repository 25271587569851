import React from 'react';

function ListIcon() {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M10 11.75H8V9.75H10V11.75ZM27 11.75H12V9.75H27V11.75Z" fill="#ADADAD" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 18.75H8V16.75H10V18.75ZM27 18.75H12V16.75H27V18.75ZM10 25.25H8V23.25H10V25.25ZM27 25.25H12V23.25H27V25.25Z" fill="#ADADAD" />
        </svg>
    );
}

export function ListIconActive() {
    return (
        <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="35" height="35" rx="5" fill="#FF8890" />
            <path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M10 11.75H8V9.75H10V11.75ZM27 11.75H12V9.75H27V11.75Z" fill="white" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 18.75H8V16.75H10V18.75ZM27 18.75H12V16.75H27V18.75ZM10 25.25H8V23.25H10V25.25ZM27 25.25H12V23.25H27V25.25Z" fill="white" />
        </svg>

    );
}

const IconList = ({ active }: { active?: boolean }) => active ? <ListIconActive /> : <ListIcon />;

export default IconList;
