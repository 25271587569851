import { Row, Space } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { IWaitingListResItem } from 'features/user/services/types/waiting';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import uiSelector from 'services/UI/selectors';
import { styled } from 'styled-components';
import storage from 'utils/sessionStorage';
import { formatDuration } from 'utils/time';
type IProps = {
  data: IWaitingListResItem;
  viewOnly?: boolean;
};
const WalkInItem = ({ data, viewOnly }: IProps) => {
  const currentTime = uiSelector.getCurrentTime();
  const navigate = useNavigate();
  const countDown = useMemo(() => {
    if (!data) return null;
    const now = moment(currentTime);
    const minutes = moment(data.createdDate).diff(now, 'minutes');
    return formatDuration(minutes);
  }, [currentTime, data]);

  const onClick = () => {
    if (viewOnly) return;
    if (!data) return;
    navigate(`/store/${storage.shop_id.get()}/ticket/edit/${data.checkInId}`);
  };

  if (!data) return null;
  return (
    <Styled onClick={onClick} type='button'>
      <StyledBox>
        <div className='box-time'>
          <div >
            <Text variant='H9'>{moment(data.createdDate).format('LT')}</Text>
            <Text variant='BODY_1'>{countDown}</Text>
          </div>
        </div>
        <div className='content'>
          <div className='header-content'>
            <Row align={'middle'} className='customer-block'>
              <Circle />
              <Text className='customer-name'>{data?.customerName}</Text>
            </Row>
            <Text variant='H9' className='ticket_code'>
              #{data?.ticketNumber}
            </Text>
          </div>
          <div className='body-content'>
            <Space direction='vertical'>
              <Text>Staff:</Text>
              <Icon type='arrowForwardOutline' />
            </Space>
            <Space direction='vertical'>
              <Text className='service-staff'>{data?.staffName}</Text>
              {data.serviceInfo.map(o => (
                <Text key={o.itemId} className='service-name'>{o.itemName}</Text>
              ))}
            </Space>
          </div>
        </div>
      </StyledBox>
    </Styled>
  );
};

export default WalkInItem;
const Styled = styled.button`
  width: 100%;
  margin-bottom:0.5rem;
  height: 100%;
`;
const StyledBox = styled(Row)`
padding: 0.5rem 1rem;
gap: 0.5rem;
flex-shrink: 0;
width: 100%;
height: 100%;
border-radius: 10px;
border: 1px solid var(--line-line-3, #86909C);
background: #FFF;
  .box-time {
    padding: 0 1.5rem;
    color: var(--text-text-3, #1D2129);
    text-align: center;
    border-radius: 10px;
    border: 1px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-1, #F6F7FC);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .content {
    flex:1;
    align-self: contain;
    .header-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      background: var(--fill-fill-2, #ADADAD);
      padding: 0.25rem 0.75rem;
      .customer-block {
        display: flex;
        align-items: center;
        gap: 0.375rem;
      }
      .customer-name {
        color: var(--text-text-1, #FFF);
      }
      .ticket-code {
        color: var(--text-text-3, #1D2129);
        text-align: right;
      }
    }
    .body-content {
      display: flex;
      gap: 0.5rem;
      .service-staff {
        color: var(--text-text-3, #1D2129);
      }
      .service-name {
        color: var(--text-text-2, #767676);
      }
    }
  }
`;

const Circle = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <circle cx="5" cy="5" r="5" fill="#FF8890" />
  </svg>
);
