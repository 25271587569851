
export const StaffIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <g clipPath="url(#clip0_857_71617)">
            <path d="M8.35571 0H9.64586C10.873 0.1818 12.0165 0.8874 12.6964 1.9296C13.0839 2.4948 13.299 3.15945 13.4052 3.83175C13.537 4.86945 13.303 5.9553 12.7338 6.8355C12.0273 7.9578 10.7745 8.7165 9.45281 8.8209C9.03206 8.81415 8.60771 8.85015 8.19101 8.77815C7.29911 8.6238 6.45851 8.1864 5.82536 7.5393C5.03426 6.73785 4.58201 5.62545 4.56041 4.50225C4.51541 2.9826 5.30831 1.46475 6.59981 0.65565C7.12901 0.31275 7.73516 0.0999 8.35571 0Z" fill="#1D2129" />
            <path d="M2.49339 11.1821C3.38034 10.1804 4.51119 9.39376 5.76039 8.91406C6.40479 9.32446 7.12119 9.62866 7.87359 9.76771C8.16789 9.84106 8.47119 9.87526 8.77494 9.87256C9.08949 9.86941 9.40584 9.88426 9.71859 9.84466C10.5439 9.71776 11.3629 9.46576 12.068 9.00901C12.1405 8.97391 12.2174 8.89831 12.3029 8.93791C14.0809 9.63541 15.5929 10.962 16.5478 12.6122C16.9856 13.3268 17.2943 14.1134 17.5328 14.9139C17.7335 15.6092 17.8325 16.3269 17.9113 17.0447C17.2912 17.177 16.676 17.3291 16.0519 17.442C15.6761 17.4825 15.3112 17.5905 14.9345 17.6229C14.5439 17.6526 14.1632 17.7557 13.7713 17.7737C13.1255 17.8241 12.4852 17.9321 11.8363 17.9433C11.5555 17.9762 11.2684 17.9339 10.9916 18.0009H7.00869C6.73194 17.9343 6.44439 17.9757 6.16359 17.9433C5.47059 17.9298 4.78659 17.8119 4.09629 17.762C3.75024 17.735 3.41094 17.6558 3.06534 17.6229C2.68824 17.5914 2.32329 17.4807 1.94709 17.442C1.32429 17.3246 0.706435 17.1828 0.0881348 17.0447C0.115585 16.6869 0.184435 16.3346 0.226285 15.9786C0.263635 15.6906 0.357685 15.4139 0.408985 15.1286C0.788335 13.6773 1.48583 12.299 2.49339 11.1821ZM13.2326 11.7936C13.2272 12.4826 13.2331 13.172 13.2299 13.8609C12.5405 13.8641 11.8516 13.8587 11.1622 13.8632C11.1595 14.252 11.1595 14.6412 11.1622 15.03C11.8507 15.039 12.5392 15.0251 13.2272 15.0368C13.2371 15.7271 13.2245 16.4174 13.2335 17.1077C13.6358 17.1194 14.039 17.1185 14.4418 17.1081C14.4481 16.4471 14.44 15.7856 14.4445 15.1245C14.4355 15.0525 14.5241 15.0255 14.5808 15.0323C15.2248 15.03 15.8683 15.0368 16.5122 15.03C16.5154 14.6408 16.5154 14.252 16.5122 13.8632C15.8233 13.8587 15.1339 13.8636 14.4445 13.8614C14.4413 13.172 14.4467 12.4826 14.4422 11.7932C14.039 11.7905 13.6358 11.7905 13.2326 11.7936Z" fill="#1D2129" />
        </g>
        <defs>
            <clipPath id="clip0_857_71617">
                <rect width="18" height="18" rx="9" fill="white" />
            </clipPath>
        </defs>
    </svg>
);

export const CustomerIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="vuesax/linear/user-square">
            <g id="user-square">
                <path id="Vector" d="M13.605 16.2141C12.945 16.4091 12.165 16.4991 11.25 16.4991H6.75002C5.83502 16.4991 5.05502 16.4091 4.39502 16.2141C4.56002 14.2641 6.56252 12.7266 9.00002 12.7266C11.4375 12.7266 13.44 14.2641 13.605 16.2141Z" fill="#1D2129" stroke="#1D2129" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 14.085 2.355 15.6375 4.395 16.215C4.56 14.265 6.5625 12.7275 9 12.7275C11.4375 12.7275 13.44 14.265 13.605 16.215C15.645 15.6375 16.5 14.085 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5ZM9 10.6275C7.515 10.6275 6.315 9.42001 6.315 7.93501C6.315 6.45001 7.515 5.25 9 5.25C10.485 5.25 11.685 6.45001 11.685 7.93501C11.685 9.42001 10.485 10.6275 9 10.6275Z" stroke="#1D2129" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M11.6849 7.93501C11.6849 9.42001 10.4849 10.6275 8.99994 10.6275C7.51494 10.6275 6.31494 9.42001 6.31494 7.93501C6.31494 6.45001 7.51494 5.25 8.99994 5.25C10.4849 5.25 11.6849 6.45001 11.6849 7.93501Z" stroke="#1D2129" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </g>
    </svg>

);

export const SearchIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_464_15526)">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#1D2129" />
                <path fillRule="evenodd" clipRule="evenodd" d="M17.4873 17.9547C17.7798 17.6615 18.2547 17.6608 18.548 17.9534L22.072 21.4682C22.3653 21.7607 22.3659 22.2356 22.0734 22.5289C21.7808 22.8222 21.306 22.8228 21.0127 22.5303L17.4887 19.0154C17.1954 18.7229 17.1948 18.248 17.4873 17.9547Z" fill="#1D2129" />
            </g>
            <defs>
                <clipPath id="clip0_464_15526">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export const PeriodTimeIcon = () => (
    <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group 48835">
            <path id="Vector" d="M4.96106 2.85433C5.87891 1.97941 6.80699 1.11573 7.73302 0.25C7.72791 0.786607 7.72893 1.32424 7.71666 1.86084C10.1462 1.86084 12.5758 1.8588 15.0053 1.86187C15.7607 1.86595 16.5048 2.19303 17.0056 2.75928C17.7865 3.59332 18.0614 4.77794 17.9889 5.89408C17.9275 7.1247 18.1769 8.42176 17.6802 9.59207C17.3746 10.3362 16.8216 11.0108 16.0724 11.3358C15.2036 11.7037 14.2418 11.5106 13.3291 11.5596C13.3291 10.882 13.3311 10.2043 13.328 9.52666C13.9106 9.51848 14.4943 9.54506 15.0758 9.51439C15.7678 9.42138 16.1746 8.68444 16.1828 8.03745C16.1828 7.11959 16.192 6.20174 16.1787 5.28491C16.1368 4.64404 15.6891 3.9255 14.99 3.89893C12.5696 3.88564 10.1483 3.90302 7.72688 3.89075C7.71973 4.42327 7.73404 4.95477 7.7228 5.48728C6.79574 4.61645 5.88198 3.73232 4.96106 2.85433Z" fill="#1D2129" />
            <path id="Vector_2" d="M1.83438 2.11696C2.71544 1.70198 3.71301 1.91253 4.65029 1.85734C4.6554 2.53398 4.65131 3.21164 4.65233 3.88929C4.07178 3.90462 3.48917 3.8709 2.90964 3.90565C2.38938 3.95777 2.02347 4.41363 1.88037 4.88483C1.76794 5.20986 1.79963 5.55635 1.79758 5.89365C1.80269 6.6449 1.78941 7.39615 1.80269 8.1474C1.8538 8.78315 2.28922 9.49454 2.99038 9.52009C5.40972 9.5344 7.83007 9.51294 10.2494 9.53031C10.2576 8.9937 10.2371 8.45607 10.2627 7.92049C11.1744 8.81381 12.1086 9.68363 13.0254 10.5729C12.0963 11.4396 11.1632 12.3012 10.2484 13.1833C10.2453 12.6416 10.2545 12.1009 10.2627 11.5592C7.82496 11.5572 5.38723 11.5602 2.95052 11.5582C2.24527 11.5469 1.55228 11.2526 1.0586 10.7497C0.335969 10.0148 -0.0360801 8.96406 0.00275998 7.93991C0.011959 6.95971 -0.00848259 5.97848 0.0129817 4.99828C0.119281 3.83205 0.746856 2.63823 1.83438 2.11696Z" fill="#1D2129" />
        </g>
    </svg>
);

export const ListIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M4 8H21" stroke="#1D2129" strokeLinecap="round" />
        <path d="M4 12H21" stroke="#1D2129" strokeLinecap="round" />
        <path d="M4 16H12" stroke="#1D2129" strokeLinecap="round" />
    </svg>
);

export const ServiceRadioSelectedIcon = () => (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.5" cy="14" r="14" fill="#FF8890" />
        <path fillRule="evenodd" clipRule="evenodd" d="M22.4939 7.43558C22.8056 7.70834 22.8372 8.18216 22.5644 8.49389L12.0644 20.4939C11.9277 20.6501 11.7324 20.7427 11.525 20.7496C11.3175 20.7565 11.1164 20.6771 10.9697 20.5303L6.46967 16.0303C6.17678 15.7374 6.17678 15.2626 6.46967 14.9697C6.76256 14.6768 7.23744 14.6768 7.53033 14.9697L11.4635 18.9028L21.4356 7.50613C21.7083 7.1944 22.1822 7.16282 22.4939 7.43558Z" fill="white" />
    </svg>
);

export const ServiceRadioIcon = () => (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="14.5" cy="14" r="13.5" fill="#E5E5E5" stroke="#86909C" />
    </svg>
);

export const DropdownIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <g clipPath="url(#clip0_550_43529)">
            <path d="M6 9L12 15L18 9" stroke="#1D2129" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
            <clipPath id="clip0_550_43529">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
