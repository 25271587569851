import React from 'react';
import { ICouponPrintDiscount } from 'services/shop/types/coupon';
import styled from 'styled-components';
type Props = {
  isLarge?: boolean;
  data: ICouponPrintDiscount,
};
const Money = ({ isLarge, data }: Props) => {
  return (
    <TopMoneyStyled >
      <DollarBox isLarge={isLarge}>$</DollarBox>
      <MoneyBox isLarge={isLarge}>{data.discountValue}</MoneyBox>
      <MoneyContent isLarge={isLarge}>
        <div className="top">
          <span className='money-suffix'>00</span>
          <span className='money-off'>OFF</span>
        </div>
        <div className="coupon-name">{data.couponName}</div>
      </MoneyContent>
    </TopMoneyStyled>
  );
};

export default Money;

const TopMoneyStyled = styled.div`
  display: flex;
  justify-content: center;
`;
const DollarBox = styled.span<{ isLarge?: boolean }>`
  color: black;
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 100% */
  ${({ isLarge }) => isLarge && `
    transform: translateY(20px);
  `}
`;
const MoneyBox = styled.span<{ isLarge?: boolean }>`
  color: black;
  font-size: 150px;
  font-style: normal;
  font-weight: 400;
  line-height: 120px; /* 80% */
  font-family: Archivo Black;
  ${({ isLarge }) => isLarge && `
    font-size: 100px;
  `}
`;

const MoneyContent = styled.div<{ isLarge?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transform: translateY(-15px);
  font-family: Archivo Black;
  .top {
    display: flex;
    align-items: center;
    .money-suffix {
      color: black;
      transform: translateY(-3px);
      font-size: 35px;
      font-style: normal;
      font-weight: 700;
      text-decoration-line: underline;
      font-family: Poppins;
    }
    .money-off {
      color: black;
      font-family: Playfair Display;
      font-size: 60px;
      font-style: normal;
      font-weight: 700;
      line-height: 55px; /* 100% */
    }
  }
  .coupon-name {
    color: black;
    font-size: 30px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    text-transform: uppercase;
  }

  ${({ isLarge }) => isLarge && `
    transform: none;
    padding-bottom: 18px;
    padding-top: 5px;
    .coupon-name {
      font-size: 22px;
    }
  `}
`;
