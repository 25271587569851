import React from 'react';
import styled from 'styled-components';
type IComponentProps = {
  children: React.ReactNode;
};
const RightEditTicket: React.FC<IComponentProps> = ({children}) => {
  return <ComponentStyled>{children}</ComponentStyled>;
};

export default RightEditTicket;
const ComponentStyled = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
`;
