import React, { useEffect } from 'react';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import Header from './components/Header';
import Main from './components/Main';
import ChooseCheckInTypePageStyled from './styles';
type IChooseCheckInTypePageProps = {};
const ChooseCheckInTypePage: React.FC<IChooseCheckInTypePageProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(shopActions.doInit.checkIn());
  }, []);

  return (
    <ChooseCheckInTypePageStyled>
      <Header />
      <Main />
    </ChooseCheckInTypePageStyled>
  );
};

export default ChooseCheckInTypePage;
