import React, { useState } from 'react';
import { debounce, isArray } from 'lodash';
import moment, { Moment } from 'moment';
import { Input, Select } from 'antd';
import styled from 'styled-components';
import Box from 'components/Box';
import Text from 'components/Text';
import ticketListActions from 'features/ticketList/services/actions';
import ButtonAddNewTicket from 'features/user/pages/clockInClockOut/components/Buttons/ButtonAddNewTicket';
import { useAppDispatch } from 'store/hooks';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import DatePickerPeriod from 'components/DatePickerPeriod';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { CALENDAR_PICKER_TYPE } from 'features/settingStaff/services/constants';
import SegmentedTime from 'components/SegmentedTime';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { RangeValue } from 'rc-picker/lib/interface';
const ALL_PAYMENT = 'ALL_PAYMENT';
type IHeaderTicketsProps = {
  isCreditPage?: boolean;
};
const paymentType = [
  { value: ALL_PAYMENT, label: 'All Payments' },
  { value: PAYMENT_TYPE.CASH, label: 'Cash' },
  { value: PAYMENT_TYPE.CREDIT_CARD, label: 'Credit Card' },
  { value: PAYMENT_TYPE.CHECK, label: 'Check' },
  { value: PAYMENT_TYPE.GIFT_CARD, label: 'Gift Card' },
  { value: PAYMENT_TYPE.LOYALTY_POINT, label: 'Loyalty Point' },
];

const HeaderTickets: React.FC<IHeaderTicketsProps> = ({ isCreditPage }) => {
  const dispatch = useAppDispatch();
  const ticketParam = ticketListSelectors.ticketsTableData.params();
  const [viewType, setViewType] = useState<string | number>(
    CALENDAR_PICKER_TYPE.DATE
  );

  const debouncedHandleChange = debounce((value: string) => {
    const params = {
      ticketNumber: value,
    };
    dispatch(ticketListActions.setTicketsParams(params));
  }, 300); // 300ms delay

  const convertStartEndDate = (date: moment.Moment, type: string | number) => {
    switch (type) {
      case 'week': {
        const startDate = date.startOf('week').format(DATE_FORMAT);
        const endDate = date.endOf('week').format(DATE_FORMAT);
        return {
          startDate,
          endDate,
        };
      }

      case 'month': {
        const startDate = date.startOf('month').format(DATE_FORMAT);
        const endDate = date.endOf('month').format(DATE_FORMAT);
        return {
          startDate,
          endDate,
        };
      }

      default: {
        const startDate = moment(date).format(DATE_FORMAT);
        const endDate = moment(date).format(DATE_FORMAT);
        return {
          startDate,
          endDate,
        };
      }
    }
  };

  const handleChangeDate = (date: Moment | RangeValue<Moment>) => {
    if (viewType == CALENDAR_PICKER_TYPE.WEEK && isArray(date) || isArray(date)) {
      const params = {
        startDate:  moment(date?.[0]).format(DATE_FORMAT),
        endDate:  moment(date?.[1]).format(DATE_FORMAT),
        date: moment(date?.[0]).format(DATE_FORMAT),
      };
      dispatch(ticketListActions.setTicketsParams(params));
    } else {
      const formattedDate = moment(date).format(DATE_FORMAT);
      if (date) {
        const dateConvert = convertStartEndDate(date, viewType);
        const params = {
          startDate: dateConvert.startDate,
          endDate: dateConvert.endDate,
          date: formattedDate,
        };
        dispatch(ticketListActions.setTicketsParams(params));
      }
    }
  };

  const handleChangePaymentType = (id: PAYMENT_TYPE) => {
    const params = {
      // @ts-ignore
      paymentType: id === ALL_PAYMENT ? undefined : id,
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  const handleChangeTypeView = (type: string | number) => {
    const date = moment();
    setViewType(type);
    const params = {
      startDate: convertStartEndDate(date, type).startDate,
      endDate: convertStartEndDate(date, type).endDate,
      date: date.format('MM-DD-YYYY'),
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  return (
    <HeaderTicketsStyled>
      {!isCreditPage && (
        <Box mr='2'>
          <Text variant='H5'>List Tickets</Text>
        </Box>
      )}
      <FilterGroupStyled>
        <TicketNumberInput>
          <Input
            className='ticketNumberInput'
            placeholder='Ticket No.'
            type='number'
            onChange={(e) => {
              debouncedHandleChange(e.target.value);
            }}
          />
        </TicketNumberInput>

        <Box display='flex' gap='2'>
          <DatePickerPeriod
            onChange={handleChangeDate}
            picker={viewType as CALENDAR_PICKER_TYPE}
          />
          <SegmentedTime value={viewType} handleChange={handleChangeTypeView} />
        </Box>
        {ticketParam.status !== 'OPEN_TICKET' && !isCreditPage && (
          <>
            <PaymentTypeSelect>
              <Select
                className='paymentTypeSelect'
                placeholder='All payment type'
                onChange={handleChangePaymentType}
                options={paymentType}
              />
            </PaymentTypeSelect>
            <ButtonAddNewTicket />
          </>
        )}
      </FilterGroupStyled>
    </HeaderTicketsStyled>
  );
};

export default HeaderTickets;
const HeaderTicketsStyled = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 1.5rem;
`;
const TicketNumberInput = styled.div`
  width: 13rem;
  .ticketNumberInput {
    height: 3.5rem;
  }
`;
const PaymentTypeSelect = styled.div`
  width: 13rem;
  .paymentTypeSelect {
    width: 100%;
  }
`;
const FilterGroupStyled = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
`;
