
const Calculator = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M5.67011 2.22027C8.57011 1.70527 11.5601 2.15027 14.5001 1.99027C16.0501 2.10027 17.9601 1.59027 19.1901 2.81527C20.0901 3.62027 19.9801 4.91027 20.0151 6.00027C19.9601 10.3503 20.0451 14.7053 19.9851 19.0503C20.0901 20.4603 18.9901 21.8853 17.5451 21.9453C14.5251 22.1103 11.4951 21.9253 8.47511 22.0203C7.23511 21.9653 5.74011 22.1953 4.80011 21.1753C3.91511 20.3803 4.02011 19.1053 3.98511 18.0253C4.03511 13.6703 3.95511 9.31527 4.01511 4.96027C3.94011 3.81027 4.59511 2.66527 5.67011 2.22027ZM6.12011 3.67527C5.31011 4.13027 5.57011 5.22027 5.48511 5.98027C5.54511 10.3353 5.44511 14.6953 5.52511 19.0503C5.41011 19.9003 6.11011 20.5753 6.95511 20.4753C9.98511 20.5603 13.0151 20.4553 16.0451 20.5203C16.8701 20.4603 18.3001 20.6653 18.4201 19.5053C18.6301 15.0153 18.4201 10.5003 18.5201 6.00527C18.4251 5.23027 18.6951 4.11027 17.8551 3.66527C15.9251 3.29027 13.9401 3.57527 11.9901 3.50527C10.0351 3.58027 8.04511 3.28527 6.12011 3.67527Z" fill="#1D2129" />
      <path d="M7.62508 5.66496C10.2401 5.29996 12.9201 5.54496 15.5601 5.52496C17.2301 5.37496 17.0401 7.37996 17.0401 8.48996C16.9751 9.32996 17.1751 10.79 16.0051 10.92C13.3451 11.07 10.6551 11.065 8.00008 10.92C6.82508 10.8 7.03008 9.34496 6.95508 8.50496C7.06508 7.57996 6.71008 6.26496 7.62508 5.66496ZM8.50008 6.99996C8.50508 7.83496 8.50508 8.66496 8.50008 9.49996C10.8301 9.49996 13.1651 9.49996 15.5001 9.49996C15.4951 8.66496 15.4951 7.83496 15.5001 6.99996C13.1651 6.99996 10.8351 6.99996 8.50008 6.99996Z" fill="#1D2129" />
      <path d="M7.64512 12.6752C8.99512 11.7852 10.3101 14.1502 8.84512 14.8202C7.51512 15.7202 6.18012 13.3502 7.64512 12.6752Z" fill="#1D2129" />
      <path d="M11.5852 12.55C12.4352 12.255 13.5402 13.12 13.1852 14.015C13.1352 15.06 11.4252 15.36 10.9802 14.42C10.4402 13.815 10.8802 12.805 11.5852 12.55Z" fill="#1D2129" />
      <path d="M15.1302 12.6506C15.8252 12.2506 16.8552 12.6657 16.9152 13.5057C17.0952 14.9457 17.1252 16.4307 16.8352 17.8607C16.2052 19.0657 14.2752 18.3756 14.5202 17.0356C14.5952 15.6206 14.0052 13.7406 15.1302 12.6506Z" fill="#1D2129" />
      <path d="M7.68507 16.1606C9.04007 15.3156 10.2951 17.6806 8.82507 18.3556C7.43507 19.1756 6.20007 16.7956 7.68507 16.1606Z" fill="#1D2129" />
      <path d="M11.5702 16.0503C12.4052 15.7603 13.5152 16.5853 13.1902 17.4753C13.1452 18.5653 11.4202 18.8803 10.9552 17.8953C10.4652 17.2753 10.8902 16.3253 11.5702 16.0503Z" fill="#1D2129" />
    </svg>
  );
};

export default Calculator;

export const CalculatorActive = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M5.67011 2.22027C8.57011 1.70527 11.5601 2.15027 14.5001 1.99027C16.0501 2.10027 17.9601 1.59027 19.1901 2.81527C20.0901 3.62027 19.9801 4.91027 20.0151 6.00027C19.9601 10.3503 20.0451 14.7053 19.9851 19.0503C20.0901 20.4603 18.9901 21.8853 17.5451 21.9453C14.5251 22.1103 11.4951 21.9253 8.47511 22.0203C7.23511 21.9653 5.74011 22.1953 4.80011 21.1753C3.91511 20.3803 4.02011 19.1053 3.98511 18.0253C4.03511 13.6703 3.95511 9.31527 4.01511 4.96027C3.94011 3.81027 4.59511 2.66527 5.67011 2.22027ZM6.12011 3.67527C5.31011 4.13027 5.57011 5.22027 5.48511 5.98027C5.54511 10.3353 5.44511 14.6953 5.52511 19.0503C5.41011 19.9003 6.11011 20.5753 6.95511 20.4753C9.98511 20.5603 13.0151 20.4553 16.0451 20.5203C16.8701 20.4603 18.3001 20.6653 18.4201 19.5053C18.6301 15.0153 18.4201 10.5003 18.5201 6.00527C18.4251 5.23027 18.6951 4.11027 17.8551 3.66527C15.9251 3.29027 13.9401 3.57527 11.9901 3.50527C10.0351 3.58027 8.04511 3.28527 6.12011 3.67527Z" fill="white" />
      <path d="M7.62508 5.66496C10.2401 5.29996 12.9201 5.54496 15.5601 5.52496C17.2301 5.37496 17.0401 7.37996 17.0401 8.48996C16.9751 9.32996 17.1751 10.79 16.0051 10.92C13.3451 11.07 10.6551 11.065 8.00008 10.92C6.82508 10.8 7.03008 9.34496 6.95508 8.50496C7.06508 7.57996 6.71008 6.26496 7.62508 5.66496ZM8.50008 6.99996C8.50508 7.83496 8.50508 8.66496 8.50008 9.49996C10.8301 9.49996 13.1651 9.49996 15.5001 9.49996C15.4951 8.66496 15.4951 7.83496 15.5001 6.99996C13.1651 6.99996 10.8351 6.99996 8.50008 6.99996Z" fill="white" />
      <path d="M7.64512 12.6752C8.99512 11.7852 10.3101 14.1502 8.84512 14.8202C7.51512 15.7202 6.18012 13.3502 7.64512 12.6752Z" fill="white" />
      <path d="M11.5852 12.55C12.4352 12.255 13.5402 13.12 13.1852 14.015C13.1352 15.06 11.4252 15.36 10.9802 14.42C10.4402 13.815 10.8802 12.805 11.5852 12.55Z" fill="white" />
      <path d="M15.1302 12.6506C15.8252 12.2506 16.8552 12.6657 16.9152 13.5057C17.0952 14.9457 17.1252 16.4307 16.8352 17.8607C16.2052 19.0657 14.2752 18.3756 14.5202 17.0356C14.5952 15.6206 14.0052 13.7406 15.1302 12.6506Z" fill="white" />
      <path d="M7.68507 16.1606C9.04007 15.3156 10.2951 17.6806 8.82507 18.3556C7.43507 19.1756 6.20007 16.7956 7.68507 16.1606Z" fill="white" />
      <path d="M11.5702 16.0503C12.4052 15.7603 13.5152 16.5853 13.1902 17.4753C13.1452 18.5653 11.4202 18.8803 10.9552 17.8953C10.4652 17.2753 10.8902 16.3253 11.5702 16.0503Z" fill="white" />
    </svg>
  );
};