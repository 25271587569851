import Button from 'components/Button';
import ActiveServices from './ActiveServices';
import ButtonQuickPay from './ButtonQuickPay';
import ExtendFeatures from './ExtendFeatures';
import { ButtonContainerStyled, CarouserContainerStyled, CenterMainStyled, ContainerStyled } from './styles';
import ActiveProducts from './ActiveProducts';
import cashierSelectors from 'features/cashier/services/selectors';
import { FEATURE_PRODUCT_MODE } from 'features/cashier/services/constants';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import ModalWarningEmptyService from './ModalWarningEmptyService';
import { useAppDispatch } from 'store/hooks';
import cashierActions from 'features/cashier/services/actions';

const CenterMainBody = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleGoListTicket = () => {
    dispatch(cashierActions.checkCashierWillUnmount());
    navigate(`/store/${storage.shop_id.get()}`);
  };
  return (
    <CenterMainStyled>
      <ContainerStyled>
        <ExtendFeatures />
        <ButtonContainerStyled>
          <Button block ntype="DEFAULT" onClick={handleGoListTicket}>
            List Tickets
          </Button>
          <ButtonQuickPay />
        </ButtonContainerStyled>
        <CarouserContainerStyled>
          <ActiveFeatureProduct />
        </CarouserContainerStyled>
      </ContainerStyled>
      <ModalWarningEmptyService />
    </CenterMainStyled>
  );
};

export default CenterMainBody;

const ActiveFeatureProduct = () => {
  const featureProductMode = cashierSelectors.getFeatureProductMode();
  return (
    <>
      <div className={featureProductMode === FEATURE_PRODUCT_MODE.SERVICE ? 'show' : 'hide'}><ActiveServices /></div>
      <div className={featureProductMode === FEATURE_PRODUCT_MODE.PRODUCT ? 'show' : 'hide'}><ActiveProducts /></div>
    </>
  );
};