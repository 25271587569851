function General() {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9 8C8.44772 8 8 8.44772 8 9V15.5C8 16.0523 8.44772 16.5 9 16.5H15.5C16.0523 16.5 16.5 16.0523 16.5 15.5V9C16.5 8.44772 16.0523 8 15.5 8H9ZM10 14.5V10H14.5V14.5H10ZM8 19.5C8 18.9477 8.44772 18.5 9 18.5H15.5C16.0523 18.5 16.5 18.9477 16.5 19.5V26C16.5 26.5523 16.0523 27 15.5 27H9C8.44772 27 8 26.5523 8 26V19.5ZM10 20.5V25H14.5V20.5H10ZM19.5 18.5C18.9477 18.5 18.5 18.9477 18.5 19.5V26C18.5 26.5523 18.9477 27 19.5 27H26C26.5523 27 27 26.5523 27 26V19.5C27 18.9477 26.5523 18.5 26 18.5H19.5ZM20.5 25V20.5H25V25H20.5Z" fill="#ADADAD" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.9412 8C19.4214 8 19 8.42138 19 8.94118V15.0588C19 15.5786 19.4214 16 19.9412 16H26.0588C26.5786 16 27 15.5786 27 15.0588V8.94118C27 8.42138 26.5786 8 26.0588 8H19.9412ZM20.8824 14.1176V9.88235H25.1176V14.1176H20.8824Z" fill="#ADADAD" />
    </svg>
  );
}

export function GeneralActive() {
  return (
    <svg width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="35" height="35" rx="5" fill="#FF8890" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 8C8.44772 8 8 8.44772 8 9V15.5C8 16.0523 8.44772 16.5 9 16.5H15.5C16.0523 16.5 16.5 16.0523 16.5 15.5V9C16.5 8.44772 16.0523 8 15.5 8H9ZM10 14.5V10H14.5V14.5H10ZM8 19.5C8 18.9477 8.44772 18.5 9 18.5H15.5C16.0523 18.5 16.5 18.9477 16.5 19.5V26C16.5 26.5523 16.0523 27 15.5 27H9C8.44772 27 8 26.5523 8 26V19.5ZM10 20.5V25H14.5V20.5H10ZM19.5 18.5C18.9477 18.5 18.5 18.9477 18.5 19.5V26C18.5 26.5523 18.9477 27 19.5 27H26C26.5523 27 27 26.5523 27 26V19.5C27 18.9477 26.5523 18.5 26 18.5H19.5ZM20.5 25V20.5H25V25H20.5Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.9412 8C19.4214 8 19 8.42138 19 8.94118V15.0588C19 15.5786 19.4214 16 19.9412 16H26.0588C26.5786 16 27 15.5786 27 15.0588V8.94118C27 8.42138 26.5786 8 26.0588 8H19.9412ZM20.8824 14.1176V9.88235H25.1176V14.1176H20.8824Z" fill="white" />
    </svg>
  );
}


const IconGeneral = ({ active }: { active?: boolean }) => active ? <GeneralActive /> : <General />;

export default IconGeneral;
