import { Col, Row, Select } from 'antd';
import Text from 'components/Text';
import React, { useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type ISpecialDiscountFormProps = {
  value?: string | null,
  onChange?: (val: string) => void;
};
const SpecialDiscountForm: React.FC<ISpecialDiscountFormProps> = ({
  onChange = () => undefined, value
}) => {
  const [open, setOpen] = useState(false);
  const specialDiscounts = shopSelectors.data.specialDiscounts();
  const selected = specialDiscounts.find(o => o.id?.toString() === value?.toString());
  return (
    <SpecialDiscountFormStyled>
      <Text variant='CONTENT_1' mb={0.5}>List Discount</Text>
      <SelectStyled
        value={value}
        onDropdownVisibleChange={setOpen}
        optionLabelProp='label'
        placeholder={'Select discount'}
        suffixIcon={!open ? <DownIcon /> : <UpIcon />}
        // @ts-ignore
        onChange={e => onChange(e?.toString())}
      >
        {specialDiscounts?.map((o) => (
          <Select.Option key={o.id} value={o.id.toString()} label={`#${o.id} - ${o.discountName}`}>
            <Row align={'middle'} justify={'space-between'}>
              <Text variant='CONTENT_1'>#{o.id} - ${o.discountName}</Text>
              <Text variant='CONTENT_2'>{o.discountValue}%</Text>
            </Row>
          </Select.Option>
        ))
        }
      </SelectStyled>
      {selected && <Row gutter={[12, 12]}>
        <Col span={24}>
          <Row align={'middle'} justify={'space-between'}>
            <Text>Amount:</Text>
            <Text variant='H9' style={{ fontSize: '1.125rem', fontWeight: '600' }}>{selected.discountType === 'PERCENT' ? `${selected.discountValue}%` : formatCurrency(selected.discountValue)}</Text>
          </Row>
        </Col>
        <Col span={24}>
          <Row align={'middle'} justify={'space-between'}>
            <Text>Staff:</Text>
            <Text variant='H9' style={{ fontSize: '1.125rem', fontWeight: '600' }}>{selected.staffDiscountPercent}%</Text>
          </Row>
        </Col>
        <Col span={24}>
          <Row align={'middle'} justify={'space-between'}>
            <Text>Company:</Text>
            <Text variant='H9' style={{ fontSize: '1.125rem', fontWeight: '600' }}>{selected.ownerDiscountPercent}%</Text>
          </Row>
        </Col>
      </Row>}
    </SpecialDiscountFormStyled >
  );
};

export default SpecialDiscountForm;
type SpecialDiscountFormStyledProps = {}
const SpecialDiscountFormStyled = styled.div<SpecialDiscountFormStyledProps>``;
const SelectStyled = styled(Select)`
  width: 100%;
  margin-bottom: 1rem;
`;

const DownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_5604_179240)">
      <path d="M6 9L12 15L18 9" stroke="#1D2129" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5604_179240">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const UpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_5604_179188)">
      <path d="M18 15L12 9L6 15" stroke="#1D2129" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_5604_179188">
        <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
      </clipPath>
    </defs>
  </svg>
);
