const Turn = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18138 4.13397C6.47298 3.79677 7.06458 3.85797 7.29498 4.23357C7.47378 4.49277 7.45098 4.86357 7.23858 5.09757C6.68538 5.75757 6.09858 6.39117 5.54658 7.05237C8.01738 7.04997 10.4882 7.05357 12.959 7.05117C13.8626 7.03557 14.7626 7.38117 15.4214 7.99917C16.3058 8.81397 16.7186 10.1016 16.463 11.2776C16.2278 12.48 15.3098 13.5132 14.147 13.896C13.3802 14.1708 12.5534 14.0592 11.7566 14.0868C11.4242 14.0916 11.0198 14.1024 10.8122 13.7904C10.5074 13.4124 10.727 12.774 11.2034 12.6696C12.0362 12.558 12.905 12.7788 13.7174 12.5136C14.4806 12.24 15.0386 11.4876 15.071 10.6764C15.125 9.85077 14.6294 9.02877 13.8782 8.68797C13.4066 8.44917 12.8654 8.49117 12.3542 8.49117C10.0838 8.49477 7.81218 8.48997 5.54178 8.49357C6.11178 9.16437 6.71178 9.80997 7.27218 10.4892C7.59978 10.8804 7.32978 11.544 6.83058 11.6172C6.54258 11.6844 6.26178 11.5308 6.08538 11.31C5.05698 10.1304 4.02618 8.95197 2.99658 7.77117C4.05978 6.56037 5.11098 5.33757 6.18138 4.13397Z"
        fill="#1D2129"
      />
      <path
        d="M9.85357 10.0996C10.6216 9.82485 11.4484 9.94125 12.2464 9.91125C12.58 9.90405 12.9892 9.89805 13.192 10.2172C13.4884 10.5976 13.2652 11.224 12.7936 11.3284C11.9872 11.4352 11.1544 11.2396 10.3612 11.4544C9.59677 11.6956 9.01117 12.4096 8.93557 13.2088C8.82997 14.0692 9.33277 14.956 10.1224 15.3112C10.5928 15.5476 11.1328 15.508 11.6428 15.5068C13.9144 15.5044 16.186 15.508 18.4564 15.5056C17.8852 14.8324 17.284 14.1844 16.7224 13.5028C16.4272 13.1416 16.6264 12.5368 17.0692 12.4048C17.38 12.286 17.7208 12.436 17.914 12.6892C18.9424 13.8688 19.972 15.0472 21.0016 16.2268C20.0164 17.3596 19.0216 18.4852 18.0412 19.6228C17.8468 19.8496 17.6176 20.11 17.2888 20.0872C16.7524 20.1052 16.3732 19.4164 16.7008 18.9832C17.2624 18.2848 17.878 17.6308 18.4588 16.948C15.988 16.9456 13.5172 16.9468 11.0476 16.948C10.1476 16.9624 9.24757 16.6252 8.58877 16.0108C7.66717 15.1732 7.25677 13.8208 7.55917 12.6124C7.82557 11.4532 8.72557 10.4716 9.85357 10.0996Z"
        fill="#1D2129"
      />
    </svg>
  );
};

export default Turn;

export const TurnActive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18138 4.13397C6.47298 3.79677 7.06458 3.85797 7.29498 4.23357C7.47378 4.49277 7.45098 4.86357 7.23858 5.09757C6.68538 5.75757 6.09858 6.39117 5.54658 7.05237C8.01738 7.04997 10.4882 7.05357 12.959 7.05117C13.8626 7.03557 14.7626 7.38117 15.4214 7.99917C16.3058 8.81397 16.7186 10.1016 16.463 11.2776C16.2278 12.48 15.3098 13.5132 14.147 13.896C13.3802 14.1708 12.5534 14.0592 11.7566 14.0868C11.4242 14.0916 11.0198 14.1024 10.8122 13.7904C10.5074 13.4124 10.727 12.774 11.2034 12.6696C12.0362 12.558 12.905 12.7788 13.7174 12.5136C14.4806 12.24 15.0386 11.4876 15.071 10.6764C15.125 9.85077 14.6294 9.02877 13.8782 8.68797C13.4066 8.44917 12.8654 8.49117 12.3542 8.49117C10.0838 8.49477 7.81218 8.48997 5.54178 8.49357C6.11178 9.16437 6.71178 9.80997 7.27218 10.4892C7.59978 10.8804 7.32978 11.544 6.83058 11.6172C6.54258 11.6844 6.26178 11.5308 6.08538 11.31C5.05698 10.1304 4.02618 8.95197 2.99658 7.77117C4.05978 6.56037 5.11098 5.33757 6.18138 4.13397Z"
        fill="white"
      />
      <path
        d="M9.85357 10.0996C10.6216 9.82485 11.4484 9.94125 12.2464 9.91125C12.58 9.90405 12.9892 9.89805 13.192 10.2172C13.4884 10.5976 13.2652 11.224 12.7936 11.3284C11.9872 11.4352 11.1544 11.2396 10.3612 11.4544C9.59677 11.6956 9.01117 12.4096 8.93557 13.2088C8.82997 14.0692 9.33277 14.956 10.1224 15.3112C10.5928 15.5476 11.1328 15.508 11.6428 15.5068C13.9144 15.5044 16.186 15.508 18.4564 15.5056C17.8852 14.8324 17.284 14.1844 16.7224 13.5028C16.4272 13.1416 16.6264 12.5368 17.0692 12.4048C17.38 12.286 17.7208 12.436 17.914 12.6892C18.9424 13.8688 19.972 15.0472 21.0016 16.2268C20.0164 17.3596 19.0216 18.4852 18.0412 19.6228C17.8468 19.8496 17.6176 20.11 17.2888 20.0872C16.7524 20.1052 16.3732 19.4164 16.7008 18.9832C17.2624 18.2848 17.878 17.6308 18.4588 16.948C15.988 16.9456 13.5172 16.9468 11.0476 16.948C10.1476 16.9624 9.24757 16.6252 8.58877 16.0108C7.66717 15.1732 7.25677 13.8208 7.55917 12.6124C7.82557 11.4532 8.72557 10.4716 9.85357 10.0996Z"
        fill="white"
      />
    </svg>
  );
};
