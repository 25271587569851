import { Button, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IApiGetTicketsParam } from 'features/ticketList/services/types/api';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import HeaderTickets from './components/HeaderTickets';
import TabTicketByParams from './components/Tabs/TabTicketByParams';
import ListTicketPageStyled from './styles';
import { remove } from 'lodash';
type IListTicketPageProps = any;
const CreditCardPage: React.FC<IListTicketPageProps> = () => {
  const dispatch = useAppDispatch();
  const adjustTipStore = ticketListSelectors.adjustTip();
  const params = ticketListSelectors.ticketsTableData.params();
  useEffect(() => {
    dispatch(ticketListActions.doInit());
    const _params: IApiGetTicketsParam = {
      status: ['CLOSED', 'REFUND', 'VOID'],
      page: 1,
      size: 10,
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
      paymentType: PAYMENT_TYPE.CREDIT_CARD,
    };
    dispatch(ticketListActions.setVisibleCusPhone(true));
    dispatch(ticketListActions.setTicketsParams(_params));
  }, []);

  const disableAdjustTip = useMemo(() => {
    return !adjustTipStore.some(o => !!o.tip);
  }, [adjustTipStore]);

  const handleParentButtonClick = () => {
    // call action in store list
    dispatch(ticketListActions.adjustTip.fetch({}));
  };

  const handleFilterStatus = (_status: TicketStatus) => () => {
    const statusArr = [...params.status || []];
    if (statusArr?.indexOf(_status) !== -1) {
      remove(statusArr, o => o === _status);
    } else {
      // @ts-ignore
      statusArr.push(_status);
    }
    const _params: IApiGetTicketsParam = {
      // @ts-ignore
      status: statusArr,
      page: 1,
    };
    dispatch(ticketListActions.setTicketsParams(_params));
  };

  return (
    <>
      <ListTicketPageStyled>
        <Sidebar />
        <BodyStyled>
          <Row align={'middle'} style={{ gap: '0.5rem', marginBottom: '1rem' }}>
            <Box mr="2">
              <Text variant="H5">Credit Cards</Text>
            </Box>
            <div style={{ flex: 1 }} />
            <HeaderTickets isCreditPage />
            <AdjustButtonStyled
              icon={<Icon type="adjustTipAll" />}
              onClick={handleParentButtonClick}
              disabled={disableAdjustTip}
            >
              Adjust Tip All
            </AdjustButtonStyled>
          </Row>
          <Row style={{ width: '100%', gap: '0.5rem', marginBottom: '0.5rem' }} justify={'end'}>
            <TicketStatusCheckBox
              status='CLOSED'
              selected={params.status?.includes('CLOSED')}
              onClick={handleFilterStatus('CLOSED')}
            />
            <TicketStatusCheckBox
              status='VOID'
              selected={params.status?.includes('VOID')}
              onClick={handleFilterStatus('VOID')}
            />
            <TicketStatusCheckBox
              status='REFUND'
              selected={params.status?.includes('REFUND')}
              onClick={handleFilterStatus('REFUND')}
            />
          </Row>
          <TabTicketByParams tableKey={'CLOSED'} isCreditPage />
        </BodyStyled>
      </ListTicketPageStyled>
    </>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
  padding: 0 1.5rem;

  .circle-status {
    width: 25px;
     height: 25px; 
     background: #DDDDDD; 
     border-radius: 5px; 
     border: 1px solid #777D7C; 
     margin-right: 4px
  }
`;

export default CreditCardPage;

const AdjustButtonStyled = styled(Button)`
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  gap: 0.175rem;
  padding: 0 1rem;
  background-color: #6fabb6;
  border-color: #6fabb6 !important;
  box-shadow: -1px 1px 4px 0px #0000001a inset, 0px 4px 4px 0px #00000040;
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: white !important;

  &:hover {
    opacity: 0.7;
    transition: opacity 0.3s; // smooth transition for the hover effect
  }
  &:disabled {
    background-color: #6fabb6 !important;
    border-color: #6fabb6!important;
    opacity: 0.7;
  }
`;

type TicketStatus = 'CLOSED' | 'REFUND' | 'VOID';

const getColors = (status: TicketStatus) => {
  switch (status) {
    case 'CLOSED': return '#FFF';
    case 'VOID': return '#feedbf6e';
    case 'REFUND': return '#acc3c854';
    default: return '#FFF';
  }
};
const getLabel = (status: TicketStatus) => {
  switch (status) {
    case 'CLOSED': return ' Closed';
    case 'VOID': return ' Voided';
    case 'REFUND': return ' Refunded';
    default: return '';
  }
};
type ITicketStatusCheckBoxProps = {
  status: TicketStatus;
  selected?: boolean;
  onClick?: () => void;
};
const TicketStatusCheckBox = ({ status, selected, onClick }: ITicketStatusCheckBoxProps) => {
  return (
    <button onClick={onClick}>
      <Row align={'middle'}>
        <Row align={'middle'} justify={'center'} className='circle-status' style={{ background: getColors(status), }} >
          {selected ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
            <path d="M6.35192 11.7369C6.03176 11.7369 5.72761 11.6088 5.5035 11.3847L0.973172 6.85445C0.508943 6.39022 0.508943 5.62184 0.973172 5.15761C1.4374 4.69338 2.20578 4.69338 2.67001 5.15761L6.35192 8.83943L14.58 0.611356C15.0442 0.147126 15.8126 0.147126 16.2768 0.611356C16.7411 1.07559 16.7411 1.84397 16.2768 2.30819L7.20034 11.3847C6.97622 11.6088 6.67207 11.7369 6.35192 11.7369Z" fill="#1D2129" />
          </svg> : null}
        </Row>
        <Text variant='CONTENT_2' color='text_3'>{getLabel(status)}</Text>
      </Row>
    </button>
  );
};

