import { Col, Form, FormListFieldData, Input, Row } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
type IRangeSaleInputProps = {
  field: FormListFieldData;
  remove: (index: number | number[]) => void;
};
const RangeSaleInput: React.FC<IRangeSaleInputProps> = ({ field, remove }) => {
  return (
    <Row gutter={[16, 16]} style={{ margin: 0 }}>
      <ColRangerItem flex="1">
        <RangeInputTextContainer>
          <RangeInputText>Range Sale</RangeInputText>
        </RangeInputTextContainer>

        <RangeInputContainer>
          <Text variant="H8" color="text_2">
            From:
          </Text>
          <FormItemNoMargin name={[field.name, 'from']}>
            <Input bordered={false} />
          </FormItemNoMargin>
          -
          <Text variant="H8" color="text_2">
            To:
          </Text>
          <FormItemNoMargin name={[field.name, 'to']}>
            <Input bordered={false} />
          </FormItemNoMargin>
        </RangeInputContainer>
      </ColRangerItem>

      <Col flex="1">
        <FormItemNoMargin label="Commission" name={[field.name, 'commission']}>
          <Input />
        </FormItemNoMargin>
      </Col>

      <ColCenterFitContent>
        <IconContainer onClick={() => remove(field.name)}>
          <Icon type="circleClose" />
        </IconContainer>
      </ColCenterFitContent>
    </Row>
  );
};

export default RangeSaleInput;
const ColRangerItem = styled(Col)`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;
const RangeInputTextContainer = styled.div`
  margin-bottom: 0.5rem;
  height: 19px;
`;
const RangeInputText = styled.div`
  color: var(--text-text-3, #1d2129);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const RangeInputContainer = styled.div`
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-0, #fff);
  .ant-input {
    height: calc(3rem - 2px);
  }
`;

const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
`;

const ColCenterFitContent = styled(Col)`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: fit-content;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0;
  cursor: pointer;
`;
