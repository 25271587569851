import { Input, Spin } from 'antd';
import SearchIcon from 'assets/Icons/search';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import { debounce } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ButtonAddCustomer from 'widgets/Customer/AddCustomer';
import { maskPhone } from 'widgets/Customer/CustomerSearchInput';
type ILeftCustomerDetailsProps = {};
const LeftCustomerDetails: React.FC<ILeftCustomerDetailsProps> = () => {
  const dispatch = useAppDispatch();
  const customers = customerSelectors.customersActiveData.data();
  const loading = customerSelectors.customersActiveData.loading();
  const customerDetails = customerSelectors.getCustomerDetails();
  const [inputValue, setInputValue] = useState<string>('');

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handleClickCustomer = (id: string) => {
    if (id === customerDetails?.id) return;
    dispatch(customerActions.getCustomerDetails.fetch(id));
    navigate(`/store/${storage.shop_id.get()}/customers/${id}`);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (value.length <= 4) {
      setInputValue(value);
    }
  };

  const debouncedHandle = debounce((text: string) => {
    const params = {
      lastPhoneNumber: text ? Number(text) : '',
    };
    dispatch(customerActions.getCustomersActive.fetch(params));
  }, 500);

  useEffect(() => {
    debouncedHandle(inputValue);
  }, [inputValue]);

  useEffect(() => {
    dispatch(customerActions.getCustomersActive.fetch({}));
  }, []);

  return (
    <LeftCustomerDetailsStyled>
      <Box p="4" display="flex" gap="3">
        <Button icon="back" ntype="SECONDARY" onClick={handleBack}>
          Back
        </Button>

        <ButtonAddCustomer model="new-ticket" />
      </Box>
      <Box px="4" py="3">
        <Text variant="H5" color="text_3">
          CUSTOMER DETAIL
        </Text>
      </Box>

      <Box display="flex" flexDirection="column" gap="3" px="4" pt="3">
        <Input
          suffix={<SearchIcon />}
          placeholder="Last 4 number / Name"
          value={inputValue}
          onChange={handleInputChange}
          type="number"
          maxLength={4}
        />
      </Box>
      <ListStyled>
        {loading ? (
          <Box className="center">
            <Spin />
          </Box>
        ) : (
          <Box pb="3">
            {customers.length > 0 ? (
              customers.map((item) => (
                <ItemStyled
                  active={item.id === customerDetails?.id}
                  key={item.id}
                  onClick={() => handleClickCustomer(item?.id)}
                >
                  <Box>
                    <Text variant="CONTENT_2" color="text_3">
                      {item.name || '--'}
                    </Text>
                    <Text mt={0.5} variant="BODY_1" color="text_2">
                      {item.point} point
                    </Text>
                  </Box>
                  <Text variant="BODY_1" color="text_3">
                    {item.phone ? maskPhone(item.phone) : '--'}
                  </Text>
                </ItemStyled>
              ))
            ) : (
              <Box
                className="center"
                height={'3rem'}
                bgColor="fill_1"
                borderRadius="5px"
              >
                <Text variant="CONTENT_2" color="text_3">
                  No data
                </Text>
              </Box>
            )}
          </Box>
        )}
      </ListStyled>
    </LeftCustomerDetailsStyled>
  );
};

export default LeftCustomerDetails;
const LeftCustomerDetailsStyled = styled.div`
  min-width: 30rem;
  background: var(--fill-fill-3, #e5e5e5);
  border-right: 1px solid ${colorTheme.line_3};
`;
const ListStyled = styled.div`
  margin-top: 1rem;
  padding: 0 24px;
  overflow: auto;
  height: calc(100vh - 18rem);
`;

interface ItemStyledProps {
  active?: boolean;
}

const ItemStyled = styled.div.withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
}) <ItemStyledProps>`
  padding: 0.5rem 0.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.active ? '#FFE5E7' : '#f6f7fb')};
  border-bottom: 1px solid var(--line-line-2, #c9cdd4);
  cursor: pointer;
  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }
`;
