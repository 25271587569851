import _ from 'lodash';
import React from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import { ButtonActions } from '../helpers';
import ServicePicker from './ServicePicker';
type IServicesTabProps = {
  selected: IServiceItemData[];
  setSelected: (sle: IServiceItemData[]) => void;
  next: () => void;
  back: () => void;
};
const ServicesTab: React.FC<IServicesTabProps> = ({ selected, setSelected, next, back }) => {
  const handleChooseServices = (s: IServiceItemData) => {
    const exist = _.find(selected, (o: IServiceItemData) => o.id === s.id);
    const newSer = _.cloneDeep(selected);
    if (exist) {
      _.remove(newSer, o => o.id === s.id);
    } else {
      newSer.push(s);
    }
    setSelected(newSer);
  };

  return (
    <>
      <ServicePicker
        selected={selected}
        handleChooseServices={handleChooseServices}
      />
      <ButtonActions
        onBack={back}
        onOk={next}
        disabledOk={!selected.length}
      />
    </>
  );
};

export default ServicesTab;
