import VoidButtonHandler, { IVoidFuncType } from 'features/ticketList/components/VoidButton';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';

type IVoidButtonProps = {
  disabled?: boolean;
};
const VoidButton: React.FC<IVoidButtonProps> = (props) => {
  const dispatch = useAppDispatch();
  const ticketDetails = ticketListSelectors.ticketDetailsData.data();
  const handleVoid: IVoidFuncType = (payload) => {
    dispatch(ticketListActions.voidTicketByBillId.fetch(payload));
  };

  return <VoidButtonHandler disabled={props.disabled} isDetail ticketDetails={ticketDetails} onVoid={handleVoid} />;
};

export default VoidButton;
