import { Input, InputRef } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Form, { FormItem } from 'components/Form';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Spin from 'components/Spin';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { IGiftCardPaymentValue } from 'features/cashier/services/types/giftCard';
import CountingFooter from 'features/payment/components/CountingFooter';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import useGetInfoGiftCard from './useGetInfoGiftCard';

type ModalPaymentGiftCardProps = {
  visible?: boolean;
  onClose?: () => void;
  onSubmit?: (val: IGiftCardPaymentValue) => void;
  total: number;
};

export const useModalPaymentGiftCardRef = () => useRef<ModalPaymentGiftCardRefs>(null);

export type ModalPaymentGiftCardRefs = {
  setAmount: (num: number) => void;
  reset: () => void;
};
const ModalPaymentGiftCard = forwardRef<ModalPaymentGiftCardRefs, ModalPaymentGiftCardProps>(
  ({ visible, onClose = () => undefined, onSubmit = () => undefined, total = 0 }, ref) => {
    const currencyRef = useRef<CurrencyKeyBoardRef>(null);
    const inputCodeRef = useRef<InputRef>(null);
    const [amount, setAmount] = useState<number | null>(null);
    const giftCard = useGetInfoGiftCard();

    useEffect(() => {
      if (visible) {
        setTimeout(() => { inputCodeRef.current?.focus(); }, 100);
      }
    }, [visible]);

    useEffect(() => {
      const available = giftCard.resultGiftCard.available;
      const amountVal = available - total < 0 ? available : total;
      currencyRef.current?.setValue(amountVal?.toFixed(2)?.toString());
    }, [giftCard.data, total]);

    const amountValue = amount || 0;

    const handleClose = () => {
      onClose();
      giftCard.reset();
      currencyRef.current?.setValue('');
    };

    const handleSubmit = () => {
      if (!giftCard.data) return;

      onSubmit({
        giftCardId: giftCard.data.id,
        amount: amountValue,
      });
      onClose();
    };

    useImperativeHandle(ref, () => ({
      setAmount: num => currencyRef.current?.setValue(num.toFixed(2)),
      reset: () => [giftCard.reset(), currencyRef.current?.setValue('0.00')]
    }));

    const giftCardBalance = giftCard.data ? giftCard.resultGiftCard.available - amountValue : 0;

    return (
      <Modal
        // width="auto"
        footerBgColor={colorTheme.fill_5}
        headerBgColor={colorTheme.info_bg_3}
        visible={visible}
        modalTitle="GIFT CARD"
        width={800}
        noneBodyStyle
        footer={
          <CountingFooter
            submitButtonTitle="Redeem"
            handleCloseModal={handleClose}
            onSubmit={handleSubmit}
            isValid={!!amountValue}
            style={{ padding: '1.5rem', paddingTop:'1rem' }}
          >
            <Box className="center">
              <Box display="flex" alignItems="center" gap="4">
                <Text variant="CONTENT_1" color="text_3">
                  Gift Card Balance:
                </Text>
                <Text variant="H5" color="text_3">
                  {formatCurrency(giftCardBalance > 0 ? giftCardBalance : 0)}
                </Text>
              </Box>
            </Box>
            <Text mb={1} />
          </CountingFooter>
        }
      >
        <ModalPaymentGiftCardStyled>
          <Box>
            <Box display="flex" flexDirection="column" gap="2">
              <FormItem label={'Gift Number'}
                validateStatus={giftCard.msg ? 'warning' : undefined}
                help={giftCard.msg}
              >
                <Input
                  ref={inputCodeRef}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  addonAfter={
                    <button type='button' onClick={giftCard.onScan}><Icon type={'QR'} /></button>
                  }
                  value={giftCard.code}
                  onChange={e => giftCard.setCode(e.target.value)}
                />
              </FormItem>
              {giftCard.loading && <Spin />}
            </Box>

            <Box display="flex" gap="2" justifyContent='space-between' mb='2'>
              <Box display="flex" alignItems='center' gap='2'>
                <Text variant="CONTENT_1" color="text_3">
                  Available:
                </Text>
                <Text variant="H9" color="text_3">
                  {formatCurrency(giftCard.resultGiftCard.available)}
                </Text>
              </Box>
              <Box br='line_1' />
              <Box display="flex" alignItems='center' gap='2'>
                <Text variant="CONTENT_1" color="text_3">
                  Used:
                </Text>
                <Text variant="H9" color="text_3">
                  {formatCurrency(giftCard.resultGiftCard.used)}
                </Text>
              </Box>
              <Box br='line_1' />
              <Box display="flex" alignItems='center' gap='2'>
                <Text variant="CONTENT_1" color="text_3">
                  Amount:
                </Text>
                <Text variant="H9" color="text_3">
                  {formatCurrency(giftCard.resultGiftCard.amount)}
                </Text>
              </Box>
              <Box br='line_1' />
              <Box display="flex" alignItems='center' gap='2'>
                <Text variant="CONTENT_1" color="text_3">
                  Expiration:
                </Text>
                <Text variant="H9" color="text_3">
                  {giftCard.resultGiftCard.expireDate}
                </Text>
              </Box>
            </Box>


            <Text variant="H6" color="text_3">
              Redeem
            </Text>
            <CurrencyKeyBoard ref={currencyRef} value={amount} onChange={val => setAmount(val || 0)} />
            {/* <CurrencyInputWrapStyled>
              <CurrencyInput
                ref={currencyInputRef}
                onChangeDebounce={(e, val) => setAmount(val)}
              />
            </CurrencyInputWrapStyled> */}
          </Box>
        </ModalPaymentGiftCardStyled>
      </Modal>
    );
  });
ModalPaymentGiftCard.displayName = 'ModalPaymentGiftCard';
export default ModalPaymentGiftCard;

const ModalPaymentGiftCardStyled = styled(Form)`
  padding: 1rem 3rem;
`;
