import Text from 'components/Text';
import { ICalendarDndData, ICalendarViewColItem, ICalendarViewRowItem, ICalendarViewRowSpanInfoItem } from 'features/appointment/services/types/calendar';
import { memo, useCallback, useMemo } from 'react';
import CalendarStyled, { fixedStyle } from '../styles';
import CalendarItem from './CalendarItem';
import Droppable from './DnD/Droppable';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import { IScheduleBooking, IScheduleBookingDateItem } from 'services/shop/types/booking';
import { get } from 'lodash';
import moment from 'moment';
import { CALENDAR_ITEM_TYPES } from 'features/appointment/services/constants';

const TIME_VALUE_FORMAT = 'HH:mm';

type Props = {
  data: ICalendarViewRowItem,
  rowsSpanInfo: ICalendarViewRowSpanInfoItem[],
  isSingleCol?: boolean;
  distance: number;
  index: number;
  scheduleBooking: IScheduleBooking | null;
};
const CalendarViewRow = ({ data, rowsSpanInfo, index, scheduleBooking }: Props) => {
  const { colData, rowTime } = data;

  const renderColumn = useCallback((col: ICalendarViewColItem, index: number) => {
    const dropId = data.id + '/' + col.id;
    const dropData: ICalendarDndData = { rowId: data.id, colData: col, rowTime: data.rowTime, blockDrop: false };

    const scheduleBookingDate: IScheduleBookingDateItem | null = get(scheduleBooking, [col.rowTime.format('ddd')], null) || null;
    let disable = false;
    if (scheduleBookingDate) {
      const colTime = moment(col.rowTime.format(TIME_VALUE_FORMAT), TIME_VALUE_FORMAT);
      const startWorkingDate = moment(scheduleBookingDate?.startTime, 'HH:mm');

      const isBetweenWorkingHour = colTime.isSame(startWorkingDate) || colTime.isBetween(
        startWorkingDate,
        moment(scheduleBookingDate?.endTime, 'HH:mm')
      );
      disable = !scheduleBookingDate?.enable || !isBetweenWorkingHour;
    }

    const orderGroup = rowsSpanInfo.find(it =>
      rowTime.isSameOrBefore(it?.rowEnd.value) &&
      it.colId === col.id &&
      col.data.some(i => it.orderIds.includes(i?.id?.toString())),
    );

    const isExact = !!orderGroup && orderGroup.rowStart.id === data.id;

    if (isExact) {
      const includeBlockBreakHours = col.data.some(o => o.type === CALENDAR_ITEM_TYPES.BLOCK_HOURS || o.type === CALENDAR_ITEM_TYPES.BREAK_STAFF);
      return (
        <CalendarStyled.TD disable={includeBlockBreakHours} key={col.id + 'col'} rowSpan={orderGroup?.rowSpan || 1} className={`${col.rowTime.format('DD-MM-YYYY HH:mm')} CalendarStyled_isExact ${dropId} ${col.id} ${index === 0 ? 'first-col' : ''} `} >
          <Droppable disable={disable} blockDrop={includeBlockBreakHours} rowSpan={orderGroup?.rowSpan || 1} key={dropId} id={dropId} data={dropData}>
            <CalendarItem data={col.data} headerData={col.headerData} />
          </Droppable>
        </CalendarStyled.TD>
      );
    }
    const isBefore = !!orderGroup;

    if (isBefore) return <CalendarStyled.TD disable={disable} key={col.id + 'col'} className={`${col.rowTime.format('DD-MM-YYYY HH:mm')} CalendarStyled_isBefore ${dropId} ${col.id} ${index === 0 ? 'first-col' : ''}`} ><Droppable key={dropId} id={dropId} data={dropData} /></CalendarStyled.TD>;

    return (
      <CalendarStyled.TD disable={disable} key={col.id} className={`${col.rowTime.format('DD-MM-YYYY HH:mm')} CalendarStyled_none ${dropId} ${col.id} ${index === 0 ? 'first-col' : ''}`}>
        <Droppable disable={disable} blockDrop={disable} key={dropId} id={dropId} data={dropData} />
      </CalendarStyled.TD>
    );
  }, [data, rowsSpanInfo, scheduleBooking]);

  const time = useMemo(() => {
    const minute = data.rowTime.minutes();
    if (minute > 0) return data.rowTime.format('mm');
    return data.rowTime.format(FORMAT_TIME);
  }, [data]);

  return (
    <CalendarStyled.TRow className={`${index === 0 ? 'first-row' : ''}`}>
      <CalendarStyled.TD fixed time style={fixedStyle}>
        <div className="box-time">
          <Text className='text-time' textAlign='right' variant='CAPTION_3' >{time}</Text>
        </div>
      </CalendarStyled.TD>
      {colData.length > 0 ? colData.map(renderColumn) : <CalendarStyled.TD className='first-col' />}
    </CalendarStyled.TRow>
  );
};

export default memo(CalendarViewRow);
