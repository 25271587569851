import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { ICustomerItemData } from './types/customer';
import { IState } from './types/reducer';
import authActions from 'features/auth/services/actions';
import { IScheduleBookingResData } from './types/booking';

const initialState: IState = {
  categories: [],
  productCategories: [],
  productsVIP: [],
  addonServices: [],
  staffs: [],
  staffsAvailable: [],
  customers: [],
  recentCustomers: [],
  shopInfo: null,
  specialDiscounts: [],
  coupons: [],
  discountSetting: null,
  orderPaymentMethods: [],
  allSetting: null,
  totalStation: 0,
  scheduleBooking: null,
  couponPrint: null,
  feeCreditCard: null,
  taxConfig: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.get.scheduleBooking.success, (state, { payload }) => {
        const data = payload as IScheduleBookingResData;

        state.scheduleBooking = {
          ['Sun']: {
            enable: data.enableSun,
            endTime: data.sunEndTime,
            startTime: data.sunStartTime,
          },
          ['Sat']: {
            enable: data.enableSat,
            endTime: data.satEndTime,
            startTime: data.satStartTime,
          },
          ['Fri']: {
            enable: data.enableFri,
            endTime: data.friEndTime,
            startTime: data.friStartTime,
          },
          ['Thu']: {
            enable: data.enableThu,
            endTime: data.thuEndTime,
            startTime: data.thuStartTime,
          },
          ['Wed']: {
            enable: data.enableWed,
            endTime: data.wedEndTime,
            startTime: data.wedStartTime,
          },
          ['Tue']: {
            enable: data.enableTue,
            endTime: data.tueEndTime,
            startTime: data.tueStartTime,
          },
          ['Mon']: {
            enable: data.enableMon,
            endTime: data.monEndTime,
            startTime: data.monStartTime,
          },
        };
      })
      .addCase(actions.get.taxConfig.success, (state, { payload }) => {
        state.taxConfig = payload;
      })
      .addCase(actions.get.feeCreditCard.success, (state, { payload }) => {
        state.feeCreditCard = payload;
      })
      .addCase(actions.get.couponPrint.success, (state, { payload }) => {
        state.couponPrint = payload;
      })
      .addCase(actions.get.recentCustomers.success, (state, { payload }) => {
        state.recentCustomers = payload;
      })
      .addCase(actions.get.totalStation.success, (state, { payload }) => {
        state.totalStation = payload;
      })
      .addCase(actions.get.productsVIP.success, (state, { payload }) => {
        state.productsVIP = payload;
      })
      .addCase(actions.get.productCategories.success, (state, { payload }) => {
        state.productCategories = payload;
      })
      .addCase(actions.get.allSetting.success, (state, { payload }) => {
        state.allSetting = payload;
      })
      .addCase(actions.get.otherPaymentMethod.success, (state, { payload }) => {
        state.orderPaymentMethods = payload;
      })
      .addCase(actions.get.discountSetting.success, (state, { payload }) => {
        state.discountSetting = payload;
      })
      .addCase(actions.get.coupons.success, (state, { payload }) => {
        state.coupons = payload;
      })
      .addCase(actions.get.specialDiscount.success, (state, { payload }) => {
        state.specialDiscounts = payload;
      })
      .addCase(actions.get.shopInfo.success, (state, { payload }) => {
        state.shopInfo = payload;
      })
      .addCase(actions.get.staffsAvailable.success, (state, { payload }) => {
        state.staffsAvailable = payload;
      })
      .addCase(actions.get.categories.success, (state, { payload }) => {
        state.categories = payload;
      })
      .addCase(actions.get.addOnServices.success, (state, { payload }) => {
        state.addonServices = payload;
      })
      .addCase(actions.get.staffs.success, (state, { payload }) => {
        state.staffs = payload;
      })
      .addCase(actions.get.customers.success, (state, { payload }) => {
        state.customers = (payload as ICustomerItemData[]).filter(o => !!o.phone);
      })
      .addCase(authActions.ownerLogout, (state) => {
        state.totalStation = 0;
        state.categories = [];
        state.productCategories = [];
        state.productsVIP = [];
        state.addonServices = [];
        state.staffs = [];
        state.staffsAvailable = [];
        state.customers = [];
        state.shopInfo = null;
        state.specialDiscounts = [];
        state.coupons = [];
        state.discountSetting = null;
        state.orderPaymentMethods = [];
        state.allSetting = null;
        state.totalStation = 0;
      })
      ;
  },
});

const shopServiceReducer = Slice.reducer;
export default shopServiceReducer;
