const VoidList = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3343_27949)">
        <path
          d="M0.464063 2.15654C0.999375 1.29966 1.98656 0.741381 3 0.751225C7.37531 0.750287 11.7511 0.750756 16.1264 0.750756C16.4391 0.750287 16.7545 0.740912 17.0625 0.803725C17.7155 0.9256 18.3197 1.27716 18.7561 1.77685C19.2239 2.30513 19.4977 3.00122 19.5038 3.7081C19.5066 4.95638 19.5038 6.20513 19.5052 7.45341C19.5061 7.61466 19.4813 7.78107 19.395 7.92029C19.1273 8.36654 18.368 8.36326 18.1083 7.91044C17.9798 7.70232 18.0056 7.45013 18.0042 7.21716C18.0042 6.06216 18.0052 4.90763 18.0038 3.75263C18.0056 3.35654 17.8777 2.94638 17.5908 2.66372C17.3067 2.37638 16.8952 2.24794 16.4977 2.25122C12.0141 2.25029 7.53047 2.25122 3.04688 2.25076C2.63625 2.24185 2.20641 2.36513 1.91344 2.66419C1.61484 2.9581 1.49063 3.38701 1.50047 3.79763C1.50094 9.28247 1.49953 14.7673 1.50094 20.2522C1.49719 20.6797 1.65328 21.124 1.98656 21.4043C2.27625 21.6565 2.66859 21.7568 3.04594 21.7508C5.28141 21.7512 7.51688 21.7498 9.75188 21.7512C10.0059 21.7456 10.2713 21.8637 10.3988 22.0906C10.5638 22.3864 10.537 22.7998 10.2938 23.0459C10.1414 23.2006 9.91781 23.2564 9.70688 23.2512C7.45594 23.2493 5.20453 23.2526 2.95359 23.2493C2.11453 23.2433 1.29516 22.8518 0.745313 22.2214C0.300938 21.7226 0.0417188 21.0715 0 20.4059V3.57169C0.0417188 3.07247 0.188906 2.57794 0.464063 2.15654Z"
          fill="black"
        />
        <path
          d="M5.03576 6.77618C5.18389 6.74149 5.3367 6.75181 5.48717 6.7504C8.40795 6.75134 11.3287 6.74993 14.2495 6.75134C14.4056 6.75134 14.565 6.78649 14.6962 6.87462C15.1101 7.14884 15.1087 7.85899 14.6911 8.12946C14.5476 8.22603 14.3714 8.25368 14.2017 8.25134C11.2172 8.24993 8.23264 8.25228 5.24811 8.2504C4.9467 8.26071 4.6378 8.07837 4.54639 7.78259C4.3978 7.38884 4.59608 6.86337 5.03576 6.77618Z"
          fill="black"
        />
        <path
          d="M15.104 10.0954C16.507 9.629 18.0628 9.63463 19.4615 10.1142C20.5626 10.4868 21.5597 11.1567 22.327 12.029C23.333 13.161 23.9208 14.6423 24 16.1526V16.8431C23.9559 17.5382 23.8237 18.2301 23.5767 18.8826C23.0578 20.2842 22.0523 21.4982 20.7731 22.2707C19.2858 23.182 17.4394 23.4674 15.742 23.0793C14.1745 22.732 12.7476 21.7963 11.8026 20.4984C10.9945 19.4006 10.5389 18.0506 10.5065 16.6884C10.4648 15.314 10.8492 13.9284 11.6076 12.7804C12.427 11.5256 13.6809 10.5642 15.104 10.0954ZM16.7076 11.279C14.8851 11.4665 13.2323 12.6956 12.4767 14.3563C11.978 15.4265 11.8669 16.671 12.1767 17.811C12.5151 19.0851 13.3551 20.211 14.4642 20.9193C15.2494 21.4251 16.1714 21.7199 17.1061 21.7457C18.1472 21.7785 19.1925 21.4762 20.0639 20.9071C21.203 20.1726 22.0537 18.9956 22.3659 17.6737C22.6097 16.6631 22.5281 15.5784 22.1414 14.6137C21.6919 13.4774 20.8373 12.5099 19.7719 11.9137C18.8484 11.3924 17.7623 11.1623 16.7076 11.279Z"
          fill="black"
        />
        <path
          d="M5.03344 11.2762C5.11876 11.2552 5.20688 11.2509 5.29454 11.25C6.78001 11.2514 8.26547 11.2495 9.75047 11.2509C10.0055 11.2448 10.2722 11.3634 10.3997 11.5922C10.553 11.8687 10.5384 12.2423 10.3411 12.4936C10.2 12.6703 9.96985 12.7528 9.7486 12.75C8.26407 12.7514 6.77954 12.7495 5.29501 12.7509C5.07188 12.758 4.83469 12.6909 4.68329 12.5184C4.33688 12.1345 4.49344 11.3864 5.03344 11.2762Z"
          fill="black"
        />
        <path
          d="M14.3292 14.4438C14.3452 14.0711 14.6747 13.7416 15.0478 13.7275C15.262 13.7158 15.4711 13.8138 15.6183 13.9666C16.1634 14.5117 16.7095 15.0559 17.2542 15.6016C17.8177 15.0433 18.3741 14.4775 18.9389 13.9206C19.2038 13.6633 19.6608 13.6666 19.9247 13.9239C20.1319 14.1058 20.2336 14.4077 20.1511 14.6749C20.1061 14.8408 19.9908 14.973 19.8694 15.0888C19.3472 15.6091 18.8264 16.1308 18.3056 16.6525C18.847 17.193 19.3875 17.7344 19.9289 18.2753C20.0845 18.422 20.1877 18.6297 20.1792 18.8472C20.1708 19.2278 19.8324 19.5663 19.4517 19.5766C19.2464 19.5841 19.0453 19.4945 18.9024 19.3492C18.3525 18.8017 17.8055 18.2505 17.2547 17.703C16.703 18.2519 16.1545 18.8041 15.6024 19.3525C15.4359 19.5203 15.1884 19.6108 14.9536 19.5649C14.5852 19.5006 14.2894 19.1336 14.332 18.7567C14.348 18.5744 14.4431 18.407 14.5739 18.2819C15.1177 17.7391 15.6619 17.1963 16.2038 16.6516C15.6586 16.105 15.112 15.5594 14.5664 15.0138C14.4127 14.8675 14.3166 14.6575 14.3292 14.4438Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_3343_27949">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VoidList;
