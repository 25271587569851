import { Avatar, Col, Divider, Table, TableColumnsType } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { ISalaryStaffDetail, ITableSalaryStaffDetail } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import AppointmentDatePicker from '../../components/DatePicker';
import SwitchCalendarType from '../../components/SwitchCalendarType';
import { ReportType } from '../../constants';
import DashboardStaffPayrollReport from './components/Dashboard';
interface IEmployeeSalaryDetail { }

interface DataType extends ITableSalaryStaffDetail {
    key?: string,
}
const columns: TableColumnsType<DataType> = [
    {
        title: <div>Time</div>,
        dataIndex: 'time',
        key: 'time',
        render: (_, record) => (
            <Text variant='CONTENT_1'>{moment(record.createdDate).format('MM/DD/YYYY HH:mm:ss A')}</Text>
        ),
    },
    {
        title: 'Ticket',
        dataIndex: 'tickets',
        key: 'tickets',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {record.ticketNumber}
            </Text>
        ),
    },
    {
        title: 'Services',
        dataIndex: 'services',
        key: 'services',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {record.itemName}
            </Text>
        ),
    },
    {
        title: 'Sell Price',
        dataIndex: 'sell_price',
        key: 'sell_price',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.sellPrice)}
            </Text>
        ),
    },
    {
        title: 'Commission',
        dataIndex: 'commission',
        key: 'commission',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.commission)}
            </Text>
        ),
    },
    {
        title: 'Discount',
        dataIndex: 'discount',
        key: 'discount',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.discount)}
            </Text>
        ),
    },
    {
        title: 'Tips',
        dataIndex: 'tips',
        key: 'tips',
        render: (_, record) => (
            <div style={{ display: 'flex' }}>
                <Text variant="CONTENT_1" color="text_3" style={{ textDecoration: 'line-through', paddingRight: '10px' }}>
                    {formatCurrency(record.tip)}
                </Text>
                <Text variant="CONTENT_1" color="text_3">
                    {formatCurrency(record.tipFee)}
                </Text>
            </div>
        ),
    },
    {
        title: 'Cash',
        dataIndex: 'cash',
        key: 'cash',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.cashInPay)}
            </Text>
        ),
    },
    {
        title: 'Check',
        dataIndex: 'check',
        key: 'check',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.checkInPay)}
            </Text>
        ),
    },
];

const EmployeeSalaryDetail: React.FC<IEmployeeSalaryDetail> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { staff_id = '' } = useParams();
    const [dataTable, setDataTable] = useState<ITableSalaryStaffDetail[]>([]);
    const [dataDashboard, setDataDashboard] = useState<ISalaryStaffDetail>();
    const salaryStaffDetail = reportSelectors.getSalaryStaffDetail();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.EMPLOYEE_SALARY_DETAIL];
    const viewType = reportSelectors.getViewType()?.[ReportType.EMPLOYEE_SALARY_DETAIL];
    useEffect(() => {
        dispatch(reportActions.setParams({
            [ReportType.EMPLOYEE_SALARY_DETAIL]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
            }
        }));
        staff_id && dispatch(reportActions.getSalaryStaffDetail.fetch({
            staffId: staff_id,
            startDate: dataAppointment?.startTime || moment().format(DATE_FORMAT),
            endDate: dataAppointment?.endTime || moment().format(DATE_FORMAT)
        }));
    }, []);

    useEffect(() => {
        dataAppointment && staff_id && dispatch(reportActions.getSalaryStaffDetail.fetch({
            staffId: staff_id,
            startDate: dataAppointment?.startTime,
            endDate: dataAppointment?.endTime
        }));
    }, [dataAppointment, staff_id]);

    useEffect(() => {
        const _dashboardData = { ...salaryStaffDetail };
        delete _dashboardData.details;
        if (salaryStaffDetail?.details) {
            setDataTable(salaryStaffDetail.details);
        }
        setDataDashboard(_dashboardData);
    }, [salaryStaffDetail]);

    const handlePrint = () => {
        if (viewType && viewType != CALENDAR_VIEW_TYPE.DAY_VIEW) {
            return navigate(`/store/${storage.shop_id.get()}/report/print/staff-salary-date-range/${staff_id}`);
        }
        navigate(`/store/${storage.shop_id.get()}/report/print/staff-salary/${staff_id}`);

    };

    const handleBack = () => {
        navigate(-1);
    };

    return (
        <StyledEmployeeSalaryDetail>
            <div style={{ background: '#FFF' }}>
                <Sidebar />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, maxWidth: 'calc(100% - 104px)' }}>
                <HeaderBox>
                    <AvatarBox>
                        <Avatar src={'URL AVATAR'} size={90} />
                        <Col>
                            <Text variant='BODY_2' color='text_2'>Fatima Khan</Text>
                            <Text variant='BODY_1' color='text_2'>(036) 731-2036</Text>
                            <Text variant='BODY_1' color='text_2'>Created date: 7-19-2023 </Text>
                        </Col>
                    </AvatarBox>
                    <AppointmentDatePicker size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.EMPLOYEE_SALARY_DETAIL} />
                    <SwitchCalendarType actions={reportActions} selectors={reportSelectors} type={ReportType.EMPLOYEE_SALARY_DETAIL} />
                    <Button disabled={dataTable.length == 0} icon="print" onClick={handlePrint}>
                        Preview
                    </Button>
                    <Button icon="back" onClick={handleBack}>
                        Back
                    </Button>
                </HeaderBox>
                <Content>
                    <Divider />
                    <DashboardStaffPayrollReport data={dataDashboard} />
                    <TableBox>
                        <Table
                            loading={false}
                            dataSource={dataTable}
                            columns={columns}
                            pagination={{ pageSize: 50 }}
                            scroll={{ y: 500 }}
                        />
                    </TableBox>
                </Content>
            </div>
        </StyledEmployeeSalaryDetail>
    );
};
export default EmployeeSalaryDetail;

const StyledEmployeeSalaryDetail = styled.div`
    display: flex;
    background-color: ${colorTheme.fill_3};
    height: 100vh;
    flex:1;
    width: 100vw;
    .search-staff {
        flex:1;
        input {
            height: 38px;
        }
        .ant-input-suffix {
            height: 38px;
        }
    }
`;

const HeaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 20px;
    padding-left: 20px;
    flex-wrap: wrap;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0px 40px;
`;

const TableBox = styled.div`
  .ant-table-thead > tr > th {
    background: var(--fill-fill-2, #adadad);
    color: white; /* White text */
  }
`;

const AvatarBox = styled.div`
    flex: 1 1 0%;
    flex-direction: row;
    display: flex;
    align-items: center;
    span {
        margin-right: 5px;
    }
    width: 100%;
`;