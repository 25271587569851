import { Col, Form, Input, Radio, Row, Select } from 'antd';
import Box from 'components/Box';
import InputDOB from 'components/InputDOB';
import InputEmail from 'components/InputEmail';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { IStaffPayload } from 'features/checkIn/services/types/demoData';
import settingStaffActions from 'features/settingStaff/services/actions';
import {
  STAFF_POSITION_INFO,
  positionsArray,
} from 'features/settingStaff/services/constants';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import { IStaffInfo } from 'features/settingStaff/services/types/staff';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import ButtonGroup from './ButtonGroup';

type IStaffInformationProps = {};

const StaffInformation: React.FC<IStaffInformationProps> = () => {
  const selectedStaff = settingStaffSelectors.selectedStaffData.data();
  const [form] = Form.useForm<IStaffInfo>();
  const { Option } = Select;
  const dispatch = useAppDispatch();

  const onFinish = async (values: IStaffInfo) => {
    if (!(await form.validateFields())) {
      return;
    }
    const body: IStaffPayload = {
      staffId: selectedStaff.id || '',
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone,
      email: values.email,
      staffType: values.staffType,
      // gender: values.gender,
      dob: values.birthday ? moment(values.birthday).format('MM-DD-YYYY') : '',
      street: values.address || '',
      city: values.city || '',
      state: values.state || '',
      zip: values.zip || '',
      status: values.status,
    };
    dispatch(settingStaffActions.createOrUpdateStaff.fetch(body));
  };

  const defaultValues: IStaffInfo = {
    avatar: '',
    id: '',
    status: 'ACTIVE',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    birthday: null,
    staffType: STAFF_POSITION_INFO.TECHNICAL,
    address: '',
    city: '',
    state: '',
    zip: '',
  };

  useEffect(() => {
    if (selectedStaff) {
      form.setFieldsValue({
        avatar: selectedStaff.avatar,
        id: selectedStaff.id,
        status: selectedStaff.status,
        firstName: selectedStaff.firstName,
        lastName: selectedStaff.lastName,
        phone: formatPhone(selectedStaff.phone),
        email: selectedStaff.email,
        birthday: selectedStaff.birthday
          ? moment(selectedStaff.birthday).format('MM/DD/YYYY')
          : moment().format('MM/DD/YYYY'),
        staffType: STAFF_POSITION_INFO.TECHNICAL,
        address: selectedStaff?.address || '',
        city: selectedStaff?.city || '',
        state: selectedStaff?.state || '',
        zip: selectedStaff?.zip || '',
      });
    }
  }, [selectedStaff]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      initialValues={defaultValues}
    >
      <StaffInformationStyled>
        <div className="gradient-box"></div>
        <Box>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  { required: true, message: 'Please select an option!' },
                ]}
              >
                <Radio.Group>
                  <Radio value={'ACTIVE'}>Active</Radio>
                  <Radio value={'INACTIVE'}>Inactive</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  { required: true, message: 'Please input First Name!' },
                ]}
              >
                <Input placeholder="Enter your First Name" />
              </Form.Item>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  { required: true, message: 'Please input your Last Name!' },
                ]}
              >
                <Input placeholder="Enter your Last Name" />
              </Form.Item>
              <Form.Item
                name="phone"
                label="Phone"
                // rules={[
                //   {
                //     required: true,
                //     message: 'Please input your phone number!',
                //   },
                //   {
                //     validator: validatePhoneNumber,
                //     message:
                //       'Please follow the required phone format: (098) 76-5432',
                //   },
                // ]}
              >
                <Input
                  placeholder="Enter your phone number"
                  onChange={(e) => {
                    const formattedValue = formatPhone(e.target.value);
                    form.setFieldsValue({ phone: formattedValue });
                  }}
                />
              </Form.Item>
              <Form.Item
                name="birthday"
                label={'DOB'}
                rules={[{ required: true, message: 'Please input birthday!' }]}
              >
                <InputDOB />
              </Form.Item>

              <InputEmail
                name={'email'}
                label="Email (Optional)"
                rules={[
                  { type: 'email' },
                  { required: true, message: 'Please input your email!' },
                ]}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                name="staffType"
                label="Position"
                rules={[
                  { required: true, message: 'Please input your staff type!' },
                ]}
              >
                <Select>
                  {positionsArray.map((item) => (
                    <Option key={item.value} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <AddressStyled>
                <AddressHeaderStyled>
                  <Text variant="H9">Address</Text>
                </AddressHeaderStyled>
                <Box px="3" py="3">
                  <Form.Item name="address" label="Street">
                    <Input placeholder="Enter your Street" />
                  </Form.Item>

                  <Form.Item name="city" label="City">
                    <Input placeholder="Enter your City" />
                  </Form.Item>

                  <Form.Item name="state" label="State">
                    <Input placeholder="Enter your State" />
                  </Form.Item>

                  <Form.Item name="zip" label="Zip Code">
                    <Input placeholder="Enter your Zip Code" />
                  </Form.Item>
                </Box>
              </AddressStyled>
            </Col>
          </Row>
        </Box>
        <ButtonGroup />
      </StaffInformationStyled>
    </Form>
  );
};

export default StaffInformation;
const StaffInformationStyled = styled.div`
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: #fff;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 1.5rem 4rem;

  .ant-radio-checked .ant-radio-inner {
    background: linear-gradient(to bottom, #548088, #6faab6);
    border-color: #6faab6;
  }
`;
const AddressHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  height: 3rem;
  padding: 0 1rem;
  background-color: #adadad;
`;
const AddressStyled = styled.div`
  margin-top: 1.5rem;
  background: #f6f7fc;

  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
