import { Row } from 'antd';

const IconLogoSmall = () => (
  <Row justify={'center'}>
    <svg width="23" height="33" viewBox="0 0 23 33" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.21733 0.197851C3.07614 0.0174691 3.16149 -0.0775536 3.33704 0.0792065C3.28819 0.109807 3.24846 0.149534 3.21733 0.197851Z" fill="#C2C2C2"/>
<path d="M3.21484 0.197746C3.24598 0.149429 3.28571 0.109702 3.33456 0.0791016C3.96268 0.489255 4.61817 0.85646 5.27044 1.22742C10.8258 4.43832 16.3762 7.66156 21.9326 10.8703C22.0266 10.9648 22.2628 10.9804 22.2408 11.1522C22.135 11.1505 22.0298 11.1495 21.9251 11.1425C17.0833 9.80413 12.228 8.50764 7.37328 7.22135C7.34536 7.19075 7.31745 7.16015 7.28953 7.13008C5.92969 4.82002 4.59723 2.49331 3.21484 0.197746Z" fill="#767B7B"/>
<path d="M0.867188 5.45898C2.94533 6.00657 5.02455 6.56382 7.09786 7.13503C7.16067 7.15972 7.22671 7.14845 7.28952 7.12966C7.31743 7.15972 7.34535 7.19032 7.37327 7.22092C8.09855 8.57862 8.9108 9.88853 9.68548 11.2172C9.62696 11.338 9.57972 11.5098 9.35692 11.3665C8.49743 11.3686 7.63793 11.3606 6.77844 11.3697L6.74247 11.3493C6.72529 11.3321 6.69039 11.2978 6.67321 11.28C6.2185 10.8345 5.76862 10.3846 5.32304 9.9304C4.78565 9.34309 4.24665 8.72947 3.62015 8.23825C2.70536 7.31487 1.67729 6.46021 0.867188 5.45898Z" fill="#F58E95"/>
<path d="M7.37305 7.22168C12.2278 8.50797 17.083 9.80446 21.9249 11.1428C22.0349 11.1664 22.1745 11.364 21.9941 11.3828C18.022 11.4188 14.0359 11.3334 10.0519 11.3699C9.90644 11.393 9.79102 11.3023 9.68526 11.218C8.91058 9.88928 8.09833 8.57937 7.37305 7.22168Z" fill="#D97279"/>
<path d="M3.61914 8.23828C4.24564 8.7295 4.78464 9.34312 5.32203 9.93043C4.69552 9.43921 4.15653 8.82559 3.61914 8.23828Z" fill="#F6C4C6"/>
<path d="M21.926 11.1416C22.0307 11.1486 22.1359 11.1497 22.2416 11.1513C22.2465 11.2409 22.2465 11.3316 22.2449 11.4218C18.4654 12.407 14.6978 13.4399 10.92 14.4325C10.6462 14.528 10.3311 14.5318 10.1083 14.738L10.0643 14.7347C9.73948 14.1678 9.1672 13.7679 8.72054 13.2906C8.06076 12.663 7.44177 11.9936 6.7793 11.3692C7.63879 11.3601 8.49829 11.3682 9.35778 11.366C9.58058 11.5093 9.62782 11.3376 9.68634 11.2168C9.7921 11.301 9.90752 11.3918 10.053 11.3687C14.037 11.3322 18.0231 11.4175 21.9952 11.3816C22.1756 11.3628 22.036 11.1652 21.926 11.1416Z" fill="#D67982"/>
<path d="M6.67358 11.2803C6.69075 11.298 6.72565 11.3323 6.74283 11.3495C4.49558 11.3887 2.24725 11.3501 0 11.3694C0.0220108 11.2701 0.157834 11.3286 0.229772 11.3087C2.37771 11.2953 4.52725 11.3517 6.67358 11.2803Z" fill="#D0E7EC"/>
<path d="M0 11.3695C2.24725 11.3501 4.49558 11.3888 6.74283 11.3496L6.7788 11.37C7.44127 11.9944 8.06026 12.6638 8.72005 13.2914C8.31419 13.9812 7.79398 14.6502 7.53361 15.4034C7.42409 15.434 7.31511 15.4651 7.20613 15.4957L7.16426 15.4903C4.79514 14.0849 2.34603 12.8088 0 11.3695Z" fill="#6FABB6"/>
<path d="M11.4579 22.5927C12.1724 21.9399 12.7951 21.1856 13.5462 20.5752C14.0702 22.051 14.3638 23.6036 14.7987 25.11L14.7836 25.1416C13.6793 26.975 12.6341 28.8427 11.569 30.6991C11.5384 30.7098 11.4777 30.7319 11.4471 30.7426C11.4498 29.401 11.4514 28.06 11.4493 26.7189C11.446 25.3569 11.4589 23.9949 11.4434 22.6329L11.4579 22.5927Z" fill="#6FABB6"/>
<path d="M10.9211 14.4325C14.6989 13.4399 18.4665 12.407 22.2459 11.4219C22.2454 11.4589 22.2438 11.533 22.2432 11.57C18.2056 12.5782 14.2039 13.7395 10.1657 14.7444L10.1094 14.738C10.3322 14.5318 10.6473 14.5281 10.9211 14.4325Z" fill="#E37E7D"/>
<path d="M10.1646 14.7457C14.2028 13.7407 18.2045 12.5795 22.2421 11.5713C22.2416 11.6615 22.2416 11.7517 22.2394 11.8424C19.312 14.714 16.4361 17.6409 13.5323 20.5383C12.8247 20.4459 12.1654 20.1335 11.4557 20.0551C10.6628 19.7303 9.79687 19.6052 8.97656 19.3572C9.17627 18.458 9.44953 17.5765 9.69004 16.6875C9.85216 16.0422 10.0728 15.4055 10.1646 14.7457Z" fill="#F28478"/>
<path d="M13.5332 20.5377C16.437 17.6403 19.3129 14.7134 22.2404 11.8418C22.2398 11.9465 22.2393 12.0517 22.235 12.1569C20.662 14.6178 19.314 17.2409 17.7926 19.7437C16.8708 21.4224 15.8621 23.0534 14.9446 24.7348C14.8807 24.8319 14.8485 24.9447 14.8356 25.0596C14.827 25.0725 14.8093 25.0971 14.8007 25.11C14.3659 23.6036 14.0722 22.0511 13.5482 20.5753L13.5332 20.5377Z" fill="#E9D077"/>
<path d="M17.791 19.744C19.3124 17.2412 20.6604 14.6181 22.2334 12.1572C22.2334 12.1835 22.2323 12.2367 22.2323 12.263C20.4446 15.4562 18.5833 18.6064 16.7693 21.7845C16.1047 22.8636 15.5518 24.0195 14.834 25.0599C14.8469 24.945 14.8791 24.8322 14.943 24.7351C15.8604 23.0537 16.8692 21.4227 17.791 19.744Z" fill="#F4D87C"/>
<path d="M16.7704 21.7852C18.5844 18.6071 20.4457 15.4569 22.2334 12.2637C22.2452 12.6185 22.2592 12.9755 22.2135 13.3293C20.8097 18.6248 19.3768 23.9144 17.9934 29.2152C17.6326 30.4672 17.3223 31.7384 16.9642 32.9925C16.9025 33.0134 16.8601 32.993 16.8375 32.9308C16.5342 31.7481 16.254 30.5574 15.9013 29.3881C15.562 27.9654 15.1905 26.5476 14.7852 25.1427L14.8002 25.111C14.8088 25.0981 14.8265 25.0734 14.8351 25.0606C15.5529 24.0201 16.1058 22.8643 16.7704 21.7852Z" fill="#FFDE83"/>
<path d="M8.71964 13.291C9.1663 13.7683 9.73858 14.1682 10.0634 14.7351C9.2173 14.9493 8.38304 15.2065 7.5332 15.403C7.79358 14.6498 8.31378 13.9809 8.71964 13.291Z" fill="#5D97A0"/>
<path d="M7.53451 15.4042C8.38434 15.2077 9.21861 14.9505 10.0647 14.7363L10.1087 14.7395L10.1651 14.746C10.0733 15.4058 9.85263 16.0425 9.6905 16.6878C9.50099 16.8644 9.2739 16.6448 9.08869 16.5734C8.66834 16.3077 8.25335 16.0317 7.8024 15.8202C7.64456 15.7622 7.52055 15.5625 7.3348 15.6495C7.29346 15.5969 7.25052 15.5464 7.20703 15.4965C7.31601 15.4659 7.42499 15.4348 7.53451 15.4042Z" fill="#E4CD75"/>
<path d="M1.56764 16.9955C3.43695 16.5096 5.30035 15.9996 7.16376 15.4912L7.20563 15.4966C7.24912 15.5465 7.29206 15.597 7.3334 15.6496C6.79494 16.6025 6.20065 17.5275 5.69547 18.497L5.65253 18.5094C4.06721 18.0778 2.47223 17.6799 0.884766 17.2564C0.940061 17.0438 1.39585 17.112 1.56764 16.9955Z" fill="#FEDE84"/>
<path d="M7.33519 15.6495C7.52094 15.5626 7.64496 15.7623 7.80279 15.8203C8.25374 16.0318 8.66873 16.3077 9.08908 16.5735C9.27429 16.6449 9.50138 16.8644 9.69089 16.6878C9.45038 17.5768 9.17712 18.4583 8.97742 19.3576L8.93715 19.371C8.91085 19.379 8.85877 19.3941 8.833 19.4016C8.78844 19.3141 8.68966 19.2883 8.60431 19.2609C7.63529 19.0049 6.6593 18.7772 5.69727 18.497C6.20244 17.5274 6.79673 16.6025 7.33519 15.6495Z" fill="#EAC96E"/>
<path d="M5.653 18.5084L5.69595 18.4961C6.65798 18.7763 7.63398 19.004 8.60299 19.26C8.68835 19.2874 8.78713 19.3132 8.83169 19.4007C7.04881 20.4191 5.26647 21.4439 3.50346 22.4978C3.45353 22.465 3.41165 22.4242 3.37891 22.3748C4.16915 21.1068 4.91054 19.806 5.653 18.5084Z" fill="#777D7C"/>
<path d="M8.93575 19.3708L8.97601 19.3574C9.79632 19.6054 10.6623 19.7305 11.4552 20.0553C11.4471 20.9009 11.4461 21.7475 11.4584 22.5936L11.4439 22.6338C11.4112 22.6644 11.3795 22.695 11.3478 22.7267C10.1775 23.8734 9.02755 25.0405 7.86796 26.198C7.52813 26.5346 7.25326 26.9764 6.83398 27.2121C7.19153 25.812 7.56571 24.414 7.96835 23.0268C8.04941 22.8303 8.08968 22.6236 8.11491 22.4148C8.39944 21.4039 8.69846 20.3941 8.93575 19.3708Z" fill="#F68E95"/>
<path d="M11.4553 20.0547C12.165 20.1331 12.8242 20.4455 13.5318 20.5379L13.5468 20.5754C12.7958 21.1858 12.173 21.9401 11.4585 22.5929C11.4462 21.7468 11.4472 20.9002 11.4553 20.0547Z" fill="#D97D87"/>
<path d="M3.50428 22.4979C3.32443 22.6434 3.22834 22.5543 3.37973 22.375C3.41248 22.4244 3.45435 22.4652 3.50428 22.4979Z" fill="#C1C1C1"/>
<path d="M7.96875 23.0261C7.99291 22.8162 8.03317 22.6089 8.11531 22.4141C8.09008 22.6229 8.04981 22.8296 7.96875 23.0261Z" fill="#F8C6CA"/>
<path d="M11.3496 22.7257C11.3813 22.694 11.413 22.6634 11.4457 22.6328C11.4613 23.9948 11.4484 25.3568 11.4516 26.7188L11.399 26.7198C11.3679 25.389 11.4607 24.0522 11.3496 22.7257Z" fill="#D1E2E9"/>
<path d="M11.3963 26.7208L11.449 26.7197C11.4511 28.0608 11.4495 29.4018 11.4468 30.7434C11.4774 30.7327 11.5381 30.7107 11.5687 30.6999C11.5289 30.7713 11.5053 30.8782 11.3996 30.8583C11.3888 29.4791 11.399 28.1 11.3963 26.7208Z" fill="#A7CAD0"/>
</svg>

  </Row>
);

export default IconLogoSmall;
