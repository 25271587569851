import ActionBtn from 'components/ActionBtn';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import AddATip, { ModalAddTipRef } from 'features/payment/pages/PaymentPage/CustomerSide/components/AddATip';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import multiplePaymentSelectors from 'features/payment/pages/PaymentPage/services/selectors';
import { PaymentSocketData, TypePaymentActors, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { useEffect, useRef } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import useUpdateTicket from '../useUpdateTicket';

const ButtonCard = () => {
  const dispatch = useAppDispatch();
  const updateTicket = useUpdateTicket();
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const ticketContext = useTicketWrapper();
  const tipRef = useRef<ModalAddTipRef>(null);
  const setLoadingPage = useSetLoadingPage();
  const cashierMsg = multiplePaymentSelectors.getCashierSocketMsg();

  const billRef = useRef<{
    billId: string | null,
    detailTicket: IBillDetailData | null,
  }>({
    billId: null,
    detailTicket: null,
  });
  const socketContext = useSocketContext();

  const onDone = async () => {
    loadingRef.current?.setVisible(false);
    const id = billRef.current.billId || '';
    ticketContext.completedTicket(id, true);
    dispatch(multiplePaymentActions.setCashierMsg.fetch(null));
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${billRef.current.billId}`);
  };

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData = JSON.parse(message.body);
    if (!payment) return;
    if (payment.actor !== TypePaymentActors.CUSTOMER) return;

    switch (payment.action) {
      case TypePaymentPassData.COMPLETED_PAYMENT: {
        onDone();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (!cashierMsg) return;
    listeningData(cashierMsg);
  }, [cashierMsg]);

  const getDetailTicket = async (billId: string) => {
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(billId);
      if (res.data.data) return res.data.data;
      else return null;
    } catch (error) {
      return null;
    }
  };

  const postPayment = async (billId: string, detailTicket: IBillDetailData) => {
    const body: IBodyAPIPayment = {
      billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.CREDIT_CARD,
          // paymentType: PAYMENT_TYPE.CASH,
          amount: detailTicket.total,
        },
      ]
    };

    loadingRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        tipRef.current?.open();
        dispatch(multiplePaymentActions.getTransactionDetail.fetch(billId));
        socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
          billId: detailTicket?.billId || '',
          actor: TypePaymentActors.CASHIER,
          action: TypePaymentPassData.REQUEST_SIGNATURE
        });
      }
    } catch (error) {
    } finally {
      loadingRef.current?.setVisible(false);
    }
  };

  const handleAddATip = async (val: number) => {
    const billId = billRef.current.billId;
    if (val && billId) {
      setLoadingPage(true);
      try {
        await paymentApis.addTipCreditCard(billId, val);
      } catch (error) { }
      finally {
        setLoadingPage(false);
      }
    }
    onDone();
  };

  const onCard = async () => {
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;
      const billId = rest.billId;
      const detailTicket = await getDetailTicket(billId);
      if (!detailTicket) throw 'fail';

      billRef.current.billId = billId;
      billRef.current.detailTicket = detailTicket;
      const screen = `/store/${storage.shop_id.get()}/ticket/payment/customer-side/${billId}`;
      socketContext.switchCustomerScreen(screen);
      await postPayment(billId, detailTicket);
    } catch (error) { }
  };
  return (
    <div>
      <ActionBtn icon='cardBtn' ntype='YELLOW' small label='Card' onClick={onCard} />
      <PaymentLoading ref={loadingRef} />
      <AddATip ref={tipRef} onAddATip={handleAddATip} />
    </div>
  );
};

export default ButtonCard;