import fetch from 'services/request';
import { IBodyAPIPayment, IBodyApiSplitTipPayment, IBodyApiUpdateDiscountPayment, IBodyApiUpdateTipPayment } from './types/api';


export const getBillDetail = (billId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/transaction-detail/' + billId,
  });
};

export const payment = (body: IBodyAPIPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/payment/completed',
    body,
  });
};

export const splitTip = (body: IBodyApiSplitTipPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/split-tip',
    body,
  });
};

const updatePaymentTip = (body: IBodyApiUpdateTipPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/update-tip',
    body,
  });
};

const updatePaymentDiscountTicket = (body: IBodyApiUpdateDiscountPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/update-discount-item',
    body,
  });
};


const getTransactionDetail = (id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/transaction-detail/' + id,
  });
};

const getCustomerInfo = (phoneNumber: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-info-by-phone',
    params: { phoneNumber },
  });
};

const updateSignature = (billId: string, signature: string) => {
  return fetch({
    method: 'post',
    url: 'api/v1/payment/update-signature',
    body: { billId, signature },
  });
};

const addTipCreditCard = (billId: string, tip: number) => {
  return fetch({
    method: 'post',
    url: 'api/v1/payment/add-tip-credit-card',
    body: { billId, tip },
  });
};

const makeRating = (billId: string, stars: number) => {
  return fetch({
    method: 'post',
    url: 'api/v1/review',
    body: {
      stars,
      billId,
    }
  });
};

const getInfoPrintWaiting = (type: string, id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/check-in/get-info-print-waiting/' + id,
    params: { type }
  });
};

const getBillInfoWithQRCode = (qrCode: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/get-by-qrcode',
    params: { qrCode }
  });
};

const paymentApis = {
  getBillDetail,
  payment,
  splitTip,
  updatePaymentTip,
  updatePaymentDiscountTicket,
  getTransactionDetail,
  getCustomerInfo,
  updateSignature,
  addTipCreditCard,
  makeRating,
  getInfoPrintWaiting,
  getBillInfoWithQRCode,
};

export default paymentApis;
