import { Col, Form, Row } from 'antd';
import Button from 'components/Button';
import React from 'react';
import styled from 'styled-components';
type IButtonGroupProps = {};
const ButtonGroup: React.FC<IButtonGroupProps> = () => {
  const form = Form.useFormInstance();
  const handleCancel = () => {
    form.resetFields();
  };
  return (
    <ButtonGroupStyled>
      <Row gutter={24}>
        <Col span={12}>
          <Button width="100%" ntype="SECONDARY" onClick={handleCancel}>
            Reset
          </Button>
        </Col>
        <Col span={12}>
          <Form.Item>
            <Button width="100%" ntype="PRIMARY" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </ButtonGroupStyled>
  );
};

export default ButtonGroup;
const ButtonGroupStyled = styled.div``;
