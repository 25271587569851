import ActionBtn from 'components/ActionBtn';
import useUpdateTicket from '../useUpdateTicket';
import { useNavigate, useParams } from 'react-router-dom';
import storage from 'utils/sessionStorage';

const ButtonSave = () => {
  const { id = '' } = useParams();
  const updateTicket = useUpdateTicket();
  const navigate = useNavigate();

  const handleEditTicket = async () => {
    try {
      const res = await updateTicket();
      if (!res || id === res?.billId) return;
      navigate('/store/' + storage.shop_id.get() + '/ticket/edit/' + res?.billId, { replace: true });
    } catch (error) { }
  };

  return (
    <ActionBtn
      label='Save'
      onClick={handleEditTicket}
      icon='save'
      ntype='LIGHT_BLUE'
      small
    />
  );
};

export default ButtonSave;