import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '../../../images/logo.png';
import Button from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { CHECK_IN_COUNTDOWN } from 'features/checkIn/services/constants';
type IHeaderProps = {};
const Header: React.FC<IHeaderProps> = () => {
  const [count, setCount] = useState(CHECK_IN_COUNTDOWN);
  const navigate = useNavigate();
  const { shop_id = '' } = useParams();
  const goCheckIn = () => {
    navigate(`/store/${shop_id}/check-in/choose-check-in-type`, {
      replace: true,
    });
  };

  useEffect(() => {
    if (count === 0) {
      goCheckIn();
    } else {
      const interval = setInterval(() => {
        setCount((previous) => previous - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [count]);

  return (
    <HeaderStyled className="ant-layout-header-cus">
      <img src={logo} className="logo" />
      <Button ntype="DEFAULT" onClick={goCheckIn}>{`Done (${count}s)`}</Button>
    </HeaderStyled>
  );
};

export default Header;
const HeaderStyled = styled.div`
  position: fixed;
  top:0;
  left:0;
  right:0;
  padding: 1.5rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    height:80%;
    width:auto;
  }
`;
