import Button from 'components/Button';
import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { PHONE_MASKED } from 'utils/mask';
import FormAddCustomer from './Form';
import shopActions from 'services/shop/actions';
import {
  ICreateCustomerBody,
  ICustomerFormValues,
} from 'services/shop/types/customer';
type Props = {
  model?: 'default' | 'new-ticket' | 'icon';
};
const ButtonAddCustomer = ({ model = 'default' }: Props) => {
  const [visible, setVisible] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const onOpen = () => setVisible(true);

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };
  const valid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  const onSubmit = async () => {
    if (!(await valid())) return;
    form.submit();
  };

  const handleFinish = async (values: ICustomerFormValues) => {
    onClose();
    PHONE_MASKED.resolve(values?.phone);
    const phone = PHONE_MASKED.unmaskedValue;
    const payload: ICreateCustomerBody = {
      phone: phone || null,
      name: values.name || null,
      email: values.email || null,
      birthday: values.birthday || null,
    };
    dispatch(shopActions.doAdd.customer.fetch(payload));
  };

  const ButtonView = () => {
    if (model === 'new-ticket') {
      return (
        <Button onClick={onOpen} icon="addPlus" ntype="PRIMARY">
          <Text color="text_3" variant="CONTENT_2">
            ADD NEW CUSTOMER
          </Text>
        </Button>
      );
    }

    if (model === 'icon') {
      return (
        <Button onClick={onOpen} icon="addPlus" ntype="PRIMARY" />
      );
    }

    return (
      <Button icon={'addNewPlus'} ntype={'TEXT'} onClick={onOpen}>
        <Text color="info_3" variant="CONTENT_2">
          Add New Customer
        </Text>
      </Button>
    );
  };

  return (
    <>
      <ButtonView />
      <Modal
        visible={visible}
        modalTitle="ADD NEW CUSTOMER"
        onClose={onClose}
        onSubmit={onSubmit}
        cancelTitle="Close"
        okTitle="Done"
      >
        <Form form={form} onFinish={handleFinish}>
          <FormAddCustomer />
        </Form>
      </Modal>
    </>
  );
};

export default ButtonAddCustomer;
