import Modal from 'components/Modal';
import NumberKeyBoard, { PIN_LENGTH } from 'components/NumberKeyBoard';
import { IApiDeleteClockInOut } from 'features/report/services/types/api';
import { ITimeClockReportHistory } from 'features/report/services/types/reportStaff';
import React, { useEffect, useState } from 'react';
type IModalPassword = {
  onSubmit?: (payload: IApiDeleteClockInOut) => void;
  data: ITimeClockReportHistory | undefined;
  isShown: boolean;
  onClose: () => void;
};
const ModalDeleteTimeInOut: React.FC<IModalPassword> = ({ onSubmit, isShown, onClose, data }) => {
  const [text, setText] = useState('');
  const handleCloseModal = () => {
    setText('');
    onClose();
  };

  useEffect(() => {
    if (text.length === PIN_LENGTH) {
      try {
        const isExcept = true;
        if (isExcept) {
            const payload:IApiDeleteClockInOut = {
                clockId: data?.id || '',
                pinCode: text
            };
          onSubmit && onSubmit(payload);
          handleCloseModal();
        }
      } catch (error) {
        setText('');
      }
    }
  }, [text]);

  useEffect(() => {
    if (!text) {
      setText(String(''));
    }
  }, []);

  return (
      <Modal
        modalTitle="ADMIN PASSWORD"
        visible={isShown}
        onClose={onClose}
        hiddenOkButton={true}
        destroyOnClose={true}
        okTitle=""
        cancelTitle="Close"
      >
        <NumberKeyBoard
          inputClassName="custom-number-input-keyboard"
          value={text}
          onChange={setText}
          type="PIN-PASSWORD"
         />
      </Modal>
  );
};

export default ModalDeleteTimeInOut;

