import React from 'react';
import styled from 'styled-components';
import ReferralsTable from '../Table/ReferralsTable';

type IReferralTabProps = {};
const ReferralTab: React.FC<IReferralTabProps> = () => {
  return (
    <ReferralTabStyled>
      <ReferralsTable />
    </ReferralTabStyled>
  );
};

export default ReferralTab;
const ReferralTabStyled = styled.div``;
