import Button from 'components/Button';
import React from 'react';
import styled from 'styled-components';
type IButtonGroupFooter = {
  clickBack: () => void;
  clickSkip?: () => void;
  clickNext?: () => void;
  clickDone?: () => void;
  hasSkip?: boolean;
  hasNext?: boolean;
  className?: string;
};
const ButtonGroupFooter: React.FC<IButtonGroupFooter> = ({
  hasNext,
  hasSkip,
  clickBack = () => undefined,
  clickSkip = () => undefined,
  clickNext = () => undefined,
  clickDone = () => undefined,
  className = '',
}) => {
  return (
    <ButtonGroupFooterStyled className={`${className ?? ''}`}>
      <Button onClick={clickBack} ntype="SECONDARY" className="btn" icon="back">
        Back
      </Button>
      {hasSkip ? (
        <Button
          onClick={clickSkip}
          className="btn reverse skip"
          icon="rightDoubleArrow"
        >
          Skip
        </Button>
      ) : null}
      {hasNext ? (
        <Button
          onClick={clickNext}
          ntype="PRIMARY"
          className="btn reverse"
          icon="next"
        >
          Next
        </Button>
      ) : (
        <Button onClick={clickDone} ntype="PRIMARY" className="btn">
          Done
        </Button>
      )}
    </ButtonGroupFooterStyled>
  );
};

export default ButtonGroupFooter;
const ButtonGroupFooterStyled = styled.div`
    /* position: absolute; */
    margin-top: 1rem;
    margin-right: 1rem;
    bottom: 4rem;
    right: 2.125rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 1.5rem;

    .btn {
        width: 8.25rem;
    }

    .reverse {
        flex-direction: row-reverse;
    }
    
    .skip {
        background-color: white;
    }
`;
