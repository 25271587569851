import { Layout } from 'antd';
import Sidebar from 'components/layout/Sidebar';
import React from 'react';
import AppointmentContent from './components/AppointmentContent';
import AppointmentDetailPageStyled from './styles';
type IAppointmentDetailPageProps = any;
const AppointmentDetailPage: React.FC<IAppointmentDetailPageProps> = () => {
  return (
    <AppointmentDetailPageStyled>
      <Sidebar />
      <Layout>
        <AppointmentContent />
      </Layout>
    </AppointmentDetailPageStyled>
  );
};

export default AppointmentDetailPage;

