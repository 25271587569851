import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toCurrency } from './currency-conversion';

export default class CurrencyInputMasked extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    separator: PropTypes.oneOf(['.']),
  };

  static defaultProps = {
    separator: '.',
  };

  state = {
    value: this.props.defaultValue || '',
  };

  handleChange = event => {
    const { onChange, separator } = this.props;
    const valueAsCurrency = toCurrency(event.target.value, separator);

    this.setState({ value: valueAsCurrency });

    if (onChange) {
      event.persist();
      onChange(event, valueAsCurrency);
    }
  };

  get value() {
    return this.props.value ?? this.state.value;
  }

  focus = () => {
    if (!this.inputRef) return;
    typeof this.inputRef.focus === 'function' && this.inputRef.focus();
  };

  render() {
    const {
      handleChange,
      props,
      value,
    } = this;
    return (
      <input
        {...props}
        ref={ref => {
          this.inputRef = ref;
        }}
        // type=""
        pattern="\d*"
        step="0.00"
        value={value}
        onChange={handleChange}
      />
    );
  }
}