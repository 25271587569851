import { Card, Carousel, Col, Row } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { IProduct } from 'features/settingService/services/types/reducer';
import uniqueId from 'lodash/uniqueId';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import ModalAddNewProduct from './ModalAddProduct';
type ICarouselProps = {
    subCategories: number,
    returnListProducts: (products: IProduct[]) => void,
    commissionFee?: number
};
const WIDTH_ITEM_CAROUSEL = 200;
const ProductsCarousel: React.FC<ICarouselProps> = ({
    subCategories,
    returnListProducts,
    commissionFee
}) => {
    const carouselRef = React.createRef<CarouselRef>();
    const [totalPage, setTotalPage] = useState<number>(0);
    const [arrayByPage, setArrayByPage] = useState<number[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [widthItem, setWidthItem] = useState<number>(WIDTH_ITEM_CAROUSEL);
    const [lstProducts, setLstProducts] = useState<any[]>([]);
    const [dataProduct, setDataProduct] = useState<IProduct | undefined>();
    useEffect(() => {
        const sizeProduct = lstProducts?.length;
        const discrepancy = subCategories - lstProducts?.length;
        if (discrepancy >= 0) {
            const discrepancyArray = Array(discrepancy).fill({}).map((m, index) => {
                return {
                    id: '',
                    idTemp: uniqueId(),
                    urlImage: null,
                    image:null,
                    productName: null,
                    orderNumber: sizeProduct + index + 1,
                    priceSell: 0,
                    unit: 0,
                    commission: commissionFee ?? 0,
                };
            });
            const newLstProducts = [...lstProducts, ...discrepancyArray];
            setLstProducts(newLstProducts);
            returnListProducts(newLstProducts);
        } else {
            const newLstProducts = lstProducts.slice(0, discrepancy);
            setLstProducts(newLstProducts);
            returnListProducts(newLstProducts);
        }
    }, [subCategories]);

    useEffect(() => {
        setLstProducts(lstProducts.map(m => { return { ...m, commission: commissionFee ?? 0 }; }));
    }, [commissionFee]);

    useEffect(() => {
        reCalcTotalPage();
    }, [lstProducts]);

    const reCalcTotalPage = () => {
        // const currWidth = window.screen.availWidth;
        const currWidth = 900;
        const widthCarousel = currWidth - 92;
        const maxPageSize = Math.floor(widthCarousel / WIDTH_ITEM_CAROUSEL);
        const remainWidthCarousel = widthCarousel - maxPageSize * WIDTH_ITEM_CAROUSEL;
        const newWidthItem = WIDTH_ITEM_CAROUSEL + (remainWidthCarousel) / maxPageSize;
        const _totalPage = Math.ceil(lstProducts.length / maxPageSize);
        const _arrayByPage = Array.from({ length: _totalPage }, (_, i) => i + 1);
        setTotalPage(_totalPage);
        setPageSize(maxPageSize);
        setArrayByPage(_arrayByPage);
        setWidthItem(newWidthItem);
    };
    const handleCloseModalAddNewProduct = () => {
        setDataProduct(undefined);
    };
    const handleAddNewProduct = (data: IProduct) => {
        const _lstProducts = lstProducts.map((m: IProduct) => {
            if (m.idTemp === data.idTemp) {
                return data;
            }
            return m;
        });
        setLstProducts(_lstProducts);
        returnListProducts(_lstProducts);
    };
    return (
        <>
            <CarouselContainerStyled>
                <Carousel ref={carouselRef} dots={false}>
                    {arrayByPage.map((page) => (
                        <div key={page}>
                            <Row gutter={[12, 12]} style={{ padding: '20px 0px', overflowY: 'auto' }}>
                                {lstProducts.slice((page - 1) * pageSize, page * pageSize).map((item: IProduct) => {
                                    return (
                                        <ColBox key={item.idTemp}
                                            width={widthItem}
                                        >
                                            <CardCus onClick={() => setDataProduct(item)}>
                                                <CameraBox bgimg={item.image ? 'data:image/png;base64,' + item.image : undefined}>
                                                    {item.image ? '' : <Icon type="camera" />}
                                                </CameraBox>
                                                <ProductDetail span={24}>
                                                    <Text variant='CAPTION_2'>{item.productName ?? '-- Product Name --'}</Text>
                                                    <Row>
                                                        <Text variant='CAPTION_1'>Sell price: </Text>
                                                        <Text variant='CAPTION_2'>{formatCurrency(item.priceSell)}</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text variant='CAPTION_1'>Unit: </Text>
                                                        <Text variant='CAPTION_2'>{item.unit}</Text>
                                                    </Row>
                                                    <Row>
                                                        <Text variant='CAPTION_1'>Commission price: </Text>
                                                        <Text variant='CAPTION_2'>{formatCurrency(item.commission)}</Text>
                                                    </Row>
                                                </ProductDetail>
                                            </CardCus>
                                        </ColBox>
                                    );
                                })}
                            </Row>
                        </div>
                    ))}
                </Carousel>
                {totalPage > 1 && <NextButton
                    onClick={() => {
                        carouselRef.current?.next();
                        reCalcTotalPage();
                    }}
                >
                    <Icon type="nextCarousel"></Icon>
                </NextButton>}
                {totalPage > 1 && <PrevButton
                    onClick={() => {
                        carouselRef.current?.prev();
                        reCalcTotalPage();
                    }}
                >
                    <Icon type="nextCarousel"></Icon>
                </PrevButton>}
            </CarouselContainerStyled>
            <ModalAddNewProduct key={Math.random()} isShowModal={!!dataProduct} onClose={handleCloseModalAddNewProduct} data={dataProduct} onSubmit={handleAddNewProduct} />
        </>
    );
};

export default ProductsCarousel;
const CarouselContainerStyled = styled.div`
  position: relative;
  width: calc(900px - 104px);
  padding: 0px 20px 20px;
  margin-left: 10px;
`;
const NextButton = styled.div`
  position: absolute;
  top: 100px;
  right: -10px;
  bottom: 0;
  z-index: 1;
  text-align: center;
`;
const PrevButton = styled.div`
  position: absolute;
  top: 100px;
  left: -10px;
  bottom: 0;
  z-index: 1;
  text-align: center;
  >svg {
    rotate: 180deg;
  }
`;
const ColBox = styled(Col) <{ width?: number }>`
 width: ${props => props.width ? props.width + 'px' : 'undefined'};
`;
const CardCus = styled(Card)`
    .ant-card .ant-card-bordered {
        height: 100%;
    }
    .ant-card-body {
        padding: 12px !important;
        .ant-row {
            justify-content: center;
            div {
                margin: 0px 2px;
            }
        }
    }
`;

const CameraBox = styled.div<{ bgimg: string | undefined }>`
    width: 132px;
    height: 132px;
    margin: 0 auto;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background: ${props => props?.bgimg ? `url(${props?.bgimg})` : '#898989'};
    background-position: center;
`;

const ProductDetail = styled(Col)`
    text-align: center;
`;
