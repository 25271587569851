import { DOMAIN_SOCKET } from 'services/request';
import SockJS from 'sockjs-client';
import { over, Client } from 'stompjs';

export const initSocket = (): Client => {
    const socket = new SockJS(DOMAIN_SOCKET);
    return over(socket);
};

export const SWITCH_SCREEN_TOPIC = '/topic/switch-screen';
export const PAYMENT_PASS_DATA_TOPIC = '/topic/pass-payment-data';

export const WAITING_TICKET_TOPIC = '/topic/waiting-ticket';
export const CUSTOMER_CHECK_IN_TOPIC = '/topic/customer-checkin';
export const STAFF_CLOCK_IN_OUT_TOPIC = '/topic/staff-clock-in-out';
export const SYNC_TURN_TOPIC = '/topic/sync-turn';
export const APPOINTMENT_TOPIC = '/topic/book-appointment-completed';
export const PRINT_CHECK_IN =  '/topic/print-check-in';