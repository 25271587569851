import CarouserBox from 'components/Carosel/CarouserBox';
import AvatarBox from 'features/checkIn/components/AvatarBox';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import React, { useMemo, useRef } from 'react';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

interface IStaffList { }
const StaffList: React.FC<IStaffList> = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const _staffList = checkInSelectors.getStaffsAvailable();
  const selected = checkInSelectors.getSelectedStaff();

  const staffList = useMemo(() => {
    return [
      {
        staffId: null,
        staffName: 'Next Available',
        staffAvatar: null,
      },
      ..._staffList,
    ];
  }, [_staffList]);

  const chooseStaff = (staff: IStaffAvailableItem) => () => {
    dispatch(checkInActions.chooseStaff(staff));
  };

  return (
    <ListStaffBoxStyled ref={divRef}>
      <CarouserBox
        content="Available Staffs"
        list={staffList}
        rowPerPage={12}
        colSpan={4}
        itemEmptyMessage='No available staff'
        itemContent={(item: IStaffAvailableItem) => (
          <AvatarBox
            onClick={chooseStaff(item)}
            key={item.staffId}
            username={item.staffName}
            isSelected={selected?.staffId === item.staffId}
            isStaffNextAvailable={!item.staffId}
            url={item?.staffAvatar}
          />
        )}
      ></CarouserBox>
    </ListStaffBoxStyled>
  );
};

const ListStaffBoxStyled = styled.div`
    width: 100%;
`;
export default StaffList;
