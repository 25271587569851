import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import CalendarMonthView from './MonthView';
import CalendarTableView from './TableView';
import { memo } from 'react';

const CalendarTypeView = () => {
  const viewType = appointmentSelectors.getViewType();
  if (viewType !== CALENDAR_VIEW_TYPE.MONTH_VIEW) {
    return <CalendarTableView />;
  }
  return <CalendarMonthView />;
};

export default memo(CalendarTypeView);