import React from 'react';
import styled from 'styled-components';
import bannerCustomerInfo from '../../../images/bannerCustomerInfo.png';
import Text from 'components/Text';
import checkInSelectors from 'features/checkIn/services/selectors';
import { formatCurrency } from 'utils/formatCurrency';
type IMainProps = {};
const Main: React.FC<IMainProps> = () => {
  const customerData = checkInSelectors.getCheckedInData();
  return (
    <MainStyled>
      <img src={bannerCustomerInfo} className="image-banner" />
      <div className="body">
        <Text variant="H3" color="text_3">
          {customerData.data?.name ?? 'Unknow Name'}
        </Text>
        <Text variant="H4" color="text_2">
          You have checked in successfully!{' '}
        </Text>
        <div className="divider"></div>
        <Text variant="H3" color="info_3">
          {`${customerData.data?.point ?? 0} Points (${formatCurrency(customerData.data?.balance)})`}
        </Text>
      </div>
    </MainStyled>
  );
};

export default Main;
const MainStyled = styled.div`
  .image-banner {
    width:100%;
  }
  .body {
    text-align: center;
  }
  .divider {
    width: 50rem;
    margin: 24px auto;
    height: 1px;
    background: #86909C;
  }
`;
