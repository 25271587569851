import { Col, InputRef, Row } from 'antd';
import Text from 'components/Text';
import React, { useEffect, useMemo, useRef } from 'react';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { ICustomerItemData, ICustomerItemDataOpt } from 'services/shop/types/customer';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ButtonAddCustomer from 'widgets/Customer/AddCustomer';
import CustomerSearchInput from 'widgets/Customer/CustomerSearchInput';
import CustomerItem from 'widgets/Customer/ModalChooseCustomer/CustomerItem';
import { ButtonActions } from './helpers';
type ICustomerTabProps = {
  customer: ICustomerItemData | null;
  setCustomer: (val: ICustomerItemData) => void;
  onClose: () => void;
  next: () => void;
};
const CustomerTab: React.FC<ICustomerTabProps> = ({ onClose, customer, setCustomer, next }) => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<InputRef>(null);

  const customers = shopSelectors.data.customers();

  const options = useMemo(() => {
    return customers.map(o => ({
      ...o,
      label: o.name,
      value: o.id,
      keyword: o.name.toLowerCase(),
    }));
  }, [customers]);

  // const recentList = shopSelectors.data.recentCustomers();

  useEffect(() => {
    dispatch(shopActions.get.recentCustomers.fetch());
  }, []);

  // const recentListOptions = useMemo(() => {
  //   return recentList.map(o => ({
  //     ...o,
  //     label: o.name,
  //     value: o.id,
  //     keyword: o.name.toLowerCase(),
  //   }));
  // }, [recentList]);

  // const addRecent = async (customer_id: string) => {
  //   try {
  //     await shopApis.addCustomersRecent(customer_id);
  //   } catch (error) { }
  // };

  const handleDone = () => next();

  const onPickCustomer = (cus: ICustomerItemData) => {
    // addRecent(cus.id);
    setCustomer(cus);
    handleDone();
  };

  return (
    <>
      <CustomerTabStyled>
        <Row wrap={false} align={'middle'} style={{ gap: '1rem' }}>
          <CustomerSearchInput inputRef={inputRef} onSelect={onPickCustomer} />
          <ButtonAddCustomer model='icon' />
        </Row>
        <Text mb={1.5} />
        {customer && (
          <Row gutter={[16, 16]} justify="start" className='container'>
            <Col span={4} >
              <CustomerItem selected data={customer} />
            </Col>
          </Row>
        )}
        {/* <Text mb={1.5} />
        {recentListOptions.length > 0 && <>
          <Text variant='H8' mb={1} mt={1.5}>Recently Searched</Text>
          <Row gutter={[16, 16]} justify="start">
            {recentListOptions.slice(0, 8).map((item: ICustomerItemDataOpt) => {
              return (
                <Col
                  span={4}
                  key={item.id}
                  onClick={() => onPickCustomer(item)}
                >
                  <CustomerItem data={item} />
                </Col>
              );
            })}
          </Row>
        </>} */}
        <Text mb={1.5} />
        <Text variant='H8' mb={1}>All Customer</Text>
        <ListContainer gutter={[16, 16]} justify="start">
          {options.map((item: ICustomerItemDataOpt) => {
            return (
              <Col
                span={4}
                key={item.id}
                onClick={() => onPickCustomer(item)}
              >
                <CustomerItem
                  data={item}
                />
              </Col>
            );
          })}
        </ListContainer>
      </CustomerTabStyled>
      <ButtonActions first onBack={onClose} onOk={handleDone} disabledOk={!customer} />
    </>
  );
};

export default CustomerTab;
type CustomerTabStyledProps = {}
const CustomerTabStyled = styled.div<CustomerTabStyledProps>``;
const ListContainer = styled(Row)`
  padding-top: 0;
  // max-height: 18rem;
  // min-height: 18rem;
  overflow-y: auto;
`;
