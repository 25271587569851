import { IStaffItemData } from 'services/shop/types/staff';
import { PATH_LOADING } from 'features/settingStaff/services/constants';
import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import uiSelector from 'services/UI/selectors';
import { IServiceItem, IStaffsParams } from './types/api';
import {
  ISalaryConfig,
  IStaffInfo,
  IStaffOffDay,
  IStaffScheduler,
} from './types/staff';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['settingStaff']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['settingStaff']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) =>
  useAppSelector((state) => get(getCurrentState(state), key, defaultValue));

const servicesSelected = () => selector('servicesSelected') as IServiceItem[];
const getStaffs = () => selector('staffs') as MyState['staffs'];
const getStaffListParams = () =>
  selector('staffParams') as MyState['staffParams'];

const getTotal = () => selector('totalElement') as MyState['totalElement'];

export const getStaffsFilterParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.staffParams ?? {};
};
export const getStaffDayOffParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.staffDayOffParams ?? {};
};

const staffsTableData = {
  loading: () => uiSelector.getLoading(PATH_LOADING.getStaffList) as boolean,
  params: () => selector('staffParams') as IStaffsParams,
  data: () => selector('staffs') as IStaffItemData[],
  dataFilter: () => selector('staffsDisplay') as IStaffItemData[],
  totalSize: () => selector('totalElement') as number | null,
};
const selectedStaffData = {
  data: () => selector('selectedStaff') as IStaffInfo,
  dataSalary: () => selector('selectedStaffSalary') as ISalaryConfig,
  dataScheduler: () => selector('scheduler') as IStaffScheduler,
  dataDaysOff: () => selector('staffDaysOff') as IStaffOffDay[],
};

const settingStaffSelectors = {
  getStaffs,
  servicesSelected,
  getStaffListParams,
  getTotal,
  staffsTableData,
  selectedStaffData,
};
export default settingStaffSelectors;
