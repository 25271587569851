const MoneyExchange = () => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2347_117686)">
        <path
          d="M14.2035 19.5801C13.4741 19.3837 12.8211 19.0243 12.3649 18.5683C12.1674 18.3712 11.9049 18.2627 11.6257 18.2627C11.3466 18.2627 11.0841 18.3714 10.8865 18.5687L10.8066 18.6485C10.6097 18.8452 10.498 19.1178 10.5 19.3965C10.5022 19.6748 10.6182 19.9457 10.8184 20.1395C11.7088 21.002 12.9664 21.6255 14.269 21.8502L14.3952 21.872V22.8592C14.3952 23.4357 14.8642 23.9049 15.4408 23.9049H15.5594C16.1359 23.9049 16.6049 23.4357 16.6049 22.8592V21.849L16.7286 21.8255C18.8905 21.4139 20.1815 20.0626 20.1815 18.2123C20.2004 15.6959 18.3365 14.7773 16.7109 14.2582L16.6049 14.2245V10.835L16.7896 10.8754C17.2689 10.9802 17.6968 11.1404 18.0271 11.3388C18.1898 11.4363 18.3754 11.4877 18.564 11.4877C18.9275 11.4877 19.2592 11.304 19.4516 10.9961L19.5046 10.9112C19.6519 10.6754 19.6993 10.3852 19.635 10.1151C19.5705 9.84456 19.3971 9.60678 19.1593 9.4628C18.4927 9.05954 17.6315 8.76178 16.7339 8.6245L16.6049 8.60473V7.85825C16.6049 7.28167 16.1359 6.8125 15.5594 6.8125H15.4408C14.8642 6.8125 14.3952 7.2816 14.3952 7.85825V8.66007L14.2732 8.6847C12.2752 9.08766 11.0824 10.3412 11.0824 12.0377C11.0824 13.8536 12.1322 15.0922 14.292 15.8242L14.3952 15.8592V19.6316L14.2035 19.5801ZM16.6049 16.5651L16.8183 16.6586C17.6655 17.0304 17.9784 17.4472 17.9719 18.1989C17.9719 18.8249 17.5792 19.2712 16.8047 19.5258L16.6051 19.5912L16.6049 16.5651ZM14.1714 13.3676C13.5385 13.0295 13.2919 12.6566 13.2919 12.0378C13.2919 11.5674 13.5943 11.2174 14.1905 10.9976L14.3951 10.9221V13.487L14.1714 13.3676Z"
          fill="#1D2129"
        />
        <path
          d="M30.1347 6.26595C29.8264 6.01935 29.4001 5.98271 29.0541 6.17313L26.9998 7.3044C24.4437 3.37695 20.0775 0.980469 15.3406 0.980469C7.67016 0.980469 1.42969 7.22086 1.42969 14.8914C1.42969 15.7311 2.11041 16.4117 2.95001 16.4117C3.78976 16.4117 4.47033 15.7311 4.47033 14.8914C4.47033 8.89747 9.34677 4.02111 15.3406 4.02111C18.9661 4.02111 22.315 5.8173 24.3283 8.77539L22.4041 9.83498C22.0583 10.0254 21.8612 10.4053 21.9051 10.7977C21.9485 11.19 22.2243 11.5175 22.6035 11.6274L27.7596 13.1215C27.8486 13.1473 27.9399 13.16 28.0311 13.16C28.1942 13.16 28.3564 13.1191 28.5018 13.039C28.7284 12.9141 28.8963 12.7043 28.9685 12.4557L30.4625 7.29969C30.5722 6.92052 30.4429 6.51254 30.1347 6.26595Z"
          fill="#1D2129"
        />
        <path
          d="M28.051 14.5859C27.2112 14.5859 26.5307 15.2666 26.5307 16.1063C26.5307 22.1003 21.6543 26.9766 15.6603 26.9766C12.0349 26.9766 8.68594 25.1804 6.67265 22.2223L8.59692 21.1627C8.94272 20.9724 9.13975 20.5924 9.09604 20.2C9.05256 19.8077 8.77685 19.4802 8.39761 19.3705L3.24166 17.8764C3.15257 17.8505 3.06127 17.8378 2.97013 17.8378C2.807 17.8378 2.64486 17.8787 2.49936 17.9588C2.27268 18.0837 2.10476 18.2935 2.0327 18.5421L0.538602 23.6981C0.428759 24.0772 0.558062 24.4851 0.866308 24.7318C1.17455 24.9784 1.60093 25.0152 1.94688 24.8247L4.00121 23.6934C6.55718 27.6207 10.9234 30.0172 15.6602 30.0172C23.3307 30.0172 29.5712 23.7768 29.5712 16.1063C29.5713 15.2666 28.8905 14.5859 28.051 14.5859Z"
          fill="#1D2129"
        />
      </g>
      <defs>
        <clipPath id="clip0_2347_117686">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0.5 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MoneyExchange;
