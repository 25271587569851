import { DatePicker as AntDatePickerUI, Row } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import Text from 'components/Text';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import WeeklyPicker from './WeeklyPicker';

const AntDatePicker = AntDatePickerUI.generatePicker<Moment>(momentGenerateConfig);

type IDatePickerProps = PickerDateProps<Moment> & {
    actions: any,
    selectors: any,
    type: string
    size?: SizeType
};
const AppointmentDatePicker: React.FC<IDatePickerProps> = ({
    actions,
    selectors,
    type,
    size = 'small'
}) => {
    const dispatch = useAppDispatch();
    const viewType = selectors.getViewType()?.[type] || CALENDAR_VIEW_TYPE.DAY_VIEW;
    const params = selectors.getParams()?.[type];

    const startDate = useMemo(() => {
        return params?.startTime ? moment(params?.startTime, DATE_FORMAT) :  null;
    }, [params]);

    const endDate = useMemo(() => {
        return params?.endTime ? moment(params?.endTime, DATE_FORMAT) :  null;
    }, [params]);


    if (viewType === CALENDAR_VIEW_TYPE.DAY_VIEW) {
        const onChange: IDatePickerProps['onChange'] = (date) => {
            if (!date) return;
            dispatch(actions.setParams({
                [type]: {
                    startTime: date?.format(DATE_FORMAT),
                    endTime: date?.format(DATE_FORMAT),
                }
            }));
        };

        return (
            <Row>
                <DatePickerStyled
                    onChange={onChange}
                    size={size}
                    inputReadOnly
                    clearIcon={false}
                    value={startDate}
                    inputRender={(props: any) => (
                        <InputUI {...props} justify={'space-between'} align={'middle'} >
                            <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>
                            <Text variant='CONTENT_2' color='text_3' >{startDate?.format(DATE_FORMAT)}</Text>
                        </InputUI>
                    )}
                />
            </Row>
        );
    }

    if (viewType === CALENDAR_VIEW_TYPE.WEEK_VIEW) {

        const onChange = (values: RangeValue<Moment>) => {
            if (!values) return;
            dispatch(actions.setParams({
                [type]: {
                    startTime: values?.[0]?.format(DATE_FORMAT),
                    endTime: values?.[1]?.format(DATE_FORMAT),
                }
            }));
        };

        return (
                <WeeklyPicker onSubmit={onChange} defaultValue={[startDate, endDate]}/>
        );
    }

    if (viewType === CALENDAR_VIEW_TYPE.MONTH_VIEW) {
        const onChange: IDatePickerProps['onChange'] = (date) => {
            if (!date) return;
            dispatch(actions.setParams({
                [type]: {
                    startTime: date?.startOf('month').format(DATE_FORMAT),
                    endTime: date?.endOf('month').format(DATE_FORMAT),
                }
            }));
        };
        return (
            <Row>
                <DatePickerStyled
                    clearIcon={false}
                    inputReadOnly
                    value={startDate}
                    onChange={onChange}
                    picker="month"
                    inputRender={(props: any) => (
                        <InputUI {...props} justify={'space-between'} align={'middle'} >
                            <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>
                            <Text variant='CONTENT_2' color='text_3' >{startDate?.format('MM-YYYY')}</Text>
                        </InputUI>
                    )}
                    size={size}
                />
            </Row>
        );
    }

    return null;
};

export default AppointmentDatePicker;
type DatePickerStyledProps = {}
const DatePickerStyled = styled(AntDatePicker) <DatePickerStyledProps>`
  min-width: 20rem;
`;

const InputUI = styled(Row)`
flex: 1;
padding: 0.5rem;
margin-right: 0.5rem;
-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;
