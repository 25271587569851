const Item = (props: any) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
            <path d="M4 8H21" stroke="#1D2129" strokeLinecap="round" />
            <path d="M4 12H21" stroke="#1D2129" strokeLinecap="round" />
            <path d="M4 16H12" stroke="#1D2129" strokeLinecap="round" />
        </svg>
    );
};

export default Item;