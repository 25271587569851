import { QRCode, Row } from 'antd';
import React from 'react';
import { ICouponPrintGiftCard } from 'services/shop/types/coupon';
import styled from 'styled-components';
import applePayImage from './applePayIcon.png';
import GGPayIcon from './GGPayIcon';
import AndroidIcon from './androidIcon';
import TabIcon from './tabIcon';
type Props = {
  data: ICouponPrintGiftCard;
};
const BottomGiftCard = ({ data }: Props) => {
  return (
    <BottomGiftCardStyled>
      <Row align={'middle'} justify={'space-between'}>
        <div>
          <Title>Now accept These Form Of Payments</Title>
          <Payments>
            <PaymentBox>
              <img src={applePayImage} />
            </PaymentBox>
            <PaymentBox>
              <GGPayIcon />
            </PaymentBox>
            <PaymentBox>
              <AndroidIcon />
            </PaymentBox>
            <PaymentBox>
              <TabIcon />
            </PaymentBox>
          </Payments>
        </div>
        <QRCode color='black' bordered={false} size={90} value={data?.url} />
      </Row>
      <ShopText>* This coupon to be used at <span className='name'>&nbsp;{data.shopName}&nbsp;</span> only!</ShopText>
    </BottomGiftCardStyled>
  );
};

export default BottomGiftCard;

const BottomGiftCardStyled = styled.div`
  padding: 0 11px;
`;
const Title = styled.div`
color: black;
font-size: 11px;
font-style: normal;
font-weight: bold;
line-height: normal;
letter-spacing: 1.65px;
text-transform: capitalize;
font-weight: 900;
font-family: 'Roboto', sans-serif;
margin-bottom: 4px;
`;

const ShopText = styled(Row)`
  color: black;
  font-size: 12px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  .name {
    font-weight: 700;
  }
  transform: translateY(-10px);
  font-weight: 900;
  font-family: 'Roboto', sans-serif;
`;

const Payments = styled(Row)`
  gap: 8px;
`;
const PaymentBox = styled.div`
  display: flex;
  width: 54px;
  height: 39px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  img {
    width: 41px;
    height: 18px;
  }
  border-radius: 5px;
  border: 2px solid black;
`;