import { ICouponPrintData } from 'services/shop/types/coupon';
import CouponUI from './CouponUI';
type Props = {
  data: ICouponPrintData['DISCOUNT']
};
const CouponDiscount = ({ data }: Props) => {
  if (!data.length) return null;

  return (
    <>
      {data.map((o, i) => (<CouponUI data={o} key={i} />))}
    </>
  );
};

export default CouponDiscount;
