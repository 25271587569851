

const Glass = (props: any) => {
    return (
        <svg width="29" height="31" viewBox="0 0 29 31" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M14.9205 0.5H16.3758C17.974 0.663658 19.5442 1.20034 20.8417 2.16017C22.7053 3.49155 24.0043 5.58813 24.364 7.85133C24.7975 10.3283 24.0736 12.9793 22.4562 14.9048C21.5995 15.9443 20.4938 16.7699 19.2656 17.3199C18.5608 17.6236 17.9077 18.2576 17.9091 19.0729C17.8944 20.7434 17.8988 22.4169 17.9077 24.0888C17.8944 25.1253 18.7584 25.8861 18.8616 26.8931C19.0724 28.3469 18.1391 29.8626 16.7399 30.3152C15.5265 30.7737 14.0713 30.3506 13.2501 29.3598C12.4244 28.3985 12.2578 26.9241 12.88 25.8154C13.2029 25.2374 13.5125 24.6152 13.4933 23.9355C13.4963 22.4626 13.486 20.9882 13.4963 19.5138C13.5081 19.0317 13.4668 18.5112 13.1557 18.1175C12.7959 17.603 12.2018 17.3538 11.6577 17.0855C8.84309 15.6804 6.9308 12.6387 6.91016 9.48643C6.82906 7.15099 7.76236 4.81998 9.39009 3.14949C10.8409 1.63676 12.8475 0.732954 14.9205 0.5ZM15.04 1.90657C13.1409 2.07613 11.3186 3.00205 10.0801 4.45433C8.91828 5.80045 8.24891 7.57562 8.28282 9.35669C8.27545 11.6508 9.43727 13.9052 11.2906 15.2528C12.0661 15.8558 13.0112 16.1713 13.8369 16.6933C14.2895 16.9734 14.5519 17.5116 14.5372 18.0394C14.5446 20.3055 14.5387 22.5702 14.5401 24.8364C14.5726 25.6222 13.8398 26.1456 13.7204 26.8931C13.5302 27.9517 14.3632 29.059 15.4381 29.1563C16.5335 29.3377 17.6408 28.4383 17.6821 27.3296C17.7956 26.3447 16.8933 25.6399 16.8785 24.6801C16.8549 22.5658 16.8815 20.4515 16.8653 18.3372C16.849 17.8197 16.9714 17.2521 17.3503 16.8776C17.9106 16.4176 18.5859 16.1374 19.2258 15.8086C21.5199 14.5937 23.0607 12.0932 23.099 9.49528C23.1668 7.47094 22.353 5.4407 20.9036 4.02528C19.3865 2.50223 17.1778 1.71048 15.04 1.90657Z" fill="#241F20" />
            <path d="M15.0417 2.92949C16.7019 2.75108 18.4181 3.26123 19.7008 4.33016C21.1428 5.50378 22.0009 7.3453 21.9979 9.20303C21.9935 11.018 21.1693 12.8168 19.779 13.9889C18.5862 15.0166 16.9924 15.5636 15.4192 15.4913C13.4818 15.4132 11.6093 14.3855 10.5139 12.7829C9.31224 11.0903 9.06897 8.78431 9.8386 6.86465C10.6643 4.72088 12.752 3.13295 15.0417 2.92949ZM14.6186 4.3051C13.8313 4.48645 13.0793 4.84178 12.4674 5.37256C10.8279 6.70836 10.2323 9.13374 11.0579 11.0785C11.8983 13.2488 14.3901 14.6052 16.668 14.0965C18.9577 13.6999 20.7359 11.5075 20.6946 9.19124C20.7019 7.6387 19.9426 6.10828 18.6909 5.18973C17.5512 4.30805 16.0178 4.01169 14.6186 4.3051Z" fill="#241F20" />
            <path d="M12.9453 11.5696C14.7087 9.95365 16.4603 8.32592 18.2207 6.70703C18.4138 6.97095 19.0729 7.34102 18.5819 7.6418C16.8878 9.15895 15.2572 10.7469 13.5454 12.2449C13.3449 12.0207 13.1444 11.7966 12.9453 11.5696Z" fill="#241F20" />
            <path d="M18.5905 8.68468C18.7924 8.48711 19.0121 8.31018 19.2392 8.14062C19.4102 8.66403 19.5223 9.21104 19.4736 9.76393C18.2941 10.824 17.1529 11.9283 15.9778 12.9943C15.4264 13.1904 14.8057 12.9 14.2749 12.7068C15.6888 11.3401 17.1618 10.0352 18.5905 8.68468Z" fill="#241F20" />
            <path d="M3.44262 10.9258C3.71243 12.1245 4.63393 13.1742 5.86063 13.4308C4.70028 13.8097 3.6623 14.7312 3.35416 15.9387C3.06812 14.7209 2.0788 13.6932 0.843262 13.4396C2.11124 13.2376 3.15216 12.1584 3.44262 10.9258Z" fill="#241F20" />
            <path d="M7.09759 16.5508C7.39394 18.3952 8.86391 19.9964 10.7275 20.3046C9.53329 20.4977 8.53954 21.3042 7.7935 22.2184C7.36888 22.7226 7.07842 23.33 6.95752 23.9788C6.60809 22.208 5.16466 20.6039 3.33936 20.3311C5.17645 19.9596 6.77322 18.4144 7.09759 16.5508ZM5.38729 20.3193C6.1584 20.5921 6.77764 21.2128 6.9929 22.009C7.27451 21.2231 7.91587 20.5641 8.72974 20.3341C7.93947 20.0731 7.26419 19.4553 7.10054 18.6149C6.79976 19.3949 6.19526 20.0554 5.38729 20.3193Z" fill="#241F20" />
            <path d="M20.814 24.1987C22.626 23.8316 24.1623 22.3159 24.5457 20.5039C24.7683 21.4107 25.162 22.3012 25.8387 22.9632C26.4653 23.5957 27.2925 23.9997 28.1564 24.1928C26.9327 24.3816 25.9301 25.2323 25.1811 26.1715C24.8037 26.6403 24.5442 27.1918 24.4248 27.7815C23.978 26.0638 22.6171 24.5039 20.814 24.1987ZM23.1022 24.2046C23.7672 24.3904 24.2493 24.9345 24.4601 25.5788C24.671 24.9109 25.2298 24.4081 25.8844 24.1855C25.1914 24.0262 24.6607 23.4542 24.5162 22.7642C24.3289 23.4527 23.7834 23.9982 23.1022 24.2046Z" fill="#241F20" />
        </svg>);
};

export default Glass;