import { Col, Layout, Row, Space } from 'antd';
import React from 'react';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import AlarmClockUI from './AlarmClockUI';
import ButtonAddNewTicket from './Buttons/ButtonAddNewTicket';
import ButtonClockIn from './Buttons/ButtonClockIn';
import ButtonClockInOutHistory from './Buttons/ButtonClockInOutHistory';
type IHeaderProps = {};
const Header: React.FC<IHeaderProps> = () => {
  return (
    <HeaderStyled>
      <Space direction="horizontal" align="center">
        <Content />
      </Space>
      <Row style={{ flex: 1 }} justify={'center'}>
        <AlarmClockUI />
      </Row>
      <Row className="header-actions">
        <ButtonClockInOutHistory />
        <ButtonAddNewTicket />
        <ButtonClockIn isClockIn />
        <ButtonClockIn isClockIn={false} />
      </Row>
    </HeaderStyled>
  );
};

export default Header;

const Content = () => {
  return (
    <ContentStyled align={'middle'} gutter={16} className="staff-info">
      <Col>
        <div className="station">Station {storage.station_number.get()}</div>
      </Col>
    </ContentStyled>
  );
};

const ContentStyled = styled(Row)`
  .station {
    border-radius: 5px;
    background: var(--fill-fill-4, #1D2129);
    display: flex;
    height: 42px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 8px;

    color: var(--text-text-1, #FFF);
    text-align: center;
    font-family: Open Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;

const HeaderStyled = styled(Layout.Header)`
  max-height: unset;
  height: unset;
  background: #efefef;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem;
  .header-actions {
    gap: 1rem;
  }
  .staff-info {
    .avatar {
      height: 3.75rem;
      width: 3.75rem;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;
