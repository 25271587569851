import { APPOINTMENT_LAYOUT } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import React, { memo } from 'react';
import CalendarTypeView from './Calendar/CalendarTypeView';
import ListLayout from './ListLayout';

function AppointmentLayout() {
    const layout = appointmentSelectors.getAppointmentLayout();
    if (layout === APPOINTMENT_LAYOUT.CALENDAR)
        return <CalendarTypeView />;
    return <ListLayout />;
}

export default memo(AppointmentLayout);
