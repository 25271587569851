import React from 'react';
import styled from 'styled-components';
import model from '../images/model.png';
type IComponentProps = {};
const ModelBox: React.FC<IComponentProps> = () => {
  return <ModelBoxStyled src={model} />;
};

export default ModelBox;
const ModelBoxStyled = styled.img`
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 5.6rem;
`;
