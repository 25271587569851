import { createAction, createAsyncAction } from 'services/actionConfigs';
import { IProductCategoryItem, IProductItemData } from 'services/shop/types/product';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { FEATURE_PRODUCT_MODE, PREFIX_ACTIONS } from './constants';
import { ICategory, ICreateCustomerBody, IServiceCategory, IStaff } from './types/api';
import { ICustomerResItem } from './types/customer';
import { IStateDiscountItemValue } from './types/discount';
import { IGiftCardResult } from './types/giftCard';
import { IState } from './types/reducer';
import { IProductVIPItemData } from 'services/shop/types/vip';
import { IDraftQuickPayItem } from './types/quickpay';

const initData = createAction(PREFIX_ACTIONS + 'initData');
const getDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'getDetail');

// actions init data
const getTicketDetails = createAsyncAction<string>(PREFIX_ACTIONS + 'getTicketDetails');
const addNewCustomer = createAsyncAction<ICreateCustomerBody>(PREFIX_ACTIONS + 'addNewCustomer');

// actions handle data UI
const selectAddOnItem = createAction<IServiceCategory>(PREFIX_ACTIONS + 'selectAddOnItem');
const selectServiceItem = createAction<IServiceCategory>(PREFIX_ACTIONS + 'selectServiceItem');
const selectProductItem = createAction<IProductItemData>(PREFIX_ACTIONS + 'selectProductItem');
const selectProductVIPItem = createAction<IProductVIPItemData>(PREFIX_ACTIONS + 'selectProductVIPItem');

const addStaffItem = createAction<IStaff>(PREFIX_ACTIONS + 'addStaffItem');
const switchStaffItem = createAction<string>(PREFIX_ACTIONS + 'switchStaffItem');
const removeStaffItem = createAction<string>(PREFIX_ACTIONS + 'removeStaffItem');
const removeServiceOfItem = createAction<{ staffId: string, serviceId?: string }>(PREFIX_ACTIONS + 'removeServiceOfItem');
const setActiveStaffId = createAction<string>(PREFIX_ACTIONS + 'setActiveStaffId');
const setActiveItemsCollapse = createAction<string[]>(PREFIX_ACTIONS + 'setActiveItemsCollapse');
const switchSelectedCustomer = createAction<ICustomerResItem>(PREFIX_ACTIONS + 'switchSelectedCustomer');
const removeSelectedCustomer = createAction(PREFIX_ACTIONS + 'removeSelectedCustomer');
const calculateTicketPaymentInfo = createAction(PREFIX_ACTIONS + 'calculateTicketPaymentInfo');
const setVisibleChooseCustomer = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleChooseCustomer');
const setVisibleVIP = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleVIP');
const setVisibleChooseStaff = createAction<IState['visibleChooseStaff']>(PREFIX_ACTIONS + 'setVisibleChooseStaff');
const setVisibleModalWarningEmptyServices = createAction<IState['visibleModalWarningEmptyServices']>(PREFIX_ACTIONS + 'setVisibleModalWarningEmptyServices');
const setVisibleModalWarningVIPPackage = createAction<IState['visibleModalWarningVIPPackage']>(PREFIX_ACTIONS + 'setVisibleModalWarningVIPPackage');
const clearTicket = createAction(PREFIX_ACTIONS + 'clearTicket');
const setDraftStaffForNewTicket = createAction<IStaffAvailableItem>(PREFIX_ACTIONS + 'setDraftStaffForNewTicket');

// check-in
const selectCategory = createAction<ICategory>(PREFIX_ACTIONS + 'selectCategory');
const selectProductCategory = createAction<IProductCategoryItem>(PREFIX_ACTIONS + 'selectProductCategory');
const selectServiceItemAtCheckIn = createAction<IServiceCategory>(PREFIX_ACTIONS + 'selectServiceItemAtCheckIn');
const selectAddOnServiceAtCheckIn = createAction(PREFIX_ACTIONS + 'selectAddOnServiceAtCheckIn');
const initCategories = createAction(PREFIX_ACTIONS + 'initCategories');
const setTip = createAction<number>(PREFIX_ACTIONS + 'setTip');

const setDraftServiceSwitchStaff = createAction<string | null>(PREFIX_ACTIONS + 'setDraftServiceSwitchStaff');

const setDraftQuickPay = createAction<IDraftQuickPayItem | null>(PREFIX_ACTIONS + 'setDraftQuickPay');
const updateQuickPayItem = createAction<IDraftQuickPayItem>(PREFIX_ACTIONS + 'updateQuickPayItem');

const switchStaffNew = createAction<{ newStaff: IStaff, currStaffId: string }>(PREFIX_ACTIONS + 'switchStaffNew');

const toggleVisibleDiscountItemMode = createAction(PREFIX_ACTIONS + 'toggleVisibleDiscountItemMode');

const toggleVisibleDeleteItemMode = createAction(PREFIX_ACTIONS + 'toggleVisibleDeleteItemMode');

const setDiscountTicket = createAction<IState['discountTicket']>(PREFIX_ACTIONS + 'setDiscountTicket');

const setDraftDiscountItemModal = createAction<IState['draftDiscountItemModal']>(PREFIX_ACTIONS + 'setDraftDiscountItemModal');

const setDiscountItem = createAction<IStateDiscountItemValue>(PREFIX_ACTIONS + 'setDiscountItem');

const addQuickPay = createAction<number>(PREFIX_ACTIONS + 'addQuickPay');

const addGiftCard = createAction<IGiftCardResult>(PREFIX_ACTIONS + 'addGiftCard');

const setFeatureProductMode = createAction<FEATURE_PRODUCT_MODE>(PREFIX_ACTIONS + 'setFeatureProductMode');

const clearCustomer = createAsyncAction(PREFIX_ACTIONS + 'clearCustomer');


const checkCustomerPackage = createAsyncAction<string>(PREFIX_ACTIONS + 'checkCustomerPackage');

const setEnableCardFee = createAction<boolean>(PREFIX_ACTIONS + 'setEnableCardFee');

const setVisibleCashierConfirmUpdate = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleCashierConfirmUpdate');
const checkCashierWillUnmount = createAction(PREFIX_ACTIONS + 'checkCashierWillUnmount');

const setHighlightItemId = createAction<string>(PREFIX_ACTIONS + 'setHighlightItemId');

const cashierActions = {
  getDetail,
  initData,

  getTicketDetails,
  addNewCustomer,

  selectCategory,
  selectProductCategory,
  selectAddOnItem,
  selectServiceItem,
  addStaffItem,
  removeStaffItem,
  switchStaffItem,
  switchStaffNew,
  clearTicket,

  switchSelectedCustomer,
  removeSelectedCustomer,

  removeServiceOfItem,
  setActiveStaffId,
  selectServiceItemAtCheckIn,
  selectAddOnServiceAtCheckIn,
  initCategories,
  setActiveItemsCollapse,

  calculateTicketPaymentInfo,
  setTip,
  setVisibleChooseCustomer,
  setVisibleVIP,
  setVisibleChooseStaff,
  setDraftServiceSwitchStaff,
  toggleVisibleDiscountItemMode,
  toggleVisibleDeleteItemMode,
  setDiscountTicket,
  setDraftDiscountItemModal,
  setDiscountItem,
  addQuickPay,
  addGiftCard,
  setDraftStaffForNewTicket,

  selectProductItem,

  setFeatureProductMode,

  selectProductVIPItem,

  setVisibleModalWarningEmptyServices,
  setVisibleModalWarningVIPPackage,
  setDraftQuickPay,
  updateQuickPayItem,
  clearCustomer,
  checkCustomerPackage,
  setEnableCardFee,
  setVisibleCashierConfirmUpdate,
  checkCashierWillUnmount,
  setHighlightItemId,
};

export default cashierActions;
