import { Col, Form, Row } from 'antd';
import Button from 'components/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
type IButtonGroupProps = {};
const ButtonGroup: React.FC<IButtonGroupProps> = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/store/${storage.shop_id.get()}/setting-staff`);
  };

  return (
    <ButtonGroupStyled>
      <Row gutter={24}>
        <Col span={8} offset={4}>
          <Button
            onClick={handleBack}
            width="100%"
            ntype="DEFAULT"
            icon="arrowLeft"
          >
            Back
          </Button>
        </Col>

        <Col span={8}>
          <Form.Item>
            <Button width="100%" ntype="PRIMARY" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </ButtonGroupStyled>
  );
};

export default ButtonGroup;
const ButtonGroupStyled = styled.div``;
