import Box from 'components/Box';
import { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import CardBox from 'features/cashier/components/CardBox';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IStateDetailPayment } from 'features/payment/services/types/payment';
import { useSocketContext } from 'hooks/useSocket';
import { get, sumBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { setLoadingPage } from 'services/UI/sagas';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import AddATip, { ModalAddTipRef } from '../../../CustomerSide/components/AddATip';
import multiplePaymentActions from '../../../services/actions';
import multiplePaymentSelectors from '../../../services/selectors';
import PassDataPayment, { PassDataPaymentRef } from '../../PassDataPayment';
import CheckOthers from '../CheckOthers';
import CreditCard from '../CreditCard';
import ButtonGiftCard from '../GiftCard';
import LoyaltyPoint from '../LoyaltyPoint';
import DescribePayment from './DescribePayment';
import DetailItems from './DetailItems';
import DetailPayment from './DetailPayment';
import PaymentKeyboard from './PaymentKeyboard';

const PaymentInfoBox = () => {
  const tipRef = useRef<ModalAddTipRef>(null);

  const { id = '' } = useParams();
  const customerRef = useRef<PassDataPaymentRef>(null);
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const dispatch = useAppDispatch();
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const triggerClear = useRef(false);

  const [amountKB, setAmountKB] = useState<number | null>();

  const ticketContext = useTicketWrapper();
  const navigate = useNavigate();
  const socketContext = useSocketContext();

  const detailPayment = multiplePaymentSelectors.getDetailPayment();
  const detailTicket = multiplePaymentSelectors.getDetail();

  const onBack = () => {
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/check-in/sign-in`);
    navigate(-1);
  };

  const remaining = useMemo(() => {
    const totalPayment = sumBy(Object.values(detailPayment), o => (o?.amount ?? 0));
    const value = (detailTicket?.total ?? 0) - totalPayment;
    return value > 0 ? value : 0;
  }, [detailTicket, detailPayment]);

  const handleClear = () => {
    triggerClear.current = true;
    currencyRef.current?.clear();
    dispatch(multiplePaymentActions.clearPaymentInfo());
  };


  useEffect(() => {
    if (triggerClear.current) {
      triggerClear.current = false;
      return;
    }
    currencyRef.current?.setValue(0?.toFixed(2) || '');
  }, [detailPayment, detailTicket]);

  const onCompleted = async (ignorePrint?: boolean) => {
    if (!detailTicket?.billId) return;
    dispatch(multiplePaymentActions.setCashierMsg.fetch(null));
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${id}`);
    if (ignorePrint) {
      navigate(`/store/${storage.shop_id.get()}`);
      return;
    }
    ticketContext.completedTicket(detailTicket?.billId, true);
  };

  const onPostPaymentWithCreditCard = () => {
    tipRef.current?.open();
    customerRef.current?.requestSignature();
  };

  const handlePostPayment = async (_detailPayment: IStateDetailPayment, ignorePrint?: boolean) => {
    const paymentItems = Object.entries(_detailPayment).map(([key, value]) => {
      if (key === PAYMENT_TYPE.CASH) {
        return ({
          paymentType: key as PAYMENT_TYPE,
          ...value,
          chance: Math.abs(get(value, 'chance', 0) || 0),
          amount: value?.amount ?? 0,
        });
      }
      return ({
        paymentType: key as PAYMENT_TYPE,
        ...value,
        amount: value?.amount ?? 0,
      });
    }).filter(o => !!o.amount);

    loadingRef.current?.setVisible(true);
    const body: IBodyAPIPayment = {
      billId: detailTicket?.billId || '',
      paymentInfo: paymentItems,
    };
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        if (detailPayment.CREDIT_CARD?.amount) {
          onPostPaymentWithCreditCard();
        } else {
          onCompleted(ignorePrint);
        }
      } else {
        throw 'fail';
      }
    } catch (error) { }
    finally {
      loadingRef.current?.setVisible(false);
    }
  };

  const handleAddATip = async (val: number) => {
    const billId = detailTicket?.billId;
    if (val && billId) {
      setLoadingPage(true);
      try {
        await paymentApis.addTipCreditCard(billId, val);
      } catch (error) { }
      finally {
        setLoadingPage(false);
      }
    }
    onCompleted(false);
  };

  const onPostPayment = () => handlePostPayment(detailPayment);

  const handleApplyKeyboard = (paymentType: PAYMENT_TYPE) => () => {
    const _amount = amountKB || 0;

    let paymentAmount = 0;

    switch (paymentType) {
      case PAYMENT_TYPE.CASH: {
        const cash = get(detailPayment, [PAYMENT_TYPE.CASH, 'amount'], 0) || 0;
        paymentAmount = cash + _amount;
        dispatch(multiplePaymentActions.setCashPayment(paymentAmount));
        break;
      }
      case PAYMENT_TYPE.CREDIT_CARD: {
        const card = get(detailPayment, [PAYMENT_TYPE.CREDIT_CARD, 'amount'], 0) || 0;
        paymentAmount = card + _amount;
        dispatch(multiplePaymentActions.setCardPayment(paymentAmount));
        break;
      }
    }

    /**
     * AUTO COMPLETE PAYMENT
     */
    // const newRemaining = remaining - paymentAmount;
    // if (newRemaining <= 0) {
    //   const _detailPayment = cloneDeep(detailPayment);
    //   set(_detailPayment, [paymentType, 'amount'], paymentAmount);
    //   handlePostPayment(_detailPayment);
    // }
  };

  return (
    <>
      <PaymentInfoBoxStyled>
        <Box display={'flex'} className='box-payment'>
          <Box width={'35rem'} overflow="auto" display='flex' flexDirection='column'>
            <Box bgColor="primary_button" className="center" height={'3.5rem'}>
              <Text variant="H5" color="text_3">
                PAYMENTS
              </Text>
            </Box>
            <PaymentKeyboard
              keyboardRef={currencyRef}
              onBack={onBack}
              onDone={onPostPayment}
              onClear={handleClear}
              value={amountKB}
              remaining={remaining}
              onChange={setAmountKB}
            />
          </Box>
          <Box width={'13rem'} bl="line_2" br="line_2" px="2" py="4">
            <Box px="1" display="flex" flexDirection="column" gap="4">
              <LoyaltyPoint
                amountKB={amountKB || 0}
                onRedeem={handlePostPayment}
              />
              <ButtonGiftCard
                amountKB={amountKB || 0}
                onRedeem={handlePostPayment}
              />
              <CheckOthers
                amountKB={amountKB || 0}
                customerRef={customerRef}
                onRedeem={handlePostPayment}
              />
              <CardBox
                icon="cash"
                type={'DEFAUL'}
                onClick={handleApplyKeyboard(PAYMENT_TYPE.CASH)}
              >
                Cash
              </CardBox>

              <CreditCard
                onRedeem={handleApplyKeyboard(PAYMENT_TYPE.CREDIT_CARD)}
              />
            </Box>
          </Box>
          <Box width="19rem" overflow="auto">
            <DetailItems data={detailTicket} />
            <DescribePayment data={detailTicket} />
            <DetailPayment data={detailPayment} remaining={remaining} />
          </Box>
        </Box>
      </PaymentInfoBoxStyled>
      <PaymentLoading ref={loadingRef} />
      <PassDataPayment ref={customerRef} onDone={onCompleted} remaining={remaining} />
      <AddATip ref={tipRef} onAddATip={handleAddATip} />
    </>
  );
};

export default PaymentInfoBox;

const PaymentInfoBoxStyled = styled.div`
  .box-payment {
    background: #fff;
    border-radius: 5px;
    opacity: 0.9;
    background: var(--fill-fill-0, #FFF);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  display: flex;
  .divider {
    background: #86909C;
    width: 100%;
    height: 1px;
  }
`;