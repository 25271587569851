// import { Player } from '@lottiefiles/react-lottie-player';
// import loading from './Loading/animation_lntwnftg.json';
import React from 'react';
import { Spin as SpinAnt } from 'antd';

const Spin = () => {
  return (
    <SpinAnt spinning />
    // <Player
    //   autoplay={true}
    //   loop={true}
    //   controls={true}
    //   src={loading}
    //   style={{ height: '6rem', width: 'auto' }}
    // ></Player>
  );
};

export default Spin;
