
const ClockOut = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M27 4H12.5916V6.52632H24.7833V25.4737H12.5916V28H27V4ZM11.2934 9.37839L5.48276 16.0007L11.2934 22.623L12.8608 20.8366L9.72535 17.2632L20.429 17.2632V14.7368L9.72654 14.7368L12.8608 11.1648L11.2934 9.37839Z" fill="#1D2129" />
    </svg>
  );
};

export default ClockOut;
