import { createContext, useContext } from 'react';

type ICalendarContext = {
    scrollTo: (top: number) => void,
};

const CalendarContext = createContext<ICalendarContext>({
    scrollTo: () => undefined
});

export const useCalendarContext = () => useContext(CalendarContext);

export default CalendarContext;
