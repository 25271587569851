import { Form, Modal, Upload } from 'antd';
import { RcFile } from 'antd/es/upload';
import { UploadFile } from 'antd/lib/upload';
import React, { useState } from 'react';
import styled from 'styled-components';

type IUploadAddStaffProps = {};

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const UploadAddStaff: React.FC<IUploadAddStaffProps> = () => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState<string>();
  const watchAvatar = Form.useWatch('avatar');
  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1)
    );
  };

  const uploadButton = <div>Upload</div>;

  return (
    <>
      <Form.Item
        name="avatar"
        label="Avatar"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) {
            return e;
          }
          return e && e.fileList;
        }}
      >
        <UploadAddStaffStyled
          listType="picture-card"
          onPreview={handlePreview}
          beforeUpload={() => false}
        >
          {watchAvatar && watchAvatar.length !== 0 ? null : uploadButton}
        </UploadAddStaffStyled>
      </Form.Item>
      <Modal
        visible={previewOpen} // Changed from open to visible
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img alt="example" style={{ width: '100%' }} src={previewImage} />
      </Modal>
    </>
  );
};

export default UploadAddStaff;
const UploadAddStaffStyled = styled(Upload)``;
