import { Col, Row } from 'antd';
import Text from 'components/Text';
import ImageBackground from 'features/auth/components/ImageBackground';
import authSelectors from 'features/auth/services/selectors';
import bg from '../../assets/ownerDashboard.png';
import Header from './components/Header';
import Shops, { IShopsRef } from './components/Shops';
import OwnerDashboardStyled from './styles';
import { useEffect, useRef } from 'react';
import { get } from 'lodash';
import { useAppDispatch } from 'store/hooks';
import authActions from 'features/auth/services/actions';
const OwnerDashboard = () => {
  const dispatch = useAppDispatch();
  const shops = authSelectors.owner.getShops();
  const isAutoSelectShop = authSelectors.getAutoSelectShop();
  const shopsRef = useRef<IShopsRef>(null);

  useEffect(() => {
    if (isAutoSelectShop) return;
    if (shops.length === 1) {
      const shopId = get(shops, [0, 'id'], '');
      shopsRef.current?.setActiveShopId(shopId);
      dispatch(authActions.setAutoSelectShop(true));
    }
  }, [shops]);

  return (
    <ImageBackground bg={bg} className='bg' >
      <OwnerDashboardStyled>
        <Header />
        <Row justify={'center'} style={{ flex: 1 }}>
          <Col span={16} className="main">
            <Text variant='H3' color='text_3' mb={3}>ADMIN LOG IN</Text>
            <Shops ref={shopsRef} />
          </Col>
        </Row>
      </OwnerDashboardStyled>
    </ImageBackground>
  );
};

export default OwnerDashboard;

