import { Form, FormItemProps, Input } from 'antd';
import Text from 'components/Text';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { ISpacing, generateCssSpacing } from 'styles/spacing';
const suggestionEmailDefault = [
  '@fozito.com',
  '@gmail.com',
  '@yahoo.com',
];
type Props = FormItemProps & ISpacing;

interface IInputEmailProps extends Props {
  suggestionEmail?: string[];
  value?: string;
  onChange?: (val: string | undefined) => void;
  tabIndex?: number;
  autoFocus?: boolean;
}
const InputEmail: React.FC<IInputEmailProps> = ({ suggestionEmail = suggestionEmailDefault, value, onChange = () => undefined, tabIndex, autoFocus, ...formProps }) => {
  const form = Form.useFormInstance();
  const getValue = () => {
    return (value || (formProps.name ? form.getFieldValue(formProps.name) : '')) as string;
  };
  const setValue = (val: string) => {
    onChange(val);
    if (formProps.name) {
      form.setFieldValue(formProps.name, val);
      form.validateFields([formProps.name]);
    }
  };

  const onPressSuggestion = (emailType: string) => () => {
    const val = getValue();
    if (formProps.name) form.validateFields([formProps.name]);
    if (!val) return;

    const newVal = (val.includes('@') ? val.slice(0, val.search('@')) : val) + emailType;
    setValue(newVal);
  };
  const suggestion = useMemo(() => {
    return (
      <div className="suggestion">
        {suggestionEmail.map(o => (
          <button className='box-item' key={o} type='button' onClick={onPressSuggestion(o)}>
            <Text variant='CONTENT_1' color='text_1' >{o}</Text>
          </button>
        ))}
      </div>
    );
  }, []);

  const styles: React.CSSProperties = useMemo(
    () => {
      return ({
        ...generateCssSpacing(formProps),
        ...formProps.style ?? {},
      });
    },
    [formProps]
  );


  return (
    <InputEmailStyled style={styles}>
      <Form.Item {...formProps} extra={suggestion} >
        <Input tabIndex={tabIndex} autoFocus={autoFocus} placeholder='Email' className='input-email' />
      </Form.Item>
    </InputEmailStyled>
  );
};

export default InputEmail;


type InputEmailStyledProps = {}
const InputEmailStyled = styled.div<InputEmailStyledProps>`
  .suggestion {
    display: flex;
    align-items: center;
    margin-top:0.5rem;
    gap: 1rem;
    flex-wrap: wrap;
    .box-item {
      border-radius: 50px;
      background: var(--fill-fill-2, #ADADAD);    
      display: flex;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      
    }
  }
  .ant-input {
    height: 3.5rem;
  }
`;
