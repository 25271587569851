import { Divider, Form, InputNumber, Switch } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { ITaxParams } from 'features/settingService/services/types/paymentSetting';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type ITaxProps = {};
const Tax: React.FC<ITaxProps> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<ITaxParams>();
  const taxStore = settingServiceSelectors.paymentSetting.tax();

  const saleTaxWatch = Form.useWatch('enableSaleTax', form);
  const usedTaxWatch = Form.useWatch('enableUseTax', form);

  const onFinish = (values: ITaxParams) => {
    const body = { ...taxStore, ...values };
    dispatch(settingServiceActions.postTax.fetch(body));
  };

  useEffect(() => {
    if (taxStore) {
      form.setFieldsValue(taxStore);
    }
  }, [taxStore]);

  useEffect(() => {
    dispatch(settingServiceActions.getTax.fetch());
  }, []);

  return (
    <TaxStyled>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ saleTaxPercent: 0, useTaxPercent: 0 }}
      >
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Box display="flex" gap="3" alignItems="center">
            <FormItemStyled name="enableSaleTax" valuePropName="checked">
              <Switch />
            </FormItemStyled>
            <Text variant="CONTENT_2" color="text_3">
              Sale Tax
            </Text>
          </Box>
          {saleTaxWatch && (
            <Form.Item name="saleTaxPercent" label="Sale Tax">
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) => `${value}%`}
                parser={(value: any) => value?.replace('%', '')}
              />
            </Form.Item>
          )}
          <Divider />
          <Box display="flex" gap="3" alignItems="center">
            <FormItemStyled name="enableUseTax" valuePropName="checked">
              <Switch />
            </FormItemStyled>
            <Text variant="CONTENT_2" color="text_3">
              Used Tax
            </Text>
          </Box>
          {usedTaxWatch && (
            <Form.Item
              label="Used Tax"
              name="useTaxPercent"
              style={{ width: '100%' }}
            >
              <InputNumber
                style={{ width: '100%' }}
                formatter={(value) => `${value}%`}
                parser={(value: any) => value?.replace('%', '')}
              />
            </Form.Item>
          )}
        </Box>
        <Box display="flex" gap="3" mt="4" justifyContent="end">
          <Button width="10rem" ntype="SECONDARY" htmlType="reset">
            Reset
          </Button>
          <Form.Item>
            <Button width="10rem" ntype="PRIMARY" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Box>
      </Form>
    </TaxStyled>
  );
};

export default Tax;
const TaxStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
      color: var(--text-text-3, #1d2129);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
`;
const FormItemStyled = styled(Form.Item)`
  margin: 0;
`;
