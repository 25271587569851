
export const NAME_REDUCER = 'checkIn';

export const PREFIX_ACTIONS = 'checkIn_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
};

export const CHECK_IN_COUNTDOWN = 2;