import ActionBtn from 'components/ActionBtn';
import CalculatorModal from 'components/CalculatorModal';
import { useState } from 'react';

const ButtonCalculator = () => {
  const [visible, setVisible] = useState(false);
  return (
    <div>
      <ActionBtn
        label='Calculator'
        ntype="LIGHT_BLUE"
        icon='calculatorBtn'
        small
        onClick={() => setVisible(true)}
      />
      <CalculatorModal visible={visible} onClose={() => setVisible(false)} />
    </div>
  );
};

export default ButtonCalculator;