import Button from 'components/Button';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import userSelectors from 'features/user/services/selectors';
import useAddNewTicket from 'hooks/useAddNewTicket';

const ButtonAddNewTicket = () => {
  const modal = useModalConfirmRef();
  const isClockIn = userSelectors.staff.isClockIn();
  const getNumberTicket = useAddNewTicket(modal);

  return (
    <>
      <Button ntype="PRIMARY" disabled={!isClockIn} icon='ticket' onClick={getNumberTicket} >New Ticket</Button>
      <ModalConfirm ref={modal} okTitle='Yes' cancelTitle='No' />
    </>
  );
};

export default ButtonAddNewTicket;