import styled from 'styled-components';

const SignPageStyled = styled.div`
    width: 100%;
    height: 100vh;
    background: var(--fill-fill-3, #E5E5E5);

`;

export default SignPageStyled;
