import React, { useState } from 'react';
import { Menu } from 'antd';
import { MenuProps } from 'antd/lib';
import Box from 'components/Box';
import styled from 'styled-components';
// import Security from './GeneralConfigurationTabMenu/Security';
import Notification from './GeneralConfigurationTabMenu/Notification';
import WorkingTime from './GeneralConfigurationTabMenu/WorkingTime';
// import Role from './GeneralConfigurationTabMenu/Role';

type IConfigurationTabProps = {};
type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
enum MenuKeys {
  SECURITY = 'security',
  NOTIFICATION = 'notification',
  WORKING_TIME = 'working',
  ROLE = 'role',
}

const items: MenuItem[] = [
  // getItem('Security', MenuKeys.SECURITY),
  getItem('Notification', MenuKeys.NOTIFICATION),
  getItem('Working Time', MenuKeys.WORKING_TIME),
  // getItem('Role', MenuKeys.ROLE),
];

const ConfigurationTab: React.FC<IConfigurationTabProps> = () => {
  const [selectedKey, setSelectedKey] = useState<MenuKeys>(MenuKeys.NOTIFICATION);

  const handleMenuSelect = (info: { key: string }) => {
    setSelectedKey(info.key as MenuKeys);
  };

  // useEffect(() => {}, []);

  return (
    <ConfigurationTabStyled>
      <BoxStyled display="flex" p="4" gap="4" alignItems="stretch">
        <LeftBoxStyled>
          <MenuBoxStyled>
            <Menu
              style={{ width: '100%' }}
              defaultSelectedKeys={[MenuKeys.NOTIFICATION]}
              items={items}
              onSelect={handleMenuSelect}
            />
          </MenuBoxStyled>
        </LeftBoxStyled>
        {/* {selectedKey === MenuKeys.SECURITY && <Security />} */}
        {selectedKey === MenuKeys.NOTIFICATION && <Notification />}
        {selectedKey === MenuKeys.WORKING_TIME && <WorkingTime />}
        {/* {selectedKey === MenuKeys.ROLE && <Role />} */}
      </BoxStyled>
    </ConfigurationTabStyled>
  );
};

export default ConfigurationTab;

const ConfigurationTabStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--fill-fill-3, #e5e5e5);
  height: calc(100vh - 3.125rem);
  min-width: 50rem;
  overflow: auto;
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
`;

const LeftBoxStyled = styled.div`
  border-radius: 5px;
  min-width: 16.5rem;
  background-color: white;
  gap: 1.5rem;
`;

const MenuBoxStyled = styled.div`
  padding: 0.25rem 0;
  .ant-menu-item {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;
