import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { useMemo } from 'react';
import { ICustomerItemData } from 'services/shop/types/customer';
import { useAppDispatch } from 'store/hooks';
import ModalChooseCustomerWidget from 'widgets/Customer/ModalChooseCustomer';

const ModalChooseCustomer = () => {
  const dispatch = useAppDispatch();
  const visible = cashierSelectors.getVisibleChooseCustomer();
  const customerStore = cashierSelectors.getSelectedCustomer();

  const customer = useMemo(() => {
    return ({
      email: '',
      id: customerStore?.customerId || '',
      imageAvatarUrl: '',
      name: customerStore?.customerName || '',
      numberVisit: 0,
      phone: customerStore?.customerPhone || '',
      point: customerStore?.point || 0,
      status: '',
    }) as ICustomerItemData;
  }, [customerStore]);

  const handleClose = () => {
    dispatch(cashierActions.setVisibleChooseCustomer(false));
  };

  const onPickCustomer = (option: ICustomerItemData) => {
    dispatch(cashierActions.switchSelectedCustomer(option));
  };

  const handleChooseUnknown = () => {
    dispatch(cashierActions.clearCustomer.fetch());
  };

  return (
    <ModalChooseCustomerWidget
      visible={visible}
      onClose={handleClose}
      onPickCustomer={onPickCustomer}
      customer={customer}
      onChooseUnknown={handleChooseUnknown}
    />
  );
};

export default ModalChooseCustomer;