import { Select, SelectProps } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/es/select';
import React from 'react';
import styled from 'styled-components';
interface ISelectFromToOptionsProps
  extends SelectProps<any, DefaultOptionType | BaseOptionType> {}
const SelectFromToOptions: React.FC<ISelectFromToOptionsProps> = (props) => {
  return <SelectFromToOptionsStyled {...props} />;
};

export default SelectFromToOptions;
const SelectFromToOptionsStyled = styled(Select)`
  width: 100%;
`;
