const SettingLight = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 15.0863C13.6569 15.0863 15 13.8004 15 12.2141C15 10.6278 13.6569 9.3418 12 9.3418C10.3431 9.3418 9 10.6278 9 12.2141C9 13.8004 10.3431 15.0863 12 15.0863Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 13.0563V11.3712C2 10.3755 2.85 9.55213 3.9 9.55213C5.71 9.55213 6.45 8.32663 5.54 6.82348C5.02 5.9618 5.33 4.84162 6.24 4.34376L7.97 3.39591C8.76 2.94592 9.78 3.214 10.25 3.97037L10.36 4.15228C11.26 5.65543 12.74 5.65543 13.65 4.15228L13.76 3.97037C14.23 3.214 15.25 2.94592 16.04 3.39591L17.77 4.34376C18.68 4.84162 18.99 5.9618 18.47 6.82348C17.56 8.32663 18.3 9.55213 20.11 9.55213C21.15 9.55213 22.01 10.3659 22.01 11.3712V13.0563C22.01 14.052 21.16 14.8754 20.11 14.8754C18.3 14.8754 17.56 16.1009 18.47 17.604C18.99 18.4753 18.68 19.5859 17.77 20.0838L16.04 21.0316C15.25 21.4816 14.23 21.2135 13.76 20.4572L13.65 20.2752C12.75 18.7721 11.27 18.7721 10.36 20.2752L10.25 20.4572C9.78 21.2135 8.76 21.4816 7.97 21.0316L6.24 20.0838C5.33 19.5859 5.02 18.4657 5.54 17.604C6.45 16.1009 5.71 14.8754 3.9 14.8754C2.85 14.8754 2 14.052 2 13.0563Z"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingLight;
