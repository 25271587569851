import Button from 'components/Button';
import Modal from 'components/Modal';
import NumberKeyBoard, { useNumberKeyBoardRef } from 'components/NumberKeyBoard';
import userActions from 'features/user/services/actions';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';

const ButtonClockIn = ({ isClockIn }: { isClockIn: boolean }) => {
  const kbRef = useNumberKeyBoardRef();
  const [visible, setVisible] = useState(false);
  const onClockIn = () => {
    setVisible(true);
    kbRef.current?.focusInput();
  };
  const [pin, setPin] = useState('');
  const dispatch = useAppDispatch();

  const [, startTransition] = useTransition();
  const handleClose = () => startTransition(() => {
    setPin('');
    kbRef.current?.reset();
    setVisible(false);
  });

  const handle = useCallback((_pin: string) => {
    if (_pin.length < 4) return;
    handleClose();
    if (isClockIn) {
      dispatch(userActions.doClockIn.fetch(_pin));
      return;
    }

    dispatch(userActions.doClockOut.fetch(_pin));
  }, [isClockIn]);

  const _debounce = useCallback(
    debounce((_pin) => {
      handle(_pin);
    }, 500),
    [isClockIn]
  );

  useEffect(() => _debounce(pin), [pin]);

  return (
    <>
      <Button
        ntype="SECONDARY" icon={isClockIn ? 'clockIn' : 'clockOut'}
        onClick={onClockIn}
      >
        {isClockIn ? 'Clock In' : 'Clock Out'}
      </Button>
      <Modal
        modalTitle='password'
        hiddenOkButton
        visible={visible} onClose={handleClose}>
        <NumberKeyBoard
          ref={kbRef}
          type='PIN-PASSWORD'
          value={pin} onChange={(val) => setPin(val || '')}
        />
      </Modal>
    </>
  );
};

export default ButtonClockIn;

