import { Checkbox, Col, Dropdown, InputNumber, Row, Switch } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { MenuProps } from 'antd/lib';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { IProduct } from 'features/settingService/services/types/reducer';
import { FC, useEffect, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { default as ModalAddNewProduct, default as ModalEditProduct } from './ModalAddProduct';
import Product from './Product';
import ModalEditCategoryName from './ModalEditCategoryName';

interface IListProduct { }
const ListProducts: FC<IListProduct> = () => {
    const dispatch = useAppDispatch();
    const [checkedList, setCheckedList] = useState<IProduct[]>([]);
    const [commission, setCommission] = useState<number>(0);
    const [switchStatus, setSwitchStatus] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [showModalAddProduct, setShowModalAddProduct] = useState<boolean>(false);
    const [showModalCategoryName, setShowModalCategoryName] = useState<boolean>(false);
    const [editProductData, setEditProductData] = useState<IProduct>();
    const lstProducts = settingServiceSelectors.getProductsByCategoryId();
    const pageable = settingServiceSelectors.getProductsPageable();
    const reloadProduct = settingServiceSelectors.reloadProduct();
    const cateForProductSelected = settingServiceSelectors.getCateForProductSelected();
    const checkAll = lstProducts.length === checkedList.length;
    const indeterminate = checkedList.length > 0 && checkedList.length < lstProducts.length;
    const fetchProduct = (page: number) => {
        return dispatch(settingServiceActions.getProductsByCategoryId.fetch({
            body: {
                catId: lstProducts[0].catId || ''
            }, params: {
                page: page,
                size: pageable.pageSize
            }
        }));
    };

    useEffect(() => {
        setPage(pageable.pageNumber);
        setTotalPage(pageable.totalPages);
        setCheckedList([]);
    }, [pageable]);

    useEffect(() => {
        if (reloadProduct) {
            setCheckedList([]);
            if (checkAll && page > 1) {
                fetchProduct(page - 1);
            } else {
                fetchProduct(page);
            }
            dispatch(settingServiceActions.reloadProduct(false));
            dispatch(settingServiceActions.getListCategoriesForProduct.fetch({}));
        }
    }, [reloadProduct]);

    useEffect(()=>{
        setCommission(cateForProductSelected?.supplyFee ?? 0);
        setSwitchStatus(cateForProductSelected?.applyFee ?? false);
    },[cateForProductSelected]);

    const onCheckAllChange = (e: CheckboxChangeEvent) => {
        setCheckedList(e.target.checked ? lstProducts : []);
    };
    const onSwitchChange = () => {
        setSwitchStatus(!switchStatus);
        dispatch(settingServiceActions.changeCommissionPrice.fetch({catId: cateForProductSelected?.id ?? '', commission, turnOn:!switchStatus}));
    };
    const items: MenuProps['items'] = [
        {
            key: 'EDIT',
            label: (<a onClick={(e) => {
                e.preventDefault();
                if(cateForProductSelected) {
                    setShowModalCategoryName(true);
                }
            }}>Edit name</a>),
            disabled: !cateForProductSelected,
        },
        {
            key: 'DELETE',
            label: (<a onClick={(e) => {
                e.preventDefault();
                if(cateForProductSelected) {
                    const params = {
                        id: cateForProductSelected?.id,
                    };
                    dispatch(settingServiceActions.deleteCategoryForProduct.fetch(params));
                }
            }}>Delete Category</a>),
            disabled: !cateForProductSelected,
        },
    ];
    const handleCheckProduct = (data: IProduct, checked: boolean) => {
        if (checked) {
            return setCheckedList([...checkedList, data]);
        }
        setCheckedList(checkedList.filter(f => f.id != data.id));
    };
    const handleDeleteProduct = () => {
        dispatch(settingServiceActions.deleteProduct.fetch({
            ids: checkedList.map(m => m.id)
        }));
    };
    const handleOpenModalAddProduct = () => {
        if (!cateForProductSelected) return;
        setShowModalAddProduct(true);
    };
    const handleCloseModalAddProduct = () => {
        setShowModalAddProduct(false);
    };
    const onSubmitModalAddNewProduct = (data: IProduct) => {
        dispatch(settingServiceActions.createProduct.fetch({ ...data, catId: cateForProductSelected?.id ?? '' }));
    };
    const handleCloseModalEditProduct = () => {
        setEditProductData(undefined);
    };
    const onSubmitModalEditProduct = (data: IProduct) => {
        dispatch(settingServiceActions.updateProduct.fetch({ ...data, catId: cateForProductSelected?.id ?? '' }));
    };
    const onChangeCommission = (value:any) => {
        setCommission(value);
    };
    const handlePrev = () => {
        fetchProduct(page - 1);
    };
    const handleNext = () => {
        fetchProduct(page + 1);
    };
    return (
        <ListProductsStyled>
            <Header>
                <CheckBoxAll>
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll} />
                    <CheckboxText variant="H9">{cateForProductSelected?.categoryName ?? ''}</CheckboxText>
                </CheckBoxAll>
                <ToolBox>
                    <Switch checked={switchStatus} onChange={onSwitchChange} />
                    <ApplyTextStyled>Apply Commission Price</ApplyTextStyled>
                    <InputNumberStyled
                        value={commission}
                        min={0}
                        max={100}
                        formatter={(value: any) => `${value}%`}
                        onChange={onChangeCommission}
                        controls={false}
                    />
                    <Dropdown
                        menu={{
                            items,
                        }}
                        trigger={['click']}
                    >
                        <a onClick={(e) => {
                            e.preventDefault();
                        }}>
                            <ToolButton>
                                <Icon type="ellipsis" width="28" height="28" />
                            </ToolButton>
                        </a>
                    </Dropdown>

                    <ToolButton onClick={handleDeleteProduct}>
                        <Icon type="trash" width="28" height="28" fill={checkedList.length > 0 ? '#000' : '#cccccc'} />
                    </ToolButton>
                    <ToolButton onClick={handleOpenModalAddProduct}>
                        <Icon type="addPlus" fill={!cateForProductSelected ? '#cccccc' : '#6FABB6'} width="28" height="28" />
                    </ToolButton>
                </ToolBox>
            </Header>
            <Body>
                <Row gutter={[12, 12]}>
                    {lstProducts.map((product: IProduct) => <Col key={product.id} span={12}><Product data={product} checked={!!checkedList.find(f => f.id == product.id)} onCheck={handleCheckProduct} onEdit={setEditProductData} /></Col>)}
                </Row>

                {/* <PaginationStyled defaultCurrent={1} current={1} total={5} onChange={onChangePage} /> */}
                {/* <Pagination current={pageNumber} total={totalPage}/> */}
                {totalPage > 1 && <PaginationStyled display="flex" gap="2">
                    <IconButtonBox onClick={handlePrev} disabled={page === 1}>
                        <Icon type="back" />
                    </IconButtonBox>
                    <IconButtonBox onClick={handleNext} disabled={totalPage === page}>
                        <Icon type="next" />
                    </IconButtonBox>
                </PaginationStyled>}
            </Body>
            <ModalAddNewProduct key={Math.random()} isShowModal={showModalAddProduct} onClose={handleCloseModalAddProduct} onSubmit={onSubmitModalAddNewProduct} />
            <ModalEditProduct key={Math.random()} isShowModal={!!editProductData} data={editProductData} onClose={handleCloseModalEditProduct} onSubmit={onSubmitModalEditProduct} />
            <ModalEditCategoryName isShown={showModalCategoryName} onClose={() => setShowModalCategoryName(false)} />

        </ListProductsStyled>
    );
};

export default ListProducts;
const HEADER_HEIGHT = 52;
const ListProductsStyled = styled.div`
    position: relative;
`;
const Header = styled.div`
    height: ${HEADER_HEIGHT}px;
    width: 100%;
    border-bottom: solid 2px #C9CDD4;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.125rem;
    z-index: 2;
    background: white;
`;
const Body = styled.div`
    min-height: 25rem;
    max-height: calc(100vh - 12rem);
    padding: 20px;
    overflow-y: auto;
`;
const CheckBoxAll = styled.div`
    display: flex;
    align-items: center;
`;
const CheckboxText = styled(Text)`
    padding: 0px 0.5rem;
`;
const ApplyTextStyled = styled(Text)`
    padding: 0px 1rem;
`;
const InputNumberStyled = styled(InputNumber)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    height: 2rem;
    width: 70px;
    text-align: center;
`;
const ToolBox = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const ToolButton = styled.div`
    width:72px;
    height: ${HEADER_HEIGHT}px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid 2px #C9CDD4;
`;
const PaginationStyled = styled(Box)`
    float: right;
    background: white;
    padding: 20px;
`;
const IconButtonBox = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem;
  border-radius: 50%;
  background-color: ${colorTheme.fill_2};
  border: 1px solid ${colorTheme.line_1};
  cursor: pointer;
  ${(props) =>
        props.disabled &&
        `
    opacity: 0.5;
  pointer-events: none;
  `}
`;