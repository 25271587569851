export const NAME_REDUCER = 'customer';

export const PREFIX_ACTIONS = 'customer_feature_';

export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
  getCustomers: `loading.${NAME_REDUCER}.getCustomers`,
  getAppointments: `loading.${NAME_REDUCER}.getAppointments`,
  getTransactions: `loading.${NAME_REDUCER}.getTransactions`,
  getReferrals: `loading.${NAME_REDUCER}.getReferrals`,
  getSummaryReport: `loading.${NAME_REDUCER}.getSummaryReport`,
  getCustomersActive: `loading.${NAME_REDUCER}.getCustomersActive`,
  getCoupons: `loading.${NAME_REDUCER}.getCoupons`,
};

export const ICustomerStatus = {
  NEW: 'NEW',
  VIP: 'VIP',
  DELETE: 'DELETE',
  REGULAR: 'REGULAR',
};

export const optionsSpendingAmount = [
  { value: '', label: 'All' },
  { value: '1', label: '> $1,000.00' },
  { value: '2', label: '$501.00 - $1,000.00' },
  { value: '3', label: '$301.00 - $500.00' },
  { value: '4', label: '$100.00 - $300.00' },
  { value: '5', label: '< $100.00' },
];
export const optionsPoint = [
  { value: '', label: 'All' },
  { value: '1', label: '> 1,000' },
  { value: '2', label: '501- 1,000' },
  { value: '3', label: '301 - 500' },
  { value: '4', label: '100 - 300' },
  { value: '5', label: '< 100' },
];
export const optionsStatus = [
  { value: 'VIP', label: 'Vip' },
  { value: 'NEW', label: 'New' },
  { value: 'REGULAR', label: 'Regular' },
  { value: 'DELETED', label: 'Deleted' },
];
