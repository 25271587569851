import { Modal } from 'antd';
import React, { useState } from 'react';
import { styled } from 'styled-components';
import GroupButtonFooterModal, { BtnProps } from '../GroupButtonFooterModal';
import Text from 'components/Text';
import stick from '../../images/stick.png';
import Button from 'components/Button';
import { useAppDispatch } from 'store/hooks';
import paymentActions from 'features/payment/services/actions';

interface IBtnShowSendSMSModal {}

const BtnShowSendSMSModal: React.FC<IBtnShowSendSMSModal> = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  // sent sms success
  const dispatch = useAppDispatch();
  const handleAfterSentSms = () => {
    setIsOpenModal(false);
    dispatch(paymentActions.sendInfoPaySuccess(true));
  };
  const btnPropsAfterSend: BtnProps[] = [
    {
      buttonName: 'Success',
      onClick: handleAfterSentSms,
      type: 'PRIMARY',
    },
  ];

  return (
    <>
      <Button
        onClick={() => {
          setIsOpenModal((previous) => !previous);
        }}
        ntype="DEFAULT"
        icon="sendSms"
      >
        Send SMS
      </Button>
      <SendEmailModalStyled
        footer={<GroupButtonFooterModal buttonSequence={btnPropsAfterSend} />}
        title=""
        closable={false}
        open={isOpenModal}
        onCancel={() => undefined}
        width={550}
        centered
      >
        <div className="pay-sent-sms">
          <img src={stick} />
          <Text variant="H5">Success</Text>
        </div>
      </SendEmailModalStyled>
    </>
  );
};

export default BtnShowSendSMSModal;

const SendEmailModalStyled = styled(Modal)`
  .pay-sent-sms {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
