import { createAction, createAsyncAction } from 'services/actionConfigs';
import { APPOINTMENT_LAYOUT, APPOINTMENT_STATUS, CALENDAR_ITEM_TYPES, CALENDAR_VIEW_TYPE, PREFIX_ACTIONS, PROLONGED_TIME_STATUS } from './constants';
import { IApiGetAppointments, IApiGetTableAppointment, IBodyApiUpdateAppointmentWithDrop } from './types/api';
import { IAppointmentItemData } from './types/appointment';
import { IState } from './types/reducer';

const getAppointments = createAsyncAction<IApiGetAppointments>(PREFIX_ACTIONS + 'getAppointments');

const getLockBreakTimes = createAsyncAction<IApiGetAppointments>(PREFIX_ACTIONS + 'getLockBreakTimes');

const setParams = createAction<IApiGetAppointments | undefined>(PREFIX_ACTIONS + 'setParams');

const initData = createAction(PREFIX_ACTIONS + '');

// UI ACTIONS
const setAppointmentLayout = createAction<APPOINTMENT_LAYOUT>(PREFIX_ACTIONS + 'setAppointmentLayout');
const setSelectedCalendarItemTypes = createAction<CALENDAR_ITEM_TYPES[]>(PREFIX_ACTIONS + 'setSelectedCalendarItemTypes');
const setCalendarViewType = createAction<CALENDAR_VIEW_TYPE>(PREFIX_ACTIONS + 'setCalendarViewType');
const setAppointmentDetail = createAction<IAppointmentItemData>(PREFIX_ACTIONS + 'setAppointmentDetail');
const decreaseStaffPage = createAction(PREFIX_ACTIONS + 'decreaseStaffPage');
const increaseStaffPage = createAction(PREFIX_ACTIONS + 'increaseStaffPage');
const setNewAppointmentDraftData = createAction<IState['newAppointmentDraftData']>(PREFIX_ACTIONS + 'setNewAppointmentDraftData');
const setNewBlockHourDraftData = createAction<IState['newBlockHourDraftData']>(PREFIX_ACTIONS + 'setNewBlockHourDraftData');
const setNewBreakTimeDraftData = createAction<IState['newBreakTimeDraftData']>(PREFIX_ACTIONS + 'setNewBreakTimeDraftData');
const setDraftAppointmentDetail = createAction<IState['draftAppointmentData']>(PREFIX_ACTIONS + 'setDraftAppointmentDetail');

const addMoreTimeAppointment = createAction<{ status: PROLONGED_TIME_STATUS, appointmentId: string }>(PREFIX_ACTIONS + 'addMoreTimeAppointment');

const updateAppointmentWithDrop = createAction<IBodyApiUpdateAppointmentWithDrop>(PREFIX_ACTIONS + 'updateAppointmentWithDrop');

const deleteAppointment = createAsyncAction<string>(PREFIX_ACTIONS + 'deleteAppointment');

const addMoreBreakTime = createAction<string>(PREFIX_ACTIONS + 'addMoreBreakTime');

const setTableParams = createAction<IApiGetTableAppointment | undefined>(PREFIX_ACTIONS + 'setTableParams');

const getTableAppointments = createAsyncAction<IApiGetTableAppointment>(PREFIX_ACTIONS + 'getTableAppointments');

const setAppointmentStatusFilter = createAction<APPOINTMENT_STATUS[]>(PREFIX_ACTIONS + 'setAppointmentStatusFilter');

const removeBreakBlockHour = createAction<string>(PREFIX_ACTIONS + 'removeBreakBlockHour');

const appointmentActions = {
  setTableParams,
  getTableAppointments,

  setParams,
  getAppointments,
  getLockBreakTimes,
  initData,
  decreaseStaffPage,
  increaseStaffPage,
  setNewAppointmentDraftData,
  setNewBlockHourDraftData,
  setNewBreakTimeDraftData,
  // 
  setAppointmentLayout,
  setSelectedCalendarItemTypes,
  setCalendarViewType,
  setAppointmentDetail,
  setDraftAppointmentDetail,
  addMoreTimeAppointment,
  addMoreBreakTime,
  updateAppointmentWithDrop,
  deleteAppointment,
  setAppointmentStatusFilter,
  removeBreakBlockHour,
};

export default appointmentActions;

